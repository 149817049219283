import React, { ChangeEvent, useState } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
} from 'reactstrap'
import InfoModal from '../../../components/InfoModal'
import { useActions } from '../../../hooks/useActions'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import '../styles.scss'

const defaultUploadStatus = {
  success: false,
  message: '',
}

const UploadGeofencesButton = () => {
  const [modalVisible, setModalVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [file, setFile] = useState<File>()
  const [uploadStatus, setUploadStatus] = useState(defaultUploadStatus)
  const toggleModalVisability = () => {
    setModalVisible(!modalVisible)
    setUploadStatus(defaultUploadStatus)
    setInvalidDataMessage(null)
    setIsLoading(false)
  }

  const { postGeofencesCsv } = useActions()

  const { selectedCustomersList } = useTypedSelector((state) => ({
    selectedCustomersList: state.common.customers.selectedCustomersList,
  }))

  const [invalidDataMessage, setInvalidDataMessage] = useState<string|null>(null)

  const onFileChange = (event: ChangeEvent) => {
    if (event.target) {
      const target = event.target as HTMLInputElement
      if (target) {
        const file = target.files?.[0]
        setFile(file)
      }
    }
  }

  const onUpload = async () => {
    if (!file) return

    setInvalidDataMessage(null)

    const reader = new FileReader()

    reader.readAsDataURL(file)
    reader.onload = async function () {
      const result = reader.result?.toString().split('base64,')[1]
      if (result) {
        setIsLoading(true)

        const response: any = await postGeofencesCsv(result)

        setIsLoading(false)

        if (!response.statusCode) {
          let message = ''
          if (response.invalid_data?.length) {
            message = `Invalid geofence coordinates data at rows: ${response.invalid_data.join(', ')}. Please check latitude, longitude, radius values.`
          }
          else if (response.incorrect_name_formats?.length) (
            message = `Incorrect name formats at rows: ${response.incorrect_name_formats.join(', ')}. See csv example.`
          )
          else if (response.already_exists_names?.length) {
            const names = response.already_exists_names.join(", ")
            message = `Geofence with names: '${names}' already exists.`
          }
          else if (response.invalid_data_types?.length) {
            message = `Wrong data types at rows: ${response.invalid_data_types.join(', ')}. See csv example.`
          }
          else {
            message = 'Exception: Something went wrong, please validate data in csv file.'
          }
          setInvalidDataMessage(message)
        } else {
          const successful = response.statusCode === 200

          setUploadStatus({
            success: successful,
            message: response.message,
          })

          if (successful) {
            setModalVisible(false)
          }
        }
      }
    }
    reader.onerror = function (error) {
      console.log('Error: ', error)
    }
  }

  const downloadExample = () => {
    let csvContent = 'data:text/csv;charset=utf-8,'
    let csvData = [
      [
        'Name',
        'Latitude',
        'Longitude',
        'Radiuss In Meters',
        '<- Note: This row is for reference and should be removed!',
      ],
      ['Geofence1', '39.9526', '75.1652', '100'],
      ['Geofence2', '34.0522', '118.2437', '200'],
      ['Geofence3', '25.7617', '80.1918', '300'],
    ]

    csvData.forEach(function (rowArray) {
      let row = rowArray.join(',')
      csvContent += row + '\r\n'
    })

    let encodedUri = encodeURI(csvContent)
    let link = document.querySelector('#geofences-example-link') as HTMLElement

    if (!link) {
      link = document.createElement('a')
      link.style.display = 'none'
      link.id = 'geofences-example-link'
      link.setAttribute('href', encodedUri)
      link.setAttribute('download', 'GeofencesExample.csv')
      document.body.appendChild(link)
    }

    link.click()
  }

  return (
    <>
      <Button
        variant='primary'
        className='mr-2'
        onClick={toggleModalVisability}
        disabled={selectedCustomersList.length !== 1}
      >
        Upload Geofences Csv
      </Button>
      <Modal size='lg' centered isOpen={modalVisible}>
        <ModalHeader toggle={toggleModalVisability}>
          Upload Geofences Csv
        </ModalHeader>
        <ModalBody>
          {!uploadStatus.success && uploadStatus.message && (
            <>
              <span className='upload_csv_error'>{uploadStatus.message}</span>
            </>
          )}

          {invalidDataMessage && (
            <div className='upload_csv_error'>
              {invalidDataMessage}
            </div>
          )}

          <div className='upload_csv'>
            <div>
              <span>Upload geofences csv file</span>
              <input type='file' onChange={onFileChange} accept='text/csv' />
            </div>
            <div>
              <div>
                <Button
                  size='md'
                  onClick={downloadExample}
                  className={'download-sample'}
                >
                  Download File Example
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color='primary'
            onClick={() => {
              onUpload()
            }}
            disabled={!file || isLoading}
          >
            {isLoading ? <Spinner size='sm' color='light' /> : 'Ok'}
          </Button>

          <Button
            color='danger'
            onClick={toggleModalVisability}
            disabled={isLoading}
          >
            {'Cancel'}
          </Button>
        </ModalFooter>
      </Modal>
      <InfoModal
        open={uploadStatus.success}
        message={uploadStatus.message}
        header='CSV Upload'
        onConfirm={() => {
          setUploadStatus(defaultUploadStatus)
        }}
      />
    </>
  )
}

export default UploadGeofencesButton
