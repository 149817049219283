import React, { RefObject, useEffect, useRef, useState } from "react"
import { Label, Input } from "reactstrap"
import CustomMenu from "../../../CustomerSelector/CustomMenu"
import { useCustomerListItems } from "../../../CustomerSelector/hooks"
import "../../../CustomerSelector/style.scss"
import { useTypedSelector } from "../../../../hooks/useTypedSelector"
import { ICustomerListItem } from "../../../CustomerSelector"
import { cloneDeep } from "lodash"

interface ICustomerData {
  data: Array<ICustomer>
  locale: ILocale
}

interface Props {
  singleItem: boolean
  onSubmit?: () => void
  disabled?: boolean
  setAllFromTags?: boolean
  preselectedCustomerKeys?: string
  parameters: any
  setParameters: React.Dispatch<React.SetStateAction<any>>
  setIsFormTouched: React.Dispatch<React.SetStateAction<boolean>>
  setCustomerId: React.Dispatch<React.SetStateAction<number|null>>
}

const ScheduledReportCustomerSelector: React.FC<Props> = ({
    singleItem, onSubmit, 
    disabled = false, 
    setAllFromTags, 
    parameters, 
    preselectedCustomerKeys = "", 
    setParameters, 
    setIsFormTouched,
    setCustomerId
  }) => {
  const {
    data,
    locale
  }: ICustomerData = useTypedSelector((state: { common: { customers: { data: any } }; whitelabel: { locale: any } }) => ({
    data: state.common.customers.data,
    locale: state.whitelabel.locale
  }))

  const customerInput = useRef()
  const [inputValue, setInputValue] = useState("")
  const [currentSelected, setCurrentSelected] = useState<Array<ICustomer>>([])
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [selectedCustomersList, setSelectedCustomersList] = useState<Array<ICustomer>>(data.filter((d) => preselectedCustomerKeys.includes(d.key)))
  const [selectedCustomerKey, setSelectedCustomerKey] = useState("");

  useEffect(()=>{
    if (setAllFromTags) {
      setIsMenuOpen(false)
      onSubmit && onSubmit()
    } 
  },[setAllFromTags, data, onSubmit])

  useEffect(() => {
    let cKeys = preselectedCustomerKeys.split(',');

    if (preselectedCustomerKeys.length > 0) {
      setSelectedCustomersList(data.filter((d) => cKeys.includes(d.key)));
    } else {
      setSelectedCustomersList([]);
    }
  }, [preselectedCustomerKeys, data])
  
  useEffect(() => {
    setCurrentSelected(selectedCustomersList)
  }, [selectedCustomersList])

  useEffect(() => {
    if (parameters) {
      setSelectedCustomerKey(currentSelected.map(c => c.key).toString())
      setIsFormTouched(true);
    }
  }, [currentSelected, parameters, setSelectedCustomerKey, setIsFormTouched])

  useEffect(() => {
    let value = "All"

    if (selectedCustomersList.length === 1) {
      value = selectedCustomersList[0].name
    } else if (selectedCustomersList.length > 1 && selectedCustomersList.length < data.length) {
      value = "Multiple"
    } else if (selectedCustomersList.length === 0) {
      value = "None";
    }
    
    setInputValue(value)
  }, [selectedCustomersList, data])

  const parsedData = useCustomerListItems(data)

  const handleCheck = (item: ICustomerListItem | null, state = false, isAll = false) => {
    // state = current value of checkbox (true or false)
    let newList = [...currentSelected]
    
    if (isAll && !singleItem) {
      state ? newList = data : newList = []
    } else if (item) {
      const idx = currentSelected.findIndex(el => el.id === item.id)
    
      if (idx > -1) {
        newList.splice(idx, 1)
      } else {
        const elem = data.find(el => el.id === item.id)

        if (elem) {
          if (singleItem) {
            newList = [elem];
            setCustomerId(elem.id);
          } else {
            newList.push(elem)
          }
        }
      }
    }
    
    setCurrentSelected(newList)
  }

  const handleModalClickOutside = (e: Event, menu: RefObject<any>) => {
    if (menu.current && !menu.current.contains(e.target) && e.target !== customerInput.current) {
      if (isMenuOpen) setIsMenuOpen(false)
    }
  }

  const handleCancel = () => {
    setCurrentSelected(selectedCustomersList)
    setIsMenuOpen(false)
  }

  return (
    <div className="d-flex align-items-center customer__selector">
      <div className="d-flex align-items-center ">
        <Label className="mb-0 mr-2">{locale["Customer"] || "Customer"}</Label>
        <Input
          innerRef={customerInput}
          className={`customer__input ${disabled ? "customer__input__disabled" : ""}`}
          placeholder="Select Customer"
          readOnly={true}
          disabled={disabled}
          value={inputValue}
          onClick={() => {
            if (!isMenuOpen) setIsMenuOpen(true)
          }}
        > 
        </Input>
      </div>
      {isMenuOpen ? (
        <CustomMenu
          data={parsedData}
          currentSelected={currentSelected}
          isAllSelected={currentSelected.length === data.length}
          isMenuOpen={isMenuOpen}
          handleModalClickOutside={handleModalClickOutside}
          handleCheck={handleCheck}
          onClear={() => currentSelected.length ? setCurrentSelected([]) : null}
          onCancel={handleCancel}
          onSubmit={() => {
            setIsMenuOpen(false)
            setSelectedCustomersList(currentSelected)

            let newParameters = cloneDeep(parameters);
            newParameters.customer_keys = selectedCustomerKey
            setParameters(newParameters);

            onSubmit && onSubmit()
          }}
          disableAllOption
        />
      ) : null}
    </div>
  )
}

export default ScheduledReportCustomerSelector