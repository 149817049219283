import baseApi from "./BaseApi"

class WarningApi {
  getRecentWarnings = (
    assetId: number,
    daysRange: number | null,
    offset: number,
    limit: number,
    allWarnings: boolean,
    filter: any
  ) => {
    let queryString = `rivata/warning?asset_id=${assetId}&limit=${limit}&offset=${offset}`

    let warningStatusQuery = filter.warning_status.map((w: { id: any; }) => w.id).join(':');
    let sensorTypeQuery = filter.sensor_type.map((w: { id: any; }) => w.id).join(':');
    let warningTypeQuery = filter.warning_type.map((w: { id: any; }) => w.id).join(':');
    let positionTypeQuery = filter.position_type.map((w: { id: any; }) => w.id).join(':');

    if (daysRange) {
      queryString += `&days=${daysRange}`
    }
  
    if (allWarnings) {
      queryString += "&all_warnings=true"
    }
  
    if (filter) {
      queryString += `&warning_status=${warningStatusQuery}&sensor_type=${sensorTypeQuery}&warning_type=${warningTypeQuery}&position_type=${positionTypeQuery}`
    }
  
    return baseApi.get(queryString, false)
  }


  postAcknowledgements = (acknowledgements: any) => {
    return baseApi.post(`rivata/warning/acknowledge`, acknowledgements)
  }


  postWarningsCancel = (body: any) => {
    return baseApi.post(`rivata/warning/cancel`, body)
  }
}

const warningApi = new WarningApi()

export default warningApi