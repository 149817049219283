import React, { useCallback, useEffect, useRef, useState } from "react";
import { debounce } from "lodash";
import { AnyObject } from "yup/lib/types";

import { UnitsOfMeasurement } from "../../enums";

import GeofenceRenderer from "../../modules/Geofences/renderer/GeofenceRenderer";

import { IGpsData } from "./types";
import {
  initMap,
  addLocationButton,
  startMarkersClustering,
  updateBaseLayer,
  composeBubble,
  handleLatestLocationZoom,
} from "./mapUtils";
import { bboxAll } from "../RivataMapCluster/utils";

import "./styles.scss";

interface IRivataMap {
  vin: string;
  locations: Array<IGpsData>;
  layerType: string;
  unitsOfMeasurement?: string;
  geofencesVisible: boolean;
  geofences: Array<IGeofence>;
  isWarningDetailsPage?: boolean;
  mapZoomBounds?: any,
  onMapViewChange: Function
  size?: any //only for calling resize on change
}

const RivataMap: React.FC<IRivataMap> = ({
  locations,
  layerType,
  unitsOfMeasurement = UnitsOfMeasurement.imperial,
  geofencesVisible,
  geofences,
  isWarningDetailsPage = false,
  mapZoomBounds,
  onMapViewChange,
  size,
}) => {
  const mapRef = useRef<any>();

  const [hMap, setHMap] = useState<any>(null);
  const [ui, setUi] = useState(null);
  const [bubble, setBubble] = useState<any>(null);
  const [defaultLayers, setDefaultLayers] = useState<AnyObject>({});
  const [markersLayer, setMarkersLayer] = useState<any>(null);
  const [initialBoundsZoom, setInitialBoundsZoom] = useState({zoom: null, bounds: null});  

  
  const onMapViewChangeEvent = useCallback((ev: any) => {
    if (onMapViewChange && ev["newValue"]["lookAt"]){
      const bounds =  ev["newValue"]["lookAt"]["bounds"].getBoundingBox()
      const zoom = ev["newValue"]["lookAt"]["zoom"]
   
      onMapViewChange(bounds, zoom);
    }
  }, [onMapViewChange])

  const addInfoBubble = useCallback((e: any) => {
    if (!bubble) return;
    
    composeBubble(e, bubble, unitsOfMeasurement);
  }, [bubble, unitsOfMeasurement]);

  useEffect(() => {
    // map initialization
    const { defaultLayers, hMap, ui, bubble } = initMap(
      mapRef.current,
      unitsOfMeasurement
    );

    setHMap(hMap);
    setDefaultLayers(defaultLayers);
    setUi(ui);
    setBubble(bubble);
  }, [unitsOfMeasurement]);

  useEffect(() => {
    if (!ui) return;
    // create invisible markers and group them for zoom function(zoom on data bounding box on datachange and on btn click)
    const group = new H.map.Group();
    const markersList: Array<H.map.Object> = []

    locations.forEach((loc) => {
      const marker = new H.map.Marker(
        { lat: loc.latitude, lng: loc.longitude },
      );

      marker.setVisibility(false)
      markersList.push(marker)
    })

    group.addObjects(markersList);
    hMap.addObject(group);

    let bbox = group.getBoundingBox();
    
    if (bbox) {
      // get geo bounding box for the group and set it to the map
      bbox = bboxAll(bbox, 0.5);

      addLocationButton(hMap, ui, bbox);
      handleLatestLocationZoom(hMap, bbox)
    }
  }, [hMap, ui, locations]);

  useEffect(() => {
    if (!hMap) return;

    // set zoom on screen size change
    handleLatestLocationZoom(hMap, null, initialBoundsZoom)
  }, [hMap, initialBoundsZoom])

  useEffect(() => {
    updateBaseLayer(layerType, hMap, defaultLayers);
  }, [layerType, hMap, defaultLayers]);

  useEffect(() => {
    if (markersLayer) hMap.removeLayer(markersLayer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations, hMap]);

  useEffect(() => {
    if (mapZoomBounds){
      const { zoom, bounds } = mapZoomBounds;
      if (zoom && bounds) {
        setInitialBoundsZoom({ zoom, bounds })
      }
    }
  }, [mapZoomBounds]);

  useEffect(() => {
    if (hMap){
      hMap.addEventListener('mapviewchange', debounce(onMapViewChangeEvent, 200, {leading: false}));
    }
    return () => {
      if(hMap){
        hMap.removeEventListener('mapviewchange', onMapViewChangeEvent);
      }
    }
  }, [hMap, onMapViewChangeEvent]);


  useEffect(() => {
    if (!hMap || !locations.length) return;

    const clusteringLayer = startMarkersClustering(
      locations,
      hMap,
      isWarningDetailsPage,
      addInfoBubble
    );

    setMarkersLayer(clusteringLayer);
  }, [locations, hMap, isWarningDetailsPage, addInfoBubble]);

  useEffect(() => {
    if (!hMap) return

    hMap.getViewPort().resize()
  }, [hMap, size])

  return (
    <div
      className="map"
      id="rivata-map"
      ref={mapRef}
      style={{ height: "400px", width: "100%" }}
    >
      <GeofenceRenderer
        map={hMap}
        ui={ui}
        geofences={geofences}
        geofencesVisible={geofencesVisible}
        shouldAddCenterMarkers={true}
        // vin={vin}
      />
    </div>
  );
};

export default RivataMap;
