import React, { useMemo, useContext, useState } from 'react'

import RivataModule from '../../components/RivataModule'
import RivataLineChart from '../../components/RivataLineChart'
import RivataLoader from '../../components/RivataLoader'
import StatusAlert from '../../components/StatusAlert'

import { UnitsOfMeasurement } from "../../enums"

import { composeChartData, composeAnnotations, composeDefaultChartData } from './utils'
import { composeCustomTooltip } from "../../utils/chartUtils"
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { AssetDetailsContext } from '../../pages/AssetDetails'

import "./styles.scss"
import RivataToggle from '../../components/RivataToggle'

const id = "axle_load"

const tooltip = composeCustomTooltip({
    displayLabelColors: false,
    bgColor: true,
    beforeBody: true,
    beforeLabel: true,
    afterLabel: true,
    footer: true,
})

const annotations = composeAnnotations(id)

const viewTypes = {
    default: { id: "default", label: "Default View" },
    admin: { id: "admin", label: "Admin View" },
}

const AxleLoadChart = ({
    width,
}) => {
    const {
        axleLoad: { isLoading, data, calibrationsCount, status }
    } = useTypedSelector(state => ({
        axleLoad: state.assetDetails.axleLoad
    }))

    const {
        locale,
        unitsOfMeasurement, 
        chartsXAxisLabel,
        isSuperAdmin
    } = useContext(AssetDetailsContext)

    const [viewType, setViewType] = useState(viewTypes.default)

    const chartData = useMemo(() => {
        if (!data.length || (viewType.id === viewTypes.admin.id && (!calibrationsCount || calibrationsCount < 2))) return null
        if (viewType.id === viewTypes.default.id) return composeDefaultChartData(data, unitsOfMeasurement)
        else if (viewType.id === viewTypes.admin.id) return composeChartData(data, calibrationsCount, unitsOfMeasurement)

        return null
    }, [data, calibrationsCount, unitsOfMeasurement, viewType])

    const yAxisLabel = useMemo(() => {
        let units = { imperial: "psi", metric: "bar" }

        if (viewType.id === "admin") units = { imperial: "lbs", metric: "kg" }

        return `Axle Load (${unitsOfMeasurement === UnitsOfMeasurement.imperial ? units.imperial : units.metric})`
    }, [unitsOfMeasurement, viewType])

    return (
        <RivataModule
            fullScreenModalModeEnabled
            title="Axle Load"
            width={width}
            locale={locale}
            error={status}
            collapsible
            filters={isSuperAdmin && (
                <RivataToggle
                    item1={viewTypes.default}
                    item2={viewTypes.admin}
                    selectedId={viewType.id}
                    onToggle={setViewType}
                />
            )}
        >
            {isLoading ? (
                <RivataLoader/>
            ) : (!calibrationsCount || calibrationsCount < 2) && viewType.id === "admin" ? (
                <StatusAlert customText="Not enough calibration data to populate chart. please provide more weight data for calibration" color="success"/>
            ) : chartData ? (
                <RivataLineChart
                    id={id}
                    data={chartData}
                    height={400}
                    tooltip={tooltip}
                    yAxisLabel={yAxisLabel} 
                    annotations={annotations}
                    yMin={chartData.yMin}
                    yMax={chartData.yMax}
                    xAxisLabel={chartsXAxisLabel}
                />
            ) : (
                <StatusAlert customText="No Data" color="success"/>
            )}
        </RivataModule>
    )
}

export default AxleLoadChart