import React from "react"
import { Row } from "reactstrap"
import * as Yup from "yup"
import { FormField } from "../../components/CreateModal"
import CreateModal from "../../components/CreateModal/children"
import { IAssetGroupFormValues } from "./types"

interface IgetSchema {
  (): Yup.AnyObjectSchema
}

const getSchema: IgetSchema = () => {
  const schemaObject = {
    name: Yup.string().required("Required")
  }
 
  return Yup.object().shape(schemaObject)
}

interface IAssetGroupModal {
  title: string
  locale: ILocale
  initialValues: any
  disabled: boolean
  open: boolean | undefined
  onClose: undefined | (() => void )
  onCreate: (c: IAssetGroupFormValues) => void
}

const AssetGroupModal: React.FC<IAssetGroupModal> = ({
  locale,
  onCreate,
  initialValues,
  title,
  disabled,
  open = undefined,
  onClose = undefined,
}) => {
  return (
    <CreateModal
      disabled={false}
      btnClassName="btn-primary ml-3"
      onCreate={onCreate}
      createBtnLabel={title === "Create" ? "Create and Assign Assets" : title}
      openBtnLabel={`${title} Asset Group`}
      header={`${title} Asset Group`}
      locale={locale}
      schema={getSchema()}
      initialValues={initialValues}
      onClose={onClose}
      open={open}
      resetFormValues={undefined}
      footerBtnDisabled={disabled}
      validate={null}
    >
      <Row className="p-t-0">
        <FormField name="name" label="Asset Group Name" disabled={disabled} />
      </Row>
    </CreateModal>
  );
};

export default AssetGroupModal;
