import moment from "moment"
import { MenuItemType } from "../../componentsV2/Menu/MenuItem"


export const getGpsDropdownItems = (timezone: string): Array<MenuItemType> => {
    const now = moment().tz(timezone) 
        const todayStart = moment(now).startOf('day').unix()
        const todayEnd = moment(now).endOf('day').unix()
        const yesterdayStart = moment(now).subtract(1, 'day').startOf('day').unix()
        const yesterdayEnd = moment(now).subtract(1, 'day').endOf('day').unix()
        const weekAgo = moment(now).subtract(7, 'days').startOf('day').unix()
        const twoWeeksAgo = moment(now).subtract(14, 'days').startOf('day').unix()
        const monthAgo = moment(now).subtract(30, 'days').startOf('day').unix()

        return [
            { id: `${todayStart}_${todayEnd}`, label: "Today", type: "radio", markType: "circle", checked: false, parentId: null },
            { id: `${yesterdayStart}_${yesterdayEnd}`, label: "Yesterday", type: "radio", markType: "circle", checked: false, parentId: null },
            { id: `${weekAgo}_${todayEnd}`, label: "Last 7 Days", type: "radio", markType: "circle", checked: false, parentId: null },
            { id: `${twoWeeksAgo}_${todayEnd}`, label: "Last 14 Days", type: "radio", markType: "circle", checked: false, parentId: null },
            { id: `${monthAgo}_${todayEnd}`, label: "Last 30 Days", type: "radio", markType: "circle", checked: false, parentId: null },
            { id: `${0}_${monthAgo}`, label: "Older than 30 days", type: "radio", markType: "circle", checked: false, parentId: null },
        ]
}