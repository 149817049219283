import React, { useEffect, useState } from "react";
import RivataModule from "../../components/RivataModule";

import "./styles.scss";
import AssetCard from "../../components/AssetCard";
import RivataLoader from "../../components/RivataLoader";

import circleCritWarn from '../../assets/images/icons/svg/circle_crit_warn.svg';
import circleWarn from '../../assets/images/icons/svg/circle_warn.svg';
import circleOk from '../../assets/images/icons/svg/circle_ok.svg';
import insideGeofenceIcon from '../../assets/images/icons/svg/ICON_Geofence_Inside.svg';
import outsideGeofenceIcon from '../../assets/images/icons/svg/ICON_Geofence_Outside.svg';
import smarthubIcon from '../../assets/images/icons/svg/ICON_SmartHub.svg';
import tpmsIcon from '../../assets/images/icons/svg/ICON_TPMS.svg';
import linePressureIcon from '../../assets/images/icons/svg/ICON_Line_Pressure.svg';
import axleLoadIcon from '../../assets/images/icons/svg/ICON_Axle_Load.svg';
import { isEmpty } from "lodash";

interface Props {
  locale: Record<string, string>;
  width: number;
  data: {
    trailers_count: number;
    tractors_count: number;
    inside_geofence_count: number;
    outside_geofence_count: number;
    gateways_sensor_assets_count: number;
    smarthub_sensor_assets_count: number;
    tpms_sensor_assets_count: number;
    line_pressure_sensor_assets_count: number;
    axle_load_sensor_assets_count: number;
    normal_state_assets_count: number;
    warning_state_assets_count: number;
    critical_warning_state_assets_count: number;
  };
  isLoading: boolean;
  availableCustomersSubscriptions: any
}

const FleetSummaryModule: React.FC<Props> = ({ locale, width, data, isLoading, availableCustomersSubscriptions }) => {
  const [sensorsData, setSensorsData] = useState<any>([])

  useEffect(() => {
    if (!availableCustomersSubscriptions) {
      return
    }
    const sD = []
    if (availableCustomersSubscriptions.valid_smarthub_subscription) {
      sD.push({ icon: smarthubIcon, label: "Smarthub", value: data.smarthub_sensor_assets_count })
    }
    if (availableCustomersSubscriptions.valid_tpms_subscription) {
      sD.push({ icon: tpmsIcon, label: "TPMS", value: data.tpms_sensor_assets_count })
    }
    if (availableCustomersSubscriptions.valid_line_pressure_subscription) {
      sD.push({ icon: linePressureIcon, label: "Line Pressure", value: data.line_pressure_sensor_assets_count })
    }
    if (availableCustomersSubscriptions.valid_axle_load_subscription) {
      sD.push({ icon: axleLoadIcon, label: "Axle Load", value: data.axle_load_sensor_assets_count })
    }
    setSensorsData(sD)

  }, [availableCustomersSubscriptions, data, setSensorsData])

  return (
    <RivataModule
      title="Fleet Summary"
      width={width}
      marginTop={4}
      locale={locale}
      filters={null}
      customClass="custom-module-style"
    >
      {isLoading || isEmpty(data) ? <RivataLoader /> : <>
        <div className="fleet-summary-container">
          <AssetCard
            label="Total Assets by Type"
            items={{
              data: [
                { label: "Tractors", value: data.tractors_count },
                { label: "Trailers", value: data.trailers_count },
                { label: "All", value: data.tractors_count + data.trailers_count }
              ]
            }}
          />
          <AssetCard
            label="Assets by Sensor Type"
            items={{
              data: sensorsData
            }}
          />
          {/* <AssetCard
            label="Card 3"
          /> */}
        </div>
        <div className="fleet-summary-container">
          <AssetCard label="Asset Health"
            items={{
              data: [
                { icon: circleOk, label: "Normal", value: data.normal_state_assets_count },
                { icon: circleWarn, label: "Warning", value: data.warning_state_assets_count },
                { icon: circleCritWarn, label: "High severity", value: data.critical_warning_state_assets_count }
              ]
            }}
          />
          <AssetCard
            label="Assets in Geofence"
            items={{
              data: [
                { icon: insideGeofenceIcon, label: "Inside", value: data.inside_geofence_count },
                { icon: outsideGeofenceIcon, label: "Outside", value: data.outside_geofence_count }
              ]
            }}
          />
          {/* <AssetCard
            label="Card 6"
          /> */}
        </div>
      </>}

    </RivataModule>
  );
};

export default FleetSummaryModule;
