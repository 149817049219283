import React, { useEffect, useState } from 'react'
import { Nav, NavItem, NavLink } from 'reactstrap'
import { getCurrentEpoch } from '../../../utils'

interface Props {
  isSuperAdmin: boolean
  activeTab: string
  customerDefaults: any
  toggleTab: (tab: string) => void
  isAdmin: boolean
}

const ProvisionTabs: React.FC<Props> = ({
  isSuperAdmin,
  activeTab,
  customerDefaults,
  toggleTab,
  isAdmin,
}) => {
  const [shouldAssetRender, setShouldAssetRender] = useState(true)
  const { subscriptions, cargo_tags_subscription } = customerDefaults

  useEffect(() => {
    if (
      !isSuperAdmin &&
      cargo_tags_subscription &&
      (!subscriptions || Object.keys(subscriptions).length === 0)
    ) {
      setShouldAssetRender(false)
      toggleTab('users')
    }
  }, [isSuperAdmin, subscriptions, cargo_tags_subscription, toggleTab])

  return (
    <Nav tabs className='ml-3 mr-3'>
      {shouldAssetRender && (
        <NavItem className='tab-item'>
          <NavLink
            className={`assets_tab ${
              activeTab === 'assets' ? 'active-table_tab' : ''
            }`}
            onClick={() => {
              toggleTab('assets')
            }}
          >
            Assets
          </NavLink>
        </NavItem>
      )}
      {(subscriptions?.TIRE_SENSOR &&
        subscriptions.TIRE_SENSOR.valid_to >= getCurrentEpoch()) ||
      isSuperAdmin ? (
        <NavItem className='tab-item'>
          <NavLink
            className={`assets_tab ${
              activeTab === 'tpmsAssets' ? 'active-table_tab' : ''
            }`}
            onClick={() => {
              toggleTab('tpmsAssets')
            }}
          >
            TPMS Assets
          </NavLink>
        </NavItem>
      ) : null}

      <NavItem className='tab-item'>
        <NavLink
          className={`assets_tab ${
            activeTab === 'users' ? 'active-table_tab' : ''
          }`}
          onClick={() => {
            toggleTab('users')
          }}
        >
          Users
        </NavLink>
      </NavItem>
      {isAdmin && (
        <NavItem className='tab-item'>
          <NavLink
            className={`assets_tab ${
              activeTab === 'assetsBulkUploadHistory' ? 'active-table_tab' : ''
            }`}
            onClick={() => {
              toggleTab('assetsBulkUploadHistory')
            }}
          >
            Assets Bulk Upload History
          </NavLink>
        </NavItem>
      )}
    </Nav>
  )
}

export default React.memo(ProvisionTabs)
