import React, { useState } from 'react';
import { Row, Col, Label } from 'reactstrap';
import * as Yup from "yup";
import CreateModal from "../../../components/CreateModal/children";
import CustomersDropdown from '../../../components/CustomersDropdown';

const LocationGatewaySchema = Yup.object().shape({
    new_customer: Yup.string().required()
});

const ElementMigrateModal = ({
    locale = {},
    onCreate,
    disabled,
    initialValues,
    open,
    onClose,
    currentCustomerName,
    elementName,
    isLoading,
    elementType
}) => {
    const [newCustomer, setNewCustomer] = useState({...initialValues})
    return (
        <CreateModal
            variant="primary"
            disabled={disabled}
            className="ml-3"
            btnClassName="ml-3"
            onCreate={() => {
                return onCreate(newCustomer)
            }}
            createBtnLabel={"Save"}
            header={`Migrate ${elementType}: ${elementName}`}
            locale={locale}
            schema={LocationGatewaySchema}
            initialValues={initialValues}
            onClose={onClose}
            open={open}
            isLoading={isLoading}
        >
            Current customer: <b>{currentCustomerName}</b>
            <Row className="p-t-0">
                <Col>
                    <Label className="mt-2">Migrate to customer:</Label>
                    <br/>
                    <CustomersDropdown
                        name="new_customer"
                        locale={locale}
                        onChange={(customer) => setNewCustomer({ 
                            new_customer: customer.key, 
                            new_customer_name: customer.name 
                        })}
                        shouldRemoveAllOption={true}
                        customSelectedCustomerName={currentCustomerName}
                        ml={"ml-0"}
                    />
                </Col>
            </Row>
        </CreateModal>
    );
};

export default ElementMigrateModal;