import React from 'react'
import { ReportParameterGroupProps } from './ReportParameterGroupProps'
import CustomerRow from '../ReportParametersRows/CustomerRow'
import TimeZoneRow from '../ReportParametersRows/TimeZoneRow'

const InternalConmetDailyReportParameters: React.FC<
  ReportParameterGroupProps
> = (props: ReportParameterGroupProps) => {
  return (
    <>
      <CustomerRow value={props.parameters.customer_keys} />
      <TimeZoneRow value={props.parameters.timezone} />
    </>
  )
}
export default InternalConmetDailyReportParameters
