import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { TabContent, TabPane, Row } from 'reactstrap'

import SubNav from '../../components/SubNav'
import ReportControls from '../../modules/ReportControls'
import ReportGrid from '../../modules/ReportGrid'
import CustomerSelector from '../../modules/CustomerSelector'
import ReportTabs from './components/ReportTabs'

import { useTypedSelector } from '../../hooks/useTypedSelector'
import { useActions } from '../../hooks/useActions'
import { useQuery } from '../../hooks/useQuery'
import ScheduledReportsGrid from '../../modules/ScheduledReportsGrid'
import { getClearLabel, sortByAlphabet } from '../../utils'
import { reportAccessFilter, tagReportAccessFilter } from './utils'
import ReportsHistoryGrid from '../../modules/ReportsHistoryGrid'

const Reports = () => {
  const {
    selectedReportType,
    locale,
    auth: {
      isSuperAdmin,
      isAdmin,
      preferences: { permissions },
    },
    dropdown,
    selectedCustomersList,
    customerDefaults: { subscriptions },
  } = useTypedSelector((state) => ({
    selectedReportType: state.reports.report.selectedReportType,
    locale: state.whitelabel.locale,
    auth: state.auth,
    dropdown: state.tagLocations.dropdown,
    selectedCustomersList: state.common.customers.selectedCustomersList,
    customerDefaults: state.common.customerDefaults,
  }))

  const { fetchTagsLocationForDropdown, fetchScheduledReports } = useActions()
  const [dateRangeString, setDateRangeString] = useState('')
  const [customerSelectDisabled, setCustomerSelectDisabled] = useState(false)
  const [isAll, setIsAll] = useState(false)
  const [activeTab, setActiveTab] = useState('reports')
  const history = useHistory()
  const location = useLocation()
  const params = useQuery()

  const onCustomerSelect = () => {
    fetchTagsLocationForDropdown()
    fetchScheduledReports()
  }

  const breadcrumbs = useMemo(() => {
    return [{ label: 'Home', path: '/dashboard' }, { label: 'Reports' }]
  }, [])

  const setTabParam = useCallback(
    (tab) => {
      history.push({
        pathname: location.pathname,
        search: `?tab=${tab}`,
      })
    },
    [history, location.pathname],
  )

  useEffect(() => {
    const paramsTab = params.get('tab')

    if (!paramsTab) setTabParam(activeTab)

    if (
      [
        'reports',
        'tagReports',
        'scheduledReports',
        'internalScheduledReports',
        'reportsHistory',
      ].includes(paramsTab) &&
      paramsTab !== activeTab
    ) {
      setActiveTab(paramsTab)
    }

    if (paramsTab === 'internalScheduledReports') {
      setCustomerSelectDisabled(true)
    } else {
      setCustomerSelectDisabled(false)
    }
  }, [params, activeTab, setTabParam])

  const toggleTab = useCallback(
    (tab) => {
      setActiveTab(tab)
      setTabParam(tab)
    },
    [setTabParam],
  )
  const hasCargoTagSubscription = useMemo(() => {
    return selectedCustomersList.some((el) => el.cargo_tags_subscription)
  }, [selectedCustomersList])
  useEffect(() => {
    if (!hasCargoTagSubscription && activeTab === 'tagReports') {
      toggleTab('reports')
    }
  }, [hasCargoTagSubscription, toggleTab, activeTab])
  const reportItems = useMemo(() => {
    const accessedReports = reportAccessFilter(
      isSuperAdmin,
      isAdmin,
      subscriptions,
      permissions,
    )

    const items = accessedReports.map((el) => ({
      id: el,
      label: getClearLabel(el),
    }))

    sortByAlphabet(items, 'label')

    return items
  }, [isSuperAdmin, isAdmin, subscriptions, permissions])
  const tagReportItems = useMemo(() => {
    const accessedReports = tagReportAccessFilter(
      isSuperAdmin,
      hasCargoTagSubscription,
    )

    const items = accessedReports.map((el) => ({
      id: el,
      label: getClearLabel(el),
    }))

    sortByAlphabet(items, 'label')

    return items
  }, [isSuperAdmin, hasCargoTagSubscription])
  return (
    <>
      <SubNav title='Reports' breadcrumbs={breadcrumbs}>
        {!customerSelectDisabled && (
          <CustomerSelector onSubmit={onCustomerSelect} />
        )}
      </SubNav>
      <div className='tabs-wrapper'>
        <ReportTabs
          isSuperAdmin={isSuperAdmin}
          activeTab={activeTab}
          toggleTab={toggleTab}
          hasCargoTagSubscription={hasCargoTagSubscription}
        />
        <TabContent activeTab={activeTab} className='ml-3 mr-3 bg-transparent'>
          <TabPane tabId='reports'>
            <Row>
              {activeTab === 'reports' && (
                <>
                  <ReportControls
                    isSuperAdmin={isSuperAdmin}
                    locale={locale}
                    selectedReport={selectedReportType}
                    setDateRangeString={setDateRangeString}
                    isAll={isAll}
                    setIsAll={setIsAll}
                    dropdown={dropdown}
                    reportsDropdownItems={reportItems}
                  />
                  <ReportGrid
                    selectedReport={selectedReportType}
                    locale={locale}
                    dateRangeString={!isAll ? dateRangeString : ''}
                  />
                </>
              )}
            </Row>
          </TabPane>
          {hasCargoTagSubscription ? (
            <TabPane tabId='tagReports'>
              <Row>
                {activeTab === 'tagReports' && (
                  <>
                    <ReportControls
                      isSuperAdmin={isSuperAdmin}
                      locale={locale}
                      selectedReport={selectedReportType}
                      setDateRangeString={setDateRangeString}
                      isAll={isAll}
                      setIsAll={setIsAll}
                      dropdown={dropdown}
                      reportsDropdownItems={tagReportItems}
                    />
                    <ReportGrid
                      selectedReport={selectedReportType}
                      locale={locale}
                      dateRangeString={!isAll ? dateRangeString : ''}
                    />
                  </>
                )}
              </Row>
            </TabPane>
          ) : null}
          {isSuperAdmin ? (
            <TabPane tabId='internalScheduledReports'>
              <Row>
                {activeTab === 'internalScheduledReports' && (
                  <ScheduledReportsGrid internalReports={true} />
                )}
              </Row>
            </TabPane>
          ) : (
            ''
          )}
          <TabPane tabId='scheduledReports'>
            <Row>
              {activeTab === 'scheduledReports' && (
                <ScheduledReportsGrid internalReports={false} />
              )}
            </Row>
          </TabPane>
          <TabPane tabId='reportsHistory'>
            <Row>{activeTab === 'reportsHistory' && <ReportsHistoryGrid />}</Row>
          </TabPane>
        </TabContent>

      </div>
    </>
  )
}

export default Reports
