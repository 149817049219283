import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Input } from "reactstrap"
import moment from "moment"
import RivataMenu from "../RivataMenu"
import "./styles.scss"
import { ReactComponent as Clock } from "./clock.svg"
import { FixedSizeList } from "react-window"
import {
  dropdownPos,
  getDropdownItems,
  inputToValue,
  valueToLabel,
} from "./utils"

export type IAllowedRange = {
  min: string
  max: string
}

type Props = {
  value: string
  setValue: (value: string) => void
  use12Hours?: boolean
  useSeconds?: boolean
  dropdownItemsStep?: number
  restrictToStep?: boolean
  allowedRange?: IAllowedRange
  onDropdownOpen?: () => void
  disabled?: boolean
}

const TimeInput: React.FC<Props> = ({
  value,
  setValue,
  use12Hours = true,
  useSeconds,
  dropdownItemsStep = 15, // 1-60 minutes step for generating dropdown items, best to select number that divides 60 without remainder
  restrictToStep, // allows to select only values listed in dropdown, inputed values will be rounded down
  allowedRange,
  onDropdownOpen,
  disabled,
}) => {
  const inputRef = useRef<HTMLDivElement>(null)
  const listRef = useRef<FixedSizeList<any>>(null)

  const [inputString, setInputString] = useState("")
  const [isInvalidInput, setIsInvalidInput] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const step = dropdownItemsStep > 0 && dropdownItemsStep < 61 ? dropdownItemsStep : 15

  const range = useMemo(() => {
    if (!allowedRange) return null

    const momentMin = moment(allowedRange.min, "HH:mm")
    let momentMax

    if (allowedRange.max !== "24:00")
      momentMax = moment(allowedRange.max, "HH:mm")
    else momentMax = moment("23:59", "HH:mm")

    return { min: momentMin, max: momentMax }
  }, [allowedRange])

  const dropdownItems = useMemo(() => {
    return getDropdownItems(step, range, use12Hours, useSeconds)
  }, [step, range, use12Hours, useSeconds])

  const scrollDropdown = useCallback(
    (value: string) => {
      if (!listRef.current) return

      listRef.current.scrollToItem(dropdownPos(value, step, range), "center")
    },
    [step, range]
  )

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputString(event.target.value)

    const newValue = inputToValue(
      event.target.value,
      use12Hours,
      useSeconds,
      restrictToStep,
      step,
      range
    )

    if (newValue || event.target.value === "") {
      setIsInvalidInput(false)
      scrollDropdown(newValue ?? value)
    } else setIsInvalidInput(true)
  }

  const handleInputFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select()
    setIsDropdownOpen(true)
  }

  const handleInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const newValue = inputToValue(
      event.target.value,
      use12Hours,
      useSeconds,
      restrictToStep,
      step,
      range
    )

    if (newValue && newValue !== value) {
      setValue(newValue)
    } else {
      setInputString(valueToLabel(value, use12Hours, useSeconds))
    }

    setIsInvalidInput(false)
  }

  const handleDropdownSelect = useCallback(
    (el) => {
      setIsDropdownOpen(false)
      setValue(el.id)
    },
    [setValue]
  )

  useEffect(() => {
    if (isDropdownOpen) scrollDropdown(value)
  }, [value, isDropdownOpen, scrollDropdown])

  useEffect(() => {
    setInputString(valueToLabel(value, use12Hours, useSeconds))
  }, [value, use12Hours, useSeconds])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (inputRef.current && !inputRef.current.contains(event.target as Node))
        setIsDropdownOpen(false)
    }

    document.addEventListener("mousedown", handleClickOutside)

    return () => document.removeEventListener("mousedown", handleClickOutside)
  }, [])

  useEffect(() => {
    if (onDropdownOpen && isDropdownOpen) onDropdownOpen()
  }, [isDropdownOpen, onDropdownOpen])

  return (
    <div ref={inputRef} className="time-input-wrapper">
      <Input
        className={`time-input ${useSeconds ? "use-seconds" : ""} ${
          isInvalidInput ? "invalid" : ""
        }`}
        value={inputString}
        onChange={handleInputChange}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        disabled={disabled}
      />

      <Clock
        className="time-input-icon"
        onClick={() => setIsDropdownOpen(!disabled)}
      />

      {isDropdownOpen && !disabled && (
        <RivataMenu
          data={dropdownItems}
          width={useSeconds ? "110px" : "90px"}
          labelKey="label"
          onClick={handleDropdownSelect}
          listRef={listRef}
        />
      )}
    </div>
  )
}

export default React.memo(TimeInput)
