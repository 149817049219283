const stage = process.env.REACT_APP_STAGE || 'dev'
let hereMapsOauthConfig, clientId, userPoolId, mapApiKey, gaMeasurementId

if (stage === 'prod') {
  hereMapsOauthConfig = require('./hereMapsConfigs/here_maps_oauth_credentials_prod.json')
} else {
  hereMapsOauthConfig = require('./hereMapsConfigs/here_maps_oauth_credentials_dev.json')
}

// TODO: We need to get these values from terraform and added to app
//       via REACT_APP_<VAR> in build spec.
switch (stage) {
  default:
    clientId = '2ln3tu71ileca6favmar5akhgk'
    userPoolId = 'eu-central-1_7c2kjU03F'
    mapApiKey = 'kgWz9GHxVSUNiTpW4fUwtFPKYt4d2qNbpBy6zOLoeeo'
    gaMeasurementId = 'G-XKYNQR2SG8'
    break
}

export default {
  region: 'eu-central-1',
  userPool: userPoolId,
  userPoolBaseUri: `https://bx2021v4-auth-rivata-bx.auth.eu-central-1.amazoncognito.com`,
  clientId: clientId,
  callbackUri: `${window.location.origin}/callback`,
  signoutUri: `${window.location.origin}/logout`,
  tokenScopes: [
    'openid',
    'email',
    'profile',
    `${window.location.origin}/test_scope`,
  ],
  apiUri: `${window.location.origin}`,
  mapApiKey: mapApiKey,
  build_branch: process.env.REACT_APP_BRANCH || 'local',
  build_fullhash:
    process.env.REACT_APP_CODEBUILD_RESOLVED_SOURCE_VERSION || 'local',
  ga_measurement_id: gaMeasurementId,
  hereMapsOauthKey: hereMapsOauthConfig.access_key_id,
  hereMapsOauthSecret: hereMapsOauthConfig.access_key_secret,
  hereMapsOauthUrl: hereMapsOauthConfig.endpoint_url,
}
