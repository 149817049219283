import React, { useState } from "react";
import { Tooltip } from "reactstrap";

interface FakeBtnProps {
  label: string;
  active: boolean;
  icon: string;
  onClickHandler: () => void;
  message?: string;
}
const PseudoBtn: React.FC<FakeBtnProps> = ({
  label,
  active,
  icon,
  onClickHandler,
  message,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };

  return (
    <>
      <div
        className={`btn btn-primary ml-3 ${!active ? "disabled" : ""}`}
        onClick={active ? onClickHandler : () => {}}
        id="tooltip"
      >
        <i className={`mr-2 ${icon}`}></i>
        {label}
      </div>
      {!active && message && (
        <Tooltip
          hideArrow={true}
          placement="top"
          isOpen={tooltipOpen}
          target={"tooltip"}
          toggle={toggleTooltip}
        >
          {message}
        </Tooltip>
      )}
    </>
  );
};

export default PseudoBtn;
