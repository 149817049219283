import { getClearLabel, convertDataEpochToDate } from "../../../utils";
import { IRow } from "../../../components/RivataGrid";

interface ActiveWarningRow {
  parent_account: string;
  customer: string;
  asset_name: string;
  vin: string;
  warning_timestamp: number;
  position: string;
  warning_type: string;
  sensor_type: string;
  description: string;
  asset_in_geofence: string;
  warning_latitude: number;
  warning_longitude: number;
  warning_geofence: string;
  report_datetime: number;
  asset_type: string;
}


export const activeWarningsRows = (rows: Array<IRow>, locale: ILocale, unitsOfMeasurement: string) =>
  rows.map((row) => {
    return {
      ...row,
      parent_account: row.parent_account || "---",
      warning_type: locale[row.warning_type] || row.warning_type,
      sensor_type: row.sensor_type ? getClearLabel(row.sensor_type) : "---",
      position: row.position === "Chassis" ? getClearLabel("Red Supply Line") : getClearLabel(row.position),
      description: row.description || "---",
      asset_in_geofence: row.asset_in_geofence || "---",
      warning_latitude: row.warning_latitude || "---",
      warning_longitude: row.warning_longitude || "---",
      warning_geofence: row.warning_geofence || "---",
      warning_timestamp: convertDataEpochToDate(row.warning_timestamp, null, null, true),
      report_datetime: convertDataEpochToDate(row.report_datetime, null, null, true),
      asset_type: row.asset_type ? getClearLabel(row.asset_type) : "---",
      asset_groups: row.asset_groups || "---",
    };
  });
