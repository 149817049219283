import { useMemo } from 'react'
import { IColumn, IRow } from '../../components/RivataGrid'
import {
  IActionColumnConfig,
  composeDynamicActionsFormatter,
} from '../../components/RivataGrid/cellFormatter'

export const defaultInitialValues: {
  id: number
  name: string
  isDisabled: boolean
  frequency: string
  parameters: any
  type: string
  assetType: string
  preselectedAssets: Array<{ vin: string; name: string }>
  preselectedGeofences: Array<{ id: string; name: string }>
  customerId: null | number
  preselectedUsers: Array<{ id: string; name: string }>
} = {
  id: -1,
  name: '',
  isDisabled: true,
  frequency: '',
  parameters: {},
  type: '',
  assetType: '',
  preselectedAssets: [],
  preselectedGeofences: [],
  customerId: null,
  preselectedUsers: [],
}

export type ScheduledReportFormValues = typeof defaultInitialValues

interface IuseInitialFormValues {
  (reportToEdit: IScheduledReport | null): ScheduledReportFormValues
}

export const useInitialFormValues: IuseInitialFormValues = (reportToEdit) => {
  return useMemo(() => {
    if (!reportToEdit) return defaultInitialValues

    let reportData = {
      id: reportToEdit.id,
      name: reportToEdit.report_name.trim(),
      isDisabled: reportToEdit.is_disabled,
      frequency: reportToEdit.schedule,
      parameters: reportToEdit.parameters,
      type: reportToEdit.report_type_name,
      assetType:
        reportToEdit.parameters && reportToEdit.parameters.asset_type
          ? reportToEdit.parameters.asset_type
          : 'all',
      warningType:
        reportToEdit.parameters && reportToEdit.parameters.warning_type
          ? reportToEdit.parameters.warning_type
          : '',
      preselectedAssets: reportToEdit.preselected_assets,
      preselectedGeofences: reportToEdit.preselected_geofences,
      customerId: reportToEdit.customer_id,
      preselectedUsers: reportToEdit.preselected_users
        ? reportToEdit.preselected_users
        : [],
    }

    return reportData
  }, [reportToEdit])
}

interface IuseNotificationUsersColumns {
  (formValues: any, onDelete: (id: number) => void): Array<IColumn>
}

export const useScheduledReportsUsersColumns: IuseNotificationUsersColumns = (
  formValues,
  onDelete,
) => {
  return useMemo(() => {
    const formatterConfig: IActionColumnConfig = {
      onDelete,
    }

    const columns: Array<IColumn> = [
      {
        name: 'Remove',
        key: 'remove',
        minWidth: 75,
        width: 75,
        formatter: ({ row }: any) =>
          composeDynamicActionsFormatter({ ...formatterConfig, cellData: row }),
      },
      { name: 'Username', key: 'username', minWidth: 100, width: '20%' },
      { name: 'First Name', key: 'first_name', minWidth: 100, width: '15%' },
      { name: 'Last Name', key: 'last_name', minWidth: 100, width: '15%' },
      { name: 'Email', key: 'email', minWidth: 100, width: '30%' },
      { name: 'Email Verified', key: 'email_verified', width: '20%' },
    ]

    columns[columns.length - 1].width = undefined

    return columns
  }, [onDelete])
}

export const useScheduledReportsUsersRows = (
  data: Array<IUserShortFormat> | null,
): Array<IRow> => {
  return useMemo(() => {
    if (!data) return []

    return data.map((row) => {
      return {
        ...row,
        email_verified: row.email_verified.toString(),
        phone_number_verified: row.phone_number_verified.toString(),
      }
    })
  }, [data])
}
