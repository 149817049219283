import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'

import DropdownItemSelector from '../../componentsV2/DropdownItemSelector'
import { MenuItemType } from '../../componentsV2/Menu/MenuItem'

import { reportStatusItems } from './items'

interface Props {
    onDropdownItemSelect?: (id: string, selectedValue: MenuItemType[]) => void
    className?: string
    disabled?: boolean
}

const ReportStatusFilter = forwardRef(({
    onDropdownItemSelect,
    className,
    disabled
}: Props, ref) => {
    const [selectedItems, setSelectedItems] = useState([] as Array<MenuItemType>)
    const [label, setLabel] = useState('Report Status')
    const [allItems, setAllItems] = useState(reportStatusItems)
    const isNewSelectedValueIds = useRef(true)

    const onItemSelect = useCallback((id: string, items: Array<MenuItemType>) => {
        const checked = items.filter(el => el.checked)
        if (onDropdownItemSelect)
            onDropdownItemSelect(id, checked)
        setSelectedItems(checked)
    }, [onDropdownItemSelect])

    useImperativeHandle(ref, () => ({
        clearSelection() {
            isNewSelectedValueIds.current = false
            setSelectedItems([])
            setAllItems(allItems.map((c: any) => {
                return {
                    ...c,
                    checked: false
                }
            }))
        }
    }))

    useEffect(() => {
        setLabel('Report Status' + (selectedItems && selectedItems?.length > 0 ? `: ${selectedItems?.length} selected` : ""))
    }, [selectedItems])

    return (
        <DropdownItemSelector
            id="report_status"
            filterClassName={`mr-2 ${className ?? ""}`}
            filterBtnLabel={label}
            items={allItems}
            onItemsChange={onItemSelect}
            disabled={disabled ? disabled : false}
            clearSelectionOnReset={true}
        />
    )
})

export default React.memo(ReportStatusFilter)
