import React, { useState, useEffect } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink, Row } from 'reactstrap';
import classnames from 'classnames';
import JSONlocale from 'react-json-editor-ajrm/locale/en';

import SubNav from '../../components/SubNav'
import ProvisionAssetJson from '../../modules/ProvisionAssetJson'
import HardwareCommandsTable from '../../modules/HardwareCommandsTable'
import DataMigration from "../../modules/DataMigration";
import ProvisionHistory from "../../modules/ProvisionHistory";
import AdminGeofencesEvents from "../../modules/AdminGeofencesEvents";
import TestHardwareDataGenerationWrapper from "../../modules/TestHardwareDataGeneration";

import SensorInfo from "./modules/SensorInfo";
import AssetSubscriptions from "./modules/AssetSubscriptions";
import AssetInfo from "./modules/AssetInfo";

import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";

import './style.scss'


const tabs = [
    { id: "subscription", label: "Subscriptions", Component: AssetSubscriptions },
    { id: "sensor_info", label: "Sensor Info", Component: SensorInfo },
    { id: "provision_asset", label: "Provision Asset", Component: ProvisionAssetJson },
    { id: "data_migration_tool", label: "Data migration tool", Component: DataMigration },
    { id: "hardware_commands", label: "Hardware Commands", Component: HardwareCommandsTable },
    { id: "provision_history", label: "Provision History", Component: ProvisionHistory },
    { id: "geofences_events", label: "Geofences events", Component: AdminGeofencesEvents },

    ...(process.env.REACT_APP_STAGE !== "prod"
        ? [{ id: "test_hardware_data_generation", label: "Test Hardware Data Generation", Component: TestHardwareDataGenerationWrapper }]
        : []),
]

const AssetAdminInfo = ({
    match: { params },
}) => {
    const locale = useTypedSelector(state => state.whitelabel.locale)
    const { setAssetInfo, fetchAdminInfoPage } = useActions()

    const [activeTab, setActiveTab] = useState(tabs[0].id);

    useEffect(() => {
        fetchAdminInfoPage(params.vin)
    }, [fetchAdminInfoPage, params.vin])


    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    useEffect(() => {
        return () => {
            setAssetInfo({}) // clear info
        }
    }, [setAssetInfo])

    return (
        <>
            <SubNav
                title="Asset Admin Info"
                breadcrumbs={[
                    { label: "Home", path: "/dashboard" },
                    { label: "Asset Details", path: `/details/${params.vin}?reducePoints=true` },
                    { label: "Asset Admin Info" }
                ]}
            />
            <div className="w-100 asset-admin-info">
                <AssetInfo vin={params.vin} />
                <div>
                    <Nav tabs>
                        {tabs.map(tab => {
                            return (
                                <NavItem key={tab.id} >
                                    <NavLink
                                        className={classnames({ active: activeTab === tab.id, "active-tab__style": activeTab === tab.id })}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => { toggle(tab.id); }}
                                    >
                                        {tab.label}
                                    </NavLink>
                                </NavItem>
                            )
                        })}
                    </Nav>
                </div>
                <TabContent activeTab={activeTab}>
                    {tabs.map(tab => {
                        const Component = tab.Component

                        const props = ["subscription", "sensor_info"].includes(tab.id) ? { JSONlocale: JSONlocale } : {}

                        return (
                            <TabPane key={tab.id} tabId={tab.id}>
                                {activeTab === tab.id && (
                                    <Row>
                                        <Component
                                            locale={locale}
                                            vin={params.vin}
                                            {...props}
                                        />
                                    </Row>
                                )}
                            </TabPane>
                        )
                    })}
                </TabContent>
            </div>
        </>
    );
};

export default AssetAdminInfo;
