import { IRow } from '../../../components/RivataGrid'
import { convertDataEpochToDate } from '../../../utils'

export const driverAppTripReportRows = (rows: Array<IRow>) => {
  return rows.map((row) => {
    row.start_timestamp = row.start_timestamp
      ? convertDataEpochToDate(row.start_timestamp, null, null, true)
      : '---'
    row.end_timestamp = row.end_timestamp
      ? convertDataEpochToDate(row.end_timestamp, null, null, true)
      : '---'

    return row
  })
}
