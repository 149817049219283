import React, { useEffect, useState } from "react"
import { Prompt, useHistory } from "react-router-dom"
import { Location } from "history"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"

interface Props {
  when?: boolean
}

const RouteLeavingGuard = ({ when = true }: Props) => {
  const history = useHistory()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [location, setLocation] = useState<Location | null>(null)
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)

  const toggleModal = () => {
    setIsModalOpen((isOpen) => !isOpen)
  }

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation) {
      setIsModalOpen(true)
      setLocation(nextLocation)
      return false
    }
    return true
  }

  const onConfirm = () => {
    setIsModalOpen(false)
    setConfirmedNavigation(true)
  }

  useEffect(() => {
    if (confirmedNavigation && location) {
      history.push(location.pathname)
    }
  }, [confirmedNavigation, location, history])

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />

      <Modal isOpen={isModalOpen} toggle={toggleModal} centered>
        <ModalHeader toggle={toggleModal}>Unsaved changes</ModalHeader>

        <ModalBody>
          Are you sure you want to leave this page? You currently have unsaved
          changes.
        </ModalBody>

        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>

          <Button color="primary" onClick={onConfirm}>
            Continue
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default RouteLeavingGuard
