import React, { useState, useEffect, useCallback } from 'react'
import moment from 'moment'
import { Formik } from 'formik'
import { Form, Row, Col, Button } from 'reactstrap'
import * as Yup from 'yup'
import RivataDropdown from '../RivataDropdown'
import UnitsOfMeasureToggle from '../UnitsOfMeasureToggle'
import RivataFormField from '../../components/RivataFormField'
import InfoModal from '../../components/InfoModal'
import { DateInput } from '../../components/DateRangeCalendar'
import PickTime from '../TimePicker'
import { UnitsOfMeasurement } from '../../enums'
import {
    getCurrentEpoch,
    lbsToKg,
    dateToEpoch
} from '../../utils'
import { useTypedSelector } from "../../hooks/useTypedSelector";
import './style.scss'


const schema = Yup.object({
    vehicle_weight: Yup.number().required("This field is required").positive("Value must be positive").max(99999999, "Value is too big")
})

const initialValues = {
    vehicle_weight: ""
}

const dropdownItems = [
    { id: "empty", label: "Empty" },
    { id: "loaded", label: "Loaded" }
]

const AxleLoadCalibrationForm = ({
    unitsOfMeasure,
    onChangeUnitsOfMeasure,
    onSubmit,
    selectedAsset
}) => {
    const {timezone} = useTypedSelector(state => ({timezone: state.auth.preferences.timezone}))

    const [ vehicleState, setVehicleState ] = useState("empty")
    const [ dateStart, setDateStart ] = useState(new Date())
    const [ timeStart, setTimeStart ] = useState(moment(getCurrentEpoch() * 1000).tz(timezone).format(`HH:mm`))

    const [ statusMessage, setStatusMessage ] = useState(null)
    const [ minCalendarDate, setMinCalendarDate ] = useState(null)


    useEffect(() => {
        if (!selectedAsset?.subscription) return

        setMinCalendarDate(
            selectedAsset.subscription.AXLE_LOAD?.valid_from 
                ? selectedAsset.subscription.AXLE_LOAD.valid_from 
                : 946688400
        )
    }, [selectedAsset])

    const setDefaultForm = useCallback(() => {
        setVehicleState("empty")
        setDateStart(new Date())
        setTimeStart(moment(getCurrentEpoch() * 1000).tz(timezone).format(`HH:mm`))
    }, [timezone])


    return (
        <div className="axle-load-calibration-form" >
            <InfoModal header={"Calibration Info"} message={statusMessage || ""} open={!!statusMessage} onConfirm={() => setStatusMessage(null)} />
            <Formik
                enableReinitialize={true}
                validationSchema={schema}
                initialValues={initialValues}
                onSubmit={async (values, { resetForm }) => {
                    const{startDate} = dateToEpoch(dateStart, new Date(), timeStart)
                    const body = {
                        asset_id: selectedAsset.id,
                        calibration_data: {
                            is_loaded: vehicleState === "empty" ? false : true,
                            vehicle_weight: unitsOfMeasure === UnitsOfMeasurement.imperial ? lbsToKg(values.vehicle_weight) : values.vehicle_weight,
                            epoch_from: startDate
                        }
                    }
                    const res = await onSubmit(body)

                    if (res.statusCode === 201) { 
                        setStatusMessage(res.message)
                        resetForm()
                        setDefaultForm()
                    }
                }}
            >
                {(props) => {
                    return (
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault()
                                props.submitForm()
                            }}  
                        >
                            <fieldset disabled={!selectedAsset?.id}>
                                <Row className="mb-3 d-flex justify-content-between" style={{ marginRight: "15px"}}>
                                    <Col>
                                        <RivataDropdown
                                            items={dropdownItems}
                                            caret={true}
                                            onSelect={setVehicleState}
                                            selected={vehicleState}
                                        />
                                    </Col>
                                    <Col md="4 d-flex justify-content-end" style={{ paddingRight: 0, paddingLeft: "50px"}}>
                                        <UnitsOfMeasureToggle 
                                            unitsOfMeasure={unitsOfMeasure} 
                                            onChangeUnitsOfMeasure={onChangeUnitsOfMeasure}
                                        />
                                    </Col>
                                </Row>

                                <Row className="d-flex mb-3 calibration-row" >
                                    <RivataFormField type="number" md="2" name="vehicle_weight" label={"Vehicle Weight"}/>
                                    <label 
                                        className="mr-5 units-of-measure__label"
                                    >
                                        {unitsOfMeasure === UnitsOfMeasurement.imperial ? "lbs" : "kg"}
                                    </label>

                                    <DateInput
                                        onSelect={setDateStart}
                                        isSingleDate={true}
                                        singleDateMd={12}
                                        md={1}
                                        to={dateStart}
                                        title={"Select Vehicle Weight Date Period"}
                                        customClassNames={"calibration-form__date-range"}
                                        disabled={!selectedAsset?.id}
                                        minDate={minCalendarDate && new Date(minCalendarDate * 1000)}
                                    />
                                    <div className="calibration-form__time-range">
                                        <PickTime
                                            t={timeStart}
                                            onChange={setTimeStart}
                                            isdisabled={!selectedAsset?.id}
                                        />
                                    </div>
                                </Row>

                                <Row className="d-flex justify-content-end">
                                    <Col md="2" className="d-flex justify-content-end">
                                        <Button className="mr-3 btn-primary" type="submit" >Save</Button>
                                    </Col>
                                </Row>
                            </fieldset>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}

export default AxleLoadCalibrationForm