import { useMemo } from "react"
import { ICustomerListItem } from "."


const getFilteredChildren = (children: Array<ICustomerListItem>, value: string) => {
  const data: Array<ICustomerListItem> = []

  for (let i = 0; i < children.length; i++) {
    if (children[i].name.toLocaleLowerCase().includes(value)) {
      data.push(children[i])
    }
  }

  return data
}

export const useFilteredData = (data: Array<ICustomerListItem>, searchValue: string) => {
  return useMemo(() => {
    if (searchValue === "") return data

    const res: Array<ICustomerListItem> = []
    const value = searchValue.toLowerCase()
    
    data.forEach((customer) => {
      const newCustomerItem = { ...customer }

      const filteredchildren: Array<ICustomerListItem> = getFilteredChildren(newCustomerItem.children, value)
      let shouldAdd = false

      if (newCustomerItem.name.toLocaleLowerCase().includes(value)) shouldAdd = true

      if (shouldAdd || filteredchildren.length > 0) {
        newCustomerItem.children = filteredchildren
        
        res.push(newCustomerItem)
      }
    })
    
    return res
  }, [data, searchValue])
}


export const useCustomerListItems = (data: Array<ICustomer>) => {
  return useMemo(() => {
    const parentList: Array<ICustomerListItem> = []
    const childrenList: Array<ICustomerListItem> = []

    data.forEach(item => {
      const el: ICustomerListItem = {
        id: item.id,
        name: item.name,
        isLabel: false,
        children: [],
        parent_id: item.parent_id,
        parent_customer_name: item.parent_customer_name
      }

      !item.parent_id ? parentList.push(el) : childrenList.push(el)
    })

    childrenList.forEach(item => {
      const idx = parentList.findIndex(el => el.id === item.parent_id)

      if (idx > -1) parentList[idx].children.push(item)
      else {
        if (!item.parent_customer_name || !item.parent_id) return;
        
        parentList.push({ id: item.parent_id, isLabel: true, name: item.parent_customer_name, children: [item] })
      }
    })
    
    return parentList
  }, [data])
}