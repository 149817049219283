import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Redirect } from 'react-router-dom'
import JSONInput from 'react-json-editor-ajrm'
//@ts-ignore
import JSONlocale from 'react-json-editor-ajrm/locale/en'
import { Button } from "reactstrap"

import configurationApi from '../../services/api/ApiGroups/Configuration'

import RivataModule from '../../components/RivataModule'
import SubNav from '../../components/SubNav'

import { useTypedSelector } from '../../hooks/useTypedSelector'
import RivataLoader from '../../components/RivataLoader'
import ConfirmModal from '../../components/ConfirmModal'
import { getStatusObj } from '../../utils/utils'
import StatusAlert from '../../components/StatusAlert'


const Admin: React.FC = () => {
    const locale = useTypedSelector(state => state.whitelabel.locale)

    const [jsonError, setJsonError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [status, setStatus] = useState<IStatus | null>(null)
    const [config, setConfig] = useState<IDataProcessingConfig | null>(null)
    const [updateBody, setUpdateBody] = useState<IDataProcessingConfig | null>(null)


    useEffect(() => {
        if (process.env.REACT_APP_STAGE === "prod") return

        const fetchConfig = async () => {
            try {
                setIsLoading(true)
                const res = await configurationApi.getDataProcessingConfig()

                setConfig(res)
            } catch (err) {
                setStatus(getStatusObj(err))

                console.log(err)
            } finally {
                setIsLoading(false)
            }
        }

        fetchConfig()
    }, [])


    const updateConfig = useCallback(async () => {
        if (!updateBody) return

        try {
            setIsLoading(true)

            const res = await configurationApi.putDataProcessingConfig(updateBody)
            
            const status = getStatusObj()
            status.message = "Update success"

            setStatus(status)
            setConfig(res)
        } catch (err) {
            //@ts-ignore error always has a message
            setStatus(getStatusObj(err, err.message))

            console.log(err)
        } finally {
            setIsLoading(false)
        }
    }, [updateBody])

    const breadcrumbs = useMemo(() => {
        return [
            { label: "Home", path: "/dashboard" },
            { label: "Admin Page" }
        ]
    }, [])

    if (process.env.REACT_APP_STAGE === "prod") {
        return (
            <Redirect to="/" />
        )
    }

    return (
        <>
            <SubNav
                title="Admin Page"
                breadcrumbs={breadcrumbs}
            />

            <RivataModule
                title="Data Processing Configuration"
                locale={locale}
                filters={null}
            >
                <>
                    {isLoading && <RivataLoader/>}

                    {status && (
                        <ConfirmModal
                            header="Status"
                            open={true}
                            onClose={() => setStatus(null)}
                        >
                            <StatusAlert color={status.ok ? "success" : "danger"} customText={status.message} statusCode={undefined} statusText={undefined} />
                        </ConfirmModal>
                    )}

                    <div>
                        <h3 className="mt-3 mb-3">Update Config</h3>
                        
                        <JSONInput
                            id='json_input'
                            locale={JSONlocale}
                            placeholder={config}
                            width="100%"
                            waitAfterKeyPress={100}
                            style={{ body: { padding: "1rem" } }}
                            onChange={(data: { error: string, jsObject: IDataProcessingConfig }) => {
                                const error = !!data.error

                                setJsonError(error)
                                
                                if (!error) setUpdateBody(data.jsObject)
                            }}
                        />
                        
                        <div className="do-provision__button">
                            <Button
                                onClick={updateConfig}
                                color="success"
                                className="mt-3"
                                disabled={jsonError || !updateBody}
                            >
                                Submit
                            </Button>
                        </div>
                    </div>
                </>
            </RivataModule>
        </>
    )
}

export default Admin