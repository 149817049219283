import React, { useCallback, useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, Row, Col, FormGroup, Input } from "reactstrap";
import RivataLoader from "../RivataLoader";
import './style.scss';

interface Props {
    open: boolean
    onVerificationRequestCodeClick: () => any,
    onVerifyCodeClick: (code: string) => any,
    onCancel: () => void,
    phoneNumber: string
}

const PhoneNumberVerificationModal: React.FC<Props> = ({ onVerificationRequestCodeClick, onVerifyCodeClick, onCancel, open, phoneNumber }: Props) => {
    const [code, setCode] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const onCodeSendButtonClick = useCallback(async() => {
        setErrorMessage("")
        setLoading(true);

        const res = await onVerificationRequestCodeClick();
        
        if (res["status"] !== "OK") {
            setErrorMessage(res["status"])
        }

        setLoading(false);
    }, [onVerificationRequestCodeClick])

    const onSubmitButtonClick = async() => {
        setErrorMessage("")
        setLoading(true);

        const res = await onVerifyCodeClick(code)

        setLoading(false);

        if (res["status"] !== "approved") {
            setErrorMessage(res["status"])
        }
        else {
            onCancel()
        }
     
    }

    useEffect(() => {
        // send code on mount
        onCodeSendButtonClick()
    }, [onCodeSendButtonClick])

    return (
        <Modal isOpen={open} centered>
            {loading && <RivataLoader/>}
            <ModalHeader toggle={onCancel}>
                Phone Number Verification
            </ModalHeader>
            <ModalBody>
                <FormGroup className="w-100">
                    <Row>
                        <Col md="8">
                            <Input
                                type="text"
                                name="phone"
                                id="textphone"
                                value={phoneNumber}
                                disabled
                            />
                        </Col>
                        <Col md="4">
                            <Button
                                color="secondary"
                                onClick={onCodeSendButtonClick}
                                disabled={loading}
                                className="w-100"
                            >
                                Resend Code
                            </Button>
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup className="w-100">
                    <Row>
                        <Col md="8">
                            <Input
                                type="text"
                                name="phone"
                                id="textcode"
                                placeholder="Please Enter Verification Code"
                                value={code}
                                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => setCode(evt.target.value)} 
                                
                            />
                        </Col>
                        <Col md="4">
                            <Button
                                color="secondary"
                                onClick={onSubmitButtonClick}
                                className="w-100"
                                disabled={!code}
                            >
                                Submit
                            </Button>
                        </Col>
                    </Row>
                </FormGroup>
                {errorMessage && <div className="errorMessage">{errorMessage}</div>}
            </ModalBody>
        </Modal>
    );
};

export default PhoneNumberVerificationModal;
