import { Dispatch } from "redux";
import { IgetState } from "../types";
import {
  IaxleLoadData,
  Ierror,
  IpostData,
  LoadCalibrationTypes,
  UsersActionsType,
} from "./types";
import { getErrorObj } from "../../utils";
import api from "../../services/api";
import { store } from "../store";

export const setLoadingAxleLoadAssets = (isLoading: boolean) =>
  ({
    type: LoadCalibrationTypes.LOADING,
    payload: isLoading,
  } as const);
export const setAxleLoadAssets = (data: Array<IaxleLoadData>) =>
  ({
    type: LoadCalibrationTypes.SET_ASSETS,
    payload: data,
  } as const);

export const setAxleLoadAssetsError = (err: Ierror) =>
  ({
    type: LoadCalibrationTypes.SET_AXLE_LOAD_ERROR,
    payload: err,
  } as const);

export const setCalibrationDataOffset = (offset: number) =>
  ({
    type: LoadCalibrationTypes.SET_AXLE_LOAD_CALIBRATION_DATA_OFFSET,
    payload: offset,
  } as const);

export const setCalibrationDataLimit = (limit: number) =>
  ({
    type: LoadCalibrationTypes.SET_AXLE_LOAD_CALIBRATION_DATA_LIMIT,
    payload: limit,
  } as const);

export const setSelectedCalibrationAsset = (asset: IAsset | null) =>
  ({
    type: LoadCalibrationTypes.SET_SELECTED,
    payload: asset,
  } as const);

export const setLoadingAxleLoadCalibrationData = (loading: boolean) =>
  ({
    type: LoadCalibrationTypes.SET_LOAD_CALIBRATION_DATA,
    payload: loading,
  } as const);

export const setAxleLoadCalibrationData = (data: Array<IaxleLoadData>) =>
  ({
    type: LoadCalibrationTypes.SET_AXLE_LOAD_CALIBRATION_DATA,
    payload: data,
  } as const);

export const setCalibrationDataTotalCount = (total: number) =>
  ({
    type: LoadCalibrationTypes.SET_AXLE_LOAD_CALIBRATION_DATA_TOTAL_COUNT,
    payload: total,
  } as const);

export const setAxleLoadCalibrationDataError = (error: Ierror) => ({
  type: LoadCalibrationTypes.SET_AXLE_LOAD_CALIBRATION_DATA_ERROR,
  payload: error,
});

export const fetchAxleLoadAssets = () => {
  return (dispatch: Dispatch<UsersActionsType>, getState: IgetState) => {
    const selectedCustomersList =
      getState().common.customers.selectedCustomersList;
    if (selectedCustomersList.length !== 1) return;
    
    dispatch(setLoadingAxleLoadAssets(true));
    return api
      .getAssets(10000, 0, "sensor_info", "axle_load")
      .then((res) => {
        dispatch(setAxleLoadAssets(res.paged_data));
        dispatch(setAxleLoadAssetsError(getErrorObj()));
      })
      .catch((err) => dispatch(setAxleLoadAssetsError(getErrorObj(err))))
      .finally(() => dispatch(setLoadingAxleLoadAssets(false)));
  };
};

export const fetchAxleLoadCalibrationData = (assetId: number) => {
  return (dispatch: Dispatch<UsersActionsType>, getState: IgetState) => {
    dispatch(setLoadingAxleLoadCalibrationData(true));
    const { offset, limit } = getState().axleLoadCalibration.calibrationData;
    return api
      .getAxleLoadCalibrationData(assetId, offset, limit)
      .then((res) => {
        dispatch(setAxleLoadCalibrationData(res.paged_data));
        dispatch(setCalibrationDataTotalCount(res.total_count));
        dispatch(setAxleLoadCalibrationDataError(getErrorObj()));
      })
      .catch((err) =>
        dispatch(setAxleLoadCalibrationDataError(getErrorObj(err)))
      )
      .finally(() => dispatch(setLoadingAxleLoadCalibrationData(false)));
  };
};

export const postAxleLoadCalibrationData = (data: IpostData) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(setLoadingAxleLoadAssets(true));

    return api
      .postAxleLoadCalibrationData(data)
      .then(() => {
        dispatch(fetchAxleLoadCalibrationData(data.asset_id));
        return getErrorObj(
          { statusCode: 201, message: "" },
          "Calibration Data has been added"
        );
      })
      .catch((err) => {
        const message = err.message.split(":")[1];
        dispatch(setAxleLoadAssetsError(getErrorObj(err, message)));
        return getErrorObj(err, message);
      })
      .finally(() => dispatch(setLoadingAxleLoadAssets(false)));
  };
};

export const putAxleLoadCalibrationData = (data: IpostData) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(setLoadingAxleLoadCalibrationData(true));

    return api
      .putAxleLoadCalibrationData(data)
      .then(() => {
        dispatch(setAxleLoadCalibrationDataError(getErrorObj()));
        dispatch(fetchAxleLoadCalibrationData(data.asset_id));
        return getErrorObj(
          { statusCode: 200, message: "" },
          "Updated successfully!"
        );
      })
      .catch((err) => {
        dispatch(setAxleLoadCalibrationDataError(getErrorObj(err)));
        return getErrorObj(err, err.message);
      })
      .finally(() => dispatch(setLoadingAxleLoadCalibrationData(false)));
  };
};

export const deleteAxleLoadCalibrationData = (
  assetId: number,
  cDataId: number
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(setLoadingAxleLoadCalibrationData(true));

    return api
      .deleteAxleLoadCalibrationData(assetId, cDataId)
      .then(() => {
        dispatch(setAxleLoadCalibrationDataError(getErrorObj()));
        dispatch(fetchAxleLoadCalibrationData(assetId));
        return getErrorObj(
          { statusCode: 204, message: "" },
          "Deleted successfully!"
        );
      })
      .catch((err) => {
        dispatch(setAxleLoadCalibrationDataError(getErrorObj(err)));
        return getErrorObj(err, err.message);
      })
      .finally(() => dispatch(setLoadingAxleLoadCalibrationData(false)));
  };
};

store.subscribe(() => {
  const lastAction = store.getState().lastAction;

  if (
    lastAction.type === LoadCalibrationTypes.SET_AXLE_LOAD_CALIBRATION_DATA_OFFSET
  ) {
    const { selectedAsset } = store.getState().axleLoadCalibration;

    store.dispatch(fetchAxleLoadCalibrationData(selectedAsset.id));
  }

  // reset offset after select limit
  if (
    lastAction.type ===
    LoadCalibrationTypes.SET_AXLE_LOAD_CALIBRATION_DATA_LIMIT
  ) {
    store.dispatch(setCalibrationDataOffset(0));
  }
});
