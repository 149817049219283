import React from "react";
import { NavLink } from "react-router-dom";
import { getFileTitle } from "../../modules/ManageSupportModule/utils";

interface ISupportButton {
  document: { file_name: string; url: string };
  dir: string;
  documents?:any
  id: number
}

const SupportButton: React.FC<ISupportButton> = ({ document, dir, id }) => {
  return (
    <NavLink
      target={ "_blank"}
      to={{
        pathname: `/document/${dir}/${id}`,
        state: { url: document.url, fileName: document.file_name },
      }}
      className="btn btn-secondary"
      style={{ textAlign: "left", marginBottom: "10px" }}
    >
      {getFileTitle(document.file_name)}
    </NavLink>
  );
};

export default SupportButton;
