import React, { useMemo } from 'react'
import RivataDropdown from '../../../components/RivataDropdown'

type Props = {
  disabled: boolean,
  warningTypes?: Array<{ id: string, label: string }>,
  selectedWarningType?: string,
  setSelectedWarningType?: React.Dispatch<React.SetStateAction<string>>
}

const WarningFilterForm: React.FC<Props> = ({
  disabled,
  warningTypes,
  selectedWarningType,
  setSelectedWarningType
}) => {
  const showWarningTypeSelector = useMemo(() => {
    return warningTypes && selectedWarningType && setSelectedWarningType
  }, [warningTypes, selectedWarningType, setSelectedWarningType])

  return (
    <>
      {showWarningTypeSelector && warningTypes && warningTypes.length > 1 && (
        <div className="d-flex mt-4 align-items-start" style={{ minWidth: "300px" }}>
          <label className="mr-2 mb-0 mt-2" style={{ minWidth: "120px" }}>Asset Health State:</label>
          <RivataDropdown
            caret={true}
            items={warningTypes}
            selected={selectedWarningType}
            onSelect={setSelectedWarningType}
            buttonLabel={null}
            filters={null}
            onRemove={null}
            disabled={disabled}
            color={"secondary"}
          />
        </div>
      )}
    </>
  )
}

export default WarningFilterForm