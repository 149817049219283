interface ExpirableItem {
  data: any
  lastUsedEpoch: number
}

export const setLocalStorageItem = (key: string, value: any) => {
  localStorage.setItem(
    key,
    JSON.stringify({
      data: value,
      lastUsedEpoch: new Date().getTime(),
    }),
  )
}

export const getLocalStorageItem = (key: string) => {
  const item = localStorage.getItem(key)
  if (item) {
    const eItem: ExpirableItem = JSON.parse(item)
    //update last used time
    setLocalStorageItem(key, eItem.data)
    return eItem.data
  }
  return undefined
}
export const removeLocalStorageItem = (key: string) => {
  localStorage.removeItem(key)
}

export const removeExpiredLocalStorageItems = () => {
  const allItems = { ...localStorage }
  Object.keys(allItems).forEach((key: string) => {
    const item = localStorage.getItem(key)

    //we need only objects, because JSON.parse will fail on any other data
    if (
      item &&
      item !== 'undefined' &&
      item.startsWith('{') &&
      item.endsWith('}')
    ) {
      try {
        const eItem: ExpirableItem = JSON.parse(item)
        if (eItem.lastUsedEpoch) {
          const epochNov: number = new Date().getTime()
          const diff = Math.abs(epochNov - eItem.lastUsedEpoch)
          const diffDays = Math.ceil(diff / (1000 * 3600 * 24))
          //remove expired items after 14 days
          if (diffDays >= 14) {
            localStorage.removeItem(key)
          }
        }
      } catch {}
    }
  })
}
