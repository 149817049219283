import * as util from 'util'

import {
  LOADING_CUSTOMERS,
  SET_CUSTOMERS,
  SET_SELECTED_CUSTOMERS_LIST,
  SET_CUSTOMERS_ERROR,
  SET_SYSTEM_DEFAULTS,
  SET_CUSTOMER_DEFAULTS,
  SET_VERSION_INFO,
  LOADING_DEFAULTS,
  SET_HIDE_AUTOGENERATED_FILTER,
} from '../actionTypes'
import { getErrorObj, getCurrentEpoch, sortByAlphabet } from '../../utils'
import appConfig from '../../config/appConfig'
import { store } from '../store'
import { AssociativeAxlesNames, RoutesWithBlockedAllOption } from '../../enums'
import api from '../../services/api'
import { fetchWhitelabel } from '../whitelabel/action'

export const loadingDefaults = (isLoading) => ({
  type: LOADING_DEFAULTS,
  payload: isLoading,
})

export const loadingCustomers = (isLoading) => ({
  type: LOADING_CUSTOMERS,
  payload: isLoading,
})

export const setCustomers = (config) => ({
  type: SET_CUSTOMERS,
  payload: config,
})

export const setSelectedCustomersList = (payload) => ({
  type: SET_SELECTED_CUSTOMERS_LIST,
  payload,
})

export const setCustomersError = (error) => ({
  type: SET_CUSTOMERS_ERROR,
  payload: error,
})

export const setSystemDefaults = (defaults) => ({
  type: SET_SYSTEM_DEFAULTS,
  payload: defaults,
})

export const setCustomerDefaults = (defaults) => ({
  type: SET_CUSTOMER_DEFAULTS,
  payload: defaults,
})

export const setVersionInfo = (versionInfo) => ({
  type: SET_VERSION_INFO,
  payload: versionInfo,
})

export const setHideAutogeneratedAssets = (payload) => ({
  type: SET_HIDE_AUTOGENERATED_FILTER,
  payload,
})

export const fetchVersionInfo = () => {
  return function (dispatch) {
    return api
      .getDataAboutApi()
      .then((data = {}) => {
        if (data) {
          data.branch = data.branch === 'not set' ? '' : data.branch
          data.commit_uid =
            data.commit_uid === 'not set' ? '' : data.commit_uid.substring(0, 8)
        }
        data.build_branch = appConfig.build_branch
        data.build_fullhash = appConfig.build_fullhash.substring(0, 8)
        dispatch(setVersionInfo(data))
      })
      .catch((err) => {})
      .finally(() => {})
  }
}

const parseDefaults = (data) => {
  const {
    cold_inflation_pressure_in_psi,
    low_pressure_in_percent,
    critical_low_pressure_in_percent,
    over_pressure_in_percent,
    critical_over_pressure_in_percent,
    steer_cold_inflation_pressure_in_psi,
  } = data
  if (data.default_profile_axles_values) {
    const thresholdsPerAxle = data.default_profile_axles_values.reduce(
      (accum, curr) => {
        const { axle, pressure_value } = curr
        accum.push({
          axle: AssociativeAxlesNames[axle],
          pressure_value,
        })
        return accum
      },
      [],
    )

    return {
      cold_inflation_pressure_in_psi,
      low_pressure_in_percent,
      critical_low_pressure_in_percent,
      over_pressure_in_percent,
      critical_over_pressure_in_percent,
      thresholdsPerAxle,
      steer_cold_inflation_pressure_in_psi,
    }
  }

  return data
}

export const fetchDefaults = (customerId) => {
  return function (dispatch) {
    dispatch(loadingDefaults(true))
    return api
      .getSettingsDefaults(customerId)
      .then((res) => {
        const systemDefaults = res.system_defaults
        const customerDefaults = res.customer_defaults

        systemDefaults.tpms = parseDefaults(systemDefaults.tpms)
        customerDefaults.tpms = parseDefaults(customerDefaults.tpms)

        const validSubscriptions = {}
        const currentDate = getCurrentEpoch()

        Object.keys(customerDefaults.subscriptions).map((subKey) => {
          const { valid_to } = customerDefaults.subscriptions[subKey]
          if (currentDate <= valid_to) {
            validSubscriptions[subKey] = customerDefaults.subscriptions[subKey]
          }
          return subKey
        })

        customerDefaults.subscriptions = validSubscriptions
        dispatch(setSystemDefaults(systemDefaults))
        dispatch(setCustomerDefaults(customerDefaults))
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(loadingDefaults(false)))
  }
}

const composeCustomersList = (customers) => {
  const customersWithChildren = customers.map((c) => {
    c.children = []
    c.parent = null

    return c
  })

  customers.forEach((item) => {
    if (item.parent_id) {
      const idx = customersWithChildren.findIndex(
        (el) => el.id === item.parent_id,
      )
      item.parent = customersWithChildren[idx]

      if (idx > -1) customersWithChildren[idx].children.push(item)
    }
  })

  return customersWithChildren
}

const blockedPathList = Object.values(RoutesWithBlockedAllOption) // blocked routes to use "All" option in customer selector

export function fetchCustomers(shouldPreselectRivata = false) {
  return function (dispatch, getState) {
    dispatch(loadingCustomers(true))
    return api
      .getCustomers()
      .then((resCustomers) => {
        const customersWithChildren = composeCustomersList(resCustomers)
        const selectedCustomersList =
          getState().common.customers.selectedCustomersList

        sortByAlphabet(customersWithChildren, 'name')

        let selectedCustomers = []
        const pathParts = window.location.pathname.split('/')
        const multipleCustomersAvailable = !blockedPathList.includes(
          pathParts[1],
        )

        if (
          shouldPreselectRivata ||
          (!multipleCustomersAvailable && selectedCustomersList.length !== 1)
        ) {
          const initialCustomer =
            customersWithChildren.find((c) => c.name === 'Rivata') ||
            customersWithChildren.find((el) => el.parent_id === null) ||
            resCustomers[0]

          if (initialCustomer) selectedCustomers.push(initialCustomer)
        } else {
          selectedCustomers = selectedCustomersList.length
            ? customersWithChildren.filter((customer) =>
                selectedCustomersList.find(
                  (oldCustomer) => oldCustomer.id === customer.id,
                ),
              )
            : customersWithChildren
        }

        dispatch(setCustomers(customersWithChildren))
        dispatch(setSelectedCustomersList(selectedCustomers))
        dispatch(setCustomersError(getErrorObj()))
      })
      .catch((err) => dispatch(setCustomersError(getErrorObj(err))))
      .finally(() => dispatch(loadingCustomers(false)))
  }
}

export const handleAppLocationChange = (pathname) => {
  return (dispatch, getState) => {
    const pathParts = pathname.split('/')
    const { data, selectedCustomersList } = getState().common.customers
    const multipleCustomersAvailable = !blockedPathList.includes(pathParts[1])
    let newSelectedList = []

    if (!multipleCustomersAvailable) {
      if (selectedCustomersList.length) {
        const rivataCustomer = selectedCustomersList.find(
          (c) => c.name === 'Rivata',
        )

        if (rivataCustomer) newSelectedList.push(rivataCustomer)
        else if (selectedCustomersList.length) {
          newSelectedList.push(
            selectedCustomersList.find((el) => el.parent_id === null) ||
              selectedCustomersList[0],
          )
        }
      } else {
        const rivataCustomer = data.find((c) => c.name === 'Rivata')
        if (rivataCustomer) newSelectedList.push(rivataCustomer)
        else if (data.length) newSelectedList.push(data[0])
      }
    } else return

    // util.inspect - remove circular parameters
    if (
      JSON.stringify(util.inspect(newSelectedList)) !==
      JSON.stringify(util.inspect(selectedCustomersList))
    ) {
      dispatch(setSelectedCustomersList(newSelectedList))
    }
  }
}

store.subscribe(() => {
  const lastAction = store.getState().lastAction

  if (lastAction.type === SET_SELECTED_CUSTOMERS_LIST) {
    if (lastAction.payload.length === 1)
      store.dispatch(fetchDefaults(lastAction.payload[0].id))
    else store.dispatch(fetchDefaults())

    if (window.location.pathname.split('/')[1] !== 'whitelabel') {
      if (lastAction.payload.length === 1) {
        store.dispatch(fetchWhitelabel(lastAction.payload[0].id))
      } else {
        store.dispatch(fetchWhitelabel())
      }
    }
  }
})
