import React, { RefObject, useEffect, useRef, useState } from "react"
import { Label, Input } from "reactstrap"

import CustomMenu from "./CustomMenu"

import { useActions } from "../../hooks/useActions"
import { useTypedSelector } from "../../hooks/useTypedSelector"
import { useCustomerListItems } from "./hooks"

import "./style.scss"
import { RoutesWithBlockedAllOption } from "../../enums"

export type ICustomerListItem = {
  id: number
  name: string
  isLabel: boolean
  children: Array<ICustomerListItem>
  parent_id?: number | null
  parent_customer_name?: string | null
}

interface ICustomerData {
  data: Array<ICustomer>
  selectedCustomersList: Array<ICustomer>
  locale: ILocale
}

interface Props {
  onSubmit?: () => void
  disabled?: boolean
  setAllFromTags?: boolean
}

const blockList: Array<string> = Object.values(RoutesWithBlockedAllOption)

const CustomerSelector: React.FC<Props> = ({ onSubmit, disabled = false, setAllFromTags }) => {
  // redux states & actions
  const {
    data,
    selectedCustomersList,
    locale
  }: ICustomerData = useTypedSelector(state => ({
    data: state.common.customers.data,
    selectedCustomersList: state.common.customers.selectedCustomersList,
    locale: state.whitelabel.locale
  }))
  const {
    setSelectedCustomersList
  } = useActions()

  const customerInput = useRef()
  const [inputValue, setInputValue] = useState("")
  const [currentSelected, setCurrentSelected] = useState<Array<ICustomer>>([])
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const pathPart = window.location.pathname.split("/")[1]
  const allDisabled = blockList.includes(pathPart)

  useEffect(()=>{
    if (setAllFromTags) {
      setIsMenuOpen(false)
      setSelectedCustomersList(data)
      onSubmit && onSubmit()
    }
  },[setAllFromTags, data, setSelectedCustomersList, onSubmit])


  useEffect(() => {
    setCurrentSelected(selectedCustomersList)
  }, [selectedCustomersList])

  useEffect(() => {
    let value = "All"

    if (selectedCustomersList.length === 1) {
      value = selectedCustomersList[0].name
    } else if (selectedCustomersList.length > 1 && selectedCustomersList.length < data.length) {
      value = "Multiple"
    }
    
    setInputValue(value)
  }, [selectedCustomersList, data])

  const parsedData = useCustomerListItems(data)

  const handleCheck = (item: ICustomerListItem | null, state = false, isAll = false) => {
    // state = current value of checkbox (true or false)
    let newList = [...currentSelected]
    
    if (isAll) {
      state ? newList = data : newList = []
    } else if (item) {
      const idx = currentSelected.findIndex(el => el.id === item.id)
    
      if (idx > -1) {
        newList.splice(idx, 1)
      } else {
        if (allDisabled && currentSelected.length !== 0) return
        
        const elem = data.find(el => el.id === item.id)
        if (elem) newList.push(elem)
      }
    }
    
    setCurrentSelected(newList)
  }

  const handleModalClickOutside = (e: Event, menu: RefObject<any>) => {
    if (menu.current && !menu.current.contains(e.target) && e.target !== customerInput.current) {
      if (isMenuOpen) setIsMenuOpen(false)
    }
  }

  const handleCancel = () => {
    setCurrentSelected(selectedCustomersList)
    setIsMenuOpen(false)
  }

  if (data.length === 1) return null

  return (
    <div className="d-flex align-items-center customer__selector">
      <div className="d-flex align-items-center ">
        <Label className="mb-0 mr-2">{locale["Customer"] || "Customer"}</Label>
        <Input
          innerRef={customerInput}
          className="customer__input"
          placeholder="Select Customer"
          readOnly={true}
          disabled={disabled}
          value={inputValue}
          onClick={() => {
            if (!isMenuOpen) setIsMenuOpen(true)
          }}
        > 
        </Input>
      </div>
      {isMenuOpen ? (
        <CustomMenu
          data={parsedData}
          currentSelected={currentSelected}
          isAllSelected={currentSelected.length === data.length}
          isMenuOpen={isMenuOpen}
          handleModalClickOutside={handleModalClickOutside}
          handleCheck={handleCheck}
          onClear={() => currentSelected.length ? setCurrentSelected([]) : null}
          onCancel={handleCancel}
          onSubmit={() => {
            setIsMenuOpen(false)
            setSelectedCustomersList(currentSelected)
            onSubmit && onSubmit()
          }}
        />
      ) : null}
    </div>
  )
}

export default CustomerSelector