import React, { useState, useEffect, useCallback } from 'react'
import { TabContent, TabPane, Row } from 'reactstrap'
import { useHistory, useLocation } from 'react-router-dom'

import ProvisionSubNav from './components/ProvisionSubNav'
import ProvisionTabs from './components/ProvisionTabs'
import AssetsTable from '../../modules/AssetsProvisionTable'
import AssetTpmsTable from '../../modules/AssetTpmsTable'
import AssetsBulkUploadHistoryTable from '../../modules/AssetsBulkUploadHistoryTable'
import Users from '../../modules/Users'

import { useTypedSelector } from '../../hooks/useTypedSelector'
import { useActions } from '../../hooks/useActions'
import { useQuery } from '../../hooks/useQuery'

import './style.scss'

const Provision = () => {
  // redux hooks
  const {
    auth: { isSuperAdmin, isAdmin },
    customers: { error, selectedCustomersList },
    locale,
    customerDefaults,
  } = useTypedSelector((state) => ({
    auth: state.auth,
    customers: state.common.customers,
    locale: state.whitelabelAdmin.locale,
    customerDefaults: state.common.customerDefaults,
  }))

  const { resetProvisionPageReducer } = useActions()

  // state
  const [activeTab, setActiveTab] = useState('assets')
  const history = useHistory()
  const location = useLocation()
  const params = useQuery()

  const setTabParam = useCallback(
    (tab) => {
      history.push({
        pathname: location.pathname,
        search: `?tab=${tab}`,
      })
    },
    [history, location.pathname],
  )

  useEffect(() => {
    const paramsTab = params.get('tab')

    if (!paramsTab) setTabParam(activeTab)

    if (
      ['assets', 'tpmsAssets', 'users', 'assetsBulkUploadHistory'].includes(
        paramsTab,
      ) &&
      paramsTab !== activeTab
    ) {
      setActiveTab(paramsTab)
    }
  }, [params, activeTab, setTabParam])

  useEffect(() => {
    return () => {
      // reset data from store when user leave this page
      resetProvisionPageReducer()
    }
  }, [resetProvisionPageReducer])

  const toggleTab = useCallback(
    (tab) => {
      setActiveTab(tab)
      setTabParam(tab)
    },
    [setTabParam],
  )

  return (
    <>
      <ProvisionSubNav
        isSuperAdmin={isSuperAdmin}
        isAdmin={isAdmin}
        locale={locale}
        selectedCustomersList={selectedCustomersList}
        error={error}
      />
      <div className='tabs-wrapper provision'>
        <ProvisionTabs
          isSuperAdmin={isSuperAdmin}
          activeTab={activeTab}
          customerDefaults={customerDefaults}
          toggleTab={toggleTab}
          isAdmin={isAdmin}
        />
        <TabContent activeTab={activeTab} className='ml-3 mr-3'>
          <TabPane tabId='assets'>
            <Row>
              {activeTab === 'assets' && (
                <AssetsTable
                  width={12}
                  locale={locale}
                  selectedCustomersList={selectedCustomersList}
                />
              )}
            </Row>
          </TabPane>
          <TabPane tabId='tpmsAssets'>
            <Row>
              {activeTab === 'tpmsAssets' && (
                <AssetTpmsTable
                  locale={locale}
                  width={12}
                  selectedCustomersList={selectedCustomersList}
                />
              )}
            </Row>
          </TabPane>
          <TabPane tabId='users'>
            <Row>
              {activeTab === 'users' && <Users locale={locale} width={12} />}
            </Row>
          </TabPane>
          <TabPane tabId='assetsBulkUploadHistory'>
            <Row>
              {activeTab === 'assetsBulkUploadHistory' && (
                <AssetsBulkUploadHistoryTable
                />
              )}
            </Row>
          </TabPane>
        </TabContent>
      </div>
    </>
  )
}

export default Provision
