import React, { useEffect, useMemo } from "react";
import SubNav from "../../components/SubNav";
import SupportInfo from "../../modules/SupportInfo";
import RivataLoader from "../../components/RivataLoader";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import SupportButton from "./SupportButton";

const Support = ({ locale = {}  }) => {
  const { fetchDocuments, fetchDocumentTypes } = useActions()
  const { isLoading: isLoadingDocs, documents, types: { isLoading: isLoadingTypes, docTypes } } = useTypedSelector(state => ({ ...state.documents }) )

  const isLoading = useMemo(() => {
    return (isLoadingDocs || isLoadingTypes)
  }, [isLoadingDocs, isLoadingTypes])
  
  useEffect(() => {
    fetchDocumentTypes()
    fetchDocuments()
  }, [fetchDocuments, fetchDocumentTypes])

  return (
    <>
      <SubNav title="Support" breadcrumbs={[{ label: "Home", path: "/dashboard" }, { label: "Support" }]} />
      <SupportInfo locale={locale}>
        {isLoading ? (
          <div className="d-flex" style={{ marginTop: "10px", marginBottom: "10px" }}>
            <RivataLoader />
          </div>
        ) : (
          <div className="d-flex" style={{ minHeight: "150px" }}>
            <div>
              <h3 className="mb-4">Contact Information</h3>
              <p className="mb-0" style={{ marginTop: "1.6rem", padding: "5px 0" }}>
                Phone: (940) 299-9143
              </p>
              <p className="mb-0" style={{ marginTop: "0.6rem", padding: "5px 0" }}>
                Email: <a href="mailto: digitalcustomerservice@conmet.com"> digitalcustomerservice@conmet.com</a>
              </p>
            </div>
            <div style={{ marginLeft: "6rem" }}>
              <h3 className="mb-4">Resources</h3>
              
              <div className="d-flex flex-column">
                {documents.resources?.map((document, index) => (
                  <SupportButton 
                    key={index}
                    id={docTypes.find(docType => docType.id === document.doc_type)?.key ?? document.id} 
                    document={document} 
                    dir={"resources"}
                  />
                ))}
              </div>
            </div>

            {documents.legals?.length ? (
              <div style={{ marginLeft: "6rem" }}>
                <h3 className="mb-4">Legal</h3>

                <div className="d-flex flex-column">
                  {documents.legals?.map((document, index) => (
                    <SupportButton 
                      key={index}
                      id={docTypes.find(docType => docType.id === document.doc_type)?.key ?? document.id}
                      document={document}
                      documents={documents.legals}
                      dir={"legals"}
                    />
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        )}
      </SupportInfo>
    </>
  );
};


export default Support;
