import { cloneDeep, debounce } from "lodash"
import { useTypedSelector } from "../../hooks/useTypedSelector"
import React, { useCallback, useEffect, useRef, useState } from "react"
import SearchInput from "../../componentsV2/SearchInput"
import { SearchIcon } from "../../componentsV2/Icon"
import { MenuItemType } from "../../componentsV2/Menu/MenuItem"
import DropdownItemSelector from "../../componentsV2/DropdownItemSelector"
import Button from "../../componentsV2/Button"

interface Props {
    onFilter: (filters: string) => void
}

const debouncer = debounce((func: () => void) => func(), 400)

const wrapperStyle = { width: "470px" }
const initialSelection = {
    tag_location_type: [],
}
const locationTypes: Array<MenuItemType> = [
    { id: "ASSET", label: "Asset", type: "checkbox", markType: "check", checked: false, parentId: null },
    { id: "TAG_LOCATION", label: "Tag Location", type: "checkbox", markType: "check", checked: false, parentId: null }]

const TagLocationsFilterMenu: React.FC<Props> = ({ onFilter }) => {
    const { selectedCustomersList } = useTypedSelector((state) => ({
        timezone: state.auth.preferences.timezone,
        selectedCustomersList: state.common.customers.selectedCustomersList
    }))
    const searchParams = useRef<Record<string, Array<string>>>(cloneDeep(initialSelection))
    const actualQuery = useRef("")
    const [macSearch, setMacSearch] = useState<string>("")
    const [typeLabel, setTypeLabel] = useState('Type')
    const [selectedTypes, setSelectedTypes] = useState<string[]>([])
    const [locationTypesItems, setLocationTypesItems] = useState<Array<MenuItemType>>(cloneDeep(locationTypes))
    const updateFilters = useCallback((key: string, value: Array<string> | string) => {
        let tempValue: Array<string> | string = value;

        if (!tempValue || !tempValue.length) {
            delete searchParams.current[key]
        } else if (Array.isArray(tempValue)) {
            searchParams.current[key] = tempValue
        } else {
            searchParams.current[key] = [tempValue]
        }

        const query = new URLSearchParams()

        Object.entries(searchParams.current).forEach(filter => {
            const key = filter[0]
            filter[1].forEach(value => query.append("filters", `${key}=${value}`))
        })
        actualQuery.current = query.toString()
        onFilter(actualQuery.current)
    }, [onFilter])

    const onSearchByMac = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const mac = e.target.value
        setMacSearch(mac)
        debouncer(() => updateFilters("mac", mac))
    }, [updateFilters])

    const onDropdownItemSelected = useCallback((id: string, value: MenuItemType[]) => {
        const checked = value.filter((v) => { return v.checked }).map((i) => { return i.id })

        debouncer(() => updateFilters(id, checked))
        setSelectedTypes(checked)
    }, [updateFilters])
    useEffect(() => {
        setTypeLabel('Type' + (selectedTypes && selectedTypes?.length > 0 ? `: ${selectedTypes?.length} selected` : ""))
    }, [selectedTypes])
    const handleFiltersReset = useCallback((filterData: boolean = true) => {
        setSelectedTypes([])

        // make filtering only if filter query params are different from prevoius
        if (actualQuery.current === "") return

        setMacSearch("")
        setLocationTypesItems(cloneDeep(locationTypes))
        actualQuery.current = ""
        searchParams.current = cloneDeep(initialSelection)

        if (filterData)
            onFilter("")
    }, [onFilter])

    useEffect(() => {
        handleFiltersReset(false)
    }, [selectedCustomersList, handleFiltersReset])
    
    return (<>
        <div className="d-flex flex-wrap">
            <SearchInput
                placeholder='Search by MAC'
                icon={<SearchIcon width={20} height={20} color='black' />}
                wrapperStyle={wrapperStyle}
                value={macSearch}
                onChange={onSearchByMac}
            /></div>
        <br />
        <div className="d-flex flex-wrap justify-content-between">
            <div className="d-flex flex-wrap">
                <DropdownItemSelector
                    id="tag_location_type"
                    filterClassName="mr-2"
                    filterBtnLabel={typeLabel}
                    items={locationTypesItems}
                    onItemsChange={onDropdownItemSelected}
                    clearSelectionOnReset={true}
                />
            </div><div>
                <Button
                    onClick={() => { handleFiltersReset() }}
                >
                    Clear
                </Button>
            </div>
        </div>
    </>)
}
export default TagLocationsFilterMenu