import React from 'react'
import { ReportParameterGroupProps } from './ReportParameterGroupProps'
import AssetTypeRow from '../ReportParametersRows/AssetTypeRow'
import AssetsRow from '../ReportParametersRows/AssetsRow'
import AssetGroupsRow from '../ReportParametersRows/AssetGroupsRow'
import GeofencesRow from '../ReportParametersRows/GeofencesRow'
import AssetHealthStateRow from '../ReportParametersRows/AssetHealthStateRow'
import CustomerRow from '../ReportParametersRows/CustomerRow'
import TimeZoneRow from '../ReportParametersRows/TimeZoneRow'

const TpmsReportParameters: React.FC<ReportParameterGroupProps> = (
  props: ReportParameterGroupProps,
) => {
  return (
    <>
      {props.isSchedule && (
        <CustomerRow value={props.parameters.customer_keys} />
      )}
      {props.isSchedule && <TimeZoneRow value={props.parameters.timezone} />}
      <AssetHealthStateRow
        value={
          props.isSchedule
            ? props.parameters.warning_type
            : props.parameters.warning_filter
        }
      />
      <AssetTypeRow value={props.parameters.asset_type} />
      <AssetsRow value={props.parameters.vins} />
      <AssetGroupsRow value={props.parameters.asset_group} />
      <GeofencesRow
        value={
          props.isSchedule
            ? props.parameters.geofences
            : props.parameters.selected_geofences
        }
      />
    </>
  )
}
export default TpmsReportParameters
