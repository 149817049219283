import {
    LOADING_SCHEDULED_REPORTS,
    SET_SCHEDULED_REPORTS,
    SET_SCHEDULED_REPORTS_USERS
} from "../actionTypes"

const initialState = {
    isLoading: false,
    data: [],
    users: []
}

const scheduledReports = (state = initialState, action) => {
    switch (action.type) {
        case LOADING_SCHEDULED_REPORTS:
            return { ...state, isLoading: action.payload }
        case SET_SCHEDULED_REPORTS:
            return { ...state, data: action.payload }
        case SET_SCHEDULED_REPORTS_USERS:
            return { ...state, users: action.payload}
        default:
            return state
    }
}

export default scheduledReports