import React, { useEffect, useState } from 'react'
import { ReportParameterRowProps } from './ReportParameterRowProps'
import { convertDataEpochToDate } from '../../../utils'
import { Label } from 'reactstrap'
import classes from './styles.module.scss'
import { NoDataLabels } from '../../../enums'
import { dateWithTimeFormat } from '../../../constants/constants'
const DateRangeRow: React.FC<ReportParameterRowProps> = (
  props: ReportParameterRowProps,
) => {
  const { value } = props
  const [displayValue, setDisplayValue] = useState<string>('-')
  useEffect(() => {
    if (!value) {
      setDisplayValue(NoDataLabels.TRIPPLE_DASH)
    } else if (value && value === 'all_time') {
      setDisplayValue('All time')
    } else {
      const fromTo = value.split('-')
      console.log(fromTo)
      setDisplayValue(
        `${convertDataEpochToDate(
          parseInt(fromTo[0], 10),
          null,
          null,
          false,
        ).format(dateWithTimeFormat)} - ${convertDataEpochToDate(
          parseInt(fromTo[1], 10),
          null,
          null,
          false,
        ).format(dateWithTimeFormat)}`,
      )
    }
  }, [value])
  return (
    <div className={classes.reportParameterRow}>
      <Label className={classes.label}>Date Range:</Label>
      <Label className={classes.value}>{displayValue}</Label>
    </div>
  )
}
export default DateRangeRow
