import { useMemo } from 'react'

import { IRow } from '../../components/RivataGrid/index'
import { ReportTypes, TagReportTypes, UnitsOfMeasurement } from '../../enums'
import { convertDataEpochToDate, getCurrentEpoch } from '../../utils'
import { dvHealthReportRows } from './parsers/deviceHealthReport'
import { tpmsReportRows } from './parsers/tpmsReport'
import { dailyReportRows } from './parsers/dailyReport'
import { geofencesReportRows } from './parsers/geofencesReports'
import { distanceReportRows } from './parsers/distanceTravelReport'
import { activeWarningsRows } from './parsers/activeWarningsReport'
import {
  tagHistoryDetailsReportRows,
  lostTagReportRows,
  tagsReportRows,
  unassignedTagReportRows,
} from './parsers/tagsReport'
import { assetProvisioningSummaryReportRows } from './parsers/assetProvisioningSummaryReport'
import { warningHistoryReportRows } from './parsers/warningHistoryReport'
import { notificationHistoryReportRows } from './parsers/notificationHistoryReport'
import { assetInGeofenceReportRows } from './parsers/assetsInGeofenceReport'
import { hardwareCommandHistoryReportRows } from './parsers/hardwareCommandHistoryReport'
import { dwellReportRows } from './parsers/dwellReport'
import { cargotagGatewayReportRows } from './parsers/cargotagGatewayReport'
import { userLoginsReportRows } from './parsers/userLoginsReport'
import { odometerSummaryRows } from './parsers/odometerOffsetSummaryReport'
import { failedNotificationHistoryReportRows } from './parsers/failedNotificationHistoryReport'
import { driverAppTripReportRows } from './parsers/driverAppTripReport'
import { utilizationReportRows } from './parsers/utilizationReport'
import { nonReportingSensorsReportRows } from './parsers/nonReportingSensorsReport'
import { durationInWarningStateRows } from './parsers/durationInWarningStateReport'
import { milesInWarningStateReportRows } from './parsers/milesInWarningStateReport'

interface IuseReportRows {
  (
    reportType: string,
    columns: Array<string>,
    rows: Array<IRow>,
    locale: ILocale,
    unitsOfMeasurement?: string,
  ): Array<IRow>
}

const useReportRows: IuseReportRows = (
  reportType,
  columns,
  rows,
  locale,
  unitsOfMeasurement = UnitsOfMeasurement.imperial,
) => {
  return useMemo(() => {
    const timezone = convertDataEpochToDate(
      getCurrentEpoch(),
      null,
      null,
      false,
    ).format('z')
    let parsedRows: Array<IRow> = []

    if (!columns.length || !rows.length) return parsedRows
    switch (reportType) {
      case ReportTypes.USER_LOGINS_REPORT:
        parsedRows = userLoginsReportRows(rows)
        break
      case ReportTypes.HARDWARE_COMMANDS_HISTORY_REPORT:
        parsedRows = hardwareCommandHistoryReportRows(columns, rows)
        break
      case ReportTypes.TPMS_REPORT:
        parsedRows = tpmsReportRows(
          columns,
          rows,
          locale,
          timezone,
          unitsOfMeasurement,
        )
        break
      case ReportTypes.DEVICE_HEALTH_REPORT:
        parsedRows = dvHealthReportRows(columns, rows, timezone)
        break
      case ReportTypes.DAILY_MILEAGE_REPORT:
        parsedRows = dailyReportRows(rows, unitsOfMeasurement)
        break
      case ReportTypes.DISTANCE_TRAVELED_REPORT:
        parsedRows = distanceReportRows(rows, unitsOfMeasurement)
        break
      case ReportTypes.GEOFENCE_HISTORY_REPORT:
        parsedRows = geofencesReportRows(columns, rows, timezone)
        break
      case TagReportTypes.LATEST_TAG_LOCATION_REPORT:
        parsedRows = tagsReportRows(rows)
        break
      case TagReportTypes.LOST_TAGS_REPORT:
        parsedRows = lostTagReportRows(rows)
        break
      case TagReportTypes.UNASSIGNED_CARGO_TAGS_REPORT:
        parsedRows = unassignedTagReportRows(rows)
        break
      case TagReportTypes.TAG_HISTORY_DETAILS_REPORT:
        parsedRows = tagHistoryDetailsReportRows(rows)
        break
      case ReportTypes.ACTIVE_WARNINGS_REPORT:
        parsedRows = activeWarningsRows(rows, locale, unitsOfMeasurement)
        break
      case ReportTypes.DURATION_IN_WARNING_STATE_REPORT:
        parsedRows = durationInWarningStateRows(rows, locale)
        break
      case ReportTypes.ASSET_PROVISIONING_SUMMARY_REPORT:
        parsedRows = assetProvisioningSummaryReportRows(rows)
        break
      case ReportTypes.WARNING_HISTORY_REPORT:
        parsedRows = warningHistoryReportRows(rows, locale, unitsOfMeasurement)
        break
      case ReportTypes.NOTIFICATION_HISTORY_REPORT:
        parsedRows = notificationHistoryReportRows(rows)
        break
      case ReportTypes.ASSETS_IN_GEOFENCE_REPORT:
        parsedRows = assetInGeofenceReportRows(rows)
        break
      case ReportTypes.DWELL_REPORT:
        parsedRows = dwellReportRows(rows)
        break
      case TagReportTypes.CARGO_TAG_GATEWAY_REPORT:
        parsedRows = cargotagGatewayReportRows(rows)
        break
      case ReportTypes.ODOMETER_OFFSET_SUMMARY_REPORT:
        parsedRows = odometerSummaryRows(rows, unitsOfMeasurement)
        break
      case ReportTypes.FAILED_NOTIFICATION_HISTORY_REPORT:
        parsedRows = failedNotificationHistoryReportRows(rows)
        break
      case ReportTypes.DRIVER_APP_TRIP_REPORT:
        parsedRows = driverAppTripReportRows(rows)
        break
      case ReportTypes.UTILIZATION_REPORT:
        parsedRows = utilizationReportRows(rows, unitsOfMeasurement)
        break
      case ReportTypes.NON_REPORTING_SENSORS_REPORT:
        parsedRows = nonReportingSensorsReportRows(
          rows,
          locale,
          unitsOfMeasurement,
        )
        break
      case ReportTypes.MILES_IN_WARNING_STATE_REPORT:
        parsedRows = milesInWarningStateReportRows(
          rows,
          locale,
          unitsOfMeasurement,
        )
        break
      default:
        parsedRows = rows
        break
    }

    return parsedRows
  }, [reportType, columns, rows, unitsOfMeasurement, locale])
}

export default useReportRows
