import React, { useCallback, useEffect, useMemo, useState } from 'react'

import DetailsGrid from '../../components/RivataGrid/DetailsGrid'
import RivataLoader from '../../components/RivataLoader'
import RivataModule from '../../components/RivataModule'
import StatusAlert from '../../components/StatusAlert'

import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { useColumns, useRows } from './hooks'
import ReportsHistoryGridFilter from './ReportsHistoryGridFilter'
import moment from 'moment'
import { MenuItemType } from '../../componentsV2/Menu/MenuItem'
import { debounce } from 'lodash'
import { dateToEpoch } from '../../utils'
import ReportGrid from '../ReportGrid'
import ReportParametersModal from '../ReportParametersModal'
const debouncer = debounce((func: () => void) => func(), 300);

const closeIcon = require('../../assets/images/close.png')

const ReportsHistoryGrid: React.FC = () => {
  const {
    reportsHistory: { isLoading, data },
    whitelabel: { locale },
    selectedCustomersList
  } = useTypedSelector((state) => ({
    auth: state.auth,
    reportsHistory: state.reportsHistory,
    whitelabel: state.whitelabel,
    selectedCustomersList: state.common.customers.selectedCustomersList,
  }))
  const [dateFrom, setDateFrom] = useState(
    new Date(moment().clone().startOf('month').unix() * 1000),
  )
  const [dateTo, setDateTo] = useState(
    moment().hours(23).minutes(59).seconds(0).toDate(),
  )
  const [reportStatus, setReportStatus] = useState<Array<string>>([])
  const [reportType, setReportType] = useState<Array<string>>([])
  const [showResults, setShowResults] = useState<boolean>(false)
  const [showParameters, setShowParameters] = useState<boolean>(false)
  const [selectedReportResult, setSelectedReportResult] = useState<any>(undefined)
  const { fetchReportsHistory, fetchReportHistoryResult } = useActions()

  useEffect(() => {
    const { startDate, endDate } = dateToEpoch(dateFrom, dateTo)
    fetchReportsHistory(reportStatus, reportType, startDate, endDate)
  }, [fetchReportsHistory, reportStatus, reportType, dateFrom, dateTo, selectedCustomersList])

  const onShowResults = useCallback(
    (item: any) => {
      fetchReportHistoryResult(item.id, item.report_name.startsWith("INTERNAL_"))
      setSelectedReportResult(item)
      setShowResults(!showResults)
    },
    [setShowResults, showResults, setSelectedReportResult, fetchReportHistoryResult],
  )
  const onShowParameters = useCallback(
    (item: any) => {
      setSelectedReportResult(item)
      setShowParameters(!showParameters)
    },
    [setShowParameters, showParameters, setSelectedReportResult],
  )
  const columns = useColumns(data, onShowResults, onShowParameters)
  const rows = useRows(data)

  const gridHeight = useMemo(
    () => (rows.length > 10 ? '495px' : `${rows.length * 45 + 47}px`),
    [rows],
  )

  const onDateRangeSelect = useCallback(
    (from, to) => {
      debouncer(() => {
        setDateFrom(from)
        setDateTo(to)
      });
    },
    [setDateFrom, setDateTo],
  )
  const onReportStatusSelected = useCallback((id: string, items: Array<MenuItemType>) => {
    const checked = items.filter((el) => el.checked);
    debouncer(() =>
      setReportStatus(
        checked.map((el) => el.id)
      )
    );
  },
    [])
  const onReportTypeSelected = useCallback((id: string, items: Array<MenuItemType>) => {
    const checked = items.filter((el) => el.checked);
    debouncer(() =>
      setReportType(
        checked.map((el) => el.id)
      )
    );
  },
    [])
  const fullScreenAction = (show: boolean) => {
    if (!show)
      setShowResults(false)
  }
  const parametersFullScreenAction = (show: boolean) => {
    if (!show)
      setShowParameters(false)
  }
  return (
    <>
      <RivataModule
        title='Reports History'
        locale={locale}
        filters={null}
        marginTop={0}
      >
        <ReportsHistoryGridFilter dateFrom={dateFrom} dateTo={dateTo}
          onDateRangeSelect={onDateRangeSelect}
          onReportStatusSelected={onReportStatusSelected}
          onReportTypeSelected={onReportTypeSelected} />
        {isLoading && <RivataLoader />}

        {rows.length ? (
          <DetailsGrid columns={columns} rows={rows} height={gridHeight} enableVirtualization={true} />
        ) : !isLoading ? (
          <StatusAlert
            customText={'No Data'}
            color='success'
            statusCode={undefined}
            statusText={undefined}
          />
        ) : (
          ''
        )}
      </RivataModule>
      {showResults && <ReportGrid
        selectedReport={selectedReportResult?.report_name}
        locale={locale}
        dateRangeString={''}
        showInModal={true}
        customFullScreenAction={fullScreenAction}
        customCollapseIcon={closeIcon}
      />}{showParameters && <ReportParametersModal
        locale={locale}
        customFullScreenAction={parametersFullScreenAction}
        selectedReport={selectedReportResult}
      />}
    </>
  )
}

export default ReportsHistoryGrid
