import React, { useState, useMemo, useEffect } from 'react';
import { Field, Formik, Form, useField, useFormikContext } from 'formik';
import { Col, Label, FormGroup, Input, FormFeedback, Row, Button, Fade } from 'reactstrap';
import * as Yup from "yup";
import doubleTrailerAxle from "../../assets/images/tpms_profile/DoubleTrailerAxle.svg";
import doubleDriveAxle from "../../assets/images/tpms_profile/DoubleDriveAxle.svg";
import singleSteerAxle from "../../assets/images/tpms_profile/SingleSteerAxle.svg";
import tractor from "../../assets/images/tpms_profile/Tractor.svg";
import DeleteModal from '../DeleteModal';
import { WARNING_TYPE, INSTALLMENT_POSITION_TYPE } from '../../constants';
import { Prompt } from 'react-router-dom';
import InfoModal from '../InfoModal';
import { UnitsOfMeasurement, TractorBubblePositions, TrailerBubblePositions } from '../../enums'
import { barToPsi } from '../../utils';
import './styles.scss';

const schema = Yup.object().shape({
    low_pressure_in_percent: Yup.number().required("Required").negative("Low pressure can't be over 0"),
    critical_low_pressure_in_percent: Yup.number().required("Required").negative("Critical low pressure can't be over 0").lessThan(Yup.ref('low_pressure_in_percent'), "Critical Low Pressure % must be lower than Low Pressure %"),
    over_pressure_in_percent: Yup.number().required("Required").positive("Over pressure can't be negative"),
    critical_over_pressure_in_percent: Yup.number().required("Required").positive("Critical over pressure can't be negative").moreThan(Yup.ref('over_pressure_in_percent'), "Critical Over Pressure %  must be higher than Over Pressure %"),

    tractor: Yup.boolean().notRequired(),
    trailer: Yup.boolean().notRequired(),
    tractor_axle_1: Yup.number().notRequired().positive("Cold inflation't be negative"),
    tractor_axle_2: Yup.number().notRequired().positive("Cold inflation't be negative"),
    tractor_axle_3: Yup.number().notRequired().positive("Cold inflation't be negative"),
    tractor_axle_4: Yup.number().notRequired().positive("Cold inflation't be negative"),
    tractor_axle_5: Yup.number().notRequired().positive("Cold inflation't be negative"),
    trailer_axle_1: Yup.number().notRequired().positive("Cold inflation't be negative"),
    trailer_axle_2: Yup.number().notRequired().positive("Cold inflation't be negative"),
    trailer_axle_3: Yup.number().notRequired().positive("Cold inflation't be negative"),
    trailer_axle_4: Yup.number().notRequired().positive("Cold inflation't be negative"),
    trailer_axle_5: Yup.number().notRequired().positive("Cold inflation't be negative"),
    trailer_axle_6: Yup.number().notRequired().positive("Cold inflation't be negative"),
});

export const FormField = ({
    className,
    md = "6",
    type = "text",
    children,
    label,
    unitsOfMeasurement,
    ...props
}) => {
    const { setFieldValue } = useFormikContext();

    const [field, meta] = useField(props);

    useEffect(() => {
        if (unitsOfMeasurement && (field.name.includes("tractor_axle") || field.name.includes("trailer_axle")) && !field.value) {
            if (unitsOfMeasurement === UnitsOfMeasurement.imperial) {
                setFieldValue(field.name, "100.00");
            } else {
                setFieldValue(field.name, "6.89");
            }
        }
    }, [field, unitsOfMeasurement, setFieldValue]);

    return (
        <Col md={md} className={className}>
            <Label>{label}</Label>
            <FormGroup className="form__filed">
                <Input
                    type={type}
                    {...field}
                    {...props}
                    tag={Field}
                    className="form__input"
                    invalid={!!(meta.touched && meta.error)}
                >
                    {children}
                </Input>
                <FormFeedback className="form__feedback">{meta.error}</FormFeedback>
            </FormGroup>
        </Col>
    );
};

const format = (formDetails, selectedProfile, unitsOfMeasurement) => {
    const { tractor_axle_1,
        tractor_axle_2,
        tractor_axle_3,
        tractor_axle_4,
        tractor_axle_5,
        trailer_axle_1,
        trailer_axle_2,
        trailer_axle_3,
        trailer_axle_4,
        trailer_axle_5,
        trailer_axle_6,
        low_pressure_in_percent,
        critical_low_pressure_in_percent,
        over_pressure_in_percent,
        critical_over_pressure_in_percent,
    } = formDetails;
    const o = {
        settings_id: selectedProfile.settings_id,
        name: selectedProfile.name,
        settings: [
            {
                warning_type_id: WARNING_TYPE.TIRE_PRESSURE_SENSOR,
                profile: {
                    low_pressure_in_percent,
                    critical_low_pressure_in_percent,
                    over_pressure_in_percent,
                    critical_over_pressure_in_percent,
                }
            },
        ]
    }

    const pushAxles = (installment_position_type_id, cold_inflation_pressure) => {
        cold_inflation_pressure = parseFloat(cold_inflation_pressure)
        o.settings.push({
            warning_type_id: WARNING_TYPE.TIRE_PRESSURE_SENSOR,
            installment_position_type_id,
            profile: {
                cold_inflation_pressure_in_psi: unitsOfMeasurement === UnitsOfMeasurement.imperial ? cold_inflation_pressure : barToPsi(cold_inflation_pressure)
            }
        })
    }

    if (tractor_axle_1) {
        pushAxles(INSTALLMENT_POSITION_TYPE.TRACTOR_STEER, tractor_axle_1)
    }
    if (tractor_axle_2) {
        pushAxles(INSTALLMENT_POSITION_TYPE.TRACTOR_FRONT, tractor_axle_2)
    }
    if (tractor_axle_3) {
        pushAxles(INSTALLMENT_POSITION_TYPE.TRACTOR_REAR_1, tractor_axle_3)
    }
    if (tractor_axle_4) {
        pushAxles(INSTALLMENT_POSITION_TYPE.TRACTOR_REAR_2, tractor_axle_4)
    }
    if (tractor_axle_5) {
        pushAxles(INSTALLMENT_POSITION_TYPE.TRACTOR_REAR_3, tractor_axle_5)
    }

    if (trailer_axle_1) {
        pushAxles(INSTALLMENT_POSITION_TYPE.TRAILER_1, trailer_axle_1)
    }
    if (trailer_axle_2) {
        pushAxles(INSTALLMENT_POSITION_TYPE.TRAILER_2, trailer_axle_2)
    }
    if (trailer_axle_3) {
        pushAxles(INSTALLMENT_POSITION_TYPE.TRAILER_3, trailer_axle_3)
    }
    if (trailer_axle_4) {
        pushAxles(INSTALLMENT_POSITION_TYPE.TRAILER_4, trailer_axle_4)
    }
    if (trailer_axle_5) {
        pushAxles(INSTALLMENT_POSITION_TYPE.TRAILER_5, trailer_axle_5)
    }
    if (trailer_axle_6) {
        pushAxles(INSTALLMENT_POSITION_TYPE.TRAILER_6, trailer_axle_6)
    }

    return o;
}

const Axle = ({ axleIndex, axleKey, axleType, assetType, drawAxle, disabled, style = {}, unitsOfMeasurement }) => {
    const [imageLoaded, setImageLoaded] = useState(false);

    const axles = {
        doubleTrailerAxle,
        doubleDriveAxle,
        singleSteerAxle,
    };

    const src = axles[axleType];

    let bubblePositions = [];

    if (assetType === "tractor") bubblePositions = TractorBubblePositions;
  
    if (assetType === "trailer") bubblePositions = TrailerBubblePositions;  
  
    return (
      <Fade timeout={50} in={imageLoaded}>
        <div key={axleKey} style={style}>
          {drawAxle && (
            <Row className="axle-row">
              <img className={`axle ${axleType}`} src={src} alt={axleKey} onLoad={() => setImageLoaded(true)} />

              <div className="position-bubble-tpms">
                <span>{bubblePositions[axleIndex]}</span>
              </div>

              <FormField disabled={disabled} md={3} name={axleKey} type="number" step="1" unitsOfMeasurement={unitsOfMeasurement} required />
            </Row>
          )}
        </div>
      </Fade>
    );
};

const TpmsProfileConfiguratiomForm = ({
    initialValues,
    disabled,
    locale,
    profiles,
    setTpmsProfiles,
    selectedProfile,
    setSelectedTpmsProfile,
    onCreateUpdate,
    onDeleteTpmsSettings,
    unitsOfMeasurement,
}) => {
    const [formChanged, setFormChanged] = useState(false)
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [isChangedTractorAxles, setIsChangedTractorAxles] = useState(false);
    const [isChangedTrailerAxles, setIsChangedTrailerAxles] = useState(false);
    const [infoModalMessage, setInfoModalMessage] = useState("");
    const [errorModalMessage, setErrorModalMessage] = useState("");
    const [status, setStatus] = useState({});

    const addAxleHandler = (type) => {
        let profile = selectedProfile
        switch (type) {
            case "tractor":
                if (profile.axels.tractorViewAxlesCount < 5) {
                    profile.axels.tractorViewAxlesCount += 1;
                    setIsChangedTractorAxles(true)
                }
                break
            case "trailer":
                if (profile.axels.trailerViewAxlesCount < 5) {
                    profile.axels.trailerViewAxlesCount += 1;
                    setIsChangedTrailerAxles(true)
                }
                break
            default:
                break
        }
        setSelectedTpmsProfile(profile)
    }

    const removeAxleHandler = (type, setFieldValue) => {
        let profile = selectedProfile
        switch (type) {
            case "tractor":
                const tractorViewAxlesCount = profile.axels.tractorViewAxlesCount;
                if (tractorViewAxlesCount > 2) {
                    const key = Object.keys(profile.axels.tractor)[tractorViewAxlesCount - 1];
                    profile.axels.tractorViewAxlesCount -= 1;
                    resetAxleValue(setFieldValue, type, key);
                    setIsChangedTractorAxles(true)
                }
                break
            case "trailer":
                const trailerViewAxlesCount = profile.axels.trailerViewAxlesCount;
                if (trailerViewAxlesCount > 1) {
                    const key = Object.keys(profile.axels.trailer)[trailerViewAxlesCount - 1];
                    profile.axels.trailerViewAxlesCount -= 1;
                    resetAxleValue(setFieldValue, type, key)
                    setIsChangedTrailerAxles(true)
                }
                break
            default:
                break
        }
        setSelectedTpmsProfile(profile)
    }
    const resetAxleValue = (setFieldValue, partOfTruck, axleKey = null) => {
        let profile = selectedProfile;
        if (axleKey) {
            setFieldValue(axleKey, "");
            profile.axels[partOfTruck][axleKey] = "";
        }
        
        else {
            Object.keys(profile.axels[partOfTruck]).forEach(axleKey => {
                setFieldValue(axleKey, "");
                profile.axels[partOfTruck][axleKey] = "";
            });
        }
    }

    const renderTractorAxels = useMemo(() => {
        if (selectedProfile.axels.hasTractor || isChangedTractorAxles) {
            const keys = Object.keys(selectedProfile.axels.tractor)
            setIsChangedTractorAxles(false)
            return keys.map((key, i) => {
                if (i < selectedProfile.axels.tractorViewAxlesCount) {
                    if (i === 0) {
                        return (
                            <Axle 
                                key={i+key}
                                axleIndex={i}
                                axleKey={key}
                                assetType="tractor"
                                axleType="singleSteerAxle"
                                drawAxle={selectedProfile.axels} 
                                disabled={disabled}
                                style={{ marginBottom: "170px" }}
                                unitsOfMeasurement={unitsOfMeasurement}
                            />
                        )
                    }

                    return (
                        <Axle 
                            key={i+key}
                            axleIndex={i}
                            axleKey={key}
                            assetType="tractor"
                            axleType="doubleDriveAxle"
                            drawAxle={selectedProfile.axels.tractor[key] >= 0} 
                            disabled={disabled}
                            unitsOfMeasurement={unitsOfMeasurement}
                        />
                    )
                }
                return isChangedTractorAxles
            })
        }
    }, [disabled, selectedProfile, isChangedTractorAxles, unitsOfMeasurement])

    const renderTrailerAxels = useMemo(() => {
        if (selectedProfile.axels.hasTrailer || isChangedTrailerAxles) {
            setIsChangedTrailerAxles(false)
            const keys = Object.keys(selectedProfile.axels.trailer)
            return keys.map((key, i) => {
                if (i < selectedProfile.axels.trailerViewAxlesCount) {
                    return (
                        <Axle 
                            key={i+key}
                            axleIndex={i}
                            axleKey={key}
                            assetType="trailer"
                            axleType="doubleTrailerAxle"
                            drawAxle={selectedProfile.axels.trailer[key] >= 0} 
                            disabled={disabled}
                            unitsOfMeasurement={unitsOfMeasurement}
                        />
                    )
                }
                return null
            })
        }
        return isChangedTrailerAxles
    }, [disabled, selectedProfile, isChangedTrailerAxles, unitsOfMeasurement])

    const selectVehicleHandler = (type, setFieldValue) => {
        const axleType = type === "hasTractor" ? "tractor" : "trailer";
        const viewAxles = type === "hasTractor" ? "tractorViewAxlesCount" : "trailerViewAxlesCount"

        resetAxleValue(setFieldValue, axleType, null)

        let profile = { ...selectedProfile, axels: { ...selectedProfile.axels, [type]: !selectedProfile.axels[type], [viewAxles]: selectedProfile.axels[viewAxles] === 0 ? 2 : 0 } }

        setSelectedTpmsProfile(profile);
        const changedProfileIndex = profiles.findIndex((el) => {
            if (el.id === selectedProfile.id) {
                return true
            }
            return false
        })

        let tpmsProfiles = profiles
        tpmsProfiles[changedProfileIndex] = { ...profile }

        setTpmsProfiles([...tpmsProfiles])
    }

    useEffect(() => {
        if (formChanged) {
            window.onbeforeunload = () => true
        } else {
            window.onbeforeunload = null
        }
    }, [formChanged]);

    const tractorRearHeight = useMemo(() => {
        return (selectedProfile.axels.tractorViewAxlesCount - 1) * 70 + 10;
    }, [selectedProfile.axels.tractorViewAxlesCount]);

    const trailerHeight = useMemo(() => {
        let height = (selectedProfile.axels.trailerViewAxlesCount + 1) * 70;

        if (height < 140) height = 140;

        return height;
    }, [selectedProfile.axels.trailerViewAxlesCount]);
    
    return (
        <>
            <Prompt when={formChanged} message={"You have unsaved changes. Do you want to leave the page?"} />
            <div className="tpms-configuration-form">
                <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validationSchema={schema}
                    onSubmit={async (values) => {
                        const res = await onCreateUpdate(format(values, selectedProfile, unitsOfMeasurement));
                        let assetsString = ""
                        if (Array.isArray(res.errorAssets)) {
                            res.errorAssets.map((assetName, i) => {
                                if (i === res.errorAssets.length - 1) {
                                    return assetsString += `${assetName}.`
                                }
                                return assetsString += `${assetName}, `
                            })
                            if (res.statusCode === 400) {
                                setErrorModalMessage(`Profile assigned to assets: ${assetsString} \n Please Unassign this profile from these assets to change this profile.`)
                            }
                        } else {
                            setStatus(res)
                        }
                    
                        setFormChanged(false);
                    }}
                >
                    {(props) => {
                        const { submitForm, handleReset, setFieldValue } = props;
                        // Change to dinamic render
                        const{low_pressure_in_percent, critical_low_pressure_in_percent, over_pressure_in_percent, critical_over_pressure_in_percent} = props.values
                        const checkFormValues = ()=>{
                            if( critical_low_pressure_in_percent > low_pressure_in_percent){
                                setErrorModalMessage("Low Pressure can't be less than the Critical Low Pressure")
                                return
                            }
                            if( critical_over_pressure_in_percent < over_pressure_in_percent){
                                setErrorModalMessage("Ower Pressure can't be higher than the Critical High Pressure")
                                return
                            }
                            else{
                                submitForm()
                            }
                        }

                        return (
                            <Form 
                                onChange={() => { 
                                    if (!formChanged) {
                                    setFormChanged(true)
                                }}}
                                onSubmit={(e) => {
                                    e.preventDefault()
                                    checkFormValues()
                                }}
                            >
                                <Row className="col-wrapper" md={12}>
                                    <Col md={4}>
                                        <h6 className="ml-3 mb-2">Set Alert Thresholds</h6>
                                        <FormField md={6} disabled={disabled} style={{ width: "100%" }} name="low_pressure_in_percent" label="Low Pressure %" type="number" step="1" />
                                        <FormField md={6} disabled={disabled} style={{ width: "100%" }} name="critical_low_pressure_in_percent" label="Critical Low Pressure %" type="number" step="1" />
                                        <FormField md={6} disabled={disabled} style={{ width: "100%" }} name="over_pressure_in_percent" label="Over Pressure %" type="number" step="1" />
                                        <FormField md={6} disabled={disabled} style={{ width: "100%" }} name="critical_over_pressure_in_percent" label="Critical Over Pressure %" type="number" step="1" />
                                    </Col>
                                    <Col md={4}>
                                        <h6>{`Set Cold Inflation Pressure (${unitsOfMeasurement === UnitsOfMeasurement.imperial ? "PSI" : "BAR"}):`}</h6>

                                        {selectedProfile.name !== "Default" ? (
                                            <Field
                                                disabled={disabled}
                                                className="mr-2"
                                                type="checkbox"
                                                name="tractor"
                                                onChange={(e) => selectVehicleHandler("hasTractor", setFieldValue, e)}
                                            />
                                        ) : null}

                                        <label htmlFor="tractor"><b>Tractor</b></label>

                                        {selectedProfile.axels.hasTractor ? (
                                            <Fade timeout={500}>
                                                <div className="d-flex flex-column align-items-start asset-container">

                                                    <img src={tractor} alt="tractor" width={200} />

                                                    <div className="tpms-tractor-rear" style={{ width: "50px", height: `${tractorRearHeight}px` }} />

                                                    <div className="d-flex flex-column axles-container" style={{ marginTop: "30px" }}>
                                                        {renderTractorAxels}
                                                    </div>

                                                    {selectedProfile.name !== "Default" ? (
                                                        <div className="add-remove-buttons">
                                                            <Button
                                                                className="btn btn-add ml-3"
                                                                disabled={disabled}
                                                                name="add_trailer_axe"
                                                                type="button"
                                                                onClick={() => removeAxleHandler("tractor", setFieldValue)}
                                                            >
                                                                &#8211;
                                                            </Button>
                                                            <Button
                                                                className="btn btn-add ml-2"
                                                                disabled={disabled}
                                                                type="button"
                                                                name="add_tractor_axe"
                                                                onClick={() => addAxleHandler("tractor")}
                                                            >
                                                                &#10010;
                                                            </Button>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Fade>
                                        ): null}

                                        <br />

                                        {selectedProfile.name !== "Default" ? (
                                            <Field
                                                disabled={disabled}
                                                className="mr-2"
                                                type="checkbox"
                                                name="trailer"
                                                onChange={() => selectVehicleHandler("hasTrailer", setFieldValue)}
                                            />
                                        ) : null}

                                        <label htmlFor="trailer" style={{ marginBottom: "1rem" }}><b>Trailer</b></label>

                                        {selectedProfile.axels.hasTrailer ? (
                                            <Fade timeout={500}>
                                                <div className="d-flex flex-column align-items-start asset-container">
                                                    <div className="trailer" style={{ width: "160px", height: `${trailerHeight}px` }} />

                                                    <div className="d-flex flex-column axles-container" style={{ paddingTop: "65px" }}>
                                                        {renderTrailerAxels}
                                                    </div>

                                                    {selectedProfile.name !== "Default" ? (
                                                        <div className="add-remove-buttons">
                                                            <Button
                                                                className="btn btn-add ml-3"
                                                                disabled={disabled}
                                                                name="add_trailer_axe"
                                                                type="button"
                                                                onClick={() => removeAxleHandler("trailer", setFieldValue)}
                                                            >
                                                                &#8211;
                                                            </Button>
                                                            <Button
                                                                className="btn btn-add ml-2"
                                                                disabled={disabled}
                                                                name="add_trailer_axe"
                                                                type="button"
                                                                onClick={() => addAxleHandler("trailer")}
                                                            >
                                                                &#10010;
                                                            </Button>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Fade>
                                        ) : null}
                                    </Col>
                                </Row>
                                <div className="edit-button-container">
                                    {formChanged && (
                                        <Button
                                            className="btn"
                                            onClick={() => { handleReset(); setFormChanged(false); }}>
                                            Reset
                                        </Button>
                                    )}
                                    {selectedProfile.name !== "Default" ? (
                                        <Button
                                            className="btn"
                                            color="danger"
                                            onClick={() => setDeleteModalVisible(true)}>
                                            Delete
                                        </Button>
                                    ) : null}
                                    <Button
                                        className="btn"
                                        color="primary"
                                        type="submit"
                                    >
                                        Save
                                    </Button>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
            <DeleteModal
                open={deleteModalVisible}
                disabled={false}
                header={`Delete profile '${selectedProfile.label}'?`}
                // message={selectedProfile.name === "default" ? `Are you sure want to delete profile "Default"? All assets assigned this profile and default profile will be reset to system default`
                //     : `Are you sure want to delete profile "${selectedProfile.label}"? All assets assigned this profile will be reset to "Default"`}
                message={`Are you sure you want to delete profile "${selectedProfile.label}"?`}
                onDelete={() => {
                    setDeleteModalVisible(false);
                    if (selectedProfile.settings_id) {
                        onDeleteTpmsSettings(selectedProfile.settings_id).then(res => {
                            if (res && res.status === "assets_assigned" && res.assigned_assets_names.length) {
                                setInfoModalMessage(`'${selectedProfile.label}' profile is assigned to assets: ${res.assigned_assets_names.join(', ')}.\nPlease unassign profile from assets before deleting.`);
                            } else if (res && res.status === "ok") {
                                setStatus({ statusCode: 200, message: `Profile "${selectedProfile.name}" successfully deleted` })
                            }
                        });
                    }
                    else {
                        // soft delete ( was not posted to api)
                        const p = profiles.filter(f => f.id !== selectedProfile.id);
                        setTpmsProfiles(p)
                        setSelectedTpmsProfile(p[0] || {})
                    }
                }}
                onCancel={() => setDeleteModalVisible(false)}
                locale={locale}
            />
            <InfoModal open={!!infoModalMessage} header={`Profile can not be deleted`} message={infoModalMessage} onConfirm={() => setInfoModalMessage("")} />
            <InfoModal open={!!errorModalMessage} header={`Profile can not be updated`} message={errorModalMessage} onConfirm={() => setErrorModalMessage("")} />
            <InfoModal open={status.statusCode >= 200} header={`TPMS profile info`} message={status?.message || ""} onConfirm={() => setStatus({})} />
        </>
    )
}

export default TpmsProfileConfiguratiomForm;