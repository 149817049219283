import React, { useEffect, useState } from 'react'
import { ReportParameterRowProps } from './ReportParameterRowProps'
import { Label } from 'reactstrap'
import classes from './styles.module.scss'
import api from '../../../services/api'
const GeofencesRow: React.FC<ReportParameterRowProps> = (
  props: ReportParameterRowProps,
) => {
  const { value } = props
  const [displayValue, setDisplayValue] = useState<string>('All')
  useEffect(() => {
    if (
      !value ||
      (value &&
        ((Array.isArray(value) && value.length === 0) ||
          (!Array.isArray(value) && value.toLowerCase() === 'all')))
    ) {
      setDisplayValue('All')
    } else {
      const fetchGeofences = async () => {
        try {
          const data = await api.getAllGeofencesShortFormat(
            false,
            Array.isArray(value) ? value.join(',') : value.split(':').join(','),
          )

          if (data && data.length > 0) {
            const val: string[] = data.map((item: any) => {
              return `${item.name}`
            })
            setDisplayValue(val.join(', '))
          }
        } catch (err) {
          console.log(err)
        }
      }
      fetchGeofences()
    }
  }, [value])
  return (
    <div className={classes.reportParameterRow}>
      <Label className={classes.label}>Geofences:</Label>
      <Label className={classes.value}>{displayValue}</Label>
    </div>
  )
}
export default GeofencesRow
