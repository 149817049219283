import React, { useEffect, useMemo } from "react";

import Subnav from '../../components/SubNav'
import DashboardBody from "./DashBody";
import CustomerSelector from "../../modules/CustomerSelector";

import { useActions } from "../../hooks/useActions";
import { useTypedSelector} from "../../hooks/useTypedSelector";

const breadcrumbs = [{ label: "Home" }]

const Dashboard = () => {
  const {
    whiteLabelConfig,
    locale,
  } = useTypedSelector(state => ({
    whiteLabelConfig: state.whitelabel.dash,
    locale: state.whitelabel.locale,
  }))
  const {
    fetchGeofences,
    setAssetsOffset,
    removeAssetGroupFilter
  } = useActions()

  useEffect(() => {
    fetchGeofences()
  }, [fetchGeofences])

  const components = useMemo(() => {
    if (Array.isArray(whiteLabelConfig)) {
      return whiteLabelConfig
        .filter((component) => component.data.enabled)
        .map(({ id, data }) => ({ component: id, width: data.width }));
    }
    return [];
  }, [whiteLabelConfig]);

  return (
    <>
      <Subnav 
        title="Dashboard"
        breadcrumbs={breadcrumbs}
      >
        <CustomerSelector
          onSubmit={() => {
            setAssetsOffset(0)
            removeAssetGroupFilter()
            fetchGeofences()
          }}
        />
      </Subnav>
      <DashboardBody
        components={components}
        locale={locale}
      />
    </>
  );
};

export default Dashboard;
