import {
  LOADING_SCHEDULED_REPORTS,
  LOADING_SCHEDULED_REPORTS_USERS,
  SET_SCHEDULED_REPORTS,
  SET_SCHEDULED_REPORTS_USERS,
} from '../actionTypes'
import api from '../../services/api'
import { IgetState } from '../types'
import { ApiError } from '../../services/api/base'
import { getStatusObj } from '../../utils/utils'
import ReportApi from '../../services/api/ApiGroups/ReportApi'
import userApi from '../../services/api/ApiGroups/UserApi'

export const loadingScheduledReports = (isLoading: boolean) => ({
  type: LOADING_SCHEDULED_REPORTS,
  payload: isLoading,
})

const setScheduledReports = (scheduledReports: Array<IScheduledReport>) => ({
  type: SET_SCHEDULED_REPORTS,
  payload: scheduledReports,
})

const loadingScheduledReportsUsers = (isLoading: boolean) => ({
  type: LOADING_SCHEDULED_REPORTS_USERS,
  payload: isLoading,
})

const setScheduledReportsUsers = (reportUsers: Array<IUserShortFormat>) => ({
  type: SET_SCHEDULED_REPORTS_USERS,
  payload: reportUsers,
})

export function fetchScheduledReports(
  show_internal_reports: boolean = false,
  report_type: string = 'ALL',
) {
  return function (dispatch: any, getState: IgetState) {
    dispatch(loadingScheduledReports(true))

    return api
      .getScheduledReports(show_internal_reports, report_type)
      .then((scheduledReports) => {
        dispatch(setScheduledReports(scheduledReports))
        dispatch(loadingScheduledReports(false))
      })
      .catch((err) => {
        console.log(err)
        dispatch(loadingScheduledReports(false))
      })
  }
}

export async function updateScheduledReport(
  scheduledReportId: number,
  schedule: string,
  parameters: string,
  toggle: boolean,
  name: string,
  customer_id: number | null,
) {
  try {
    await ReportApi.putScheduledReport(
      scheduledReportId,
      schedule,
      parameters,
      name,
      customer_id,
    )

    if (toggle) {
      await ReportApi.putEnableScheduledReport(scheduledReportId)
    }

    return getStatusObj({ statusCode: 200 }, 'Scheduled report updated!')
  } catch (err) {
    if (err instanceof ApiError) {
      let message = err.message.split(':')
      message.splice(0, 1)

      return getStatusObj(err, message.join(':'))
    }

    return getStatusObj(err)
  }
}

export async function createScheduledReport(
  schedule: string,
  parameters: string,
  toggle: boolean,
  name: string,
  type: string,
  customer_id: number | null,
) {
  try {
    let response = await ReportApi.postScheduledReport(
      schedule,
      parameters,
      name,
      type,
      customer_id,
    )

    if (toggle) {
      await ReportApi.putEnableScheduledReport(response.id)
    }

    return getStatusObj({ statusCode: 200 }, 'Scheduled report created!')
  } catch (err) {
    if (err instanceof ApiError) {
      let message = err.message.split(':')
      message.splice(0, 1)

      return getStatusObj(err, message.join(':'))
    }

    return getStatusObj(err)
  }
}

export function fetchScheduledReportsUsers(customerIds = '') {
  return function (dispatch: any, getState: IgetState) {
    dispatch(loadingScheduledReportsUsers(true))

    return userApi
      .getUsersByCustomerIdsWithShortDataFormat(customerIds)
      .then((users) => {
        dispatch(setScheduledReportsUsers(users))
        dispatch(loadingScheduledReportsUsers(false))
      })
      .catch((err) => {
        console.log(err)
        dispatch(loadingScheduledReportsUsers(false))
      })
  }
}
