export const behaviorUi = (hMap: H.Map, defaultLayers: H.service.DefaultLayers) => {
  const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(hMap));

  const ui = H.ui.UI.createDefault(hMap, defaultLayers);

  return { behavior, ui };
};

// @ts-ignore
export const wktToGeometry = (wkt: string) => H.util.wkt.toGeometry(wkt);

// NOTE: adjust values when changing content
export const getTooltipCoordinates = (x: number, y: number, width: number, data: { name: string }) => {
  const tooltipValues = Object.values(data);
  if (!tooltipValues.length) return { x, y };

  const longestStringLength = Math.max(...tooltipValues.map((v) => v?.toString().length));
  const tooltipHeight = 35;
  let tooltipWidth = 7 * longestStringLength;

  if (tooltipWidth < 85) tooltipWidth = 85;

  if (y < tooltipHeight) y += tooltipHeight;

  if (x < tooltipWidth) x = tooltipWidth;

  if (x > width - tooltipWidth) x -= tooltipWidth / 2;

  return { x, y };
};

export const getTooltipContent = (data: IGeofence) => {
  const labelColor = "#4c86c2";
  return `
    <div 
      class="alert-primary cluster show" 
      role="alert" 
      aria-live="assertive" 
      aria-atomic="true"" 
      style="background-color: ${labelColor}; border-color: ${labelColor};"
    >
      <div class="label-line"></div>
      <table class="table-responsive table-light table-sm m-0 p-0">
      <tbody>
        <tr>
          <th scope="row">Geofence: </th>
          <td colspan=3>${data.name}</td>
        </tr>
        <tr>
          <th scope="row">Created: </th>
          <td colspan=3>${data.formatted_created_at}</td>
        </tr>
        <tr>
        <th scope="row">Customer:</th>
        <td colspan=3>${data.customer_name}</td>
        <td class="aligned-cell details"><a id="locationMapBubble">Details</a></td>
      </tr>
      </tbody>
    </table>
    </div>
  `;
};
