import { WARNING_TYPE } from '../../constants';
import api from '../../services/api';
import { composeTpmsProfiles } from '../../utils';
import {
    SET_TPMS_PROFILES,
    SET_TPMS_SELECTED_PROFILE,
    LOADING_TPMS_PROFILES,
} from '../actionTypes';

export const setLoadingTpmsProfiles = (isLoading) => ({
    type: LOADING_TPMS_PROFILES,
    payload: isLoading,
})

export const setTpmsProfiles = (profiles) => ({
    type: SET_TPMS_PROFILES,
    payload: profiles,
})

export const setSelectedTpmsProfile = (profile) => ({
    type: SET_TPMS_SELECTED_PROFILE,
    payload: profile,
})

export const deleteTpmsSettings = (settings_id) => {
    return function (dispatch) {
        dispatch(setLoadingTpmsProfiles(true));
        return api
            .deleteWarningSettings(settings_id)
            .then(data => {
                dispatch(fetchTpmsProfiles())
                return data;
            }).catch(err => {
            }).finally(() => {
                dispatch(setLoadingTpmsProfiles(false));
            })
    }
}

export const addUpdateTpmsSettings = (settings) => {
    return function (dispatch, getState) {
        const selectedProfile = getState().tpmsProfiles.selectedTpmsProfile
        const selectedCustomersList = getState().common.customers.selectedCustomersList
        if (selectedCustomersList.length !== 1) return

        dispatch(setLoadingTpmsProfiles(true));
        if (settings.settings_id) {
            // update
            return api
                .putWarningSettings({ ...settings, customer_id: selectedCustomersList[0].id })
                .then((r) => {
                    dispatch(fetchTpmsProfiles(true, selectedProfile.id, selectedCustomersList[0].id)) // (set prev profile, profile id, selected custmer id)
                    return { statusCode: 200, message: `Profile "${selectedProfile.name}" successfully updated` }
                })
                .catch((err) => {
                    let message = err.message.split("[")
                    message = message[1].split("]")
                    message = message[0].split(", ")
                    dispatch(setLoadingTpmsProfiles(false));
                    return { statusCode: 400, errorAssets: message }
                })
        }
        else {
            // save
            return api
                .postWarningSettings({ ...settings, customer_id: selectedCustomersList[0].id })
                .then((r) => {
                    dispatch(fetchTpmsProfiles(true, selectedProfile.id, selectedCustomersList[0].id)) // (set prev profile, profile id, selected custmer id)
                    return { statusCode: 200, message: `Profile "${selectedProfile.name}" successfully saved` }
                })
                .catch((err) => {
                    return { statusCode: err.statusCode, errorAssets: err.message }
                })
        }
    };
}

export const fetchTpmsProfilesPage = () => {
    return function (dispatch, getState) {
        dispatch(setLoadingTpmsProfiles(true))
        const selectedCustomersList = getState().common.customers.selectedCustomersList
        if (selectedCustomersList.length === 1)
            dispatch(fetchTpmsProfiles(true, null, selectedCustomersList[0].id))
    }
}

export const fetchTpmsProfiles = (setSelectedProfile = true, selectedProfileId) => {
    return function (dispatch) {
        dispatch(setLoadingTpmsProfiles(true))

        return api.getWarningsSettings(WARNING_TYPE.TIRE_PRESSURE_SENSOR).then(warningSettings => {
            const profiles = composeTpmsProfiles(warningSettings)
            dispatch(setTpmsProfiles(profiles))
            if (setSelectedProfile) {
                const dflt = profiles.findIndex((el)=>el.name.toLowerCase() === "default") 
                if (profiles.length && dflt !== -1) {  
                    const selectedProfileIndex = selectedProfileId ? profiles.findIndex((el) => el.id === selectedProfileId ? true : false) : dflt
                    dispatch(setSelectedTpmsProfile(profiles[selectedProfileIndex])) // set edited profile as selected if It is or last profile in array
                }
            }
        }).catch(er => { }).finally(() => dispatch(setLoadingTpmsProfiles(false)))
    };
}
