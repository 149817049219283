import React from "react";
import { Link } from "react-router-dom";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import authService from "../../services/auth";

import brandLogo from "../../assets/images/conmet_brand/conmet_digital.png";
import "./style.scss";

const Login = () => {
  const refreshTokenExpired = useTypedSelector(
    (state) => state.auth.failedTokenRefresh.expired
  );

  return (
    <div className="auth-wrapper">
      <div className="auth-box on-sidebar">
        <div className="logo">
          <h2 className="welcome mb-3">Welcome To</h2>
          <img src={brandLogo} alt="logo" />
        </div>

        <a
          href={authService.getSignInUri()}
          className="btn btn-block btn-login mt-3"
        >
          Sign In
        </a>

        <Link
          to="/authentication/request-access"
          className="btn btn-block btn-req"
        >
          Request Account
        </Link>

        {refreshTokenExpired && (
          <div className="mt-3 text-secondary1">
            You have been logged in for 30days. Please login again
          </div>
        )}

        <a
          href="https://conmet.com/innovation/digital/"
          target="_blank"
          rel="noreferrer noopener"
          className="position-absolute sidebar-footer"
          style={{ bottom: "1rem", right: "1rem", fontSize: "1rem" }}
        >
          Learn More About Our Products
        </a>
      </div>
    </div>
  );
};

export default Login;
