import React, { useEffect, useState } from 'react'
import DataGrid, { Row } from 'react-data-grid'
import { IColumn, IRow } from '.'

import './style.scss'


interface Props {
    columns: Array<IColumn>
    rows: Array<IRow>
    height?: string
    selectedRowId?: number
    allowHorizontalScroll?: boolean
    enableVirtualization?: boolean
}

// grid with ability to expand subgrid https://adazzle.github.io/react-data-grid/#/master-detail
const DetailsGrid: React.FC<Props> = ({
    columns,
    rows,
    height,
    selectedRowId,
    allowHorizontalScroll = false,
    enableVirtualization = false
}) => {
    const [detailsRows, setDetailsRows] = useState(rows);

    useEffect(() => {
        setDetailsRows(rows)
    }, [rows])

    function onRowsChange(rows: Array<IRow>, { indexes }: { indexes: Array<number> }) {
        const row = rows[indexes[0]];

        if (row.type === 'MASTER') {
            if (!row.expanded) {
                rows.splice(indexes[0] + 1, 1);
            } else {
                rows.splice(indexes[0] + 1, 0, {
                    type: 'DETAIL',
                    id: row.id + 100,
                    parentId: row.id
                });
            }
            setDetailsRows(rows);
        }
    }

    return (
        <DataGrid
            className='rdg-light rivata-grid fill-grid' // light color if dart theme set
            style={{
                height,
                overflowX: allowHorizontalScroll ? undefined : "hidden",
            }}
            columns={columns}
            rows={detailsRows}
            defaultColumnOptions={{
                sortable: false,
                resizable: true
            }}
            onRowsChange={onRowsChange}
            rowKeyGetter={(row) => row.id}
            headerRowHeight={45}
            rowHeight={(args) => (args.type === 'ROW' && args.row.type === 'DETAIL' ? 300 : 45)}
            enableVirtualization={enableVirtualization}
            rowRenderer={(props) => <Row {...props} className={props.row.id === selectedRowId ? "row__highlight" : ""} />}
        />
    )
}

export default DetailsGrid
