import React, { useCallback, useEffect, useRef, useState } from 'react'
import RivataModule from '../../components/RivataModule'
import RivataTable from '../../components/RivataTable'
import { useAssign } from './utils'
import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import InfoModal from '../../components/InfoModal'
import { Button } from 'reactstrap'
import RivataDropdown from '../../components/RivataDropdown'
import ConfirmModal from '../../components/ConfirmModal'
import UploadCargoTagModal from './UploadCargoTagModal'
import ElementMigrateModal from '../AssetsProvisionTable/AssetModals/ElementMigrateModal'
import CargoTagsPreferences from './CargoTagsPreferences'
import EditCargoTagModal from './EditCargoTagModal'
import { makeScrollToElementWithOffset } from '../../utils/utils'
import CargoTagsFilterMenu from './CargoTagsFilterMenu'
import './styles.scss'

interface Props {
  locale: Record<string, string>
  unassigned: boolean
  setUnassigned: (value: boolean) => void
}
const columnsSelectorProps = {
  showDropdown: false,
  localeStorageKey: 'provision.usersTable.columns',
}
const Tags: React.FC<Props> = ({ locale = {}, unassigned, setUnassigned }) => {
  const {
    setTagsOffset,
    setTagsLimit,
    setTagsSortOptions,
    fetchCargotags,
    postCustomersCargoTagsCsv,
    setCargoTagUploadMessage,
    setUnassignedTags,
    setCargoTagsFilter,
    assignCargoTags,
    migrateCargoTag,
    putCargotag,
  } = useActions()

  useEffect(() => {
    fetchCargotags()
  }, [fetchCargotags])

  const {
    isLoading,
    pagedTags,
    limit,
    offset,
    totalCount,
    unassignedTags,
    customers: { selectedCustomersList, data },
    cargoTagUploadMessage,
  } = useTypedSelector((state) => ({
    isLoading: state.cargoTags.isLoading,
    pagedTags: state.cargoTags.pagedTags,
    limit: state.cargoTags.limit,
    totalCount: state.cargoTags.totalCount,
    offset: state.cargoTags.offset,
    unassignedTags: state.cargoTags.unassignedTags,
    customers: state.common.customers,
    cargoTagUploadMessage: state.cargoTags.cargoTagUploadMessage,
  }))

  const scrollTarget = useRef(null)

  const [editItem, setEditItem] = useState<ICargoTag | null>(null)
  const [cargotagToMigrate, setCargotagToMigrate] = useState<ICargoTag | null>(
    null,
  )

  const {
    getMACS,
    selectedMacs,
    setSelectedMacs,
    body,
    setBody,
    chosenCustomerName,
    customersWithTagsSubscription,
    selectedCustomerId,
    setSelectedCustomerId,
    onRequestBody,
    selectedRows,
    setSelectedRows,
    rows,
    columns,
    onClearAssignInfo,
    onCheckRowColumn,
    onCheckRowItem,
  } = useAssign(data, pagedTags, unassigned)

  const onUnassignedTagsFetch = () => {
    setUnassigned(!unassignedTags)
    setUnassignedTags(!unassignedTags)
  }

  const onTagMigration = (cargotag: ICargoTag) => {
    setCargotagToMigrate(cargotag)
  }

  const saveChangesHandler = () => {
    body && assignCargoTags(body)
    setSelectedRows([])
    setBody(null)
    setSelectedMacs([])
    setSelectedCustomerId('')
  }

  const saveMigratedCargotag = (values: {
    new_customer: string
    new_customer_name: string
  }) => {
    const customer_id = data.find(
      (el: ICustomer) => el.key === values.new_customer,
    ).id

    const hi_id = cargotagToMigrate?.hi_id as number

    const current_customer_id = data.find(
      (el: ICustomer) => el.name === cargotagToMigrate?.customer_name,
    ).id

    migrateCargoTag({ customer_id, hi_id, current_customer_id })
    setCargotagToMigrate(null)
  }

  const onEditBtnClick = useCallback((data) => {
    setEditItem(data)
  }, [])

  const onEditClose = useCallback(() => {
    setEditItem(null)
  }, [])

  const handlePageChange = useCallback(
    (offset) => {
      setTagsOffset(offset)
      makeScrollToElementWithOffset(scrollTarget, -200)
    },
    [setTagsOffset],
  )

  const handleLimitChange = useCallback(
    (limit) => {
      setTagsLimit(limit)
      makeScrollToElementWithOffset(scrollTarget, -200)
    },
    [setTagsLimit],
  )

  const btnText = !unassigned ? 'unassigned' : 'assigned'
  return (
    <>
      {body ? (
        <ConfirmModal
          open={true}
          onClose={onClearAssignInfo}
          modalButtons={[
            {
              id: 1,
              label: 'Save Changes',
              color: 'success',
              onClick: saveChangesHandler,
            },
          ]}
        >
          <div>
            <h5>Confirm Changes?</h5>
            <p>
              You are going to assign cargo tags: "{getMACS(selectedMacs)}" to
              the customer
            </p>
            <p>{chosenCustomerName}</p>
          </div>
        </ConfirmModal>
      ) : null}

      {editItem && (
        <EditCargoTagModal
          open={true}
          editItem={editItem}
          onEdit={putCargotag}
          onClose={onEditClose}
        />
      )}

      {cargotagToMigrate ? (
        <ElementMigrateModal
          elementName={cargotagToMigrate.mac}
          currentCustomerName={cargotagToMigrate.customer_name}
          locale={locale}
          onCreate={saveMigratedCargotag}
          onClose={() => setCargotagToMigrate(null)}
          disabled={false}
          elementType={'cargotag'}
          open={!!cargotagToMigrate}
          initialValues={{
            new_customer: cargotagToMigrate.customer_name,
          }}
          isLoading={isLoading}
        />
      ) : null}
      <RivataModule
        title='Cargo Tags'
        filters={
          <>
            {unassigned && (
              <RivataDropdown
                items={customersWithTagsSubscription}
                onSelect={onRequestBody}
                disabled={!selectedRows.length}
                caret={true}
                selected={selectedCustomerId}
                buttonLabel={'Assign Cargotags'}
                filters={undefined}
                onRemove={undefined}
                color={undefined}
              />
            )}
            <InfoModal
              onConfirm={() =>
                setCargoTagUploadMessage({ statusCode: 0, message: '' })
              }
              open={cargoTagUploadMessage.statusCode !== 0}
              message={cargoTagUploadMessage.message}
              header='Cargotags assigning info'
            />
            <Button
              variant='primary'
              className='ml-2 mr-2'
              disabled={isLoading}
              onClick={onUnassignedTagsFetch}
            >
              {`Show ${btnText} tags only`}
            </Button>

            {selectedCustomersList.length === 1 && (
              <UploadCargoTagModal
                postCustomersCargoTagsCsv={postCustomersCargoTagsCsv}
              />
            )}

            <div ref={scrollTarget} />
          </>
        }
        locale={locale}
      >
        <CargoTagsFilterMenu
          onFilter={setCargoTagsFilter}
          unassigned={unassigned}
        />

        <hr />

        <RivataTable
          showPagination={true}
          totalCount={totalCount}
          // @ts-ignore component expect undefined cause of default value
          onPageChange={handlePageChange}
          // @ts-ignore component expect undefined cause of default value
          onSelectLimit={handleLimitChange}
          isShowingLimit={true}
          pageLimit={limit}
          page={offset / limit}
          columns={columns}
          rows={rows}
          isLoading={isLoading}
          onDelete={undefined}
          onEdit={!unassigned && onEditBtnClick}
          customDeleteStyle={{ width: '50px' }}
          customEditStyle={{ width: '50px' }}
          onCustomAction={
            !unassigned ? (element: ICargoTag) => onTagMigration(element) : null
          }
          customActionIcon={'fas fa-arrows-alt-h'}
          customActionTooltipText='Migrate Cargotag'
          onCheckRowColumn={onCheckRowColumn}
          onCheckRowItem={onCheckRowItem}
          onSelectedRow={undefined}
          editDisabled={undefined}
          deleteDisabled={undefined}
          daysCount={undefined}
          limitDropdownItems={undefined}
          tpmsProfiles={undefined}
          setSortOptions={setTagsSortOptions}
          // @ts-ignore component expect undefined cause of default value
          columnsSelectorProps={columnsSelectorProps}
        />
      </RivataModule>

      <CargoTagsPreferences locale={locale} />
    </>
  )
}

export default Tags
