import { fetchPresignedUrl } from "../base"
import baseApi from "./BaseApi"

class ReportApi {
  getReport = (link: string) => {
    return baseApi.get(`rivata/report${link}`)
  }

  getReportStatusAndUrl = (key: string) => {
    return baseApi.get(`rivata/report_status?key=${key}`, false)
  }

  getReportFromUrl = async (url: string) => fetchPresignedUrl(url)

  getScheduledReports = (show_internal_reports: boolean = false, report_type: string = "ALL") => {
    return baseApi.get(`rivata/report/schedule?show_internal_reports=${show_internal_reports}&report_type=${report_type}`, true)
  }

  putScheduledReport = (scheduledReportId: number, schedule: string, parameters: string, name: string, customer_id: number|null) => {
    return baseApi.put(`rivata/report/schedule`, {id: scheduledReportId, schedule, parameters, name, customer_id})
  }

  putEnableScheduledReport = (scheduledReportId: number) => {
    return baseApi.put(`rivata/report/schedule/enable?id=${scheduledReportId}`, {})
  }

  postScheduledReport = (schedule: string, parameters: string, name: string, type: string, customer_id: number|null) => {
    return baseApi.post(`rivata/report/schedule`, {schedule, parameters, name, report_type_key: type, customer_id})
  }

  deleteScheduledReport = (scheduledReportId: number) => {
    return baseApi.delete(`rivata/report/schedule?id=${scheduledReportId}`)
  }
}

const reportApi = new ReportApi()

export default reportApi
