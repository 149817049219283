import React, { useEffect } from "react";

import SubNav from "../../components/SubNav";
import CustomerSelector from "../../modules/CustomerSelector";
import GeoLocations from "../../modules/GeoLocations";

import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";


const GeoLocationsPage = () => {
  const {
    locale
  } = useTypedSelector((state) => ({
    locale: state.whitelabel.locale,
    preferences: state.auth.preferences,
    auth: state.auth,
    common: state.common,
  }))

  const {
    fetchTagsLocations,
    setLocationsOffset,
    fetchLocationsWithCargoGps,
    fetchAssetsWithCargoGps,
    setTagsLocationsFilter
  } = useActions()

  useEffect(() => {
    fetchTagsLocations()
    fetchLocationsWithCargoGps()
    fetchAssetsWithCargoGps()
  }, [fetchTagsLocations, fetchLocationsWithCargoGps, fetchAssetsWithCargoGps])

  return (
    <>
      <SubNav
        title="Tag Locations"
        breadcrumbs={[
          { label: "Home", path: "/dashboard" },
          { label: "Tag Locations" },
        ]}
      >
        <CustomerSelector
          onSubmit={() => {
            setTagsLocationsFilter("");
            fetchLocationsWithCargoGps();
            fetchAssetsWithCargoGps();
          }}
        />
      </SubNav>

      <GeoLocations
        locale={locale}
        width={12}
        setLocationsOffset={setLocationsOffset}
      />
    </>
  );
};


export default GeoLocationsPage;
