import React, { useCallback, useEffect, useRef } from "react"
import { debounce } from "lodash"

import MacFilter from "../../componentsV2/MacFilter"
import CargoTagNameFilter from "../../componentsV2/CargoTagNameFilter"
import Button from "../../componentsV2/Button"

import { useTypedSelector } from "../../hooks/useTypedSelector"

interface Props {
  onFilter: (queryParams: string) => void
  unassigned: boolean
}

const debouncer = debounce((func: () => void) => func(), 400)

const wrapperStyle = { width: "470px" }

const CargoTagsFilterMenu: React.FC<Props> = ({ onFilter, unassigned }) => {
  const searchParams = useRef<Record<string, Array<string>>>({})
  const actualQuery = useRef("")
  const macFilterRef = useRef<any>(null)
  const cargoTagNameFilterRef = useRef<any>(null)

  const { selectedCustomersList } = useTypedSelector((state) => ({
    selectedCustomersList: state.common.customers.selectedCustomersList,
  }))

  const updateFilters = useCallback(
    (key: string, value: Array<string> | string) => {
      if (!value || !value.length) {
        delete searchParams.current[key]
      } else if (Array.isArray(value)) {
        searchParams.current[key] = value
      } else {
        searchParams.current[key] = [value]
      }

      const query = new URLSearchParams()

      Object.entries(searchParams.current).forEach((filter) => {
        const key = filter[0]
        filter[1].forEach((value) => query.append("filters", `${key}=${value}`))
      })
      actualQuery.current = query.toString()
      onFilter(actualQuery.current)
    },
    [onFilter]
  )

  const onSearch = (id: string, value: Array<string> | string) => {
    debouncer(() => updateFilters(id, value))
  }

  const handleFiltersReset = useCallback(
    (filterData: boolean = true) => {
      // clear only if filters not empty
      if (actualQuery.current === "") return

      actualQuery.current = ""
      searchParams.current = {}

      macFilterRef?.current?.clearValue()
      cargoTagNameFilterRef?.current?.clearValue()

      if (filterData) onFilter("")
    },
    [onFilter]
  )

  useEffect(() => {
    handleFiltersReset(false)
  }, [selectedCustomersList, unassigned, handleFiltersReset])

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap">
        <div className="d-flex flex-wrap">
          <MacFilter
            wrapperStyle={wrapperStyle}
            onChange={onSearch}
            ref={macFilterRef}
            wrapperClassName="mr-3"
          />

          {!unassigned && (
            <CargoTagNameFilter
              wrapperStyle={wrapperStyle}
              onChange={onSearch}
              ref={cargoTagNameFilterRef}
            />
          )}
        </div>

        <div>
          <Button
            onClick={() => {
              handleFiltersReset()
            }}
          >
            Clear
          </Button>
        </div>
      </div>
    </>
  )
}

export default React.memo(CargoTagsFilterMenu)
