
import React from "react";
import { Label, Button } from 'reactstrap'
import moment from "moment";
import classes from './styles.module.scss';

const AssetTable = ({
  label,
  value,
  onGatewayLogsGet,
  isSuperAdmin,
  data,
  gatewaysData,
  id = null
}) => {
  const onLogsHandler = () => {
    const { mac, formatted_last_updated_at_datetime } = data.filter(
      ({ mac }) => mac === value
    )[0];
    const {id} = gatewaysData.filter((gateway)=> gateway.mac === value)[0]
    const mc = mac.slice(3).split("-").join("");
    const dateNow = moment().format();
    const isFormattedDayTime =
      !formatted_last_updated_at_datetime ||
      formatted_last_updated_at_datetime === "-";
    const isEpoch = !isFormattedDayTime
      ? formatted_last_updated_at_datetime
      : dateNow;
    onGatewayLogsGet(id, isEpoch, "gateway_translator", mc);
  };
  return (
    <div className={classes.assetRow} id={id}>
      <Label className={classes.label}>{label}</Label>
      <Label className={classes.value}>{value}</Label>
      {isSuperAdmin ? (
        <div className={classes.btnWrap}>
          <Button outline color="secondary" size="sm" onClick={onLogsHandler} disabled={!data.length}>
            {"Logs"}
          </Button>
        </div>
      ) : (
        <div className={classes.btnWrap}></div>
      )}
    </div>
  );
};

export default AssetTable;