import { IColumn, IRow } from "../../../components/RivataGrid"
import { convertDataEpochToDate, psiToBar, getClearLabel } from '../../../utils'
import { UnitsOfMeasurement } from '../../../enums'

interface IcomposeTpmsReportColumns {
    (
        columns: Array<string>
    ): Array<IColumn>
}

export const tpmsReportColumns: IcomposeTpmsReportColumns = (columns) => {
    const parsedColumns: Array<IColumn> = []
    columns.forEach(key => {
        const name = getClearLabel(key) 
        if (key === "asset_name") {
            parsedColumns.push({ key, name, minWidth: 100, width: 250, frozen: true })
            return
        } else if (key === "timezone") { // put units after timezone
            parsedColumns.push({ key, name: getClearLabel(key), minWidth: 100, width: 200 })
            return
        } else if (key === "vin") {
            parsedColumns.push({ key, name: key.toUpperCase(), minWidth: 100, width: 200 })
            return
        }
        parsedColumns.push({ key, name: getClearLabel(key), minWidth: 100, width: 200 })
    })

    return parsedColumns
}

interface IcomposeTpmsReportData {
    (
        columns: Array<string>,
        rows: Array<IRow>,
        locale: ILocale,
        timezone: string,
        unitsOfMeasurement: string
    ): Array<IRow>
}

export const tpmsReportRows: IcomposeTpmsReportData = (columns, rows, locale, timezone, unitsOfMeasurement) => {
    const parsedRows = rows.map(item => {
        const lastTpmsUpdateEpoch = item.latest_tpms_update;
        const warningType = item.warning_type ? item.warning_type.split(', ').map((t: string) => locale[t]).join(', ') : "---";

        const row: IRow = { 
            ...item, 
            timezone,
            units: unitsOfMeasurement === UnitsOfMeasurement.imperial ? "PSI" : "BAR",
            in_geofence: item.in_geofence ? item.in_geofence : "---",
            warning_type: warningType,
            asset_type: item.asset_type ? item.asset_type: "---",
            report_date_time: convertDataEpochToDate(item.report_date_time, null, null, true),
            latest_tpms_update: lastTpmsUpdateEpoch ? convertDataEpochToDate(lastTpmsUpdateEpoch, null, null, true) : "---",
            asset_groups: item.asset_groups ? item.asset_groups: "---",
        }

        const startIndex = 9;

        for (let i = startIndex; i < columns.length; i++) {
            const key = columns[i]
            const value = row[key]

            if (value === undefined) {
                row[key] = ""
            }
            else {
                row[key] = !["-", ""].includes(value) ? 
                unitsOfMeasurement === UnitsOfMeasurement.imperial 
                    ? value
                    : parseFloat(psiToBar(+value)).toFixed(2)
                : "---"
            }
        }

        return row
    })

    return parsedRows
}