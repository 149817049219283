import React, { Dispatch, SetStateAction } from "react"
import { Nav, NavItem, NavLink } from "reactstrap"
import "./style.scss"
import { DefaultCron, CronTabNames } from "../../../../constants/constants"

interface Props {
    activeTab: string
    switchTab: (tab: string) => void
    resetSettings: () => void
    setCronExpression: Dispatch<SetStateAction<string>>
}

const CronEditorTabs: React.FC<Props> = ({ activeTab, switchTab, resetSettings, setCronExpression }) => {
    const tabNames = process.env.REACT_APP_STAGE === 'prod' ? CronTabNames.filter((t) => t !== "Hourly") : CronTabNames;

    return (
        <Nav tabs className="tab-nav ml-3 mr-3 editor-tabs">
            {tabNames.map((tab, i) => {
                return (
                    <NavItem className="tab-item" key={i}>
                        <NavLink
                            className={`cron_tab ${activeTab === tab ? "active-cron_tab" : ""}`}
                            onClick={() => { setCronExpression(DefaultCron); resetSettings(); switchTab(tab); }}
                        >
                            {tab}
                        </NavLink>
                    </NavItem>
                )
            })}
        </Nav>
    )
}

export default CronEditorTabs