import baseApi from './BaseApi'

class DocumentApi {
  getDocuments = (isPublic: boolean = false) =>
    baseApi.get(`rivata${isPublic ? '/public_documents' : ''}/document`, false)

  postDocument = (
    document: any,
    dir: string,
    title: string,
    docType: number,
  ) => {
    return baseApi.post(
      `rivata/document?dir=${dir}&filename=${title}&doc_type=${docType}`,
      document,
    )
  }

  deleteDocument = (dir: string, title: string) => {
    return baseApi.delete(`rivata/document?dir=${dir}&filename=${title}`)
  }

  getDocumentTypes = (isPublic: boolean = false) =>
    baseApi.get(
      `rivata${isPublic ? '/public_documents' : '/document'}/types`,
      false,
    )

  postDocumentType = (name: string) =>
    baseApi.post(`rivata/document/types?name=${name}`, null)

  deleteDocumentType = (id: number) =>
    baseApi.delete(`rivata/document/types?id=${id}`)
}

const documentApi = new DocumentApi()

export default documentApi
