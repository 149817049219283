import React, { useCallback, useMemo } from 'react'
import { SearchItemType } from '../../../components/ItemsSearch'
import ItemsSelectWithSearch from '../../../components/ItemsSelectWithSearch/ItemsSelectWithSearch'
import AssetsSelect from './AssetsSelect'
import { EndPointConfigs } from '../../../components/BackendSearchInput/constants'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import AssetGroupsFilter from '../../../componentsV2/AssetGroupsFilter'
import { MenuItemType } from '../../../componentsV2/Menu/MenuItem'

interface Props {
    disabled: boolean
    assetTypes: Array<any>
    selectedAssetType: "tractor" | "trailer" | "all"
    setSelectedAssetType: React.Dispatch<React.SetStateAction<string>>
    setSelectedGeofences: React.Dispatch<React.SetStateAction<Array<Object>>>
    setVinList: React.Dispatch<React.SetStateAction<Array<Object>>>
    selectedGeofenceItems: Array<SearchItemType>
    selectedAssetsItems: Array<SearchItemType>
    showAssetGroupsFilter?: boolean | false
    onAssetGroupFilterItemSelected?: (id: string, selectedValue: MenuItemType[]) => void
    customerIds?: string | ""
}

const geofenceColumnsToShow = [{ key: "name", name: "Name" }]

const AssetsInGeofenceReportForm: React.FC<Props> = ({
    disabled,
    assetTypes,
    selectedAssetType,
    setSelectedAssetType,
    setVinList,
    setSelectedGeofences,
    selectedGeofenceItems,
    selectedAssetsItems,
    onAssetGroupFilterItemSelected,
    customerIds,
    showAssetGroupsFilter
}) => {
    const selectedCustomersList = useTypedSelector((state) => (state.common.customers.selectedCustomersList))

    const onGeofencesSelect = useCallback((selectedGeofences: SearchItemType[]) => {
        setSelectedGeofences(selectedGeofences)
    }, [setSelectedGeofences])

    const endPointConfig = useMemo(() => {
        return {
            ...EndPointConfigs.geofence,
            subLabelKey: selectedCustomersList.length > 1 ? "customer" : undefined
        }
    }, [selectedCustomersList.length])

    return (
        <>
            <AssetsSelect
                disabled={disabled}
                assetTypes={assetTypes}
                selectedAssetType={selectedAssetType}
                setSelectedAssetType={setSelectedAssetType}
                setVinList={setVinList}
                selectedItems={selectedAssetsItems}
            />
            {showAssetGroupsFilter && <AssetGroupsFilter disabled={disabled} className="mt-3" onDropdownItemSelect={onAssetGroupFilterItemSelected} customerIds={customerIds} />}
            <ItemsSelectWithSearch
                label="Geofences"
                inputPlaceholder="Search Geofences"
                endPointConfig={endPointConfig}
                onItemsSelect={onGeofencesSelect}
                columnsToShow={geofenceColumnsToShow}
                disabled={disabled}
                selectedItems={selectedGeofenceItems}
            />
        </>
    )
}

export default AssetsInGeofenceReportForm