import moment from 'moment'
import React, { useState, useRef, useEffect } from 'react'
import { Form, Button } from 'reactstrap'
import RivataModule from '../../components/RivataModule'
import RivataTable from '../../components/RivataTable'

import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'

import { dateToEpoch } from '../../utils'
import { useTableColumns, useTableRows } from './hooks'
import { CalendarTypes } from '../../enums'
import DateTimePicker, {
  PositioningValues,
} from '../../componentsV2/DateTimePicker'

const ProvisionHistory = ({ locale = {} }) => {
  const { isLoading, data, error } = useTypedSelector(
    (state) => state.assetAdminInfo.provisionHistory,
  )
  const { fetchProvisionHistory } = useActions()

  const [selectedDateRangeObj, setSelectedDateRangeObj] = useState({})
  const [isBtnsDisabled, setIsBtnsDisabled] = useState(true)
  const columns = useTableColumns()
  const rows = useTableRows(data)
  const formRef = useRef()

  const dateRangeHandler = (date) => {
    setIsBtnsDisabled(false)
    const { startDate, endDate } = date
    setSelectedDateRangeObj({
      ...selectedDateRangeObj,
      inputValue: `${moment(startDate).format('MM/DD/YYYY')} ${
        endDate ? '- ' + moment(endDate).format('MM/DD/YYYY') : ''
      }`,
      dayStart: startDate,
      dayEnd: endDate,
    })
  }

  const onSubmit = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const { startDate, endDate } = dateToEpoch(
      selectedDateRangeObj.dayStart,
      selectedDateRangeObj.dayEnd,
    )
    fetchProvisionHistory(startDate, endDate)
  }

  const onDefaultProvisionList = () => {
    onResetForm()
    fetchProvisionHistory()
  }

  const onResetForm = () => {
    setSelectedDateRangeObj({})
    formRef.current.reset()
    setIsBtnsDisabled(true)
  }

  useEffect(() => {
    fetchProvisionHistory()
  }, [fetchProvisionHistory])

  return (
    <RivataModule
      title='Provision History'
      locale={locale}
      marginTop={0}
      error={error}
      filters={
        <Form
          className='d-inline-flex align-items-center time_input_style mb-1'
          onSubmit={onSubmit}
          innerRef={formRef}
        >
          <div className='position-relative d-flex range-selector__wrapper'>
            <DateTimePicker
              defaultMode={CalendarTypes.DateRange}
              dateFrom={moment(selectedDateRangeObj.dayStart)}
              dateTo={moment(selectedDateRangeObj.dayEnd)}
              onDateRangeSelect={(from, to) => {
                console.log(`from: ${from}, to: ${to}`)
                dateRangeHandler({ startDate: from, endDate: to })
              }}
              timeEnabled={false}
              maxDate={moment().toDate()}
              disabled={false}
              defaultContainer={false}
              className='mr-3'
              positioning={PositioningValues.topRightNoIndentation}
            />
          </div>
          <Button color='primary' disabled={isLoading || isBtnsDisabled}>
            {'Submit'}
          </Button>
          <Button
            type='button'
            style={{ marginLeft: '6px' }}
            disabled={isBtnsDisabled || isLoading}
            onClick={onDefaultProvisionList}
          >
            {'Clear'}
          </Button>
        </Form>
      }
    >
      <RivataTable isLoading={isLoading} columns={columns} rows={rows} />
    </RivataModule>
  )
}

export default ProvisionHistory
