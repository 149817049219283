import React from 'react'
import MenuItem, { MenuItemType } from './MenuItem'


interface Props {
    items: Array<MenuItemType>
    idsToRender: Array<string | null>
    depth: number
    onChange: (item: MenuItemType) => void
    onSubMenuToggle?: (item: MenuItemType) => void
    readOnly?: boolean
}


const MenuList: React.FC<Props> = ({
    items,
    idsToRender,
    depth,
    onChange,
    onSubMenuToggle,
    readOnly
}) => {
    const getSubList = (parentId: string) => {
        const subItems = items.filter(el => el.parentId === parentId)

        if (!subItems.length) return 

        return (
            <MenuList
                items={items}
                idsToRender={subItems.map(el => el.id)}
                depth={depth + 1}
                onChange={onChange}
                onSubMenuToggle={onSubMenuToggle}
            />
        )
    }

    return (
        <ul>
            {idsToRender.map(id => {
                const item = items.find(el => el.id === id)

                if (!item) return null

                const subItems = items.filter(i => i.parentId === item.id);

                if (subItems.length) {
                    item.markType = subItems.filter(si => !si.checked)?.length ? 'line' : 'check';
                }

                return (
                    <li key={item.id} className={`${readOnly || item.disabled ? "disabled" : ""}`}>
                        <MenuItem
                            {...item}
                            offsetLeft={depth * 20}
                            onSubMenuToggle={() => onSubMenuToggle && onSubMenuToggle(item)}
                            onSelect={() => onChange(item)}
                        />
    
                        {item.subMenuExpanded && getSubList(item.id)}
                    </li>
                )
            })}
        </ul>
    )
}

export default MenuList
