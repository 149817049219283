import { fetchPresignedUrl } from "../base"

interface IVinValidateResult {
    Results: Array<{
        ErrorCode: string
        Model: string 
        ModelYear: string
        Make: string
        VehicleType: string
        Axles: string
        Trim: string
        TrailerBodyType: string
    }>
}

class NHTSAApi {
  validateVin = (vin: string): Promise<IVinValidateResult> => {
    return fetchPresignedUrl(`https://vpic.nhtsa.dot.gov/api/vehicles/DecodeVinValues/${vin}?format=json`)
  }
}

const nhtsaApi = new NHTSAApi()

export default nhtsaApi
