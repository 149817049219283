import React, { ButtonHTMLAttributes, useEffect, useRef } from 'react'
import './style.scss'


interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
    btnType?: any
    setRef?: (btn: React.MutableRefObject<HTMLButtonElement | null>) => void
}


const Button: React.FC<Props> = ({
    children,
    className = "",
    btnType = "btn__default",
    setRef,
    ...props
}) => {
    const btn = useRef(null)

    useEffect(() => {
        setRef && setRef(btn) 
    }, [btn, setRef])

    return (
        <button 
            ref={btn}
            className={`filter__btn ${btnType} ${className}`}
            {...props}
        >
            {children}
        </button>
    )
}

export default Button
