import React from "react"
import { Col } from "reactstrap"
import { EndPointConfigs } from "../../../../components/BackendSearchInput/constants"

import "./style.scss"
import ItemsSelectWithSearch from "../../../../components/ItemsSelectWithSearch/ItemsSelectWithSearch"
import { SearchItemType } from "../../../../components/ItemsSearch"

interface Props {
    setProfileAssets: React.Dispatch<React.SetStateAction<SearchItemType[]>>
    setIsFormTouched: React.Dispatch<React.SetStateAction<boolean>>
    profileAssets: Array<SearchItemType>
}

const NotificationAssets: React.FC<Props> = ({
    setIsFormTouched,
    setProfileAssets,
    profileAssets
}) => {
    const columnsToShow = [{ key: "name", name: "Name" }, { key: "vin", name: "VIN" }]

    const handleMenuSelect = (el: any) => {
        setIsFormTouched(true)
        setProfileAssets(el)
    }

    return (
        <div>
            <Col md="12" className="mb-3">
                <ItemsSelectWithSearch
                    label="Assets"
                    inputPlaceholder="Search Assets"
                    endPointConfig={EndPointConfigs.assetName}
                    onItemsSelect={handleMenuSelect}
                    columnsToShow={columnsToShow}
                    disabled={false}
                    selectedItems={profileAssets}
                />
            </Col>
        </div>
    )
}

export default NotificationAssets
