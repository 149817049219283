import { convertDataEpochToDate, getClearLabel, getCurrentEpoch } from "../../../utils";
import { getWarningDescription } from "../../../utils/utils";
import { IRow } from "../../../components/RivataGrid";


const getLabelBySensorType = (type: string) => {
    switch (type) {
        case "hub_sensor":
            return "SmartHub"
        case "tire_sensor":
            return "TPMS"
        case "axle_load":
            return "Axle Load"
        case "line_pressure":
            return "Line Pressure"
        default:
            return getClearLabel(type)
    }
}

export const warningHistoryReportRows = (rows: Array<IRow>, locale: ILocale, unitsOfMeasurement: string) =>{
    const currentDateTime = convertDataEpochToDate(getCurrentEpoch(), null, null, true)

    return rows.map((row) => {
        row.warning_recovery_timestamp = row.warning_recovery_timestamp ? convertDataEpochToDate(row.warning_recovery_timestamp, null, null, true) : "---";
        row.description = getWarningDescription(
            row.warning_type, 
            row.description.warning_subtype, 
            row.description.duration_in_seconds,
            row.description.warning_value,
            row.description.is_recovered,
            locale,
            unitsOfMeasurement,
            row.warning_recovery_timestamp,
            row.description.max_warning_value,
            row.description.canceled,
            row.description.canceled && row.description.canceled_at ? convertDataEpochToDate(row.description.canceled_at, null, null, true) : '---',
            row.description.canceled ? row.description.cancel_reason : '---',
            row.description.threshold
        );
        row.parent_account = row.parent_account ? row.parent_account : "---";
        row.position = row.position ? getClearLabel(row.position) : "---";
        row.sensor_type = getLabelBySensorType(row.sensor_type)
        row.warning_type = row.warning_type ? getClearLabel(row.warning_type) : "---";
        row.warning_timestamp = convertDataEpochToDate(row.warning_timestamp, null, null, true);
        row.report_datetime = currentDateTime;
        row.asset_geofences = row.asset_geofences || "---";
        row.warning_latitude = row.warning_latitude || "---";
        row.warning_longitude = row.warning_longitude || "---";
        row.warning_geofence = row.warning_geofence || "---";
        row.asset_groups = row.asset_groups || "---";
        
        return row;
    });
}