import React from "react";
import { Jumbotron } from "reactstrap";
import Footer from "../Footer";
import authService from "../../services/auth";

import "./styles.scss";

const NoAccessPage = () => {
  return (
    <main className="no-access-page">
      <div>
        <Jumbotron>
          <div className="message">
            <h3 className="text-center">
              Your user role does not have permission to access the Digital
              Dashboard. If you feel this is in error, please contact your fleet
              administrator to update your role.
            </h3>
            <h4 className="text-center">
              Questions? please contact
              <a href="mailto:support@digital.conmet.com">
                {" "}
                support@digital.conmet.com
              </a>
            </h4>
          </div>

          <div>
            <span className="link" onClick={() => authService.signOut()}>
              Logout
            </span>
          </div>
        </Jumbotron>
      </div>
      <Footer />
    </main>
  );
};

export default NoAccessPage;
