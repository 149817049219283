import React, { useEffect, useState } from 'react'
import { Nav, NavItem, NavLink, TabContent, TabPane, Row } from "reactstrap"

import SubNav from '../../components/SubNav'
import CustomerSelector from '../../modules/CustomerSelector'
import CalibrateAxleLoad from './CalibrateAxleLoad'
import CalibrateOdometer from './CalibrateOdometer/CalibrateOdometer'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { UnitsOfMeasurement } from '../../enums'
import { useActions } from '../../hooks/useActions'

interface Props {}

const breadcrumbs = [
    { label: "Home", path: "/dashboard" },
    { label: "Calibrate Asset" }
]

const CalibrateAssets: React.FC<Props> = () => {
    const {
        customerDefaults: { subscriptions, isDefaultsLoading },
        locale,
        auth: { isSuperAdmin = false, preferences: { unitsOfMeasurement = UnitsOfMeasurement.imperial, timezone } },
        selectedCustomersList
    } = useTypedSelector(state => ({
        auth: state.auth,
        customerDefaults: state.common.customerDefaults,
        locale: state.whitelabel.locale,
        selectedCustomersList: state.common.customers.selectedCustomersList
    }))
    const {
        setSelectedCalibrationAsset,
        fetchAxleLoadAssets,
        setAxleLoadCalibrationData,
    } = useActions()


    const [activeTab, setActiveTab] = useState("odometer")
    const [selectedUnitsOfMeasurement, setSelectedUnitsOfMeasurement] = useState<string>(unitsOfMeasurement)


    useEffect(() => {
        setSelectedCalibrationAsset(null)
        setAxleLoadCalibrationData([])
        fetchAxleLoadAssets()
    }, [selectedCustomersList, fetchAxleLoadAssets, setSelectedCalibrationAsset, setAxleLoadCalibrationData])

    useEffect(() => {
        setSelectedUnitsOfMeasurement(unitsOfMeasurement)
    }, [unitsOfMeasurement])

    useEffect(() => {
        isSuperAdmin ? setActiveTab('odometer') : setActiveTab('axleLoad')
    }, [isSuperAdmin])

    return (
        <>
            <SubNav
                title="Calibrate Asset"
                breadcrumbs={breadcrumbs}
            >
                <CustomerSelector/>
            </SubNav>

            <div className='tabs-wrapper'>
                <Nav tabs className="ml-3 mr-3">
                    {(
                        <NavItem className="tab-item">
                            <NavLink
                                className={`assets_tab ${activeTab === 'odometer' ? "active-table_tab" : ""}`}
                                onClick={() => setActiveTab('odometer')}
                            >
                                Odometer
                            </NavLink>
                        </NavItem>
                    )}

                    {((subscriptions && subscriptions.AXLE_LOAD) || isSuperAdmin) && (
                        <NavItem className="tab-item">
                            <NavLink
                                className={`assets_tab ${activeTab === 'axleLoad' ? "active-table_tab" : ""}`}
                                onClick={() => setActiveTab('axleLoad')}
                            >
                                Axle Load
                            </NavLink>
                        </NavItem>
                    )}
                </Nav>

                <TabContent activeTab={activeTab} className="ml-3 mr-3">
                    <TabPane tabId="odometer">
                        {activeTab === 'odometer' && (
                            <Row>
                                <CalibrateOdometer
                                    locale={locale}
                                    isSuperAdmin={isSuperAdmin}
                                    timezone={timezone}
                                    selectedCustomersList={selectedCustomersList}
                                    selectedUnitsOfMeasurement={selectedUnitsOfMeasurement}
                                    setSelectedUnitsOfMeasurement={setSelectedUnitsOfMeasurement}
                                />
                            </Row>
                        )}
                    </TabPane>

                    <TabPane tabId="axleLoad">
                        {activeTab === "axleLoad" && (
                            <CalibrateAxleLoad
                                locale={locale}
                                isSuperAdmin={isSuperAdmin}
                                subscriptions={subscriptions}
                                isDefaultsLoading={isDefaultsLoading}
                                selectedUnitsOfMeasurement={selectedUnitsOfMeasurement}
                                setSelectedUnitsOfMeasurement={setSelectedUnitsOfMeasurement}
                            />
                        )}
                    </TabPane>
                </TabContent>
            </div>
        </>
    )
}


export default CalibrateAssets
