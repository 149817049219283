import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { FormGroup, Label, Input } from "reactstrap";
import WidthDropdown from "../../components/WidthDropdown";

const getItemStyle = (_isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  // userSelect: "none",
  // padding: grid * 2,
  // margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  // background: isDragging ? "lightgreen" : "red",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const copyWidth = (data, width) => ({ ...data, data: { ...data.data, width } });
const copyEnabled = (data, enabled) => ({
  ...data,
  data: { ...data.data, enabled },
});

const ModuleOrderItem = ({ item, onItemUpdated, index, locale }) => {
  return (
    <Draggable draggableId={item.id} index={index}>
      {(draggableProvided, draggableSnapshot) => (
        <tr
          ref={draggableProvided.innerRef}
          {...draggableProvided.draggableProps}
          {...draggableProvided.dragHandleProps}
          style={getItemStyle(
            draggableSnapshot.isDragging,
            draggableProvided.draggableProps.style
          )}
          className="bg-light border"
        >
          <td>
            <div className="d-flex align-items-center justify-content-between px-2 py-3">
              <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
            </div>
          </td>
          <td>
            <div className="d-flex align-items-center justify-content-between py-3">
              {item.data.title}
            </div>
          </td>
          <td>
            <div className="d-flex align-items-center justify-content-between py-3">
              <WidthDropdown
                selected={item.data.width}
                onSelect={(width) => onItemUpdated(copyWidth(item, width))}
              />
            </div>
          </td>
          <td>
            <div className="d-flex align-items-center justify-content-between px-2 py-3">
              <FormGroup check>
                <Label
                  check
                  className="d-inline-flex align-items-center justify-content-between"
                >
                  <Input
                    type="checkbox"
                    defaultChecked={item.data.enabled}
                    style={{ marginTop: 0 }}
                    onChange={(e) =>
                      onItemUpdated(copyEnabled(item, e.target.checked))
                    }
                  />
                  <span>Enabled</span>
                </Label>
              </FormGroup>
            </div>
          </td>
        </tr>
      )}
    </Draggable>
  );
};

export default ModuleOrderItem;
