import React, { useCallback, useRef, useState } from 'react'
import { CustomInput } from 'reactstrap'
import RivataModule from '../../components/RivataModule'
import RivataTable from '../../components/RivataTable'
import LocationMap from '../LocationMap'

import { useTypedSelector } from '../../hooks/useTypedSelector'
import { useActions } from '../../hooks/useActions'
import { useColumns, useMapData, useTableRows } from './hooks'
import ScrollBlockWrapper from '../../components/ScrollBlockWrapper'
import { makeScrollToElementWithOffset } from '../../utils/utils'
import TagLocationsFilterMenu from '../../pages/TagLocations/TagLocationsFilterMenu'

const getCustomBubbleContent = (data) => {
  const labelColor = 'green'

  return `
        <div id="location-bubble">
            <div class="alert-primary-location show" role="alert" aria-live="assertive" aria-atomic="true"" style="background-color: ${labelColor}; border-color: ${labelColor};">
                <div class="label-line"></div>
                <table class="table-responsive table-light table-sm m-0 p-0">
                <tbody>
                    <tr>
                    <th scope="row">Name: </th>
                    <td colspan=2>${data.name}</td>
                    </tr>
                    <tr>
                    <th scope="row">Tags:</th>
                    <td colspan=2>${data.tagsCount}</td>
                    </tr>
                    <tr>
                    <th scope="row">Customer: </th>
                    <td colspan=2>${data.customer_name}</td>
                    <td colspan=2>
                        <span id="cargotagDetailsLink" >Details</span>
                    </td>
                    </tr>
                </tbody>
                </table>
            </div>
        </div>
    `
}

export const customGeofenceBubbleContent = (data) => {
  const labelColor = '#4c86c2'

  return `
      <div 
        class="alert-primary cluster show" 
        role="alert" 
        aria-live="assertive" 
        aria-atomic="true"" 
        style="background-color: ${labelColor}; border-color: ${labelColor};"
      >
        <div class="label-line"></div>
        <table class="table-responsive table-light table-sm m-0 p-0">
        <tbody>
          <tr>
            <th scope="row">Geofence: </th>
            <td colspan=3>${data.name}</td>
          </tr>
          <tr>
            <th scope="row">Tags: </th>
            <td colspan=3>${data.tags_count}</td>
          </tr>
          <tr>
          <th scope="row">Customer:</th>
          <td colspan=3>${data.customer_name}</td>
          <td class="aligned-cell details"><a id="locationMapBubble">Details</a></td>
        </tr>
        </tbody>
      </table>
      </div>
    `
}
const columnsSelectorProps = {
  showDropdown: false,
  localeStorageKey: 'tagLocations.table.columns',
}
const GeoLocations = ({ locale, width, setLocationsOffset }) => {
  const {
    preferences,
    tagLocations: {
      data,
      dataStatus,
      limit,
      offset,
      isLoading,
      totalCount,
      tagsGeofences,
      geofencesStatus,
      assetsWithCargotags,
      assetsStatus,
    },
  } = useTypedSelector((state) => ({
    isSuperAdmin: state.auth.isSuperAdmin,
    subscriptions: state.common.customerDefaults.subscriptions,
    preferences: state.auth.preferences,
    tagLocations: state.tagLocations,
  }))

  const { setTagsLocationsFilter, setLocationsOrder, setLocationsLimit } =
    useActions()

  const scrollTarget = useRef(null)

  const columns = useColumns()
  const rows = useTableRows(data)
  const mapData = useMapData(assetsWithCargotags)
  const [clusteringDisabled, setClusteringDisabled] = useState(false)

  const handlePageChange = useCallback(
    (offset) => {
      setLocationsOffset(offset)
      makeScrollToElementWithOffset(scrollTarget, -200)
    },
    [setLocationsOffset],
  )

  const handleLimitChange = useCallback(
    (limit) => {
      setLocationsLimit(limit)
      makeScrollToElementWithOffset(scrollTarget, -200)
    },
    [setLocationsLimit],
  )

  return (
    <>
      <RivataModule
        title='Locations'
        width={width}
        locale={locale}
        error={
          geofencesStatus.statusCode >= 400 ? geofencesStatus : assetsStatus
        }
        filters={
          <div className='d-flex align-items-center mr-3'>
            <CustomInput
              type='switch'
              id='clusteringSwitch'
              label='Clustering'
              checked={!clusteringDisabled}
              onChange={(e) => setClusteringDisabled(!e.target.checked)}
              className='mr-3'
            />
          </div>
        }
      >
        <ScrollBlockWrapper>
          <LocationMap
            locations={mapData}
            locale={locale}
            preferences={preferences}
            geofences={tagsGeofences}
            getCustomBubbleContent={getCustomBubbleContent}
            customGeofenceBubbleContent={customGeofenceBubbleContent}
            clusteringDisabled={clusteringDisabled}
          />
        </ScrollBlockWrapper>
      </RivataModule>

      <RivataModule
        title='Tag Locations'
        width={width}
        locale={locale}
        error={dataStatus}
      >
        <TagLocationsFilterMenu
          onFilter={setTagsLocationsFilter}
        ></TagLocationsFilterMenu>
        <hr />
        <div ref={scrollTarget}>
          <RivataTable
            showPagination={true}
            isShowingLimit={true}
            totalCount={totalCount}
            onPageChange={handlePageChange}
            onSelectLimit={handleLimitChange}
            pageLimit={limit}
            page={offset / limit}
            setSortOptions={setLocationsOrder}
            columns={columns}
            rows={rows}
            isLoading={isLoading}
            columnsSelectorProps={columnsSelectorProps}
          />
        </div>
      </RivataModule>
    </>
  )
}

export default GeoLocations
