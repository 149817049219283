import { Dispatch } from "redux";
import { store } from "../store";

import notificationApi from "../../services/api/ApiGroups/NotificationsApi";
import userApi from "../../services/api/ApiGroups/UserApi";

import { ApiError } from "../../services/api/base";
import { getStatusObj } from "../../utils/utils";

import { IFilterOptions, IgetState, ThunkResult } from "../types";
import { ICreateNotificationprofile, IUpdateNotificationprofile, ManageNotificationActions, ManageNotificationsTypes } from "./types";

export const setManageNotificationsLimit = (payload: number) => ({
    type: ManageNotificationsTypes.SET_LIMIT,
    payload
})

export const setManageNotificationsOffset = (payload: number) => ({
    type: ManageNotificationsTypes.SET_OFFSET,
    payload
})

export const setManageNotificationsProfiles = (payload: Array<INotificationProfile>) => ({
    type: ManageNotificationsTypes.SET_DATA,
    payload
})

export const setManageNotificationsProfilesFilter = (payload: IFilterOptions) => ({
    type: ManageNotificationsTypes.SET_FILTER, 
    payload
})


export function createNotificationProfile(data: ICreateNotificationprofile): ThunkResult<IStatus> {
    return async function () {
        try {
            await notificationApi.postNotificationProfile(data)
            
            return getStatusObj({ statusCode: 200 }, "Profile successfuly created!")
        } catch (err) {
            if (err instanceof ApiError) {
                let message = err.message.split(":")
                message.splice(0, 1)

                return getStatusObj(err, message.join(":"))
            }

            return getStatusObj(err)
        }
    }
}


export function fetchNotificationProfiles(): ThunkResult<void> {
    return async function (dispatch: Dispatch<ManageNotificationActions>, getState: IgetState) {
        try {
            dispatch({ type: ManageNotificationsTypes.LOADING, payload: true })

            const { common, manageNotifications } = getState()
            const selectedCustomersList: Array<ICustomer> = common.customers.selectedCustomersList
            const { 
                filter: { filterType, filterData }
            } = manageNotifications

            if (selectedCustomersList.length > 1) return;

            const res = await notificationApi.getNotificationProfile(selectedCustomersList[0].id, undefined, undefined, filterType, filterData)
            dispatch({ type: ManageNotificationsTypes.SET_DATA, payload: res.paged_data })
            dispatch({ type: ManageNotificationsTypes.SET_TOTAL_COUNT, payload: res.total_count })
            dispatch({ type: ManageNotificationsTypes.SET_STATUS, payload: getStatusObj() })
        } catch (err) {
            dispatch({ type: ManageNotificationsTypes.SET_STATUS, payload: getStatusObj(err) })
        } finally {
            dispatch({ type: ManageNotificationsTypes.LOADING, payload: false })
        }
    }
}


export function updateNotificationProfile(profileId: number, data: IUpdateNotificationprofile) {
    return async function (dispatch: Dispatch<ManageNotificationActions>, getState: IgetState) {
        try {
            await notificationApi.putNotificationProfile(profileId, data)
            
            return getStatusObj({ statusCode: 200 }, "Profile updated!")
        } catch (err) {
            if (err instanceof ApiError) {
                let message = err.message.split(":")
                message.splice(0, 1)

                return getStatusObj(err, message.join(":"))
            }
            
            return getStatusObj(err)
        }
    }
}


export function deleteNotificationProfile(id: number) {
    return async function (dispatch: Dispatch<ManageNotificationActions>, getState: IgetState) {
        try {
            dispatch({ type: ManageNotificationsTypes.LOADING, payload: true })

            await notificationApi.deleteNotificationProfile(id)
            
            return getStatusObj({ statusCode: 200, message: "Deleted" })
        } catch (err) {
            return getStatusObj(err)
        } finally {
            dispatch({ type: ManageNotificationsTypes.LOADING, payload: false })
        }
    }
}


export function fetchUsersShortDataFormat() {
    return async function (dispatch: Dispatch<ManageNotificationActions>, getState: IgetState) {
        try {
            const selectedCustomersList: Array<ICustomer> = getState().common.customers.selectedCustomersList

            if (selectedCustomersList.length !== 1) return;

            const res = await userApi.getAllUsersWithShortDataFormat()

            dispatch({ type: ManageNotificationsTypes.SET_USERS, payload: res })
        } catch (err) {
            console.log(err)
        }
    }
}

store.subscribe(() => {
    const lastAction = store.getState().lastAction;
    
    if (lastAction.type === ManageNotificationsTypes.SET_LIMIT) {
        store.dispatch({ type: ManageNotificationsTypes.SET_OFFSET, payload: 0 })
        store.dispatch(fetchNotificationProfiles())
    }
})