import { useEffect, useState } from 'react'
import moment from 'moment'
import {
  WhiteLabelFilenames,
  SubscriptionTypes,
  NoDataLabels,
  ATIInstalledOptions,
} from '../../enums'
import { getClearLabel } from '../../utils'

export const useTableColumns = (selectAll) => {
  const [columns, setColumns] = useState([])

  useEffect(() => {
    const columns = [
      {
        label: 'All Visible',
        id: 'all',
        control: 'checkbox',
        checked: selectAll,
      },
      {
        label: 'Asset Name',
        id: 'name',
        control: 'sort',
        isDraggable: true,
      },
      {
        label: 'VIN',
        id: 'vin',
        control: 'sort',
        isDraggable: true,
      },
      {
        label: 'Asset Type',
        id: 'asset_type',
        control: 'sort',
        isDraggable: true,
      },
      {
        label: 'Subtype',
        id: 'asset_subtype',
        control: 'sort',
        isDraggable: true,
      },
      {
        label: 'Sensors',
        id: 'subscriptions',
        control: 'sort',
        isDraggable: true,
      },
      {
        label: 'Year',
        id: 'year',
        control: 'sort',
        isDraggable: true,
      },
      {
        label: 'Make',
        id: 'make',
        control: 'sort',
        isDraggable: true,
      },
      {
        label: 'Model',
        id: 'model',
        control: 'sort',
        isDraggable: true,
      },
      {
        label: 'ATI Installed',
        id: 'ati_installed',
        control: 'sort',
        isDraggable: true,
      },
      {
        label: 'Number Of Axles',
        id: 'number_of_axles',
        control: 'sort',
        isDraggable: true,
      },
      {
        label: 'Groups',
        id: 'groups',
        control: 'sort',
        isDraggable: true,
      },
    ]

    setColumns(columns)
  }, [selectAll])

  return columns
}

export const useTableRows = (data, selectedRows, selectAll, whiteLabelUrl) => {
  const [rows, setRows] = useState([])

  useEffect(() => {
    const hash = `?r=${+new Date()}`
    const smartHubSrc = `${whiteLabelUrl}${WhiteLabelFilenames.smarthubHealthSvg}${hash}`
    const tpmsSrc = `${whiteLabelUrl}${WhiteLabelFilenames.tpmsHealthSvg}${hash}`
    const linePressureSrc = `${whiteLabelUrl}${WhiteLabelFilenames.linePressureHealthSvg}${hash}`
    const axleLoadSrc = `${whiteLabelUrl}${WhiteLabelFilenames.axleLoadHealthSvg}${hash}`
    const gatewaySrc = `${whiteLabelUrl}${WhiteLabelFilenames.gatewayHealthSvg}${hash}`

    const d = data.paged_data.map((row) => {
      const alt = []
      const src = []
      let atiInstalledValue = NoDataLabels.DASH
      const ati_installed = row.ati_installed || ''

      const option = ATIInstalledOptions.find(
        (opt) => opt.value === ati_installed.toString(),
      )

      if (option) {
        atiInstalledValue = option.label
      }
      if (row && row.subscriptions) {
        Object.keys(row?.subscriptions).map((key) => {
          const { valid_from, valid_to } = row.subscriptions[key]
          const currentDate = moment(new Date()).unix()

          if (currentDate < valid_from || currentDate > valid_to) return null
          switch (key) {
            case SubscriptionTypes.gateway:
              src.push(gatewaySrc)
              alt.push('gw')
              break
            case SubscriptionTypes.smarthub:
              src.push(smartHubSrc)
              alt.push('hub')
              break
            case SubscriptionTypes.tpms:
              src.push(tpmsSrc)
              alt.push('tire')
              break
            case SubscriptionTypes.linePressure:
              src.push(linePressureSrc)
              alt.push('line')
              break
            case SubscriptionTypes.axleLoad:
              src.push(axleLoadSrc)
              alt.push('axle')
              break
            default:
              break
          }
          return key
        })
      }

      return {
        columns: [
          {
            columnId: 'all',
            control: 'checkbox',
            checked: selectAll
              ? true
              : selectedRows.indexOf(row.id) > -1
              ? true
              : false,
            id: row.id,
          },
          {
            id: row.id,
            type: 'text',
            label: row.name,
            columnId: 'name',
          },
          {
            id: row.id,
            type: 'text',
            label: row.vin,
            columnId: 'vin',
          },
          {
            id: row.id,
            type: 'text',
            label: getClearLabel(row.asset_type),
            columnId: 'asset_type',
          },
          {
            id: row.id,
            type: 'text',
            label: getClearLabel(row.asset_subtype),
            columnId: 'asset_subtype',
          },
          {
            id: row.id,
            type: 'icon',
            label: alt || '',
            alt,
            src,
            columnId: 'subscriptions',
            classNames: [],
            min_width: '175px',
          },
          {
            id: row.id,
            type: 'text',
            label: row.year || NoDataLabels.DASH,
            columnId: 'year',
          },
          {
            id: row.id,
            type: 'text',
            label: row.make || NoDataLabels.DASH,
            columnId: 'make',
          },
          {
            id: row.id,
            type: 'text',
            label: row.model || NoDataLabels.DASH,
            columnId: 'model',
          },
          {
            id: row.id,
            type: 'text',
            label: atiInstalledValue,
            columnId: 'ati_installed',
          },
          {
            id: row.id,
            type: 'text',
            label: row.number_of_axles || NoDataLabels.DASH,
            columnId: 'number_of_axles',
          },
          {
            id: row.id,
            type: 'text',
            label: row.groups || NoDataLabels.DASH,
            columnId: 'groups',
          },
        ],
        id: row.id,
        selectedRow: selectedRows.includes(row.id) ? true : false,
      }
    })
    setRows(d)
  }, [data, selectAll, selectedRows, whiteLabelUrl])

  return rows
}
