import React from 'react'
import { WhiteLabelFilenames } from "../../../enums";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import classes from './style.module.scss'
import { HealthStatus } from "../../../enums"; 

const r = +new Date();

const IconWrapper = ({variant, children, name})=> {
    const {whiteLabelUrl} = useTypedSelector(state => ({whiteLabelUrl: state.auth.whiteLabelUrl}))

    let img = ""
    switch(name){
        case 'smartHub':
            img = `${whiteLabelUrl}${WhiteLabelFilenames.smarthubHealthSvg}?r=${r}`;
            break;
        case 'linePressure':
            img = `${whiteLabelUrl}${WhiteLabelFilenames.linePressureHealthSvg}?r=${r}`;
            break;
        case 'axleLoad':
            img = `${whiteLabelUrl}${WhiteLabelFilenames.axleLoadHealthSvg}?r=${r}`;
            break;
        default: break;
    }

    let borderColor = ""
    switch(variant){
        case HealthStatus.good:
            borderColor = "var(--success)"
            break;
        case HealthStatus.warning:
            borderColor = "var(--warning)"
            break;
        case HealthStatus.criticalWarning:
            borderColor = "var(--criticalwarning)"
            break;
        case HealthStatus.noStatus:
            borderColor = "#6D6E71"
            break;
        default: break;
    }

    return(
        <div className={classes.iconWrapper} >
            <div 
                className={classes.iconImg} 
                style={{backgroundImage: `url(${img})`}}
            />
            <div 
                className={classes.iconBorder} 
                style={{border: `5px solid ${borderColor}`}}
            />
            <div className={classes.iconPopower} >{children}</div>
        </div>
    )
}

export default IconWrapper