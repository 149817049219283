import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const AcknowledgeButton = (props) => {
  const { disabled, btnClassName, onAcknowledge, locale = {} } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <div>
      <Button
        disabled={disabled}
        size="md"
        className={btnClassName}
        onClick={toggle}
      >
        {locale["acknowlegdeWarnings"] || "Acknowledge Warnings"}
      </Button>
      <Modal isOpen={modal && !disabled}>
        <ModalHeader toggle={toggle}>
          {locale["acknowlegdeWarningsTitle"] || "Acknowledge these warnings?"}
        </ModalHeader>
        <ModalBody>
          {locale["acknowlegdeWarningsBody"] || "This cannot be undone!"}
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              onAcknowledge();
              toggle();
            }}
          >
            {locale["ok"] || "Ok"}
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            {locale["cancel"] || "Cancel"}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AcknowledgeButton;
