import React, { useState } from "react";

import { Spinner, Fade } from "reactstrap";

import Tractor from "./Tractor";

import TimeSinceGpsUpdate from "../common/TimeSinceGpsUpdate";

import "./style.scss";

const TractorDiagram = ({
  statuses,
  pressureUnit,
  getTireData,
  timezone,
  locale,
  thresholds, 
  lastUpdatedAtDateTime
}) => {
  const width = 1200;
  const height = 300;
  const [imageLoaded, setImageLoaded] = useState(false);

  const { assetTpmsStatus:{sensors},assetSmartStatus, assetAxlesLoads, assetLinePressureStatus } = statuses
  const isAnyStatus = !!(sensors?.length || assetSmartStatus?.length || assetAxlesLoads?.length || assetLinePressureStatus?.length)

  return (
    <div className="d-flex justify-content-center align-items-center diagram-container">
      {statuses.isLoading && !imageLoaded ? (
        <Spinner />
      ) : isAnyStatus && (
        <Fade in={!statuses.isLoading && imageLoaded} timeout={200}>
          <div
            className="d-flex align-items-center justify-content-center"
            style={{
              width: `${width}px`,
              height: `${height}px`,
              marginBottom: "90px",
            }}
          >
            <Tractor
              height={height}
              sensors={statuses.assetTpmsStatus?.sensors}
              pressureUnit={pressureUnit}
              smart={statuses.assetSmartStatus}
              getTireData={getTireData}
              setImageLoaded={setImageLoaded}
              timezone={timezone}
              locale={locale}
              thresholds={thresholds}
              axlesLoads={statuses.assetAxlesLoads}
            />
          </div>

          <div className="d-flex flex-column align-items-center justify-content-center">
            <span style={{ fontSize: "18px", marginBottom: "15px", marginTop: "30px"}}>
              Asset Health Status
            </span>
            <TimeSinceGpsUpdate lastUpdatedAtDateTime={lastUpdatedAtDateTime} />
          </div>
        </Fade>
      )}
    </div>
  );
};

export default TractorDiagram;
