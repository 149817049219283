import { AssetGroupsActions, AssetGroupsReducer, AssetGroupsTypes } from "./types";

const status = { ok: true, statusCode: 0, message: "" }

const initialState: AssetGroupsReducer = {
    assetGroups: { 
        isLoading: false, 
        data: [], 
        status,
        limit: 30,
        offset: 0,
        totalCount: 0, 
        limitedData: [],
        unassignedAssetsCount: 0
    },
    assetGroupsDetails: {
        isLoading: false,
        data: {id: undefined, name: undefined, assigned_assets: { paged_data: [], total_count: 0 }, unassigned_assets: {paged_data: [], total_count: 0 } },
        limit: 30,
        offset: 0,
        status,
        sortOptions: { column: "name", direction: "asc"}
    }
}

const assetGroups = (state = initialState, action: AssetGroupsActions): AssetGroupsReducer  => {
    switch (action.type) {
        case AssetGroupsTypes.LOADING_ASSET_GROUPS:
            return { ...state, assetGroups: { ...state.assetGroups, isLoading: action.payload } }
        case AssetGroupsTypes.SET_ASSET_GROUPS:
            return { ...state, assetGroups: { ...state.assetGroups, data: action.payload.paged_data, totalCount: action.payload.total_count, unassignedAssetsCount: action.payload.assets_count_without_groups } }
        case AssetGroupsTypes.SET_ASSET_GROUPS_STATUS:
            return { ...state, assetGroups: { ...state.assetGroups, status: action.payload } }
        case AssetGroupsTypes.SET_ASSET_GROUPS_OFFSET:
            return { ...state, assetGroups: { ...state.assetGroups, offset: action.payload } }
        case AssetGroupsTypes.SET_ASSET_GROUPS_LIMIT:
            return { ...state, assetGroups: { ...state.assetGroups, limit: action.payload } }
        case AssetGroupsTypes.LOADING_ASSET_GROUPS_DETAILS:
            return { ...state, assetGroupsDetails: { ...state.assetGroupsDetails, isLoading: action.payload } }
        case AssetGroupsTypes.SET_ASSET_GROUPS_DETAILS:
            return { ...state, assetGroupsDetails: { ...state.assetGroupsDetails, data: action.payload } }
        case AssetGroupsTypes.SET_ASSET_GROUPS_DETAILS_STATUS:
            return { ...state, assetGroupsDetails: { ...state.assetGroupsDetails, status: action.payload } }
        case AssetGroupsTypes.SET_ASSET_GROUPS_LIMITED_DATA:
            return { ...state, assetGroups: { ...state.assetGroups, limitedData: action.payload } }
        case AssetGroupsTypes.SET_ASSETS_GROUPS_DETAILS_OFFSET:
            return { ...state, assetGroupsDetails: {...state.assetGroupsDetails, offset: action.payload}}
        case AssetGroupsTypes.SET_ASSETS_GROUPS_DETAILS_LIMIT:
            return { ...state, assetGroupsDetails: {...state.assetGroupsDetails, limit: action.payload}}
        default:
            return state
    }
}

export default assetGroups
