import React from 'react'
import { ReportParameterGroupProps } from './ReportParameterGroupProps'
import DateRangeRow from '../ReportParametersRows/DateRangeRow'
import CargoTagsRow from '../ReportParametersRows/CargoTagsRow'

const TagHistoryDetailsReportParameters: React.FC<ReportParameterGroupProps> = (
  props: ReportParameterGroupProps,
) => {
  return (
    <>
      <DateRangeRow
        value={`${props.parameters.epoch_from}-${props.parameters.epoch_to}`}
      />
      <CargoTagsRow value={props.parameters.macs} />
    </>
  )
}
export default TagHistoryDetailsReportParameters
