import React, { useMemo, useContext } from 'react'

import RivataModule from '../../components/RivataModule'
import RivataLineChart from '../../components/RivataLineChart'
import RivataLoader from '../../components/RivataLoader'
import StatusAlert from '../../components/StatusAlert'

import { UnitsOfMeasurement } from "../../enums"

import { psiToBar } from '../../utils'
import { composeChartData, composeAnnotations } from './utils'
import { composeCustomTooltip } from '../../utils/chartUtils'
import { useTypedSelector } from '../../hooks/useTypedSelector'

import { AssetDetailsContext } from '../../pages/AssetDetails'

const tooltip = composeCustomTooltip({
    displayLabelColors: false,
    bgColor: true,
    beforeLabel: true,
    afterLabel: true
})

const id = "Red Supply Line"

const LinePressureChart = ({ width }) => {
    const {
        linePressure: { isLoading, data, status },
        thresholds
    } = useTypedSelector(state => ({
        linePressure: state.assetDetails.linePressure,
        thresholds: state.common.customerDefaults.line_pressure
    }))

    const {
        locale,
        unitsOfMeasurement, 
        chartsXAxisLabel,
        healthColors,
        timestamp
    } = useContext(AssetDetailsContext)

    const threshold = unitsOfMeasurement === UnitsOfMeasurement.imperial ? thresholds.line_pressure_treshold : psiToBar(thresholds.line_pressure_treshold)

    const chartData = useMemo(() => {
        if (!data.length) return null
        return composeChartData(data, unitsOfMeasurement)
    }, [data, unitsOfMeasurement])

    return (
        <RivataModule
            fullScreenModalModeEnabled
            title="Line Pressure"
            width={width}
            locale={locale}
            error={status}
            collapsible
        >
            {isLoading ? (
                <RivataLoader/>
            ) : chartData ? (
                <RivataLineChart
                    id={id}
                    data={chartData}
                    height={400}
                    yAxisLabel={`Pressure (${unitsOfMeasurement === UnitsOfMeasurement.imperial ? "psi" : "bar"})`}
                    tooltip={tooltip}
                    annotations={composeAnnotations(id, threshold, timestamp, healthColors)}
                    threshold={threshold}
                    xAxisLabel={chartsXAxisLabel}
                />
            ) : (
                <StatusAlert customText="No Data" color="success"/>
            )}
        </RivataModule>
    )
}

export default LinePressureChart