import React from "react"
import { Row } from "reactstrap"
import * as Yup from "yup"
import { FormField } from "../../components/CreateModal"
import CreateModal, { SelectFormField } from "../../components/CreateModal/children"
import { ICustomerFormValues } from "./types"

interface IgetSchema {
  (isEdit: boolean): Yup.AnyObjectSchema
}

const getSchema: IgetSchema = (isEdit) => {
  const schemaObject = {
    name: Yup.string().required("Required"),
    description: Yup.string(),
    parent_id: Yup.number()
  }
 
  return Yup.object().shape(schemaObject)
}

interface IParentCustomerOption {
  value: number | null
  label: string | null
}

interface IgetParentCustomersOptions {
  (c: Array<ICustomer>): Array<IParentCustomerOption>
}

const getParentCustomersOptions: IgetParentCustomersOptions = (customers) => {
  const options: Array<IParentCustomerOption> = [{ value: null, label: null }];
  if (customers?.length) {
    customers = customers.filter(c => !c.parent_id && c.id);
    customers.forEach(o => {
      if (o.name.toLowerCase() !== "rivata") {
        options.push({
          value: o.id,
          label: o.name
        })
      }
    })
  }

  return options
}

interface ICustomerModalProps {
  title: string
  locale: ILocale
  disabled: boolean
  initialValues: any
  isUpdate: boolean
  open: boolean | undefined
  isEdit: boolean
  customers: Array<ICustomer>
  onClose: undefined | (() => void )
  onCreate: (c: ICustomerFormValues) => void
}

const CustomerModal: React.FC<ICustomerModalProps> = ({
  locale,
  onCreate,
  disabled,
  initialValues,
  isUpdate,
  title,
  open = undefined,
  onClose = undefined,
  isEdit,
  customers
}) => {
  const parentCustomerOptions = isEdit ? [] : getParentCustomersOptions(customers);
  return (
    <CreateModal
      disabled={disabled}
      btnClassName="btn-primary ml-3"
      onCreate={onCreate}
      createBtnLabel={title}
      openBtnLabel={`${title} Customer`}
      header={`${title} Customer`}
      locale={locale}
      schema={getSchema(isEdit)}
      initialValues={initialValues}
      onClose={onClose}
      open={open}
      resetFormValues={undefined}
      footerBtnDisabled={false}
      validate={null}
    >
      <Row className="p-t-0">
        <FormField name="name" label="Company Name" disabled={isUpdate} />
        <FormField
          name="description"
          type="textarea"
          label="Description"
          md="12"
        />
        {!isEdit && <SelectFormField name="parent_id" label="Parent Customer" options={parentCustomerOptions} disabled={!!initialValues.parent_id}/>}
      </Row>
    </CreateModal>
  );
};

export default CustomerModal;
