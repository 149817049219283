import {
  SET_ASSET_TPMS_STATUS,
  SET_SMARTHUB_STATUS,
  SET_LINE_PRESSURE_STATUS,
  SET_LATEST_AXLE_LOADS,
  LOADING_ASSET_STATUS,
} from "../actionTypes";
import { convertDataEpochToDate } from "../../utils";
import api from "../../services/api";

const action = (type, payload) => ({ type, payload });


export function fetchAssetStatus(assetId, type) {
  return async function (dispatch, getState) {
    dispatch(action(LOADING_ASSET_STATUS, true));
    const state = getState();
    const isSuperAdmin = state.auth.isSuperAdmin;
    const subscriptions = state.common.customerDefaults.subscriptions;
    const inflation_pressure = state.common.customerDefaults.tpms.cold_inflation_pressure_in_psi

    const checkSubscription = (key) => {
      if (!(key in subscriptions)) return false;
      
      const src = subscriptions[key]
      const subsEnd = new Date(convertDataEpochToDate(src.valid_to,null,null,null,null,false,true));
      const currentDate = new Date(convertDataEpochToDate(Date.now() / 1000,null,null,null,null,false,true));

      if (subsEnd > currentDate) return true;
      return false;
    };

    const adjustInflationPressure = (obj)=>{
      const isNullPressure = obj.sensors.some((el)=>el.cold_inflation_pressure_in_psi === null)
      if(isNullPressure){
        return {...obj, sensors: obj.sensors.map((sensor)=>({...sensor, cold_inflation_pressure_in_psi:inflation_pressure}))}
      }
      else return obj
    }
    return api
      .getAssetStatuses(type, assetId)
      .then((data) => {
        let tpms = []
        if (data.tpms[0])
         tpms = adjustInflationPressure(data.tpms[0])
        if (!isSuperAdmin) {
          checkSubscription("TIRE_SENSOR") &&
            dispatch(action(SET_ASSET_TPMS_STATUS, tpms || {}));
          checkSubscription("HUB_SENSOR") &&
            dispatch(action(SET_SMARTHUB_STATUS, data.hubs));
          checkSubscription("LINE_PRESSURE") &&
            dispatch(action(SET_LINE_PRESSURE_STATUS, data.line));
          checkSubscription("AXLE_LOAD") &&
            dispatch(action(SET_LATEST_AXLE_LOADS, data.axles));
        }
        dispatch(action(SET_ASSET_TPMS_STATUS, tpms || {}));
        dispatch(action(SET_SMARTHUB_STATUS, data.hubs));
        dispatch(action(SET_LINE_PRESSURE_STATUS, data.line));
        dispatch(action(SET_LATEST_AXLE_LOADS, data.axles));
      })
      .catch((error) => console.log(error))
      .finally(() => dispatch(action(LOADING_ASSET_STATUS, false)));
  };
}