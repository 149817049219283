import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as assetAdminInfoActions from '../redux/assetAdminInfo/action'
import * as authActions from '../redux/auth/action'
import * as axleLoadCalibrationActions from '../redux/axleLoadCalibration/action'
import * as commonActions from '../redux/common/action'
import * as dashboardActions from '../redux/dashboard/action'
import * as detailsActions from '../redux/details/action'
import * as assetDetailsActions from '../redux/assetDetails/action'
import * as documentsActions from '../redux/documents/action'
import * as geofencesActions from '../redux/geofences/action'
import * as tagLocationsActions from '../redux/tagLocations/action'
import * as provisionActions from '../redux/provision/action'
import * as reportsActions from '../redux/reports/action'
import * as scheduledReportsActions from '../redux/scheduledReports/action'
import * as settingsActions from '../redux/settings/action'
import * as adminLogsActions from '../redux/adminLogs/actions'
import * as tpmsPageActions from '../redux/tpmsProfiles/action'
import * as manageGeofencesActions from '../redux/manageGeofences/action'
import * as cargoTagsActions from '../redux/cargoTags/action'
import * as manageNotificationsActions from '../redux/manageNotifications/action'
import * as assetGroupsActions from '../redux/assetGroups/action'
import * as reportsHistoryActions from '../redux/reportsHistory/action'
import * as assetBulkUploadActions from '../redux/assetBulkUpload/action'

const actions = {
  ...assetAdminInfoActions,
  ...authActions,
  ...axleLoadCalibrationActions,
  ...commonActions,
  ...dashboardActions,
  ...assetDetailsActions,
  ...detailsActions,
  ...documentsActions,
  ...geofencesActions,
  ...tagLocationsActions,
  ...provisionActions,
  ...reportsActions,
  ...scheduledReportsActions,
  ...settingsActions,
  ...adminLogsActions,
  ...tpmsPageActions,
  ...manageGeofencesActions,
  ...cargoTagsActions,
  ...manageNotificationsActions,
  ...assetGroupsActions,
  ...reportsHistoryActions,
  ...assetBulkUploadActions,
}

export const useActions = () => {
  const dispatch = useDispatch()

  return useMemo(() => {
    return bindActionCreators(actions, dispatch)
  }, [dispatch])
}
