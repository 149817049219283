import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

interface IUserdata {
  user_name?: string;
  first_name?: string;
  last_name?: string;
  status?: string;
  endpoint: string;
}

interface IMessage {
  message: string;
  push_message: string;
}
interface Props {
  open: boolean;
  locale: Record<string, string>;
  header: string;
  recipients: {
    emails: Array<IUserdata>;
    sms: Array<IUserdata>;
    push: Array<IUserdata>;
  };
  messages: IMessage;
  onConfirm: () => void;
}

const AlertInfoModal: React.FC<Props> = ({
  onConfirm,
  locale,
  header,
  open,
  messages,
  recipients,
}: Props) => {
  const getEndpoints = (
    title: string,
    arr: Array<IUserdata>,
    isPush?: boolean
  ) => {
    return (
      <div className="mb-2 d-flex flex-column">
        <h6>{title}</h6>
        {arr.map((el: IUserdata, i:number) => {
          const username = el.user_name ? el.user_name : "";
          const firstName = el.first_name ? el.first_name : "";
          const lastName = el.last_name ? el.last_name : "";
          const status = el.status ? ` (${el.status})` : "";
          const userInfo = !el.first_name || !el.last_name ? "" : ` (${firstName} ${lastName})`;

          return (
            <span key={el.user_name}>
              {username}
              {userInfo}
              {!isPush ? <> - {el.endpoint} <i>{`${status}`}</i> </> : <i>{` ${status}`}</i> }
            </span>
          );
        })}
      </div>
    );
  };
  return (
    <Modal isOpen={open} size="lg">
      <ModalHeader toggle={onConfirm}>{header}</ModalHeader>
      <ModalBody>
        <div>
          <b>Alert text: </b>
        </div>
        {messages.message}
        <br /> <br />
        <div>
          {recipients ? (
            <>
              {recipients.emails.length ? getEndpoints("Email Recipients:", recipients.emails) : ""}
              {recipients.sms.length ? getEndpoints("SMS Recipients:", recipients.sms) : ""}
              {recipients.push.length ? getEndpoints("Push Recipients:", recipients.push, true) : ""}
            </>
          ) : (
            <b>No recipients info</b>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onConfirm}>
          {locale["ok"] || "Ok"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AlertInfoModal;
