import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Collapse, Modal, ModalBody } from "reactstrap";
import ErrorBoundry from "../../components/ErrorBoundry";
import "./styles.scss";
import { saveGoogleAnalyticsEvent } from '../../utils/utils';

const collapseIcon = require('../../assets/images/collapse.png');
const expandIcon = require('../../assets/images/expand.png');

const RivataModule = ({
  title,
  subTitle = "",
  showInfoIcon = false,
  width = 12,
  children,
  filters,
  locale,
  error = {
    statusCode: 0,
    message: "",
  },
  paddingMobile = true,
  bg = "light",
  text = "dark",
  marginTop = -1,
  customClass = "",
  collapsible = false,
  defaultCollapsed = false,
  fullScreenModalModeEnabled = false,
  customFullScreenAction = (f) => { },
  onCollapse = (b) => { },
  forceShowInModal = false,
  customCollapseIcon = undefined,
  customModalClass = ""
}) => {
  const [collapsed, setCollapsed] = useState(false);
  const [fullScreenMode, setFullScreenMode] = useState(forceShowInModal);
  const toggleFullScreenMode = () => {
    customFullScreenAction(!fullScreenMode);
    setFullScreenMode(!fullScreenMode);
  }

  useEffect(() => {
    if (fullScreenMode) {
      saveGoogleAnalyticsEvent('maximize_button_click', { title })
    }
  }, [fullScreenMode, title])

  const onHeaderClick = () => {
    if (collapsible) {
      setCollapsed(!collapsed);
      onCollapse(!collapsed)
    }
  };

  useEffect(() => {
    if (defaultCollapsed) setCollapsed(true)
  }, [defaultCollapsed])

  const renderModule = () => (
    <Col
      id="rivata-module-component"
      lg={width}
      data-component="fleet-map"
      className={`mt-${marginTop > -1 ? marginTop : 4} ${customClass}`}
    >
      <Card>
        <CardHeader
          className={`border-bottom p-3 mb-0 d-inline-flex d-flex flex-column flex-md-row flex-wrap`}
          style={{ background: "#dcddde" }}
        >
          <div
            onClick={onHeaderClick}
            className={`collapsed-header-style ${subTitle && "flex-column header-with-subtitle-style"} ${collapsible && "collapsed-header-cursor"}`}
          >
            {collapsible && (
              <i
                className={`fa fa-arrow-down collapse-arrow-style ${collapsed && "collapse-arrow-expanded"
                  }`}
              ></i>
            )}
            <h4
              className={`d-block mb-0 mr-2`}
              style={{ fontWeight: 400, color: "#414042" }}
            >
              {locale[title] || title}
            </h4>
            <h6
              className={`d-block mb-0 mr-2`}
              style={{ fontWeight: 400, color: "#414042" }}
            >
              {showInfoIcon ? <i className="fa fa-info-circle font-12" style={{ color: "#33afc9", marginRight: "6px" }} /> : ""}
              {locale[subTitle] || subTitle}
            </h6>
          </div>

          <div className="d-inline-flex mt-2 ml-md-2 justify-content-end flex-grow-1 flex-wrap">
            {filters}

            {fullScreenModalModeEnabled && <img onClick={toggleFullScreenMode} className="expand-icon" src={fullScreenMode ? (customCollapseIcon || collapseIcon) : expandIcon} alt="" />}
          </div>
        </CardHeader>
        <Collapse isOpen={!collapsed}>
          <CardBody
            className={`position-relative${!paddingMobile ? " no-pad-mobile" : ""
              }`}
          >
            <ErrorBoundry error={error}>{children}</ErrorBoundry>
          </CardBody>
        </Collapse>
      </Card>
    </Col>
  )


  return (<>
    {!fullScreenMode && renderModule()}
    {(fullScreenModalModeEnabled && fullScreenMode) && <Modal modalTransition={{ timeout: 0 }} isOpen={fullScreenMode} className={`rivata-module-fullscreen-modal ${customModalClass}`}>
      <ModalBody>
        {renderModule()}
      </ModalBody>
    </Modal>}
  </>
  );
};

export default RivataModule;
