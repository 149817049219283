import {
  InternalScheduledReportTypes,
  ScheduledReportTypes,
  WarningCase,
} from '../enums'

interface IAxlesGroups {
  [key: string]: Array<string>
}

export const AxlesGroups: IAxlesGroups = {
  Steer: ['Steer Left', 'Steer Right'],
  'Drive 1': [
    'Drive Left 1 Outer',
    'Drive Left 1 Inner',
    'Drive Right 1 Outer',
    'Drive Right 1 Inner',
    'Drive Right 1',
    'Drive Left 1',
  ],
  'Drive 2': [
    'Drive Left 2 Outer',
    'Drive Left 2 Inner',
    'Drive Right 2 Outer',
    'Drive Right 2 Inner',
    'Drive Right 2',
    'Drive Left 2',
  ],
  'Drive 3': [
    'Drive Left 3 Outer',
    'Drive Left 3 Inner',
    'Drive Right 3 Outer',
    'Drive Right 3 Inner',
    'Drive Right 3',
    'Drive Left 3',
  ],
  'Drive 4': [
    'Drive Left 4 Outer',
    'Drive Left 4 Inner',
    'Drive Right 4 Outer',
    'Drive Right 4 Inner',
    'Drive Right 4',
    'Drive Left 4',
  ],
  'Drive 5': [
    'Drive Left 5 Outer',
    'Drive Left 5 Inner',
    'Drive Right 5 Outer',
    'Drive Right 5 Inner',
    'Drive Right 5',
    'Drive Left 5',
  ],
  'Drive 6': [
    'Drive Left 6 Outer',
    'Drive Left 6 Inner',
    'Drive Right 6 Outer',
    'Drive Right 6 Inner',
    'Drive Right 6',
    'Drive Left 6',
  ],
  'Trailer 1': [
    'Trailer Left 1 Outer',
    'Trailer Left 1 Inner',
    'Trailer Right 1 Outer',
    'Trailer Right 1 Inner',
    'Trailer Left 1',
    'Trailer Right 1',
  ],
  'Trailer 2': [
    'Trailer Left 2 Outer',
    'Trailer Left 2 Inner',
    'Trailer Right 2 Outer',
    'Trailer Right 2 Inner',
    'Trailer Left 2',
    'Trailer Right 2',
  ],
  'Trailer 3': [
    'Trailer Left 3 Outer',
    'Trailer Left 3 Inner',
    'Trailer Right 3 Outer',
    'Trailer Right 3 Inner',
    'Trailer Left 3',
    'Trailer Right 3',
  ],
  'Trailer 4': [
    'Trailer Left 4 Outer',
    'Trailer Left 4 Inner',
    'Trailer Right 4 Outer',
    'Trailer Right 4 Inner',
    'Trailer Left 4',
    'Trailer Right 4',
  ],
  'Trailer 5': [
    'Trailer Left 5 Outer',
    'Trailer Left 5 Inner',
    'Trailer Right 5 Outer',
    'Trailer Right 5 Inner',
    'Trailer Left 5',
    'Trailer Right 5',
  ],
  'Trailer 6': [
    'Trailer Left 6 Outer',
    'Trailer Left 6 Inner',
    'Trailer Right 6 Outer',
    'Trailer Right 6 Inner',
    'Trailer Left 6',
    'Trailer Right 6',
  ],
}

export const AxlesGroupsOrder: { [key: string]: number } = {
  Steer: 0,
  'Drive 1': 10,
  'Drive 2': 20,
  'Drive 3': 30,
  'Drive 4': 40,
  'Drive 5': 50,
  'Drive 6': 60,
  'Trailer 1': 70,
  'Trailer 2': 80,
  'Trailer 3': 90,
  'Trailer 4': 100,
  'Trailer 5': 110,
  'Trailer 6': 120,
}

export const NotificationSystemProfileNames: { [key: string]: string } = {
  system_profile_sms_tpms_critical: 'TPMS Critical SMS',
  system_profile_email_tpms_critical: 'TPMS Critical Email',
  system_profile_sms_tpms_non_critical: 'TPMS Informational SMS',
  system_profile_email_tpms_non_critical: 'TPMS Informational Email',
  system_profile_email_line_pressure: 'Line Pressure Email',
  system_profile_sms_line_pressure: 'Line Pressure SMS',
  system_profile_sms_temperature_critical: 'Critical Hub Temperature SMS',
  system_profile_sms_temperature_non_critical: 'Hub Temperature SMS',
  system_profile_email_temperature_critical: 'Critical Hub Temperature Email',
  system_profile_email_temperature_non_critical: 'Hub Temperature Email',
  system_profile_sms_asset_state: 'Asset State SMS',
  system_profile_email_asset_state: 'Asset State Email',
  system_profile_sms_geofence_entry: 'Geofence Entry SMS',
  system_profile_email_geofence_entry: 'Geofence Entry Email',
  system_profile_sms_geofence_exit: 'Geofence Exit SMS',
  system_profile_email_geofence_exit: 'Geofence Exit Email',
  system_profile_sms_vibration_critical: 'Vibration Critical SMS',
  system_profile_email_vibration_critical: 'Vibration Critical Email',
  system_profile_sms_vibration_non_critical: 'Vibration Informational SMS',
  system_profile_email_vibration_non_critical: 'Vibration Informational Email',
  system_profile_sms_over_speed: 'Over Speed Informational SMS',
  system_profile_email_over_speed: 'Over Speed Information Email',
  system_profile_sms_critical_over_speed: 'Over Speed Critical SMS',
  system_profile_email_critical_over_speed: 'Over Speed Critical Email',
  system_profile_sms_pressure_fast_leak: 'Pressure Fast Leak SMS',
  system_profile_email_pressure_fast_leak: 'Pressure Fast Leak Email',
}

export const CriticalWarningTypes = [
  WarningCase.ABNORMAL_HIGH_BEARING_VIBRATION,
  WarningCase.ABNORMAL_HIGH_TIRE_VIBRATION,
  WarningCase.CRITICAL_HIGH_TEMPERATURE,
  WarningCase.CRITICAL_LOW_PRESSURE,
  WarningCase.CRITICAL_OVER_PRESSURE,
  WarningCase.LOW_LINE_PRESSURE,
]

export const TpmsWarningTypes = [
  WarningCase.CRITICAL_OVER_PRESSURE,
  WarningCase.OVER_PRESSURE,
  WarningCase.CRITICAL_LOW_PRESSURE,
  WarningCase.LOW_PRESSURE,
  WarningCase.NO_STATUS_TPMS,
  WarningCase.FAST_LEAK,
]

export const SmartHubWarningTypes = [
  WarningCase.CRITICAL_HIGH_TEMPERATURE,
  WarningCase.HIGH_TEMPERATURE,
  WarningCase.ABNORMAL_HIGH_BEARING_VIBRATION,
  WarningCase.ABNORMAL_BEARING_VIBRATION,
  WarningCase.ABNORMAL_HIGH_TIRE_VIBRATION,
  WarningCase.ABNORMAL_TIRE_VIBRATION,
  WarningCase.NO_STATUS_SMARTHUB,
]

export const LinePressureWarningTypes = [
  WarningCase.LOW_LINE_PRESSURE,
  WarningCase.NO_STATUS_LINE_PRESSURE,
]

export const AxleLoadWarningTypes = [WarningCase.NO_STATUS_AXLE_LOAD]

export const DefaultCron = '0 0 * * *'

export const CronTabNames = ['Hourly', 'Daily', 'Weekly', 'Monthly']

export const CronSettingsInitialValue = {
  datetime: {
    hours: 0,
    minutes: 0,
    day: 1,
  },
  tabs: {
    hourly: {
      everyNHours: false,
      everyNMinutes: false,
      atHoursAndMinutes: false,
    },
    daily: {
      everyNDays: false,
      everyWeekdays: false,
    },
    weekly: {
      options: [
        {
          id: 0,
          name: 'sunday',
          enabled: false,
        },
        {
          id: 1,
          name: 'monday',
          enabled: false,
        },
        {
          id: 2,
          name: 'tuesday',
          enabled: false,
        },
        {
          id: 3,
          name: 'wednesday',
          enabled: false,
        },
        {
          id: 4,
          name: 'thursday',
          enabled: false,
        },
        {
          id: 5,
          name: 'friday',
          enabled: false,
        },
        {
          id: 6,
          name: 'saturday',
          enabled: false,
        },
      ],
    },
    monthly: {
      nDaysOfEveryMonth: false,
    },
  },
}

export const AssetTypes = [
  // { value: "", label: "" },
  { value: 'all', label: 'All' },
  { value: 'tractor', label: 'Tractor' },
  { value: 'trailer', label: 'Trailer' },
]

export const WarningTypes = [
  { value: 'all', label: 'All' },
  { value: 'warning', label: 'Warning' },
  { value: 'no_status', label: 'No Status' },
]

export const AssetTypeSelectorReports: string[] = [
  InternalScheduledReportTypes.INTERNAL_TPMS_REPORT,
  ScheduledReportTypes.TPMS_REPORT,
  ScheduledReportTypes.ASSETS_IN_GEOFENCE_REPORT,
  ScheduledReportTypes.ACTIVE_WARNINGS_REPORT,
  ScheduledReportTypes.DWELL_REPORT,
  ScheduledReportTypes.DEVICE_HEALTH_REPORT,
]

export const AssetHealthSelectorReports: string[] = [
  ScheduledReportTypes.TPMS_REPORT,
]

export const AssetsSelectorReports: string[] = [
  ScheduledReportTypes.TPMS_REPORT,
  ScheduledReportTypes.ASSETS_IN_GEOFENCE_REPORT,
  ScheduledReportTypes.ACTIVE_WARNINGS_REPORT,
  ScheduledReportTypes.DWELL_REPORT,
  ScheduledReportTypes.DEVICE_HEALTH_REPORT,
]

export const CustomerSelectorReports: string[] = [
  InternalScheduledReportTypes.INTERNAL_TPMS_REPORT,
  InternalScheduledReportTypes.INTERNAL_CONMET_DAILY_REPORT,
  ScheduledReportTypes.TPMS_REPORT,
  ScheduledReportTypes.ASSETS_IN_GEOFENCE_REPORT,
  ScheduledReportTypes.ACTIVE_WARNINGS_REPORT,
  ScheduledReportTypes.DWELL_REPORT,
  ScheduledReportTypes.DEVICE_HEALTH_REPORT,
]

export const GeofencesSelectorReports: string[] = [
  ScheduledReportTypes.TPMS_REPORT,
  ScheduledReportTypes.ASSETS_IN_GEOFENCE_REPORT,
  ScheduledReportTypes.ACTIVE_WARNINGS_REPORT,
  ScheduledReportTypes.DWELL_REPORT,
]

export const SingleCustomerItemReports: string[] = [
  ScheduledReportTypes.TPMS_REPORT,
  ScheduledReportTypes.ASSETS_IN_GEOFENCE_REPORT,
  ScheduledReportTypes.ACTIVE_WARNINGS_REPORT,
  ScheduledReportTypes.DWELL_REPORT,
  ScheduledReportTypes.DEVICE_HEALTH_REPORT,
]

export const WarningTypeReports: string[] = [
  ScheduledReportTypes.ACTIVE_WARNINGS_REPORT,
]

export const UserSelectorReports: string[] = [
  InternalScheduledReportTypes.INTERNAL_FAILED_NOTIFICATION_HISTORY_REPORT,
]

export const AssetGroupSelectorReports: string[] = [
  ScheduledReportTypes.ACTIVE_WARNINGS_REPORT,
  ScheduledReportTypes.ASSETS_IN_GEOFENCE_REPORT,
  ScheduledReportTypes.DWELL_REPORT,
  ScheduledReportTypes.TPMS_REPORT,
  InternalScheduledReportTypes.INTERNAL_TPMS_REPORT,
  ScheduledReportTypes.DEVICE_HEALTH_REPORT,
]

export const DaysSelectorReports: string[] = [
  InternalScheduledReportTypes.INTERNAL_FAILED_NOTIFICATION_HISTORY_REPORT,
]

export const dateFormat = 'ddd, MMM D, YYYY'
export const dateWithTimeFormat = 'ddd, MMM D, YYYY hh:mm A'
export const timeFormat = 'hh:mm A'

export const ScheduledReportsWithDateRange: string[] = []
