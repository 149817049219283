import { AssetSubtypeTractor, AssetSubtypeTrailer } from "../../enums";
import { getClearLabel } from "../../utils";
import { MenuItemType } from "../Menu/MenuItem";

export const assetTypeItems: Array<MenuItemType> = [
    { id: "tractor", label: "Tractor", type: "checkbox", markType: "check", checked: false, parentId: null, subMenuExpanded: true },
    { id: AssetSubtypeTractor.DAY_CAB, label: getClearLabel(AssetSubtypeTractor.DAY_CAB), type: "checkbox", markType: "check", checked: false, parentId: "tractor" },
    { id: AssetSubtypeTractor.SLEEPER_CAB, label: getClearLabel(AssetSubtypeTractor.SLEEPER_CAB), type: "checkbox", markType: "check", checked: false, parentId: "tractor" },
    { id: AssetSubtypeTractor.OTHER_TRACTOR, label: getClearLabel(AssetSubtypeTractor.OTHER_TRACTOR), type: "checkbox", markType: "check", checked: false, parentId: "tractor" },
    { id: "unknown_tractor", label: "Unknown", type: "checkbox", markType: "check", checked: false, parentId: "tractor" },
    { id: "trailer", label: "Trailer", type: "checkbox", markType: "check", checked: false, parentId: null, subMenuExpanded: true },
    { id: AssetSubtypeTrailer.BULK_TRAILER, label: getClearLabel(AssetSubtypeTrailer.BULK_TRAILER), type: "checkbox", markType: "check", checked: false, parentId: "trailer" },
    { id: AssetSubtypeTrailer.CAR_CARRIER_TRAILER, label: getClearLabel(AssetSubtypeTrailer.CAR_CARRIER_TRAILER), type: "checkbox", markType: "check", checked: false, parentId: "trailer" },
    { id: AssetSubtypeTrailer.CURTAIN_SIDE_TRAILER, label: getClearLabel(AssetSubtypeTrailer.CURTAIN_SIDE_TRAILER), type: "checkbox", markType: "check", checked: false, parentId: "trailer" },
    { id: AssetSubtypeTrailer.DOLLY, label: getClearLabel(AssetSubtypeTrailer.DOLLY), type: "checkbox", markType: "check", checked: false, parentId: "trailer" },
    { id: AssetSubtypeTrailer.DROP_DECK_TRAILER, label: getClearLabel(AssetSubtypeTrailer.DROP_DECK_TRAILER), type: "checkbox", markType: "check", checked: false, parentId: "trailer" },
    { id: AssetSubtypeTrailer.DRY_VAN_TRAILER, label: getClearLabel(AssetSubtypeTrailer.DRY_VAN_TRAILER), type: "checkbox", markType: "check", checked: false, parentId: "trailer" },
    { id: AssetSubtypeTrailer.EXTENDABLE_DOUBLE_DROP_TRAILER, label: getClearLabel(AssetSubtypeTrailer.EXTENDABLE_DOUBLE_DROP_TRAILER), type: "checkbox", markType: "check", checked: false, parentId: "trailer" },
    { id: AssetSubtypeTrailer.FLATBED_TRAILER, label: getClearLabel(AssetSubtypeTrailer.FLATBED_TRAILER), type: "checkbox", markType: "check", checked: false, parentId: "trailer" },
    { id: AssetSubtypeTrailer.HOPPER_BOTTOM_TRAILER, label: getClearLabel(AssetSubtypeTrailer.HOPPER_BOTTOM_TRAILER), type: "checkbox", markType: "check", checked: false, parentId: "trailer" },
    { id: AssetSubtypeTrailer.INTERMODAL_CONTAINTER_TRAILER, label: getClearLabel(AssetSubtypeTrailer.INTERMODAL_CONTAINTER_TRAILER), type: "checkbox", markType: "check", checked: false, parentId: "trailer" },
    { id: AssetSubtypeTrailer.LIVESTOCK_TRAILER, label: getClearLabel(AssetSubtypeTrailer.LIVESTOCK_TRAILER), type: "checkbox", markType: "check", checked: false, parentId: "trailer" },
    { id: AssetSubtypeTrailer.LOGGING_TRAILER, label: getClearLabel(AssetSubtypeTrailer.LOGGING_TRAILER), type: "checkbox", markType: "check", checked: false, parentId: "trailer" },
    { id: AssetSubtypeTrailer.LOWBODY_TRAILER, label: getClearLabel(AssetSubtypeTrailer.LOWBODY_TRAILER), type: "checkbox", markType: "check", checked: false, parentId: "trailer" },
    { id: AssetSubtypeTrailer.POLE_TRAILER, label: getClearLabel(AssetSubtypeTrailer.POLE_TRAILER), type: "checkbox", markType: "check", checked: false, parentId: "trailer" },
    { id: AssetSubtypeTrailer.REFRIGERATED_TRAILER, label: getClearLabel(AssetSubtypeTrailer.REFRIGERATED_TRAILER), type: "checkbox", markType: "check", checked: false, parentId: "trailer" },
    { id: AssetSubtypeTrailer.SPECIALIZED_HEAVY_HAUL_TRAILER, label: getClearLabel(AssetSubtypeTrailer.SPECIALIZED_HEAVY_HAUL_TRAILER), type: "checkbox", markType: "check", checked: false, parentId: "trailer" },
    { id: AssetSubtypeTrailer.TANKER_TRAILER, label: getClearLabel(AssetSubtypeTrailer.TANKER_TRAILER), type: "checkbox", markType: "check", checked: false, parentId: "trailer" },
    { id: AssetSubtypeTrailer.OTHER_TRAILER, label: getClearLabel(AssetSubtypeTrailer.OTHER_TRAILER), type: "checkbox", markType: "check", checked: false, parentId: "trailer" },
    { id: "unknown_trailer", label: "Unknown", type: "checkbox", markType: "check", checked: false, parentId: "trailer" }
]
