import { Dispatch } from 'react'
import api from '../../services/api'
import { getErrorObj } from '../../utils'

import { IgetState } from '../types'
import { AssetBulkUploadActionTypes } from './types'

export function postAssetsCsv(base64str: string, file_name: string) {
  return async function (dispatch: Dispatch<any>, getState: IgetState) {
    try {
      dispatch({
        type: AssetBulkUploadActionTypes.UPLOADING_ASSETS_BULK_UPLOAD_FILE,
        payload: true,
      })
      const selectedCustomersList =
        getState().common.customers.selectedCustomersList
      if (selectedCustomersList.length !== 1) return

      await api.postAssetsCsv(base64str, selectedCustomersList[0].id, file_name)

      const errorObj = getErrorObj({
        statusCode: 200,
        message:
          'File uploaded successfully. \n Assets will be added to the system soon.',
      })
      return errorObj
    } catch (err) {
      const errorObj = getErrorObj(err as any)
      return errorObj
    } finally {
      dispatch({
        type: AssetBulkUploadActionTypes.UPLOADING_ASSETS_BULK_UPLOAD_FILE,
        payload: false,
      })
    }
  }
}
export function getAssetsBulkUploadHistory() {
  return async function (dispatch: Dispatch<any>, getState: IgetState) {
    try {
      dispatch({
        type: AssetBulkUploadActionTypes.LOADING_BULK_UPLOAD_HISTORY,
        payload: true,
      })
      const { offset, limit, sortOptions } = getState().assetBulkUpload.history

      const result = await api.getAssetsBulkUploadHistory(
        limit,
        offset,
        sortOptions.column,
        sortOptions.direction,
      )
      dispatch({
        type: AssetBulkUploadActionTypes.SET_BULK_UPLOAD_HISTORY,
        payload: result.paged_data,
      })
      dispatch({
        type: AssetBulkUploadActionTypes.SET_BULK_UPLOAD_HISTORY_TOTAL_COUNT,
        payload: result.total_count,
      })
    } catch (err) {
      console.log(err)
    } finally {
      dispatch({
        type: AssetBulkUploadActionTypes.LOADING_BULK_UPLOAD_HISTORY,
        payload: false,
      })
    }
  }
}

export const setAssetBulkUploadHistoryOffset = (payload: number) => ({
  type: AssetBulkUploadActionTypes.SET_BULK_UPLOAD_HISTORY_OFFSET,
  payload,
})

export const setAssetBulkUploadHistoryLimit = (payload: number) => ({
  type: AssetBulkUploadActionTypes.SET_BULK_UPLOAD_HISTORY_LIMIT,
  payload,
})
export const setAssetBulkUploadHistorySortOptions = (payload: any) => ({
  type: AssetBulkUploadActionTypes.SET_BULK_UPLOAD_HISTORY_SORT_OPTIONS,
  payload,
})
