import { SET_REFRESHED_TOKEN, SET_SELECTED_CUSTOMERS_LIST, SET_SESSION } from '../../../redux/actionTypes'
import { store } from '../../../redux/store'
import { fetchOrDelete, updateOrCreate } from '../base'

interface IHeader {
  [key: string]: string
}

class BaseApi {
  authToken: string | null = null
  customerIdsList: string | null = null

  constructor() {
    store.subscribe(() => {
      const lastAction = store.getState().lastAction

      if (lastAction.type === SET_SESSION) {
        this.authToken = lastAction.payload.credentials.idToken
      } else if (lastAction.type === SET_REFRESHED_TOKEN) {
        this.authToken = lastAction.payload
      } else if (lastAction.type === SET_SELECTED_CUSTOMERS_LIST) {
        const customersCount = store.getState().common.customers.data.length

        if (
          (customersCount > 0 && lastAction.payload.length !== customersCount) || (lastAction.payload.length === 1 && customersCount === 1) // 1 customer user.
        ) {
          this.customerIdsList = lastAction.payload.map((c: ICustomer) => c.id).join(",")
        } else {
          this.customerIdsList = null
        }
      }
    })
  }

  get = (endpoint: string, addCustomerIds = true, headers?: IHeader) => {
    // check if endpoint already have query params
    if (addCustomerIds && this.customerIdsList) {
      let sign = "?"
      if (endpoint.indexOf("?") > -1) sign = "&"

      endpoint += `${sign}customer_ids=${this.customerIdsList}`
    }

    return fetchOrDelete("GET", endpoint, this.authToken, headers)
  }

  post = (endpoint: string, data: any, headers?: IHeader, need_to_stringify_data: boolean = true) => {
    // need_to_stringify_data parameter added to be able to use post method in different ways.
    // File class upload or basic post requests executing. If we do JSON.stringify to File object, if will become an empty object.
    const body = need_to_stringify_data ? JSON.stringify(data) : data
    return updateOrCreate("POST", endpoint, body, this.authToken, headers)
  }

  put = (endpoint: string, data: any, headers?: IHeader) => {
    return updateOrCreate("PUT", endpoint, JSON.stringify(data), this.authToken, headers)
  }

  delete = (endpoint: string, headers?: IHeader) => {
    return fetchOrDelete("DELETE", endpoint, this.authToken, headers)
  }
}

const baseApi = new BaseApi()

export default baseApi