import React, { useCallback, useMemo, useState } from 'react'
import {
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap'
import { IAssetSensors } from './types'

enum warningTypes {
  smarthubTemp = 'smarthubTemp',
  smarthubVib = 'smarthubVib',
  tpms = 'tpms',
  linePressure = 'linePressure',
  axleLoad = 'axleLoad',
}

enum vibrationTypes {
  normalVibration = 'normalVibration',
  abnormalTireVibration = 'abnormalTireVibration',
  abnormalHighTireVibration = 'abnormalHighTireVibration',
  abnormalBearingVibration = 'abnormalBearingVibration',
  abnormalHighBearingVibration = 'abnormalHighBearingVibration',
}

const vibrationTypeOptions = [
  { value: vibrationTypes.normalVibration, label: 'Normal vibration' },
  {
    value: vibrationTypes.abnormalTireVibration,
    label: 'Tire vibration',
  },
  {
    value: vibrationTypes.abnormalHighTireVibration,
    label: 'High severity tire vibration',
  },
  {
    value: vibrationTypes.abnormalBearingVibration,
    label: 'Bearing vibration',
  },
  {
    value: vibrationTypes.abnormalHighBearingVibration,
    label: 'High severity bearing vibration',
  },
]

type Props = {
  assetSensors: IAssetSensors
  onAddWarning: (
    warningType: string,
    mac: string,
    value: number | string,
  ) => void
  disabled?: boolean
}

const AddSensorDataModal: React.FC<Props> = ({
  assetSensors,
  onAddWarning,
  disabled,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [warningType, setWarningType] = useState(warningTypes.smarthubTemp)
  const [sensorMac, setSensor] = useState('')
  const [value, setValue] = useState(0)
  const [vibrationType, setVibrationType] = useState(
    vibrationTypes.normalVibration,
  )

  const warningTypeOptions = useMemo(() => {
    const options: Array<{ value: warningTypes; label: string }> = []

    if (assetSensors.smarthub_sensors)
      options.push(
        { value: warningTypes.smarthubTemp, label: 'SmartHub temperature' },
        // { value: warningTypes.smarthubVib, label: "SmartHub vibration" }
      )

    if (assetSensors.tpms)
      options.push({ value: warningTypes.tpms, label: 'TPMS' })

    if (assetSensors.line_pressure)
      options.push({ value: warningTypes.linePressure, label: 'Line pressure' })

    if (assetSensors.axle_load)
      options.push({ value: warningTypes.axleLoad, label: 'Axle load' })

    return options
  }, [assetSensors])

  const sensors = useMemo(() => {
    if (warningType === 'smarthubTemp' || warningType === 'smarthubVib')
      return assetSensors.smarthub_sensors

    if (warningType === 'tpms') return assetSensors.tpms
  }, [warningType, assetSensors])

  const reset = useCallback(() => {
    setWarningType(warningTypeOptions[0].value ?? warningTypes.smarthubTemp)
    setSensor('')
    setValue(0)
    setVibrationType(vibrationTypes.normalVibration)
  }, [warningTypeOptions])

  const toggle = useCallback(() => {
    if (!isOpen) reset()
    setIsOpen(!isOpen)
  }, [isOpen, reset])

  const onAdd = useCallback(() => {
    const warningValue = warningType !== 'smarthubVib' ? value : vibrationType

    if (
      !sensorMac &&
      warningType !== warningTypes.linePressure &&
      warningType !== warningTypes.axleLoad
    )
      return

    onAddWarning(warningType, sensorMac, warningValue)
    setIsOpen(false)
  }, [onAddWarning, sensorMac, value, vibrationType, warningType])

  return (
    <>
      <Button onClick={toggle} disabled={disabled}>
        Add Sensor Data
      </Button>

      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Add Sensor Data</ModalHeader>

        <ModalBody>
          <label>Sensor Type:</label>

          <Input
            type='select'
            value={warningType}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              reset()
              setWarningType(e.target.value as warningTypes)
            }}
          >
            {warningTypeOptions.map((wt) => (
              <option value={wt.value}>{wt.label}</option>
            ))}
          </Input>

          {sensors && (
            <>
              <label className='mt-3'>Sensor:</label>

              <Input
                type='select'
                value={sensorMac}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  setSensor(e.target.value)
                }
              >
                <option value='' />

                {sensors.map((sensor) => (
                  <option value={sensor.mac}>
                    {sensor.mac} | {sensor.position}
                  </option>
                ))}
              </Input>
            </>
          )}

          {warningType !== 'smarthubVib' ? (
            <>
              <label className='mt-3'>
                {warningType === 'smarthubTemp' ? 'Temperature:' : 'Pressure:'}
              </label>

              <Input
                type='number'
                value={value}
                onChange={(e: any) => {
                  setValue(+e.target.value)
                }}
              />
            </>
          ) : (
            <>
              <label className='mt-3'>Vibration type:</label>

              <Input
                type='select'
                value={vibrationType}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  setVibrationType(e.target.value as vibrationTypes)
                }}
              >
                {vibrationTypeOptions.map((vt) => {
                  return <option value={vt.value}>{vt.label}</option>
                })}
              </Input>
            </>
          )}
        </ModalBody>

        <ModalFooter>
          <Button
            color='primary'
            className='mr-2'
            onClick={onAdd}
            disabled={
              !sensorMac &&
              warningType !== warningTypes.linePressure &&
              warningType !== warningTypes.axleLoad
            }
          >
            Add Sensor Data
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default AddSensorDataModal
