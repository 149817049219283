import {
  CLEAR_SESSION,
  SET_SESSION,
  SET_TOKEN_LIFE_TIME,
  SET_REFRESHED_TOKEN,
  CHECK_SESSION,
  SAVING_SESSION,
  SET_SESSION_ERROR,
  SET_PREFERENCES,
  SET_AUTH_WHITELABEL,
  FAILED_TOKEN_REFRESH,
} from "../actionTypes";
import { Timezones, NotificationThresholds, UnitsOfMeasurement } from "../../enums";
import { baseUrl } from "../../services/api/base";
import moment from "moment";

const setCachedTimezone = (timezone) => {
  localStorage.setItem("timezone", timezone)
}

const getCachedTimezone = () => localStorage.getItem("timezone") || Timezones.AMERICA_LOS_ANGELES

const baseWhiteLabelUrl = baseUrl

const initialState = {
  isLoggedIn: false,
  loginChecked: false,
  failedTokenRefresh: {
    failed: false,
    expired: false,
    error: "",
  },
  isAttributesLoaded: false,
  user: {
    email: null,
    userName: null,
  },
  expiresIn: 0,
  credentials: {},
  isSavingUser: true,
  error: false,
  preferences: {
    timezone: getCachedTimezone(),
    notifications: {
      receiveEmail: false,
      receiveText: false,
      threshold: NotificationThresholds.WARNING,
      critical_alerts_topic_subscription_confirmed: null,
      non_critical_alerts_topic_subscription_confirmed: null
    },
    unitsOfMeasurement: UnitsOfMeasurement.imperial,
    permissions: []
  },
  isSuperAdmin: false,
  isAdmin: false,
  defWhiteLabel: "rivata",
  whiteLabelEpoch: moment().unix(),
  whiteLabel: "rivata",
  whiteLabelUrl: `${baseWhiteLabelUrl}/config/rivata/`,
}

const setUserAttributes = (state, payload) => {
  const parent_customer = payload.customers.find(c=> !c.parent_id);
  if (parent_customer){
    document.title = parent_customer.name;
  }
  else {
    document.title = payload.customers[0].name;
  }
  
  setCachedTimezone(payload.timezone)
  
  return {
    ...state,
    isAttributesLoaded: true,
    isAdmin: payload.is_admin,
    isSuperAdmin: payload.is_super_admin,
    isDriver: payload.roles.includes('DRIVER'),
    defWhiteLabel: payload.parent_customer_key,
    whiteLabel: payload.parent_customer_key,
    whiteLabelUrl: `${baseWhiteLabelUrl}/config/${payload.parent_customer_key.toLowerCase()}/`,
    preferences: {
      id: payload.id,
      email: payload.email,
      phone: payload.phone_number,
      timezone: payload.timezone,
      unitsOfMeasurement: payload.units_of_measurement,
      customer_ids: payload.customer_ids,
      customers: payload.customers,
      roles: payload.roles,
      username: payload.username,
      phoneNumberVerified: payload.phone_number_verified,
      isAssignedToSmsAlerts: payload.is_assigned_to_sms_alert_profile,
      availableCustomersSubscriptions: payload.available_customers_subscriptions,
      firstName: payload.first_name,
      lastName: payload.last_name,
      permissions: payload.permissions
    },
    isSuperStandardUser:payload.is_super_standard_user
  }
};

const setSession = (session, state) => {
  const { user: { userName, email } } = session;
  return {
    ...state,
    ...session,
    isLoggedIn: true,
    loginChecked: true,
    user: { userName, email }
  };
};

const setTokenLifeEndTime = (state, payload) => ({ ...state, expiresIn: payload })
const setRefreshedToken = (state, payload) => ({ ...state, credentials: { ...state.credentials, idToken: payload } })

const setAuthWhitelabel = (state, payload) => ({ 
  ...state, 
  whiteLabel: payload.key,
  whiteLabelEpoch: payload.reload ? moment().unix() : state.whiteLabelEpoch,
  whiteLabelUrl: `${baseWhiteLabelUrl}/config/${payload.key.toLowerCase()}/`,
})

const setFailedTokenRefresh = (state, payload) => {
  const expired = state.failedTokenRefresh.expired || payload.includes("Refresh Token has expired")

  return {
    ...state,
    failedTokenRefresh: {
      failed: true,
      expired,
      error: payload
    }
  }
}

const session = (state = initialState, action) => {
  switch (action.type) {
    case SET_SESSION:
      return setSession(action.payload, state);
    case SET_PREFERENCES:
      return setUserAttributes(state, action.payload);
    case SET_AUTH_WHITELABEL:
      return setAuthWhitelabel(state, action.payload);
    case SET_TOKEN_LIFE_TIME:
      return setTokenLifeEndTime(state, action.payload);
    case SET_REFRESHED_TOKEN:
      return setRefreshedToken(state, action.payload);
    case CHECK_SESSION:
      return { ...state, loginChecked: true };
    case FAILED_TOKEN_REFRESH:
      return setFailedTokenRefresh(state, action.payload);
    case CLEAR_SESSION:
      return initialState;
    case SAVING_SESSION:
      return {
        ...state,
        isSavingUser: action.payload,
      };
    case SET_SESSION_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default session;
