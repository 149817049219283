import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Label, Col, Button } from "reactstrap"
import { useFormikContext } from "formik"

import RivataGrid from "../../../../components/RivataGrid"
import BackendSearchInput from "../../../../components/BackendSearchInput"
import { EndPointConfigs } from "../../../../components/BackendSearchInput/constants"
import ManageProfileUsersModal from "../ManageProfileUsersModal"

import { useNotificationUsersColumns, useNotificationUsersRows } from "../../hooks"

import "./style.scss"

interface Props {
    users: Array<IUserShortFormat>
    profileUsers: Array<IUserShortFormat> | null
    setProfileUsers: React.Dispatch<React.SetStateAction<IUserShortFormat[] | null>>
    setIsFormTouched: React.Dispatch<React.SetStateAction<boolean>>
}

const NotificationUsers: React.FC<Props> = ({
    users,
    profileUsers,
    setProfileUsers,
    setIsFormTouched
}) => {
    const context = useFormikContext()
    const [usersToManage, setUsersToManage] = useState<Array<number> | null>(null)


    useEffect(() => {
        if (Object.keys(context.touched).length > 0) setIsFormTouched(true)
    }, [context.touched, setIsFormTouched])

    const handleRemoveUser = useCallback((id: number) => {
        setIsFormTouched(true)
        setProfileUsers(prev => {
            if (!prev) return prev;

            const newArr = [ ...prev ]
            const idx = newArr.findIndex(el => el.id === id)

            if (idx > -1) newArr.splice(idx, 1)

            return newArr
        })
    }, [setProfileUsers, setIsFormTouched])


    const columns = useNotificationUsersColumns(context.values, handleRemoveUser)
    const rows = useNotificationUsersRows(profileUsers)


    const handleMenuSelect = useCallback((el) => {
        setIsFormTouched(true)
        setProfileUsers(prev => {
            if (!prev) return [ el ]

            if (prev.find(oldItem => oldItem.id === el.id)) return prev
            
            return [...prev, el]
        })
    }, [setProfileUsers, setIsFormTouched])


    const gridHeight = useMemo(() => rows.length > 6 ? 250 : rows.length * 35 + 47, [rows.length])

    return (
        <div className="notification-users">
            <Col md="12" className="users-search mb-3">
                <div className="d-flex justify-content-between mb-3">
                    <div className="w-50">
                        <Label>Add Profile Users:</Label>
                        
                        <BackendSearchInput
                            endPointConfig={EndPointConfigs.user}
                            placeholder="Search User"
                            onSelect={handleMenuSelect}
                            width="355px"
                        />
                    </div>

                    <div className="d-flex align-items-end">
                        <Button
                            className="manage-notifications__btn mr-2"
                            type="button"
                            onClick={() => setUsersToManage(profileUsers ? profileUsers.map(el => el.id) : [])}
                        >
                            Manage Users
                        </Button>
                        <Button 
                            className="manage-notifications__btn"
                            type="button"
                            color="danger"
                            onClick={() => {
                                if (profileUsers?.length) {
                                    setIsFormTouched(true)
                                    setProfileUsers([])
                                }
                            }}
                        >
                            Remove All Users
                        </Button>
                    </div>
                </div>

                {usersToManage && (
                    <ManageProfileUsersModal
                        isOpen={true}
                        users={users}
                        selectedUserIds={usersToManage}
                        onCancel={() => setUsersToManage(null)}
                        onConfirm={(selectedUsers) => {
                            setIsFormTouched(true)
                            setProfileUsers(selectedUsers)
                            setUsersToManage(null)
                        }}
                    />
                )}

                {profileUsers && profileUsers.length > 0 && (
                    <RivataGrid
                        columns={columns}
                        rows={rows}
                        height={`${gridHeight}px`}
                    />
                )}
            </Col>
        </div>
    )
}

export default NotificationUsers
