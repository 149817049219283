import React, { useState } from 'react'
import { Tooltip } from "reactstrap";

interface Props {
    tooltipText?: string | null,
    tooltipTarget?: string,
    disabled?: boolean,
    onAction?: (...args: Array<any>) => void,
    className: string    
}

const ActionWithTooltip : React.FC<Props> = ({tooltipText, tooltipTarget, disabled, onAction, className}) => {
    const [tooltipOpened, setTooltipOpened] = useState(false)
    return <>
      {tooltipText && <Tooltip
        hideArrow={true}
        placement="auto"
        isOpen={tooltipOpened}
        target={tooltipTarget}
        toggle={() => setTooltipOpened(!tooltipOpened)}
    >
        {tooltipText}
    </Tooltip>}

    <i id={tooltipTarget} className={className} style={{ cursor: disabled ? "auto" : "pointer", color: disabled ? "gray" : undefined }} onClick={disabled ? undefined : onAction} />
    </>
}

export default ActionWithTooltip;