import { convertDataEpochToDate } from "../../utils";

export const formatTimestamp = (timestamp) => {
  if (typeof timestamp === "number") {
    return convertDataEpochToDate(timestamp, null, null, true).slice(-8);
  }
  return timestamp;
};

export const formatInfoString = (info) => {
  if (info) {
    return info.split("_").join(" ");
  }
  return;
};
