import React from "react"
import ItemsSelectWithSearch from "../../../components/ItemsSelectWithSearch/ItemsSelectWithSearch"
import { EndPointConfigs } from "../../../components/BackendSearchInput/constants";

type Props = {
  selectedCargoTags: Array<{ id: number; mac: number; name: string }>
  setSelectedCargoTags: React.Dispatch<React.SetStateAction<Array<Object>>>
  disabled: boolean
}

const columnsToShow = [
  { key: "mac", name: "MAC" },
  { key: "name", name: "Name" },
]

const CargoTagsSelect: React.FC<Props> = ({
  selectedCargoTags,
  setSelectedCargoTags,
  disabled,
}) => {
  return (
    <div className="d-flex flex-row mt-3">
      <ItemsSelectWithSearch 
          label="Cargo Tags"
          inputPlaceholder = "Cargo Tags"
          endPointConfig={EndPointConfigs.cargotag}
          onItemsSelect={setSelectedCargoTags}
          columnsToShow={columnsToShow}
          disabled={disabled}
          selectedItems={selectedCargoTags}
      />
    </div>
  )
}

export default CargoTagsSelect
