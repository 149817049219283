import React, { useCallback, useMemo } from 'react'
import { Row } from "reactstrap"
import * as Yup from "yup"

import CreateModal from '../../components/CreateModal/children'
import { FormField } from '../../components/CreateModal'


const validationSchema = Yup.object().shape({
    cargo_tag_name: Yup.string()
})

interface Props {
    editItem: ICargoTag
    open: boolean
    onEdit: (id: string, data: Pick<ICargoTag, "cargo_tag_name">) => any
    onClose: () => void
}

const EditCargoTagModal: React.FC<Props> = ({ editItem, open, onEdit, onClose }) => {
    const initialValues = useMemo(() => {
        return {
            cargo_tag_name: editItem.cargo_tag_name || ""
        }
    }, [editItem])

    const onSubmit = useCallback(async (data: Pick<ICargoTag, "cargo_tag_name">) => {
        const status: IStatus = await onEdit(editItem.hi_id.toString(), data)

        if (status.ok) {
            onClose()
        }
        
        return status
    }, [editItem, onEdit, onClose])

    return (
        <CreateModal
            disabled={false}
            onClose={onClose}
            onCreate={onSubmit}
            open={open}
            initialValues={initialValues}
            schema={validationSchema}
            header={`Cargo Tag ${editItem.mac}`}
            createBtnLabel="Update"
        >
            <Row>
                <FormField name="cargo_tag_name" label="Cargo Name" />
            </Row>
        </CreateModal>
    )
}

export default React.memo(EditCargoTagModal)