import { getColor, getClearLabel, psiToBar, convertDataEpochToDate, DATE_FORMAT } from '../../utils'
import { getFilteredPositions, getUniqueDatasets, sortDataByPositions } from '../../utils/chartUtils'
import { UnitsOfMeasurement } from '../../enums'

export const composeTireChartData = (data, wheelColors, unitsOfMeasurement) => {
  if (!data.length) return null

  const pressureUnits = unitsOfMeasurement === UnitsOfMeasurement.imperial ? "PSI" : "Bar"

  const filteredKeys = getFilteredPositions(data.map(el => el[1]), "tpms")

  const datasets = data.reduce((accum, curr) => {
    if (!filteredKeys.includes(curr[1])) return accum

    const label = getClearLabel(curr[1])
    const color = getColor(label, wheelColors)

    accum.push({
      label,
      position: curr[1],
      backgroundColor: color,
      borderColor: color,
      borderWidth: 2,
      fill: false,
      data: curr[2].reduce((accum, curr) => {
        const y = unitsOfMeasurement === UnitsOfMeasurement.imperial ? curr[1] : psiToBar(curr[1]).toFixed(2)
        const x = convertDataEpochToDate(curr[0], null, null, false)

        accum.push({
          y,
          x,
          aaa: curr,
          displayValue: `Pressure: ${y} ${pressureUnits}`,
          displayFooter: x.format(DATE_FORMAT)
        })
  
        return accum
      }, [])
    })
    
    return accum
  }, [])

  const uniqueDatasets = getUniqueDatasets(datasets)

  sortDataByPositions(uniqueDatasets)

  return { datasets: uniqueDatasets }
};

export const drawPressureLinesLegend = (chartLinesLegend) => {
  const canvas = chartLinesLegend.current

  if (canvas) {
    if (canvas.getContext) {
      const ctx = canvas.getContext('2d')
      const critText = "Critical Threshold"

      ctx.canvas.width = 400
      ctx.canvas.height = 30

      // draw rectangle line
      for (let i = 5; i < 50; i += 4) {
        ctx.beginPath()
        ctx.strokeStyle = "#FED925"
        ctx.rect(i, 15, 0.5, 3)
        ctx.stroke()
      }

      // draw crit threshold label
      ctx.font = "16px myriad-pro-condensed"
      ctx.fillStyle = '#858585'
      const critTextWidth = ctx.measureText(critText).width

      ctx.fillText(critText, 55, 20)

      // draw dots line
      ctx.beginPath();
      ctx.strokeStyle = "#FED925"
      ctx.setLineDash([2, 2]);
      ctx.moveTo(55 + critTextWidth + 10, 15);
      ctx.lineTo(55 + critTextWidth + 60, 15);
      ctx.stroke();

      // draw threshold label
      ctx.fillText("Threshold", 55 + critTextWidth + 70, 20)
    }
  }
}

