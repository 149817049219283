import React from "react";
import { Col, Label, FormGroup, FormFeedback, Input } from "reactstrap";
import { Field, useField } from "formik";
import PhoneInput from 'react-phone-number-input/input'

export const FormField = ({
  className = "",
  md = "6",
  type = "text",
  children = null,
  label,
  customOnChange = () => { },
  ...props
}) => {
  const [field, meta] = useField(props);

  return (
    <Col md={md} className={className}>
      <Label>{label}</Label>
      <FormGroup className="w-100">
        <Input
          type={type}
          {...field}
          {...props}
          tag={Field}
          invalid={!!(meta.touched && meta.error)}
          onChange={(e) => { field.onChange(e); customOnChange(e);  }} //added ability to add custom actions to onChange events.
        >
          {children}
        </Input>
        <FormFeedback>{meta.error}</FormFeedback>
      </FormGroup>
    </Col>
  );
};

export const PhoneNumberField = ({
  className = "",
  md = "6",
  label,
  ...props
}) => {
  const [field, meta, helper] = useField(props);
  return (
    <Col md={md} className={className}>
      <Label>{label}</Label>
      <FormGroup className="w-100">
        <PhoneInput
          {...field}
          {...props}
          type="text"
          className={`form-control ${meta.touched && meta.error ? "is-invalid" : ""} ${props.className ?? ""}`}
          name="phone_number"
          defaultCountry="US"
          onChange={(v) => helper.setValue(v ?? "")}
        />
        <FormFeedback>{meta.error}</FormFeedback>
      </FormGroup>
    </Col>
  );
};
