import { ManageGeofencesReducer, ManageGeofencesTypes, ManageGeofencesAction } from "./types"

const status = {
  ok: false,
  statusCode: 0,
  message: "",
};

const initialState: ManageGeofencesReducer = {
  isLoading: false,
  data: [],
  totalCount: 0,
  limit: 10,
  offset: 0,
  showDeleted: false,
  dataStatus: status,
  actionStatus: status,
  assets: {
    isLoading: false,
    data: [],
    filter: "",
    status: status,
  },
  allShortData: {
    isLoading: false,
    data: [],
    status,
  },
}

const manageGeofences = (state = initialState, action: ManageGeofencesAction) => {
  switch (action.type) {
    case ManageGeofencesTypes.LOADING_MANAGE_GEOFENCES:
      return { ...state, isLoading: action.payload }

    case ManageGeofencesTypes.SET_MANAGE_GEOFENCES_DATA:
      return { ...state, data: action.payload }
    case ManageGeofencesTypes.SET_MANAGE_GEOFENCES_COUNT:
      return { ...state, totalCount: action.payload }
    case ManageGeofencesTypes.SET_MANAGE_GEOFENCES_LIMIT:
      return { ...state, limit: action.payload }
    case ManageGeofencesTypes.SET_MANAGE_GEOFENCES_OFFSET:
      return { ...state, offset: action.payload }
    case ManageGeofencesTypes.SET_MANAGE_GEOFENCES_SHOW_DELETED:
      return { ...state, showDeleted: action.payload }

    case ManageGeofencesTypes.SET_MANAGE_GEOFENCES_DATA_STATUS:
      return { ...state, dataStatus: action.payload }
    case ManageGeofencesTypes.SET_MANAGE_GEOFENCES_ACTION_STATUS:
      return { ...state, actionStatus: action.payload }

    case ManageGeofencesTypes.LOADING_MANAGE_GEOFENCES_ASSETS:
      return { ...state, assets: { ...state.assets, isLoading: action.payload}}
    case ManageGeofencesTypes.SET_MANAGE_GEOFENCES_ASSETS:
      return { ...state, assets: { ...state.assets, data: action.payload}}
    case ManageGeofencesTypes.SET_MANAGE_GEOFENCES_ASSETS_FILTER:
      return { ...state, assets: { ...state.assets, filter: action.payload}}
    case ManageGeofencesTypes.SET_MANAGE_GEOFENCES_ASSETS_STATUS:
      return { ...state, assets: { ...state.assets, status: action.payload}}

    case ManageGeofencesTypes.LOADING_MANAGE_GEOFENCES_ALL_SHORT_DATA:
      return { ...state, allShortData: { ...state.allShortData, isLoading: action.payload}}
    case ManageGeofencesTypes.SET_MANAGE_GEOFENCES_ALL_SHORT_DATA:
      return { ...state, allShortData: { ...state.allShortData, data: action.payload}}
    case ManageGeofencesTypes.SET_MANAGE_GEOFENCES_ALL_SHORT_DATA_STATUS:
      return { ...state, allShortData: { ...state.allShortData, status: action.payload}}

    case ManageGeofencesTypes.RESET_MANAGE_GEOFENCES_REDUCER:
      return initialState
    default:
      return state
  }
}

export default manageGeofences