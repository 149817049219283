import React, { Component } from 'react';
import ErrorIndicator from '../ErrorIndicator';

export default class ErrorBoundry extends Component {
    state = {
        hasError: false
    }

    componentDidCatch() {
        this.setState({
            hasError: true
        })
    }

    render() {
        if (this.state.hasError || this.props.error.statusCode >= 400) {
            return <ErrorIndicator error={this.props.error}/>
        }

        return this.props.children
    }
}