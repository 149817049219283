import React, { useCallback, useEffect, useState } from "react"
import { Formik, Form } from "formik"
import { Row, Col, Button } from "reactstrap"
import * as Yup from 'yup'

import NotificationUsers from "../NotificationUsers"
import InputsGroup from "./InputsGroup"

import { NotificationFormValues } from "../../hooks"

import "./style.scss"
import NotificationAssets from "../NotificationAssets"
import { SearchItemType } from "../../../../components/ItemsSearch"
import AssetGroupsFilter from "../../../../componentsV2/AssetGroupsFilter"
import { MenuItemType } from "../../../../componentsV2/Menu/MenuItem"
import NotificationGeofences from "../NotificationGeofences"


const schema = Yup.object().shape({
    name: Yup.string().required("Profile name is required"),
    sms: Yup.boolean(),
    email: Yup.boolean(),
    critical_alert_interval_in_seconds: Yup.number(),
    info_alert_interval_in_seconds: Yup.number(),
    tire_pressure: Yup.string(),
    line_pressure: Yup.string(),
})


interface Props {
    locale: ILocale
    initialValues: NotificationFormValues
    users: Array<IUserShortFormat>
    isUpdate: boolean
    profileUsers: Array<IUserShortFormat> | null
    profileAssets: Array<SearchItemType>
    profileGeofences: Array<SearchItemType>
    setProfileUsers: React.Dispatch<React.SetStateAction<IUserShortFormat[] | null>>
    setProfileAssets: React.Dispatch<React.SetStateAction<SearchItemType[]>>
    setProfileGeofences: React.Dispatch<React.SetStateAction<SearchItemType[]>>
    handleFormSubmit: (
        formValues: NotificationFormValues,
        resetForm: () => void
    ) => void
    onCancel: () => void
    selectedCustomersList: Array<ICustomer>
    profileAssetsGroups: Array<string>
    setProfileAssetsGroups: React.Dispatch<React.SetStateAction<number[]>>
    subscriptions: any
    isSuperAdmin: boolean
}

const ProfileForm: React.FC<Props> = ({
    initialValues,
    users,
    isUpdate,
    profileUsers,
    profileAssets,
    profileGeofences,
    setProfileUsers,
    setProfileAssets,
    setProfileGeofences,
    handleFormSubmit,
    onCancel,
    selectedCustomersList,
    profileAssetsGroups,
    setProfileAssetsGroups,
    subscriptions,
    isSuperAdmin
}) => {
    const [isFormTouched, setIsFormTouched] = useState(false)

    useEffect(() => {
        setIsFormTouched(false)
    }, [initialValues])


    const onDropdownItemSelect = useCallback((id: string, items: Array<MenuItemType>) => {
        const ids = items.map(el => Number(el.id))
        setProfileAssetsGroups(ids)

        setIsFormTouched(true)

    }, [setProfileAssetsGroups, setIsFormTouched]);

    return (
        <Formik
            validationSchema={schema}
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={(values, { resetForm }) => handleFormSubmit(values, resetForm)}
        >
            <Form className="profile-form" >
                <Row className="profile-form__wrapper">
                    <Col md="6" className="mt-3">
                        <InputsGroup disabled={initialValues.is_system_profile} subscriptions={subscriptions} isSuperAdmin={isSuperAdmin} />
                    </Col>

                    <Col md="6" className="mt-3">
                        <NotificationUsers
                            users={users}
                            profileUsers={profileUsers}
                            setProfileUsers={setProfileUsers}
                            setIsFormTouched={setIsFormTouched}
                        />
                        {!initialValues.is_system_profile && <>
                            <NotificationAssets
                                profileAssets={profileAssets}
                                setIsFormTouched={setIsFormTouched}
                                setProfileAssets={setProfileAssets}
                            />
                            <div className="profile-form__asset_group">
                                <AssetGroupsFilter
                                    selectedValueIds={profileAssetsGroups}
                                    onDropdownItemSelect={onDropdownItemSelect}
                                    customerIds={selectedCustomersList.map((c) => c.id).join(",")}
                                    ref={null}
                                />
                            </div>
                            <NotificationGeofences
                                profileGeofences={profileGeofences}
                                setIsFormTouched={setIsFormTouched}
                                setProfileAssets={setProfileGeofences}
                            />
                        </>}

                    </Col>

                </Row>

                <Row className="mt-3" style={{ alignSelf: "flex-end" }}>
                    <Col className="d-flex justify-content-end">
                        <Button
                            type="submit"
                            color="primary"
                            disabled={!isFormTouched}
                        >
                            {isUpdate ? "Save" : "Create"}
                        </Button>
                        <Button
                            color={"danger"}
                            className="ml-3"
                            onClick={onCancel}
                        >
                            Cancel
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Formik>
    )
}

export default ProfileForm