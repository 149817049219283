import { AnnotationOptions } from "chartjs-plugin-annotation";
import { composeVertLineAnnotation } from "../../utils/chartUtils";

interface IchartThresholdAnnotations {
    (
        id: string,
        threshold: number,
        criticalThreshold: number,
        verticalLine: number,
        healthColors: Array<IHealthColor>
    ): IDictStrKeys<AnnotationOptions>;
}

export const temperatureChartThresholdAnnotations: IchartThresholdAnnotations = (
    id = "chart",
    threshold,
    criticalThreshold,
    verticalLine,
    healthColors
) => {
    const annotations: IDictStrKeys<AnnotationOptions> = {}
    const xMin = -Infinity
    const xMax = Infinity

    annotations.box1 = {
        type: "box",
        id: `box-plot-top-${id}`,
        xScaleID: "x",
        yScaleID: "y",
        yMax: Infinity,
        yMin: threshold,
        backgroundColor: "#fffcdf",
        borderWidth: 0,
        drawTime: "beforeDatasetsDraw",
    };

    annotations.box2 = {
        type: "box",
        id: `box-plot-bottom-${id}`,
        xScaleID: "x",
        yScaleID: "y",
        yMax: threshold,
        yMin: -Infinity,
        xMin,
        xMax,
        backgroundColor: "#fff",
        borderWidth: 0,
        drawTime: "beforeDatasetsDraw",
    };

    if (threshold) {
        annotations.thresholdLine = {
            id: `threshold-line-${id}`,
            type: "line",
            xScaleID: "x",
            yScaleID: "y",
            yMax: threshold,
            yMin: threshold,
            xMin,
            xMax,
            value: threshold,
            borderColor: "#fed925",
            borderWidth: 2,
            borderDash: [2, 2],
            borderDashOffset: 10,
            drawTime: "beforeDatasetsDraw",
        };
    }
    if (criticalThreshold) {
        annotations.criticalThresholdLine = {
            id: `critical-threshold-line-${id}`,
            type: "line",
            xScaleID: "x",
            yScaleID: "y",
            yMax: criticalThreshold,
            yMin: criticalThreshold,
            xMin,
            xMax,
            value: criticalThreshold,
            borderColor: "#FED925",
            borderWidth: 4,
            borderDash: [2, 2],
            borderDashOffset: 10,
            drawTime: "beforeDatasetsDraw",
        };
    }

    if (verticalLine && healthColors && healthColors[1]) {
        annotations.verticalLine = composeVertLineAnnotation(id, verticalLine, healthColors[1].color)
    }

    return annotations;
};