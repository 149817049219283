import React, { useState } from "react";
import {
  Form,
  FormGroup,
  Label,
  Col,
  Row,
  Button,
  Input,
  FormFeedback,
} from "reactstrap";
import PhoneInput from 'react-phone-number-input/input'
import TimezonePicker from "../../components/TimezonePicker";
import LanguageDropdown from "../../components/LanguageDropdown";
import UnitsOfMeasureToggle from "../UnitsOfMeasureToggle";
import NotificationsSettings from "./NotificationsSettings";
import PhoneNumberVerificationModal from "./PhoneNumberVerificationModal";
import { validatePhone } from "../../utils"

import { useActions } from "../../hooks/useActions";

import "./style.scss";


const PreferencesForm = ({
  onSubmit,
  onTimezoneChange,
  onLanguageChange,
  phone,
  email,
  timezone,
  language,
  hasLanguage = false,
  locale,
  unitsOfMeasure,
  onChangeUnitsOfMeasure,
  showUnitsOfMeasureSelector,
  onPhoneChange,
  isUserProfile,
  phoneNumberVerified,
  isVerifyPhoneActive = true,
  firstName,
  lastName,
  roles,
  onFirstNameChange,
  onLastNameChange,
}) => {
  const [phoneValidationData, setPhoneValidationData] = useState({ isValid: true, errorMessage: ""});
  const [verificationFormVisible, setVerificationFormVisible] = useState(false);

  const toggleVerificationForm = () => {
    setVerificationFormVisible(!verificationFormVisible);
  }

  const {
    requestVerificationCode,
    verifyCode
  } = useActions()

  return (
    <Form 
      className="w-100" 
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();

        setPhoneValidationData(validatePhone(phone))

        if (phoneValidationData.isValid) onSubmit(e)
      }}
    >
      {isUserProfile && (
        <>
          <Row>
            <Col md="6">
              <FormGroup className="w-100">
                <Label>Email</Label>
                <Input
                  disabled
                  type="text"
                  name="email"
                  id="textemail"
                  placeholder=""
                  value={email || ""}
                  onChange={() => { }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup className="w-100">
                <Row>
                  <Col>
                    <Label>Phone</Label>
                  </Col>
                </Row>

                  <Row>
                  <Col md="6">
                    <PhoneInput
                      type="text"
                      name="phone"
                      placeholder="Enter Phone Here"
                      defaultCountry="US"
                      value={phone || ""}
                      onChange={(value) => {
                        onPhoneChange(value ?? "")
                        setPhoneValidationData({ isValid: true, errorMessage: ""})
                      }}
                      onBlur={() => setPhoneValidationData(validatePhone(phone))}
                      className={`form-control ${!phoneValidationData.isValid ? "is-invalid" : ""}`}
                    />
                    <FormFeedback tooltip>
                      {phoneValidationData.errorMessage}
                    </FormFeedback>
                  </Col>

                  <Col md="2">
                    {!phoneNumberVerified &&
                      <>
                        <Button
                          color="warning"
                          onClick={toggleVerificationForm}
                          disabled={!isVerifyPhoneActive}
                        >
                          Verify Phone Number
                        </Button>
                        {verificationFormVisible && (
                          <PhoneNumberVerificationModal 
                            open={verificationFormVisible} 
                            onCancel={toggleVerificationForm} 
                            onVerificationRequestCodeClick={requestVerificationCode}
                            onVerifyCodeClick={verifyCode}
                            phoneNumber={phone}
                          />
                        )}
                      </>
                    }
                  </Col>
                </Row>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup className="w-100">
                <Label>First Name</Label>
                <Input
                  type="text"
                  name="firstname"
                  id="firstname"
                  placeholder=""
                  value={firstName || ""}
                  onChange={(e) => onFirstNameChange(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup className="w-100">
                <Label>Last Name</Label>
                <Input
                  type="text"
                  name="lastname"
                  id="lastname"
                  placeholder=""
                  value={lastName || ""}
                  onChange={(e) => onLastNameChange(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup className="w-100">
                <Label>Role</Label>
                <Input
                  disabled
                  type="text"
                  name="role"
                  id="role"
                  placeholder=""
                  value={roles?.join(",").replace("_", "")}
                />
              </FormGroup>
            </Col>
          </Row>
        </>
      )}
      <h3 className="card-title">{locale.locale || "Locale"}</h3>
      <hr />
      <Row>
        <Col md="6">
          <FormGroup className="w-100">
            <Label>{locale.timezone || "Timezone"}</Label>
            <TimezonePicker
              onSetTimezone={onTimezoneChange}
              initialValue={timezone}
              locale={locale}
              id="user_profile"
            />
          </FormGroup>
          {hasLanguage && (
            <FormGroup className="w-100">
              <Label className="d-block">{locale.language || "Language"}</Label>
              <LanguageDropdown
                onSelect={onLanguageChange}
                selected={language}
              />
            </FormGroup>
          )}
        </Col>
      </Row>
      {showUnitsOfMeasureSelector ? (
        <>
          <h3>Units of Measure</h3>
          <hr />
          <Row className="mb-4">
            <Col md="6">
              <Label>Select Units of Measure</Label>
              <UnitsOfMeasureToggle
                onChangeUnitsOfMeasure={onChangeUnitsOfMeasure}
                unitsOfMeasure={unitsOfMeasure}
              />
            </Col>
          </Row>
        </>
      ) : null}

      <Row>
        <Col>
          <NotificationsSettings/>
        </Col>
      </Row>

      {onSubmit && (
        <>
          <hr />
          <div className="form-actions">
            <Row>
              <Col md="6">
                <div className="d-flex align-items-center">
                  <Button
                    type="submit"
                    color="primary"
                    disabled={false}
                  >
                    {locale.submit || "Submit"}
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </>
      )}
    </Form>
  );
};

export default PreferencesForm;
