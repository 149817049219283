import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useMemo,
} from 'react'
import moment from 'moment'
import { CustomInput, Row } from 'reactstrap'
import RivataModule from '../../components/RivataModule'
import HEREMap from '../../components/RivataMap'
import { LayerTypes } from '../../components/RivataMap/utils'
import RivataLoader from '../../components/RivataLoader'
import LayersDropdown from '../../components/LayersDropdown'
import Legend from './Legend'
import { dateToEpoch, convertDataEpochToDate } from '../../utils'
import { useParsedData } from './hooks'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { useActions } from '../../hooks/useActions'
import { AssetDetailsContext } from '../../pages/AssetDetails'
import ScrollBlockWrapper from '../../components/ScrollBlockWrapper'
import DateTimePicker from '../../componentsV2/DateTimePicker'
import { CalendarTypes } from '../../enums'

const maxAdditionalHours = 48

const DetailsMap = ({ width }) => {
  const {
    gps: { isLoading, data, status },
    geofences,
    preferences,
    warnings,
  } = useTypedSelector((state) => ({
    gps: state.assetDetails.gps,
    geofences: state.geofences.geofences.data,
    preferences: state.auth.preferences,
    warnings: state.assetDetails.recentWarnings.data,
  }))
  const { fetchAssetDetailsGps } = useActions()

  const { locale, assetInfoData, healthColors, warningKey } =
    useContext(AssetDetailsContext)

  const [formValues, setFormValues] = useState({
    inputValue:
      moment(new Date()).format('MM/DD/YYYY') +
      ' - ' +
      moment(new Date()).format('MM/DD/YYYY'),
    startTime: '00:00',
    endTime: '23:59',
    dayStart: new Date(),
    dayEnd: new Date(),
  })
  const [dateRangeError, setDateRangeError] = useState(null)
  const [selectedLayerOption, setSelectedLayerOption] = useState(
    LayerTypes.NORMAL,
  )
  const [forceMapRerender, setForceMapRerender] = useState(false)
  const [geofencesVisible, setGeofencesVisible] = useState(false)
  const [mapZoomBounds, setMapZoomBounds] = useState({
    zoom: null,
    bounds: null,
  })

  const selectedWarning = useMemo(() => {
    return warnings.find((el) => el.key === warningKey)
  }, [warningKey, warnings])

  // custom hook
  const nonAcknowledgedCriticalWarningsOnly = true

  const parsedLocations = useParsedData(
    data,
    assetInfoData,
    selectedWarning,
    setForceMapRerender,
    nonAcknowledgedCriticalWarningsOnly,
  )

  useEffect(() => {
    if (selectedWarning?.epoch) {
      const composedFrom = moment
        .unix(selectedWarning.epoch)
        .subtract(4, 'hours')
        .unix()

      const composedTo = moment
        .unix(selectedWarning.epoch)
        .add(4, 'hours')
        .unix()

      const filterDateStart = convertDataEpochToDate(
        composedFrom,
        null,
        null,
        true,
      ).split(' ')
      const filterDateEnd = convertDataEpochToDate(
        composedTo,
        null,
        null,
        true,
      ).split(' ')

      const beforeWarningtime = moment(
        [filterDateStart[1], filterDateStart[2]].join(' '),
        ['HH:mm A'],
      ).format('HH:mm')
      const afterWarningtime = moment(
        [filterDateEnd[1], filterDateEnd[2]].join(' '),
        ['HH:mm A'],
      ).format('HH:mm')

      const beforeWarningDate = filterDateStart[0]
      const afterWarningDate = filterDateEnd[0]

      setFormValues({
        inputValue: `${beforeWarningDate} ${
          afterWarningDate ? '- ' + afterWarningDate : ''
        }`,
        startTime: beforeWarningtime,
        endTime: afterWarningtime,
        dayStart: new Date(beforeWarningDate),
        dayEnd: new Date(afterWarningDate),
      })
    }
  }, [selectedWarning])

  useEffect(() => {
    if (assetInfoData?.lastLocationEpoch && !selectedWarning) {
      const start = convertDataEpochToDate(
        assetInfoData.lastLocationEpoch - 24 * 3600,
        null,
        null,
        false,
      )
      const end = convertDataEpochToDate(
        assetInfoData.lastLocationEpoch,
        null,
        null,
        false,
      )

      setFormValues({
        inputValue:
          start.format('MM/DD/YYYY') + ' - ' + end.format('MM/DD/YYYY'),
        startTime: start.format('HH:mm'),
        endTime: end.format('HH:mm'),
        dayStart: new Date(start),
        dayEnd: new Date(end),
      })

      setForceMapRerender(true)
    }
  }, [assetInfoData, selectedWarning])

  // const toggleCalendar = useCallback((value) => {
  //   setShowCalendar((prev) => (value !== undefined ? value : !prev));
  // }, []);

  const dateRangeHandler = useCallback((startDate, endDate) => {
    setFormValues((prev) => ({
      ...prev,
      inputValue: `${moment(startDate).format('MM/DD/YYYY')} ${
        endDate ? '- ' + moment(endDate).format('MM/DD/YYYY') : ''
      }`,
      dayStart: startDate,
      dayEnd: endDate,
      startTime: moment(startDate).format('HH:mm'),
      endTime: moment(endDate).format('HH:mm'),
    }))

    const selectedDays = moment
      .duration(moment(endDate).diff(moment(startDate)))
      .as('days')
    if (selectedDays > (maxAdditionalHours + 24) / 24) {
      return setDateRangeError("You can't select more than 3 days")
    }
    setDateRangeError(null)
  }, [])

  const handleSubmit = useCallback(() => {
    const { startDate, endDate } = dateToEpoch(
      formValues.dayStart,
      formValues.dayEnd,
      formValues.startTime,
      formValues.endTime,
    )
    fetchAssetDetailsGps(startDate, endDate)
  }, [
    formValues.dayStart,
    formValues.dayEnd,
    formValues.startTime,
    formValues.endTime,
    fetchAssetDetailsGps,
  ])

  const onMapViewChange = useCallback((bounds, zoom) => {
    setMapZoomBounds({ bounds, zoom })
  }, [])

  return (
    <RivataModule
      fullScreenModalModeEnabled
      title='Vehicle Location'
      width={width}
      locale={locale}
      paddingMobile={false}
      error={status}
      filters={
        <>
          {geofences?.length ? (
            <div className='d-flex align-items-center mr-3'>
              <CustomInput
                type='switch'
                id='geofencesSwitch'
                label={'Show Geofences'}
                checked={geofencesVisible}
                onChange={(e) => setGeofencesVisible(e.target.checked)}
              />
            </div>
          ) : null}

          <LayersDropdown
            left={0}
            top={0}
            zIndex={1}
            selected={selectedLayerOption}
            onSelect={(type) => setSelectedLayerOption(type)}
            locale={locale}
          />
        </>
      }
      collapsible
    >
      <section>
        <Row className='ml-0 mb-2 align-items-center'>
          <DateTimePicker
            defaultMode={CalendarTypes.DateRange}
            hourLimit={maxAdditionalHours}
            dateFrom={moment(formValues.dayStart)
              .hours(moment(formValues.startTime, 'HH:mm').hours())
              .minutes(moment(formValues.startTime, 'HH:mm').minutes())}
            dateTo={moment(formValues.dayEnd)
              .hours(moment(formValues.endTime, 'HH:mm').hours())
              .minutes(moment(formValues.endTime, 'HH:mm').minutes())}
            onDateRangeSelect={dateRangeHandler}
            timeEnabled={true}
            maxDate={moment().toDate()}
          />
          <button
            className='btn btn-primary ml-3'
            type='submit'
            disabled={!!dateRangeError}
            onClick={handleSubmit}
          >
            Submit
          </button>
        </Row>
      </section>
      <ScrollBlockWrapper>
        {window.H && (
          <Legend
            locale={locale}
            isWarningDetailsPage={true}
            isAssetDetailsPage={!selectedWarning}
          />
        )}
        {window.H && (
          <HEREMap
            locations={parsedLocations.data}
            layerType={selectedLayerOption}
            closestTimestamp={parsedLocations.warningTimestamp}
            selectable={false}
            bboxLast24hrs={false}
            locale={locale}
            preferences={preferences}
            unitsOfMeasurement={preferences.unitsOfMeasurement}
            healthColors={healthColors}
            showLastLocationAsPin={true}
            forceMapRerender={forceMapRerender}
            setForceMapRerender={setForceMapRerender}
            geofences={geofences}
            vin={assetInfoData?.vin}
            geofencesVisible={geofencesVisible}
            isWarningDetailsPage={true}
            onMapViewChange={onMapViewChange}
            mapZoomBounds={mapZoomBounds}
            size={width}
          />
        )}
      </ScrollBlockWrapper>
      {isLoading && (
        <div
          className='position-absolute w-100 h-100'
          style={{
            backgroundColor: 'rgba(0,0,0,.1)',
            left: 0,
            top: 0,
            display: 'flex',
            zIndex: 1,
            justifyContent: 'center',
          }}
        >
          <RivataLoader />
        </div>
      )}
    </RivataModule>
  )
}

export default DetailsMap
