import React, { useCallback } from 'react'
import DateTimePicker from '../../../componentsV2/DateTimePicker'
import { CalendarTypes } from '../../../enums'
import moment from 'moment'
import AssetsSelect from './AssetsSelect'
import { SearchItemType } from '../../../components/ItemsSearch'
import ItemsSelectWithSearch from '../../../components/ItemsSelectWithSearch/ItemsSelectWithSearch'
import { EndPointConfigs } from '../../../components/BackendSearchInput/constants'

const usersColumnsToShow = [
  { key: 'username', name: 'Username' },
  { key: 'first_name', name: 'First Name' },
  { key: 'last_name', name: 'Last Name' },
  { key: 'email', name: 'Email' },
]

interface Props {
  disabled: boolean
  onDateRangeSelect: (from: Date, to: Date) => void
  dateFrom: string
  dateTo: string
  setVinList: React.Dispatch<React.SetStateAction<Array<Object>>>
  selectedAssetsItems: Array<SearchItemType>
  setUsernames: (list: Object[]) => void
  selectedUsersItems: Array<SearchItemType>
}

const DriverAppTripReportForm: React.FC<Props> = ({
  disabled,
  onDateRangeSelect,
  dateFrom,
  dateTo,
  setVinList,
  selectedAssetsItems,
  setUsernames,
  selectedUsersItems,
}) => {
  const onUsersSelect = useCallback(
    (selectedUsers: SearchItemType[]) => {
      setUsernames(selectedUsers)
    },
    [setUsernames],
  )

  return (
    <>
      <div className='mb-2'>Select Date Range</div>
      <DateTimePicker
        defaultMode={CalendarTypes.DateRange}
        dateFrom={dateFrom}
        dateTo={dateTo}
        onDateRangeSelect={onDateRangeSelect}
        minDate={new Date(2000, 0, 1, 0, 0)}
        maxDate={moment().hours(23).minutes(59).seconds(0).toDate()}
        disabled={disabled}
      />

      <AssetsSelect
        disabled={disabled}
        setVinList={setVinList}
        selectedItems={selectedAssetsItems}
      />

      <ItemsSelectWithSearch
        label='Users'
        inputPlaceholder='Search Users'
        endPointConfig={EndPointConfigs.user}
        onItemsSelect={onUsersSelect}
        columnsToShow={usersColumnsToShow}
        disabled={disabled}
        tableWidth='800px'
        selectedItems={selectedUsersItems}
      />
    </>
  )
}

export default DriverAppTripReportForm
