import { LOADING_REPORTS_HISTORY, SET_REPORTS_HISTORY } from '../actionTypes'
import api from '../../services/api'
import { IgetState } from '../types'

export const loadingReportsHistory = (isLoading: boolean) => ({
  type: LOADING_REPORTS_HISTORY,
  payload: isLoading,
})

const setReportsHistory = (reportsHistory: Array<any>) => ({
  type: SET_REPORTS_HISTORY,
  payload: reportsHistory,
})

export function fetchReportsHistory(reportStatus: Array<string>, reportType: Array<string>, dateFrom: number, dateTo: number) {
  return function (dispatch: any, getState: IgetState) {
    dispatch(loadingReportsHistory(true))

    return api
      .getReportsHistory(reportStatus, reportType, dateFrom, dateTo)
      .then((history) => {
        dispatch(setReportsHistory(history))
        dispatch(loadingReportsHistory(false))
      })
      .catch((err) => {
        console.log(err)
        dispatch(loadingReportsHistory(false))
      })
  }
}
