import React from 'react'
import { ReportParameterGroupProps } from './ReportParameterGroupProps'
import AssetsRow from '../ReportParametersRows/AssetsRow'
import DateRangeRow from '../ReportParametersRows/DateRangeRow'
import UsersRow from '../ReportParametersRows/UsersRow'

const DriverAppTripReportParameters: React.FC<ReportParameterGroupProps> = (
  props: ReportParameterGroupProps,
) => {
  return (
    <>
      <DateRangeRow
        value={`${props.parameters.epoch_from}-${props.parameters.epoch_to}`}
      />
      <AssetsRow value={props.parameters.vins} />
      <UsersRow value={props.parameters.usernames} />
    </>
  )
}
export default DriverAppTripReportParameters
