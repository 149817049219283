import React from "react";
import { Jumbotron, Col, Row, Button } from "reactstrap";

const ErrorJumbo = (props) => {
  const { statusCode, statusText } = props;
  return (
      <main className="page-wrapper d-block">
        <div className="page-content container-fluid">
          <Row>
            <Col>
              <Jumbotron>
                <h1 className="display-3">{`${statusCode}`}</h1>
                <p className="lead">{statusText}</p>
                <hr className="my-2" />
                <p>{"We are having problems with this page. Please check your url or try again later."}</p>
                <Button href="/">{"Home"}</Button>
              </Jumbotron>
            </Col>
          </Row>
        </div>
      </main>
  );
};

export default ErrorJumbo;
