import { TagsActionsTypes } from "./types"

const initialState = {
  isLoading: false,
  pagedTags: [],
  limit: 10,
  offset: 0,
  totalCount: 0,
  filter: "",
  sortOptions: {
    column: null,
    direction: null,
  },
  error: false,
  unassignedTags: false,
  cargoTagUploadMessage: { statusCode: 0, message: "" },
}

const cargoTags = (
  state = initialState,
  action: {
    type: TagsActionsTypes
    payload: any
  }
) => {
  switch (action.type) {
    case TagsActionsTypes.LOADING_CARGOTAGS:
      return { ...state, isLoading: action.payload }
    case TagsActionsTypes.UNASSIGNED_CARGOTAGS:
      return { ...state, unassignedTags: action.payload }
    case TagsActionsTypes.SET_PAGED_CARGOTAGS:
      return { ...state, pagedTags: action.payload }
    case TagsActionsTypes.SET_TAGS_TOTAL_COUNT:
      return { ...state, totalCount: action.payload }
    case TagsActionsTypes.SET_TAGS_OFFSET:
      return { ...state, offset: action.payload }
    case TagsActionsTypes.SET_TAGS_ERROR:
      return { ...state, error: action.payload }
    case TagsActionsTypes.SET_TAGS_LIMIT:
      return { ...state, limit: action.payload }
    case TagsActionsTypes.SET_TAGS_SORT_OPTIONS:
      return { ...state, sortOptions: action.payload }
    case TagsActionsTypes.SET_CARGO_TAG_UPLOAD_MESSAGE:
      return { ...state, cargoTagUploadMessage: action.payload }
    case TagsActionsTypes.SET_CARGO_TAGS_FILTER:
      return { ...state, filter: action.payload }

    default:
      return state
  }
}

export default cargoTags
