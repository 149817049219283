import { DEFAULT_WINDOW } from "../../constants";
import { AssetDetailsActions, AssetDetailsReducer, AssetDetailsTypes } from "./types";

const status = { statusCode: 0, message: "" }

const initialState: AssetDetailsReducer = {
    assetInfo: { isLoading: false, data: null, status },
    sensorInfo: {
        isLoading: false,
        data: [],
        status
    },
    statuses: {
        isLoading: false,
        data: null,
        status
    },
    recentWarnings: {
        isLoading: false,
        loadingWarningAcknowledge: false,
        data: [],
        totalCount: 0,
        acknowledgedWarningsCount: 0,
        limit: 10,
        offset: 0,
        showAllWarnings: false,
        filter: {
            warning_status: [],
            sensor_type: [],
            warning_type: [],
            position_type: []
        },
        days: DEFAULT_WINDOW,
        status
    },
    vibrations: {
        isLoading: false,
        data: {},
        status
    },
    tirePressure: {
        isLoading: false,
        data: [],
        status
    },
    axleLoad: {
        isLoading: false, 
        data: [], 
        calibrationsCount: null, 
        status
    },
    linePressure: {
        isLoading: false,
        data: [],
        status
    },
    temperature: {
        isLoading: false,
        data: [],
        status
    },
    powerInputs: {
        isLoading: false,
        data: null,
        status
    },
    gps: {
        isLoading: false,
        data: [],
        status
    },
    motionAnnotations: {},
    assignedTpmsProfile: null,
    chartsXRange: { min: null, max: null }
}


const assetDetails = (state = initialState, action: AssetDetailsActions): AssetDetailsReducer  => {
    switch (action.type) {
        // asset info
        case AssetDetailsTypes.LOADING_ASSET_INFO:
            return { ...state, assetInfo: { ...state.assetInfo, isLoading: action.payload } }
        case AssetDetailsTypes.SET_ASSET_INFO:
            return { ...state, assetInfo: { ...state.assetInfo, data: action.payload } }
        case AssetDetailsTypes.SET_ASSET_INFO_STATUS:
            return { ...state, assetInfo: { ...state.assetInfo, status: action.payload } }

        // sensorInfo
        case AssetDetailsTypes.LOADING_SENSOR_INFO:
            return { ...state, sensorInfo: { ...state.sensorInfo, isLoading: action.payload } }
        case AssetDetailsTypes.SET_SENSOR_INFO:
            return { ...state, sensorInfo: { ...state.sensorInfo, data: action.payload } }
        case AssetDetailsTypes.SET_SENSOR_INFO_STATUS:
            return { ...state, sensorInfo: { ...state.sensorInfo, status: action.payload } }

        // statuses
        case AssetDetailsTypes.LOADING_STATUS_DIAGRAM_INFO:
            return { ...state, statuses: { ...state.statuses, isLoading: action.payload } }
        case AssetDetailsTypes.SET_STATUS_DIAGRAM:
            return { ...state, statuses: { ...state.statuses, data: action.payload } }
        case AssetDetailsTypes.SET_STATUS_DIAGRAM_STATUS:
            return { ...state, statuses: { ...state.statuses, status: action.payload } }

        // warnings
        case AssetDetailsTypes.LOADING_WARNINGS:
            return { ...state, recentWarnings: { ...state.recentWarnings, isLoading: action.payload } }
        case AssetDetailsTypes.SET_WARNINGS:
            return { ...state, recentWarnings: { ...state.recentWarnings, data: action.payload } }
        case AssetDetailsTypes.SET_WARNINGS_LIMIT:
            return { ...state, recentWarnings: { ...state.recentWarnings, limit: action.payload } }
        case AssetDetailsTypes.SET_WARNINGS_OFFSET:
            return { ...state, recentWarnings: { ...state.recentWarnings, offset: action.payload } }
        case AssetDetailsTypes.SET_WARNINGS_TOTAL_COUNT:
            return { ...state, recentWarnings: { ...state.recentWarnings, totalCount: action.payload } }
        case AssetDetailsTypes.SET_WARNINGS_FILTER:
            return { ...state, recentWarnings: { ...state.recentWarnings, filter: action.payload } }
        case AssetDetailsTypes.SET_WARNINGS_STATUS:
            return { ...state, recentWarnings: { ...state.recentWarnings, status: action.payload } }
        case AssetDetailsTypes.SET_SHOW_ALL_WARNINGS:
            return { ...state, recentWarnings: { ...state.recentWarnings, showAllWarnings: action.payload } }
        case AssetDetailsTypes.SET_WARNINGS_DAYS:
            return { ...state, recentWarnings: { ...state.recentWarnings, days: action.payload } }
        
        // acknowledgement
        case AssetDetailsTypes.LOADING_WARNINGS_ACKNOWLEDGE:
            return { ...state, recentWarnings: { ...state.recentWarnings, loadingWarningAcknowledge: action.payload } }
        case AssetDetailsTypes.SET_WARNINGS_ACKNOWLEDGED_COUNT:
            return { ...state, recentWarnings: { ...state.recentWarnings, acknowledgedWarningsCount: action.payload } }

        // vibrations
        case AssetDetailsTypes.LOADING_VIBRATIONS:
            return { ...state, vibrations: { ...state.vibrations, isLoading: action.payload } }
        case AssetDetailsTypes.SET_VIBRATIONS:
            return { ...state, vibrations: { ...state.vibrations, data: action.payload } }
        case AssetDetailsTypes.SET_VIBRATIONS_STATUS:
            return { ...state, vibrations: { ...state.vibrations, status: action.payload } }

        // tirePressure
        case AssetDetailsTypes.LOADING_TIRE_PRESSURE:
            return { ...state, tirePressure: { ...state.tirePressure, isLoading: action.payload } }
        case AssetDetailsTypes.SET_TIRE_PRESSURE:
            return { ...state, tirePressure: { ...state.tirePressure, data: action.payload } }
        case AssetDetailsTypes.SET_TIRE_PRESSURE_STATUS:
            return { ...state, tirePressure: { ...state.tirePressure, status: action.payload } }
        
        // axle load
        case AssetDetailsTypes.LOADING_AXLE_LOAD:
            return { ...state, axleLoad: { ...state.axleLoad, isLoading: action.payload } }
        case AssetDetailsTypes.SET_AXLE_LOAD:
            return { ...state, axleLoad: { ...state.axleLoad, data: action.payload } }
        case AssetDetailsTypes.SET_AXLE_LOAD_STATUS:
            return { ...state, axleLoad: { ...state.axleLoad, status: action.payload } }
        case AssetDetailsTypes.SET_AXLE_LOAD_CALIBRATIONS_COUNT:
            return { ...state, axleLoad: { ...state.axleLoad, calibrationsCount: action.payload } }

        // linePressure
        case AssetDetailsTypes.LOADING_LINE_PRESSURE:
            return { ...state, linePressure: { ...state.linePressure, isLoading: action.payload } }
        case AssetDetailsTypes.SET_LINE_PRESSURE:
            return { ...state, linePressure: { ...state.linePressure, data: action.payload } }
        case AssetDetailsTypes.SET_LINE_PRESSURE_STATUS:
            return { ...state, linePressure: { ...state.linePressure, status: action.payload } }

        // motionAnnotations
        case AssetDetailsTypes.SET_MOTION_ANNOTATIONS:
            return { ...state, motionAnnotations: action.payload }

        // temperature
        case AssetDetailsTypes.LOADING_TEMPERATURE:
            return { ...state, temperature: { ...state.temperature, isLoading: action.payload } }
        case AssetDetailsTypes.SET_TEMPERATURE:
            return { ...state, temperature: { ...state.temperature, data: action.payload } }
        case AssetDetailsTypes.SET_TEMPERATURE_STATUS:
            return { ...state, temperature: { ...state.temperature, status: action.payload } }

        // powerInputs
        case AssetDetailsTypes.LOADING_POWER_INPUTS:
            return { ...state, powerInputs: { ...state.powerInputs, isLoading: action.payload } }
        case AssetDetailsTypes.SET_POWER_INPUTS:
            return { ...state, powerInputs: { ...state.powerInputs, data: action.payload } }
        case AssetDetailsTypes.SET_POWER_INPUTS_STATUS:
            return { ...state, powerInputs: { ...state.powerInputs, status: action.payload } }

        // gps
        case AssetDetailsTypes.LOADING_GPS:
            return { ...state, gps: { ...state.gps, isLoading: action.payload } }
        case AssetDetailsTypes.SET_GPS:
            return { ...state, gps: { ...state.gps, data: action.payload } }
        case AssetDetailsTypes.SET_GPS_STATUS:
            return { ...state, gps: { ...state.gps, status: action.payload } }
        
        // assignedTpmsProfile
        case AssetDetailsTypes.SET_ASSIGNED_TPMS_PROFILE:
            return { ...state, assignedTpmsProfile: action.payload }
        
        // charts common x range
        case AssetDetailsTypes.SET_CHARTS_X_RANGE:
            return { ...state, chartsXRange: action.payload }

        case AssetDetailsTypes.RESET_REDUCER:
            if (action.payload) return initialState
            else return state
        default:
            return state
    }
}

export default assetDetails
