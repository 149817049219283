import {
  getClearLabel,
  convertDataEpochToDate,
  getDurationFromSeconds,
} from '../../../utils'
import { IRow } from '../../../components/RivataGrid'

export const durationInWarningStateRows = (
  rows: Array<IRow>,
  locale: ILocale,
) =>
  rows.map((row) => {
    return {
      ...row,
      parent_account: row.parent_account || '---',
      warning_type: locale[row.warning_type] || row.warning_type,
      position:
        row.position === 'Chassis'
          ? getClearLabel('Red Supply Line')
          : getClearLabel(row.position),
      description: row.description || '---',
      start_date: convertDataEpochToDate(row.start_date, null, null, true),
      end_date: row.end_date
        ? convertDataEpochToDate(row.end_date, null, null, true)
        : '---',
      duration: getDurationFromSeconds(row.duration),
      is_recovered_to_normal: row.is_recovered_to_normal ? 'Yes' : 'No',
    }
  })
