import React, { ReactElement, useEffect, useState } from 'react'
import { useLocation, Link } from 'react-router-dom'
import './styles.scss'

type breadcrumb = { label: string; path?: string | undefined }

interface ISubNavprops {
  title: string
  breadcrumbs: Array<breadcrumb>
  children?: ReactElement | null
}

const SubNav: React.FC<ISubNavprops> = ({
  title,
  breadcrumbs,
  children = null,
}) => {
  const location = useLocation()
  const [isPublic, setIsPublic] = useState(false)

  useEffect(() => {
    if (location.pathname.includes('/public/')) {
      setIsPublic(true)
    }
  }, [location.pathname])

  return (
    <div
      className={`page-titles fixed-top ${!isPublic ? 'top-indent' : ''}`}
      style={{ maxHeight: '61px' }}
    >
      <div
        className='row subnav-content__wrapper container-fluid'
        style={{ maxWidth: '1600px' }}
      >
        <div className={`col-md-${children ? 4 : 9} align-self-center`}>
          <div className='d-flex justify-content-start align-items-center'>
            <h4 className='text-themecolor mb-0 d-inline-block'>{title}</h4>
            {/* <div className="divider d-inline-block"></div> */}
            <ol className='ml-3 breadcrumb d-inline-flex'>
              {breadcrumbs.map((bc, i) => {
                if (i === breadcrumbs.length - 1) {
                  return (
                    <li key={i} className='breadcrumb-item active'>
                      {bc.label}
                    </li>
                  )
                }
                return (
                  <li key={i} className='breadcrumb-item'>
                    {bc.path && <Link to={bc.path}>{bc.label}</Link>}
                  </li>
                )
              })}
            </ol>
          </div>
        </div>
        {children && (
          <div className='col-md-8 align-self-center text-right'>
            <div className='d-flex justify-content-end align-items-center subnav-children__wrapper'>
              {children}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default SubNav
