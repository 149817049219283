import React, { useCallback } from "react";
import { SearchItemType } from "../../../components/ItemsSearch";
import ItemsSelectWithSearch from "../../../components/ItemsSelectWithSearch/ItemsSelectWithSearch";
import { EndPointConfigs } from "../../../components/BackendSearchInput/constants";
import { MenuItemType } from "../../../componentsV2/Menu/MenuItem";
import AssetGroupsFilter from "../../../componentsV2/AssetGroupsFilter";
import HealthStatusFilter from "../../../componentsV2/HealthStatusFilter";
import DateTimePicker from "../../../componentsV2/DateTimePicker";
import { CalendarTypes } from "../../../enums";
import moment from "moment";

interface Props {
  dateFrom: Date;
  dateTo: Date;
  minDate: Date;
  disabled: boolean;
  setDateFrom: React.Dispatch<React.SetStateAction<Date>>;
  setDateTo: React.Dispatch<React.SetStateAction<Date>>;
  setVinList: React.Dispatch<React.SetStateAction<Array<Object>>>;
  setSelectedGeofences: React.Dispatch<React.SetStateAction<Array<Object>>>;
  selectedAssetsItems: Array<SearchItemType>;
  selectedGeofenceItems: Array<SearchItemType>;
  sersorStatusItemsFiltered: Array<any>;
  onWarningItemSelectNew: (id: string, conf: Array<MenuItemType>) => void;
  showAssetGroupsFilter?: boolean | false;
  onAssetGroupFilterItemSelected?: (
    id: string,
    selectedValue: MenuItemType[]
  ) => void;
  customerIds?: string | "";
}

const columnsToShow = [
  { key: "name", name: "Name" },
  { key: "vin", name: "VIN" },
];

const geofenceColumnsToShow = [{ key: "name", name: "Name" }];

const WarningHistoryReportForm: React.FC<Props> = ({
  dateFrom,
  dateTo,
  minDate,
  disabled,
  setDateFrom,
  setDateTo,
  setVinList,
  setSelectedGeofences,
  selectedAssetsItems,
  selectedGeofenceItems,
  sersorStatusItemsFiltered,
  onWarningItemSelectNew,
  onAssetGroupFilterItemSelected,
  customerIds,
  showAssetGroupsFilter,
}) => {
  const onAssetsSelect = useCallback(
    (Assets: SearchItemType[]) => {
      setVinList(Assets);
    },
    [setVinList]
  );

  const onGeofencesSelect = useCallback(
    (selectedGeofences: SearchItemType[]) => {
      setSelectedGeofences(selectedGeofences);
    },
    [setSelectedGeofences]
  );

  const onWarningTypeSelect = useCallback(
    (id, conf: MenuItemType[]) => {
      onWarningItemSelectNew(id, conf);
    },
    [onWarningItemSelectNew]
  );

  const onDateRangeSelect = (from: Date, to: Date) => {
    setDateFrom(from);
    setDateTo(to);
  }

  return (
    <>
      <HealthStatusFilter
        id="warning_type_new"
        className="mr-2 warning-type-select"
        overrideDefaultItems={sersorStatusItemsFiltered}
        onDropdownItemSelect={onWarningTypeSelect}
        disabled={disabled}
      />

      <div className="mb-2">Select Date Range</div>
      <DateTimePicker
        defaultMode={CalendarTypes.DateRange}
        dateFrom={dateFrom.toISOString()}
        dateTo={dateTo.toISOString()}
        onDateRangeSelect={onDateRangeSelect}
        maxDate={moment().toDate()}
        disabled={disabled}
      />

      <ItemsSelectWithSearch
        label="Assets"
        inputPlaceholder="Search Assets"
        endPointConfig={EndPointConfigs.assetName}
        onItemsSelect={onAssetsSelect}
        columnsToShow={columnsToShow}
        disabled={disabled}
        selectedItems={selectedAssetsItems}
      />
      {showAssetGroupsFilter && (
        <AssetGroupsFilter
          className="mt-3"
          onDropdownItemSelect={onAssetGroupFilterItemSelected}
          customerIds={customerIds}
        />
      )}
      <ItemsSelectWithSearch
        label="Geofences"
        inputPlaceholder="Search Geofences"
        endPointConfig={EndPointConfigs.geofence}
        onItemsSelect={onGeofencesSelect}
        columnsToShow={geofenceColumnsToShow}
        disabled={disabled}
        selectedItems={selectedGeofenceItems}
      />
    </>
  );
};

export default WarningHistoryReportForm;
