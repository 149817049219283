import React, { useCallback, useRef } from 'react'
import RivataModule from '../../../components/RivataModule'
import RivataTable from '../../../components/RivataTable'
import { useActions } from '../../../hooks/useActions'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { makeScrollToElementWithOffset } from '../../../utils/utils'
import { useColumns, useRows } from './hooks'
import GeofenceDetailsFilterMenu from './GeofenceDetailsFilterMenu'

const defaultVisibleColumnsIds = [
    "status",
    "name",
    "asset_groups",
    "asset_type",
    "entry_time",
    "dwell_time",
    "timestamp",
]
  
const alwaysVisibleColumnsIds = [
    "status",
    "name",
    "asset_type",
]
const columnsSelectorProps = {
    showDropdown: true,
    defaultVisible: defaultVisibleColumnsIds,
    alwaysVisible: alwaysVisibleColumnsIds,
    localeStorageKey: "geofenceDetails.columns",
}
interface Props {
    locale: ILocale
    onFilter: (Filters: string) => void
}

const GeofenceDetailsGrid: React.FC<Props> = ({
    locale,
    onFilter,
}) => {
    const {
        selectedGeofence: {
            data: geofenceData,
            status: geofenceStatus
        },
        assets: {
            isLoading,
            data: assetsData,
            limit,
            offset,
            totalCount,
            status: assetsStatus
        },
        auth: {
            whiteLabelUrl,
            isSuperAdmin
        }
    } = useTypedSelector(state => ({
        selectedGeofence: state.geofences.selectedGeofence,
        assets: state.geofences.assets,
        whiteLabelUrl: state.auth.whiteLabelUrl,
        auth: state.auth
    }))
    const {
        setGeofenceAssetsLimit,
        setGeofenceAssetsOffset,
        // setGeofenceAssetsSortOptions
    } = useActions()

    const scrollTarget = useRef(null)

    const columns = useColumns()
    const rows = useRows(columns, assetsData, limit, offset, whiteLabelUrl, isSuperAdmin)

    const handlePageChange = useCallback((offset) => {
        setGeofenceAssetsOffset(offset)
        makeScrollToElementWithOffset(scrollTarget, -250)
    }, [setGeofenceAssetsOffset])

    const handleLimitChange = useCallback((limit) => {
        setGeofenceAssetsLimit(limit)
        makeScrollToElementWithOffset(scrollTarget, -250)
    }, [setGeofenceAssetsLimit])

    return (
        <RivataModule 
            title={`Assets in Geofence ${geofenceData?.name || ""}`} 
            locale={locale}
            filters={null} 
            error={geofenceStatus.statusCode >= 400 ? geofenceStatus : assetsStatus}
        >
            <GeofenceDetailsFilterMenu 
                onFilter={onFilter}
            />

            <hr/>

            <div ref={scrollTarget} className={`position-relative ${rows.length <= 3 && 'extra-height-content'}`}>
                <RivataTable 
                    columns={columns}
                    rows={rows}
                    isLoading={isLoading} 
                    pageLimit={limit}
                    totalCount={totalCount}
                    page={offset / limit}
                    showPagination={true}
                    isShowingLimit={true}
                    setSortOptions={undefined} // setGeofenceAssetsSortOptions
                    // @ts-ignore component expect undefined cause of default value
                    onPageChange={handlePageChange}
                    // @ts-ignore component expect undefined cause of default value
                    onSelectLimit={handleLimitChange}
                    onEdit={undefined} 
                    onDelete={undefined} 
                    editDisabled={undefined} 
                    deleteDisabled={undefined} 
                    onCustomAction={undefined}
                    // @ts-ignore component expect undefined cause of default value
                    columnsSelectorProps={columnsSelectorProps}
                />
            </div>
        </RivataModule>
    )
}

export default GeofenceDetailsGrid