import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import CargotagGrid from "../../modules/CargotagGrid/CargotagGrid"
import CargotagPlaceDetails from "../../modules/CargotagPlaceDetails"
import CargotagLocationDetailsMap from "./modules/CargotagLocationDetailsMap"
import SubNav from "../../components/SubNav"

import { useActions } from "../../hooks/useActions"
import { useTypedSelector } from "../../hooks/useTypedSelector"

const breadcrumbs = [{ label: "Home", path: "/dashboard" }, { label: "Tag Locations", path: "/tag-locations" }, { label: "Tag Location Details" }]

const TagLocationDetails: React.FC = () => {
  const { 
    locale, 
    preferences,
    tagLocations: { selectedLocationOrAsset, isLoading, selectedStatus } 
  } = useTypedSelector(state => ({
    locale: state.whitelabel.locale,
    preferences: state.auth.preferences,
    tagLocations: state.tagLocations
  }))
  const { fetchLocationsWithCargoGps, setSelectedPlace } = useActions()
  const [place, setPlace] = useState(null)
  const params: { id: string } = useParams()

  useEffect(() => {
    fetchLocationsWithCargoGps(params.id)
  }, [params.id, fetchLocationsWithCargoGps])

  useEffect(() => {
    // hack to fix issue with non drawable marker on page change
    setPlace(selectedLocationOrAsset)
  }, [selectedLocationOrAsset])

  useEffect(() => {
    // clear selected place on unmount
    return () => {
      setSelectedPlace(null)
    }
  }, [setSelectedPlace])
  
  return (
    <>
      <SubNav title="Tag Location Details" breadcrumbs={breadcrumbs} />
      <CargotagPlaceDetails
        locale={locale}
        place={place}
        isLoading={isLoading}
        title="Location Info"
        status={selectedStatus}
      />

      <CargotagLocationDetailsMap
        locale={locale}
        location={place}
        preferences={preferences}
        status={selectedStatus}
      />

      <CargotagGrid
        place={place}
        locale={locale}
        isLoading={isLoading}
        status={selectedStatus}
      />
    </>
  )
}

export default TagLocationDetails