import React from "react"

import { useTypedSelector } from "../../../hooks/useTypedSelector"
import { NoDataLabels } from "../../../enums"

interface Props {
    vin: string
}

const AssetInfo: React.FC<Props> = ({ vin }) => {
    const {
        assetInfo,
        provisionInfo,
        gateways
    } = useTypedSelector(state => ({
        assetInfo: state.assetAdminInfo.assetInfo,
        provisionInfo: state.assetAdminInfo.provisionInfo,
        gateways: state.assetAdminInfo.gateways
    }))

    return (
        <div>
            <h4>Selected Asset Info</h4>
            <ul className="asset-info__list">
                <li>Asset Id: <span>{assetInfo?.id ? assetInfo.id : NoDataLabels.DASH}</span></li>
                <li>Asset Name: <span>{assetInfo?.name ? assetInfo.name : NoDataLabels.DASH}</span></li>
                <li>Asset Vin: <span>{vin}</span></li>
                <li>Last Provisioning Timestamp: <span>{provisionInfo?.lastProvisionTimestamp ? provisionInfo.lastProvisionTimestamp : NoDataLabels.DASH}</span></li>
                <li>Whitelisting Automation: <span>{assetInfo?.whitelistingAutomation?.toString()}</span></li>
                {gateways.map((gw: any) => {
                    return (
                        <li key={gw.id} className="mt-2">
                            <div>Gateway ESN: <span>{gw.mac}</span></div>
                            <div>Gateway Device Type: <span>{gw.info?.device_info?.type || NoDataLabels.DASH}</span></div>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default AssetInfo