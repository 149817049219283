import React, { useCallback, useMemo, useState } from "react"
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap"
import { useTypedSelector } from "../../../hooks/useTypedSelector"

type Props = {
  selectedGeofence: IGeofence | null
  shareCustomerIds: Array<number>
  setShareCustomerIds: React.Dispatch<React.SetStateAction<number[]>>
}

const ShareCustomersSelector: React.FC<Props> = ({
  selectedGeofence,
  shareCustomerIds,
  setShareCustomerIds,
}) => {
  const {
    customers: { data: customers, selectedCustomersList },
    isSuperAdmin,
  }: {
    customers: {
      data: Array<ICustomer>
      selectedCustomersList: Array<ICustomer>
    }
    isSuperAdmin: boolean
  } = useTypedSelector((state) => ({
    customers: state.common.customers,
    isSuperAdmin: state.auth.isSuperAdmin,
  }))

  const [isOpen, setIsOpen] = useState(false)

  const customersFiltered = useMemo(() => {
    if (!isSuperAdmin) {
      const parent = customers.find((customer) => !customer.parent_id)

      const children = customers.filter((customer) => !!customer.parent_id)

      return { parent, children }
    }

    let parentId: number

    if (selectedGeofence) {
      const creator = customers.find(
        (customer) => customer.id === selectedGeofence.customer_id
      )

      if (!creator) return

      parentId = !creator.parent_id ? creator.id : creator.parent_id
    } else {
      if (selectedCustomersList.length !== 1) return

      parentId = !selectedCustomersList[0].parent_id
        ? selectedCustomersList[0].id
        : selectedCustomersList[0].parent_id
    }

    const parent = customers.find(
      (customer: ICustomer) => customer.id === parentId
    )

    const children = customers.filter(
      (customer: ICustomer) => customer.parent_id === parentId
    )

    return { parent, children }
  }, [isSuperAdmin, customers, selectedCustomersList, selectedGeofence])

  const onCheck = useCallback(
    (customerId: number) => {
      setShareCustomerIds((shareCustomerIds) => {
        const res = [...shareCustomerIds]
        const idx = res.findIndex((id) => id === customerId)

        if (idx >= 0) {
          res.splice(idx, 1)
          return res
        }

        res.push(customerId)
        return res
      })
    },
    [setShareCustomerIds]
  )

  const toggle = () => setIsOpen((isOpen) => !isOpen)

  const parentIsLocationCreator =
    customersFiltered?.parent &&
    selectedGeofence?.customer_id === customersFiltered.parent.id

  const parentIsSelectedCustomer =
    customersFiltered?.parent &&
    selectedCustomersList[0].id === customersFiltered.parent.id

  const parentInList =
    customersFiltered?.parent &&
    shareCustomerIds.includes(customersFiltered.parent.id)

  const parentChecked =
    parentIsLocationCreator ||
    (!selectedGeofence && parentIsSelectedCustomer) ||
    parentInList

  const parentDisabled =
    parentIsLocationCreator || (!selectedGeofence && parentIsSelectedCustomer)

  return (
    <>
      {customersFiltered && (
        <Dropdown isOpen={isOpen} toggle={toggle}>
          <DropdownToggle caret>Customers</DropdownToggle>

          <DropdownMenu>
            {customersFiltered.parent && (
              <div className="ml-2">
                <input
                  type="checkbox"
                  id="parentCustomer"
                  checked={parentChecked}
                  disabled={parentDisabled}
                  onChange={() =>
                    customersFiltered.parent &&
                    onCheck(customersFiltered.parent.id)
                  }
                />
                <label htmlFor="parentCustomer" className="mb-0 ml-1">
                  {customersFiltered.parent.name}
                </label>
              </div>
            )}

            {customersFiltered.children.map((childCustomer: ICustomer) => {
              const isLocationCreator =
                selectedGeofence?.customer_id === childCustomer.id

              const isSelectedCustomer =
                selectedCustomersList[0].id === childCustomer.id

              const isInList = shareCustomerIds.includes(childCustomer.id)

              const checked =
                isLocationCreator ||
                (!selectedGeofence && isSelectedCustomer) ||
                isInList

              const disabled =
                isLocationCreator || (!selectedGeofence && isSelectedCustomer)

              return (
                <div
                  className="ml-4 mt-2"
                  key={`childCustomer${childCustomer.id}`}
                >
                  <input
                    type="checkbox"
                    id={`childCustomer${childCustomer.id}`}
                    onChange={() => onCheck(childCustomer.id)}
                    checked={checked}
                    disabled={disabled}
                  />
                  <label
                    className="mb-0 ml-1"
                    htmlFor={`childCustomer${childCustomer.id}`}
                  >
                    {childCustomer.name}
                  </label>
                </div>
              )
            })}
          </DropdownMenu>
        </Dropdown>
      )}
    </>
  )
}

export default React.memo(ShareCustomersSelector)
