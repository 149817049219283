import { AssetSpeedLevel, BearingVibrationLevel, NotificationTypes, PressureWarningLevel, TemperatureWarningLevel, TireVibrationLevel, WarningCase } from "../../enums"
import { ICreateNotificationprofile, IUpdateNotificationprofile } from "../../redux/manageNotifications/types"
import { NotificationFormValues } from "./hooks"

interface IcomposeCreateRequestBody {
    (
        formValues: NotificationFormValues,
        profileUsers: Array<IUserShortFormat> | null,
        profileAssets: Array<any>,
        profileGeofences: Array<any>,
        profileAssetsGroups: Array<any>,
        customerId: number
    ): ICreateNotificationprofile
}


export const composeCreateRequestBody: IcomposeCreateRequestBody = (formValues, profileUsers, profileAssets, profileGeofences, profileAssetsGroups, customerId) => {
    let tirePressure: Array<string> = []

    if (formValues.tire_pressure === NotificationTypes.all) {
        tirePressure = Object.values(PressureWarningLevel)
    } else if (formValues.tire_pressure === NotificationTypes.criticalWarning) {
        tirePressure = [PressureWarningLevel.criticalLowPressure, PressureWarningLevel.criticalOverPressure]
    } else if (formValues.tire_pressure === NotificationTypes.warning) {
        tirePressure = [PressureWarningLevel.lowPressure, PressureWarningLevel.overPressure]
    }

    let hubTemperature: Array<string> = []
    if (formValues.hub_temperature === NotificationTypes.all) {
        hubTemperature = [TemperatureWarningLevel.criticalHighTemperature, TemperatureWarningLevel.highTemperature]
    } else if (formValues.hub_temperature === NotificationTypes.criticalWarning) {
        hubTemperature = [TemperatureWarningLevel.criticalHighTemperature]
    } else if (formValues.hub_temperature === NotificationTypes.warning) {
        hubTemperature = [TemperatureWarningLevel.highTemperature]
    }

    let geofenceAlerts: Array<string> = []
    if (formValues.geofence === NotificationTypes.all) {
        geofenceAlerts = [NotificationTypes.geofenceEntry, NotificationTypes.geofenceExit]
    } else if (formValues.geofence === NotificationTypes.geofenceEntry) {
        geofenceAlerts = [NotificationTypes.geofenceEntry]
    } else if (formValues.geofence === NotificationTypes.geofenceExit) {
        geofenceAlerts = [NotificationTypes.geofenceExit]
    }

    let tireVibration: Array<string> = []
    if (formValues.tire_vibration === NotificationTypes.all) {
        tireVibration = [TireVibrationLevel.abnormalTireVibration, TireVibrationLevel.abnormalHighTireVibration]
    } else if (formValues.tire_vibration === NotificationTypes.criticalWarning) {
        tireVibration = [TireVibrationLevel.abnormalHighTireVibration]
    } else if (formValues.tire_vibration === NotificationTypes.warning) {
        tireVibration = [TireVibrationLevel.abnormalTireVibration]
    }

    let bearingVibration: Array<string> = []
    if (formValues.bearing_vibration === NotificationTypes.all) {
        bearingVibration = [BearingVibrationLevel.abnormalBearingVibration, BearingVibrationLevel.abnormalHighBearingVibration]
    } else if (formValues.bearing_vibration === NotificationTypes.criticalWarning) {
        bearingVibration = [BearingVibrationLevel.abnormalHighBearingVibration]
    } else if (formValues.bearing_vibration === NotificationTypes.warning) {
        bearingVibration = [BearingVibrationLevel.abnormalBearingVibration]
    }

    let assetSpeed: Array<string> = []
    if (formValues.asset_speed === NotificationTypes.all) {
        assetSpeed = [AssetSpeedLevel.criticalOverSpeed, AssetSpeedLevel.overSpeed]
    } else if (formValues.asset_speed === NotificationTypes.criticalWarning) {
        assetSpeed = [AssetSpeedLevel.criticalOverSpeed]
    } else if (formValues.asset_speed === NotificationTypes.warning) {
        assetSpeed = [AssetSpeedLevel.overSpeed]
    }

    const body: ICreateNotificationprofile = {
        name: formValues.name,
        customer_id: customerId,
        alert_type_id: 1,
        profile: {},
        alert_notification_types: {
            sms: formValues.sms,
            email: formValues.email
        },
        intervals: {
            critical_alert_interval_in_seconds: hoursToSeconds(formValues.critical_alert_interval_in_seconds),
            info_alert_interval_in_seconds: hoursToSeconds(formValues.info_alert_interval_in_seconds)
        },
        users: !profileUsers ? [] : profileUsers.map(el => el.id),
        assets: !profileAssets ? [] : profileAssets.map(el => el.id),
        assets_groups: profileAssetsGroups,
        is_system_profile: false,
        geofences: !profileGeofences ? [] : profileGeofences.map(el => el.id), 
        is_combined: formValues.is_combined
    }

    if (tirePressure.length > 0) body.profile.tire_pressure = tirePressure
    if (formValues.line_pressure !== NotificationTypes.none) body.profile.line_pressure = [WarningCase.LOW_LINE_PRESSURE]
    if (hubTemperature.length > 0) body.profile.hub_temperature = hubTemperature
    if (formValues.asset_state !== NotificationTypes.none) body.profile.asset_state = [WarningCase.MOVEMENT_WITHOUT_ABS] 
    if (geofenceAlerts.length > 0) body.profile.geofence = geofenceAlerts
    if (tireVibration.length > 0) body.profile.tire_vibration = tireVibration
    if (bearingVibration.length > 0) body.profile.bearing_vibration = bearingVibration
    if (assetSpeed.length > 0) body.profile.asset_speed = assetSpeed
    if (formValues.pressure_fast_leak !== NotificationTypes.none) body.profile.pressure_fast_leak = [WarningCase.FAST_LEAK]

    return body
}


interface IcomposeUpdateRequestBody {
    (
        profileToEdit: INotificationProfile,
        formValues: NotificationFormValues,
        profileUsers: Array<IUserShortFormat> | null,
        profileAssets: Array<any>,
        profileGeofences: Array<any>,
        profileAssetsGroups: Array<any>,
        customerId: number
    ): IUpdateNotificationprofile
}

export const composeUpdateRequestBody: IcomposeUpdateRequestBody = (
    profileToEdit,
    formValues,
    profileUsers,
    profileAssets,
    profileGeofences,
    profileAssetsGroups,
    customerId
) => {
    const body = composeCreateRequestBody(formValues, profileUsers, profileAssets, profileGeofences, profileAssetsGroups, customerId)

    const updateBody = {
        name: formValues.is_system_profile ? profileToEdit.name : body.name,
        alert_type_id: body.alert_type_id,
        profile: body.profile,
        alert_notification_types: body.alert_notification_types,
        intervals: body.intervals,
        users: body.users,
        assets: body.assets,
        assets_groups: body.assets_groups,
        is_system_profile: body.is_system_profile,
        geofences: body.geofences,
        is_combined: body.is_combined
    }

    return updateBody
}


type UnverifiedUsersContacts = { email: Array<IUserShortFormat>, sms: Array<IUserShortFormat> }
interface IgetUnverifiedUserList {
    (
        email: boolean,
        sms: boolean,
        usersList: Array<IUserShortFormat> | null
    ): UnverifiedUsersContacts
}

export const getUnverifiedUserList: IgetUnverifiedUserList = (email, sms, usersList) => {
    const defaultValue: UnverifiedUsersContacts = { email: [], sms: [] }

    if (!usersList) return defaultValue

    return usersList.reduce((prev, curr) => {
        if (email && !curr.email_verified) prev.email.push(curr)
        if (sms && !curr.phone_number_verified) prev.sms.push(curr)

        return prev
    }, defaultValue)
}

export const secondsToHours = (sec: number) => Math.floor(sec / 60 / 60)
export const hoursToSeconds = (hours: number) => Math.floor(hours * 60 * 60)
