import React, { useState, useMemo } from 'react'
import { getClearLabel } from '../../utils'
import { reorderTableColumns } from '../../utils/utils'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

export const tpmsTableSortColumns = [
  { id: 'customer_name', label: 'Customer', isDraggable: true },
  { id: 'asset_name', label: 'Asset Name', isDraggable: true },
  { id: 'vin', label: 'VIN', isDraggable: true },
  { id: 'asset_groups', label: 'Asset Groups', isDraggable: true },
  { id: 'geofences', label: 'Geofence', isDraggable: true },
  {
    id: 'latest_tpms_update_at_epoch',
    label: 'Latest TPMS Update',
    isDraggable: true,
  },
  { id: 'tpms_profile', label: 'TPMS Profile', isDraggable: true },
]
const Thead = ({
  columns,
  onSort,
  sortColumn,
  setSortColumn,
  columnsToShow,
  setColumnsOrder,
}) => {
  const [sortDirection, setSortDirection] = useState(null)

  const sortColumns = useMemo(() => {
    const cols = []
    columnsToShow.forEach((colToShow) => {
      const tpmsCol = tpmsTableSortColumns.find((col) => colToShow === col.id)
      if (tpmsCol) {
        cols.push(tpmsCol)
      }
    })
    return cols
  }, [columnsToShow])

  const sortHandler = (id) => {
    let currentDirection = sortDirection
    let column = id

    if (sortColumn !== column) {
      setSortColumn(column)
      currentDirection = null
    }

    if (!currentDirection) {
      currentDirection = 'desc'
    } else if (currentDirection === 'desc') {
      currentDirection = 'asc'
    } else {
      currentDirection = null
      column = null
      setSortColumn(null)
    }

    setSortDirection(currentDirection)
    if (onSort) onSort(column, currentDirection)
  }

  const sensorsColumns = useMemo(() => {
    const data = columns.map((th) => {
      return (
        <th key={th} className='sensor-column'>
          {getClearLabel(th)}
        </th>
      )
    })

    if (columns.length <= 12) return { upperRow: data }

    const upperRow = []
    const lowerRow = []

    for (let i = 0; i < columns.length; i++) {
      if (i % 2 === 0) upperRow.push(data[i])
      else lowerRow.push(data[i])
    }

    return { upperRow, lowerRow }
  }, [columns])

  const rowSpan = sensorsColumns.lowerRow ? 2 : 1
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const newItems = reorderTableColumns(
      sortColumns,
      result.source.index,
      result.destination.index,
    )
    setColumnsOrder(newItems)
  }
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='droppable' direction='horizontal'>
        {(droppableProvided, droppableSnapshot) => (
          <thead>
            <tr ref={droppableProvided.innerRef}>
              {sortColumns.map((item, index) =>
                item.isDraggable ? (
                  <TpmsDraggableTh
                    item={item}
                    sortColumn={sortColumn}
                    sortHandler={sortHandler}
                    rowSpan={rowSpan}
                    sortDirection={sortDirection}
                    index={index}
                    key={`table_header_${item.id}`}
                  />
                ) : (
                  <TpmsTh
                    item={item}
                    sortColumn={sortColumn}
                    sortHandler={sortHandler}
                    rowSpan={rowSpan}
                    sortDirection={sortDirection}
                    draggableProps={null}
                    key={`table_header_${item.id}`}
                  />
                ),
              )}
              {sensorsColumns.upperRow}
              {droppableProvided.placeholder}
            </tr>

            {sensorsColumns.lowerRow && <tr>{sensorsColumns.lowerRow}</tr>}
          </thead>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default Thead

const TpmsTh = ({
  item,
  sortHandler,
  sortColumn,
  rowSpan,
  sortDirection,
  draggableProps,
}) => {
  return (
    <th
      key={item.id}
      className='primary-column'
      onClick={() => sortHandler(item.id)}
      rowSpan={rowSpan}
      ref={draggableProps?.draggableRef}
      {...draggableProps?.draggableProps}
      {...draggableProps?.dragHandleProps}
    >
      <div className='th-items__wrapper'>
        {item.label}
        {!sortDirection || sortColumn !== item.id ? (
          <i className='fas fa-sort' />
        ) : sortDirection === 'desc' ? (
          <i className='fas fa-sort-down' />
        ) : (
          <i className='fas fa-sort-up' />
        )}
      </div>
    </th>
  )
}
const TpmsDraggableTh = ({
  item,
  sortHandler,
  sortColumn,
  rowSpan,
  sortDirection,
  index,
}) => {
  return (
    <Draggable draggableId={item.id} index={index}>
      {(draggableProvided) => (
        <TpmsTh
          item={item}
          sortColumn={sortColumn}
          sortHandler={sortHandler}
          rowSpan={rowSpan}
          sortDirection={sortDirection}
          draggableProps={{
            draggableRef: draggableProvided.innerRef,
            draggableProps: draggableProvided.draggableProps,
            dragHandleProps: draggableProvided.dragHandleProps,
          }}
        />
      )}
    </Draggable>
  )
}
