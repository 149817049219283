import { LOADING_REPORTS_HISTORY, SET_REPORTS_HISTORY } from '../actionTypes'

const initialState = {
  isLoading: false,
  data: [],
}

const reportsHistory = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_REPORTS_HISTORY:
      return { ...state, isLoading: action.payload }
    case SET_REPORTS_HISTORY:
      return { ...state, data: action.payload }
    default:
      return state
  }
}

export default reportsHistory
