import {
  LOADING_WHITELABEL,
  SET_WHITELABEL,
  SET_WHITELABEL_ERROR,
} from "../actionTypes";
import api from "../../services/api";
import { setAuthWhitelabel } from "../auth/action";

export const loadingWhitelabel = (isLoading) => ({
  type: LOADING_WHITELABEL,
  payload: isLoading,
});

export const setWhitelabel = (assets) => ({
  type: SET_WHITELABEL,
  payload: assets,
});

export const setWhitelabelError = (error) => ({
  type: SET_WHITELABEL_ERROR,
  payload: error,
});

export function fetchWhitelabel(customerId) {
  return function (dispatch, getState) {
    dispatch(loadingWhitelabel(true));
    return api
      .getConfig(customerId)
      .then((config) => {
        const whitelabelKey = customerId ? getState().common.customers.data.find(e => e.id === customerId).key : getState().auth.defWhiteLabel
        dispatch(setAuthWhitelabel({key: whitelabelKey}))

        dispatch(setWhitelabel(config));
        dispatch(setWhitelabelError(false));
      })
      .catch(() => dispatch(setWhitelabelError(true)))
      .finally(() => dispatch(loadingWhitelabel(false)));
  };
}


