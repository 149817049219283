import baseApi from "./BaseApi"

class WarningSettingApi {
  getWarningsSettings = (
    hardwareTypeId: number,
    profileId?: number | null
  ) => {
    const customer_ids = baseApi.customerIdsList?.split(",")
    let queryString = `?warning_type_id=${hardwareTypeId}`

    if (profileId) {
      queryString += `&profile_id=${profileId}`
    }
    if (customer_ids?.length === 1) {
      queryString += `&customer_id=${customer_ids[0]}`
    }

    return baseApi.get(`rivata/warningsetting${queryString}`, false)
  }

  getWarningsSettingsNames = (addCustomerIds = true) => {
    return baseApi.get(`rivata/warningsetting?get_limited_data=true`, addCustomerIds)
  }


  putWarningSettings = (data: any) => {
    return baseApi.put(`rivata/warningsetting`, data)
  }


  postWarningSettings = (data: any) => {
    return baseApi.post(`rivata/warningsetting`, data)
  }


  deleteWarningSettings = (settingsId: number) => {
    return baseApi.delete(`rivata/warningsetting?settings_id=${settingsId}`)
  }


  getSettingsDefaults = (customerId = null) => {
    return baseApi.get(`rivata/warningsetting/default${customerId ? "?customer_id=" + customerId : ""}`, false)
  }


  assignWarningSettingsToAssets = (data: any) => {
    return baseApi.put(`rivata/warningsetting/assign`, data)
  }

  searchWarningsSettings = (
    searchValue: string,
    limit = 20
  ) => {
    const params = new URLSearchParams()

    params.append("search_value", searchValue)

    params.append("limit", limit.toString())

    return baseApi.get(`rivata/warningsetting/search?${params.toString()}`)
  }
}

const warningSettingApi = new WarningSettingApi()

export default warningSettingApi