import moment from "moment"

type IMomentTimeRange = {
  min: moment.Moment,
  max: moment.Moment
} | null

//atempt to recreate google calendar's time input string parsing
export const inputToValue = (
  string: string,
  use12Hours = true,
  useSeconds = false,
  restrictToStep = false,
  step?: number,
  range?: IMomentTimeRange,
) => {
  //check if there is "am" or "pm" in input string, if (use12Hours) then add pm
  const ampm = /[aA][mM]/.test(string)
    ? "AM"
    : /[pP][mM]/.test(string) || use12Hours
      ? "PM"
      : null

  //get array of all digits from input string
  const digits = string.match(/\d/g)

  if (!digits || digits.length > 6) return

  let hours
  let minutes
  let seconds

  if (digits.length % 2 === 0) {
    hours = digits[0] + digits[1]
    minutes = (digits[2] ?? "0") + (digits[3] ?? "0")
    seconds = (digits[4] ?? "0") + (digits[5] ?? "0")
  } else {
    hours = "0" + digits[0]
    minutes = (digits[1] ?? "0") + (digits[2] ?? "0")
    seconds = (digits[3] ?? "0") + (digits[4] ?? "0")
  }

  let momentTime =
    ampm && +hours > 0 && +hours <= 12
      ? moment(`${hours}:${minutes}:${seconds}${ampm}`, "hh:mm:ssA", true)
      : moment(`${hours}:${minutes}:${seconds}`, "HH:mm:ss", true)

  if (!momentTime.isValid()) return

  if (range) {
    if (momentTime.isBefore(range.min)) momentTime = range.min

    if (momentTime.isAfter(range.max)) momentTime = range.max
  }

  if (restrictToStep && step) {
    momentTime.seconds(0)
    if (+minutes % step !== 0)
      momentTime.minutes(step * Math.floor(+minutes / step))
  }

  return useSeconds
    ? momentTime.format("HH:mm:ss")
    : momentTime.format("HH:mm")
}

export const valueToLabel = (time: string, use12Hours = true, useSeconds = false) => {
  if (!time) return ""

  if (use12Hours)
    return useSeconds
      ? moment(time, "HH:mm").format("hh:mm:ssA")
      : moment(time, "HH:mm").format("hh:mmA")

  return useSeconds
    ? moment(time, "HH:mm").format("HH:mm:ss")
    : moment(time, "HH:mm").format("HH:mm")
}

export const getDropdownItems = (step: number, range?: IMomentTimeRange, use12Hours = true, useSeconds = false) => {
  const res = []

  let hMin = 0,
    hMax = 23,
    mMin = 0,
    mMax = 59

  if (range) {
    hMin = range.min.hours()
    hMax = range.max.hour()

    mMin = range.min.minutes()
    mMin = mMin % step > 0 ? mMin + (step - (mMin % step)) : mMin
    if (mMin > 59) {
      mMin = 0
      hMin++
    }

    mMax = range.max.minutes()
  }

  for (let h = hMin; h <= hMax; h++) {
    let m = h === hMin ? mMin : 0
    const max = h === hMax ? mMax : 59

    while (m <= max) {
      const label = valueToLabel(`${h}:${m}`, use12Hours, useSeconds)
      const id = inputToValue(label, use12Hours, useSeconds,) ?? `${h}:${m}`
      res.push({ id, label })

      m += step
    }
  }

  return res
}

export const dropdownPos = (value: string, step: number, range?: IMomentTimeRange) => {
  const stepsInHour = Math.floor(60 / step)

  const m = moment(value, "HH:mm")

  let position = m.hours() * stepsInHour + Math.floor(m.minutes() / step)

  if (range) {
    const minPos =
      range.min.hours() * stepsInHour +
      Math.ceil(range.min.minutes() / step)

    const maxPos =
      range.max.hours() * stepsInHour +
      Math.floor(range.max.minutes() / step)

    position = position > maxPos ? maxPos : position
    position = position - minPos
  }

  return position
}