import { getColor, getClearLabel, convertDataEpochToDate, DATE_FORMAT } from "../../utils"
import { getFilteredPositions, getUniqueDatasets, sortDataByPositions } from '../../utils/chartUtils'

export const composeVibrationLineChartDataOld = (data, graphColors, valueLabel = "Health:") => {
  const keys = Object.keys(data)

  if (!keys.length) return { datasets: [] }

  const filteredKeys = getFilteredPositions(keys, "smarthub")

  const datasets = filteredKeys.map(position => {
    const label = getClearLabel(position)
    const color = getColor(label, graphColors)

    const line = {
      label,
      position,
      backgroundColor: color,
      borderColor: color,
      borderWidth: 2,
      fill: false,
      showLine: true,
      data: []
    }

    data[position].forEach(el => {
      const y = el[1].toFixed(2)
      const x = convertDataEpochToDate(el[0], null, null, false) 

      line.data.push({
        y,
        x,
        displayValue: `${valueLabel} ${y}`,
        displayFooter: x.format(DATE_FORMAT)
      })
    })

    return line
  })

  const uniqueDatasets = getUniqueDatasets(datasets)
  
  sortDataByPositions(uniqueDatasets)

  return { datasets: uniqueDatasets }
}