import { IgetState } from "../types";
import { Dispatch } from 'redux'
import { TagsActionsTypes, TagsSortPayload } from "./types";
import api from "../../services/api";
import { store } from "../store";
import { getErrorObj } from "../../utils";
import { getStatusObj } from "../../utils/utils";


export const setTagsOffset = (offset: number) => ({
  type: TagsActionsTypes.SET_TAGS_OFFSET,
  payload: offset,
});

export const setTagsLoading = (isLoading: boolean) => ({
  type: TagsActionsTypes.LOADING_CARGOTAGS,
  payload: isLoading,
});
export const setPagedTags = (tags: Array<ICargoTag>) => ({
  type: TagsActionsTypes.SET_PAGED_CARGOTAGS,
  payload: tags,
});

export const setTagsError = (error: string) => ({
  type: TagsActionsTypes.SET_TAGS_ERROR,
  payload: error,
});
export const setTagsLimit = (limit: number) => ({
  type: TagsActionsTypes.SET_TAGS_LIMIT,
  payload: limit,
});
export const setTagsTotalCount = (count: number) => ({
  type: TagsActionsTypes.SET_TAGS_TOTAL_COUNT,
  payload: count,
});
export const setTagsSortOptions = (sort: TagsSortPayload) => {
  return {
    type: TagsActionsTypes.SET_TAGS_SORT_OPTIONS,
    payload: sort,
  };
};

export const setUnassignedTags = (unassigned:boolean) => ({
  type: TagsActionsTypes.UNASSIGNED_CARGOTAGS,
  payload: unassigned,
});

export const setCargoTagUploadMessage = (payload: any) => ({
  type: TagsActionsTypes.SET_CARGO_TAG_UPLOAD_MESSAGE,
  payload
})

export const setCargoTagsFilter = (payload: any) => ({
  type: TagsActionsTypes.SET_CARGO_TAGS_FILTER,
  payload
})


export const fetchCargotags = ()=>{
  return function(dispatch: Dispatch<any>, getState: IgetState){
    dispatch(setTagsLoading(true))
    const { limit, offset, sortOptions, unassignedTags, filter } = getState().cargoTags;
    return api.getCargotags(limit, offset, unassignedTags, sortOptions, filter)
    .then((result)=>{
      const {paged_data, count, error} = result
      if(error){
        dispatch(setTagsError(error))
        dispatch(setTagsLoading(false))
        return
      }
      dispatch(setTagsTotalCount(count))
      dispatch(setPagedTags(paged_data))
    })
    .catch((e)=>{
      dispatch(setTagsError(e))
    })
    .finally(()=>{
      dispatch(setTagsLoading(false))
    })
  }
}

export const assignCargoTags = (body:Array<{hi_id:number, customer_id:number}>)=>{
  return async (dispatch: Dispatch<any>, getState: IgetState) => {
    try {
        dispatch(setTagsLoading(true))
        const res = await api.assignCargoTagsToCustomer(body)
        const customerName = getState().common.customers.data.find((el: ICustomer) => el.id === body[0].customer_id)?.name
        let msgs = ""
        if(res.status === 200){
          res.result.forEach((el: any, i: number, arr: any)=>{
            if(i === arr.length-1){
              msgs += ` ${el.mac}`
            }
            else{
              msgs += ` ${el.mac},`
            }
          })

        dispatch(setCargoTagUploadMessage({ statusCode: 200, message: `Cargotags: ${msgs} succesfully assigned to customer ${customerName}`}))
        }
        dispatch(fetchCargotags())
        return res
    } catch (e) {
      if(e instanceof Error){
        dispatch(setCargoTagUploadMessage({ statusCode: 400, message: e.message}))
      }

    } finally {
      dispatch(setTagsLoading(false))
    }
}
}

export const migrateCargoTag = (body: {hi_id:number, customer_id:number, current_customer_id: number}) => {
  return async (dispatch: Dispatch<any>, getState: IgetState) => {
    if(body.customer_id === body.current_customer_id){
      dispatch(setCargoTagUploadMessage({ statusCode: 400, message: `Cargotag already belongs to specified customer`}))
      return
    }
      try {

          dispatch(setTagsLoading(true))
          const res = await api.migrateCargoTagsBetweenCustomers(body);
          const mac = res.result.row_to_json?.mac
          const customerName = getState().common.customers.data.find((el: ICustomer)=> el.id === body.customer_id).name
          dispatch(setCargoTagUploadMessage({ statusCode: res.status, message: `Cargotag ${mac} successfuly migrated to customer ${customerName}`}))
          dispatch(fetchCargotags())
          return res;
      } catch (e) {
        if(e instanceof Error){
          dispatch(setCargoTagUploadMessage({ statusCode: 400, message: e.message}))
        }
      } finally {
        dispatch(setTagsLoading(true))
      }
  }
}

export function postCustomersCargoTagsCsv(base64str: string, reassignAll?: boolean) {
  return async function (dispatch: Dispatch<any>, getState: IgetState) {
      try {
          dispatch(setTagsLoading(true));
          const selectedCustomersList = getState().common.customers.selectedCustomersList
          if (selectedCustomersList.length !== 1) return

          const response = await api.postCustomerCargoTagsCsv(base64str, selectedCustomersList[0].id, reassignAll)

          if (response.assigned_cargo_tags.length || response.incorrect_names.length || response.name_exists_cargo_tags.length) {
            return response
          } else {
            dispatch(fetchCargotags())
            const errorObj = getErrorObj({ statusCode: 200, message: "Cargo tags uploaded successfully" })
            return errorObj
          }
      } catch (err) {
          const errorObj = getErrorObj(err as any);
          return errorObj
      } finally {
          dispatch(setTagsLoading(false));
      }      
  }
}

export const putCargotag = (id: string, data: Pick<ICargoTag, "cargo_tag_name">) => {
  return async (dispatch: Dispatch<any>, getState: IgetState) => {
    try {
      const res = await api.putCargotag(id, data)

      const cargoTagsList = getState().cargoTags.pagedTags
      const newTagsList = [ ...cargoTagsList ]
      const idx = newTagsList.findIndex((el: ICargoTag) => el.hi_id === res.id)

      if (idx > -1) {
        newTagsList[idx] = { ...newTagsList[idx], cargo_tag_name: res.info.cargo_tag_name }
      }

      dispatch({ type: TagsActionsTypes.SET_PAGED_CARGOTAGS, payload: newTagsList })

      return getStatusObj()
    } catch (err) {
      console.log(err)
      const status = getStatusObj(err)

      return status
    }
  }
}

store.subscribe(() => {
  const lastAction = store.getState().lastAction;
  if (
    lastAction.type === TagsActionsTypes.SET_TAGS_OFFSET || 
    lastAction.type === TagsActionsTypes.SET_TAGS_SORT_OPTIONS 
     ) {
      store.dispatch(fetchCargotags())
  }
  if (
    lastAction.type === TagsActionsTypes.SET_TAGS_LIMIT || 
    lastAction.type === TagsActionsTypes.SET_CARGO_TAGS_FILTER
  ) {
    store.dispatch(setTagsOffset(0))
  }
})