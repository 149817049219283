import React from "react"

import './style.scss';

type Props = {
  label: string
  items?: {
    data: Array<{ icon?: any, label: string, value: number }>
  }
}

const AssetCard: React.FC<Props> = ({
  label,
  items
}) => {
  return (
    <div className="asset_card_container">
      <span className="asset_card_label">{label}</span>
      {items?.data.map(d => {
        return (
          <div key={d.label} className="asset_card_content">
            {d.icon && <div className="asset_card_content_icon_container">
              <img className="asset_card_content_icon" src={d.icon} alt={""} />
            </div>}
            <div>
              <div className="asset_card_content_label">{d.label}</div>
              <div className="asset_card_content_value"><b>{d.value}</b></div>
            </div>
          </div>
        )
      })}

    </div>
  )
}

export default AssetCard
