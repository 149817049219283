import React from "react";
import { connect } from "react-redux";
import SubNav from '../../components/SubNav'
import { postTresholds, setIsLoading } from "../../redux/tresholds/action";
import ThresholdsModule from "../../modules/Thresholds";
import CustomerSelector from "../../modules/CustomerSelector";

const Thresholds = ({
    locale, 
    isLoading, 
    postTresholds, 
    unitsOfMeasurement,
    common: { customerDefaults, systemDefaults, isDefaultsLoading, customers: { selectedCustomersList } }
}) => {
    return (
        <>
            <SubNav
                title="Thresholds"
                breadcrumbs={[
                    { label: "Home", path: "/dashboard" },
                    { label: "Thresholds" }
                ]}
            >
                <CustomerSelector/>
            </SubNav>
            <ThresholdsModule 
                width={12} 
                locale={locale} 
                vibrationTreshold={customerDefaults?.smarthub?.vibration_treshold || systemDefaults?.smarthub?.vibration_treshold} 
                temperatureTreshold={customerDefaults?.smarthub?.temperature_treshold || systemDefaults?.smarthub?.temperature_treshold} 
                criticalTemperatureTreshold={customerDefaults?.smarthub?.critical_temperature_treshold || systemDefaults?.smarthub?.critical_temperature_treshold}
                linePressureThreshold={customerDefaults?.line_pressure?.line_pressure_treshold || systemDefaults?.line_pressure?.line_pressure_treshold}
                overSpeedThreshold={customerDefaults?.asset_speed?.over_speed_threshold || systemDefaults?.asset_speed?.over_speed_threshold}
                criticalOverSpeedThreshold={customerDefaults?.asset_speed?.critical_over_speed_threshold || systemDefaults?.asset_speed?.critical_over_speed_threshold}
                onSave={postTresholds} 
                isLoading={isLoading || isDefaultsLoading} 
                unitsOfMeasurement={unitsOfMeasurement}
                customerId={selectedCustomersList.length === 1 ? selectedCustomersList[0].id : null}
            />
        </>
    );
};

const mapStateToProps = (state) => ({
    locale: state.whitelabel.locale,
    unitsOfMeasurement: state.auth.preferences.unitsOfMeasurement,
    common: state.common,
    ...state.tresholds,
});

const mapDispatchToProps = (dispatch) => ({
    postTresholds: (thresholds) => dispatch(postTresholds(thresholds)),
    setIsLoading: (loading) => dispatch(setIsLoading(loading)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Thresholds);
