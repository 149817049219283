import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Spinner } from 'reactstrap'

import Input, { SearchInputProps } from '../SearchInput'
import Label, { LabelItemType } from './Label'
import Selection from './Selection'
import List, { ListItemType } from './List'
import { SearchIcon } from '../Icon'

import './style.scss'


interface Props extends SearchInputProps {
    isSingleSelection?: boolean
    isLoading?: boolean
    menuItems: Array<ListItemType>
    clearSelection?: number
    onLabelSelectionChange: (selectedItems: Array<ListItemType>) => void
    onSearch?: (value: string) => void
}

const LabelSelector: React.FC<Props> = ({
    isSingleSelection,
    isLoading,
    placeholder = "",
    wrapperStyle,
    wrapperClassName,
    menuItems,
    clearSelection,
    onLabelSelectionChange,
    onSearch,
    value,
    ...props
}) => {
    const menu = useRef<HTMLDivElement>(null)
    const [isOpen, setIsOpen] = useState(false)
    const [selected, setSelected] = useState<Array<ListItemType>>([])
    const [searchValue, setSearchValue] = useState("")

    useEffect(() => {
        const handleClickOutside = (e: MouseEvent) => {
            if (menu.current && !menu.current.contains(e.target as Node)) {
                setIsOpen(false)
            }
        }
    
        if (isOpen) document.addEventListener('click', handleClickOutside, true)
        return () => {
          document.removeEventListener('click', handleClickOutside, true)
        }
    }, [isOpen])

    useEffect(() => {
        if (!clearSelection) return

        setSearchValue("")
        setSelected([])
    }, [clearSelection])


    const onItemSelect = useCallback((el: ListItemType) => {
        let newList = selected

        if (isSingleSelection && selected.length > 0) {
            if (!selected.find(item => item.id === el.id)) {
                newList = [ el ]
            }
        } else if (!selected.find(item => item.id === el.id)) {
            newList = [ ...selected, el ]
        }

        if (newList !== selected) {
            setSelected(newList)
            onLabelSelectionChange(newList)
            setSearchValue("")
        }
    }, [selected, isSingleSelection, onLabelSelectionChange])

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value)
        onSearch && onSearch(e.target.value)

        if (isSingleSelection && selected.length === 1 && selected[0].isSingleSelection) {
            handleClearSelection()
        }
    }

    const handleClearSelection = useCallback(() => {
        setSelected([])
        onLabelSelectionChange([])
    }, [onLabelSelectionChange])

    const handleLabelDelete = useCallback((el: LabelItemType) => {
        const newSelection = selected.filter(i => i.id !== el.id)

        setSelected(newSelection)
        onLabelSelectionChange(newSelection)
    }, [selected, onLabelSelectionChange])

    return (
        <div className='label-selector'>
            <div 
                className={`label-selector__displayer ${isOpen && "menu-open"} ${wrapperClassName}`}
                style={wrapperStyle}
                onClick={() => setIsOpen(true)}
            >
                <div className='absolute d-flex'>
                    <div className='mr-1 d-flex align-items-center'>
                        <SearchIcon height={20} width={20} color='black' />
                    </div>
                    
                    {!isOpen && !selected.length ? placeholder : (
                        selected.map((el, i) => i <= 2 && (
                            <Label
                                key={el.id}
                                className={`label__btn ${!el.showFull && "limited_size"}`}
                                text={el.label}
                                onDelete={() => handleLabelDelete(el)}
                            />
                        ))
                    )}

                    {selected.length > 3 && (
                        <div className='selected-label__count'>
                            <span>+{selected.length - 3} more</span>
                        </div>
                    )}
                </div>
            </div>
        
            {isOpen && (
                <div 
                    ref={menu}
                    className='label-selector__menu'
                >
                    <div className='menu__search'>
                        <Input 
                            placeholder={placeholder} 
                            wrapperStyle={wrapperStyle} 
                            wrapperClassName={wrapperClassName} 
                            autoFocus
                            endElement={
                                <span
                                    className='search-btn__finish'
                                    onClick={() => setIsOpen(false)}
                                >
                                    Done
                                </span>
                            }
                            onChange={onInputChange}
                            value={value ?? searchValue}
                            {...props} 
                        />
                    </div>

                    <Selection
                        items={selected}
                        onRemoveLabel={handleLabelDelete}
                        onClearSelection={handleClearSelection}
                    />

                    <div className='menu_list'>
                        {(searchValue || value) && (
                            <label>
                                {isLoading || menuItems.length
                                    ? "Suggestions"
                                    : "Nothing found"}
                            </label>
                        )}

                        {isLoading && <Spinner className="ml-2" size="sm" animation="grow" color="dark" />}
                        
                        <List
                            items={menuItems}
                            onClick={onItemSelect}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default React.memo(LabelSelector)
