import React from 'react'
import RivataModule from '../../components/RivataModule'
import RivataLoader from '../../components/RivataLoader'
import StatusAlert from '../../components/StatusAlert'
import AxleLoadCalibrationForm from '../../components/AxleLoadCalibrationForm'
import { Typeahead } from 'react-bootstrap-typeahead'
import { Col } from 'reactstrap'
import 'react-bootstrap-typeahead/css/Typeahead.css'

const CalibrateAxleLoadModule = ({
    isSuperAdmin,
    subscriptions,
    isDefaultsLoading,
    axleLoadAssets: { isLoading, data },
    locale = {},
    onSubmit,
    setSelectedAsset,
    selectedAsset,
    setSelectedUnitsOfMeasurement,
    selectedUnitsOfMeasurement
}) => {
    return (
        <RivataModule
            title="Calibrate Axle Load"
            locale={locale}
            marginTop={0}
            filters={
                <Col md="4">
                    <Typeahead
                        clearButton
                        id="axle_load_assets_search"
                        selected={selectedAsset ? [selectedAsset] : []}
                        onChange={(selected) => setSelectedAsset(selected[0])}
                        labelKey={(option) => option.vin && option.name ? `${option.vin} (${option.name})` : ""}
                        options={data}
                        placeholder={"Select VIN"}
                        disabled={isLoading}
                    />
                </Col>
            }
        >
            {isLoading && <RivataLoader />}
            {!isLoading && !data.length ? (
                <StatusAlert color="success" customText="No Data" />
            ) : (
                <AxleLoadCalibrationForm 
                    data={data}
                    unitsOfMeasure={selectedUnitsOfMeasurement}
                    onChangeUnitsOfMeasure={setSelectedUnitsOfMeasurement}
                    onSubmit={onSubmit}
                    selectedAsset={selectedAsset}
                />
            )}
        </RivataModule>
    )
}

export default CalibrateAxleLoadModule