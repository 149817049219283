import React from "react";
import { SearchItemType } from "../../../components/ItemsSearch";
import AssetsSelect from "./AssetsSelect";
import DateTimePicker from "../../../componentsV2/DateTimePicker";
import { CalendarTypes } from "../../../enums";
import moment from "moment";

interface ITripReportFormProps {
  disabled: boolean;
  dateFrom: string;
  dateTo: string;
  minDate: Date;
  onDateRangeSelect: (from: Date, to: Date) => void;
  setVinList: React.Dispatch<React.SetStateAction<Array<Object>>>;
  selectedAssetsItems: Array<SearchItemType>;
}

const DailyMileageReport: React.FC<ITripReportFormProps> = ({
  disabled,
  dateFrom,
  dateTo,
  minDate,
  onDateRangeSelect,
  setVinList,
  selectedAssetsItems,
}) => {
  return (
    <>
      <div className="mb-2">Select Date Range</div>
      <DateTimePicker
        defaultMode={CalendarTypes.DateRange}
        dateFrom={dateFrom}
        dateTo={dateTo}
        onDateRangeSelect={onDateRangeSelect}
        minDate={minDate}
        maxDate={moment().toDate()}
        disabled={disabled}
      />

      <AssetsSelect
        disabled={disabled}
        setVinList={setVinList}
        selectedItems={selectedAssetsItems}
      />
    </>
  );
};

export default DailyMileageReport;
