import React, { useState } from "react";
import classes from "./styles.module.scss";
import JSONInput from "react-json-editor-ajrm";
import TimeInput from "../TimeInput";
import JSONlocale from "react-json-editor-ajrm/locale/en";
import { formatTimestamp } from "./utils";
import {
  Button,
  Pagination,
  PaginationLink,
  PaginationItem,
  Collapse,
} from "reactstrap";
import LimitDropdown from "../../components/LimitDropdown";

const JsonView = ({ collapse, data, i, setCollapse }) => {
  return (
    <Collapse isOpen={collapse === i} className={classes.json}>
      <JSONInput
        id="json"
        locale={JSONlocale}
        placeholder={data ? data : {}}
        width="100%"
        height="100%"
        viewOnly={true}
        confirmGood={false}
      />
      <Button
        color="secondary"
        size="md"
        className={classes.btnJson}
        onClick={() => setCollapse()}
      >
        {"Close"}
      </Button>
    </Collapse>
  );
};

export const GatewayLogsTranslator = ({ logs }) => {
  const [collapse, setCollapse] = useState();
  return logs.map((log, i) => {
    log.timestamp = formatTimestamp(log.timestamp);
    log.created = formatTimestamp(log.created);
    return (
      <li key={log.timestamp + i} className={classes.logItem}>
        <div className={classes.logInfo}>
          {Object.keys(log)
            .filter((key) => {
              return key !== "data";
            })
            .map((key) => {
              return <div className={classes.gateLogInfo}>{log[key]}</div>;
            })}
          <div className={classes.gateLogInfo}>
            <Button
              outline
              color="secondary"
              size="sm"
              onClick={() => setCollapse(i)}
            >
              {"JSON view"}
            </Button>
          </div>
        </div>
        <JsonView
          collapse={collapse}
          i={i}
          data={log.data}
          setCollapse={setCollapse}
        />
      </li>
    );
  });
};

export const GatewayLogsRivata = ({ logs }) => {
  const [collapse, setCollapse] = useState();
  return logs.map((log, i) => {
    log.timestamp = formatTimestamp(log.timestamp);
    log.created = formatTimestamp(log.created);
    return (
      <li key={log.timestamp + i} className={classes.logItem}>
        <div className={classes.logInfo}>
          <div className={classes.gateLogInfo}>{log["timestamp"]}</div>
          <div className={classes.gateLogInfo}>{log["created"]}</div>
          <div className={classes.gateLogSource}>
            <span>
              <b>acmqid: </b>
              {`${log["source"]["acmqid"]}`}
            </span>
            <span>
              <b>sfn_id: </b>
              {log["source"]["sfn_id"]}
            </span>
          </div>
          <div className={classes.gateLogInfo}>
            <Button
              outline
              color="secondary"
              size="sm"
              onClick={() => setCollapse(i)}
            >
              {"JSON view"}
            </Button>
          </div>
        </div>
        <JsonView
          collapse={collapse}
          i={i}
          data={log.data}
          setCollapse={setCollapse}
        />
      </li>
    );
  });
};

export const GatewayLogsPagination = ({
  offset,
  logsCount,
  logsLength,
  type,
  onGatewayLogsPageChange,
  setType,
}) => {
  const limitDropdownVariant = [
    { item: "Gateway translator" },
    { item: "Gateway rivata" },
  ];
  return (
    <>
      <Pagination className={classes.gateLogsPagination}>
        <PaginationItem disabled={offset === 0}>
          <PaginationLink
            previous
            onClick={() => onGatewayLogsPageChange(offset - logsCount)}
          />
        </PaginationItem>
        <PaginationItem disabled={logsLength < 20}>
          <PaginationLink
            next
            onClick={() => onGatewayLogsPageChange(offset + logsCount)}
          />
        </PaginationItem>
      </Pagination>
      <LimitDropdown
        setPageLimit={(v) => setType(v)}
        pageLimit={type[0].toUpperCase() + type.slice(1).replace("_", " ")}
        limitDropdownItems={limitDropdownVariant}
      />
    </>
  );
};

export const TimePickerGates = ({ t, setTime, setIsPicker }) => {
  
  const onTimeSet = (value, dir) => {
    setTime(value, dir);
  };
  return (
    <div className={classes.timePicker}>
      <label className={classes.timeLabel}>From:</label>
      <TimeInput
        value={t.from}
        setValue={(value) => onTimeSet(value, "from")}
        onDropdownOpen={() => setIsPicker(true)}
      />
      <label className={classes.timeLabel}>To:</label>
      <TimeInput
        value={t.to}
        setValue={(value) => onTimeSet(value, "to")}
        onDropdownOpen={() => setIsPicker(true)}
      />
    </div>
  );
};

export const TranslatorHeader = () => {
  const translatorHeader = [
    "Server timestamp",
    "Created",
    "Active message ID",
    "Data",
  ].map((el) => {
    return <li className={classes.translatorHeaderItem}>{el}</li>;
  });
  return <ul className={classes.translatorHeader}>{translatorHeader}</ul>;
};

export const RivataHeader = () => {
  const translatorHeader = [
    "Server timestamp",
    "Created",
    "Source",
    "Data",
  ].map((el) => {
    return <li className={classes.translatorHeaderItem}>{el}</li>;
  });
  return <ul className={classes.translatorHeader}>{translatorHeader}</ul>;
};
