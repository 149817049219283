import React, { useEffect } from 'react'
import { Nav, NavItem, NavLink } from 'reactstrap'

interface Props {
  isSuperAdmin: boolean
  activeTab: string
  toggleTab: (tab: string) => void
  hasCargoTagSubscription: boolean
}

const ReportTabs: React.FC<Props> = ({
  isSuperAdmin,
  activeTab,
  toggleTab,
  hasCargoTagSubscription,
}) => {
  useEffect(() => {
    if (!isSuperAdmin) {
      toggleTab('reports')
    }
  }, [isSuperAdmin, toggleTab])

  return (
    <Nav tabs className='ml-3 mr-3'>
      <NavItem className='tab-item'>
        <NavLink
          className={`assets_tab ${
            activeTab === 'reports' ? 'active-table_tab' : ''
          }`}
          onClick={() => {
            toggleTab('reports')
          }}
        >
          Reports
        </NavLink>
      </NavItem>
      {hasCargoTagSubscription ? (
        <NavItem className='tab-item'>
          <NavLink
            className={`assets_tab ${
              activeTab === 'tagReports' ? 'active-table_tab' : ''
            }`}
            onClick={() => {
              toggleTab('tagReports')
            }}
          >
            Cargo Tag Reports
          </NavLink>
        </NavItem>
      ) : null}
      {isSuperAdmin ? (
        <NavItem className='tab-item'>
          <NavLink
            className={`assets_tab ${
              activeTab === 'internalScheduledReports' ? 'active-table_tab' : ''
            }`}
            onClick={() => {
              toggleTab('internalScheduledReports')
            }}
          >
            Internal Scheduled Reports
          </NavLink>
        </NavItem>
      ) : null}
      <NavItem className='tab-item'>
        <NavLink
          className={`assets_tab ${
            activeTab === 'scheduledReports' ? 'active-table_tab' : ''
          }`}
          onClick={() => {
            toggleTab('scheduledReports')
          }}
        >
          Scheduled Reports
        </NavLink>
      </NavItem>
      <NavItem className='tab-item'>
        <NavLink
          className={`assets_tab ${
            activeTab === 'reportsHistory' ? 'active-table_tab' : ''
          }`}
          onClick={() => {
            toggleTab('reportsHistory')
          }}
        >
          Reports History
        </NavLink>
      </NavItem>
    </Nav>
  )
}

export default React.memo(ReportTabs)
