import React, { useState } from 'react'
import RivataFormField from '../../components/RivataFormField'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { Button, Row, Col, Form } from 'reactstrap'
import './style.scss'

const RequestAccessForm = ({ onSubmit }) => {
    const [ email, setEmail ] = useState("")

    const schema = Yup.object().shape({
        first_name: Yup.string().required("This field is required"),
        last_name: Yup.string().required("This field is required"),
        business_email: Yup.string().required("This field is required").email("Enter valid email"),
        confirm_business_email: Yup.string().required("This field is required").email("Enter valid email").equals([email], "Email is not equal"),
        business_phone: Yup.string().required("This field is required"),
        company_name: Yup.string().required("This field is required"),
        vin: Yup.string().required("This field is required"),
    })

    const getData = (values) => {
        const data = {
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.business_email,
            phone: values.business_phone,
            company_name: values.company_name,
            vin: values.vin,
            environment_url: window.location.origin
        }

        return data
    }

    return (
        <div className="request-access-form" >
            <div className="w-100">
                <h1 className="request-access-form__title mb-4">WELCOME! LET'S GET STARTED</h1>
                <Formik
                    enableReinitialize={true}
                    validationSchema={schema}
                    initialValues={{
                        first_name: "",
                        last_name: "",
                        business_email: "",
                        confirm_business_email: "",
                        business_phone: "",
                        company_name: "",
                        vin: "",
                    }}
                    onSubmit={(values, ...args) => {
                        const { resetForm } = args[0]
                        onSubmit(getData(values), resetForm)
                    }}
                    validate={(values) => {
                        if (values.business_email !== email) {
                            setEmail(values.business_email)
                        }
                    }}
                >
                    {(props) => {
                        const { submitForm } = props

                        return (
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault()
                                    submitForm()
                                }}
                            >
                                <Row>
                                    <Col md="12">
                                        <h3>CONTACT INFORMATION</h3>
                                    </Col>
                                </Row>
                                <Row className="d-flex">
                                    <RivataFormField className="request-access-form__input" md="6" size="12" name="first_name" placeholder="First Name"/>
                                    <RivataFormField className="request-access-form__input" md="6" size="12" name="last_name" placeholder="Last Name"/>
                                </Row>
                                <Row>
                                    <RivataFormField className="request-access-form__input" md="12" size="12" name="business_email" placeholder="Business Email"/>
                                </Row>
                                <Row>
                                    <RivataFormField className="request-access-form__input" md="12" size="12" name="confirm_business_email" placeholder="Confirm Business Email"/>
                                </Row>
                                <Row>
                                    <RivataFormField className="request-access-form__input" md="12" size="12" name="business_phone" placeholder="Business Phone Number"/>
                                </Row>
                                <Row>
                                    <Col md="12 mt-2">
                                        <h3>COMPANY INFORMATION</h3>
                                    </Col>
                                </Row>
                                <Row>
                                    <RivataFormField className="request-access-form__input" md="12" size="12" name="company_name" placeholder="Company Name"/>
                                </Row>
                                <Row>
                                    <RivataFormField className="request-access-form__input" md="12" size="12" name="vin" placeholder="Enter one VIN under subscription"/>
                                </Row>
                                <Row className="mt-2">
                                    <Col md="12" className="d-flex justify-content-end">
                                        <Button
                                            type="submit"
                                        >
                                            Submit
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        </div>
    )
}

export default RequestAccessForm