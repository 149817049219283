import React from 'react'
import * as Yup from "yup"
import { Row, Col } from "reactstrap"

import { FormField } from '../../../components/CreateModal'
import CreateModal from '../../../components/CreateModal/children'


interface Props {
    isOpen: boolean
    isInvalid: boolean
    isAlreadyExist: boolean
    locale: ILocale
    onInputFocus: () => void
    onValidate: (data: any) => void
    onClose: () => void
}

const initialValues = { vin: "" }

const schema = Yup.object().shape({
    vin: Yup.string().trim().uppercase().required("Required"),
})

const ValidateVinModal: React.FC<Props> = ({
    isOpen,
    isInvalid,
    isAlreadyExist,
    locale,
    onInputFocus,
    onValidate,
    onClose
}) => {
    return (
        <CreateModal
            open={isOpen}
            header="Create Asset"
            createBtnLabel={isInvalid && !isAlreadyExist ? "Proceed without valid VIN" : "Continue"}
            footerBtnDisabled={isAlreadyExist}
            locale={locale}
            initialValues={initialValues}
            schema={schema}
            onCreate={onValidate}
            onClose={onClose}
        >
            <Row>
                <FormField
                    md="12"
                    name="vin"
                    label="VIN"
                    onFocus={onInputFocus}
                />

                {isInvalid && (
                    <div className='col-12'>
                        <Col
                            md="12"
                            className="alert alert-warning"
                        >
                            {isAlreadyExist
                                ? "Asset with this VIN already exist in our system"
                                : "VIN is not fully recognized in NHTSA database, please check the asset data before proceeding"
                            }
                        </Col>
                    </div>
                )}
            </Row>
        </CreateModal>
    )
}

export default ValidateVinModal
