import { useCallback, useEffect, useMemo, useState } from 'react'
import { columnsSelectorCheckboxProps } from '../RivataTable/useColumnsSelector'
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from '../../utils/localStorageUtils'

const TpmsColumnsOptions = [
  { id: 'customer_name', label: 'Customer', isDraggable: true },
  { id: 'asset_name', label: 'Asset Name', isDraggable: true },
  { id: 'vin', label: 'VIN', isDraggable: true },
  { id: 'asset_groups', label: 'Asset Groups', isDraggable: true },
  { id: 'geofences', label: 'Geofence', isDraggable: true },
  {
    id: 'latest_tpms_update_at_epoch',
    label: 'Latest TPMS Update',
    isDraggable: true,
  },
  { id: 'tpms_profile', label: 'TPMS Profile', isDraggable: true },
]

const defaultColumnsToShow = [
  'asset_name',
  'latest_tpms_update_at_epoch',
  'tpms_profile',
]

const alwaysVisibleColumns: string[] = []

const localeStorageKey = 'dashboard.TpmsTable.columns'

export const useTpmsColumns = (userName: string) => {
  const [columnsToShow, setColumnsToShow] = useState(defaultColumnsToShow)
  const [columnsSort, setColumnsSort] = useState(defaultColumnsToShow)

  useEffect(() => {
    const userKey = `${localeStorageKey}.${userName}`
    const localColumns = getLocalStorageItem(userKey)?.split(',')

    if (localColumns) setColumnsToShow(localColumns)
    else {
      //fall back to initialy saved columns
      const localColumns = localStorage.getItem(localeStorageKey)

      if (localColumns) {
        setLocalStorageItem(userKey, localColumns)
        removeLocalStorageItem(localeStorageKey)
        setColumnsToShow(localColumns?.split(','))
      }
    }

    const orderKey = `${localeStorageKey}.order`
    const userOrderKey = `${orderKey}.${userName}`
    const localSortedColumns = getLocalStorageItem(userOrderKey)?.split(',')

    if (localSortedColumns) setColumnsSort(localSortedColumns)
    else {
      //fall back to initialy saved columns order
      const localColumns = localStorage.getItem(orderKey)

      if (localColumns) {
        setLocalStorageItem(userOrderKey, localColumns)
        removeLocalStorageItem(orderKey)
        setColumnsSort(localColumns?.split(','))
      }
    }
  }, [userName])

  const onCheckboxClick = useCallback(
    (columnId) => {
      setColumnsToShow((columnsToShow) => {
        const res = [...columnsToShow]
        const idx = res.findIndex((id) => id === columnId)

        if (idx >= 0) res.splice(idx, 1)
        else res.push(columnId)

        setLocalStorageItem(`${localeStorageKey}.${userName}`, res.join(','))

        return res
      })
      setColumnsSort([])
      removeLocalStorageItem(`${localeStorageKey}.order.${userName}`)
    },
    [userName],
  )

  const checkboxesProps = useMemo(() => {
    return TpmsColumnsOptions.map((column) => {
      const onClick = () => onCheckboxClick(column.id)
      const disabled = !!alwaysVisibleColumns.includes(column.id)
      const checked = disabled || columnsToShow.includes(column.id)

      return {
        columnId: column.id,
        label: column.label,
        onClick,
        disabled,
        checked,
      } as columnsSelectorCheckboxProps
    })
  }, [columnsToShow, onCheckboxClick])

  const onSetDefault = useCallback(() => {
    setColumnsToShow(defaultColumnsToShow)
    removeLocalStorageItem(`${localeStorageKey}.${userName}`)
    setColumnsSort([])
    removeLocalStorageItem(`${localeStorageKey}.order.${userName}`)
  }, [userName])
  const columnsModified = useMemo(() => {
    if (!columnsToShow) return defaultColumnsToShow
    const colsToShow = TpmsColumnsOptions.filter((column) =>
      columnsToShow.includes(column.id),
    )
    const colsFinal: any[] = []
    if (columnsSort && columnsSort.length > 0) {
      columnsSort.forEach((id) => {
        const col = TpmsColumnsOptions.find((c) => c.id === id)
        if (col) {
          colsFinal.push(col)
        }
      })
      //in case if we add new columns, but already have columns sort order saved, let's add those columns
      colsToShow.forEach((col) => {
        if (!columnsSort.includes(col.id)) {
          colsFinal.push(col)
        }
      })
    } else {
      colsToShow.forEach((col) => colsFinal.push(col))
    }
    return colsFinal.map((c) => {
      return c.id
    })
  }, [columnsToShow, columnsSort])
  const setColumnsOrder = useCallback(
    (data: any[]) => {
      if (data && data.length > 0) {
        const columnsOrder = data.map((d: any) => {
          return d.id
        })
        setColumnsSort(columnsOrder)
        setLocalStorageItem(
          `${localeStorageKey}.order.${userName}`,
          columnsOrder.join(','),
        )
      }
    },
    [userName],
  )
  return [columnsModified, checkboxesProps, onSetDefault, setColumnsOrder]
}
