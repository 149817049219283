import React from "react"
import { Link } from "react-router-dom"
import { Location } from 'history'
import { INavRoute } from "../RivataPage/navRoutes"
import { useTypedSelector } from "../../hooks/useTypedSelector"
import ColoredImg from "../ColoredImg"

export interface Props {
    navRoutes: Array<INavRoute>
    location: Location
}

const NavList: React.FC<Props> = ({ navRoutes, location }) => {
    const themeColors = useTypedSelector(state => state.whitelabel.themeColors)
    const iconColor = themeColors.find((el: any) => el.id === "Secondary1").color

    const path = location.pathname.split("/")

    return (
        <>
            {navRoutes.map((route, i) => {
                const key = route.name + i
                const curentNavPathPart = route.path ? route.path.split("/")[1] : ""
                
                if (route.navlabel) {
                    return (
                        <li className="nav-small-cap" key={key}>
                            <span className="hide-menu">{route.name}</span>
                        </li>
                    )
                } else if (route.isCustomIcon && route.path) {
                    return (
                        <li
                            key={key}
                            className={"sidebar-item " + (path.includes(curentNavPathPart) || (path[1] === "" && route.name === "Home") ? "active active-pro" : "")}
                        >
                            <Link
                                to={route.path}
                                className="sidebar-link"
                            >
                                <div className="nav-icon__wrapper">
                                    <ColoredImg filterColor={iconColor} src={route.src} alt="nav_icon" style={route.customStyles ? route.customStyles : { width: "18px" }} />
                                </div>
                                <span className="hide-menu">{route.name}</span>
                            </Link>
                        </li>
                    )
                }
                
                return (
                    <li
                        key={key}
                        className={"sidebar-item " + (path.includes(curentNavPathPart) ? "active active-pro" : "")}
                    >
                        <Link
                            to={route.path || "/"}
                            className="sidebar-link"
                        >
                            <i className={route.icon} />
                            <span className="hide-menu">{route.name}</span>
                        </Link>
                    </li>
                )
            })}
        </>
    )
}

export default React.memo(NavList)