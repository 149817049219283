import React from "react"
import { hexToCSSFilter } from "hex-to-css-filter"

type Props = {
  filterColor: string
} & React.ImgHTMLAttributes<HTMLImageElement>

const ColoredImg: React.FC<Props> = ({
  filterColor,
  ...props
}) => {
  const filter = `brightness(0) saturate(100%) ${hexToCSSFilter(filterColor).filter.replace(";", "")}`

  return (
    <img
      alt=""
      {...props}
      style={{
        filter,
        ...props.style
      }}
    />
  )
}

export default React.memo(ColoredImg)
