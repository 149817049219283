import React, { useCallback, useEffect, useMemo, useState } from 'react'

import NotificationsModule from '../NotificationsModule'
import DetailsGrid from '../../components/RivataGrid/DetailsGrid'
import RivataLoader from '../../components/RivataLoader'
import RivataModule from '../../components/RivataModule'
import StatusAlert from '../../components/StatusAlert'
import NotificationsGridFilters from './components/NotificationsGridFilters'
import DeleteNotificationProfileModal from './modals/deleteModal'

import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { useColumns, useRows } from './hooks'

import './style.scss'
import { getCurrentEpoch } from '../../utils'
import RivataToggle from '../../components/RivataToggle'
import PseudoBtn from '../../components/CustomButtons/fakeBnt'

const NotificationsGrid: React.FC = () => {
  const {
    manageNotifications: { isLoading, data, offset, status, users, filter },
    locale,
    selectedCustomersList,
    subscriptions,
    isSuperAdmin,
  } = useTypedSelector((state) => ({
    manageNotifications: state.manageNotifications,
    locale: state.whitelabel.locale,
    selectedCustomersList: state.common.customers.selectedCustomersList,
    subscriptions: state.common.customerDefaults.subscriptions,
    isSuperAdmin: state.auth.isSuperAdmin,
  }))

  const {
    fetchNotificationProfiles,
    fetchUsersShortDataFormat,
    setManageNotificationsProfilesFilter,
    deleteNotificationProfile,
  } = useActions()

  const [showCreateForm, setShowCreateForm] = useState(false)

  const [deleteId, setDeleteId] = useState<number | null>(null)
  const [profileToEdit, setProfileToEdit] =
    useState<INotificationProfile | null>(null)
  const [selectedOption, setSelectedOption] = useState('default')
  const [filteredData, setFilteredData] = useState(data)

  useEffect(() => {
    if (selectedOption === 'default') {
      setFilteredData(data.filter((c) => c.name.startsWith('system_profile')))
    } else {
      setFilteredData(data.filter((c) => !c.name.startsWith('system_profile')))
    }
  }, [data, selectedOption])

  useEffect(() => {
    fetchUsersShortDataFormat()
  }, [fetchUsersShortDataFormat, selectedCustomersList])

  useEffect(() => {
    fetchNotificationProfiles()
  }, [fetchNotificationProfiles, offset, selectedCustomersList, filter])

  useEffect(() => {
    setProfileToEdit(null) // reset edit form after customer is changed
  }, [selectedCustomersList])

  // setProfilesUsers, setProfileToClearUsers,
  const columns = useColumns(
    filteredData,
    users,
    setDeleteId,
    setProfileToEdit,
    selectedOption === 'custom',
  )
  const rows = useRows(filteredData, users)

  const onCloseDeleteModal = useCallback(
    (isClosedBySuccess?: boolean) => {
      if (isClosedBySuccess) fetchNotificationProfiles() // refresh table after successful delete
      if (profileToEdit?.id === deleteId) setProfileToEdit(null)

      setDeleteId(null)
    },
    [profileToEdit, deleteId, fetchNotificationProfiles],
  )

  const gridHeight = useMemo(
    () => (rows.length > 22 ? '800px' : `${rows.length * 45 + 47}px`),
    [rows],
  )

  const isActiveSubscription = useMemo(() => {
    return (
      isSuperAdmin ||
      subscriptions?.TIRE_SENSOR?.valid_to >= getCurrentEpoch() ||
      subscriptions?.LINE_PRESSURE?.valid_to >= getCurrentEpoch() ||
      subscriptions?.GATEWAY?.valid_to >= getCurrentEpoch() ||
      subscriptions?.HUB_SENSOR?.valid_to >= getCurrentEpoch() ||
      subscriptions?.AXLE_LOAD?.valid_to >= getCurrentEpoch()
    )
  }, [subscriptions, isSuperAdmin])

  return (
    <RivataModule
      title='Notifications Profiles'
      locale={locale}
      filters={
        <>
          <RivataToggle
            item1={{ id: 'default', label: `Show Default Profiles` }}
            item2={{ id: 'custom', label: `Show Custom Profiles` }}
            selectedId={selectedOption}
            onToggle={(e) => {
              setSelectedOption(e.id.toString())
            }}
          />
          <PseudoBtn
            icon='fa fa-plus-circle'
            label={'Create Profile'}
            active={selectedOption === 'custom'}
            onClickHandler={() => setShowCreateForm(true)}
            message={'Only custom profiles can be created'}
          />
        </>
      }
    >
      {!isActiveSubscription ? (
        <>
          {isLoading ? (
            <RivataLoader />
          ) : (
            <StatusAlert
              customText='No Active Subscription Found'
              customSubText={
                selectedCustomersList[0]?.children?.length > 0
                  ? 'Try selecting a different account'
                  : ''
              }
              color='success'
              statusCode={undefined}
              statusText={undefined}
            />
          )}
        </>
      ) : (
        <>
          {(profileToEdit || showCreateForm) && ( // create/update form
            <div className='mb-4'>
              <NotificationsModule
                locale={locale}
                selectedCustomersList={selectedCustomersList}
                users={users}
                profileToEdit={profileToEdit}
                showCreateForm={showCreateForm}
                setShowCreateForm={setShowCreateForm}
                setProfileToEdit={setProfileToEdit}
                fetchNotificationProfiles={fetchNotificationProfiles}
                subscriptions={subscriptions}
                isSuperAdmin={isSuperAdmin}
              />

              <hr />
            </div>
          )}

          <NotificationsGridFilters
            setManageNotificationsProfilesFilter={
              setManageNotificationsProfilesFilter
            }
          />

          {status.statusCode >= 400 ? (
            <StatusAlert
              statusCode={status.statusCode}
              statusText={status.message}
              customText={undefined}
            />
          ) : rows.length ? (
            <DetailsGrid
              columns={columns}
              rows={rows}
              height={gridHeight}
              selectedRowId={profileToEdit?.id}
            />
          ) : (
            <StatusAlert
              customText={'No Data'}
              color='success'
              statusCode={undefined}
              statusText={undefined}
            />
          )}

          {deleteId && (
            <DeleteNotificationProfileModal
              locale={locale}
              profile={filteredData.find((el) => el.id === deleteId) || null}
              onClose={onCloseDeleteModal}
              onDelete={deleteNotificationProfile}
            />
          )}
        </>
      )}
    </RivataModule>
  )
}

export default NotificationsGrid
