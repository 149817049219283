import { SetLoading, SetNumber } from '../types'
interface IAssetBulkUploadSortOptions {
  column?: string | null
  direction?: string | null
}
interface IAssetBulkUploadHistory {
  data: Array<any>
  isLoading: boolean
  offset: number
  limit: number
  totalCount: number
  sortOptions: IAssetBulkUploadSortOptions
}
export interface IAssetBulkUploadReducer {
  uploadingAssetsCsv: boolean
  history: IAssetBulkUploadHistory
}

export enum AssetBulkUploadActionTypes {
  UPLOADING_ASSETS_BULK_UPLOAD_FILE = 'UPLOADING_ASSETS_BULK_UPLOAD_FILE',
  LOADING_BULK_UPLOAD_HISTORY = 'LOADING_BULK_UPLOAD_HISTORY',
  SET_BULK_UPLOAD_HISTORY = 'SET_BULK_UPLOAD_HISTORY',
  SET_BULK_UPLOAD_HISTORY_OFFSET = 'SET_BULK_UPLOAD_HISTORY_OFFSET',
  SET_BULK_UPLOAD_HISTORY_LIMIT = 'SET_BULK_UPLOAD_HISTORY_LIMIT',
  SET_BULK_UPLOAD_HISTORY_TOTAL_COUNT = 'SET_BULK_UPLOAD_HISTORY_TOTAL_COUNT',
  SET_BULK_UPLOAD_HISTORY_SORT_OPTIONS = 'SET_BULK_UPLOAD_HISTORY_SORT_OPTIONS',
}

type LoadingAssetBulkUploadDataType = SetLoading<
  | AssetBulkUploadActionTypes.UPLOADING_ASSETS_BULK_UPLOAD_FILE
  | AssetBulkUploadActionTypes.LOADING_BULK_UPLOAD_HISTORY
>
interface ISetAssetBulkUploadHistoryData {
  type: AssetBulkUploadActionTypes.SET_BULK_UPLOAD_HISTORY
  payload: Array<any>
}
type SetNumberAssetBulkUploadDataType = SetNumber<
  | AssetBulkUploadActionTypes.SET_BULK_UPLOAD_HISTORY_LIMIT
  | AssetBulkUploadActionTypes.SET_BULK_UPLOAD_HISTORY_OFFSET
  | AssetBulkUploadActionTypes.SET_BULK_UPLOAD_HISTORY_TOTAL_COUNT
>
interface ISetAssetBulkUploadSortOptionsType {
  type: AssetBulkUploadActionTypes.SET_BULK_UPLOAD_HISTORY_SORT_OPTIONS
  payload: Array<any>
}
export type AssetBulkUploadActionsType =
  | LoadingAssetBulkUploadDataType
  | ISetAssetBulkUploadHistoryData
  | SetNumberAssetBulkUploadDataType
  | ISetAssetBulkUploadSortOptionsType
