import React from "react"
import JSONInput from "react-json-editor-ajrm"

import RivataLoader from "../../../components/RivataLoader"
import RivataModule from "../../../components/RivataModule"
import StatusAlert from "../../../components/StatusAlert"

import { useTypedSelector } from "../../../hooks/useTypedSelector"

interface Props {
    locale: ILocale
    JSONlocale: any
}

const SensorInfo: React.FC<Props> = ({
    locale,
    JSONlocale
}) => {
    const sensorInfo = useTypedSelector(state => state.assetAdminInfo.sensorInfo)

    return (
        <RivataModule
            title={`Installed Sensors`}
            locale={locale}
            marginTop={0}
            filters={null}
        >
            {sensorInfo?.isLoading ? (
                <RivataLoader />
            ) : sensorInfo && sensorInfo.data && !Object.keys(sensorInfo.data).length ? (
                <StatusAlert customText="No Data" color="success" statusCode={undefined} statusText={undefined} />
            ) : (
                <JSONInput
                    id='json'
                    locale={JSONlocale}
                    placeholder={sensorInfo.data}
                    width="100%"
                    viewOnly={true}
                    confirmGood={false}
                    style={{ body: { padding: "1rem" } }}
                />
            )}
        </RivataModule>
    )
}

export default SensorInfo
