import { useMemo } from 'react'
import { IColumn, IRow } from '../../components/RivataGrid/index'
import { NoDataLabels } from '../../enums'

interface IuseCsvData {
  (columns: Array<IColumn>, rows: Array<IRow>): Array<Array<string>>
}

const useCsvData: IuseCsvData = (columns, rows) => {
  return useMemo(() => {
    const columnsList = columns.map((col) => {
      return col.name
    })

    if (!columnsList.length) return []

    const csv: Array<Array<string>> = [columnsList]

    rows.forEach((r) => {
      const row = []
      for (let i = 0; i < columns.length; i++) {
        const key = columns[i].key

        row.push(r[key] ? r[key].toString() : NoDataLabels.TRIPPLE_DASH)
      }

      csv.push(row)
    })

    return csv
  }, [columns, rows])
}

export default useCsvData
