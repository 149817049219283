import React, { useLayoutEffect, useRef } from "react";

type Props = { height?: string};

const ScrollBlockWrapper: React.FC<Props> = ({ children, height = "100%" }) => {
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    function preventScroll(e: any) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
    const element = ref.current;

    if (null !== element)
      element.addEventListener("wheel", preventScroll, { passive: false });
    return () => {
      if (null !== element) element.removeEventListener("wheel", preventScroll);
    };
  }, []);

  return <div ref={ref} style={{ height: height }}>{children}</div>;
};

export default ScrollBlockWrapper;
