import React from 'react'
import { ReportParameterGroupProps } from './ReportParameterGroupProps'
import DateRangeRow from '../ReportParametersRows/DateRangeRow'
import CargoTagsRow from '../ReportParametersRows/CargoTagsRow'

const LatestTagLocationReportParameters: React.FC<ReportParameterGroupProps> = (
  props: ReportParameterGroupProps,
) => {
  return (
    <>
      <DateRangeRow
        value={
          props.parameters.epoch_from
            ? `${props.parameters.epoch_from}-${props.parameters.epoch_to}`
            : 'all_time'
        }
      />
      <CargoTagsRow value={props.parameters.macs} />
    </>
  )
}
export default LatestTagLocationReportParameters
