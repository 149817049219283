import React, { useEffect } from "react"
import { useParams } from "react-router-dom"

import CargotagPlaceDetails from "../../modules/CargotagPlaceDetails"
import CargotagAssetLocation from "./modules/CargotagAssetLocation"
import CargotagGrid from "../../modules/CargotagGrid/CargotagGrid"
import SubNav from "../../components/SubNav"

import { useTypedSelector } from "../../hooks/useTypedSelector"
import { useActions } from "../../hooks/useActions"

import './style.scss'


const breadcrumbs = [{ label: "Home", path: "/dashboard" }, { label: "Tag Locations", path: "/tag-locations" }, { label: "Tag Asset Details" }]

const TagAssetDetails: React.FC = () => {
  const { 
    locale, 
    tagLocations: { selectedLocationOrAsset, isLoading, selectedStatus } 
  } = useTypedSelector(state => ({
    locale: state.whitelabel.locale,
    tagLocations: state.tagLocations
  }))
  const { fetchAssetsWithCargoGps } = useActions()

  const params: { id: string } = useParams()

  useEffect(() => {
    fetchAssetsWithCargoGps(params?.id)
  }, [fetchAssetsWithCargoGps, params])

  return (
    <>
      <SubNav title="Tag Asset Details" breadcrumbs={breadcrumbs} />
      <CargotagPlaceDetails
        place={selectedLocationOrAsset}
        locale={locale}
        isLoading={isLoading}
        title="Asset Info"
        status={selectedStatus}
      />

      <CargotagAssetLocation
        asset={selectedLocationOrAsset}
        locale={locale}
        isLoading={isLoading}
        status={selectedStatus}
      />

      <CargotagGrid
        place={selectedLocationOrAsset}
        locale={locale}
        isLoading={isLoading}
        status={selectedStatus}
      />
    </>
  )
}

export default TagAssetDetails