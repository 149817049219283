import { IRow } from "../../../components/RivataGrid";
import { convertDataEpochToDate, getClearLabel, parseSecondsToDateParts } from "../../../utils";

export const dwellReportColumns = (columns: Array<string>) => {
    return columns.map((column) => {
      return {
        key: column,
        name: getClearLabel(column),
        minWidth: 100,
        width: setColumnWidth(column),
      }
    })
  }

export const dwellReportRows = (rows: Array<IRow>) => {
    return rows.map(row => {
        row.parent_customer = row.parent_customer ? row.parent_customer : "---"
        row.last_moved = row.last_moved ? convertDataEpochToDate(row.last_moved, null, null, true) : "More than 90 days ago"
        
        if (row.dwell_time) {
            const { parsed: { days, hours, minutes } } = parseSecondsToDateParts(row.dwell_time)

            if (days > 0) {
                const daysLabel = days > 1 ? "Days" : "Day"

                row.dwell_time = `${days} ${daysLabel} ${hours} Hrs ${minutes} Min`
            } else {
                row.dwell_time = `${hours} Hrs ${minutes} Min`
            }
        } else row.dwell_time = "More than 90 days"
        
        row.asset_groups = row.asset_groups || "---"
        
        return row
    })
}

const setColumnWidth = (key: string) => {
    switch (key) {
      case 'vin':
        return 160
      case 'asset_type':
        return 90
      case 'last_moved':
      case 'dwell_time':
        return 170
      case 'latitude':
      case 'longitude':
        return 100
      case 'parent_customer':
      case 'customer':
      case 'asset_name':
      case 'asset_groups':
      case 'geofences':
      default:
        return 190
    }
  }
  