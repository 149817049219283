import React, { useCallback, useState } from "react"
import DetailsGrid from "../RivataGrid/DetailsGrid"
import { useColumns, useRows } from "./hooks"
import ConfirmModal from "../ConfirmModal"
import { useActions } from "../../hooks/useActions"
import { useTypedSelector } from "../../hooks/useTypedSelector"
import RivataLoader from "../RivataLoader"
import InfoModal from "../InfoModal"

interface Props { }

const NotificationsSettings: React.FC<Props> = () => {
    const [alertProfileAction, setAlertProfileAction] = useState<any>(null)
    const [statusMessage, setStatusMessage] = useState("")

    const {
        manageUserNotificationProfile
    } = useActions()

    const { notificationsProfiles, isLoading } = useTypedSelector(state => ({
        notificationsProfiles: state.settings.notificationsProfiles,
        isLoading: state.settings.isLoading
    }))

    const onUnsubscribe = useCallback((id: number) => {
        setAlertProfileAction({ "id": id, "action": "unassign" })
    }, [])

    const onSubscribe = useCallback((id: number) => {
        setAlertProfileAction({ "id": id, "action": "assign" })
    }, [])

    const onActionSubmitted = async () => {
        let res: any
        res = await manageUserNotificationProfile(alertProfileAction.id, alertProfileAction.action)
        setAlertProfileAction(null);
        setStatusMessage(res.message)
    }

    const columns = useColumns(onUnsubscribe, onSubscribe)

    const rows = useRows(notificationsProfiles)

    return (
        <div className="notification-settings">
            {isLoading && <RivataLoader />}
            <h3>Notifications</h3>
            <hr />
            <DetailsGrid
                columns={columns}
                rows={rows}
                height={'400px'}
            />
            {!!alertProfileAction && <ConfirmModal
                open={true}
                onClose={() => { setAlertProfileAction(null) }}
                modalButtons={[
                    { id: 1, label: "Ok", color: "success", onClick: onActionSubmitted },
                ]}
            >
                <div>
                    <h5>Confirm Changes</h5>
                    <span>Are you sure that you want to {alertProfileAction.action === "assign" ? 'subscribe to' : 'unsubscribe from'} notification profile</span>
                </div>
            </ConfirmModal>}
            <InfoModal header={"Notification profile"} message={statusMessage || ""} open={!!statusMessage} onConfirm={() => setStatusMessage("")} />
        </div>
    )
}

export default React.memo(NotificationsSettings)
