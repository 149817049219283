import React, { useState } from "react"
import { Link } from "react-router-dom"
import {
    Nav,
    Dropdown, 
    DropdownMenu,
    DropdownToggle,
} from "reactstrap"

import authService from "../../services/auth"
import CustomTooltip from "./CustomTooltip"

interface Props {
    phoneNumberVerified: boolean
    isAssignedToSmsAlerts: boolean
    user: {
        email: string | null
        userName: string | null
    }
}

const HeaderMenu: React.FC<Props> = ({
    phoneNumberVerified, 
    isAssignedToSmsAlerts,
    user
}) => {
    const [userDropdownOpen, setUserDropdownOpen] = useState(false)


    const toggleUserDropdown = () => {
        setUserDropdownOpen(!userDropdownOpen)
    }

    const onSignOut = (e: any) => {
        e.preventDefault()
        authService.signOut()
    }
    
    
    return (
        <Nav className="ml-auto float-right" navbar>
            <div className="d-flex align-items-center" style={{ color: "#fff", fontWeight: "bold", fontSize: "1.2rem" }}>{user.userName}</div>
            <Dropdown isOpen={userDropdownOpen} toggle={toggleUserDropdown}>
                <DropdownToggle
                    tag="span"
                    data-toggle="dropdown"
                    aria-expanded={userDropdownOpen}
                >
                    <div className="user-icon">
                        <i className="fas fa-user-circle header-icon"/>
                        {!phoneNumberVerified && isAssignedToSmsAlerts && (
                            <>
                                <i id="user_unverified_phone" className="fa-solid fa-circle-exclamation fa-lg warning__icon icon-position"/>
                                
                                <CustomTooltip target="user_unverified_phone" text="Phone number is not verified" />
                            </>
                        )}
                    </div>
                </DropdownToggle>
                <DropdownMenu>
                    <div 
                        onClick={toggleUserDropdown}
                    >
                        <Link 
                            className="user-dropdown__item"
                            to="/support"
                        >
                            <div className="d-flex align-items-center">
                                <i className="fa fa-info-circle" style={{ padding: "5px"}} />
                                <div>Support</div>
                            </div>
                        </Link>
                    </div>
                    <div 
                        onClick={toggleUserDropdown}
                    >
                        <Link 
                            className="user-dropdown__item" 
                            to="/settings"
                        >
                            <div className="d-flex align-items-center">
                                <i className="fa fa-cog" style={{ padding: "5px"}}/>
                                <div>User Profile</div>

                                {!phoneNumberVerified && isAssignedToSmsAlerts && (
                                    <>
                                        <i id="user-menu_unverified_phone" className="fa-solid fa-circle-exclamation fa-lg warning__icon"/>
                                        
                                        <CustomTooltip target="user-menu_unverified_phone" text="Phone number is not verified" />
                                    </>
                                )}
                            </div>
                        </Link>
                    </div>
                    <div 
                        onClick={onSignOut}
                    >
                        <div className="user-dropdown__item" >
                            <div className="d-flex align-items-center">
                                <i className="fas fa-sign-out-alt" style={{ padding: "5px"}}/>
                                <div>Logout</div>
                            </div>
                        </div>
                    </div>
                </DropdownMenu>
            </Dropdown>
        </Nav>
    )
}

export default HeaderMenu
