import React, { useMemo } from "react";
import tractorImg from "../../../assets/images/tpms_status_diagram/tractorNew.svg";

import { getPositions, getAxles, getIntervals, getHubOrders, indexAjustment, getCorrectedAxles } from "../utils";

import Axle from "../axle/Axle";
import AxlesLoad from '../common/axlesLoads'
import PositionBubble from "../common/PositionBubble";
import ActiveHubs from '../common/activeHubWarnings'

const Tractor = ({ height, sensors = [], pressureUnit, getTireData, axlesLoads, setImageLoaded, timezone, locale, smart, thresholds }) => {
  const axleHeight = 250;
  const axleInterval = 80;
  const axleLeftMargin = 20;
  const vehicleType = "tractor";
  const{smarthub: hubThresholds}=thresholds
  const tractorPositions = useMemo(() =>
    getPositions(
      ["DRIVE_LEFT", "DRIVE_RIGHT"],
      [
        { key: "STEER_LEFT", axle: 0 },
        { key: "STEER_RIGHT", axle: 0 },
      ]
    ), []
  );
  const correctedAxles = useMemo(() => {
    return getCorrectedAxles(sensors, smart, null, axlesLoads, vehicleType, tractorPositions)
  }, [axlesLoads, sensors, smart, tractorPositions])

  const axles = useMemo(() => getAxles(correctedAxles, tractorPositions, vehicleType), [correctedAxles, tractorPositions]);
  const hubs = useMemo(() => getHubOrders(tractorPositions, smart, hubThresholds), [hubThresholds, smart, tractorPositions])
  const intervals = useMemo(() => getIntervals(axles, axleInterval, axleLeftMargin, vehicleType), [axles]);


  const rearWidth = useMemo(() => {
    return intervals[0] + (axlesLoads.length ? 60 : 0);
  }, [intervals, axlesLoads.length]);

  const steerAxle = axles.find((axle) => axle.order === 0);
  return (
    <div className="d-flex">
      <div className="d-flex align-items-center" style={{ position: "relative", height: `${height}px` }}>
        <div className="tractor-rear" style={{width: `${rearWidth}px`, height: "100px" }}>
          {axlesLoads.length ? <AxlesLoad axlesLoads={axlesLoads} pressureUnit={pressureUnit} timezone={timezone}/> : ""}
        </div>

        {axles.map((axle, index) => {
          if (axle.order === 0 ) return null;
          let i = indexAjustment(index, axle.order, axles, intervals);
          return (
            <Axle
              key={index}
              axle={axle}
              axleType="doubleDriveAxle"
              style={{ position: "absolute", left: `${intervals[i]}px` }}
              height={axleHeight}
              pressureUnit={pressureUnit}
              getTireData={getTireData}
              timezone={timezone}
              locale={locale}
            />
          );
        })}

        {axles.map((axle, index) => {
          if (axle.order === 0 ) return null;
          let i = indexAjustment(index, axle.order, axles, intervals);
          return <PositionBubble key={index} label={axle.positionBubble} style={{left: `${intervals[i] + 5.5}px` }} />;
        })}

        {axles.map((axle, index)=>{
          if(axle.order === 0) return null
          let i = indexAjustment(index, axle.order, axles, intervals);
          return(
            <ActiveHubs key={index} axle={axle} intervals={intervals} hubs={hubs} index={i}/>
          )
        })}
       
      </div>

      <div className="d-flex align-items-center" style={{ position: "relative", height: `${height}px` }}>
        <img src={tractorImg} alt={vehicleType} height={height} onLoad={() => setImageLoaded(true)} />

        {steerAxle && (
          <>
            <Axle
              key={vehicleType}
              axle={steerAxle}
              axleType="singleSteerAxle"
              style={{ position: "absolute", left: "172px" }}
              height={axleHeight}
              pressureUnit={pressureUnit}
              getTireData={getTireData}
              timezone={timezone}
              locale={locale}
            />
            <ActiveHubs axle={steerAxle} intervals={null} hubs={hubs} index={null} />
            <PositionBubble label={steerAxle.positionBubble} style={{ left: "177.5px" }} />
          </>
        )}
      </div>
    </div>
  );
};

export default React.memo(Tractor);
