import moment from "moment";
import { ChartData, ChartDataset } from "chart.js";
import { AnnotationOptions } from "chartjs-plugin-annotation";
import { convertDataEpochToDate } from "./index";
import { ITooltipItem } from "../components/RivataLineChart/interfaces";
import { AxlesGroups, AxlesGroupsOrder } from "../constants/constants";
import { WheelOrder } from "../enums";
import { store } from "../redux/store";
export interface IChartData extends ChartData {
    x: moment.Moment;
}

export type DatasetsList = Array<ChartDataset<"line", Array<IChartData>>>

interface IgetDateRange {
    (datasets: DatasetsList): {
        min: number;
        max: number;
    };
}

export const getDateRange: IgetDateRange = (datasets) => {
    let min = Infinity;
    let max = -Infinity;
    if (!datasets.length) return { min, max };
    datasets.forEach((item) => {
        item.data.forEach((point) => {
            const pointEpoch = point.x.unix();
            if (pointEpoch < min) {
                min = pointEpoch;
            }
            if (pointEpoch > max) {
                max = pointEpoch;
            }
        });
    });
    let minMoment: number = convertDataEpochToDate(
        min,
        null,
        null,
        false
    ).unix();
    let maxMoment: number = convertDataEpochToDate(
        max,
        null,
        null,
        false
    ).unix();

    return { min: minMoment, max: maxMoment };
};

interface composeVertLineAnnotation {
    (
        id: string,
        value: number,
        color: string
    ): AnnotationOptions
}

export const composeVertLineAnnotation: composeVertLineAnnotation = (id = "chart", value, color = "rgb(226,178,63)") => ({
  id: `threshold-vline-${id}`,
  type: "line",
  scaleID: "x",
  value,
  endValue: value,
  borderColor: color,
  borderWidth: 4,
  drawTime: "beforeDatasetsDraw"
})

type annotationsList = { [key: string]: AnnotationOptions };
interface IchartThresholdAnnotations {
    (
        id: string,
        threshold: number,
        verticalLine: number,
        healthColors: Array<IHealthColor>
    ): annotationsList;
}

export const chartThresholdAnnotations: IchartThresholdAnnotations = (
    id = "chart",
    threshold,
    verticalLine,
    healthColors
) => {
    const annotations: annotationsList = {}
    const xMin = -Infinity
    const xMax = Infinity

    annotations.box1 = {
        type: "box",
        id: `box-plot-top-${id}`,
        xScaleID: "x",
        yScaleID: "y",
        yMax: Infinity,
        yMin: threshold,
        backgroundColor: "#fffcdf",
        borderWidth: 0,
        drawTime: "beforeDatasetsDraw",
    };

    annotations.box2 = {
        type: "box",
        id: `box-plot-bottom-${id}`,
        xScaleID: "x",
        yScaleID: "y",
        yMax: threshold,
        yMin: -Infinity,
        xMin,
        xMax,
        backgroundColor: "#fff",
        borderWidth: 0,
        drawTime: "beforeDatasetsDraw",
    };

    if (threshold) {
        annotations.thresholdLine = {
            id: `threshold-line-${id}`,
            type: "line",
            xScaleID: "x",
            yScaleID: "y",
            yMax: threshold,
            yMin: threshold,
            xMin,
            xMax,
            value: threshold,
            borderColor: "#fed925",
            borderWidth: 2,
            borderDash: [2, 2],
            borderDashOffset: 10,
            drawTime: "beforeDatasetsDraw",
        };
    }

    if (verticalLine && healthColors && healthColors[1]) {
        annotations.verticalLine = composeVertLineAnnotation(id, verticalLine, healthColors[1].color)
    }

    return annotations;
};

type ISpeedData = Array<{ start: number; stop: number }>

export const getMotionAnnotations = (speedData: ISpeedData) => {
    return speedData && speedData.length
        ? speedData.reduce((acc: any, data, index) => {
            acc[`box-${index}`] = {
                backgroundColor: "rgba(220, 221, 222, 0.25)",
                id: `box-plot-top-motion-annotation-${index}`,
                type: "box",
                xScaleID: "x",
                yScaleID: "y",
                yMax: Infinity, 
                yMin: -Infinity,
                xMin: data.start * 1000,
                xMax: data.stop * 1000,
                borderWidth: 1,
                borderColor: "rgba(220, 221, 222, 0.25)",
                drawTime: "beforeDatasetsDraw"
            }

            return acc;
        }, {})
        : {};
};

type ChartTooltip = {
    displayColors: boolean
    borderWidth: number
    padding?: number
    footerAlign?: string
    backgroundColor?: (item: ITooltipItem) => string | undefined
    borderColor?: (item: ITooltipItem) => string | undefined
    callbacks: {
        title?: (items: Array<ITooltipItem>) => string | undefined | void
        beforeBody?: (items: Array<ITooltipItem>) => string | undefined
        beforeLabel?: (item: ITooltipItem) => string | undefined
        afterLabel?: (item: ITooltipItem) => string | undefined
        label: (item: ITooltipItem) => string | undefined
        footer?: (items: Array<ITooltipItem>) => string | undefined
    }
}
interface IcomposeCustomTooltip {
    (
        config: {
            displayLabelColors: boolean
            bgColor?: boolean
            borderColor?: boolean
            tableFormat?: boolean
            beforeBody?: boolean
            beforeLabel?: boolean
            afterLabel?: boolean
            footer?: boolean,
            footerAlign?: string
        }
    ): any
}

export const composeCustomTooltip: IcomposeCustomTooltip = (config) => {
    const tooltip: ChartTooltip =  {
        displayColors: config.displayLabelColors,
        borderWidth: 2,
        footerAlign: config.footerAlign ? config.footerAlign : "left",
        callbacks: {
            title: (tooltipItems) => tooltipItems[0].dataset.label || "",
            label: (tooltipItem) => tooltipItem.raw.displayValue
        }
    }

    if (config.bgColor) {
        tooltip.backgroundColor = (tooltipItem) => tooltipItem.tooltip.labelColors[0].borderColor
    }
    if (config.borderColor) {
        tooltip.borderColor = (tooltipItem: any) => {
            if (tooltipItem.tooltipItems[0]?.raw?.tooltipColor) {
                return tooltipItem.tooltipItems[0].raw.tooltipColor
            }

            return tooltipItem.tooltip.labelColors[0].borderColor
        }
    }
    if (config.tableFormat) {
        tooltip.padding = 10
        tooltip.callbacks.title = () => {}
        tooltip.callbacks.label = (tooltipItem) => `${tooltipItem.dataset.label}: ${tooltipItem.raw.displayValue}`
    }
    if (config.beforeBody) {
        tooltip.callbacks.beforeBody = (tooltipItem) => tooltipItem[0].raw.displayBeforeBody
    }
    if (config.beforeLabel) {
        tooltip.callbacks.beforeLabel = (tooltipItem) => tooltipItem.raw.displayBeforeLabel
    }
    if (config.afterLabel) {
        tooltip.callbacks.afterLabel = (tooltipItem) => tooltipItem.raw.displayAfterLabel
    }
    if (config.footer) {
        tooltip.callbacks.footer = (tooltipItem) => tooltipItem[0].raw.displayFooter
    }

    return tooltip;
}


export const chartDatasetSort = (dataset: Array<IChartData>) => {
    dataset.sort((d1, d2) => d1.x.unix() - d2.x.unix())
}


export const getFilteredPositions = (positions: Array<string>, type: "smarthub" | "tpms") => {
    if (!positions.length) return []

    const sensor_info = store.getState().assetDetails.assetInfo.data?.sensor_info
    const smarthub_sensors = sensor_info?.smarthub_sensors
    const tpms = sensor_info?.tpms

    let provisionedSensors: Array<{ mac: string, position: string }> = []

    if (type === "smarthub") provisionedSensors = smarthub_sensors
    else provisionedSensors = tpms

    if (!provisionedSensors || !Array.isArray(provisionedSensors)) return positions

    const filteredPositions: Array<string> = []

    positions.forEach(pos => {
        const idx = provisionedSensors.findIndex(el => el.position === pos)

        if (idx > -1) filteredPositions.push(pos)
    })

    return filteredPositions
}


export const getAxlesDropdownItems = (positions?: Array<string>) => {
    const all = { id: "all", label: "All", disabled: false }

    if (!positions) return [ all ]

    const dropdownItems: Array<{ id: string, label: string, disabled: boolean }> = []

    const axles = Object.keys(AxlesGroups)

    positions.forEach(pos => {
        for (let i = 0; i < axles.length; i++) {
            const axle = axles[i]

            if (AxlesGroups[axle].includes(pos)) {
                if (dropdownItems.findIndex(el => el.id === axle) === -1) {
                    dropdownItems.push({ id: axle, label: axle, disabled: false })
                }

                break
            }
        }
    })

    dropdownItems.sort((a, b) => AxlesGroupsOrder[a.id] - AxlesGroupsOrder[b.id])
    
    return [ all, ...dropdownItems ]
}

export const sortDataByPositions = (data: Array<any>) => {
    data.sort((d1, d2) => WheelOrder[d1.position] - WheelOrder[d2.position]) 
}

export const getUniqueDatasets = (datasets: Array<any>) => {
    const uniqueDatasets: Array<any> = []

    for (let i = 0; i < datasets.length; i++) {
        const idx = uniqueDatasets.findIndex(el => el.label === datasets[i].label)

        if (idx > -1) {
            uniqueDatasets[idx].data = uniqueDatasets[idx].data.concat(datasets[i].data)
        } else {
            uniqueDatasets.push(datasets[i])
        }
    }

    for (let i = 0; i < uniqueDatasets.length; i++) {
        chartDatasetSort(uniqueDatasets[i].data)
    }

    return uniqueDatasets
}
