import { eventDataSensor, IEventDataWarnings } from "./types"

type sensorInfo = {
  mac: string
  position: string
}

export const warningsToSensors = (
  warnings: IEventDataWarnings,
  assetSensors: {
    gateways: Array<{
      esn: string
    }>
    smarthub_sensors: Array<sensorInfo>
    tpms: Array<sensorInfo>
    line_pressure: sensorInfo
    axle_load: sensorInfo
  },
  epoch: number,
) => {
  const res: Array<eventDataSensor> = []

  if (warnings.smarthubTemp)
    Object.entries(warnings.smarthubTemp).forEach(([sensor_mac, value]) => {
      res.push({
        sensor_type: "smarthub",
        packet_type: "temperature",
        sensor_mac,
        epoch,
        value,
      })
    })

  if (warnings.tpms)
    Object.entries(warnings.tpms).forEach(([mac, value]) => {
      res.push({
        sensor_type: "tire_pressure",
        short_mac: mac.replaceAll("-", "").slice(-4),
        value,
      })
    })

  if (warnings.linePressure !== undefined)
    res.push({
      sensor_type: "tire_pressure",
      short_mac: assetSensors.line_pressure.mac.replaceAll("-", "").slice(-4),
      value: warnings.linePressure,
    })

  if (warnings.axleLoad !== undefined)
    res.push({
      sensor_type: "tire_pressure",
      short_mac: assetSensors.axle_load.mac.replaceAll("-", "").slice(-4),
      value: warnings.axleLoad,
    })

  return res
}
