import React, { useState } from "react";
import { Label } from "reactstrap"
import RivataModule from "../../components/RivataModule";
import RivataLoader from "../../components/RivataLoader";
import DocumentsUploader from "./DocumentsUploader";
import DocumentsList from "./DocumentsList";
import RivataDropdown from "../../components/RivataDropdown";
import { IDocType } from "../../redux/documents/types";

import "./styles.scss";

interface Props {
  locale: Record<string, string>;
  documents: { [key: string]: Array<IDocument> };
  docTypes: IDocType[];
  isLoading: boolean;
  addDocument: (dir: string, title: string, doc_type: number) => void;
  removeDocument: (title: string) => void;
}

const dropdownItems = [
  { id: "legals", label: "Legals", disabled: false },
  { id: "resources", label: "Resources", disabled: false }
]

const ManageSupportModule: React.FC<Props> = ({locale, documents, docTypes, isLoading, addDocument, removeDocument }) => {
  const [selected, setSelected] = useState("legals")
  return (
    <RivataModule 
      title="Manage Documents" 
      width={12} 
      marginTop={0}
      locale={locale} 
      filters={null}
    >
      {isLoading ? (
        <div className="d-flex loader">
          <RivataLoader />
        </div>
      ) : (
        <>
          <h4>Upload Document</h4>
  
          <div className="my-2">
            <Label className="m-0 mr-3" >Select Section:</Label>
            <RivataDropdown
              caret={true}
              items={dropdownItems}
              selected={selected}
              onSelect={setSelected}
              buttonLabel={null}
              filters={null}
              onRemove={null}
              disabled={false}
              color={"secondary"}
            />
          </div>

          <DocumentsUploader
            documents={documents[selected]}
            dir={selected}
            addDocument={addDocument}
            docTypes={docTypes}
          />

          <DocumentsList
            documents={documents[selected]}
            locale={locale}
            dir={selected}
            removeDocument={removeDocument}
            docTypes={docTypes}
          />
        </>
      )}
    </RivataModule>
  );
};

export default ManageSupportModule;
