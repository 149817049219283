import React, { useState } from "react";
import {
  Badge,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import './style.scss'

const Badges = ({ filters, onRemove }) => {
  return (
    <>
      {filters.map(({ id, label }) => (
        <Badge
          className="mr-2 bg-light text-dark"
          key={label}
          pill
          variant="success"
          onClick={(e) => {
            if (typeof onRemove === "function") {
              e.stopPropagation();
              e.preventDefault();
              onRemove(id);
            }
          }}
        >
          <span style={{ fontSize: "16px" }}>
            {label} <span aria-hidden="true">&times;</span>
          </span>
        </Badge>
      ))}
    </>
  );
};

const DropdownItems = ({ items, onSelect, locale, subMenue }) => {
  return (
    <>
      {items.map(({ id, label, disabled }, i) => {
        const isMargin = i>0 && subMenue ? "16px" : "0"
        const isFontWeight = i === 0 && subMenue ? "bold" : "normal"
        return(
          <DropdownItem
            key={label + i}
            style={{marginLeft: isMargin, fontWeight: isFontWeight}}
            disabled={disabled === true}
            onClick={() => {
              if (typeof onSelect === "function") {
                onSelect(id);
              }
            }}
          >
            {locale[label] || label}
          </DropdownItem>
        )
      })}
    </>
  );
};

const getSelectedLabel = (items, selected, locale) => {
  let label = "";
  if (selected !== null && selected !== undefined) {
    const item = items.find(({ id }) => id === selected);
    if (item && item.label) {
      label = locale[item.label] || item.label;
    }
  } else if (items.length && items[0].label) {
    label = locale[items[0].label] || items[0].label;
  }
  return label;
};
const RivataDropdown = (props) => {
  const {
    buttonLabel,
    filters,
    items,
    onRemove,
    onSelect,
    selected,
    size = "md",
    locale = {},
    caret,
    color,
    disabled,
    subMenue = false,
    v2Style
  } = props
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  let buttonDropClasses = !caret ? 'singleDropDownItem' : 'mr-2'

  if (v2Style) {
    buttonDropClasses += ' v2-style'
  }

  return (
    <ButtonDropdown
      size={size}
      className={buttonDropClasses}
      isOpen={dropdownOpen}
      toggle={toggle}
      disabled={disabled}
    >
      <DropdownToggle disabled={disabled} caret={caret} color={color} >
        {filters ? (
          <Badges filters={filters} onRemove={onRemove} />
        ) : buttonLabel ? (
          buttonLabel
        ) : (
          getSelectedLabel(items, selected, locale)
        )}
      </DropdownToggle>
      <DropdownMenu className="rivata-dropdown__menu">
        <DropdownItems items={items} onSelect={onSelect} locale={locale} subMenue={subMenue ? subMenue : false}/>
      </DropdownMenu>
    </ButtonDropdown>
  );
};

export default RivataDropdown;
