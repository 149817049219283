import { SettingsActions, SettingsReducer, SettingsActionTypes } from "./types";


const initialState: SettingsReducer = {
    isLoading: false,
    notificationsProfiles: []
}


const settings = (state = initialState, action: SettingsActions): SettingsReducer => {
    switch (action.type) {
        case SettingsActionTypes.LOADING:
            return { ...state, isLoading: action.payload }
        case SettingsActionTypes.SET_NOTIFICATIONS_PROFILES:
            return { ...state, notificationsProfiles: action.payload }
        default:
            return state
    }
}


export default settings
