import { useMemo } from 'react'
import moment from 'moment'
import {
  WhiteLabelFilenames,
  SubscriptionTypes,
  ColumnsIds,
  ATIInstalledOptions,
  NoDataLabels,
} from '../../enums'
import { getClearLabel } from '../../utils'

export const useTableRows = (
  data,
  columns,
  whiteLabelUrl,
  customerIds,
  isSuperAdmin,
) => {
  return useMemo(() => {
    const hash = `?r=${+new Date()}`
    const smartHubSrc = `${whiteLabelUrl}${WhiteLabelFilenames.smarthubHealthSvg}${hash}`
    const tpmsSrc = `${whiteLabelUrl}${WhiteLabelFilenames.tpmsHealthSvg}${hash}`
    const linePressureSrc = `${whiteLabelUrl}${WhiteLabelFilenames.linePressureHealthSvg}${hash}`
    const axleLoadSrc = `${whiteLabelUrl}${WhiteLabelFilenames.axleLoadHealthSvg}${hash}`
    const gatewaySrc = `${whiteLabelUrl}${WhiteLabelFilenames.gatewayHealthSvg}${hash}`

    return data.map((datum, rowIndex) => {
      let atiInstalledValue = ''

      let ati_installed =
        datum.ati_installed !== null ? datum.ati_installed.toString() : ''

      let option = ATIInstalledOptions.find(
        (opt) => opt.value === ati_installed,
      )

      if (option) {
        atiInstalledValue = option.label
      }
      const obj = {
        ...datum,
        asset_type:
          datum.asset_type === 'tractor'
            ? 'Tractor'
            : datum.asset_type === 'trailer'
            ? 'Trailer'
            : '',
        ati_installed: atiInstalledValue,
        asset_groups:
          datum.asset_groups?.map((a) => a.name).join(', ') ||
          NoDataLabels.DASH,
        asset_subtype: getClearLabel(datum.asset_subtype),
      }

      const row = {
        id: rowIndex,
        data: datum,
        columns: columns.map((col, i) => {
          const nameFix =
            columns[i].id === 'asset_name' ? 'name' : columns[i].id
          const colId = col.id === 'asset_name' ? 'name' : col.id
          if (col.id === 'subscription' && obj.subscription) {
            const alt = []
            const src = []
            const title = []
            Object.keys(obj?.subscription).map((key) => {
              const { valid_from, valid_to } = obj.subscription[key]
              const currentDate = moment(new Date()).unix()

              if (currentDate < valid_from || currentDate > valid_to)
                return null
              switch (key) {
                case SubscriptionTypes.gateway:
                  src.push(gatewaySrc)
                  alt.push('gw')
                  title.push('Gateway')
                  break
                case SubscriptionTypes.smarthub:
                  src.push(smartHubSrc)
                  alt.push('hub')
                  title.push('SmartHub')
                  break
                case SubscriptionTypes.tpms:
                  src.push(tpmsSrc)
                  alt.push('tire')
                  title.push('TPMS')
                  break
                case SubscriptionTypes.linePressure:
                  src.push(linePressureSrc)
                  alt.push('line')
                  title.push('Line Pressure')
                  break
                case SubscriptionTypes.axleLoad:
                  src.push(axleLoadSrc)
                  alt.push('axle')
                  title.push('Axle Load')
                  break
                default:
                  break
              }
              return key
            })

            return {
              columnId: nameFix,
              type: 'icon',
              label: alt,
              alt,
              src,
              title,
              classNames: [],
              min_width: '175px',
            }
          }
          return {
            columnId: col.id,
            controlEnabled: false,
            type: 'text',
            label: `${obj[colId] || NoDataLabels.DASH}`,
          }
        }),
      }

      if (customerIds?.length < 2 && !isSuperAdmin) {
        const idx = row.columns.findIndex(
          (el) => el.columnId === ColumnsIds.CUSTOMER_NAME,
        )
        if (idx > -1) row.columns.splice(idx, 1)
      }
      return row
    })
  }, [data, columns, whiteLabelUrl, customerIds, isSuperAdmin])
}

export const useColumns = (locale, customerIds, isSuperAdmin) => {
  return useMemo(() => {
    const columns = [
      {
        label: locale['Customer'] || 'Child Customer',
        id: ColumnsIds.CUSTOMER_NAME,
        control: 'sort',
        isDraggable: true,
      },
      {
        label: locale['Asset Name'] || 'Name',
        id: 'asset_name',
        control: 'sort',
        isDraggable: true,
      },
      {
        label: locale['VIN'] || 'VIN',
        id: ColumnsIds.VIN,
        control: 'sort',
        isDraggable: true,
      },
      {
        label: locale['Asset Type'] || 'Asset Type',
        id: 'asset_type',
        control: 'sort',
        isDraggable: true,
      },
      {
        label: locale['AssetSubtype'] || 'Asset Subtype',
        id: 'asset_subtype',
        control: 'sort',
        isDraggable: true,
      },
      {
        label: 'Subscription',
        id: ColumnsIds.SUBSCRIPTION,
        control: 'sort',
        isDraggable: true,
      },
      {
        label: locale['Year'] || 'Year',
        id: 'year',
        control: 'sort',
        isDraggable: true,
      },
      {
        label: locale['Make'] || 'Make',
        id: 'make',
        control: 'sort',
        isDraggable: true,
      },
      {
        label: locale['Model'] || 'Model',
        id: 'model',
        control: 'sort',
        isDraggable: true,
      },
      {
        label: locale['NumberOfAxles'] || 'Number Of Axles',
        id: 'number_of_axles',
        control: 'sort',
        isDraggable: true,
      },
      {
        label: locale['ATI Installed'] || 'ATI Installed',
        id: 'ati_installed',
        control: 'sort',
        isDraggable: true,
      },
      {
        label: locale['Asset Groups'] || 'Asset Groups',
        id: 'asset_groups',
        isDraggable: true,
      },
    ]

    if (customerIds?.length < 2 && !isSuperAdmin) {
      const idx = columns.findIndex((el) => el.id === ColumnsIds.CUSTOMER_NAME)
      if (idx > -1) columns.splice(idx, 1)
    }

    return columns
  }, [locale, customerIds, isSuperAdmin])
}

export const useAssetSearch = (locale) => {
  return useMemo(
    () => [
      {
        label: locale['Asset Name'],
        id: ColumnsIds.ASSET_NAME,
      },
      {
        label: locale['VIN'],
        id: ColumnsIds.VIN,
      },
      {
        label: locale['make'],
        id: ColumnsIds.MAKE,
      },
      {
        label: locale['Asset Type'],
        id: ColumnsIds.ASSET_TYPE,
      },
    ],
    [locale],
  )
}
