import { UnitsOfMeasurement } from "../../../enums"
import { metersToKmh } from "../../../utils"
import { metersToMph } from "../../../utils"
import { convertDataEpochToDate } from "../../../utils"
import { getNormalizedSource } from "../../../utils/utils"


export const getOdometerRows = (data: Array<IOdometerCalibration>, selectedUnitsOfMeasurement: string) => {
    return data.map((r) => {
        const odometer = selectedUnitsOfMeasurement === UnitsOfMeasurement.imperial 
            ? `${Math.ceil( metersToMph(r.odometer) )} Miles`
            : `${Math.ceil( metersToKmh(r.odometer) )} Kilometers`

        let source = getNormalizedSource(r.source)

        return {
            id: r.id,
            data: r,
            columns: [
                {
                    type: "text",
                    label: odometer,
                    columnId: "odometer",
                    data: r.odometer
                },
                {
                    type: "text",
                    label: convertDataEpochToDate(r.epoch, null, null , true),
                    columnId: "timestamp",
                    data: r.epoch
                },
                {
                    type: "text",
                    label: source,
                    columnId: "source",
                    data: r.source
                },
                {
                    type: "text",
                    label: r.created_by,
                    columnId: "created_by",
                    data: r.source
                }
            ]
        } 
    })
}
