import homeIcon from '../../assets/images/nav_icons/Home.svg'
import reportIcon from '../../assets/images/nav_icons/Reports.svg'
import whitelabelIcon from '../../assets/images/nav_icons/Whitelabel.svg'
import geofencesIcon from '../../assets/images/nav_icons/Geofences.svg'
import cargoTagsIcon from '../../assets/images/nav_icons/CargoTags.svg'
import manageNotificationsIcon from '../../assets/images/nav_icons/ManageNotifications.svg'
import tagLocationsIcon from '../../assets/images/nav_icons/TagLocations.svg'
import assetGroupsIcon from '../../assets/images/nav_icons/AssetGroups.svg'

import { Path } from "../../enums"

export interface INavRoute {
    path?: string
    name: string
    navlabel?: boolean
    icon?: string
    src?: any
    isCustomIcon?: boolean
    customStyles?: {}
}


export const sidebarRoutes: Array<INavRoute> = [
    {
        path: "/dashboard",
        name: "Home",
        isCustomIcon: true,
        src: homeIcon,
    },
    {
        path: "/geofences",
        name: "Geofences",
        isCustomIcon: true,
        src: geofencesIcon,
        customStyles: { height: "28px", width: "28px", marginRight: "3px", marginLeft: "3px" }
    },
    {
        path: Path.TagLocations,
        name: "Tag Locations",
        icon: "fa fa-map-marker font-16",
        isCustomIcon: true,
        src: tagLocationsIcon,
        customStyles: { width: "18px", height: "18px" }
    },
    {
        path: Path.Reports,
        name: "Reports",
        isCustomIcon: true,
        src: reportIcon,
    },
   
]

export const sidebarAdminRoutes: Array<INavRoute> = [
    ...sidebarRoutes,
    {
        navlabel: true,
        name: "Admin"
    },
    {
        path: Path.Provision,
        name: "Provision",
        icon: "fa fa-edit font-16",
    },
    {
        path: Path.TpmsProfiles,
        name: "TPMS Profiles",
        icon: "fa fa-list-alt font-16",
    },
    {
        path: Path.AssetGroups,
        name: "Asset Groups",
        icon: "far fa-map font-16",
        isCustomIcon: true,
        src: assetGroupsIcon,
        customStyles: { width: "28px", height: "28px" }
    },
    {
        path: Path.ManageNotifications,
        name: "Manage Notifications",
        isCustomIcon: true,
        src: manageNotificationsIcon,
        // customStyles: { width: "18px", height: "18px" }
    },
    {
        path: Path.CalibrateAssets,
        name: "Calibrate Assets",
        icon: "fas fa-sliders-h font-16",
    },
    {
        path: Path.ManageGeofences,
        name: "Manage Geofences",
        icon: "far fa-map font-16",
    }
]

export const superAdminRoutes: Array<INavRoute> = [
    ...sidebarAdminRoutes,
    {
        path: Path.Thresholds,
        name: "Thresholds",
        icon: "fa fa-bookmark font-16"
    },
    {
        path: Path.WhiteLabel,
        name: "Whitelabel",
        icon: "fa fa-chart-area font-16",
        isCustomIcon: true,
        src: whitelabelIcon,
        customStyles: { width: "20px", height: "20px" }
    },
    {
        path: Path.ManageSupport,
        name: "Manage Support",
        icon: "fa fa-info-circle",
    },
        {
        path: Path.CargoTags,
        name: "Cargo Tags",
        isCustomIcon: true,
        src: cargoTagsIcon,
        customStyles: { width: "18px", height: "18px" }
    },
]