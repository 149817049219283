import { ITooltipItem } from "./interfaces";
import { AnnotationOptions } from "chartjs-plugin-annotation";

interface IChartoptions {
    displayX: boolean
    isSync: boolean
    displayLabelColors: boolean
    yAxisLabel: string
    xAxisLabel: string
    yScale: any
    annotations: IDictStrKeys<AnnotationOptions>
    assetMotionAnnotations: IDictStrKeys<AnnotationOptions>
    elements: any
    animation: any
    tooltip: any
}


export const chartDefaultOptions: IChartoptions = {
    displayX: true,
    isSync: true,
    displayLabelColors: false,
    yAxisLabel: "",
    xAxisLabel: "",
    yScale: {
        id: "y",
        beginAtZero: true,
        grace: "20%",
        title: {
            display: true,
            text: ""
        }
    },
    annotations: {},
    assetMotionAnnotations: {},
    elements: {
        line: {
            tension: 0
        },
        point: {
            radius: 1.5,
            hitRadius: 4,
            hoverRadius: 3
        },
    },
    animation: {
        duration: 300
    },
    tooltip: {
        displayLabelColors: false,
        backgroundColor: (tooltipItem: ITooltipItem) => tooltipItem.tooltip.labelColors[0].borderColor,
        callbacks: {
            title: (tooltipItem: Array<ITooltipItem>) => tooltipItem[0].dataset.label || "",
            label: (tooltipItem: ITooltipItem) => tooltipItem.raw.displayValue,
            afterLabel: (tooltipItem: ITooltipItem) => `Date: ${tooltipItem.raw.displayDate}`,
        }
    }
}
