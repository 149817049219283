import { useEffect, useState } from 'react'
import { CommandType, NoDataLabels } from '../../enums'
import { convertDataEpochToDate } from '../../utils'
import { groupBy } from 'lodash';

export const useTableRows = (data, putHardwareCommand, onLogButtonPress, whitelistingAutomation) => {
    const [body, setBody] = useState([])

    useEffect(() => {
      const sendHardwareCommand = (id, value, action = null) => {
        const body = {
          "hardware_command_id": id,
          "ready_to_be_sent": `${value}`,
          "action": action
        }
        putHardwareCommand(body)
      }

      const enabledCommandsIds = [];
      const groupedByMacCommands = groupBy(data, "mac");
      
      Object.keys(groupedByMacCommands).forEach(k => {
        const newest = groupedByMacCommands[k].sort((a,b) => b.created - a.created)[0];
        enabledCommandsIds.push(newest["hardware_command_id"]);
      })

      const tableBody = data.map((row) => {
          let buttons = [];

          if (row.command_type === CommandType.WHITELISTING_SMS) {
            let processDisabled = false
            if (row.canceled || !enabledCommandsIds.includes(row.hardware_command_id) || row.payload?.parameters?.length === 0){
              processDisabled = true;
            }
            
            if (
              (row.commonStatus && (row.commonStatus === "SENT" || row.commonStatus === "PARTIALLY_PROCESSED" || row.commonStatus === "SUCCESS" || row.commonStatus === "PENDING")) ||
              (row.commonStatus === 'GENERATED' && whitelistingAutomation)
             ){
              processDisabled = true;
            }

            buttons = [
              {
                type: "button",
                label: "Process",
                disabled: processDisabled,
                onClick: () => sendHardwareCommand(row.hardware_command_id, true, "process"),
                style: processDisabled ? { display: "none" } : { width: "80px", marginBottom: "10px" }
              },
              {
                type: "button",
                label: "Show log",
                disabled: !row.log,
                onClick: () => onLogButtonPress(row.log, row.hardware_command_id),
                style: { width: "80px", marginBottom: "10px" }
              }
            ]
          }
       
          return {
              columns: [
                {
                  type: "text",
                  label: row.hardware_command_id,
                  columnId: "hardwareCommandId"
                },
                {
                  type: "text",
                  label: convertDataEpochToDate(row.created, null, null, true, true, true) || NoDataLabels.DASH,
                  columnId: "Created",
                  assetId: row.hardware_command_id,
                  addBreak: true
                },
                {
                  type: "text",
                  label: row.mac || NoDataLabels.DASH,
                  columnId: "GwEsn",
                  assetId: row.hardware_command_id
                },
                {
                  type: "text",
                  label: row.command_type || NoDataLabels.DASH,
                  columnId: "CommandType",
                  data: "",
                  assetId: row.hardware_command_id,
                },
                {
                  type: "text",
                  label: row.commonStatus,
                  columnId: "CommonStatus",
                  assetId: row.hardware_command_id,
                },
                {
                  type: "json",
                  label:  row.detailedStatus,
                  columnId: "detailedStatus",
                  assetId: row.hardware_command_id,
                  width: "300px"
                },
                {
                  type: "json",
                  label: row.payload,
                  columnId: "Payload",
                  assetId: row.hardware_command_id,
                  width: "300px"
                },
                {
                  type: "text",
                  label:  (row.delivery_confirmed_at_epoch && convertDataEpochToDate(row.delivery_confirmed_at_epoch, null, null, true, false, true)) || NoDataLabels.DASH,
                  columnId: "deliveryConfirmedAt",
                  data: "date",
                  assetId: row.hardware_command_id
                },
                {
                  type: "text",
                  label:  (row.delivery_last_attempt_at_epoch && convertDataEpochToDate(row.delivery_last_attempt_at_epoch, null, null, true, false, true)) || NoDataLabels.DASH,
                  columnId: "deliveryLastAttemptAt",
                  data: "date",
                  assetId: row.hardware_command_id
                },
                {
                  type: "text",
                  label: row.delivery_attempts_count || NoDataLabels.DASH,
                  columnId: "deliveryAttemptsCount",
                  assetId: row.hardware_command_id,
                },
                {
                  type: "text",
                  label: row.modified_by || NoDataLabels.DASH,
                  columnId: "modifiedBy",
                  assetId: row.hardware_command_id,
                  max_width: "130px"
                },
                {
                  type: "buttons",
                  columnId: "MarkAsReadyToBeSent",
                  assetId: row.hardware_command_id,
                  buttons
                },
              ],
              id: row.hardware_command_id
          }
      })

      setBody(tableBody)
    }, [data, putHardwareCommand, onLogButtonPress, whitelistingAutomation])

    return body
}

export const useTableColumns = () => {
    const [data, setData] = useState([])

    useEffect(() => {
        const columns = [
            {
                label: "Hardware Command Id",
                id: "hardwareCommandId"
            },
            {
                label: "Created",
                id: "Created",
            },
            {
                label: "ESN",
                id: "GwEsn",
                customInlineStyle: {width: "125px"}
            },
            {
                label: "Command Type",
                id: "CommandType",
            },
            {
                label: "Common Status",
                id: "CommonStatus",
            },
            {
                label: "Detailed Status",
                id: "detailedStatus",
            },
            {
                label: "Payload",
                id: "Payload",
            },
            {
                label: "Delivery Confirmed At",
                id: "deliveryConfirmedAt"
            },
            {
                label: "Delivery Last Attempt At",
                id: "deliveryLastAttemptAt"
            },
            { 
                label: "Delivery Attempts Count",
                id: "deliveryAttemptsCount"
            },
            {
                label: "Modified By",
                id: "modifiedBy"
            },
            {
                label: "",
                id: "MarkAsReadyToBeSent",
                customStyle: "th_button"
            }
        ]

        setData(columns)
    }, [])

    return data
}