import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { Button, Col } from "reactstrap";
import SubNav from "../../components/SubNav";
import RivataLoader from "../../components/RivataLoader"
import ModuleOrder from "../../modules/ModuleOrder";
import ColorPicker from "../../modules/ColorPicker";
import FileUploader from "../../modules/FileUploader";
import ResetWhitelabelStyles from "../../modules/ResetWhitelabelStyles";
import {
  fetchWhitelabelAdmin,
  postWhitelabel,
  setWhitelabelAdmin,
  setWhitelabelAdminDirty,
  setSmarthubHealthSvg,
  setTpmsHealthSvg,
  setLinePressureHealthSvg,
  setAxleLoadHealthSvg,
  setGatewayHealthSvg,
  setLogoIconPng,
  setLogoFullPng,
  resetWhitelabelColorsToDefault,
  setWhitelabelAdminError
} from "../../redux/whitelabelAdmin/action";
import { WhiteLabelFilenames } from "../../enums";
import CustomerDefaults from "../../modules/CustomerDefaults";
import ErrorJumbo from "../../components/ErrorJumbo";
import CustomerSelector from "../../modules/CustomerSelector";

import "./style.scss";
import InfoModal from "../../components/InfoModal";
import { getStatusObj } from "../../utils/utils";

const parseWhitelabelAccountName = (name) => {
  const regex = /[^0-9a-zA-Z]+/g;
  name = name.trim().toLowerCase();
  name = name.replaceAll(regex, "_");
  if (name.startsWith("_")) {
    name = name.substring(1);
  }
  if (name.endsWith("_")) {
    name = name.substring(0, name.length - 1)
  }

  return name;
}

const WhiteLabel = ({
  fetchConfig,
  setConfig,
  postConfig,
  setDirty,
  setSmarthubHealthSvg,
  setTpmsHealthSvg,
  setLinePressureHealthSvg,
  setAxleLoadHealthSvg,
  setGatewayHealthSvg,
  setLogoIconPng,
  setLogoFullPng,
  resetWhitelabelColorsToDefault,
  setWhitelabelAdminError,
  whitelabel: {
    isLoading,
    isPosting,
    dirty,
    postedConfig,
    locale = {},
    config,
    error
  },
  customers: {
    data,
    selectedCustomersList,
    // error
  }
}) => {
  const handleUpdate = (newItems) => {
    setConfig(newItems, true);
    setDirty(true);
  };

  const show = !isLoading && config;

  const whiteLabel = (
    (selectedCustomersList[0]?.key && parseWhitelabelAccountName(selectedCustomersList[0].key)) ||
    ""
  ).toLowerCase();

  const btnValue = useMemo(() => {
    if (isPosting) return locale.savingEllipsis || "Saving..." 
    else if (postedConfig) return locale.Saved || "Saved"
    else return locale.Save || "Save"
  }, [locale, isPosting, postedConfig])
  
  useEffect(() => {
    if (selectedCustomersList.length === 1) fetchConfig()
  }, [fetchConfig, selectedCustomersList])

  return (
    <>
      <InfoModal
        open={error.statusCode === 200}
        locale={locale}
        header="Whitelabel"
        message={error.message}
        onConfirm={() => setWhitelabelAdminError(getStatusObj())}
      />

      {error.statusCode >= 400 ? (
        <ErrorJumbo
          statusCode={error.statusCode >= 400 ? error.statusCode : null}
          statusText={error.message ? error.message : ""}
        />
      ) : (
        <>
          <SubNav
            title="Whitelabel"
            breadcrumbs={[{ label: "Home", path: "/dashboard" }, { label: "Whitelabel" }]}
          >
            {data.length && (
              <>
                <CustomerSelector/>
                <Button
                  color="primary"
                  disabled={!dirty || isPosting || selectedCustomersList.length !== 1}
                  className="ml-3"
                  onClick={async () => {
                    postConfig(selectedCustomersList[0].id);
                    setDirty(false);
                  }}
                >
                  {btnValue}
                </Button>
              </>
            )}
          </SubNav>
          {show ? (
            <>
              <CustomerDefaults 
                title={"Defaults Settings"}
                locale={locale}
                width={12}
                preferences={config.preferenceDefaults}
                onUpdate={(preferenceDefaults) => handleUpdate({ ...config, preferenceDefaults })}
              />
              <Col 
                className="d-flex justify-content-between flex-wrap col-12"
              >
                <ModuleOrder
                  initialItems={config.dash}
                  width={4}
                  customClass="col-xl-4 col-lg-6 whitelabel-config pl-0"
                  title="Dashboard Page"
                  locale={locale}
                  onUpdate={(dash) => handleUpdate({ ...config, dash })}
                />
                <ModuleOrder
                  initialItems={config.details}
                  width={4}
                  customClass="col-xl-4 col-lg-6 whitelabel-config"
                  title="Details Page"
                  locale={locale}
                  onUpdate={(details) => handleUpdate({ ...config, details })}
                />
                <ModuleOrder
                  initialItems={config.warnings}
                  width={4}
                  customClass="col-xl-4 col-lg-6 whitelabel-config pr-0"
                  title="Warning Details Page"
                  locale={locale}
                  onUpdate={(warnings) => handleUpdate({ ...config, warnings })}
                />
              </Col>
              <FileUploader
                title="Health Icons"
                locale={locale}
                whiteLabel={whiteLabel}
                items={[
                  {
                    filename: WhiteLabelFilenames.gatewayHealthSvg,
                    label: "Gateway Health Symbol",
                    text: "GatewayHealthSymbolText",
                    id: "GatewayHealthSymbol",
                    accept: "image/svg+xml",
                    onUpdate: (file, _filename) => {
                      setDirty(true);
                      setGatewayHealthSvg(file);
                    },
                    uploadImage: () => postConfig(selectedCustomersList[0].id)
                  },
                  {
                    filename: WhiteLabelFilenames.smarthubHealthSvg,
                    label: "SmartHub Health Symbol",
                    text: "SmartHubHealthSymbolText",
                    id: "SmartHubHealthSymbol",
                    accept: "image/svg+xml",
                    onUpdate: (file, _filename) => {
                      setDirty(true);
                      setSmarthubHealthSvg(file);
                    },
                    uploadImage: () => postConfig(selectedCustomersList[0].id)
                  },
                  {
                    filename: WhiteLabelFilenames.tpmsHealthSvg,
                    label: "TPMS Health Symbol",
                    text: "TpmsHealthSymbolText",
                    id: "TpmsHealthSymbol",
                    accept: "image/svg+xml",
                    onUpdate: (file, _filename) => {
                      setDirty(true);
                      setTpmsHealthSvg(file);
                    },
                    uploadImage: () => postConfig(selectedCustomersList[0].id)
                  },
                  {
                    filename: WhiteLabelFilenames.linePressureHealthSvg,
                    label: "Line Pressure Health Symbol",
                    text: "LinePressureHealthSymbolText",
                    id: "LinePressureHealthSymbol",
                    accept: "image/svg+xml",
                    onUpdate: (file, _filename) => {
                      setDirty(true);
                      setLinePressureHealthSvg(file);
                    },
                    uploadImage: () => postConfig(selectedCustomersList[0].id)
                  },
                  {
                    filename: WhiteLabelFilenames.axleLoadHealthSvg,
                    label: "Axle Load Health Symbol",
                    text: "AxleLoadHealthSymbolText",
                    id: "AxleLoadHealthSymbol",
                    accept: "image/svg+xml",
                    onUpdate: (file, _filename) => {
                      setDirty(true);
                      setAxleLoadHealthSvg(file);
                    },
                    uploadImage: () => postConfig(selectedCustomersList[0].id)
                  }
                ]}
              />
              <FileUploader
                title="Brand Logos"
                locale={locale}
                whiteLabel={whiteLabel}
                items={[
                  {
                    filename: WhiteLabelFilenames.logoFullPng,
                    text: "LogoFullText",
                    id: "LogoFull",
                    accept: "image/png",
                    onUpdate: (file, _filename) => {
                      setDirty(true);
                      setLogoFullPng(file);
                    },
                    uploadImage: () => postConfig(selectedCustomersList[0].id)
                  },
                  {
                    filename: WhiteLabelFilenames.logoIconPng,
                    label: "Logo Icon",
                    text: "LogoIconText",
                    id: "LogoIcon",
                    accept: "image/png",
                    onUpdate: (file, _filename) => {
                      setDirty(true);
                      setLogoIconPng(file);
                    },
                    uploadImage: () => postConfig(selectedCustomersList[0].id)
                  },
                ]}
              />
              <ResetWhitelabelStyles
                resetStyles={resetWhitelabelColorsToDefault}
              />
              <ColorPicker
                title="Theme Colors"
                locale={locale}
                colorsData={config.themeColors}
                onUpdate={(themeColors) => handleUpdate({ ...config, themeColors })}
              />
              <ColorPicker
                title="Health Colors"
                locale={locale}
                colorsData={config.healthColors}
                onUpdate={(healthColors) =>
                  handleUpdate({ ...config, healthColors })
                }
              />
              <ColorPicker
                title="Graph Palette"
                locale={locale}
                colorsData={config.wheelColors}
                onUpdate={(wheelColors) => handleUpdate({ ...config, wheelColors })}
                showControlButtons={true}
                onAddAxles={(wheelColors) => handleUpdate({ ...config, wheelColors })}
                onRemoveAxles={(wheelColors) => handleUpdate({ ...config, wheelColors })}
              />
              <ColorPicker
                title="Gps Palette"
                locale={locale}
                colorsData={config.gpsColors}
                onUpdate={(gpsColors) => handleUpdate({ ...config, gpsColors })}
              />
            </>
          ) : isLoading ? (
            <RivataLoader/>
          ) : null}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  whitelabel: state.whitelabelAdmin,
  customers: state.common.customers
});

const mapDispatchToProps = (dispatch) => ({
  fetchConfig: () => dispatch(fetchWhitelabelAdmin()),
  postConfig: (customerId) => dispatch(postWhitelabel(customerId)),
  setConfig: (config, isUpdate) => dispatch(setWhitelabelAdmin(config, isUpdate)),
  setDirty: (dirty) => dispatch(setWhitelabelAdminDirty(dirty)),
  setSmarthubHealthSvg: (file) => dispatch(setSmarthubHealthSvg(file)),
  setTpmsHealthSvg: (file) => dispatch(setTpmsHealthSvg(file)),
  setLinePressureHealthSvg: (file) => dispatch(setLinePressureHealthSvg(file)),
  setAxleLoadHealthSvg: (file) => dispatch(setAxleLoadHealthSvg(file)),
  setGatewayHealthSvg: (file) => dispatch(setGatewayHealthSvg(file)),
  setLogoIconPng: (file) => dispatch(setLogoIconPng(file)),
  setLogoFullPng: (file) => dispatch(setLogoFullPng(file)),
  resetWhitelabelColorsToDefault: () => dispatch(resetWhitelabelColorsToDefault()),
  setWhitelabelAdminError: (p) => dispatch(setWhitelabelAdminError(p))
});

export default connect(mapStateToProps, mapDispatchToProps)(WhiteLabel);
