import React, { useEffect, useState } from 'react'
import { Input, FormFeedback } from 'reactstrap'

type Props = {
    disabled: boolean,
    onChange: (val: number) => void,
    dwellDays: any,
    width?: number
}

const DwellDaysInput: React.FC<Props> = ({
    disabled,
    onChange,
    dwellDays,
    width
}) => {
    const [value, setValue] = useState(dwellDays)
    const [dwellDaysInputInvalid, setDwellDaysInputInvalid] = useState(true)

    useEffect(() => {
        setDwellDaysInputInvalid(value && (value <= 0 || !Number.isInteger(Number(value))));
    }, [value, setDwellDaysInputInvalid])

    useEffect(() => {
        if (!dwellDaysInputInvalid) {
            onChange(parseInt(value))
        }
    }, [value, dwellDaysInputInvalid, onChange])

    return (
        <>
            <div className="mt-4" style={{ width }}>
                <label className="mr-2 mb-2" style={{ minWidth: "120px" }}>Dwell Days: </label>
                <Input
                    type="number"
                    step="1"
                    placeholder={"Enter Days"}
                    value={value}
                    onChange={(ev: any) => { setValue(ev.target.value) }}
                    disabled={disabled}
                    invalid={dwellDaysInputInvalid}
                />
                <FormFeedback>
                    Dwell Days value should be empty or positive integer.
                </FormFeedback>
            </div>
        </>
    )
}

export default DwellDaysInput