import { useEffect, useState } from 'react'
import { IColumn, IRow } from '../../components/RivataGrid'
import { convertDataEpochToDate, getClearLabel } from '../../utils'
import { NoDataLabels, ReportStatus, TagReportTypes } from '../../enums'
import {
  IActionColumnConfig,
  composeDynamicActionsFormatter,
} from '../../components/RivataGrid/cellFormatter'

interface IuseColumns {
  (
    data: Array<any>,
    onShowResults: (item: any) => void,
    onShowParameters: (item: any) => void,
  ): Array<IColumn>
}

export const useColumns: IuseColumns = (
  data: Array<IColumn>,
  onShowResults: (item: any) => void,
  onShowParameters: (item: any) => void,
) => {
  const [columns, setColumns] = useState<Array<IColumn>>([])

  useEffect(() => {
    const completedConfig: IActionColumnConfig = {
      onCustomAction1: (id) => {
        const result = data.find((item: any) => {
          return item.id === id
        })
        onShowResults(result)
      },
      onCustomAction2: (id) => {
        const result = data.find((item: any) => {
          return item.id === id
        })
        onShowParameters(result)
      },
      customAction1Icon: 'fa fa-eye fa-lg',
      customAction2Icon: 'fa fa-eye fa-gear',
      tooltipTexts: {
        customAction1TooltipText: 'View report result',
        customAction2TooltipText: 'View filters parameters',
      },
    }
    const otherConfig: IActionColumnConfig = {
      onCustomAction2: (id) => {
        const result = data.find((item: any) => {
          return item.id === id
        })
        onShowParameters(result)
      },
      customAction2Icon: 'fa fa-eye fa-gear',
      tooltipTexts: {
        customAction2TooltipText: 'View filters parameters',
      },
    }

    const colsList: Array<IColumn> = [
      {
        name: 'Actions',
        key: 'actions',
        minWidth: 80,
        width: 80,
        formatter: ({ row }: any) => {
          if (row.status.toUpperCase() === ReportStatus.COMPLETED)
            return composeDynamicActionsFormatter({
              ...completedConfig,
              cellData: row,
            })
          else {
            return composeDynamicActionsFormatter({
              ...otherConfig,
              cellData: row,
            })
          }
        },
      },
      { name: 'Report Name', key: 'report_name', width: 280 },
      { name: 'Status', key: 'status' },
      { name: 'Completed', key: 'end_dt' },
      { name: 'Is Scheduled', key: 'is_schedule' },
      { name: 'Schedule name', key: 'schedule_name' },
    ]

    setColumns(colsList)
  }, [data, onShowResults, onShowParameters])

  return columns
}

interface IuseRows {
  (data: Array<IScheduledReport>): Array<IRow>
}

export const useRows: IuseRows = (data) => {
  const [rows, setRows] = useState<Array<IRow>>([])

  useEffect(() => {
    let rowsList: any = []

    if (data && data.length > 0) {
      rowsList = data.map((row: any) => {
        if (row.report_name === 'CARGO_TAGS_REPORT') {
          row.report_name = TagReportTypes.LATEST_TAG_LOCATION_REPORT
        }
        return {
          id: row.id,
          selected: false,
          report_name: getClearLabel(row.report_name),
          status: getClearLabel(row.status),
          is_schedule: row.is_schedule ? 'Yes' : 'No',
          end_dt: row.end_dt
            ? convertDataEpochToDate(row.end_dt, null, null, true, false, true)
            : NoDataLabels.TRIPPLE_DASH,
          schedule_name: row.schedule_name
            ? row.schedule_name
            : NoDataLabels.TRIPPLE_DASH,
        }
      })
    }

    setRows(rowsList)
  }, [data])

  return rows
}
