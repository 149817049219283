import React from 'react'
import moment from 'moment'
import HealthStatusFilter from '../../../componentsV2/HealthStatusFilter'
import DateTimePicker from '../../../componentsV2/DateTimePicker'
import AssetGroupsFilter from '../../../componentsV2/AssetGroupsFilter'
import AssetsSelect from './AssetsSelect'
import { CalendarTypes } from '../../../enums'
import { MenuItemType } from '../../../componentsV2/Menu/MenuItem'
import { SearchItemType } from '../../../components/ItemsSearch'

type Props = {
  dateFrom: Date
  dateTo: Date
  onDateRangeSelect: (from: Date, to: Date) => void
  sersorStatusItemsFiltered: Array<any>
  onWarningItemSelectNew: (id: string, conf: Array<MenuItemType>) => void
  assetTypes: Array<{ id: string; label: string }>
  selectedAssetType: 'tractor' | 'trailer' | 'all'
  setSelectedAssetType: React.Dispatch<React.SetStateAction<string>>
  selectedAssetsItems: Array<SearchItemType>
  setVinList: React.Dispatch<React.SetStateAction<Array<Object>>>
  customerIds?: string | ''
  onAssetGroupFilterItemSelected?: (
    id: string,
    selectedValue: MenuItemType[],
  ) => void
  disabled: boolean
}

const MilesInWarningStateReportForm: React.FC<Props> = ({
  dateFrom,
  dateTo,
  onDateRangeSelect,
  sersorStatusItemsFiltered,
  onWarningItemSelectNew,
  assetTypes,
  selectedAssetType,
  setSelectedAssetType,
  selectedAssetsItems,
  setVinList,
  customerIds,
  onAssetGroupFilterItemSelected,
  disabled,
}) => {
  return (
    <>
      <HealthStatusFilter
        id='warning_type_new'
        className='mr-2'
        overrideDefaultItems={sersorStatusItemsFiltered}
        onDropdownItemSelect={onWarningItemSelectNew}
      />

      <div className='mb-2 mt-3'>Select Date Range</div>
      <DateTimePicker
        defaultMode={CalendarTypes.DateRange}
        dateFrom={dateFrom.toISOString()}
        dateTo={dateTo.toISOString()}
        onDateRangeSelect={onDateRangeSelect}
        maxDate={moment().toDate()}
        disabled={disabled}
      />

      <div className='mt-3'>
        <AssetsSelect
          disabled={disabled}
          assetTypes={assetTypes}
          selectedAssetType={selectedAssetType}
          setSelectedAssetType={setSelectedAssetType}
          setVinList={setVinList}
          selectedItems={selectedAssetsItems}
        />
      </div>

      <AssetGroupsFilter
        className='mt-3'
        onDropdownItemSelect={onAssetGroupFilterItemSelected}
        customerIds={customerIds}
      />
    </>
  )
}

export default MilesInWarningStateReportForm
