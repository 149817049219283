import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './styles.scss'
import { getSensorFromSensorType, getClearLabel } from '../../utils'
import {
  getWarningDescription,
  saveGoogleAnalyticsEvent,
} from '../../utils/utils'
import { Checkbox } from '../../components/RivataTable/TableRow'
import AlertInfoModal from './AlertInfoModal'
import { NoDataLabels } from '../../enums'

const getWarningPosition = (locale, position, sensor_type) => {
  if (sensor_type === 'line_pressure') {
    return locale['chassis']
  } else if (sensor_type === 'gateway') {
    return locale['gateway']
  } else if (sensor_type === 'axle_load') {
    return 'Air Suspension'
  }

  return position
    ? locale[position] || getClearLabel(position)
    : NoDataLabels.DASH
}

const WarningRow = ({
  locale,
  isAdmin,
  isSuperAdmin,
  onCheckRowItem,
  vin,
  timezone,
  days,
  healthColors,
  unitsOfMeasurement,
  isChecked,
  columns,
  ...data
}) => {
  const [alertModalOpen, setAlertModalOpen] = useState(false)
  const {
    warning_type,
    warning_subtype,
    datetime,
    formatted_datetime,
    position,
    duration_in_seconds,
    warning_value,
    acknowledged,
    sensor_type,
    is_recovered_to_normal,
    rowId,
    formatted_recovered_to_normal_at_datetime,
    alert_messages,
    recipients,
    max_warning_value,
    canceled,
    formatted_canceled_at_datetime,
    cancel_reason,
    threshold,
  } = data
  const timestamp = +datetime
  const highlight = window.location.href.includes(rowId)

  const toWarningDetailsLink = `/details/${vin}/warnings/${timestamp}/${days}/${rowId}`

  let rowHighlighColor = null

  if (
    highlight &&
    healthColors &&
    Array.isArray(healthColors) &&
    healthColors[1]
  ) {
    rowHighlighColor = healthColors[1].color
  }

  return (
    <tr
      onClick={() => {
        saveGoogleAnalyticsEvent(`recent_warning_on_click`)
      }}
      style={{ backgroundColor: rowHighlighColor }}
      className={acknowledged ? `table-row acknowledged` : `table-row`}
    >
      {isAdmin && (
        <td>
          {!Boolean(acknowledged) && !canceled && (
            <Checkbox
              disabled={false}
              onCheck={onCheckRowItem}
              data={data}
              checked={isChecked}
            />
          )}
        </td>
      )}
      {columns.map((col) => {
        if (col.id !== 'CHECK')
          switch (col.id) {
            case 'TIME':
              return (
                <td>
                  <Link to={toWarningDetailsLink}>{formatted_datetime}</Link>
                </td>
              )
            case 'LOCATION':
              return (
                <td>
                  <Link to={toWarningDetailsLink}>
                    {getWarningPosition(locale, position, sensor_type)}
                  </Link>
                </td>
              )
            case 'TYPE':
              return (
                <td>
                  <Link to={toWarningDetailsLink}>
                    {warning_type ? locale[warning_type] : NoDataLabels.DASH}
                  </Link>
                </td>
              )
            case 'DESCRIPTION':
              return (
                <td>
                  <div className='d-flex'>
                    {alert_messages && isSuperAdmin && (
                      <>
                        <i
                          className='fa fa-exclamation-circle font-16 alert-icon'
                          style={{ cursor: 'pointer' }}
                          onClick={() => setAlertModalOpen(true)}
                        />
                        <AlertInfoModal
                          locale={locale}
                          open={alertModalOpen}
                          header='Alert info'
                          messages={alert_messages}
                          onConfirm={() => setAlertModalOpen(!alertModalOpen)}
                          recipients={recipients}
                        />
                      </>
                    )}
                    <Link
                      to={`/details/${vin}/warnings/${timestamp}/${days}/${rowId}`}
                    >
                      {getWarningDescription(
                        warning_type,
                        warning_subtype,
                        duration_in_seconds,
                        warning_value,
                        is_recovered_to_normal,
                        locale,
                        unitsOfMeasurement,
                        formatted_recovered_to_normal_at_datetime,
                        max_warning_value,
                        canceled,
                        formatted_canceled_at_datetime,
                        cancel_reason,
                        threshold,
                      )}
                    </Link>
                  </div>
                </td>
              )
            case 'SENSORTYPE':
              return (
                <td>
                  <Link to={toWarningDetailsLink}>
                    {getSensorFromSensorType(sensor_type)}
                  </Link>
                </td>
              )
            default:
              return null
          }
        else return null
      })}
    </tr>
  )
}

export default WarningRow
