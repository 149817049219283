import React, { useMemo } from 'react'
import { Row } from 'reactstrap'
import { History, Location } from 'history'

import Header from '../Header'
import Sidebar from '../Sidebar'
import Footer from '../Footer'
import ErrorJumbo from '../ErrorJumbo'
import ErrorBoundary from '../../utils/ErrorBoundary'
import NoAccessPage from '../NoAccessPage/NoAccessPage'
import FailedTokenRefreshModal from '../FailedTokenRefreshModal'

import {
  sidebarAdminRoutes,
  sidebarRoutes,
  superAdminRoutes,
  INavRoute,
} from './navRoutes'

import { useTypedSelector } from '../../hooks/useTypedSelector'

import { Path } from '../../enums'

import './styles.scss'

const blockedRoles = ['DRIVER', 'INSTALLER', 'INSTALLER_ADMIN']

interface IRivataPageProps {
  history: History
  location: Location
  children: any
}

const RivataPage: React.FC<IRivataPageProps> = ({ location, children }) => {
  const {
    auth: {
      whiteLabel,
      whiteLabelEpoch,
      whiteLabelUrl,
      isAdmin,
      isSuperAdmin,
      user,
      loginChecked,
      failedTokenRefresh,
      isLoggedIn,
      preferences: {
        phoneNumberVerified,
        isAssignedToSmsAlerts,
        availableCustomersSubscriptions,
        roles,
      },
    },
    error,
    hasCheckedForConfig,
    customerDefaults,
    versionInfo,
    isDefaultsLoading,
  } = useTypedSelector((state) => ({
    auth: state.auth,
    error: state.whitelabelAdmin.error,
    hasCheckedForConfig: state.whitelabel.hasCheckedForConfig,
    customerDefaults: state.common.customerDefaults,
    versionInfo: state.common.versionInfo,
    isDefaultsLoading: state.common.isDefaultsLoading,
  }))

  const haveAccess = useMemo(() => {
    return !roles?.every((role: string) => blockedRoles.includes(role))
  }, [roles])

  const navRoutes = useMemo(() => {
    let routes: INavRoute[] = []
    if (isSuperAdmin) {
      routes = [...superAdminRoutes]
    } else if (isAdmin) {
      routes = [...sidebarAdminRoutes]
    } else if (haveAccess) {
      routes = [...sidebarRoutes]
    }

    if (isSuperAdmin || isDefaultsLoading) return routes

    const { cargo_tags_subscription, subscriptions } = customerDefaults

    if (cargo_tags_subscription && Object.keys(subscriptions).length === 0) {
      const idx = routes.findIndex((el) => el.path === '/dashboard')
      if (idx > -1) routes.splice(idx, 1)
    }
    if (cargo_tags_subscription === false) {
      const idx = routes.findIndex((el) => el.path === Path.TagLocations)
      if (idx > -1) routes.splice(idx, 1)
    }
    if (!availableCustomersSubscriptions?.valid_tpms_subscription) {
      const idx = routes.findIndex((el) => el.path === Path.TpmsProfiles)
      if (idx > -1) routes.splice(idx, 1)

      // no subscriptions
      if (
        !availableCustomersSubscriptions?.valid_line_pressure_subscription &&
        !availableCustomersSubscriptions?.valid_smarthub_subscription &&
        !availableCustomersSubscriptions?.valid_axle_load_subscription &&
        !availableCustomersSubscriptions?.valid_gateway_subscription
      ) {
        const idx = routes.findIndex(
          (el) => el.path === Path.ManageNotifications,
        )
        if (idx > -1) routes.splice(idx, 1)
      }
    }

    return routes
  }, [
    isSuperAdmin,
    isAdmin,
    customerDefaults,
    isDefaultsLoading,
    availableCustomersSubscriptions,
    haveAccess,
  ])

  const keys = Object.keys(customerDefaults)
  const isNotReady =
    !loginChecked ||
    (isLoggedIn && !hasCheckedForConfig) ||
    (!keys.length && isDefaultsLoading)

  const { pathname } = location

  if (pathname.split('/').includes('authentication')) {
    return children
  }

  // return children anyway because from there we get session
  if (isNotReady) return children

  return (
    <>
      {whiteLabel && (
        <link
          href={`${whiteLabelUrl}style.css?d=${whiteLabelEpoch}`}
          rel='stylesheet'
        />
      )}
      <div
        id='main-wrapper'
        dir={'ltr'}
        data-theme={'light'}
        data-layout={'vertical'}
        data-sidebartype={'mini-sidebar'}
        data-sidebar-position={'fixed'}
        data-header-position={'fixed'}
        data-boxed-layout={'full'}
      >
        {error?.statusCode >= 400 ? (
          <ErrorJumbo
            statusCode={error.statusCode >= 400 ? error.statusCode : null}
            statusText={error.message ? error.message : ''}
          />
        ) : !haveAccess ? (
          <NoAccessPage />
        ) : (
          <>
            <Header
              whiteLabelUrl={whiteLabelUrl}
              user={user}
              phoneNumberVerified={phoneNumberVerified}
              isAssignedToSmsAlerts={isAssignedToSmsAlerts}
            />
            <Sidebar
              navRoutes={navRoutes}
              location={location}
              versionInfo={versionInfo}
            />
            <main
              className='page-wrapper d-block'
              style={{ paddingTop: '8rem' }}
            >
              <div
                className='page-content container-fluid'
                style={{ maxWidth: 1600 }}
              >
                <ErrorBoundary>
                  <Row error={error}>{children}</Row>
                </ErrorBoundary>
              </div>
              <Footer />
            </main>
          </>
        )}
      </div>

      <FailedTokenRefreshModal failedTokenRefresh={failedTokenRefresh} />
    </>
  )
}

export default RivataPage
