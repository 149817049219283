import { MenuItemType } from '../Menu/MenuItem'
export const warningStatusItems: Array<MenuItemType> = [
  {
    id: 'active',
    label: 'Active',
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: null,
  },
  {
    id: 'recovered',
    label: 'Recovered',
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: null,
  },
  {
    id: 'canceled',
    label: 'Cancelled',
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: null,
  },
]
