import React, { useState, useEffect } from 'react'
import { Button } from 'reactstrap'
import JSONInput from 'react-json-editor-ajrm';
import JSONlocale from 'react-json-editor-ajrm/locale/en';

import RivataModule from '../../components/RivataModule'
import RivataLoader from '../../components/RivataLoader'
import StatusAlert from '../../components/StatusAlert'
import ConfirmModal from '../../components/ConfirmModal'
import InfoModal from '../../components/InfoModal'
import { getErrorObj } from '../../utils'
import CopyProvisionModal from './CopyProvisionModal';
import { provisionSchema } from "./utils";

import { useTypedSelector } from '../../hooks/useTypedSelector';
import { useActions } from '../../hooks/useActions';
import { mixed, object } from "yup";
import './style.scss'


const composeCopyToEnviromentsBody = (enviromentsDetails, provisioning) => {
    return {
        ...enviromentsDetails,
        provisioning_data: provisioning
    }
}

const ProvisionAssetJson = ({
    locale = {},
    vin,
}) => {
    const {
        provisionInfo: { isLoading, data, error },
    } = useTypedSelector((state) => ({
        provisionInfo: state.assetAdminInfo.provisionInfo,
    }))
    const {
        postProvision,
        setProvisionInfoError,
        postCopyToEnviroments,
        fetchProvisionJson
    } = useActions()

    const [copyProvisionModalVisible, setCopyProvisionModalVisible] = useState(false);
    const [jsonInputValue, setJsonInputValue] = useState(null)
    const [jsonError, setJsonError] = useState(false)
    const [provisionStatus, setProvisionStatus] = useState(null)
    const [sensorsError, setSensorsError] = useState("")
    const [confirmStealingMessage, setConfirmStealingMessage] = useState(null);

    useEffect(() => {
        fetchProvisionJson(vin)
    }, [fetchProvisionJson, vin])

    useEffect(() => {
        setJsonInputValue(data)
    }, [data])

    const submithandler = async (sensorStealingAcknowledged) => {
        if (!jsonError) {
            const validationError = await provisionSchema.strict().validate(jsonInputValue).then(() => null).catch(err => {
                if (err.type === 'typeError')
                    return err.message.split(',')[0]

                return err.message
            })

            if (validationError) {
                setSensorsError(validationError)
                return
            }

            const payload = {
                "provisioning_data": jsonInputValue,
                "sensor_stealing_acknowledged": sensorStealingAcknowledged
            }

            const status = await postProvision(payload)
            if (status["concerned_assets_with_macs"]) {
                let message = "";
                const concerned_assets_with_macs = status["concerned_assets_with_macs"]
                Object.keys(concerned_assets_with_macs).forEach((asset_vin) => {
                    message += `Hardware ${concerned_assets_with_macs[asset_vin].map(hw => hw["mac"]).join(", ")} already belongs to asset: ${asset_vin}; `;
                })

                setConfirmStealingMessage(message)
            }
            else {
                setProvisionStatus(status)
            }
        }
    }

    const [copyButtonDisabled, setCopyButtonDisabled] = useState(false)

    const copyJson = () => {
        if (!data) return

        navigator.clipboard.writeText(JSON.stringify(data))

        setCopyButtonDisabled(true)

        setTimeout(() => {
            setCopyButtonDisabled(false)
        }, 500)
    }

    const copyJsonToEnviroment = (selectedEnviroments) => {
        if (!jsonError) {
            //RIVS-3308: workaround to allow null for ati_installed only when json is copied across environments
            const copySchema = provisionSchema.concat(object({
                asset: object({
                    ati_installed: mixed().nullable()
                        .equals(
                            [null, 0, 1, 2],
                            "Unsupported ati_installed option. Options: 0: no, 1: yes, 2: not sure or null"
                        ),
                }).required(),
            }));
            let validationError = null
            try {
                copySchema.strict().validateSync(jsonInputValue)
            }
            catch (err) {
                if (err.type === 'typeError')
                    validationError = err.message.split(',')[0]
                else
                    validationError = err.message
            }
            if (validationError) {
                setSensorsError(validationError)
                return
            }

            postCopyToEnviroments(composeCopyToEnviromentsBody(selectedEnviroments, jsonInputValue));
            setCopyProvisionModalVisible(false);
        }
    }

    const composeErrorMessage = (message) => {
        if (message.includes('must be a') || message.includes('must be at most')) {
            message = message.replace("asset.", "");

            const words = message.split(' ');

            if (words.length > 0) {
                words[0] = `'${words[0]}'`;
            }

            return words.join(' ');
        }
        
        return message;
    }

    return (
        <RivataModule
            title={`Provision Info`}
            locale={locale}
            marginTop={0}
        >
            {!isLoading && !Object.keys(data).length ? (
                <StatusAlert customText="No Data" color="success" />
            ) : (
                <>
                    <ConfirmModal
                        open={!!provisionStatus}
                        onClose={() => setProvisionStatus(null)}
                        modalButtons={[
                            { id: 1, label: "Ok", color: "success", onClick: () => setProvisionStatus(null), }
                        ]}
                    >
                        {provisionStatus?.statusCode === 200 ? `Success Provisioning for asset vin: ${jsonInputValue.asset.vin}` : `Something went wrong: ${provisionStatus?.message}`}
                    </ConfirmModal>
                    <ConfirmModal
                        header="Are you sure you want to continue provision?"
                        open={!!confirmStealingMessage}
                        onClose={() => setConfirmStealingMessage(null)}
                        modalButtons={[
                            {
                                id: 1, label: "Ok", color: "success", onClick: () => {
                                    setConfirmStealingMessage(null);
                                    submithandler(true);
                                }
                            }
                        ]}
                    >
                        {confirmStealingMessage}
                    </ConfirmModal>
                    <InfoModal
                        onConfirm={() => setProvisionInfoError(getErrorObj())}
                        locale={locale}
                        open={error.statusCode >= 400}
                        header={"Provisioning info"}
                        message={composeErrorMessage(error.message)}
                    />
                    <InfoModal
                        onConfirm={() => {
                            setSensorsError("")
                        }}
                        locale={locale}
                        open={!!sensorsError}
                        header={"Provisioning info"}
                        message={composeErrorMessage(sensorsError)}
                    />
                    <CopyProvisionModal
                        locale={locale}
                        open={copyProvisionModalVisible}
                        onClose={() => setCopyProvisionModalVisible(false)}
                        onConfirm={copyJsonToEnviroment}
                    />
                    {isLoading && <RivataLoader />}
                    <div>
                        <div className="d-flex justify-content-between">
                            <h3 className="mb-3" >Current Provision JSON</h3>

                            <div>
                                <Button
                                    onClick={copyJson}
                                    color="secondary"
                                    className="ml-3 mb-3"
                                    disabled={!data || copyButtonDisabled}
                                >
                                    Copy JSON
                                </Button>

                                {(process.env.REACT_APP_STAGE === "prod" || process.env.REACT_APP_STAGE === "staging") && <Button
                                    onClick={() => setCopyProvisionModalVisible(true)}
                                    color="secondary"
                                    className="ml-3 mb-3"
                                    disabled={!data || copyButtonDisabled}
                                >
                                    Copy JSON To Enviroment
                                </Button>}

                            </div>

                        </div>

                        <JSONInput
                            id='json'
                            locale={JSONlocale}
                            placeholder={data}
                            width="100%"
                            viewOnly={true}
                            confirmGood={false}
                            style={{ body: { padding: "1rem" } }}
                        />
                    </div>
                    <div>
                        <h3 className="mt-3 mb-3">POST New Provisioning JSON</h3>
                        <JSONInput
                            id='json_input'
                            locale={JSONlocale}
                            placeholder={data}
                            width="100%"
                            waitAfterKeyPress={100}
                            style={{ body: { padding: "1rem" } }}
                            onChange={(data) => {
                                setJsonError(!!data.error)
                                setJsonInputValue(data.jsObject)
                            }}
                        />
                        <div className="do-provision__button">
                            <Button
                                onClick={() => submithandler(false)}
                                color="primary"
                                className="mt-3"
                                disabled={jsonError}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                </>
            )}
        </RivataModule>
    )
}

export default ProvisionAssetJson