import React from 'react'
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
} from 'reactstrap'
import { pdfColumnOption } from '.'

type Props = {
  columns: Array<pdfColumnOption>
  onColumnsReset: () => void
  onColumnCheck: (name: string) => void
  onColumnWidthChange: (value: string, name: string) => void
  className?: string
}

const PDFColumnsOptions: React.FC<Props> = ({
  columns,
  onColumnCheck,
  onColumnWidthChange,
  onColumnsReset,
  className,
}) => {
  return (
    <UncontrolledDropdown className={className}>
      <DropdownToggle caret color='white'>
        Columns
      </DropdownToggle>

      <DropdownMenu
        style={{ maxHeight: '500px', overflow: 'scroll' }}
        right
        flip={false}
      >
        {columns.map((col) => {
          return (
            <DropdownItem
              key={`columnsCheckbox_${col.name}`}
              onClick={(e: any) => {
                if (!e.target.className?.includes('no-click'))
                  onColumnCheck(col.name)
              }}
              toggle={false}
            >
              <Input
                type='number'
                className='no-click mr-2 d-inline-block'
                style={{ width: '85px' }}
                placeholder='width'
                value={col.width}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  onColumnWidthChange(e.target.value, col.name)
                }}
              />
              <Input
                type='checkbox'
                checked={col.checked}
                onChange={() => {}}
              />
              {col.name}
            </DropdownItem>
          )
        })}

        <DropdownItem divider />

        <DropdownItem className='set-default' onClick={onColumnsReset}>
          Reset
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default PDFColumnsOptions
