import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from "reactstrap";

const DeleteModal = (props) => {
  const { disabled, onDelete, onCancel, locale = {}, open, header, message, children, warningMessage, error, deleteBtnColor = "primary" } = props;

  const WarningMessage = () => (
    <>
      <Label>{warningMessage}</Label> <br />
    </>
  );

  return (
    <div>
      <Modal isOpen={open && !disabled}>
        <ModalHeader toggle={onCancel}>
          {header}
        </ModalHeader>
        <ModalBody>
          { warningMessage && <WarningMessage />}
          {message ? message : children}
        </ModalBody>
        <ModalFooter>
          {error && <div className="error mr-3">{error}</div>}
          <Button
            color={deleteBtnColor}
            onClick={onDelete}
          >
            {locale["ok"] || "Ok"}
          </Button>{" "}
          <Button color="danger" onClick={onCancel}>
            {locale["cancel"] || "Cancel"}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DeleteModal;
