import React, { useCallback, useState } from 'react'

import PseudoBtn from '../../../components/CustomButtons/fakeBnt'
import CreateUserModal from '../modals/CreateUserModal'

import { RolePermission, Timezones } from '../../../enums'

import { getCreateUserBody } from '../utils'

import { ICreateUserRequest } from '../../../redux/provision/action'
import { IUserFormValues, IValidate } from '../types'
import { ThunkResult } from '../../../redux/types'


interface Props {
    locale: ILocale
    isSuperAdmin: boolean
    isAdmin: boolean
    selectedCustomersList: Array<ICustomer>
    customersList: Array<ICustomer>
    accessedCustomerIds: Array<number>
    addUser: (body: ICreateUserRequest) => ThunkResult<IStatus>
}


const UserCreateButton: React.FC<Props> = ({
    locale,
    isSuperAdmin,
    isAdmin,
    selectedCustomersList,
    customersList,
    accessedCustomerIds,
    addUser
}) => {
    const [initialFormValues, setInitialFormValues] = useState<IUserFormValues | null>(null)

    const handleCreateUser = useCallback(async (values: IUserFormValues) => {
        const res: any = await addUser( getCreateUserBody(values) )

        return res
    }, [addUser])

    const validate: IValidate = (values, formProps) => {
        let usernameValue = values.username.toLowerCase().split(" ").join("")
        
        formProps.setFieldValue("username", usernameValue, false)
    }

    const handleSetInitialValues = useCallback(() => {
        setInitialFormValues({
            first_name: "",
            last_name: "",
            username: "",
            phone_number: "",
            email_address: "",
            timezone: Timezones.AMERICA_LOS_ANGELES,
            role: RolePermission.ADMIN,
            customerIds: [selectedCustomersList[0].id]
        })
    }, [selectedCustomersList])

    return (
        <>
            <PseudoBtn 
                icon="fa fa-plus-circle" 
                label={"Create User"} 
                active={selectedCustomersList.length === 1}
                onClickHandler={handleSetInitialValues}
                message={"Select Customer First"}
            />

            {initialFormValues && (
                <CreateUserModal
                    open={true}
                    onClose={() => setInitialFormValues(null)}
                    onSubmit={handleCreateUser}
                    title="Create"
                    locale={locale}
                    validate={validate}
                    initialValues={initialFormValues}
                    selectedCustomersList={selectedCustomersList}
                    customersList={customersList}
                    isSuperAdmin={isSuperAdmin}
                    isAdmin={isAdmin}
                    userCustomerIds={accessedCustomerIds}
                    setFormValues={setInitialFormValues}
                    resetFormValues={handleSetInitialValues}
                />
            )}
        </>
    )
}

export default React.memo(UserCreateButton)
