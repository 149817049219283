import React from 'react'
import HealthStatusRow from '../ReportParametersRows/HealthStatusRow'
import { ReportParameterGroupProps } from './ReportParameterGroupProps'
import AssetsRow from '../ReportParametersRows/AssetsRow'
import AssetGroupsRow from '../ReportParametersRows/AssetGroupsRow'
import GeofencesRow from '../ReportParametersRows/GeofencesRow'
import DateRangeRow from '../ReportParametersRows/DateRangeRow'

const WarningHistoryReportParameters: React.FC<ReportParameterGroupProps> = (
  props: ReportParameterGroupProps,
) => {
  return (
    <>
      <HealthStatusRow value={props.parameters.warning_filter} />
      <DateRangeRow
        value={`${props.parameters.epoch_from}-${props.parameters.epoch_to}`}
      />
      <AssetsRow value={props.parameters.vins} />
      <AssetGroupsRow value={props.parameters.asset_group} />
      <GeofencesRow value={props.parameters.selected_geofences} />
    </>
  )
}
export default WarningHistoryReportParameters
