import { useEffect, useState } from "react"
import { IColumn, IRow } from "../../components/RivataGrid"
import { IActionColumnConfig, composeDynamicActionsFormatter } from "../../components/RivataGrid/cellFormatter"
import { convertCronToTimezone, makeScrollToY } from "../../utils/utils"
import { DATE_FORMAT, formatType } from "../../utils"
import cronstrue from "cronstrue"
import moment from "moment"

interface IuseColumns {
    (
        data: Array<IScheduledReport>,
        setShowEditForm: (show: boolean) => void,
        setReportToEdit: (r: IScheduledReport | null) => void,
        setCreationMode: (creationMode: boolean) => void,
        setParameters: React.Dispatch<React.SetStateAction<any>>,
        actionsColumnVisible: boolean,
        setReportToDelete: (r: IScheduledReport | null) => void,
    ): Array<IColumn>
}

export const useColumns: IuseColumns = (
    data,
    setShowEditForm,
    setReportToEdit,
    setCreationMode,
    setParameters,
    actionsColumnVisible,
    setReportToDelete,
) => {
    const [columns, setColumns] = useState<Array<IColumn>>([])

    useEffect(() => {
        const formatterConfig: IActionColumnConfig = {
            onEdit: (id) => {
                const report = data.find((r) => r.id === id);

                if (!report) { return; }
                setParameters(report.parameters);
                setShowEditForm(true);
                setReportToEdit(report);
                setCreationMode(false);
                makeScrollToY(0);
            },
            onDelete: (id) => {
                const report = data.find((r) => r.id === id);

                if (!report) { return; }

                setShowEditForm(false);
                setReportToDelete(report);
            },
            tooltipTexts: {
                editTooltipText: "Edit Report Schedule",
                deleteTooltipText: "Delete Report Schedule",
            }
        }

        const colsList: Array<IColumn> = [
            {
                name: "Actions",
                key: "actions",
                minWidth: 80,
                width: 80,
                formatter: ({ row }: any) => composeDynamicActionsFormatter({ ...formatterConfig, cellData: row })
            },
            { name: "Name", key: "name", width: 280 },
            { name: "Report Type", key: "report_type" },
            { name: "Recipients", key: "recipients" },
            { name: "Frequency", key: "frequency" },
            { name: "Previous Run Time", key: "last_run_at_epoch", width: 160 },
            { name: "Next Run Time", key: "next_run_at_epoch", width: 160 },
            { name: "Enabled", key: "isEnabled", width: 100 },
        ]

        if (!actionsColumnVisible) {
            const idx = colsList.findIndex(el => el.key === "actions")
            colsList.splice(idx, 1);
        }

        setColumns(colsList)
    }, [data, setShowEditForm, setReportToEdit, setCreationMode, setParameters, actionsColumnVisible, setReportToDelete])

    return columns
}

interface IuseRows {
    (
        data: Array<IScheduledReport>
    ): Array<IRow>
}

export const useRows: IuseRows = (data) => {
    const [rows, setRows] = useState<Array<IRow>>([])

    useEffect(() => {
        let rowsList: any = [];

        if (data && data.length > 0) {
            rowsList = data.map((row: IScheduledReport) => {
                const bcc_users_emails = row.parameters?.bcc_users ? row.parameters.bcc_users.map((r: IReportBccUser) => r.username || r.email).join(", ") : "";
                return {
                    id: row.id,
                    selected: false,
                    name: row.report_name,
                    isEnabled: !bcc_users_emails || bcc_users_emails.length === 0 ? "False" : (row.is_disabled ? "False" : "True"),
                    frequency: row.parameters.timezone ? cronstrue.toString(convertCronToTimezone(row.schedule, row.parameters.timezone), { verbose: true }) : "",
                    report_type: formatType(row.report_type_name),
                    expanded: false,
                    last_run_at_epoch: row.last_run_at_epoch ? moment.unix(Number(row.last_run_at_epoch)).tz(row.parameters.timezone).format(DATE_FORMAT) : '---',
                    next_run_at_epoch: row.next_run_at_epoch ? moment.unix(Number(row.next_run_at_epoch)).tz(row.parameters.timezone).format(DATE_FORMAT) : '---',
                    recipients: bcc_users_emails
                }
            })
        }

        setRows(rowsList)
    }, [data])

    return rows
}

