import { IGeofencesReducer, GeofencesActionTypes, GeofencesActionsType } from "./types"

const status: IStatus = { statusCode: 0, message: "" }

const initialState: IGeofencesReducer = {
	selectedGeofence: {
		data: null,
		status,
	},
	geofences: {
		isLoading: false,
		data: [],
		limit: 30,
		offset: 0,
		totalCount: 0,
		sortOptions: {},
		status
	},
	assets: {
		isLoading: false,
		data: [],
		limit: 30,
		offset: 0,
		totalCount: 0,
		sortOptions: {},
		status
	},
	assets_locations: {
		isLoading: false,
		data: [],
		limit: 30,
		offset: 0,
		totalCount: 0,
		sortOptions: {},
		status
	}
}

const geofences = (state = initialState, actions: GeofencesActionsType): IGeofencesReducer => {
	switch (actions.type) {
		case GeofencesActionTypes.SET_SELECTED_GEOFENCE:
			return { ...state, selectedGeofence: { ...state.selectedGeofence, data: actions.payload } }
		case GeofencesActionTypes.SET_SELECTED_GEOFENCE_STATUS:
			return { ...state, selectedGeofence: { ...state.selectedGeofence, status: actions.payload } }


		// geofences
		case GeofencesActionTypes.LOADING_GEOFENCES:
			return { ...state, geofences: { ...state.geofences, isLoading: actions.payload } }
		case GeofencesActionTypes.SET_GEOFENCES:
			return { ...state, geofences: { ...state.geofences, data: actions.payload } }
		case GeofencesActionTypes.SET_GEOFENCES_LIMIT:
			return { ...state, geofences: { ...state.geofences, limit: actions.payload } }
		case GeofencesActionTypes.SET_GEOFENCES_OFFSET:
			return { ...state, geofences: { ...state.geofences, offset: actions.payload } }
		case GeofencesActionTypes.SET_GEOFENCES_COUNT:
			return { ...state, geofences: { ...state.geofences, totalCount: actions.payload } }
		case GeofencesActionTypes.SET_GEOFENCES_SORT:
			return { ...state, geofences: { ...state.geofences, sortOptions: actions.payload } }
		case GeofencesActionTypes.SET_GEOFENCES_STATUS:
			return { ...state, geofences: { ...state.geofences, status: actions.payload } }

		// assets
		case GeofencesActionTypes.LOADING_ASSETS:
			return { ...state, assets: { ...state.assets, isLoading: actions.payload } }
		case GeofencesActionTypes.SET_ASSETS:
			return { ...state, assets: { ...state.assets, data: actions.payload } }
		case GeofencesActionTypes.SET_ASSETS_LIMIT:
			return { ...state, assets: { ...state.assets, limit: actions.payload } }
		case GeofencesActionTypes.SET_ASSETS_OFFSET:
			return { ...state, assets: { ...state.assets, offset: actions.payload } }
		case GeofencesActionTypes.SET_ASSETS_TOTAL_COUNT:
			return { ...state, assets: { ...state.assets, totalCount: actions.payload } }
		case GeofencesActionTypes.SET_ASSETS_SORT:
			return { ...state, assets: { ...state.assets, sortOptions: actions.payload } }
		case GeofencesActionTypes.SET_ASSETS_STATUS:
			return { ...state, assets: { ...state.assets, status: actions.payload } }
		case GeofencesActionTypes.SET_GEOFENCES_ASSETS_LOCATIONS:
			return { ...state, assets_locations: { ...state.assets_locations, data: actions.payload } }
		case GeofencesActionTypes.SET_GEOFENCES_ASSETS_LOCATIONS_STATUS:
			return { ...state, assets_locations: { ...state.assets_locations, status: actions.payload } }
		case GeofencesActionTypes.LOADING_GEOFENCES_ASSETS_LOCATIONS:
			return { ...state, assets_locations: { ...state.assets_locations, isLoading: actions.payload } }
		default:
			return state
	}
}

export default geofences