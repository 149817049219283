import { convertDataEpochToDate } from "../../utils"

export const generateCommandDetails = (commands) => {
  const getCommonStatus = (command) => {
    if (command?.canceled) {
      return "CANCELED";
    }
    else if (command?.status?.common_status) {
      return command.status.common_status;
    }

    return "GENERATED";
      
  }
  
  const getDetailedStatus = (command) => {
    if (command?.status?.detailed) return command.status.detailed;
    return null;
  }

  const getPayloadDetails = (command) => {
    let parameters = []

    if (command.payload.request?.parameters) {
      parameters = command.payload?.request?.parameters
    } else if (Array.isArray(command.payload)) {
      parameters = command.payload[0]?.jvalue?.request?.parameters
    }

    return parameters?.length
      ? parameters.map((parameter) => ({
        ...parameter,
        value: decodeURIComponent(escape(window.atob(parameter.value)))
      }))
      : []
  }

  commands.forEach((command) => {
    command.commonStatus = getCommonStatus(command)
    command.detailedStatus = getDetailedStatus(command)
    command.payloadDetails = getPayloadDetails(command)
  })
}

export const parseLog = (log) => {
  let message = "";
  if (log && log.lines) {
    const lines = log.lines.slice().sort(c => c.epoch).reverse();
    lines.forEach((line) => {
      message += `<b>${convertDataEpochToDate(line.epoch, null, null, true, false, true)}</b>: ${line.message}`;
      message += "<br/><br/>";
    })
  }
  return message;
}