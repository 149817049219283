import React from 'react'
import { ReportParameterGroupProps } from './ReportParameterGroupProps'
import TimeZoneRow from '../ReportParametersRows/TimeZoneRow'

const InternalConmetBatteryVoltageReportParameters: React.FC<
  ReportParameterGroupProps
> = (props: ReportParameterGroupProps) => {
  return (
    <>
      <TimeZoneRow value={props.parameters.timezone} />
    </>
  )
}
export default InternalConmetBatteryVoltageReportParameters
