import baseApi from './BaseApi'

class UserApi {
  getUsers = (query: string) => {
    return baseApi.get(`rivata/user?${query}`)
  }

  getAllUsersWithShortDataFormat = (addCustomerIds: boolean = true) => {
    return baseApi.get(`rivata/user?get_limited_data=true`, addCustomerIds)
  }

  getUsersByCustomerIdsWithShortDataFormat = (customerIds: string = '') => {
    return baseApi.get(
      `rivata/user?get_limited_data=true&customer_ids=${customerIds}`,
    )
  }

  postUser = (data: any) => {
    return baseApi.post(`rivata/user`, data)
  }

  putUser = (data: any) => {
    return baseApi.put(`rivata/user?username=${data.username}`, data)
  }

  deleteUser = (username: string) => {
    return baseApi.delete(`rivata/user?username=${username}`)
  }

  postResendTomporaryPassword = (data: any) => {
    return baseApi.post('reset_temporary_password', data)
  }

  getUserAttributes = () => {
    return baseApi.get('rivata/user/attributes', false)
  }

  putUserAttributes = (data: any) => {
    return baseApi.put('rivata/user/attributes', data)
  }

  subscribeToAlerts = (data: any) => {
    return baseApi.post(`rivata/user/subscribealerts`, data)
  }

  requestVerificationCode = () => {
    return baseApi.get(`rivata/user/verification`, false)
  }

  verifyCode = (data: any) => {
    return baseApi.post(`rivata/user/verification`, data)
  }

  searchUsers = (
    searchValue: string,
    searchField?: 'username' | 'email' | 'first_name' | 'last_name',
    limit = 20,
  ) => {
    const params = new URLSearchParams()

    params.append('search_value', searchValue)

    params.append('limit', limit.toString())

    if (searchField) params.append('search_field', searchField)

    return baseApi.get(`rivata/user/search?${params.toString()}`)
  }
  getUsersForReportParameters = (usernames: string = '') => {
    return baseApi.get(
      `rivata/user?get_limited_data=true&usernames=${usernames}`,
    )
  }
}

const userApi = new UserApi()

export default userApi
