import { CriticalWarningTypes } from '../../constants/constants'
import { WarningCase } from '../../enums'

export interface IGpsWarningDetails {
  position: string
  key: string
  cause: string
}

export const getElemIdxByKeyInArr = (
  details: Array<IGpsWarningDetails>,
  key: string,
  searchElem: string,
): number => {
  if (key === 'key') {
    return details.findIndex((el) => (el[key] === searchElem ? true : false))
  }

  return -1
}

export const hasCriticalWarning = (
  details: Array<IGpsWarningDetails>,
  nonAcknowledgedCriticalWarningsOnly: boolean,
  key?: string,
  searchElem?: string,
): boolean => {
  if (nonAcknowledgedCriticalWarningsOnly) {
    return !!details.find((d) => {
      return CriticalWarningTypes.includes(d.cause as WarningCase)
    })
  } else if (key === 'key') {
    return details.some((el) => {
      if (
        el[key] === searchElem &&
        CriticalWarningTypes.includes(el.cause as WarningCase)
      ) {
        return true
      }

      return false
    })
  }

  return false
}
