import React, { useState } from 'react'
import { CustomInput } from 'reactstrap'
import RivataModule from '../../components/RivataModule'
import HEREMap from '../../components/RivataMapCluster'
import { LayerTypes } from '../../components/RivataMapCluster/utils'
import RivataLoader from '../../components/RivataLoader'
import LayersDropdown from '../../components/LayersDropdown'
import Legend from './Legend'
import { useLocations } from './hooks'
import { useDispatch } from 'react-redux'
import { setLocations } from '../../redux/dashboard/action'
import ScrollBlockWrapper from '../../components/ScrollBlockWrapper'

const FleetMap = ({
  selected,
  isLoading,
  width,
  locale,
  preferences,
  locations,
  locationsStatus,
  geofences,
  geofencesStatus,
}) => {
  const dispatch = useDispatch()
  const [selectedLayerOption, setSelectedLayerOption] = useState(
    LayerTypes.NORMAL,
  )
  const [geofencesVisible, setGeofencesVisible] = useState(false)
  const filteredLocations = useLocations(locations, isLoading)
  const [mapZoomBounds, setMapZoomBounds] = useState({
    zoom: null,
    bounds: null,
  })
  const [clusteringDisabled, setClusteringDisabled] = useState(false)

  let error = { statusCode: 0, message: '' }
  if (locationsStatus.statusCode >= 400) error = locationsStatus
  else if (geofencesVisible && geofencesStatus.statusCode >= 400)
    error = geofencesStatus
  else if (!window.H)
    error = { statusCode: 500, message: 'Here Maps in unavailable' }

  return (
    <RivataModule
      customFullScreenAction={() => {
        // TODO hack to rerender map in full screen
        setTimeout(() => {
          dispatch(setLocations([...locations, {}]))
        }, 100)
      }}
      fullScreenModalModeEnabled
      title='Fleet Map'
      width={width}
      locale={locale}
      paddingMobile={false}
      error={error}
      filters={
        <>
          {geofences?.length ? (
            <div className='d-flex align-items-center mr-3'>
              <CustomInput
                type='switch'
                id='clusteringSwitch'
                label={'Clustering'}
                checked={!clusteringDisabled}
                onChange={(e) => setClusteringDisabled(!e.target.checked)}
                className={'mr-3'}
              />
              <CustomInput
                type='switch'
                id='geofencesSwitch'
                label={'Show Geofences'}
                checked={geofencesVisible}
                onChange={(e) => setGeofencesVisible(e.target.checked)}
              />
            </div>
          ) : null}
          <LayersDropdown
            left={0}
            top={0}
            zIndex={1}
            selected={selectedLayerOption}
            onSelect={(type) => setSelectedLayerOption(type)}
            locale={locale}
          />
        </>
      }
    >
      <ScrollBlockWrapper>
        {window.H && <Legend locale={locale} />}
        {window.H && (
          <HEREMap
            onMapViewChange={(bounds, zoom) => {
              setMapZoomBounds({ bounds, zoom })
            }}
            mapZoomBounds={mapZoomBounds}
            locations={filteredLocations}
            selected={selected}
            layerType={selectedLayerOption}
            locale={locale}
            preferences={preferences}
            geofences={geofences}
            geofencesVisible={geofencesVisible}
            size={width}
            clusteringDisabled={clusteringDisabled}
          />
        )}
      </ScrollBlockWrapper>
      {isLoading && <RivataLoader />}
    </RivataModule>
  )
}

export default FleetMap
