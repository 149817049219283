import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import _ from "lodash"
import { MenuItemType } from './MenuItem'
import MenuList from './MenuList'
import { getChildrenByParentId, handleCheckboxSelection, handleRadioSelection, updateChildren, uppdateParentItem } from './utils'
import './style.scss'


export interface IMenuProps {
    isOpen: boolean
    items: Array<MenuItemType>
    resetBtnLabel?: string
    finishBtnLabel?: string
    menuClassName?: string
    onItemsChange: (conf: Array<MenuItemType>) => void
    onFinishSelection: (menuItems: Array<MenuItemType>) => void
    onClickOutside: (e: MouseEvent, menu: React.MutableRefObject<HTMLDivElement | null>) => void
    clearSelectionOnReset?: boolean
    readOnly?: boolean
}


// https://betterprogramming.pub/tree-lists-with-indeterminate-checkboxes-in-react-31445784ac90
const Menu: React.FC<IMenuProps> = ({
    items,
    isOpen,
    resetBtnLabel = "Reset",
    finishBtnLabel = "Done",
    menuClassName = "",
    onItemsChange,
    onFinishSelection,
    onClickOutside,
    clearSelectionOnReset,
    readOnly
}) => {
    const menu = useRef<HTMLDivElement | null>(null)
    const [menuItems, setMenuItems] = useState<Array<MenuItemType>>([])

    useEffect(() => {
        setMenuItems(_.cloneDeep(items))
    }, [items])

    useEffect(() => {
        const handleClickOutside = (e: MouseEvent) => {
          onClickOutside(e, menu)
        }
    
        if (isOpen) document.addEventListener('click', handleClickOutside, true)
        return () => {
          document.removeEventListener('click', handleClickOutside, true)
        }
    }, [onClickOutside, isOpen])

    // zero level items to draw
    const idsToRender = useMemo(() => {
        return items.reduce<Array<string>>((prev, curr) => {
            if (!curr.parentId) prev.push(curr.id)

            return prev
        }, [])
    }, [items])

    const onChange = useCallback((item: MenuItemType) => {
        if (readOnly || item.readOnly) return;

        const newDropdownData = [ ...menuItems ]
        const newItem = { ...item }
        const itemChildren = getChildrenByParentId(newDropdownData, newItem.id)
        const itemParent = newItem.parentId ? newDropdownData.find(el => el.id === newItem.parentId) : null

        if (newItem.type === "radio") {
            const currentLevelItems = getChildrenByParentId(newDropdownData, itemParent?.id ?? null)
            handleRadioSelection(newDropdownData, currentLevelItems, newItem)
        } else {
            handleCheckboxSelection(newDropdownData, itemChildren, newItem)
        }

        if (itemParent) {
            uppdateParentItem(newDropdownData, getChildrenByParentId(newDropdownData, itemParent.id), itemParent)
        }
        if (itemChildren.length > 0) {
            updateChildren(newDropdownData, itemChildren, newItem)
        }

        setMenuItems(newDropdownData)
        onItemsChange(newDropdownData)
    }, [menuItems, onItemsChange, readOnly])

    const onSubMenuToggle = useCallback((item: MenuItemType) => {
        setMenuItems(prev => {
            const newList = [ ...prev ]
            const idx = newList.findIndex(el => el.id === item.id)

            if (idx > -1) {
                const newItem = { ...item }
                newItem.subMenuExpanded = !newItem.subMenuExpanded
                newList[idx] = newItem
            }

            return newList
        })
    }, [])

    const onReset = useCallback((currentConfig: Array<MenuItemType>) => {
        const newItems = items.map(el => {
            const newEl = { ...el }

            if (clearSelectionOnReset) newEl.checked = false

            const currentElem = currentConfig.find(item => item.id === el.id)

            if (!currentElem) return newEl

            newEl.subMenuExpanded = currentElem.subMenuExpanded // save dropdown state

            return newEl
        })

        setMenuItems(newItems)
        onItemsChange(newItems)
    }, [items, onItemsChange, clearSelectionOnReset])

    if (!isOpen) return null

    return (
        <div 
            ref={menu}
            className={`custom-selector ${menuClassName}`}
        >
            <div className='custom-selector-body'>
                <MenuList
                    items={menuItems}
                    idsToRender={idsToRender}
                    depth={0}
                    onChange={onChange}
                    onSubMenuToggle={onSubMenuToggle}
                    readOnly={readOnly}
                />
            </div>

            <div className='custom-selector-footer'>
                {
                    !readOnly && (
                    <span 
                        className='footer__reset'
                        onClick={() => onReset(menuItems)}
                    >
                        {resetBtnLabel}
                    </span>
                    )
                }

                <span 
                    className='footer__done'
                    onClick={() => onFinishSelection(menuItems)}
                >
                    {finishBtnLabel}
                </span>
            </div>
        </div>
    )
}

export default React.memo(Menu)
