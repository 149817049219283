import React, { useState, useCallback, useEffect, useRef } from 'react'
import RivataModule from '../../components/RivataModule'
import RivataTable from '../../components/RivataTable'
import RivataDropdown from '../../components/RivataDropdown'
import ConfirmModal from '../../components/ConfirmModal'
import InfoModal from '../../components/InfoModal'
import StatusAlert from '../../components/StatusAlert'
import ButtonV2 from '../../componentsV2/Button'

import { useColumns, useTableRows, useAssetNames } from './hooks'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { useActions } from '../../hooks/useActions'

import { getLabel } from './utils'
import { makeScrollToElementWithOffset } from '../../utils/utils'
import AssetGroupsFilter from '../../componentsV2/AssetGroupsFilter'
import { debounce, cloneDeep } from 'lodash'
import SearchInput from '../../componentsV2/SearchInput'
import { SearchIcon } from '../../componentsV2/Icon'
import AssetTypeFilter from '../../componentsV2/AssetTypeFilter'
import AssetNameVinFilter from '../../componentsV2/AssetNameVinFilter'

const limitDropdownItems = [{ item: 10 }, { item: 30 }, { item: 90 }]

const initialSelection = {
  asset_group: [],
  asset_subtype: [],
}

const debouncer = debounce((func) => func(), 400)

const wrapperStyle = { width: '470px' }
const columnsSelectorProps = {
  showDropdown: false,
  localeStorageKey: 'provision.assetsTpmsTable.columns',
}
const AssetTpmsTable = ({ width, locale, selectedCustomersList }) => {
  const {
    loading,
    data,
    error,
    totalCount,
    pageLimit,
    pageOffset,
    tpmsProfiles,
  } = useTypedSelector((state) => ({
    ...state.provision.tpmsAssets,
    tpmsProfiles: state.provision.tpmsProfiles,
  }))

  const {
    fetchProvisionTpmsProfiles,
    fetchPagedTpmsAssets,
    setTpmsAssets,
    setTpmsPageLimit,
    setTpmsPageOffset,
    setTpmsPageSortOptions,
    setTpmsAssetsFilter,
    assignWarningSettingsToAssets,
    removeProvisionTpmsAssetGroupFilter,
  } = useActions()

  const scrollTarget = useRef(null)

  const assetNameVinFilterRef = useRef(null)
  const [selectAll, setSelectAll] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const [selectedProfileId, setSelectedProfileId] = useState('')
  const [infoModalMessage, setInfoModalMessage] = useState('')
  const [assetMakeModelSearchValue, setAssetMakeModelSearchValue] = useState('')
  const [profileSearchValue, setProfileSearchValue] = useState('')

  const groupsFilterRef = useRef(null)
  const assetTypeFilterRef = useRef(null)

  const searchParams = useRef(cloneDeep(initialSelection))
  const actualQuery = useRef('')

  useEffect(() => {
    if (selectedCustomersList.length === 1) {
      removeProvisionTpmsAssetGroupFilter()
      fetchProvisionTpmsProfiles(selectedCustomersList[0].id)
      fetchPagedTpmsAssets()
    }
  }, [
    selectedCustomersList,
    fetchProvisionTpmsProfiles,
    fetchPagedTpmsAssets,
    removeProvisionTpmsAssetGroupFilter,
  ])

  const onSelectProfile = useCallback(
    (id, label) => {
      let assetsArray = [...data]
      let idx
      assetsArray.forEach((item, index) => {
        if (item.id === id) {
          idx = index
        }
        return null
      })

      assetsArray[idx].tpmsProfile = label
      setTpmsAssets(assetsArray)
    },
    [data, setTpmsAssets],
  )

  // custom hooks
  const columns = useColumns(locale, selectAll)
  const assetNames = useAssetNames(selectedRows, data)
  const tableRows = useTableRows(
    data,
    selectAll,
    selectedRows,
    onSelectProfile,
    selectedProfileId,
  )

  const checkRowColumnHandler = (_columnId, checked) => {
    setSelectAll(checked)
    setSelectedRows(() => {
      return data.map((asset) => {
        return asset.id
      })
    })
    if (!checked) {
      setSelectedRows([])
    }
  }

  const checkRowItemHandler = (data) => {
    if (selectedRows.includes(data.assetId)) {
      let array = [...selectedRows]
      let index = array.indexOf(data.assetId)
      array.splice(index, 1)
      setSelectedRows(array)
    } else if (selectAll) {
      setSelectedRows(() => {
        return data.map((asset) => {
          if (asset.id === data.assetId) return null
          return asset.id
        })
      })
    } else {
      let array = [...selectedRows]
      array.push(data.assetId)
      setSelectedRows(array)
    }
    setSelectAll(false)
  }

  const selectProfileHandler = (profileId) => {
    if (profileId !== '') {
      setSelectedProfileId(profileId)
    }
  }

  const saveChangesHandler = async () => {
    setSelectedProfileId('')
    setSelectedRows([])
    setSelectAll(false)

    const data = []

    const warning_settings_id =
      selectedProfileId === 'systemDefaults'
        ? null
        : parseInt(selectedProfileId)

    selectedRows.forEach((asset_id) => {
      data.push({
        asset_id,
        warning_settings_id,
      })
    })

    const result = await assignWarningSettingsToAssets(
      data,
      selectedCustomersList[0].id,
    )

    if (result && result.status === 'unsuitable_assets') {
      const errorStack = result.errors.map((error) => {
        return error.message
      })
      setInfoModalMessage(`${errorStack.join('\n')}`)
    } else if (result?.status === 'ok') {
      setInfoModalMessage(`Profile successfully assigned`)
    }
  }

  const handlePageChange = useCallback(
    (offset) => {
      setTpmsPageOffset(offset)
      makeScrollToElementWithOffset(scrollTarget, -200)
    },
    [setTpmsPageOffset],
  )

  const handleLimitChange = useCallback(
    (limit) => {
      setTpmsPageLimit(limit)
      makeScrollToElementWithOffset(scrollTarget, -200)
    },
    [setTpmsPageLimit],
  )

  const updateFilters = useCallback(
    (key, value) => {
      let tempValue = value

      if (!tempValue || !tempValue.length) {
        delete searchParams.current[key]
      } else if (Array.isArray(tempValue)) {
        searchParams.current[key] = tempValue
      } else {
        searchParams.current[key] = [tempValue]
      }

      const query = new URLSearchParams()

      Object.entries(searchParams.current).forEach((filter) => {
        const key = filter[0]
        filter[1].forEach((value) => query.append('filters', `${key}=${value}`))
      })

      actualQuery.current = query.toString()
      setTpmsAssetsFilter(actualQuery.current)
    },
    [setTpmsAssetsFilter],
  )

  const onDropdownItemSelect = useCallback(
    (id, items) => {
      const checked = items.filter((el) => el.checked)

      debouncer(() =>
        updateFilters(
          id,
          checked.map((el) => el.id),
        ),
      )
    },
    [updateFilters],
  )

  const onSearchByStringValue = (id, value) => {
    debouncer(() => updateFilters(id, value))
  }

  const onSearchByAssetModelMake = (e) => {
    const value = e.target.value

    setAssetMakeModelSearchValue(value)
    debouncer(() => updateFilters('asset_model_make', value))
  }

  const onSearchByProfile = (e) => {
    const value = e.target.value

    setProfileSearchValue(value)
    debouncer(() => updateFilters('warning_setting_name', value))
  }

  const handleFiltersReset = () => {
    if (actualQuery.current === '') return

    setAssetMakeModelSearchValue('')
    setProfileSearchValue('')

    groupsFilterRef.current.clearSelection()
    assetTypeFilterRef.current.clearSelection()
    assetNameVinFilterRef.current.clearValue()

    actualQuery.current = ''
    searchParams.current = cloneDeep(initialSelection)
    setTpmsAssetsFilter(actualQuery.current)
  }

  return (
    <RivataModule
      title='TPMS Assets'
      width={width}
      locale={locale}
      marginTop={0}
      error={error}
      filters={
        <>
          {data.length ? (
            <div ref={scrollTarget}>
              <RivataDropdown
                caret={true}
                items={tpmsProfiles}
                selected={selectedProfileId}
                onSelect={selectProfileHandler}
                disabled={selectedRows.length ? false : true}
                buttonLabel={'Assign Profile'}
              />
            </div>
          ) : null}
        </>
      }
    >
      {selectedCustomersList.length !== 1 ? (
        <StatusAlert
          customText={
            'TPMS Assets can not be shown when All customers are selected. Please select a customer in dropdown.'
          }
          color={'success'}
        />
      ) : (
        <>
          <div className='d-flex justify-content-between flex-wrap'>
            <AssetNameVinFilter
              wrapperClassName='mb-2'
              onChange={onSearchByStringValue}
              ref={assetNameVinFilterRef}
            />
            <SearchInput
              placeholder='Search by Make or Asset Model'
              icon={<SearchIcon width={20} height={20} color='black' />}
              value={assetMakeModelSearchValue}
              onChange={onSearchByAssetModelMake}
              wrapperClassName='mb-2'
            />
            <SearchInput
              placeholder='Search by TPMS Profile'
              icon={<SearchIcon width={20} height={20} color='black' />}
              wrapperStyle={wrapperStyle}
              value={profileSearchValue}
              onChange={onSearchByProfile}
              wrapperClassName='mb-2'
            />
          </div>
          <div className='d-flex flex-wrap justify-content-between'>
            <div className='d-flex flex-wrap'>
              <AssetTypeFilter
                onDropdownItemSelect={onDropdownItemSelect}
                ref={assetTypeFilterRef}
              />
              <AssetGroupsFilter
                onDropdownItemSelect={onDropdownItemSelect}
                customerIds={selectedCustomersList.map((c) => c.id).join(',')}
                ref={groupsFilterRef}
              />
            </div>
            <div>
              <ButtonV2
                type='reset'
                className='btn btn-clear'
                onClick={handleFiltersReset}
              >
                Clear
              </ButtonV2>
            </div>
          </div>
          <br />
          {selectedProfileId !== '' ? (
            <ConfirmModal
              open={true}
              onClose={() => setSelectedProfileId('')}
              modalButtons={[
                {
                  id: 1,
                  label: 'Save Changes',
                  color: 'success',
                  onClick: saveChangesHandler,
                },
              ]}
            >
              <div>
                <h5>Confirm Changes?</h5>
                <p>
                  You are going to assign profile "
                  {getLabel(tpmsProfiles, selectedProfileId)}" to these assets:
                </p>
                <p>{assetNames}</p>
              </div>
            </ConfirmModal>
          ) : null}
          <InfoModal
            open={!!infoModalMessage}
            header={`Profile info`}
            message={infoModalMessage}
            onConfirm={() => setInfoModalMessage('')}
          />
          <RivataTable
            title='TPMS Assets'
            width={width}
            locale={locale}
            isLoading={loading}
            columns={columns}
            rows={tableRows}
            onCheckRowColumn={checkRowColumnHandler}
            onCheckRowItem={checkRowItemHandler}
            isShowingLimit={true}
            totalCount={totalCount}
            page={pageOffset / pageLimit}
            onPageChange={handlePageChange}
            onSelectLimit={handleLimitChange}
            pageLimit={pageLimit}
            limitDropdownItems={limitDropdownItems}
            tpmsProfiles={tpmsProfiles}
            showPagination={true}
            setSortOptions={setTpmsPageSortOptions}
            columnsSelectorProps={columnsSelectorProps}
          />
        </>
      )}
    </RivataModule>
  )
}

export default AssetTpmsTable
