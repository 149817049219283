import React from 'react'
import { LabelItemType } from './Label'

export type ListItemType = LabelItemType & { icon?: JSX.Element, showFull?: boolean, isSingleSelection?: boolean }

interface Props {
    items: Array<ListItemType>
    onClick: (el: ListItemType) => void
}

const List: React.FC<Props> = ({
    items,
    onClick
}) => {
    return (
        <ul>
            {items.map((el, i) => (
                <li 
                    key={i}
                    onClick={() => onClick(el)}
                >
                    {el.icon && <span className='mr-2'>{el.icon}</span>}
                    <span>{el.label}</span>
                </li>
            ))}
        </ul>
    )
}

export default List
