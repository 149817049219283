import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { getTooltipCoordinates } from "../RivataMapCluster/utils";
import { UnitsOfMeasurement } from "../../enums";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { convertDataEpochToDate, getWhitelabelColors } from "../../utils";
import createAssetsLayer from "./createAssetsLayer";
import getBubbleContent from "./getBubbleContent";
import { IMapAsset } from "../../redux/manageGeofences/types";
import api from "../../services/api";

export type IWhiteLabelColors = {
  healthGood: any;
  healthWarning: any;
  healthCriticalWarning: any;
};

type Props = {
  map: H.Map | null;
  ui: H.ui.UI | null;
  assets: Array<IMapAsset>;
  isVisible?: boolean;
  unitsOfMeasurement?: string;
};

const useMapAssetsRenderer: React.FC<Props> = ({
  map,
  ui,
  assets,
  isVisible = true,
  unitsOfMeasurement = UnitsOfMeasurement.imperial,
}) => {
  const history = useHistory();

  const { healthColors } = useTypedSelector((state) => ({
    healthColors: state.whitelabel.healthColors,
  }));

  const whiteLabelColors = useMemo(
    () => getWhitelabelColors(healthColors),
    [healthColors]
  );

  const [assetsLayer, setAssetsLayer] =
    useState<H.map.layer.ObjectLayer | null>(null);

  const [bubble, setBubble] = useState<H.ui.InfoBubble | null>(null);

  const removeInfoBubble = useCallback(() => {
    if (!bubble) return;
    bubble.close();
  }, [bubble]);

  const addInfoBubble = useCallback(
    async (e: any) => {
      if (!bubble || !whiteLabelColors) return;

      const data = e.target.getData();
      data.elevation = data.elevation < 0 ? 0 : data.elevation;

      const mapEl = map?.getViewPort().element;
      
      let calcData = {
        name: "",
        vin: "",
        formatted_datetime: ""
      }

      bubble.setContent(
        getBubbleContent(
          getTooltipCoordinates(mapEl, calcData, e, -25, -173),
          data,
          unitsOfMeasurement,
          whiteLabelColors,
          undefined,
          true,
        )
      );

      bubble.setPosition(e.target.getGeometry());
      bubble.open();

      const assetDetails = await api.getAssetGpsDetails(data.id).catch((err) => {
        console.error(err)
        console.error("Asset Gps not found")
        return
      })

      calcData = {
        name: assetDetails.name,
        vin: assetDetails.vin,
        formatted_datetime: convertDataEpochToDate(assetDetails.epoch, null, null, true)
      }

      bubble.setContent(
        getBubbleContent(
          getTooltipCoordinates(mapEl, calcData, e, -25, -173),
          data,
          unitsOfMeasurement,
          whiteLabelColors,
          assetDetails,
        )
      );

      document.getElementById("details")?.addEventListener("click", () => {
        history.push(`/details/${assetDetails.vin}?reducePoints=true`);
      }); 
    },
    [
      map,
      unitsOfMeasurement,
      whiteLabelColors,
      history,
      bubble,
    ]
  );

  useEffect(() => {
    if (!map) return

    const element = map.getViewPort().element.parentElement
    if (!element) return
    
    element.addEventListener("mouseleave", removeInfoBubble)

    return () => {element.removeEventListener("mouseleave", removeInfoBubble)}
  }, [map, removeInfoBubble])

  useEffect(() => {
    if (!ui) return;

    const bubble = new H.ui.InfoBubble(
      { lng: 13.4, lat: 52.51 },
      {
        content: "",
      }
    );
    bubble.addClass("info-bubble");
    ui.addBubble(bubble);

    setBubble(bubble);
  }, [ui]);

  useEffect(() => {
    if (!bubble) return;
    bubble.getElement().addEventListener("pointerleave", removeInfoBubble);  
  }, [bubble, removeInfoBubble]);

  useEffect(() => {
    if (!isVisible || !map || !assetsLayer) return;
    
    map.addLayer(assetsLayer);

    return () => {
      map.removeLayer(assetsLayer);
    };
  }, [isVisible, map, assetsLayer]);

  useEffect(() => {
    if (assets.length && whiteLabelColors)
      setAssetsLayer(
        createAssetsLayer(assets, addInfoBubble, whiteLabelColors)
      );
    else setAssetsLayer(null);
  }, [assets, addInfoBubble, whiteLabelColors]);

  return <></>;
};

export default useMapAssetsRenderer;
