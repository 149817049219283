import React, { useState } from 'react'
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap'
import '../../componentsV2/Button/style.scss'
import CsvButton from './CsvButton'
import PdfButton from './PdfButton'
import HtmlButton from './HtmlButton'

type Props = {
  data: Array<any>
  filename: string
  locale: ILocale
  disabled?: boolean
}

const DownloadDataButton: React.FC<Props> = ({
  data,
  filename,
  locale,
  disabled,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen((isOpen) => !isOpen)
  }

  return (
    <Dropdown toggle={toggle} isOpen={isOpen} disabled={disabled}>
      <DropdownToggle
        className={`filter__btn btn__default mr-3 ${
          disabled ? 'btn__disabled' : ''
        }`}
        tag='button'
        caret
      >
        {locale.download || 'Download'}
      </DropdownToggle>

      <DropdownMenu persist>
        <CsvButton
          data={data}
          filename={filename}
          toggle={toggle}
          disabled={disabled}
        />

        <PdfButton data={data} filename={filename} disabled={disabled} />

        <HtmlButton
          data={data}
          filename={filename}
          toggle={toggle}
          disabled={disabled}
        />
      </DropdownMenu>
    </Dropdown>
  )
}

export default DownloadDataButton
