import React, { useCallback, useEffect, useRef, useState } from "react";
import { CustomInput } from 'reactstrap'

import RivataModule from "../../components/RivataModule";
import RivataTable from "../../components/RivataTable";

import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { makeScrollToElementWithOffset } from "../../utils/utils";
import { ColumnsIds } from "../../enums";
import {
  useColumns,
  useTableRows
} from "./hooks";

import './styles.scss'
import FilterMenu from "./FilterMenu";

const defaultVisibleColumnsIds = [
  ColumnsIds.STATUS,
  ColumnsIds.ASSET_NAME,
  ColumnsIds.VIN,
  ColumnsIds.CUSTOMER_NAME,
  ColumnsIds.GEOFENCES,
  ColumnsIds.LAST_GPS_UPDATE,
]

const alwaysVisibleColumnsIds = [
  ColumnsIds.STATUS,
  ColumnsIds.ASSET_NAME,
  ColumnsIds.LAST_GPS_UPDATE,
]

const columnsSelectorProps = {
  showDropdown: true,
  defaultVisible: defaultVisibleColumnsIds,
  alwaysVisible: alwaysVisibleColumnsIds,
  localeStorageKey: "dashboard.assetsTable.columns",
}

const setAcknowledgement = (acknowledgements, assetId, data, checked) => {
  if (checked) {
    acknowledgements[assetId] = {
      checked,
      ...data,
    };
  } else {
    delete acknowledgements[assetId];
  }
};

const composeAcknowledgements = (
  statusData,
  checked,
  currentAcknowledgements
) => {
  const { data, assetId } = statusData;
  if (!data || !data.type || !data.ids.length) return currentAcknowledgements;

  const newAcknowledgements = { ...currentAcknowledgements };
  setAcknowledgement(newAcknowledgements, assetId, data, checked);

  return newAcknowledgements;
};

const selectAllAcknowledgements = (rows, checked) => {
  return rows.reduce((acknowledgements, row) => {
    const { assetId, data, controlEnabled } = row.columns[0];

    if (controlEnabled) {
      setAcknowledgement(acknowledgements, assetId, data, checked);
    }

    return acknowledgements;
  }, {});
};

const AssetTable = ({
  isSuperAdmin,
  width,
  whiteLabelUrl,
  locale,
  preferences,
  defaults: { subscriptions },
  isSuperStandardUser,
}) => {
  const {
    hideAutogeneratedAssets,
    selectedCustomersList,
    assets: { isLoading, data, totalCount, limit, offset, error },
  } = useTypedSelector(state => ({
    assets: state.dash.assets,
    selectedCustomersList: state.common.customers.selectedCustomersList,
    hideAutogeneratedAssets: state.common.hideAutogeneratedAssets,
  }))
  const {
    setFilter,
    setAssetsLimit,
    setAssetsOffset,
    setAssetsSortOptions,
    setHideAutogeneratedAssets,
    fetchPagedAssets,
    fetchLocations,
    fetchAssetsCardsData,
  } = useActions()

  const scrollTarget = useRef(null)

  const [selectAll, setSelectAll] = useState(false);
  const [pendingAcknowledgements, setPendingAcknowledgements] = useState({});
  // custom hooks
  const columns = useColumns(locale, selectAll, preferences.customer_ids, isSuperAdmin, subscriptions, isSuperStandardUser);

  const tableRows = useTableRows(
    data,
    whiteLabelUrl,
    preferences.timezone,
    isSuperAdmin,
    preferences.customer_ids,
    subscriptions,
    preferences.unitsOfMeasurement,
    isSuperStandardUser
  );

  useEffect(() => {
    return () => {
      setFilter("")
    }
  }, [setFilter])

  const onPageChange = useCallback((offset) => {
    setAssetsOffset(offset)
    fetchPagedAssets()
    makeScrollToElementWithOffset(scrollTarget, -200)
  }, [setAssetsOffset, fetchPagedAssets])

  const onSelectLimit = useCallback((newLimit) => {
    setAssetsLimit(newLimit)
    setAssetsOffset(0)
    fetchPagedAssets()
    makeScrollToElementWithOffset(scrollTarget, -200)
  }, [setAssetsLimit, setAssetsOffset, fetchPagedAssets])

  const onCheckRowColumn = useCallback((_columnId, checked) => {
    setSelectAll(checked);
    setPendingAcknowledgements(
      selectAllAcknowledgements(tableRows, checked)
    );
  }, [tableRows])

  const onCheckRowItem = useCallback((data, checked) => {
    setSelectAll(false);
    const pending = composeAcknowledgements(
      data,
      checked,
      pendingAcknowledgements
    );
    setPendingAcknowledgements(pending);
  }, [pendingAcknowledgements])

  const onFilter = useCallback((query) => {
    setFilter(query)
    setAssetsOffset(0)
    fetchPagedAssets()
    fetchLocations()
    fetchAssetsCardsData()
  }, [setFilter, setAssetsOffset, fetchPagedAssets, fetchLocations, fetchAssetsCardsData])

  const onSort = useCallback((options) => {
    setAssetsSortOptions(options)
    fetchPagedAssets()
  }, [setAssetsSortOptions, fetchPagedAssets])

  useEffect(() => {
    if (selectedCustomersList.length > 0) {
      onFilter("")
    }
  }, [ selectedCustomersList, onFilter])
  return (
    <RivataModule
      title="Assets"
      width={width}
      marginTop={0}
      locale={locale}
      error={error}
      filters={
        <>
          {isSuperAdmin && (
            <CustomInput
              innerRef={scrollTarget}
              id="hideAutogenerated"
              className="d-flex align-items-center mr-3"
              type="checkbox"
              label="Hide Autogenerated Assets"
              checked={hideAutogeneratedAssets}
              onChange={() => {
                setHideAutogeneratedAssets(!hideAutogeneratedAssets)
                setAssetsOffset(0)
                fetchPagedAssets()
                fetchLocations()
                fetchAssetsCardsData()
              }}
            />
          )}
        </>
      }
    >
      <>
        <FilterMenu
          timezone={preferences.timezone}
          onFilter={onFilter}
        />

        <hr />

        <div className={`position-relative ${tableRows.length <= 3 && 'extra-height-content'}`}>
          <RivataTable
            title="Assets"
            width={width}
            locale={locale}
            isLoading={isLoading}
            columns={columns}
            rows={tableRows}
            daysCount={7}
            onCheckRowColumn={onCheckRowColumn}
            onCheckRowItem={onCheckRowItem}
            isShowingLimit={true}
            totalCount={totalCount}
            onPageChange={onPageChange}
            onSelectLimit={onSelectLimit}
            pageLimit={limit}
            page={offset / limit}
            setSortOptions={onSort}
            columnsSelectorProps={columnsSelectorProps}
          />
        </div>
      </>
    </RivataModule>
  );
};

export default AssetTable;
