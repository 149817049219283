import React, { useState } from "react";
import { ListGroup } from "reactstrap";
import Document from "./Document";
import DeleteModal from "../../components/DeleteModal";
import { getFileTitle } from "./utils";
import "./styles.scss";
import StatusAlert from "../../components/StatusAlert";
import { IDocType } from "../../redux/documents/types";

interface Props {
  documents: Array<IDocument>;
  locale: Record<string, string>;
  dir: string;
  removeDocument: (dir: string, title: string) => void;
  docTypes: IDocType[];
}

const DocumentsList: React.FC<Props> = ({ documents, locale, dir, removeDocument, docTypes }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<IDocument | null>(null);

  const onDelete = (document: IDocument) => {
    setModalOpen(true);
    setSelectedDocument(document);
  };

  const deleteDocument = () => {
    if (selectedDocument) {
      removeDocument(dir, selectedDocument.file_name);
      setModalOpen(false);
    }
  };

  return (
    <div className="d-flex flex-column mt-3">
      <h4>Documents List</h4>
      {documents?.length ? (
        <>
          <ListGroup>
            {documents.map((document, index) => (
              <Document key={index} document={document} docTypes={docTypes} onDelete={onDelete} />
            ))}
          </ListGroup>

          <DeleteModal
            open={modalOpen}
            disabled={false}
            header={`Delete document ${getFileTitle(selectedDocument?.file_name)} ?`}
            message={"This cannot be undone!"}
            onDelete={deleteDocument}
            onCancel={() => {
              setModalOpen(false);
              setSelectedDocument(null);
            }}
            locale={locale}
          />
        </>
      ) : <StatusAlert statusCode={null} statusText={null} customText={"List is empty"} color="success" />}
    </div>
  );
};

export default DocumentsList;
