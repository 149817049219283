import React, { useState, useMemo } from "react";
import { Tooltip } from "reactstrap";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { celsiusToFahr, getWhitelabelColors } from "../../../utils";
import moment from "moment";
import IconWrapper from "../common/iconWrapper";
import classes from "./style.module.scss";
import { HealthStatus, NoDataLabels } from "../../../enums"; 

const HubsLayer = ({ pos, t, left, hub, type, index, intervals }) => {
  const {
    preferences: { timezone, unitsOfMeasurement },
    healthColors
  } = useTypedSelector((state) => ({
    preferences: state.auth.preferences,
    healthColors: state.whitelabel.healthColors
  }));

  const whiteLabelColors = useMemo(
    () => getWhitelabelColors(healthColors),
    [healthColors]
  );
  
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const tooltipCls = [classes.hubTooltip];

  const vibrationStatusColor = hub.isCriticalVib ? whiteLabelColors.healthCriticalWarning: hub.isVib ? whiteLabelColors.healthWarning: whiteLabelColors.healthGood;
  const temperatureStatusColor = hub.isCriticalTemperature ? whiteLabelColors.healthCriticalWarning: hub.isTemperature ? whiteLabelColors.healthWarning: whiteLabelColors.healthGood;

  let variant = HealthStatus.good
  if (hub.noStatus || hub.noData) {
    variant = HealthStatus.noStatus
    tooltipCls.push(classes.noStatus)
  }
  if ((hub.isTemperature || hub.isVib) && !hub.noStatus && !hub.noData) {
    variant = HealthStatus.warning
    tooltipCls.push(classes.warning)
  }
  if ((hub.isCriticalTemperature || hub.isCriticalVib) && !hub.noStatus && !hub.noData) {
    variant = HealthStatus.criticalWarning
    tooltipCls.push(classes.criticalWarning)
  }
  if (!hub.isTemperature && !hub.isVib && !hub.noStatus && !hub.noData) {
    variant = HealthStatus.good
    tooltipCls.push(classes.hubTooltip)
  }

  let temperatureWithLabel = " -"
  if (hub.temperature !== null && unitsOfMeasurement === "imperial") {
    temperatureWithLabel = `${celsiusToFahr(hub.temperature).toFixed(0)} °F`
  }
  if (hub.temperature !== null && unitsOfMeasurement !== "imperial") {
  temperatureWithLabel =  ` ${hub.temperature.toFixed(0)} °C`
  }
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        ...pos,
        left: `${type === "truck" ? intervals[index] : left}px`,
        width: "76px",
        height: "46px",
        borderRadius: "8px",
      }}
    >
      <IconWrapper variant={variant} name = 'smartHub'>
        <div id={`hub${t + 1}${index}`}></div>
      </IconWrapper>
      <Tooltip
        innerClassName={tooltipCls.join(' ')}
        placement={"top"}
        toggle={toggle}
        isOpen={tooltipOpen}
        target={`hub${t + 1}${index}`}
        autohide={false}
        hideArrow={true}
      >
        {hub.noStatus && (
          <>
            <span>Position: {hub.position}</span> <br />
            <span>Sensor Type: SmartHub</span> <br />
            <span>In No Status Condition</span> <br />
            <span >Latest Measurement:{hub.noStatusLatestMeasurement ? `${hub.noStatusLatestMeasurement} ${moment().tz(timezone).format("z")}` : NoDataLabels.DASH}</span>
          </>
        )}
        {!hub.noStatus && (hub.isTemperature || hub.isCriticalTemperature || hub.isVib || hub.isCriticalTemperature) && !hub.noData &&(
          <>
            <span>Position: {hub.position}</span> <br />
            <span>Sensor Type: SmartHub</span> <br /> <br />
            <span><span className={classes.dot} style={{backgroundColor: vibrationStatusColor}}/> Vibration: <b>{hub.vibrationHealthLabel}</b></span><br />
            <span>Latest Vibration Measurement:{hub.lastVib ? `${hub.lastVib} ${moment().tz(timezone).format("z")}` : NoDataLabels.DASH}</span><br /> <br />
            <span><span className={classes.dot} style={{backgroundColor: temperatureStatusColor}}/> Hub Temperature: <b>{hub.isCriticalTemperature ? "Rare high hub temperature" : hub.isTemperature ? "High hub temperature" : "Normal"}</b></span><br />
            <span>Temperature: {temperatureWithLabel}</span><br />
            <span>Latest Temperature Measurement: {hub.lastTemp ? `${hub.lastTemp} ${moment().tz(timezone).format("z")}`: NoDataLabels.DASH}</span><br />
          </>
        )}
        {!hub.noStatus && !hub.isTemperature && !hub.isCriticalTemperature && !hub.isVib && !hub.noData  && (
          <>
            <span>Position: {hub.position}</span> <br />
            <span>Sensor Type: SmartHub</span> <br /> <br />
            <span><span className={classes.dot} style={{backgroundColor: vibrationStatusColor}}/> Vibration Level: <b>{hub.vibrationHealthLabel}</b></span><br />
            <span>Latest Vibration Measurement: {hub.lastVib ? `${hub.lastVib} ${moment().tz(timezone).format("z")}` : NoDataLabels.DASH}</span><br /> <br />
            <span><span className={classes.dot} style={{backgroundColor: temperatureStatusColor}}/> Temperature: <b>{temperatureWithLabel}</b></span> <br />
            <span >Latest Temperature Measurement: {hub.lastTemp ? `${hub.lastTemp} ${moment().tz(timezone).format("z")}`: NoDataLabels.DASH}</span><br />
          </>
        )}
          {!hub.noStatus && hub.noData && (
          <>
            <span>Position: {hub.position}</span> <br />
            <span>Sensor Type: SmartHub</span> <br />
            <span>No Latest Data</span> <br />
          </>
        )}
      </Tooltip>
    </div>
  );
};

const ActiveHubs = ({ axle, intervals, hubs, index, type }) => {
  return (
    <>
      {hubs.map((hub) => {
        if (hub.order === axle.order) {
          let pos = hub.loc === "top" ? { top: "-28px" } : { bottom: "-28px" };
          let t = hub.loc === "top" ? 2 : 3;
          let left = intervals && index ? intervals[index] : "172";
          return (
            <HubsLayer
              key={hub.position}
              pos={pos}
              t={t}
              left={left}
              hub={hub}
              type={type}
              index={index}
              intervals={intervals}
            />
          );
        }

        return null
      })}
    </>
  );
};

export default ActiveHubs;
