import baseApi from './BaseApi'

class HardwareApi {
  getSensorNodeInfo = (assetId: number, hardwareTypeId?: number) => {
    let query = `asset_id=${assetId}`

    if (hardwareTypeId) query += `&hardware_type_id=${hardwareTypeId}`

    return baseApi.get(`rivata/hardware?${query}`, false)
  }

  postMigrateData = (data: any) => {
    return baseApi.post(`rivata/hardware/migratedata`, data)
  }

  deleteHardwareData = (assetId: number) => {
    return baseApi.delete(`rivata/hardware/deletedata?asset_id=${assetId}`)
  }

  getAssetHardwareCommandsHistory = (id: number) => {
    return baseApi.get(`rivata/hardware/commands_history?asset_id=${id}`, false)
  }

  putHardwareCommand = (data: any) => {
    return baseApi.put('rivata/hardware/commands_history', data)
  }

  // provision api
  getLatestProvisionTimestamp = (vin: string) => {
    return baseApi.get(
      `rivata/hardware/last_provision_timestamp?vin=${vin}`,
      false,
    )
  }

  getHardwareProvisionHistory = (
    assetId: number,
    epochFrom: number | null = null,
    epochTo: number | null = null,
  ) => {
    const params = new URLSearchParams()

    if (assetId) {
      params.append('id', assetId.toString())
    }
    if (epochFrom) {
      params.append('epoch_from', epochFrom.toString())
    }
    if (epochTo) {
      params.append('epoch_to', epochTo.toString())
    }

    return baseApi.get(
      `rivata/hardware/provision/history?${params.toString()}`,
      false,
    )
  }

  postCopyProvisioningToEnviroments = (data: any) => {
    return baseApi.post('rivata/hardware/provision/copytoenviroments', data)
  }

  getProvisionJson = (vin: string) => {
    return baseApi.get(`v1/hardware/provision?vin=${vin}`, false)
  }

  postProvisionJson = (data: any) => {
    return baseApi.post('v1/hardware/provision', data)
  }
  getAssetsHardwareByVins = (vins: Array<string>) => {
    return baseApi.get(`rivata/hardware?exactvins=${vins.join(',')}`, false)
  }
}

const hardwareApi = new HardwareApi()

export default hardwareApi
