import React, { useCallback, useState } from 'react'
import SearchInput from '../../componentsV2/SearchInput'
import Button from '../../componentsV2/Button'
import { SearchIcon } from '../../componentsV2/Icon'
import { MenuItemType } from '../../componentsV2/Menu/MenuItem'
import DropdownItemSelector from '../../componentsV2/DropdownItemSelector'
import LabelSelector from '../../componentsV2/LabelSelector'


// const menuItemsConfig: Array<MenuItemType> = [
//     { id: "a1", label: "aaaa1", type: "checkbox", markType: "check", checked: false, parentId: null },
//     { id: "a2", label: "aaaa2", type: "checkbox", markType: "line", checked: false, subMenuExpanded: true, parentId: null },
//     { id: "a3", label: "aaaa3", type: "checkbox", markType: "check", checked: false, parentId: null },
//     { id: "b1", label: "bbbb1", type: "checkbox", markType: "check", checked: false, parentId: "a2" },
//     { id: "b2", label: "bbbb2", type: "checkbox", markType: "check", checked: false, parentId: "a2" },
//     { id: "b3", label: "bbbb3", type: "checkbox", markType: "line", checked: false, subMenuExpanded: false, parentId: "a2" },
//     { id: "c1", label: "cccc1", type: "radio", markType: "circle", name: "cccc", value: "1", checked: false, parentId: "b3" },
//     { id: "c2", label: "cccc2", type: "radio", markType: "circle", name: "cccc", value: "2", checked: false, parentId: "b3" },
//     { id: "c3", label: "cccc3", type: "radio", markType: "circle", name: "cccc", value: "3", checked: false, parentId: "b3" },
// ]

const assetTypeMenu: Array<MenuItemType> = [
    { id: "dolly", label: "Dolly", type: "checkbox", markType: "line", checked: false, subMenuExpanded: false, parentId: null },
    { id: "tractor", label: "Tractor", type: "checkbox", markType: "line", checked: false, subMenuExpanded: false, parentId: null },
    { id: "trailer", label: "Trailer", type: "checkbox", markType: "line", checked: false, subMenuExpanded: false, parentId: null },
    
    { id: "single", label: "Single", type: "checkbox", markType: "check", checked: false, parentId: "dolly" },
    { id: "tandem", label: "Tandem", type: "checkbox", markType: "check", checked: false, parentId: "dolly" },

    { id: "dayCab", label: "Day cab", type: "checkbox", markType: "check", checked: false, parentId: "tractor" },
    { id: "sleeperCab", label: "Sleeper cab", type: "checkbox", markType: "check", checked: false, parentId: "tractor" },

    { id: "chassisIntermodal", label: "Chassis/Intermodal", type: "checkbox", markType: "check", checked: false, parentId: "trailer" },
    { id: "chipper", label: "Chipper", type: "checkbox", markType: "check", checked: false, parentId: "trailer" },
    { id: "curtainSide", label: "Curtain Side", type: "checkbox", markType: "check", checked: false, parentId: "trailer" },
    { id: "dryBulk", label: "Dry Bulk", type: "checkbox", markType: "check", checked: false, parentId: "trailer" },
    { id: "dryvan", label: "Dryvan", type: "checkbox", markType: "check", checked: false, parentId: "trailer" },
    { id: "ehub", label: "Ehub", type: "checkbox", markType: "check", checked: false, parentId: "trailer" },
]


const labelGroupItems = [
    { id: "eeeeeeeeee", label: "All Assets inside geofences", icon: <SearchIcon width={15} height={15} color='black' /> },
    { id: "ffffffffff", label: "All Assets outside geofences", icon: <SearchIcon width={20} height={20} color='black' /> },
]

const labelSearchItems = [
    { id: "aaaaaaaa", label: "aaaaaaaa" },
    { id: "bbbbbbbb", label: "bbbbbbbb" },
    { id: "cccccccc", label: "cccccccc" },
    { id: "dddddddddddddddddddddddddddddd", label: "dddddddddddddddddddddddddddddd" },
    { id: "eeeeeeee", label: "eeeeeeee" },
    { id: "ffffffff", label: "ffffffff" },
    { id: "gggggggg", label: "gggggggg" },
]

const wrapperStyle = { width: "449px" }


const UIComponents: React.FC = () => {
    // const [menuItems] = useState(menuItemsConfig)
    const [assetMenuItems] = useState(assetTypeMenu)
    const [labelSearchValue, setLabelSearchValue] = useState("")

    const onFinishMenuSelection = useCallback((items: Array<MenuItemType>) => {
        console.log("finish: ", items)
        
    }, [])

    const onItemsChange = useCallback((items) => {
        console.log("onItemsChange: ", items)
    }, [])

    const labelOnChange = useCallback((value) => {
        setLabelSearchValue(value.target.value)
        console.log("onSearch value = ", value)
    }, [])

    const onLabelSelectionChange = useCallback((selectedItems) => {
        console.log("onSelect = ", selectedItems)
    }, [])

    return (
        <div className='mb-5'>
            <div>
                <h1>Dropdown Item Selector</h1>

                <DropdownItemSelector
                    id="aa"
                    onItemsChange={onItemsChange}
                    onFinishSelection={onFinishMenuSelection}
                    items={assetMenuItems}
                    filterBtnLabel='Asset type'
                />

                <br/>
            </div>

            <div>
                <h1>Label Selector</h1>

                <LabelSelector
                    placeholder='Search By Geofence'
                    wrapperStyle={wrapperStyle}
                    onLabelSelectionChange={onLabelSelectionChange}
                    onChange={labelOnChange}
                    value={labelSearchValue}
                    menuItems={labelSearchValue ? labelSearchItems : labelGroupItems}
                />

                
                <br/>
            </div>

            <div>
                <h1>Input</h1>

                <SearchInput
                    placeholder='Search by VIN or Asset name'
                    wrapperStyle={wrapperStyle}
                />

                <br/>

                <SearchInput
                    icon={<SearchIcon width={20} height={20} color='black' />}
                    placeholder='Search by VIN or Asset name'
                    // style={{ width: "449px" }}
                    wrapperStyle={wrapperStyle}
                />

                <br/>
            </div>

            <div>
                <h1>Button</h1>

                <Button>
                    Sensor type
                </Button>

                <br/>

                <Button
                    btnType='btn__primary'
                >
                    Sensor type
                </Button>

                <br/>
            </div>


        </div>
    )
}

export default UIComponents