import React, { useEffect, useState } from 'react'
import { ReportParameterRowProps } from './ReportParameterRowProps'
import { Label } from 'reactstrap'
import classes from './styles.module.scss'
import api from '../../../services/api'
const CustomerRow: React.FC<ReportParameterRowProps> = (
  props: ReportParameterRowProps,
) => {
  const { value } = props
  const [displayValue, setDisplayValue] = useState<string>('All')
  useEffect(() => {
    if (!value || (value && value.toLowerCase() === 'all')) {
      setDisplayValue('All')
    } else {
      const fetchAssets = async () => {
        try {
          const data = await api.getCustomersDataByKeys(value)

          if (data && data.length > 0) {
            const val: string[] = data.map((item: any) => {
              return `${item.name}`
            })

            setDisplayValue(val.join(', '))
          }
        } catch (err) {
          console.log(err)
        }
      }
      fetchAssets()
    }
  }, [value])
  return (
    <div className={classes.reportParameterRow}>
      <Label className={classes.label}>Customer:</Label>
      <Label className={classes.value}>{displayValue}</Label>
    </div>
  )
}
export default CustomerRow
