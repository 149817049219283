import React, { useCallback, useState } from 'react'
import { ListGroup, ListGroupItem } from 'reactstrap'
import * as yup from 'yup';
import api from '../../services/api'
import { useDispatch } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import RivataLoader from '../../components/RivataLoader'
import RivataModule from '../../components/RivataModule'
import StatusAlert from '../../components/StatusAlert'
import CreateModal from '../../components/CreateModal/children'
import { FormField } from '../../components/CreateModal'
import InfoModal from '../../components/InfoModal'
import { IDocType } from '../../redux/documents/types'
import { getStatusObj } from '../../utils/utils'

const docTypeSchema = yup.object().shape({
  name: yup.string()
    .required("Required")
    .max(30, "Max length 30 symbols")
    .matches(/^[0-9a-zA-Z\s]+$/, "Only letters, numbers and whitepaces allowed")
    .trim()
})

const initialValues = { name: "" }

type Props = {
  isLoading: boolean,
  docTypes: IDocType[],
  locale: Record<string, string>,
}

const ManageDocumentTypesModule: React.FC<Props> = ({
  isLoading,
  docTypes,
  locale,
}) => {
  const dispach = useDispatch()

  const {
    loadingDocumentTypes,
    fetchDocumentTypes,
  } = useActions()

  const [status, setStatus] = useState(getStatusObj())
  const [openInfo, setOpenInfo] = useState(false)

  const postDocType = useCallback(async ({ name }: { name: string }) => {
    dispach(loadingDocumentTypes(true))
    return await api.postDocumentType(name)
      .then((res: IDocType) => {
        const status = getStatusObj({ statusCode: 201 }, `Document type "${res.name}" created`)
        setStatus(status)
        setOpenInfo(true)
        return status
      })
      .catch((err: Error) => {
        const status = getStatusObj(err)
        setStatus(status)
        return status
      })
      .finally(() => {
        fetchDocumentTypes()
      })
  }, [fetchDocumentTypes, dispach, loadingDocumentTypes])


  return (
    <>
      <RivataModule
        title="Manage Document Types"
        width={12}
        marginTop={0}
        locale={locale}
        filters={
          <CreateModal
            createBtnLabel="Add type"
            openBtnLabel="Add type"
            header="Add type"
            onCreate={postDocType}
            initialValues={initialValues}
            schema={docTypeSchema}
            disabled={isLoading}
            btnClassName="btn-primary"
          >
            <FormField name="name" label="Name" md="12" />
          </CreateModal>
        }
      >
        {isLoading && <RivataLoader />}

        <div className="d-flex flex-column">
          <h4>Document Types List</h4>
          {docTypes?.length ? (
            <ListGroup>
              {docTypes.map((docType) => (
                <ListGroupItem key={docType.id}>
                  <div className="d-flex align-items-center justify-content-between">
                    {docType.name}
                  </div>
                </ListGroupItem>
              ))}
            </ListGroup>
          ) : <StatusAlert statusCode={null} statusText={null} customText={"No document types"} color="success" />}
        </div>
      </RivataModule>

      <InfoModal
        onConfirm={() => {
          setStatus(getStatusObj())
          setOpenInfo(false)
        }}
        open={openInfo}
        message={status.message}
        header="Manage Type Info"
      />
    </>
  )
}

export default ManageDocumentTypesModule