import { useMemo } from 'react'
import { orderBy } from 'lodash'
import { ISortOptions } from '../../../redux/types'
import { NoDataLabels } from '../../../enums'

export const useColumns = (selectedCustomersList: Array<ICustomer>) => {
  return useMemo(() => {
    const columns: Array<any> = []

    columns.push({
      label: 'Geofence Name',
      id: 'name',
      control: 'sort',
      isDraggable: true,
    })

    if (selectedCustomersList.length > 1) {
      columns.push({
        label: 'Customer',
        id: 'customer_name',
        control: 'sort',
        isDraggable: true,
      })
    }

    columns.push({
      label: 'Assets Count',
      id: 'assets_in_geofence',
      control: 'sort',
      isDraggable: true,
    })

    return columns
  }, [selectedCustomersList])
}

export const useRows = (
  columns: Array<any>,
  data: Array<IGeofence>,
  limit: number,
  offset: number,
  sortOptions: ISortOptions,
  filters: string,
) => {
  return useMemo(() => {
    if (!data) return []

    let sortedData: Array<IGeofence> = [...data]

    if (sortOptions.direction === 'asc' || sortOptions.direction === 'desc') {
      const iter = (item: IGeofence) => {
        const col = item[sortOptions.column!]

        if (typeof col === 'string') {
          return col.toLowerCase()
        }

        return col
      }

      sortedData = orderBy(data, [iter], [sortOptions.direction])
    }

    let pagedData: Array<IGeofence> = []
    const length = offset + limit

    for (let i = offset; i < length && i < sortedData.length; i++) {
      pagedData.push(sortedData[i])
    }

    const queryParams = filters
      ? `?${new URLSearchParams(filters).toString()}`
      : ''

    return pagedData.map((d) => {
      return {
        id: d.id,
        columns: columns.map((col) => ({
          columnId: col.id,
          label: d[col.id] || NoDataLabels.DASH,
          type: 'link',
          link: `/geofence-details/${d.id}${queryParams}`,
        })),
      }
    })
  }, [columns, data, limit, offset, sortOptions, filters])
}
