import React, { useCallback, useEffect, useRef, useState } from 'react'
import Button from '../Button'
import Menu, { IMenuProps } from '../Menu'
import { MenuItemType } from '../Menu/MenuItem'
import './style.scss'


interface Props extends Omit<IMenuProps, "onClickOutside" | "isOpen" | "onFinishSelection" | "onItemsChange"> {
    id: string
    filterBtnLabel: string
    filterClassName?: string
    onFinishSelection?: (menuItems: Array<MenuItemType>) => void
    onItemsChange: (id: string, conf: Array<MenuItemType>) => void
    disabled?: boolean
    clearSelectionOnReset?: boolean
    readOnly?: boolean
}

const DropdownItemSelector: React.FC<Props> = ({
    id,
    items,
    filterBtnLabel,
    filterClassName,
    onItemsChange,
    onFinishSelection,
    menuClassName,
    disabled,
    clearSelectionOnReset,
    readOnly
}) => {
    const btn = useRef<HTMLButtonElement | null>(null)
    const [isOpen, setIsOpen] = useState(false)
    const [selected, setSelected] = useState(items.some(i => i.checked))

    useEffect(() => {
        setSelected(items.some(i => i.checked))
    }, [items])

    const handleMenuClose = useCallback((items: Array<MenuItemType>) => {
        setIsOpen(false)
        onFinishSelection && onFinishSelection(items)
    }, [onFinishSelection])

    const onClickOutside = useCallback((e, menu) => {
        if (menu.current && !menu.current.contains(e.target) && e.target !== btn.current) {
            setIsOpen(false)
        }
    }, [])

    const setBtnRef = useCallback((btnRef) => {
        btn.current = btnRef
    }, [])

    const onSelectionChange = useCallback((items: Array<MenuItemType>) => {
        onItemsChange(id, items)
        setSelected(items.some(el => el.checked))
    }, [id, onItemsChange])

    return (
        <div className={`relative ${filterClassName ?? ""}`}>
            <Button
                btnType={selected ? "btn__primary" : "btn__default"}
                setRef={setBtnRef}
                onClick={(e) => { e.preventDefault(); setIsOpen(!isOpen); }}
                disabled={disabled ? disabled : false}
                className={disabled ? "btn__disabled" : ""}
            >
                {filterBtnLabel}
            </Button>

            <Menu
                isOpen={isOpen}
                items={items}
                menuClassName={`item-selector__menu ${menuClassName}`}
                resetBtnLabel="Clear"
                onItemsChange={onSelectionChange}
                onFinishSelection={handleMenuClose}
                onClickOutside={onClickOutside}
                clearSelectionOnReset={clearSelectionOnReset}
                readOnly={readOnly}
            />
        </div>
    )
}

export default React.memo(DropdownItemSelector)

