import React from 'react'
import { SearchItemType } from '../../../components/ItemsSearch'
import AssetsSelect from './AssetsSelect'
import DateTimePicker from '../../../componentsV2/DateTimePicker'
import { CalendarTypes } from '../../../enums'
import { MenuItemType } from '../../../componentsV2/Menu/MenuItem'
import AssetGroupsFilter from '../../../componentsV2/AssetGroupsFilter'

interface ITripReportFormProps {
  disabled: boolean
  dateFrom: string
  dateTo: string
  minDate: Date
  onDateRangeSelect: (from: Date, to: Date) => void
  setVinList: React.Dispatch<React.SetStateAction<Array<Object>>>
  selectedAssetsItems: Array<SearchItemType>
  onAssetGroupFilterItemSelected?: (
    id: string,
    selectedValue: MenuItemType[],
  ) => void
  customerIds?: string | ''
  maxDate?: Date | undefined
}

const UtilizationReport: React.FC<ITripReportFormProps> = ({
  disabled,
  dateFrom,
  dateTo,
  minDate,
  onDateRangeSelect,
  setVinList,
  selectedAssetsItems,
  onAssetGroupFilterItemSelected,
  customerIds,
  maxDate
}) => {
  return (
    <>
      <div className='mb-2'>Select Date Range</div>
      <DateTimePicker
        defaultMode={CalendarTypes.DateRange}
        dateFrom={dateFrom}
        dateTo={dateTo}
        onDateRangeSelect={onDateRangeSelect}
        minDate={minDate}
        maxDate={maxDate}
        disabled={disabled}
      />

      <AssetsSelect
        disabled={disabled}
        setVinList={setVinList}
        selectedItems={selectedAssetsItems}
      />
      <AssetGroupsFilter
        className='mt-3'
        onDropdownItemSelect={onAssetGroupFilterItemSelected}
        customerIds={customerIds}
      />
    </>
  )
}

export default UtilizationReport
