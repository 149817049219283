import React, { useRef, useEffect, useState } from "react";
import "./picker.scss";
import moment from "moment";
import TimePicker from "rc-time-picker";
import { ReactComponent as Clock } from "./clock.svg";
import "rc-time-picker/assets/index.css";

function PickTime({ t, onChange, setIsPicker, type, isdisabled, use12hours = true }) {
  const hours = +`${t[0]}${t[1]}`;
  const minute = +`${t[3]}${t[4]}`;
  const ref = useRef();
  const [open, setOpen] = useState(false);
  const now = moment().hour(hours).minute(minute);

  const picker = type !== 'forModal' ? 'picker' : 'logsPicker'
  const icon = type !== 'forModal' ? 'icon' : 'logsIcon'
  const popup = type !=='forModal' ? 'popup' : 'logsPanel'

  useEffect(() => {
    ref.current.picker.disabled = true;
  }, [ref]);

  const onInputChange = (val) => {
    onChange(val.format("HH:mm"));
    
  };
  const handleOpen = (status)=> {
    setOpen(status)
    setIsPicker && setIsPicker(status)
  }
  return (
    <TimePicker
      showSecond={false}
      className={picker}
      defaultValue={now}
      value={now}
      open={open}
      allowEmpty={false}
      use12Hours={use12hours}
      onChange={(value) => onInputChange(value)}
      popupClassName={popup}
      inputIcon={<Clock className={icon} onClick={() => handleOpen(true)} />}
      onClose={() => setOpen(false)}
      ref={ref}
      inputReadOnly={isdisabled}
    />
  );
}

export default PickTime;
