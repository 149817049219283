import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react"
import { TabContent, TabPane } from "reactstrap"

import "./style.scss"
import CronEditorTabs from "./CronEditorTabs"
import { CronModes, CronDisplacementModes, CronTabs, CronWeekDaysIds } from "../../../../enums"
import Hourly from "./Tabs/Hourly"
import Daily from "./Tabs/Daily"
import Weekly from "./Tabs/Weekly"
import Monthly from "./Tabs/Monthly"
import { CronSettingsInitialValue, CronTabNames } from "../../../../constants/constants"
import { cloneDeep } from 'lodash'
import cronstrue from 'cronstrue';
import moment from "moment"
import { IAllowedRange } from "../../../../components/TimeInput"
import { isSequential } from "../../../../utils/utils"

interface Props {
    cron: string,
    setNewCron: Dispatch<SetStateAction<string>>,
    timezone: string
}

const CronEditor: React.FC<Props> = ({
    cron,
    setNewCron,
    timezone
}) => {
    const [activeTab, setActiveTab] = useState(process.env.REACT_APP_STAGE !== 'prod' ? CronTabNames[0] : CronTabNames[1]);
    const [timezoneCronExpression, setTimezoneCronExpression] = useState(cron);
    const [cronExpression, setCronExpression] = useState(cron);
    const [cronSettings, setCronSettings] = useState(CronSettingsInitialValue)
    const [cronDescription, setCronDescription] = useState(cronstrue.toString(cronExpression));
    const [currentMode, setCurrentMode] = useState(CronModes.everyNHours);
    const [allowedTimeRange] = useState<IAllowedRange>({
        min: "00:00",
        max: "23:50"
    });

    const switchTab = useCallback((tab: string) => {
        setActiveTab(tab);

        if (tab.toUpperCase() === CronTabs.weekly.toUpperCase()) {
            changeMode(CronModes.weekly);
        }
    }, [])

    const initEditor = useCallback(() => {
        const cronParts = cron.split(" ");

        const minutes = cronParts[0];
        const hours = cronParts[1];
        const day = cronParts[2];
        const month = cronParts[3];
        const daysOfWeek = cronParts[4];

        let newCronSettings = cloneDeep(CronSettingsInitialValue);
        let initM = minutes.includes("*/") ? Number(minutes.slice(2)) : Number(minutes);
        let initH = hours.includes("*/") ? Number(hours.slice(2)) : Number(hours);
        let m = minutes.includes("*/") ? Number(minutes.slice(2)) : Number(minutes);
        let h = hours.includes("*/") ? Number(hours.slice(2)) : Number(hours);
        let d = day.includes("1/") ? Number(day.slice(2)) : Number(day);

        let time = moment.tz(`${h}:${m}`, "H:m", timezone);
        let offset = time.utcOffset() / 60;
        let displacementMode = CronDisplacementModes.none;
        let minutesOffset = time.utcOffset() % 60;

        if (timezone) {
            m = time.minutes() + minutesOffset;
            h = Math.abs(h) === 0.5 ? 0 : Math.ceil(time.hours() + offset);
            displacementMode = h < 0 ? CronDisplacementModes.backward : h > 23 ? CronDisplacementModes.forward : CronDisplacementModes.none;
            
            h = h < 0 ? 24 + h : (h > 23 ? 0 + h - 24 : h)
            m = m < 0 ? 60 + m : (m > 50 ? 0 + m - 60 : m)

            if (displacementMode === CronDisplacementModes.forward) {
                d = d + 1;
            } else if (displacementMode === CronDisplacementModes.backward) {
                d = d - 1;
            }

            if (d < 1) {
                d = 31;
            } else if (d > 31) {
                d = 1;
            }
        }

        newCronSettings.datetime.minutes = m;
        newCronSettings.datetime.hours = h;

        if (day === "*" && month === "*" && daysOfWeek === "*") {
            // hourly tab
            if (hours.includes("*/") && minutes === "0") {
                // every n hour(s)
                switchTab(CronTabNames[0]);
                newCronSettings.tabs.hourly.everyNHours = true;
                setCurrentMode(CronModes.everyNHours);
                newCronSettings.datetime.minutes = initM;
                newCronSettings.datetime.hours = initH;
            } else if (hours === "*" && minutes.includes("*/")) {
                // every n hour(s)
                switchTab(CronTabNames[0]);
                newCronSettings.tabs.hourly.everyNMinutes = true;
                setCurrentMode(CronModes.everyNMinutes);
                newCronSettings.datetime.minutes = initM;
                newCronSettings.datetime.hours = initH;
            }
            else {
                switchTab(CronTabNames[0]);
                newCronSettings.tabs.hourly.atHoursAndMinutes = true;
                setCurrentMode(CronModes.atHoursAndMinutes);
            }
        }

        if (day !== "*") {
            if (day.includes("1/")) {
                // daily tab
                // every n day(s)
                switchTab(CronTabNames[1]);
                newCronSettings.tabs.daily.everyNDays = true;
                setCurrentMode(CronModes.everyNDays);
            } else {
                // monthly tab
                // n day(s) of every month
                switchTab(CronTabNames[3]);
                newCronSettings.datetime.day = d;
                newCronSettings.tabs.monthly.nDaysOfEveryMonth = true;
                setCurrentMode(CronModes.nDaysOfEveryMonth);
            }
        }

        if (daysOfWeek !== "*") {
            if (daysOfWeek === ("1-5")) {
                // daily tab
                // every week day
                switchTab(CronTabNames[1]);
                newCronSettings.tabs.daily.everyWeekdays = true;
                setCurrentMode(CronModes.everyWeekdays);
            } else {
                // weekly tab
                let daysOfWeekIds: any[] = [];
                            
                // is sequential
                if (daysOfWeek.includes("-")) {
                    daysOfWeekIds = daysOfWeek.split("-");
                    let firstId = Number(daysOfWeekIds[0]);
                    let lastId = Number(daysOfWeekIds[1]);

                    for (let i = firstId; i < lastId; i++) {
                        daysOfWeekIds.push(i);
                    }
                } else if (daysOfWeek.includes(",")) {
                    let days = daysOfWeek.split(",");

                    for (let i = 0; i < days.length; i++) {
                        daysOfWeekIds.push(Number(days[i]));
                    }
                } else {
                    daysOfWeekIds.push(Number(daysOfWeek));
                }

                switchTab(CronTabNames[2]);

                if (daysOfWeek !== "0-6") {
                    if (displacementMode === CronDisplacementModes.forward) {
                        for(let i = 0; i < daysOfWeekIds.length; i++) {
                            if (daysOfWeekIds[i] === CronWeekDaysIds.saturday) {
                                daysOfWeekIds[i] = CronWeekDaysIds.sunday;
                            } else {
                                daysOfWeekIds[i] = daysOfWeekIds[i] + 1;
                            }
                        }
                    } else if (displacementMode === CronDisplacementModes.backward) {
                        for(let i = 0; i < daysOfWeekIds.length; i++) {
                            if (daysOfWeekIds[i] === CronWeekDaysIds.sunday) {
                                daysOfWeekIds[i] = CronWeekDaysIds.saturday;
                            } else {
                                daysOfWeekIds[i] = daysOfWeekIds[i] - 1;
                            }
                        }
                    }
                }
                newCronSettings.tabs.weekly.options.forEach((o) => {
                    if (daysOfWeekIds.some(dow => Number(dow) === o.id)) {
                        o.enabled = true;
                    }
                });
            }
        }

        setCronSettings(newCronSettings);
    }, [cron, switchTab, timezone]);

    const changeMode = (mode: number) => {
        let newCronSettings = cloneDeep(CronSettingsInitialValue);
        setCurrentMode(mode);

        switch(mode) {
            case CronModes.everyNMinutes:
                newCronSettings.tabs.hourly.everyNMinutes = true;
                newCronSettings.datetime.minutes = 10;
                break;
            case CronModes.everyNHours:
                newCronSettings.tabs.hourly.everyNHours = true;
                newCronSettings.datetime.hours = 1;
                newCronSettings.datetime.minutes = 10;
                break;
            case CronModes.atHoursAndMinutes:
                newCronSettings.tabs.hourly.atHoursAndMinutes = true;
                break;
            case CronModes.everyNDays:
                newCronSettings.tabs.daily.everyNDays = true;
                break;
            case CronModes.everyWeekdays:
                newCronSettings.tabs.daily.everyWeekdays = true;
                break;
            case CronModes.nDaysOfEveryMonth:
                newCronSettings.tabs.monthly.nDaysOfEveryMonth = true;
                break;
        }

        setCronSettings(newCronSettings);
    }

    const resetSettings = () => {
        setCronSettings(CronSettingsInitialValue);
    }

    const updateDateTime = useCallback((hours: number, minutes: number, day: number) => {
        if (currentMode === CronModes.everyNHours && hours < 1) {
            return;
        }

        if (currentMode === CronModes.everyNMinutes && (minutes % 10 !== 0 || minutes < 10)) {
            return;
        }

        if (currentMode !== CronModes.everyNMinutes) {
            if (hours < 0 || hours > 23 || minutes % 10 !== 0 || minutes < 0 || minutes > 59 || day < 1) {
                return;
            }
        }

        if (currentMode === CronModes.nDaysOfEveryMonth && (day < 1 || day > 31)) {
            return;
        }

        setCronSettings((prev) => {
            return { ...prev, datetime: { hours, minutes, day }}
        })
    }, [currentMode])

    const selectedWeekDays = useCallback((displacementMode: number = CronDisplacementModes.none) => {
        let weekDays = cronSettings.tabs.weekly.options.filter((o) => o.enabled).map((o) => o.id);

        switch(displacementMode) {
            case CronDisplacementModes.forward:
                for (let i = 0; i < weekDays.length; i++) {
                    const d = weekDays[i];
                    if (d === CronWeekDaysIds.saturday) {
                        weekDays[i] = CronWeekDaysIds.sunday;
                    } else {
                        weekDays[i] = d + 1;
                    }
                }
                break;
            case CronDisplacementModes.backward:
                for (let i = 0; i < weekDays.length; i++) {
                    const d = weekDays[i];
                    if (d === CronWeekDaysIds.sunday) {
                        weekDays[i] = CronWeekDaysIds.saturday;
                    } else {
                        weekDays[i] = d - 1;
                    }
                }
                break;
        }

        weekDays.sort((a, b) => a - b);

        if (weekDays.length === 1) {
            return `${weekDays[0]}`;
        } else if (weekDays.length === cronSettings.tabs.weekly.options.length) {
            return "0-6";
        } else if (weekDays.length > 0) {
            if (isSequential(weekDays)) {
                return `${weekDays[0]}-${weekDays[weekDays.length-1]}`;
            } else {
                let weekDayString = "";

                weekDays.forEach((wd, i) => {
                    weekDayString += wd;

                    if (i+1 !== weekDays.length) {
                        weekDayString += ",";
                    }
                })
                
                return weekDayString;
            }
        } else {
            return "*";
        }
    }, [cronSettings])

    const generateCron = useCallback((mode: number, hours: number, minutes: number, day: number) => {
        let time = moment.tz(`${hours}:${minutes}`, "H:m", timezone);
        
        let utcHours = hours;
        let utcMinutes = minutes;
        let d = day;

        if (mode !== CronModes.everyNHours && mode !== CronModes.everyNMinutes) {
            utcHours = time.utc().hours();
            utcMinutes = time.utc().minutes();
        }

        // day of month
        let offset = 0;
        let displacementMode = CronDisplacementModes.none; 

        offset = moment.tz(timezone).utcOffset() / 60;

        if (offset < 0 && utcHours < hours) {
            displacementMode = CronDisplacementModes.forward;
        }
        
        if (offset > 0 && utcHours > hours) {
            displacementMode = CronDisplacementModes.backward;
        }

        if (mode === CronModes.nDaysOfEveryMonth) {
            if (displacementMode === CronDisplacementModes.forward) {
                d = day + 1;
            } else if (displacementMode === CronDisplacementModes.backward) {
                d = day - 1;
            }
    
            d = d > 31 ? 1 : d < 1 ? 31 : d;
        }

        // TIMEZONE CRON
        let tzMinutes = utcMinutes;
        let tzHours = utcHours;

        if (currentMode !== CronModes.everyNHours && currentMode !== CronModes.everyNMinutes && timezone) {
            let convertedTime = moment.utc(`${tzMinutes} ${tzHours}`, 'm H').tz(timezone);
            tzMinutes = convertedTime.minutes();
            tzHours = convertedTime.hours();
        }

        switch(mode) {
            case CronModes.everyNMinutes:
                setCronExpression(`*/${utcMinutes} * * * *`)
                setTimezoneCronExpression(`*/${utcMinutes} * * * *`)
                break;
            case CronModes.everyNHours:
                setCronExpression(`0 */${utcHours} * * *`)
                setTimezoneCronExpression(`0 */${utcHours} * * *`)
                break;
            case CronModes.atHoursAndMinutes:
                setCronExpression(`${utcMinutes} ${utcHours} * * *`)
                setTimezoneCronExpression(`${tzMinutes} ${tzHours} * * *`)
                break;
            case CronModes.everyNDays:
                setCronExpression(`${utcMinutes} ${utcHours} 1/${d} * *`)
                setTimezoneCronExpression(`${tzMinutes} ${tzHours} 1/${d} * *`)
                break;
            case CronModes.everyWeekdays:
                setCronExpression(`${utcMinutes} ${utcHours} * * 1-5`)
                setTimezoneCronExpression(`${tzMinutes} ${tzHours} * * 1-5`)
                break;
            case CronModes.nDaysOfEveryMonth:
                setCronExpression(`${utcMinutes} ${utcHours} ${d} * *`)
                setTimezoneCronExpression(`${tzMinutes} ${tzHours} ${day} * *`)
                break;
            case CronModes.weekly:
                setCronExpression(`${utcMinutes} ${utcHours} * * ${selectedWeekDays(displacementMode)}`)
                setTimezoneCronExpression(`${tzMinutes} ${tzHours} * * ${selectedWeekDays()}`)
                break;
        }
    }, [selectedWeekDays, currentMode, timezone])

    const toggleWeekday = (id: number) => {
        let newCronSettings = cloneDeep(cronSettings);
        let weekDay = newCronSettings.tabs.weekly.options.find((o) => o.id === id);

        if (weekDay) {
            weekDay.enabled = !weekDay.enabled;
        }

        setCronSettings(newCronSettings);
        generateCron(currentMode, cronSettings.datetime.hours, cronSettings.datetime.minutes, cronSettings.datetime.day);
    }

    useEffect(() => {
        initEditor();
    }, [initEditor]);

    useEffect(() => {
        generateCron(currentMode, cronSettings.datetime.hours, cronSettings.datetime.minutes, cronSettings.datetime.day);
    }, [cronSettings.datetime.hours, cronSettings.datetime.minutes, cronSettings.datetime.day, generateCron, currentMode]);

    useEffect(() => {
        setNewCron(cronExpression);
    }, [cronExpression, setNewCron])

    useEffect(() => {
        setCronDescription(cronstrue.toString(timezoneCronExpression));
    }, [timezoneCronExpression])

    return (
        <>
            <CronEditorTabs activeTab={activeTab} switchTab={switchTab} resetSettings={resetSettings} setCronExpression={setCronExpression}/>
            <TabContent activeTab={activeTab} className="ml-3 mr-3">
                { process.env.REACT_APP_STAGE !== 'prod' ? (
                    <TabPane tabId={CronTabNames[0]}>
                        <Hourly settings={cronSettings} updateDateTime={updateDateTime} changeMode={changeMode} allowedTimeRange={allowedTimeRange} currentMode={currentMode} />
                    </TabPane>
                ) : ""}
                <TabPane tabId={CronTabNames[1]}>
                    <Daily settings={cronSettings} updateDateTime={updateDateTime} changeMode={changeMode} allowedTimeRange={allowedTimeRange} />
                </TabPane>
                <TabPane tabId={CronTabNames[2]}>
                    <Weekly settings={cronSettings} updateDateTime={updateDateTime} toggleWeekday={toggleWeekday} allowedTimeRange={allowedTimeRange} />
                </TabPane>
                <TabPane tabId={CronTabNames[3]}>
                    <Monthly settings={cronSettings} updateDateTime={updateDateTime} changeMode={changeMode} allowedTimeRange={allowedTimeRange} />
                </TabPane>
                <div className="cron-description mt-3">
                    {cronDescription}
                </div>
                <div className="cron-description">
                    {cronExpression}
                </div>
            </TabContent>
        </>
    )
}

export default CronEditor
