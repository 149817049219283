import React, { useCallback } from "react";
import { SearchItemType } from "../../../components/ItemsSearch";
import ItemsSelectWithSearch from "../../../components/ItemsSelectWithSearch/ItemsSelectWithSearch";
import AssetsSelect from "./AssetsSelect";
import { EndPointConfigs } from "../../../components/BackendSearchInput/constants";
import DateTimePicker from "../../../componentsV2/DateTimePicker";
import { CalendarTypes } from "../../../enums";
import moment from "moment";

type INotificationHistoryReportForm = {
  disabled: boolean;
  dateFrom: Date;
  dateTo: Date;
  notificationTypes: { email: boolean; sms: boolean; push: boolean };
  onDateRangeSelect: (from: Date, to: Date) => void;
  setNotificationTypes: React.Dispatch<
    React.SetStateAction<{
      email: boolean;
      sms: boolean;
      push: boolean;
    }>
  >;
  setUsernames: (list: Object[]) => void;
  setVinList: React.Dispatch<React.SetStateAction<Array<Object>>>;
  selectedAssetsItems: Array<SearchItemType>;
  selectedUsersItems: Array<SearchItemType>;
};

const usersColumnsToShow = [
  { key: "username", name: "Username" },
  { key: "first_name", name: "First Name" },
  { key: "last_name", name: "Last Name" },
  { key: "email", name: "Email" },
];

const NotificationHistoryReportForm: React.FC<
  INotificationHistoryReportForm
> = ({
  disabled,
  dateFrom,
  dateTo,
  notificationTypes,
  onDateRangeSelect,
  setUsernames,
  setVinList,
  setNotificationTypes,
  selectedAssetsItems,
  selectedUsersItems,
}) => {
  const onUsersSelect = useCallback(
    (selectedUsers: SearchItemType[]) => {
      setUsernames(selectedUsers);
    },
    [setUsernames]
  );

  return (
    <>
      <div className="mb-2">Select Date Range</div>
      <DateTimePicker
        defaultMode={CalendarTypes.DateRange}
        dateFrom={dateFrom.toISOString()}
        dateTo={dateTo.toISOString()}
        onDateRangeSelect={onDateRangeSelect}
        maxDate={moment().toDate()}
        disabled={disabled}
      />
      <div className="mb-2 mt-3">Notification types</div>
      <div className="d-flex flex-row">
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="checkbox"
            id="email"
            checked={notificationTypes.email}
            onChange={() =>
              setNotificationTypes((old) => ({ ...old, email: !old.email }))
            }
          />
          <label className="form-check-label" htmlFor="email">
            Email
          </label>
        </div>

        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="checkbox"
            id="SMS"
            checked={notificationTypes.sms}
            onChange={() =>
              setNotificationTypes((old) => ({ ...old, sms: !old.sms }))
            }
          />
          <label className="form-check-label" htmlFor="SMS">
            SMS
          </label>
        </div>

        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="checkbox"
            id="app"
            checked={notificationTypes.push}
            onChange={() =>
              setNotificationTypes((old) => ({ ...old, push: !old.push }))
            }
          />
          <label className="form-check-label" htmlFor="app">
            App
          </label>
        </div>
      </div>

      <ItemsSelectWithSearch
        label="Users"
        inputPlaceholder="Search Users"
        endPointConfig={EndPointConfigs.user}
        onItemsSelect={onUsersSelect}
        columnsToShow={usersColumnsToShow}
        disabled={disabled}
        tableWidth="800px"
        selectedItems={selectedUsersItems}
      />

      <AssetsSelect
        disabled={disabled}
        setVinList={setVinList}
        selectedItems={selectedAssetsItems}
      />
    </>
  );
};

export default NotificationHistoryReportForm;
