import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import {
  Nav,
  NavItem,
  NavLink,
  Navbar,
  Collapse,
} from "reactstrap"
import { convertDataEpochToDate, getCurrentEpoch } from '../../utils'

import HeaderMenu from "./HeaderMenu"

import "./style.scss"

interface IHeaderProps {
    whiteLabelUrl: string
    user: {
        email: string | null
        userName: string | null
    }
    phoneNumberVerified: boolean
    isAssignedToSmsAlerts: boolean
}

const showMobilemenu = () => {
    document.getElementById("main-wrapper")?.classList.toggle("show-sidebar");
}

let interval: NodeJS.Timeout


const Header: React.FC<IHeaderProps> = ({
    whiteLabelUrl,
    user,
    phoneNumberVerified,
    isAssignedToSmsAlerts,
}) => {
    const [currentDateTime, setCurrentDateTime] = useState(null)

    useEffect(() => {
        interval = setInterval(() => {
            const newDatetime = convertDataEpochToDate(getCurrentEpoch(), null, null, true)
            if (currentDateTime !== newDatetime) setCurrentDateTime(newDatetime)
        }, 1000)

        return () => {
            clearInterval(interval)
        }
    }, [currentDateTime])

    const sidebarHandler = () => {
        let element = document.getElementById("main-wrapper")
        
        if (element) {
            switch ("mini-sidebar") {
                case "mini-sidebar":
                  element.classList.toggle("full")
                  if (element.classList.contains("full")) {
                    element.setAttribute("data-sidebartype", "full")
                  } else {
                    element.setAttribute("data-sidebartype", "mini-sidebar")
                  }
                  break
                default:
            }
        }
    }

    return (
        <header
            className="topbar navbarbg"
            data-navbarbg={"skin5"}
            style={{ zIndex: 1001 }}
        >
            <Navbar
                className={"top-navbar navbar-dark"}
                expand="md"
            >
                <div
                    className="navbar-header"
                    id="logobg"
                    data-logobg={"skin6"}
                >
                    <span
                        className="nav-toggler d-block d-md-none"
                        onClick={showMobilemenu}
                    >
                        <i className="ti-menu ti-close" />
                    </span>
                    
                    <Link to="/dashboard" className="d-flex">
                        <b className="logo-icon">
                            <img
                                src={`${whiteLabelUrl}logo_icon.png?d=${getCurrentEpoch()}`}
                                style={{ width: 40, height: 40, objectFit: "contain" }}
                                alt="homepage"
                                className="dark-logo p-1 m-2"
                            />
                        </b>
                        <span className="logo-text">
                            <img
                                src={`${whiteLabelUrl}logo_full.png?d=${getCurrentEpoch()}`}
                                style={{ maxWidth: 160, height: 64, objectFit: "contain" }}
                                alt="homepage"
                                className="dark-logo"
                            />
                        </span>
                    </Link>
                </div>

                <Collapse
                    isOpen={false}
                    className="navbarbg"
                    navbar
                    data-navbarbg={"skin5"}
                >
                    <Nav className="float-left" navbar>
                        <NavItem>
                            <NavLink
                                href="#"
                                className="d-none d-md-block"
                                onClick={sidebarHandler}
                            >
                                <i className="fa-solid fa-bars" style={{ fontSize: "18px" }} />
                            </NavLink>
                        </NavItem>
                    </Nav>

                    <div 
                        className="w-100 d-flex justify-content-end mr-5"
                        style={{ color: "#fff", fontSize: "1rem" }}
                    >
                        <label className="mb-0">{currentDateTime}</label>
                    </div>

                    <HeaderMenu 
                        phoneNumberVerified={phoneNumberVerified} 
                        isAssignedToSmsAlerts={isAssignedToSmsAlerts} 
                        user={user}                    
                    />
                </Collapse>
            </Navbar>
        </header>
    )
}

export default Header
