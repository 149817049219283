import {
  celsiusToFahr,
  getColor,
  getClearLabel,
  convertDataEpochToDate,
  DATE_FORMAT,
} from "../../utils";
import { getFilteredPositions, getUniqueDatasets, sortDataByPositions } from "../../utils/chartUtils";
import { UnitsOfMeasurement } from "../../enums";

export const composeLineChartData = (data, graphColors, unitsOfMeasurement) => {
  const filteredKeys = getFilteredPositions(data.map(el => el[1]), "smarthub")
  const temperatureUnits = unitsOfMeasurement === UnitsOfMeasurement.imperial ? "°F" : "°C"

  const datasets = data.reduce((lines, curr) => {
    if (!filteredKeys.includes(curr[1])) return lines

    const label = getClearLabel(curr[1])
    const color = getColor(label, graphColors)

    lines.push({
      label,
      position: curr[1],
      backgroundColor: color,
      borderColor: color,
      borderWidth: 2,
      fill: false,
      data: curr[2].reduce((accum, curr) => {
        const y = unitsOfMeasurement === UnitsOfMeasurement.imperial ? celsiusToFahr(curr[1]).toFixed(1) : curr[1]
        const x = convertDataEpochToDate(curr[0], null, null, false)

        accum.push({
          y,
          x,
          aaa: curr,
          displayValue: `Temperature: ${y} ${temperatureUnits}`,
          displayFooter: x.format(DATE_FORMAT)
        })
  
        return accum
      }, [])
    })
    
    return lines
  }, [])

  const uniqueDatasets = getUniqueDatasets(datasets)
  
  sortDataByPositions(uniqueDatasets)

  return { datasets: uniqueDatasets }
};
