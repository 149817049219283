import React, { useState, useEffect } from "react";
import { Label, Input } from "reactstrap";
export default ({ control, onCheck, id, checked, label, sortColumn, setSortColumn, sortHandler, customStyle, customInlineStyle, draggableProps }) => {
  const [sort, setSort] = useState(null)

  useEffect(() => {
    if (sortColumn === id) return
    setSort(null)
  }, [sortColumn, id])

  useEffect(() => {
    if (!id || !sort || !sortHandler) return
    sortHandler(id, sort)
  }, [id, sort, sortHandler])

  const isSubs = label === "Subscription" ? "mr-5" : "mr-2"
  return (

    <th className={customStyle} style={customInlineStyle ? customInlineStyle : {}}
      ref={draggableProps?.draggableRef}
      {...draggableProps?.draggableProps}
      {...draggableProps?.dragHandleProps} >
      <div

        className="align-items-center d-flex table__sort"
        onClick={control === "sort" ? () => {
          if (sortColumn !== id) {
            setSortColumn(id)
          }

          if (!sort) {
            setSort("desc")
          } else if (sort === "desc") {
            setSort("asc")
          } else {
            setSort("desc")
          }
        } : null}
      >
        {control === "sort" ? (
          <>
            {label}
            <div className={`ml-2 ${isSubs}`}>
              {!sort ? (
                <i className="fas fa-sort sort__default" />
              ) : sort === "desc" ? (
                <i className="fas fa-sort-down sort__asc" />
              ) : (
                <i className="fas fa-sort-up sort__desc" />
              )}
            </div>
          </>
        ) : control === "checkbox" ? (
          <Label check>
            <Input
              type="checkbox"
              checked={checked}
              onChange={(evt) => onCheck(id, evt.target.checked)}
            />
            {label}
          </Label>
        ) : (
          label
        )}
      </div>
    </th>
  );
};
