import React from 'react';
import { Spinner } from 'reactstrap';
import './styles.scss';

const RivataLoader = () => {

    return (
        <div className="rivata-loader d-flex justify-content-center align-items-center">
            <Spinner animation="grow" color="dark" />
        </div>
    )
}

export default RivataLoader;