import React, { useMemo, useContext, useState } from "react";

import RivataLoader from "../../components/RivataLoader";
import RivataModule from "../../components/RivataModule";
import RivataLineChart from "../../components/RivataLineChart";
import RivataDropdown from "../../components/RivataDropdown";
import StatusAlert from "../../components/StatusAlert";

import { UnitsOfMeasurement } from "../../enums";
import { AxlesGroups } from "../../constants/constants";

import { composeLineChartData } from "./utils";
import { celsiusToFahr } from "../../utils";
import {
  composeCustomTooltip,
  getAxlesDropdownItems,
} from "../../utils/chartUtils";

import { useTypedSelector } from "../../hooks/useTypedSelector";

import { AssetDetailsContext } from '../../pages/AssetDetails'

import { temperatureChartThresholdAnnotations } from "./annotations";
import { saveGoogleAnalyticsEvent } from '../../utils/utils'

const id = "temperature";
const tooltip = composeCustomTooltip({
  displayLabelColors: true,
  tableFormat: true,
  footer: true,
  footerAlign: "right"
})

const TemperatureChart = ({ width }) => {
  const {
    temperature: { isLoading, data, status },
    customerDefaults,
  } = useTypedSelector(state => ({
    temperature: state.assetDetails.temperature,
    customerDefaults: state.common.customerDefaults,
  }))


  const {
    locale,
    chartsXAxisLabel,
    unitsOfMeasurement,
    healthColors,
    wheelColors,
    timestamp
  } = useContext(AssetDetailsContext)


  const [selectedAxle, setSelectedAxle] = useState("all");

  const thresholds = useMemo(() => {
    const { temperature_treshold, critical_temperature_treshold } = customerDefaults.smarthub;
    
    const isImperial = unitsOfMeasurement === UnitsOfMeasurement.imperial;

    return {
      "threshold": isImperial ? celsiusToFahr(temperature_treshold) : temperature_treshold,
      "critical_threshold": isImperial ? celsiusToFahr(critical_temperature_treshold) : critical_temperature_treshold,
    }    
  }, [customerDefaults, unitsOfMeasurement])
  
  const chartData = useMemo(() => {
    if (data.length && wheelColors) {
      return composeLineChartData(data, wheelColors, unitsOfMeasurement);
    }
    return null;
  }, [data, wheelColors, unitsOfMeasurement]);

  const filtredChartData = useMemo(() => {
    if (selectedAxle === "all" || !chartData) return chartData

    return { datasets: chartData.datasets.filter(line => AxlesGroups[selectedAxle].includes(line.label)) }
  }, [chartData, selectedAxle])

  const dropdownItems = useMemo(() => {
    return getAxlesDropdownItems(chartData?.datasets?.map(el => el.label))
  }, [chartData]);

  const annotations = useMemo(() => {
    return temperatureChartThresholdAnnotations(
      id,
      thresholds.threshold,
      thresholds.critical_threshold,
      timestamp,
      healthColors
    )
  }, [thresholds, timestamp, healthColors])

  const yLabel = useMemo(() => {
    return `Temperature (${unitsOfMeasurement === UnitsOfMeasurement.imperial ? "°F" : "°C"})`
  }, [unitsOfMeasurement])

  return (
    <RivataModule
      fullScreenModalModeEnabled
      title="Hub Temperature"
      width={width}
      locale={locale}
      error={status}
      filters={dropdownItems.length > 1 && (
        <div>
          <label className="mr-2">Select Axle:</label>
          <RivataDropdown
            items={dropdownItems}
            selected={selectedAxle}
            onSelect={(value) => {
              saveGoogleAnalyticsEvent('charts_axle_filter', {axle: value})
              
              setSelectedAxle(value)
          }}
          />
        </div>
      )}
      collapsible
    >
      {isLoading ? (
        <RivataLoader />
      ) : filtredChartData ? (
        <RivataLineChart
          id={id}
          height={400}
          data={filtredChartData}
          yAxisLabel={yLabel}
          xAxisLabel={chartsXAxisLabel}
          threshold={thresholds.threshold}
          tooltip={tooltip}
          annotations={annotations}
        />
      ) : (
        <StatusAlert customText="No Data" color="success" />
      )}
    </RivataModule>
  );
};

export default TemperatureChart;
