import { getClearLabel, getMileage } from "../../../utils";
import { IColumn, IRow } from "../../../components/RivataGrid";

const getColumnName = (column: string, unitsOfMeasurement: string) => {
  let label = column;

  if (column === "mileage" || column === "start_odometer_value" || column === "end_odometer_value") {
    if (unitsOfMeasurement === "metric") {
      label += " (km)";
    } else {
      label += " (mi)";
    }
  }

  return label !== "vin" ? getClearLabel(label) : label.toUpperCase();
};

export const dailyReportColumns = (columns: Array<string>, unitsOfMeasurement: string): Array<IColumn> => {
  columns.push("mileage") // not in response cause data will be calculated based on others response values on frontend side

  const parsedColumns = columns.map((column) => ({ key: column, name: getColumnName(column, unitsOfMeasurement), width: 260, minWidth: 100 }));

  return parsedColumns
}

type RowItem = {
  start_value: number
  end_value: number
  date: string
}

// interface IMileageRow {
//   asset_id: number
//   asset_name: string
//   vin: string
//   data: Array<RowItem>
// }

export const dailyReportRows = (rows: Array<IRow>, unitsOfMeasurement: string): Array<any> => {
  return rows.reduce((accum: Array<IRow>, curr) => {
    curr.data.forEach((item: RowItem) => {
      const start_value = item.start_value
      const end_value = item.end_value

      accum.push({
        asset_name: curr.asset_name,
        vin: curr.vin,
        date: item.date,
        start_odometer_value: getMileage(start_value, unitsOfMeasurement).toFixed(2),
        end_odometer_value: getMileage(end_value, unitsOfMeasurement).toFixed(2),
        mileage: getMileage(end_value - start_value, unitsOfMeasurement).toFixed(2)
      })
    })

    return accum
  }, [])
}
