import { getClearLabel } from "../../../utils";
import { IRow } from "../../../components/RivataGrid";

export const assetProvisioningSummaryReportRows = (rows: Array<IRow>) =>
    rows.map((row) => {
        row.hardware_type = row.hardware_type ? getClearLabel(row.hardware_type) : "---";
        row.install_position = row.install_position ? getClearLabel(row.install_position) : "---";

        return row;
    });
