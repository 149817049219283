import React, { useCallback } from 'react'
import RivataModule from '../../components/RivataModule'
import RivataTpmsTable from '../../components/RivataTpmsTable'
import './styles.scss'

const TpmsStatusTable = ({
  title,
  tpmsStatusAssets,
  defaults,
  preferences,
  width,
  locale,
  isShowingLimit,
  setSortOptions,
  setLimit,
  setOffset,
  columnsToShow,
  setColumnsOrder,
}) => {
  const sortHandler = useCallback(
    (sortColumn, sortDirection) => {
      setSortOptions({ column: sortColumn, direction: sortDirection })
    },
    [setSortOptions],
  )

  return (
    <RivataModule
      title={title}
      width={width}
      marginTop={0}
      locale={locale}
      error={tpmsStatusAssets.error}
    >
      <RivataTpmsTable
        tpmsStatusAssets={tpmsStatusAssets}
        defaults={defaults}
        preferences={preferences}
        isShowingLimit={isShowingLimit}
        onPageChange={(offset) => setOffset(offset)}
        pageLimit={tpmsStatusAssets.limit}
        onSelectLimit={(limit) => {
          setLimit(limit)
          setOffset(0)
        }}
        onSort={sortHandler}
        columnsToShow={columnsToShow}
        setColumnsOrder={setColumnsOrder}
      />
    </RivataModule>
  )
}

export default TpmsStatusTable
