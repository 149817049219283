import React, { FormEvent, useCallback, useState } from 'react'
import { Formik } from 'formik'
import { Row, Col, Button, Form } from "reactstrap"
import moment from 'moment'
import * as Yup from 'yup'

import RivataFormField from '../../../components/RivataFormField'
import TimeInput from '../../../components/TimeInput'
import UnitsOfMeasureToggle from '../../../components/UnitsOfMeasureToggle'
import { DateInput } from '../../../components/DateRangeCalendar'

import { UnitsOfMeasurement } from '../../../enums'
import { dateToEpoch, getCurrentEpoch } from '../../../utils'


const schema = Yup.object({
    odometer: Yup.number().required("This field is required").min(0, "Value must be positive").max(99999999, "Value is too big")
})

const initialValues = { odometer: 0 }

interface Props {
    selectedAsset: AssetShortFormatType | null
    timezone: string
    selectedUnitsOfMeasurement: string
    setSelectedUnitsOfMeasurement: React.Dispatch<React.SetStateAction<string>>
    handleOdometerFormSubmit: (data: any, formikHelpers: any, startDate: number) => Promise<any>
}

const OdometerForm: React.FC<Props> = ({
    selectedAsset,
    timezone,
    selectedUnitsOfMeasurement,
    setSelectedUnitsOfMeasurement,
    handleOdometerFormSubmit
}) => {
    const [ dateStart, setDateStart ] = useState(new Date())
    const [ timeStart, setTimeStart ] = useState<string>(moment(getCurrentEpoch() * 1000).tz(timezone).format(`HH:mm`))
    const [ minCalendarDate ] = useState(1577836800)

    const onSubmit = useCallback(async (data, formikHelpers) => {
        const { startDate } = dateToEpoch(dateStart, new Date(), timeStart)

        const res = await handleOdometerFormSubmit(data, formikHelpers, startDate)

        if (!res || res.status !== "ok") return
        
        setDateStart(new Date())
        setTimeStart(moment(getCurrentEpoch() * 1000).tz(timezone).format(`HH:mm`))
    }, [timezone, dateStart, timeStart, handleOdometerFormSubmit])

    return (
        <div>
            <Formik
                enableReinitialize={true}
                validationSchema={schema}
                initialValues={initialValues}
                onSubmit={onSubmit}
            >
                {(props) => (
                    <Form
                        onSubmit={(e: FormEvent) => {
                            e.preventDefault()
                            props.submitForm()
                        }}
                    >
                        <fieldset disabled={!selectedAsset?.id}>
                            <Row className="d-flex justify-content-between">
                                <RivataFormField
                                    type="number" 
                                    step="0.1"
                                    md="2"
                                    label={`Odometer(${selectedUnitsOfMeasurement === UnitsOfMeasurement.imperial ? "Miles" : "Kilometers"})`}
                                    name="odometer"
                                />

                                <Col md="3" className="d-flex justify-content-end">
                                    <div>
                                        <UnitsOfMeasureToggle 
                                            unitsOfMeasure={selectedUnitsOfMeasurement} 
                                            onChangeUnitsOfMeasure={setSelectedUnitsOfMeasurement}
                                        />
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <DateInput
                                    onSelect={setDateStart}
                                    isSingleDate={true}
                                    singleDateMd={12}
                                    md={2}
                                    to={dateStart}
                                    title={"Select Vehicle Weight Date Period"}
                                    customClassNames={"calibration-form__date-range"}
                                    disabled={!selectedAsset?.id}
                                    minDate={minCalendarDate && new Date(minCalendarDate * 1000)}
                                />
                                
                                <Col md="1" className="calibration-form__time-range d-flex align-items-end">
                                    <TimeInput
                                        value={timeStart}
                                        setValue={setTimeStart}
                                        disabled={!selectedAsset?.id}
                                    />
                                </Col>
                            </Row>
                            
                            <Row className="d-flex justify-content-end">
                                <Col md="2" className="d-flex justify-content-end">
                                    <Button className="btn-primary" type="submit">Save</Button>
                                </Col>
                            </Row>
                        </fieldset>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default OdometerForm
