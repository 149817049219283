import React from 'react'

type Props = {
    label?: string,
    items: Array<any>,
    itemToString: (item: any) => string
}

const UploadCargoTagException = ({
    label = "",
    items,
    itemToString
}: Props) => {
    return (
        <div>
            {!!items.length && (
                <div>
                    <span className="upload_csv_error">EXCEPTION: {label}</span>
                    <br />

                    {items.map((item, id) => {
                        return (
                            <div key={id}>
                                <span className="assigned_cargo_tags"> {itemToString(item)} </span>
                                <br />
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    )
}

export default UploadCargoTagException