import React, { useCallback, useEffect, useRef, useState } from "react";
import { debounce, cloneDeep } from "lodash";
import Button from "../../componentsV2/Button";
import DropdownItemSelector from "../../componentsV2/DropdownItemSelector";
import { MenuItemType } from "../../componentsV2/Menu/MenuItem";
import { getGpsDropdownItems } from "./utils";
import { geofenceSearchItems } from "./filterConfigs";
import AssetGroupsFilter from "../../componentsV2/AssetGroupsFilter";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import AssetTypeFilter from "../../componentsV2/AssetTypeFilter";
import SensorTypeFilter from "../../componentsV2/SensorTypeFilter";
import HealthStatusFilter from "../../componentsV2/HealthStatusFilter";
import AssetNameVinFilter from "../../componentsV2/AssetNameVinFilter";
import GeofenceFilter from "../../componentsV2/GeofenceFilter";
import SensorIdFilter from "../../componentsV2/SensorIdFilter";

interface Props {
  timezone: string;
  onFilter: (queryParams: string) => void;
}

const debouncer = debounce((func: () => void) => func(), 400);

const wrapperStyle = { width: "470px" };
const initialSelection = {
  asset_subtype: [],
  sensor_type: [],
  status_2: [],
  last_gps_update_2: [],
  asset_group: [],
};

const FilterMenu: React.FC<Props> = ({ timezone, onFilter }) => {
  const searchParams = useRef<Record<string, Array<string>>>(
    cloneDeep(initialSelection)
  );
  const actualQuery = useRef("");
  const groupsFilterRef = useRef<any>(null);
  const assetTypeFilterRef = useRef<any>(null);
  const sensorTypeFilterRef = useRef<any>(null);
  const healthStatusFilterRef = useRef<any>(null);
  const assetNameVinFilterRef = useRef<any>(null);
  const sensorIdFilterRef = useRef<any>(null);
  const [clearLabelSelector, setClearLabelSelector] = useState(0);
  const [dropdownItems, setDropdownItems] = useState({
    geofenceSearchItems: cloneDeep(geofenceSearchItems),
    gpsDropdownItems: getGpsDropdownItems(timezone),
  });
  const { selectedCustomersList } = useTypedSelector((state) => ({
    selectedCustomersList: state.common.customers.selectedCustomersList,
  }));

  useEffect(() => {
    setDropdownItems((prev) => ({
      ...prev,
      gpsDropdownItems: getGpsDropdownItems(timezone),
    }));
  }, [timezone]);
  const updateFilters = useCallback(
    (key: string, value: Array<string> | string) => {
      let tempValue: Array<string> | string = value;

      if (!tempValue || !tempValue.length) {
        delete searchParams.current[key];
      } else if (Array.isArray(tempValue)) {
        searchParams.current[key] = tempValue;
      } else {
        searchParams.current[key] = [tempValue];
      }

      const query = new URLSearchParams();

      Object.entries(searchParams.current).forEach((filter) => {
        const key = filter[0];
        filter[1].forEach((value) =>
          query.append("filters", `${key}=${value}`)
        );
      });
      actualQuery.current = query.toString();
      onFilter(actualQuery.current);
    },
    [onFilter]
  );

  const onSearchByStringValue = (id: string, value: string) => {
    debouncer(() => updateFilters(id, value));
  };

  const handleFiltersReset = useCallback(
    (filterData: boolean = true) => {
      //some elements must be reseted even if filters weren't selected
      setClearLabelSelector((prev) => prev + 1);
      setDropdownItems({
        geofenceSearchItems: cloneDeep(geofenceSearchItems),
        gpsDropdownItems: getGpsDropdownItems(timezone),
      });

      // make filtering only if filter query params are different from prevoius
      if (actualQuery.current === "") return;

      actualQuery.current = "";
      searchParams.current = cloneDeep(initialSelection);
      groupsFilterRef?.current?.clearSelection();
      assetTypeFilterRef?.current?.clearSelection();
      sensorTypeFilterRef?.current?.clearSelection();
      healthStatusFilterRef?.current?.clearSelection();
      assetNameVinFilterRef?.current?.clearValue();
      sensorIdFilterRef?.current?.clearValue();
      if (filterData) onFilter("");
    },
    [onFilter, timezone]
  );

  const onDropdownItemSelect = useCallback(
    (id: string, items: Array<MenuItemType>) => {
      const checked = items.filter((el) => el.checked);

      debouncer(() =>
        updateFilters(
          id,
          checked.map((el) => el.id)
        )
      );
    },
    [updateFilters]
  );

  useEffect(() => {
    handleFiltersReset(false);
  }, [selectedCustomersList, handleFiltersReset]);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap">
        <AssetNameVinFilter
          wrapperStyle={wrapperStyle}
          onChange={onSearchByStringValue}
          ref={assetNameVinFilterRef}
        />

        <SensorIdFilter
          ref={sensorIdFilterRef}
          wrapperStyle={wrapperStyle}
          updateFilters={updateFilters}
        />

        <GeofenceFilter
          wrapperStyle={wrapperStyle}
          clearSelection={clearLabelSelector}
          updateFilters={updateFilters}
          searchParams={searchParams}
        />
      </div>

      <br />

      <div className="d-flex flex-wrap justify-content-between">
        <div className="d-flex flex-wrap">
          <AssetTypeFilter
            onDropdownItemSelect={onDropdownItemSelect}
            ref={assetTypeFilterRef}
          />
          <SensorTypeFilter
            onDropdownItemSelect={onDropdownItemSelect}
            ref={sensorTypeFilterRef}
          />

          <HealthStatusFilter
            onDropdownItemSelect={onDropdownItemSelect}
            ref={healthStatusFilterRef}
          />

          <DropdownItemSelector
            id="last_gps_update_2"
            filterClassName="mr-2"
            filterBtnLabel="Last GPS Update"
            items={dropdownItems.gpsDropdownItems}
            onItemsChange={onDropdownItemSelect}
          />
          <AssetGroupsFilter
            onDropdownItemSelect={onDropdownItemSelect}
            ref={groupsFilterRef}
            customerIds={selectedCustomersList.map((c: any) => c.id).join(",")}
          />
        </div>

        <div>
          <Button
            onClick={() => {
              handleFiltersReset();
            }}
          >
            Clear
          </Button>
        </div>
      </div>
    </>
  );
};

export default React.memo(FilterMenu);
