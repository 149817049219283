import React, { useMemo } from 'react'

import RivataModule from '../../components/RivataModule'
import RivataGrid from '../../components/RivataGrid'
import StatusAlert from '../../components/StatusAlert'
import RivataLoader from '../../components/RivataLoader'
import DownloadDataButton from '../../components/DownloadDataButton'

import { useTypedSelector } from '../../hooks/useTypedSelector'
import useCsvData from './useCsvData'
import useReportColumns from './useReportColumns'
import useReportRows from './useReportRows'

import { getClearLabel } from '../../utils'
import { TagReportTypes, UnitsOfMeasurement } from '../../enums'

import './style.scss'

interface Props {
  selectedReport: string
  locale: ILocale
  dateRangeString: string
  showInModal?: boolean | undefined
  customFullScreenAction?: (f: boolean) => void | undefined
  customCollapseIcon?: string | undefined
}

const ReportGrid: React.FC<Props> = ({
  selectedReport,
  locale,
  dateRangeString,
  showInModal = false,
  customFullScreenAction = (f) => {},
  customCollapseIcon,
}) => {
  const {
    report: { rows, columns, isLoading, error },
    preferences,
  } = useTypedSelector((state) => ({
    report: state.reports.report,
    preferences: state.auth.preferences,
  }))

  const { unitsOfMeasurement = UnitsOfMeasurement.imperial } = preferences
  const reportColumns = useReportColumns(
    selectedReport,
    columns,
    unitsOfMeasurement,
  )
  const reportRows = useReportRows(
    selectedReport,
    columns,
    rows,
    locale,
    unitsOfMeasurement,
  )
  const csvData = useCsvData(reportColumns, reportRows)

  const filteredColumns =
    selectedReport === TagReportTypes.UNASSIGNED_CARGO_TAGS_REPORT
      ? reportColumns.filter((el) => el.key !== 'report_run_time')
      : reportColumns

  const filter = useMemo(() => {
    if (!csvData.length || error.statusCode !== 0) return null

    let filename = selectedReport.toLowerCase()

    if (
      selectedReport === TagReportTypes.LATEST_TAG_LOCATION_REPORT &&
      dateRangeString
    ) {
      filename = `${dateRangeString} ${selectedReport.toLowerCase()}`
    }

    return (
      <DownloadDataButton
        disabled={isLoading}
        locale={locale}
        data={csvData}
        filename={filename}
      />
    )
  }, [
    csvData,
    isLoading,
    locale,
    selectedReport,
    dateRangeString,
    error.statusCode,
  ])

  const gridHeight = useMemo(() => {
    // 37 = grid header; 10 = horizontal scroll
    const height = reportRows.length > 22 ? 800 : reportRows.length * 35 + 47

    return `${height}px`
  }, [reportRows.length])

  return (
    <RivataModule
      title={getClearLabel(selectedReport)}
      locale={locale}
      marginTop={-1}
      filters={filter}
      fullScreenModalModeEnabled
      customClass={reportRows.length <= 7 ? 'grid-extra-height' : ''}
      forceShowInModal={showInModal}
      customFullScreenAction={customFullScreenAction}
      customCollapseIcon={customCollapseIcon}
    >
      <>
        {isLoading ? (
          <RivataLoader />
        ) : !reportColumns.length ||
          !reportRows.length ||
          error.statusCode >= 400 ? (
          <StatusAlert
            color={error.statusCode <= 200 ? 'success' : 'danger'}
            customText={null}
            statusCode={null}
            statusText={error.statusCode === 0 ? 'No Data' : error.message}
          />
        ) : (
          <RivataGrid
            rows={reportRows}
            columns={filteredColumns}
            height={gridHeight}
            allowHorizontalScroll={filteredColumns.length > 5}
          />
        )}
      </>
    </RivataModule>
  )
}

export default ReportGrid
