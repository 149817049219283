import React, { useCallback, useEffect, useState } from 'react'
import { CustomInput } from 'reactstrap'

import LayersDropdown from '../../../components/LayersDropdown'
import RivataLoader from '../../../components/RivataLoader'
import RivataModule from '../../../components/RivataModule'
import RivataMapCluster from '../../../components/RivataMapCluster'

import { LayerTypes } from '../../../components/RivataMapCluster/utils'
import { useTypedSelector } from '../../../hooks/useTypedSelector'

import { getCustomTooltipContent } from '../utils'
import ScrollBlockWrapper from '../../../components/ScrollBlockWrapper'

interface Props {
  locale: ILocale
  geofences: Array<IGeofence>
  isLoading: boolean
  geofencesStatus: IStatus
}

const GeofencesActivityMap: React.FC<Props> = ({
  locale,
  geofences,
  isLoading,
  geofencesStatus,
}) => {
  const { assets, preferences, locationsStatus } = useTypedSelector(
    (state) => ({
      assets: state.geofences.assets_locations.data,
      preferences: state.auth.preferences,
      locationsStatus: state.geofences.assets_locations.status,
    }),
  )

  const [selectedLayerOption, setSelectedLayerOption] = useState(
    LayerTypes.NORMAL,
  )
  const [mapZoomBounds, setMapZoomBounds] = useState({
    zoom: null,
    bounds: null,
  })
  const [markersVisible, setMarkersVisible] = useState(false)
  const [locations, setLocations] = useState<Array<any>>([])
  const [clusteringDisabled, setClusteringDisabled] = useState(false)

  const onMapViewChange = useCallback((bounds, zoom) => {
    setMapZoomBounds({ bounds, zoom })
  }, [])

  useEffect(() => {
    // TODO hack to rerender map
    setLocations(markersVisible ? assets : [])
  }, [assets, markersVisible])

  let error = { statusCode: 0, message: '' }
  if (geofencesStatus.statusCode >= 400) error = geofencesStatus
  else if (markersVisible && locationsStatus.statusCode >= 400)
    error = locationsStatus
  else if (!window.H)
    error = { statusCode: 500, message: 'Here Maps in unavailable' }

  return (
    <RivataModule
      title={'Geofence Map'}
      width={12}
      locale={locale}
      fullScreenModalModeEnabled
      customFullScreenAction={(isFullScreen) => {
        // TODO hack to rerender map in full screen
        setTimeout(() => {
          if (isFullScreen) setLocations(markersVisible ? [...assets, {}] : [])
          else setLocations(markersVisible ? assets : [])
        }, 100)
      }}
      error={error}
      filters={
        <>
          <div className='d-flex align-items-center mr-3'>
            <CustomInput
              type='switch'
              id='clusteringSwitch'
              label='Clustering'
              checked={!clusteringDisabled}
              onChange={(e: any) => setClusteringDisabled(!e.target.checked)}
              className='mr-3'
              disabled={!markersVisible}
            />
            <CustomInput
              type='switch'
              id='markersSwitch'
              label='Show Assets'
              checked={markersVisible}
              onChange={(e: any) => setMarkersVisible(e.target.checked)}
            />
          </div>

          <LayersDropdown
            locale={locale}
            selected={selectedLayerOption}
            onSelect={setSelectedLayerOption}
          />
        </>
      }
    >
      {isLoading && <RivataLoader />}

      <ScrollBlockWrapper>
        <RivataMapCluster
          onMapViewChange={onMapViewChange}
          mapZoomBounds={mapZoomBounds}
          locations={locations}
          layerType={selectedLayerOption}
          locale={locale}
          preferences={preferences}
          geofences={geofences}
          geofencesVisible={true}
          setBounds={true}
          geofenceLocationBtn={true}
          displayGeofenceBubble={true}
          customGeofenceTooltipContent={getCustomTooltipContent}
          clusteringDisabled={clusteringDisabled}
        />
      </ScrollBlockWrapper>
    </RivataModule>
  )
}

export default GeofencesActivityMap
