import React, { useCallback, useEffect, useMemo, useState } from "react"
import {
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Collapse,
} from "reactstrap"
import JSONInput from "react-json-editor-ajrm"
//@ts-ignore
import JSONlocale from "react-json-editor-ajrm/locale/en"

import RivataModule from "../../components/RivataModule"
import {
  IAssetSensors,
  IEventDataWarnings,
  writeToRivataDbEventData,
} from "./types"
import { convertDataEpochToDate, getCurrentEpoch } from "../../utils"
import { useTypedSelector } from "../../hooks/useTypedSelector"
import api from "../../services/api"
import AddSensorDataModal from "./AddSensorDataModal"
import { warningsToSensors } from "./utils"
import RivataGrid, { IRow } from "../../components/RivataGrid"
import InfoModal from "../../components/InfoModal"
import RivataLoader from "../../components/RivataLoader"
import { NoDataLabels } from "../../enums"
import StatusAlert from "../../components/StatusAlert"

type Props = {
  locale: any,
  vin: string
}

const initGps = {
  latitude: 0,
  longitude: 0,
  altitude: 0,
  speed: 0,
  direction: 0,
  hdop: 1,
}

const TestHardwareDataGeneration: React.FC<Props> = ({ locale }) => {
  const { assetId, assetSensors } = useTypedSelector((state) => ({
    assetId: state.assetAdminInfo.assetInfo.id as number,
    assetSensors: state.assetAdminInfo.sensorInfo.data as IAssetSensors,
  }))

  const [isLoading, setIsLoading] = useState(true)

  const [gatewayMac, setGatewayMac] = useState("")
  const [epoch, setEpoch] = useState(getCurrentEpoch())
  const [ignition, setIgnition] = useState(true)
  const [gps, setGps] = useState(initGps)
  const [warnings, setWarnings] = useState<IEventDataWarnings>({})

  const [json, setJson] = useState<writeToRivataDbEventData | any>([])
  const [previewJSON, setPreviewJSON] = useState(false)
  const [canEditJSON, setCanEditJSON] = useState(false)

  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false)
  const [info, setInfo] = useState("")

  useEffect(() => {
    if (canEditJSON) return

    setEpoch(getCurrentEpoch())
    const interval = setInterval(() => { setEpoch(getCurrentEpoch()) }, 1000)

    return () => { clearInterval(interval) }
  }, [canEditJSON])

  useEffect(() => {
    if (!assetSensors.gateways) return
    setGatewayMac(assetSensors.gateways[0].esn)
  }, [assetSensors])

  useEffect(() => {
    if (!assetId) return

    api.getAssetGpsDetails(assetId).then((res) => {
      setGps({
        latitude: res.gps_details.latitude ?? 0,
        longitude: res.gps_details.longitude ?? 0,
        altitude: res.gps_details.elevation ?? 0,
        speed: res.gps_details.speed ?? 0,
        direction: res.gps_details.heading ?? 0,
        hdop: res.gps_details.hdop ?? 1,
      })
      setIsLoading(false)
    })
  }, [assetId])

  useEffect(() => {
    setJson([
      {
        gateway_mac: gatewayMac,
        epoch: epoch,
        metadata: {
          inputs: {
            ignition: ignition ? 1 : 0,
          },
        },
        gps: gps,
        sensors: warningsToSensors(warnings, assetSensors, epoch),
      },
    ])
  }, [gatewayMac, epoch, ignition, gps, warnings, assetSensors])

  const warningsRows = useMemo(() => {
    const res: Array<IRow> = []

    if (warnings.smarthubTemp)
      Object.entries(warnings.smarthubTemp).forEach(([mac, value]) => {
        res.push({
          mac,
          position:
            assetSensors.smarthub_sensors.find((item) => item.mac === mac)
              ?.position ?? NoDataLabels.DASH,
          type: "smarthubTemp",
          value,
        })
      })

    if (warnings.smarthubVib)
      Object.entries(warnings.smarthubVib).forEach(([mac, value]) => {
        res.push({
          mac,
          position:
            assetSensors.smarthub_sensors.find((item) => item.mac === mac)
              ?.position ?? NoDataLabels.DASH,
          type: "smarthubVib",
          value,
        })
      })

    if (warnings.tpms)
      Object.entries(warnings.tpms).forEach(([mac, value]) => {
        res.push({
          mac,
          position:
            assetSensors.tpms.find((item) => item.mac === mac)?.position ??
            NoDataLabels.DASH,
          type: "tpms",
          value,
        })
      })

    if (warnings.linePressure !== undefined)
      res.push({
        mac: assetSensors.line_pressure.mac,
        position: assetSensors.line_pressure.position,
        type: "linePressure",
        value: warnings.linePressure,
      })

    if (warnings.axleLoad !== undefined)
      res.push({
        mac: assetSensors.axle_load.mac,
        position: assetSensors.axle_load.position,
        type: "axleLoad",
        value: warnings.axleLoad,
      })

    return res
  }, [warnings, assetSensors])

  const onAddWarning = useCallback(
    (warningType: string, mac: string, value: number | string) => {
      setWarnings((warnings) => {
        switch (warningType) {
          case "smarthubTemp":
            if (!warnings.smarthubTemp) warnings.smarthubTemp = {}
            warnings.smarthubTemp[mac] = +value
            break

          case "smarthubVib":
            if (!warnings.smarthubVib) warnings.smarthubVib = {}
            warnings.smarthubVib[mac] = `${value}`
            break

          case "tpms":
            if (!warnings.tpms) warnings.tpms = {}
            warnings.tpms[mac] = +value
            break

          case "linePressure":
            warnings.linePressure = +value
            break

          case "axleLoad":
            warnings.axleLoad = +value
            break
        }

        return JSON.parse(JSON.stringify(warnings))
      })
    },
    []
  )

  const onSubmit = useCallback(() => {
    setIsSubmitModalOpen(false)
    setIsLoading(true)

    api
      .testingWriteToRivataDb(json)
      .then(() => {
        setInfo(
          "Data is submitted. It might take time to process."
        )
      })
      .catch((error) => setInfo(error.message))
      .finally(() => setIsLoading(false))
  }, [json])

  return (
    <>
      <RivataModule
        title="Test Hardware Data Generation"
        locale={locale}
        marginTop={0}
        filters={undefined}
      >
        {isLoading && <RivataLoader />}

        <div>
          <div>
            <label className="pr-2 mb-0 pt-2">Date-Time: {convertDataEpochToDate(epoch).format("DD/MM/YY hh:mm:ss A")}</label>
          </div>

          <div className="pt-2">
            <label className="pr-2 mb-0 pt-2">Ignition:</label>
            <input
              type="checkbox"
              disabled={canEditJSON}
              checked={ignition}
              onChange={(e: any) => setIgnition(e.target.checked)}
            />
          </div>

          <div className="pt-2">
            <label className="pr-2 mb-0 pt-2">GPS:</label>
            <div className="pl-2">
              <div>
                <label className="pr-2 mb-0 pt-2" style={{ width: "80px" }}>
                  latitude:
                </label>
                <Input
                  type="number"
                  style={{ width: "120px", display: "inline" }}
                  value={gps.latitude}
                  onChange={(e: any) => {
                    setGps({ ...gps, latitude: +e.target.value })
                  }}
                  disabled={canEditJSON}
                />
              </div>

              <div>
                <label className="pr-2 mb-0 pt-2" style={{ width: "80px" }}>
                  longitude:
                </label>
                <Input
                  type="number"
                  style={{ width: "120px", display: "inline" }}
                  value={gps.longitude}
                  onChange={(e: any) => {
                    setGps({ ...gps, longitude: +e.target.value })
                  }}
                  disabled={canEditJSON}
                />
              </div>

              <div>
                <label className="pr-2 mb-0 pt-2" style={{ width: "80px" }}>
                  altitude:
                </label>
                <Input
                  type="number"
                  style={{ width: "120px", display: "inline" }}
                  value={gps.altitude}
                  onChange={(e: any) => {
                    setGps({ ...gps, altitude: +e.target.value })
                  }}
                  disabled={canEditJSON}
                />
              </div>

              <div>
                <label className="pr-2 mb-0 pt-2" style={{ width: "80px" }}>
                  speed:
                </label>
                <Input
                  type="number"
                  style={{ width: "120px", display: "inline" }}
                  value={gps.speed}
                  onChange={(e: any) => {
                    setGps({ ...gps, speed: +e.target.value })
                  }}
                  disabled={canEditJSON}
                />
              </div>

              <div>
                <label className="pr-2 mb-0 pt-2" style={{ width: "80px" }}>
                  direction:
                </label>
                <Input
                  type="number"
                  style={{ width: "120px", display: "inline" }}
                  value={gps.direction}
                  onChange={(e: any) => {
                    setGps({ ...gps, direction: +e.target.value })
                  }}
                  disabled={canEditJSON}
                />
              </div>
            </div>
          </div>

          <div className="pt-2">
            <div>
              <label className="pr-2 mb-0 pt-2">Sensors:</label>
              <AddSensorDataModal
                assetSensors={assetSensors}
                onAddWarning={onAddWarning}
                disabled={canEditJSON}
              />
            </div>

            {!!warningsRows.length && (
              <div className="pt-2">
                <RivataGrid
                  columns={[
                    { key: "mac", name: "Mac" },
                    { key: "position", name: "Position" },
                    { key: "type", name: "Warning Type" },
                    { key: "value", name: "Value" },
                  ]}
                  rows={warningsRows}
                />
              </div>
            )}
          </div>
        </div>

        <hr />

        <div>
          <label className="pr-2 mb-0">Preview JSON:</label>
          <input
            type="checkbox"
            checked={previewJSON}
            onChange={(e: any) => {
              setPreviewJSON(e.target.checked)
              setCanEditJSON(false)
            }}
          />
        </div>

        <Collapse isOpen={previewJSON} className="pt-2">
          <div className="pb-2">
            <label className="pr-2 mb-0">Edit JSON:</label>
            <input
              type="checkbox"
              checked={canEditJSON}
              onChange={(e: any) => setCanEditJSON(e.target.checked)}
            />
          </div>

          <JSONInput
            id="json_input"
            locale={JSONlocale}
            placeholder={json}
            width="100%"
            viewOnly={!canEditJSON}
            waitAfterKeyPress={100}
            style={{ body: { padding: "1rem" } }}
            onChange={(data: any) => {
              setJson(data.jsObject)
            }}
          />
        </Collapse>

        <hr />

        <div className="d-flex justify-content-end mt-3">
          <Button
            color="success"
            onClick={() => setIsSubmitModalOpen(true)}
            disabled={isLoading}
          >
            Submit
          </Button>
        </div>
      </RivataModule>

      <Modal
        isOpen={isSubmitModalOpen}
        toggle={() => setIsSubmitModalOpen((isOpen) => !isOpen)}
      >
        <ModalHeader toggle={() => setIsSubmitModalOpen((isOpen) => !isOpen)}>
          Modal title
        </ModalHeader>

        <ModalBody>Submit data to backend?</ModalBody>

        <ModalFooter>
          <Button color="success" onClick={onSubmit}>
            Submit
          </Button>

          <Button onClick={() => setIsSubmitModalOpen(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>

      <InfoModal
        open={!!info}
        header="Info"
        message={info}
        onConfirm={() => setInfo("")}
      />
    </>
  )
}
const TestHardwareDataGenerationWrapper: React.FC<Props> = ({ locale, vin }) => {
  const { assetSensors } = useTypedSelector((state) => ({
    assetSensors: state.assetAdminInfo.sensorInfo.data as IAssetSensors,
  }))
  return (assetSensors ? <TestHardwareDataGeneration locale={locale} vin={vin} /> : <>
    <RivataModule
      title="Test Hardware Data Generation"
      locale={locale}
      marginTop={0}
      filters={undefined}
    >
      <StatusAlert customText="Asset does not have any sensors assigned" color="success" statusCode={undefined} statusText={undefined} />
    </RivataModule></>)
}
export default TestHardwareDataGenerationWrapper
