import React from 'react'
import { Col } from "reactstrap";
import SubNav from '../../components/SubNav'
import CustomerSelector from '../../modules/CustomerSelector'
import AssetGroupsModule from '../../modules/AssetGroupsModule'

interface Props {}

const breadcrumbs = [
    { label: "Home", path: "/dashboard" },
    { label: "Asset Groups" }
]

const AssetGroups: React.FC<Props> = () => {
    return (
        <>
            <SubNav
                title="Asset Groups"
                breadcrumbs={breadcrumbs}
            >
                <CustomerSelector/>
            </SubNav>

            <Col md="12">
                <AssetGroupsModule/>
            </Col>
        </>
    )
}

export default AssetGroups
