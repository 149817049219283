import { MenuItemType } from '../Menu/MenuItem'

export const sensorStatusItems: Array<MenuItemType> = [
  {
    id: 'tire',
    label: 'Tire',
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: null,
    subMenuExpanded: true,
  },
  {
    id: 'critical_over_pressure',
    label: 'Critical Over Pressure',
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: 'tire',
  },
  {
    id: 'over_pressure',
    label: 'Over Pressure',
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: 'tire',
  },
  {
    id: 'critical_low_pressure',
    label: 'Critical Low Pressure',
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: 'tire',
  },
  {
    id: 'low_pressure',
    label: 'Low Pressure',
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: 'tire',
  },
  {
    id: 'abnormal_high_tire_vibration',
    label: 'High Severity Tire Vibration',
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: 'tire',
  },
  {
    id: 'abnormal_tire_vibration',
    label: 'Tire Vibration',
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: 'tire',
  },
  {
    id: 'fast_leak',
    label: 'Pressure Fast Leak',
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: 'tire',
  },
  {
    id: 'hub',
    label: 'Hub',
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: null,
    subMenuExpanded: true,
  },
  {
    id: 'critical_high_temperature',
    label: 'Critical High Temperature',
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: 'hub',
  },
  {
    id: 'high_temperature',
    label: 'High Temperature',
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: 'hub',
  },
  {
    id: 'abnormal_high_bearing_vibration',
    label: 'High Severity Bearing Vibration',
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: 'hub',
  },
  {
    id: 'abnormal_bearing_vibration',
    label: 'Bearing Vibration',
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: 'hub',
  },
  {
    id: 'line_pressure',
    label: 'Line Pressure',
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: null,
    subMenuExpanded: true,
  },
  {
    id: 'low_line_pressure',
    label: 'Low Line Pressure',
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: 'line_pressure',
  },
  {
    id: 'no_status',
    label: 'No status',
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: null,
    subMenuExpanded: true,
  },
  {
    id: 'no_status_tpms',
    label: 'No Status TPMS',
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: 'no_status',
  },
  {
    id: 'no_status_smarthub',
    label: 'No Status Smarthub',
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: 'no_status',
  },
  {
    id: 'no_status_line_pressure',
    label: 'No Status Line Pressure',
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: 'no_status',
  },
  {
    id: 'no_status_axle_load',
    label: 'No Status Axle Load',
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: 'no_status',
  },
  {
    id: 'asset_state',
    label: 'Asset State',
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: null,
    subMenuExpanded: true
  },
  {
    id: 'movement_without_abs',
    label: 'Movement Without ABS',
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: 'asset_state',
  },
  {
    id: 'asset_speed',
    label: 'Asset Speed',
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: null,
    subMenuExpanded: true
  },
  {
    id: 'over_speed',
    label: 'Over Speed',
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: 'asset_speed',
  },
  {
    id: 'critical_over_speed',
    label: 'Critical Over Speed',
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: 'asset_speed',
  },
  {
    id: 'ok',
    label: 'Normal',
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: null,
  },
]
