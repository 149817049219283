import React from 'react'
import Label, { LabelItemType } from './Label'

interface Props {
    items: Array<LabelItemType>
    onRemoveLabel: (el: LabelItemType) => void
    onClearSelection: () => void
}

const Selection: React.FC<Props> = ({
    items,
    onRemoveLabel,
    onClearSelection
}) => {
    return (
        <>
            <div className='selected__labels'>
                {items.map(el => {
                    return (
                        <Label
                            key={el.id}
                            className='label__btn'
                            text={el.label}
                            onDelete={() => onRemoveLabel(el)}
                        />
                    )
                })}
            </div>

            {items.length > 0 && (
                <div className='clear__btn'>
                    <span
                        
                        onClick={() => onClearSelection()}
                    >
                        Clear all
                    </span>
                </div>
            )}
        </>
    )
}

export default Selection