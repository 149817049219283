import React from 'react';
import RivataLineChart from "../RivataLineChart";

const LineChartGroup = ({ chartsData, chartsXAxisLabel }) => {
  if (!chartsData) return null
  return chartsData.map((c) => {
    return (
      <div key={c.id}>
        <RivataLineChart
          height={c.height}
          id={c.id}
          data={c.data}
          legendPostion={c.legendPostion}
          yAxisLabel={c.yAxisLabel}
          annotations={c.annotations}
          xAxisLabel={chartsXAxisLabel}
          ignoreMotionAnnotations={true}
        />
      </div>
    )
  })
}

export default LineChartGroup;