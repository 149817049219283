import React, {
  CSSProperties,
  MutableRefObject,
  forwardRef,
  useCallback,
  useState,
} from "react";
import LabelSelector from "../LabelSelector";
import { geofenceSearchItems } from "../../modules/AssetTable/filterConfigs";
import { cloneDeep, debounce } from "lodash";
import geolocationApi from "../../services/api/ApiGroups/GeolocationApi";
import { ListItemType } from "../LabelSelector/List";

interface Props {
  wrapperStyle?: CSSProperties;
  clearSelection: number;
  updateFilters: (key: string, value: Array<string> | string) => void;
  searchParams: MutableRefObject<Record<string, string[]>>;
}

const debouncer = debounce((func: () => void) => func(), 400);

const GeofenceFilter = forwardRef(
  ({ wrapperStyle, clearSelection, updateFilters, searchParams }: Props) => {
    const [searchItems, setGeofenceSearchItems] = useState(
      cloneDeep(geofenceSearchItems)
    );
    const [isSuggestionsLoading, setSuggestionsLoading] = useState(false);

    const onSearchGeofence = useCallback((value: string) => {
      if (!value.trim()) {
        setSuggestionsLoading(false);
        setGeofenceSearchItems(cloneDeep(geofenceSearchItems));
      } else {
        setSuggestionsLoading(true);
        setGeofenceSearchItems([]);
      }

      debouncer(async () => {
        if (!value.trim()) return setSuggestionsLoading(false);

        try {
          const res: Array<IShortGeofence> =
            await geolocationApi.searchGeolocations(value, 2);
          setGeofenceSearchItems(
            res.map((el) => ({ id: el.id, label: el.name }))
          );
        } catch (err) {
          console.log(err);
        }

        setSuggestionsLoading(false);
      });
    }, []);

    const handleLabelSelectionChange = useCallback(
      (items: Array<ListItemType>) => {
        console.log(items);
        const ids = geofenceSearchItems.map((el) => el.id);

        if (items.length === 1 && ids.includes(items[0].id)) {
          delete searchParams.current.geofence_id;
          updateFilters("asset_geofence", items[0].id.toString());
          return;
        }

        delete searchParams.current.asset_geofence;
        updateFilters(
          "geofence_id",
          items.map((el) => el.id.toString())
        );
        setGeofenceSearchItems(cloneDeep(geofenceSearchItems));
      },
      [updateFilters, searchParams]
    );

    return (
      <LabelSelector
        isSingleSelection={searchItems.every((el) => el.isSingleSelection)}
        isLoading={isSuggestionsLoading}
        placeholder="Search by Geofence"
        menuItems={searchItems}
        wrapperStyle={wrapperStyle}
        clearSelection={clearSelection}
        onLabelSelectionChange={handleLabelSelectionChange}
        onSearch={onSearchGeofence}
      />
    );
  }
);

export default React.memo(GeofenceFilter);
