import React, { useState } from "react";
import { Tooltip } from "reactstrap";
import moment from 'moment'
import classes from "./style.module.scss";
import { convertDataEpochToDate, psiToBar } from "../../../utils";
import IconWrapper from "../common/iconWrapper";
import { HealthStatus, NoDataLabels } from "../../../enums";

const AxlesLoad = ({ axlesLoads, pressureUnit, timezone }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const { axel_value, latest_axel_measurement, is_latest_axel_data, no_status } = axlesLoads[0];
  const axle_pressure = axel_value !== null && pressureUnit.toLowerCase() === "bar" ? psiToBar(axel_value).toFixed() : axel_value
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const date = is_latest_axel_data && convertDataEpochToDate(latest_axel_measurement, null, null, true)
  const tz = moment().tz(timezone).format("z")
  const dateTz = `${date} ${tz}`

  const no_status_styling = no_status || axle_pressure === null

  return (
    <div id='axleLoad'>
      <div style={{ paddingRight: "10px" }}>
        <IconWrapper variant={no_status_styling ? HealthStatus.noStatus : HealthStatus.good} name='axleLoad' />
      </div>

      <div className={classes.axleLoad}> {!no_status_styling ? axle_pressure : NoDataLabels.DASH} </div>

      {axlesLoads[0] && <Tooltip
        placement="top"
        toggle={toggle}
        innerClassName={`${classes.hubTooltip} ${no_status_styling && classes.noStatus}`}
        isOpen={tooltipOpen}
        target={"axleLoad"}
        autohide={false}
        hideArrow={true}
      >
        Sensor Type: Axle Load<br />

        {!no_status && <>
          Total Load: {!no_status_styling ? axle_pressure : "No Data"}<br />
          Units:  {pressureUnit}<br />
          {`Latest Measurement: ${latest_axel_measurement ? dateTz : NoDataLabels.DASH}`}
        </>}

        {no_status && <> No Status </>}
      </Tooltip>}
    </div>
  );
};

export default AxlesLoad;