import React, { useCallback, useMemo } from 'react'
import { composeDynamicActionsFormatter } from '../RivataGrid/cellFormatter'
import { SearchItemType } from '../ItemsSearch'
import RivataGrid from '../RivataGrid'
import BackendSearchInput from '../BackendSearchInput'
import { IBackendSearchEndPointConfig } from '../BackendSearchInput/constants'

type Props = {
  endPointConfig: IBackendSearchEndPointConfig,
  label?: string,
  inputPlaceholder?: string,
  onItemsSelect: (selectedItems: SearchItemType[]) => void,
  columnsToShow: { key: string, name: string }[],
  disabled?: boolean,
  tableWidth?: string,
  selectedItems: Array<SearchItemType>,
  customerIds?: string
  allowHorizontalScroll?: boolean
}

const ItemsSelectWithSearch: React.FC<Props> = ({
  endPointConfig,
  label,
  inputPlaceholder = "Search",
  onItemsSelect,
  columnsToShow,
  disabled,
  tableWidth = "400px",
  selectedItems,
  customerIds,
  allowHorizontalScroll = false,
}) => {

  const onItemSelect = useCallback((item: SearchItemType) => {
    if (!selectedItems.find(oldItem => oldItem.id === item.id))
      onItemsSelect([...selectedItems, item])
  }, [onItemsSelect, selectedItems])

  const onItemDelete = useCallback((item) => {
    onItemsSelect(selectedItems.filter((oldItem) => oldItem.id !== item))
  }, [onItemsSelect, selectedItems])

  const tableCollumns = useMemo(() => [
    {
      key: "remove",
      name: "Remove",
      minWidth: 75,
      width: 75,
      formatter: ({ row }: any) => composeDynamicActionsFormatter({ onDelete: onItemDelete, cellData: row })
    },
    ...columnsToShow,
  ], [onItemDelete, columnsToShow])

  const tableHeight = useMemo(() => selectedItems.length > 5 ? "210x" : undefined, [selectedItems])

  return (
    <div>
      {label && <label style={{ paddingTop: "20px" }}>{label}</label>}

      <div className="d-flex flex-row mb-2">
        <BackendSearchInput
          endPointConfig={endPointConfig}
          placeholder={inputPlaceholder}
          onSelect={onItemSelect}
          disabled={disabled}
          customerIds={customerIds}
        />
      </div>

      {selectedItems.length > 0 &&
        <div className='d-flex flex-row mb-2'>
          <div style={{ width: tableWidth }}>
            <RivataGrid
              columns={tableCollumns}
              rows={selectedItems}
              height={tableHeight}
              allowHorizontalScroll={allowHorizontalScroll}
            />
          </div>
        </div>
      }
    </div>
  )
}

export default React.memo(ItemsSelectWithSearch)