import React, { useCallback, useState } from 'react'
import { AssetSubtypeTractor, AssetTypes, ManufacturerTrailerTypeMapping, NthsaTrailerSubtypeMap } from '../../../enums'
import assetsApi from '../../../services/api/ApiGroups/AssetsApi'
import nhtsaApi from '../../../services/api/ApiGroups/NHTSAApi'
import AssetModal from './AssetModal'
import ValidateVinModal from './ValidateVinModal'

export interface ICreateAssetValues extends Pick<IAsset, "name" | "vin" | "make" | "model" | "number_of_axles" | "asset_subtype"> {
    asset_type: string
    year: string
    ati_installed: string
}

interface Props {
    isCreateStarted: boolean
    locale: ILocale
    setIsCreateStarted: React.Dispatch<React.SetStateAction<boolean>>
    onCreateAsset: (values: any) => Promise<IStatus>
}

const getTrailerSubtypeFromVin = (vin: string) => {
    const manufacturerIdentifier = vin.substring(0, 3);

    if (!ManufacturerTrailerTypeMapping.knownManufacturers.includes(manufacturerIdentifier)) {
        return null;
    }

    const manufacturerData = ManufacturerTrailerTypeMapping.manufacturersData[manufacturerIdentifier as keyof typeof ManufacturerTrailerTypeMapping.manufacturersData];

    const trailerSubtypeSymbol = vin[manufacturerData.indexOfTrailerType]

    if (!manufacturerData.validTypes.includes(trailerSubtypeSymbol)) {
        return null;
    }

    const trailerSubtype = manufacturerData.mapping[trailerSubtypeSymbol as keyof typeof manufacturerData.mapping];
    return trailerSubtype
}

const AssetCreateChain: React.FC<Props> = ({
    isCreateStarted,
    locale,
    setIsCreateStarted,
    onCreateAsset
}) => {
    const [assetValues, setAssetValues] = useState<ICreateAssetValues | null>(null)
    const [isValidateVinOpen, setIsValidateVinOpen] = useState(isCreateStarted)
    const [invalid, setInvalid] = useState(false)
    const [isAlreadyExist, setIsAlreadyExist] = useState(false)
    const [isNthsaFilled, setIsNthsaFilled] = useState(false)

    const handleValidate = useCallback(async (data: Pick<IAsset, "vin">) => {
        const { vin } = data

        // try to get subtype from vin using existing list. if exists then asset is trailer.
        const trailerSubtype = getTrailerSubtypeFromVin(vin);

        const values: ICreateAssetValues = {
            vin,
            name: "",
            asset_type: trailerSubtype ? "trailer" : "",
            make: "",
            model: "",
            year: "",
            ati_installed: "",
            asset_subtype: trailerSubtype,
            number_of_axles: ""
        }

        if (invalid && !isAlreadyExist) {
            // continue without valid vin
            setIsValidateVinOpen(false)

            return
        }

        try {
            const assetExist = await assetsApi.checkIfAssetExistByVin(vin)

            if (assetExist) {
                setIsAlreadyExist(true)
                setInvalid(true)

                return
            }
        } catch (err) {
            console.log(err)
        }

        try {
            // get data about vehicle from api and fill some fields
            const res = await nhtsaApi.validateVin(vin)
            const result = res.Results[0]

            if (!result) return

            let assetType = result.VehicleType.toLowerCase().trim()

            if (assetType === "truck") assetType = AssetTypes.TRACTOR
            if (![AssetTypes.TRACTOR, AssetTypes.TRAILER].includes(assetType)) assetType = ""

            values.asset_type = assetType
            values.make = result.Make
            values.model = result.Model
            values.year = result.ModelYear ? result.ModelYear : ""

            if (result.Axles) {
                values.number_of_axles = parseInt(result.Axles)
            }

            if (!values.asset_subtype) {
                if (assetType === AssetTypes.TRAILER) {
                    const trailerBodyType = result.TrailerBodyType;
                    if (trailerBodyType) {
                        // try go get subtype from nthsa
                        const found_asset_subtype = NthsaTrailerSubtypeMap[trailerBodyType as keyof typeof NthsaTrailerSubtypeMap]
                        if (found_asset_subtype) {
                            values.asset_subtype = found_asset_subtype
                        }
                    }
                }
                else if (assetType === AssetTypes.TRACTOR && result.Trim) {
                    const asset_subtype_trim: string = result.Trim.toLowerCase().replaceAll(" ", "_")
                    const found_asset_subtype = Object.values(AssetSubtypeTractor).find((element) => asset_subtype_trim.includes(element));
                    if (found_asset_subtype) {
                        values.asset_subtype = found_asset_subtype || "";
                    }
                }
            }

            if (result.ErrorCode === "0") {
                setInvalid(false);
                setIsNthsaFilled(true);
                setIsValidateVinOpen(false)
            }
            else {
                setInvalid(true);
            }

            setAssetValues(values)

        } catch (err) {
            console.log(err)
            setInvalid(true)
        }
    }, [invalid, isAlreadyExist])

    const handleClose = useCallback(() => {
        setIsCreateStarted(false)
    }, [setIsCreateStarted])

    const clearErrors = useCallback(() => {
        if (invalid) setInvalid(false)
        if (isAlreadyExist) setIsAlreadyExist(false)
    }, [invalid, isAlreadyExist])

    return (
        <>
            {isValidateVinOpen && (
                <ValidateVinModal
                    isOpen={true}
                    isInvalid={invalid}
                    isAlreadyExist={isAlreadyExist}
                    locale={locale}
                    onInputFocus={clearErrors}
                    onValidate={handleValidate}
                    onClose={handleClose}
                />
            )}

            {(assetValues && !isValidateVinOpen) && (
                <AssetModal
                    open={true}
                    disabled={false}
                    isVinDisabled={true}
                    isNHTSAFilled={isNthsaFilled}
                    title="Create"
                    locale={locale}
                    initialValues={assetValues}
                    onClose={handleClose}
                    onCreate={onCreateAsset}
                />
            )}
        </>
    )
}

export default React.memo(AssetCreateChain)
