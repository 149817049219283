import React, { useMemo } from "react";
import LinePressureLayer from "./linePressureLayer";
import AxlesLoad from '../common/axlesLoads'
import {
  getPositions,
  getAxles,
  getIntervals,
  indexAjustment,
  getHubOrders,
  getCorrectedAxles
} from "../utils";

import Axle from "../axle/Axle";

import PositionBubble from "../common/PositionBubble";
import ActiveHubs from "../common/activeHubWarnings";

const Trailer = ({
  height,
  sensors = [],
  smart,
  pressureUnit,
  getTireData,
  timezone,
  line,
  locale,
  thresholds,
  axlesLoads
}) => {
  const axleHeight = 250;
  const axleInterval = 80;
  const axleLeftMargin = 20;
  const trailerPositions = useMemo(
    () => getPositions(["TRAILER_LEFT", "TRAILER_RIGHT"]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sensors]
  );
  const correctedAxles = getCorrectedAxles(sensors, smart, line, axlesLoads, 'trailer', trailerPositions)

  const{smarthub: hubThresholds, line_pressure}=thresholds

  const axles = useMemo(
    () => getAxles(correctedAxles, trailerPositions, "trailer"),
    [correctedAxles, trailerPositions]
  );

 

  const intervals = useMemo(
    () => getIntervals(axles, axleInterval, axleLeftMargin, "trailer"),
    [axles]
  );
  const hubs = getHubOrders(trailerPositions, smart, hubThresholds);

  const trailerWidth = useMemo(() => { 
    let width = intervals.length * 100;
    if (intervals.length === 2 ) width = 260;
    if (intervals.length === 3 ) {
      width = (intervals.length * 100 + 30) 
    }
    if (intervals.length === 1) {
      width = (intervals.length * 100 + 70)
    }
    return width;
  }, [intervals.length]);

  return (
    <div
      className="d-flex align-items-center"
      style={{ position: "relative", height: `${height}px` }}
    >
      <div
        className="trailer-rear"
        style={{ width: `${trailerWidth}px`, height: "230px" }}
      >
        {axlesLoads.length ? <AxlesLoad axlesLoads={axlesLoads} pressureUnit={pressureUnit} timezone={timezone}/> : ""}
        {line?.length ? <LinePressureLayer line={line} threshold={line_pressure} timezone={timezone}/> : ""}
      </div>

      {axles.map((axle, index) => {
        let i = indexAjustment(index, axle.order - 1, axles, intervals);
        return (
          <Axle
            key={axle.order+index}
            axle={axle}
            axleType="doubleTrailerAxle"
            style={{ position: "absolute", left: `${intervals[i]}px` }}
            height={axleHeight}
            pressureUnit={pressureUnit}
            getTireData={getTireData}
            timezone={timezone}
            locale={locale}
          />
        );
      })}

      {axles.map((axle, index) => {
        let i = indexAjustment(index, axle.order - 1, axles, intervals);
        return (
          <ActiveHubs
            key={axle.order+index}
            axle={axle}
            intervals={intervals}
            hubs={hubs}
            index={i}
            type="truck"
          />
        );
      })}

      {axles.map((axle, index) => {
        let i = indexAjustment(index, axle.order - 1, axles, intervals);
        return (
          <PositionBubble
            key={axle.positionBubble+index}
            label={axle.positionBubble}
            style={{ left: `${intervals[i] + 5.5}px` }}
          />
        );
      })}
    </div>
  );
};

export default React.memo(Trailer);
