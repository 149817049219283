import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { CustomInput } from "reactstrap";
import { debounce, cloneDeep } from "lodash";
import Button from "../../componentsV2/Button";
import SearchInput from "../../componentsV2/SearchInput";
import DropdownItemSelector from "../../componentsV2/DropdownItemSelector";
import { SearchIcon } from "../../componentsV2/Icon";
import { MenuItemType } from "../../componentsV2/Menu/MenuItem";
import { getGpsDropdownItems } from "../AssetTable/utils";
import { sensorStatusItems } from "./TpmsStatusFilterConfigs";
import RivataModule from "../../components/RivataModule";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import AssetGroupsFilter from "../../componentsV2/AssetGroupsFilter";
import ColumnsSelectorDropdown from "../../components/RivataTable/ColumnsSelectorDropdown";
import { columnsSelectorCheckboxProps } from "../../components/RivataTable/useColumnsSelector";
import SensorTypeFilter from "../../componentsV2/SensorTypeFilter";
import { sensorTypeItems } from "../../componentsV2/SensorTypeFilter/items";
import HealthStatusFilter from "../../componentsV2/HealthStatusFilter";
import AssetNameVinFilter from "../../componentsV2/AssetNameVinFilter";
import GeofenceFilter from "../../componentsV2/GeofenceFilter";

interface Props {
  columnsCheckboxesProps: columnsSelectorCheckboxProps[];
  onSetDefaultColumns: () => void;
}

const debouncer = debounce((func: () => void) => func(), 400);

const wrapperStyle = { width: "470px" };
const initialSelection = {
  warnings_subtype: [],
  last_tpms_update: [],
  asset_group: [],
  sensor_type: [],
};

const defaultTpmsTabFilter = "filters=sensor_type=tpms";

const TpmsStatusFilterMenu: React.FC<Props> = ({
  columnsCheckboxesProps,
  onSetDefaultColumns,
}) => {
  const {
    isSuperAdmin,
    timezone,
    locale,
    hideAutogeneratedAssets,
    selectedCustomersList,
  } = useTypedSelector((state) => ({
    isSuperAdmin: state.auth.isSuperAdmin,
    timezone: state.auth.preferences.timezone,
    locale: state.whitelabel.locale,
    hideAutogeneratedAssets: state.common.hideAutogeneratedAssets,
    selectedCustomersList: state.common.customers.selectedCustomersList,
  }));

  const {
    setTpmsStatusTractorAssetsFilter,
    setTpmsStatusTrailerAssetsFilter,
    setTpmsStatusTractorAssetsOffset,
    setTpmsStatusTrailerAssetsOffset,
    setHideAutogeneratedAssets,
    fetchLocations,
    setFilter,
    fetchAssetsCardsData,
  } = useActions();

  useEffect(() => {
    setFilter(defaultTpmsTabFilter);
    fetchLocations();
    fetchAssetsCardsData();
    return () => {
      setFilter("");
    };
  }, [setFilter, fetchLocations, fetchAssetsCardsData]);

  const modifiedSensorTypeItems = useMemo(() => {
    const items = cloneDeep(sensorTypeItems)
    for (let i = 0; i < items.length; i++) {
      items[i].disabled = true;
      if (items[i].id === 'tpms') {
        items[i].checked = true;
      }
    }
    return items;
  }, [])

  const modifiedHealthStatusItems = useMemo(() => {
    return sensorStatusItems;
  }, []);

  const searchParams = useRef<Record<string, Array<any>>>(
    cloneDeep(initialSelection)
  );
  const actualQuery = useRef("");
  const groupsFilterRef = useRef<any>(null);
  const healthStatusFilterRef = useRef<any>(null);
  const assetNameVinFilterRef = useRef<any>(null);
  const [profileSearchValue, setProfileSearchValue] = useState("");
  const [clearLabelSelector, setClearLabelSelector] = useState(0);
  const [dropdownItems, setDropdownItems] = useState({
    sensorStatusItems: cloneDeep(sensorStatusItems),
    gpsDropdownItems: getGpsDropdownItems(timezone),
  });

  useEffect(() => {
    return () => {
      setTpmsStatusTractorAssetsFilter("");
      setTpmsStatusTrailerAssetsFilter("");
    };
  }, [setTpmsStatusTractorAssetsFilter, setTpmsStatusTrailerAssetsFilter]);

  useEffect(() => {
    setDropdownItems((prev) => ({
      ...prev,
      gpsDropdownItems: getGpsDropdownItems(timezone),
    }));
  }, [timezone]);

  const onFilter = useCallback(
    (query: string) => {
      setTpmsStatusTractorAssetsFilter(query);
      setTpmsStatusTrailerAssetsFilter(query);
      setTpmsStatusTractorAssetsOffset(0);
      setTpmsStatusTrailerAssetsOffset(0);
      fetchLocations();
      fetchAssetsCardsData();
    },
    [
      setTpmsStatusTractorAssetsFilter,
      setTpmsStatusTractorAssetsOffset,
      setTpmsStatusTrailerAssetsFilter,
      setTpmsStatusTrailerAssetsOffset,
      fetchLocations,
      fetchAssetsCardsData,
    ]
  );

  const updateFilters = useCallback(
    (key: string, value: Array<string> | string) => {
      if (!value || !value.length) {
        delete searchParams.current[key];
      } else if (Array.isArray(value)) {
        searchParams.current[key] = value;
      } else {
        searchParams.current[key] = [value];
      }

      const query = new URLSearchParams();

      Object.entries(searchParams.current).forEach((filter) => {
        const key = filter[0];
        filter[1].forEach((value) =>
          query.append("filters", `${key}=${value}`)
        );
      });

      actualQuery.current = query.toString();

      // hack to be able to filter map and assets cards
      query.append("filters", "sensor_type=tpms");
      setFilter(query.toString());

      onFilter(actualQuery.current);
    },
    [onFilter, setFilter]
  );

  const onSearchByStringValue = (id: string, value: string) => {
    debouncer(() => updateFilters(id, value));
  };

  const onSearchByProfile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setProfileSearchValue(value);
    debouncer(() => updateFilters("warning_setting_name", value));
  };

  const handleFiltersReset = useCallback(() => {
    //some elements must be reseted even if filters weren't selected
    setClearLabelSelector((prev) => prev + 1);
    setDropdownItems({
      sensorStatusItems: cloneDeep(sensorStatusItems),
      gpsDropdownItems: getGpsDropdownItems(timezone),
    });

    // make filtering only if filter query params are different from prevoius
    if (actualQuery.current === "") return;

    setProfileSearchValue("");
    groupsFilterRef?.current?.clearSelection();
    healthStatusFilterRef?.current?.clearSelection();
    assetNameVinFilterRef?.current?.clearValue();
    actualQuery.current = "";
    searchParams.current = cloneDeep(initialSelection);
    setFilter(defaultTpmsTabFilter);
    onFilter("");
  }, [onFilter, timezone, setFilter]);

  const onDropdownItemSelect = useCallback(
    (id: string, items: Array<MenuItemType>) => {
      const checked = items.filter((el) => el.checked);

      debouncer(() =>
        updateFilters(
          id,
          checked.map((el) => el.id)
        )
      );
    },
    [updateFilters]
  );

  useEffect(() => {
    if (actualQuery.current === "") {
      onFilter("");
    } else {
      handleFiltersReset();
    }
  }, [selectedCustomersList, handleFiltersReset, onFilter]);

  return (
    <RivataModule
      title="TPMS Status"
      locale={locale}
      marginTop={0}
      filters={
        <>
          {isSuperAdmin && (
            <CustomInput
              id="hideAutogenerated"
              className="d-flex align-items-center mr-3"
              type="checkbox"
              label="Hide Autogenerated Assets"
              checked={hideAutogeneratedAssets}
              onChange={() => {
                setHideAutogeneratedAssets(!hideAutogeneratedAssets);
                setTpmsStatusTractorAssetsOffset(0);
                setTpmsStatusTrailerAssetsOffset(0);
                fetchLocations();
                fetchAssetsCardsData();
              }}
            />
          )}
        </>
      }
    >
      <div className="d-flex justify-content-between flex-wrap">
        <AssetNameVinFilter
          wrapperStyle={wrapperStyle}
          onChange={onSearchByStringValue}
          ref={assetNameVinFilterRef}
        />

        <SearchInput
          placeholder="Search by TPMS Profile"
          icon={<SearchIcon width={20} height={20} color="black" />}
          wrapperStyle={wrapperStyle}
          value={profileSearchValue}
          onChange={onSearchByProfile}
        />

        <GeofenceFilter
          wrapperStyle={wrapperStyle}
          clearSelection={clearLabelSelector}
          updateFilters={updateFilters}
          searchParams={searchParams}
        />
      </div>

      <br />

      <div className="d-flex flex-wrap justify-content-between">
        <div className="d-flex flex-wrap">
          <HealthStatusFilter
            id="warnings_subtype"
            onDropdownItemSelect={onDropdownItemSelect}
            overrideDefaultItems={modifiedHealthStatusItems}
            ref={healthStatusFilterRef}
          />
          <SensorTypeFilter
            onDropdownItemSelect={onDropdownItemSelect}
            overrideDefaultItems={modifiedSensorTypeItems}
            readonly={true}
          />
          <DropdownItemSelector
            id="last_tpms_update"
            filterClassName="mr-2"
            filterBtnLabel="Last TPMS Update"
            items={dropdownItems.gpsDropdownItems}
            onItemsChange={onDropdownItemSelect}
          />
          <AssetGroupsFilter
            onDropdownItemSelect={onDropdownItemSelect}
            ref={groupsFilterRef}
            customerIds={selectedCustomersList.map((c: any) => c.id).join(",")}
          />
        </div>

        <div>
          <Button
            onClick={() => {
              handleFiltersReset();
            }}
          >
            Clear
          </Button>
        </div>
      </div>

      <hr />

      <div className="d-flex justify-content-end">
        <ColumnsSelectorDropdown
          checkboxesProps={columnsCheckboxesProps}
          onSetDefault={onSetDefaultColumns}
          className=""
        />
      </div>
    </RivataModule>
  );
};

export default React.memo(TpmsStatusFilterMenu);
