import React, { CSSProperties, InputHTMLAttributes } from 'react'
import './style.scss'


export interface SearchInputProps extends InputHTMLAttributes<HTMLInputElement> {
    icon?: JSX.Element
    wrapperStyle?: CSSProperties
    wrapperClassName?: string
    endElement?: JSX.Element
}

const SearchInput: React.FC<SearchInputProps> = ({
    icon,
    wrapperStyle = {width: '470px'},
    wrapperClassName = "",
    endElement,
    ...props
}) => {
    return (
        <div 
            className={`custom__input ${wrapperClassName}`} 
            style={wrapperStyle}
        >
            {icon && (
                <div className='icon__wrapper'>
                    {icon}
                </div>
            )}

            <div style={{ width: "100%" }}>
                <input
                    {...props}
                />
            </div>

            {endElement && (
                <div>
                    {endElement}
                </div>
            )}
        </div>
    )
}

export default SearchInput
