import React, { useState, useEffect } from 'react';
import { Field, Formik, Form, useField } from 'formik';
import { Col, Label, FormGroup, Input, FormFeedback, Row, Button } from 'reactstrap';
import * as Yup from "yup";

import './styles.scss';
import { Prompt } from 'react-router-dom';
import { WARNING_TYPE } from '../../constants';
import { UnitsOfMeasurement } from '../../enums'
import { fahrToCelsius, mphToKmh} from '../../utils'

const schema = Yup.object().shape({
    // vibration_treshold: Yup.number().required("Required").positive("Vibration threshold can't be under 0"),
    temperature_treshold: Yup.number().required("Required").positive("Over temperature threshold can't be under 0"),
    line_pressure_treshold: Yup.number().required("Required").positive("Line pressure threshold can't under 0"),
    critical_temperature_treshold: Yup.number().required("Required").positive("Critical over temperature threshold can't be under 0")
        .moreThan(Yup.ref("temperature_treshold"), "Critical over temperature threshold should be greater than over temperature"),
    over_speed_threshold: Yup.number().required("Required").positive("Over speed threshold can't be under 0"),
    critical_over_speed_threshold: Yup.number().required("Required").positive("Critical over speed threshold can't be under 0")
        .moreThan(Yup.ref("over_speed_threshold"), "Critical over speed threshold should be greater than over speed threshold")
});

export const FormField = ({
    className,
    md = "6",
    type = "text",
    children,
    label,
    units,
    ...props
}) => {
    const [field, meta] = useField(props);
    return (
        <Col md={md} className={className}>
            <Label>{label}</Label>
            <FormGroup className="w-100">
                <div className="d-flex align-items-center">
                    <Input
                        type={type}
                        {...field}
                        {...props}
                        tag={Field}
                        invalid={!!(meta.touched && meta.error)}
                    >
                        {children}
                    </Input>
                    <Label className="mb-0 ml-2" >{units}</Label>
                </div>
                <FormFeedback>{meta.error}</FormFeedback>
            </FormGroup>
        </Col>
    );
};

const format = (formDetails, customerId) => {
    const { critical_temperature_treshold, temperature_treshold, line_pressure_treshold, critical_over_speed_threshold, over_speed_threshold } = formDetails;
    return {
        customer_id: customerId,
        name: "",
        settings: [
            {
                warning_type_id: WARNING_TYPE.HUB_TEMPERATURE_SENSOR,
                profile: {
                    temperature_treshold: Number(temperature_treshold),
                    critical_temperature_treshold: Number(critical_temperature_treshold)
                }
            },
            // {
            //     warning_type_id: WARNING_TYPE.HUB_VIBRATION_SENSOR,
            //     profile: {
            //         vibration_treshold: Number(vibration_treshold)
            //     }
            // },
            {
                warning_type_id: WARNING_TYPE.LINE_PRESSURE_SENSOR,
                profile: {
                    line_pressure_treshold: Number(line_pressure_treshold)
                }
            },
            {
                warning_type_id: WARNING_TYPE.OVER_SPEED,
                profile: {
                    over_speed_threshold: Number(over_speed_threshold),
                    critical_over_speed_threshold: Number(critical_over_speed_threshold)
                }
            }
        ]
    }
}

const TresholdsForm = ({
    initialValues,
    disabled,
    onSave,
    unitsOfMeasurement,
    customerId,
}) => {
    const [formChanged, setFormChanged] = useState(false)

    useEffect(() => {
        if (formChanged) {
            window.onbeforeunload = () => true
        } else {
            window.onbeforeunload = null
        }
    }, [formChanged]);

    return (
        <>
            <Prompt when={formChanged} message={"You have unsaved changes. Do you want to leave page?"} />
            <div>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validationSchema={schema}
                    onSubmit={(values) => {
                        const thresholds = values
                        thresholds.critical_temperature_treshold = unitsOfMeasurement === UnitsOfMeasurement.metric
                        ? parseFloat(values.critical_temperature_treshold)
                        : fahrToCelsius(values.critical_temperature_treshold)
                        thresholds.temperature_treshold = unitsOfMeasurement === UnitsOfMeasurement.metric 
                        ? parseFloat(values.temperature_treshold) 
                        : fahrToCelsius(values.temperature_treshold)
                        thresholds.critical_over_speed_threshold = unitsOfMeasurement === UnitsOfMeasurement.metric
                        ? parseFloat(values.critical_over_speed_threshold)
                        : mphToKmh(values.critical_over_speed_threshold)
                        thresholds.over_speed_threshold = unitsOfMeasurement === UnitsOfMeasurement.metric
                        ? parseFloat(values.over_speed_threshold)
                        : mphToKmh(values.over_speed_threshold)
                      
                        onSave(format(thresholds, customerId));
                    }}
                >
                    {(props) => {
                        const { submitForm, handleReset } = props;
                        return <Form className="tresholds-form" onChange={() => { if (!formChanged) setFormChanged(true) }}>
                            <Row className="col-wrapper" md={12}>
                                <Col md={4}>
                                    <h6 className="ml-3 mb-2">Set Thresholds</h6>
                                    {/*<FormField 
                                        md={6} 
                                        disabled={disabled} 
                                        name="vibration_treshold" 
                                        label="Vibration threshold" 
                                        type="number" 
                                        step="1" 
                                    /> */}
                                    <FormField 
                                        md={6} 
                                        disabled={disabled}
                                        name="critical_temperature_treshold" 
                                        label="Critical over temperature" 
                                        type="number" 
                                        step="1" 
                                        units={unitsOfMeasurement === UnitsOfMeasurement.metric ? "°C" : "°F"} 
                                    />
                                    <FormField 
                                        md={6} 
                                        disabled={disabled}
                                        name="temperature_treshold" 
                                        label="Over temperature" 
                                        type="number" 
                                        step="1" 
                                        units={unitsOfMeasurement === UnitsOfMeasurement.metric ? "°C" : "°F"} 
                                    />
                                    <FormField 
                                        md={6} 
                                        disabled={disabled}
                                        name="line_pressure_treshold" 
                                        label="Line pressure threshold" 
                                        type="number" 
                                        step="1" 
                                    />
                                    <FormField 
                                        md={6} 
                                        disabled={disabled}
                                        name="critical_over_speed_threshold" 
                                        label="Critical over speed" 
                                        type="number" 
                                        step="1" 
                                        units={unitsOfMeasurement === UnitsOfMeasurement.metric ? "kmh" : "mph"} 
                                    />
                                    <FormField 
                                        md={6} 
                                        disabled={disabled}
                                        name="over_speed_threshold" 
                                        label="Over speed" 
                                        type="number" 
                                        step="1" 
                                        units={unitsOfMeasurement === UnitsOfMeasurement.metric ? "kmh" : "mph"} 
                                    />
                                </Col>
                            </Row>
                            {formChanged && <Button
                                className="btn"
                                onClick={() => {
                                    handleReset();
                                    setFormChanged(false);
                                }}>
                                Reset
                            </Button>}
                            <Button
                                disabled={disabled}
                                className="btn"
                                color="primary"
                                onClick={() => {
                                    submitForm();
                                    setFormChanged(false);
                                }}>
                                Save
                            </Button>
                        </Form>
                    }}
                </Formik>
            </div>
        </>
    )
}

export default TresholdsForm;