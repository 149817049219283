import { TractorBubblePositions, TrailerBubblePositions } from "../../enums";
import { sortBy } from "lodash";
import { convertDataEpochToDate } from "../../utils";
import { CRITICAL_OVER_TEMPERATURE_THRESHOLD, VIBRATION_THRESHOLD } from "../../constants";

class SensorDiagramUtils {

//ajustment for non standart asset with skipped axles like ["Drive_Left_1", "Drive_Right_1", "Drive_Left_3", "Drive_Right_3",]
  _intervalsAjustment = (arr, assetType) => {
    const maxOrder = Math.max.apply(
      Math,
      arr.map((el) => el.order)
    );

    if (arr.length) {
      return Array(assetType === "trailer" ? maxOrder : maxOrder + 1).fill(0);
    } else return [];
  };

  _getUpatedStr = (str) => {
    const arr = str.toLowerCase().split("_");
    const res = arr.map((el) => el.charAt(0).toUpperCase() + el.slice(1));
    return res.join(" ");
  };

///check for wrong sensors(case if trailer has tractor sensors or otherwise)
_checkForCorrectPosition = (positions, sensors) => {
    const checkedSensors = [];
    sensors.forEach((sensor) => {
      positions.forEach((position) => {
        if (sensor.position === position.key) {
          checkedSensors.push(sensor);
        }
      });
    });
    return checkedSensors;
  };

  getPositions = (prefixes, positions = []) => {
    for (let i = 1; i <= 5; i++) {
      prefixes.forEach((item) => {
        positions.push({ key: `${item}_${i}`, axle: i });
      });

      prefixes.forEach((item) => {
        positions.push({ key: `${item}_${i}_INNER`, axle: i });
      });

      prefixes.forEach((item) => {
        positions.push({ key: `${item}_${i}_OUTER`, axle: i });
      });
    }
    return positions;
  };

  indexAjustment = (index, order, arr, arr2) => {
    if (arr.length < arr2.length) {
      if (index !== order) {
        return order;
      }
      return index;
    }
    return index;
  };

  getCorrectedAxles = (sensors,smart,line,axlesLoads,assetType,positions) => {
    const isLineOrAxles = !!((line && line.length) ||(axlesLoads && axlesLoads.length));
   
    const renameKeys = (obj, newKeys) => {
      const keyValues = Object.keys(obj).map((key) => {
        const newKey = newKeys[key] || key;
        return { [newKey]: obj[key] };
      });
      return Object.assign({}, ...keyValues);
    };


    
  const isSteerAxle = ()=>{
    let isSteerAxle = false
    sensors.forEach((sensor)=>{
      const pos = positions.find((p)=>p.key === sensor.key)
      if(pos && pos.key.includes("STEER")){
        isSteerAxle = true
      }
    })
    return isSteerAxle
  }

    const isSteerInSmartNotInSensors = !isSteerAxle() && smart.some((el)=>el.position.includes('STEER')) ? true : false
    const isSensors = (sensors.length && smart.length ) || (sensors.length && !smart.length)
    const defaultKey = (assetType = "trailer" ? "TRAILER_LEFT_1" : "DRIVE_LEFT_1");
    const defaults = [{ key: defaultKey, hidden: true }];
    const copySensors = [...sensors]

    if (!sensors.length && !smart.length && isLineOrAxles) {
      return defaults;
    }

    if(isSensors && isSteerInSmartNotInSensors){
      const steerSmart = smart.filter((el)=>el.position.includes('STEER'))
       .map((e)=>{
         const newEl = renameKeys(e, {position: "key"})
         return {...newEl, hidden: true}
       })
      return[...copySensors, ...steerSmart]
     }

    const s = sensors.reduce((acc, sensor) => {
      const pos = positions.find((p) => p.key === sensor.key);
      if (pos) {
        acc.push(pos.axle);
      }
      return acc;
    }, []);
    const maxSensorsOrder = Math.max(...[...new Set(s)]);

    const sm = smart.reduce((acc, s) => {
      const pos = positions.find((p) => p.key === s.position);
      if (pos) {
        acc.push(pos.axle);
      }
      return acc;
    }, []);
    const smartOrders = [...new Set(sm)];
    const arr = []



    smartOrders.forEach((el) => {
      if (el > maxSensorsOrder | el < maxSensorsOrder ) {
        const keys = positions.filter((p) => p.axle === el)
        
        if (keys) {
          keys.forEach((e)=>{
            const additional = smart.find((el) => el.position === e.key);
            if(additional) arr.push(additional)
          })
        }
      }
    });
    
    if(arr.length){
      arr.forEach((el)=>{
        const newEl = renameKeys(el, { position: "key" });
        copySensors.push({ ...newEl, hidden: true });
      })
    }

    if (isSensors && !isSteerInSmartNotInSensors) {
      return copySensors;
    }

    if (!isSensors) {
      return smart.map((el) => {
        const newEl = renameKeys(el, { position: "key" });
        return { ...newEl, hidden: true };
      });

    }
    if (sensors.length < smart.length) {
      const axlesDelta = [];
      sensors.forEach((sensor) => {
        smart.forEach((el) => {
          if (sensor.key.includes(el.position)) {
            axlesDelta.push(el);
          }
          return;
        });
      });
      const axlesSet = [...new Set(axlesDelta)];
      const reducedSmarts = smart
        .reduce((acc, el) => {
          const matchSmart = axlesSet.find((a) => a.position === el.position);
          if (!matchSmart) {
            acc.push(el);
          } else {
            return acc;
          }
          return acc;
        }, [])
        .map((el) => {
          const newEl = renameKeys(el, { position: "key" });
          return { ...newEl, hidden: true };
        });

      return [...copySensors, ...reducedSmarts];
    }
  };

  getAxles = (sensors, positions, assetType) => {
    let bubblePositions = [];

    if (assetType === "tractor") bubblePositions = TractorBubblePositions;

    if (assetType === "trailer") bubblePositions = TrailerBubblePositions;
    const axles = sortBy(
     sensors && sensors.reduce((acc, sensor) => {
        const position = positions.find((p) => p.key === sensor.key);

        if (position) {
          const existingAxle = acc.find((axle) => axle.order === position.axle);

          if (!existingAxle) {
            acc.push({ order: position.axle, sensors: [sensor] });
          } else {
            existingAxle.sensors.push(sensor);
          }
        }

        return acc;
      }, []),
      (a) => a.order
    );

    axles.forEach((axle, index) => {
      const ajustedIntervals = this._intervalsAjustment(axles, assetType);
      const i = indexAjustment(index, axle.order - 1, axles, ajustedIntervals);
      let nonStandartTractorCorrection =
        assetType === "tractor" && ajustedIntervals.length > axles.length
          ? (ajustedIntervals.length - 1) - axles.length
          : 0;
      axle.positionBubble = bubblePositions[i + nonStandartTractorCorrection];
    });

    if (!axles.length) return axles

    const result = []
    const axlesCount = axles[axles.length - 1].order
    const start = assetType === "tractor" ? 0 : 1

    for (let i = start; i <= axlesCount; i++) {
      const axle = axles.find(el => el.order === i)
      const idx = assetType === "tractor" ? i : i - 1
      
      result.push({ order: i, positionBubble: bubblePositions[idx], sensors: axle?.sensors ?? [] })
    }

    return result;
  };
  
  getHubOrders = (positions, smart, thresholds) => {
    const checked = this._checkForCorrectPosition(positions, smart);
    const { critical_vibration_treshold, temperature_treshold, critical_temperature_treshold } = thresholds;

    const hbs = sortBy(
      checked.map((hub) => {
        const position = positions.find((p) => p.key === hub.position);
        if (position && hub.position.includes("LEFT")) {
          return { order: position.axle, ...hub, loc: "top" };
        }
        if (position && hub.position.includes("RIGHT")) {
          return { order: position.axle, ...hub, loc: "bottom" };
        }
  
        return null;
      }),
      (a) => (a ? a.order : 0)
    );


    const tooltipInfo = [];
    hbs.forEach((el) => {
      if (!el) return;

      const isTemperature = el.current_temperature > temperature_treshold;
      const isCriticalTemperature = critical_temperature_treshold !== null ? el.current_temperature > critical_temperature_treshold : el.current_temperature > CRITICAL_OVER_TEMPERATURE_THRESHOLD;
      
      // Vibration flags
      const isAbnormal = el.current_vib >= VIBRATION_THRESHOLD;
      const isAbnormalHigh = el.current_vib >= critical_vibration_treshold;
      const isTire = isAbnormal || isAbnormalHigh ? el.tire_outlier_ind : 0;
      const isBearing = isAbnormal || isAbnormalHigh ? el.bearing_outlier_ind : 0;

      let vibrationHealthLabel = ""

      if (isAbnormalHigh) {
        if (isTire && isBearing) {
          vibrationHealthLabel = "High Severity - Inspect Tire And Bearing"
        } else if (isTire) {
          vibrationHealthLabel = "High Severity - Inspect Tire"
        } else if (isBearing) {
          vibrationHealthLabel = "High Severity - Inspect Bearing"
        }
        
      } else if (isAbnormal) {
        if (isTire && isBearing) {
          vibrationHealthLabel = "Inspect Tire And Bearing"
        } else if (isTire) {
          vibrationHealthLabel = "Inspect Tire"
        } else if (isBearing) {
          vibrationHealthLabel = "Inspect Bearing"
        }
      }
      
      if (!vibrationHealthLabel) {
        vibrationHealthLabel = "Normal"
      }

      const latestData = el.last_vibs_measurement || el.last_temperature_measurement;
     
      const obj = {
        position: el.position && this._getUpatedStr(el.position),
        loc: el.loc,
        isTemperature,
        isCriticalTemperature,
        isVib: isAbnormal && (isTire || isBearing),
        isCriticalVib: isAbnormalHigh && (isTire || isBearing),
        vibrationHealthLabel,
        order: el.order,
        lastVib:
          el.last_vibs_measurement &&
          convertDataEpochToDate(el.last_vibs_measurement, null, null, true),
        lastTemp:
          el.last_temperature_measurement &&
          convertDataEpochToDate(
            el.last_temperature_measurement,
            null,
            null,
            true
          ),
        temperature: el.current_temperature,
        vib: el.current_vib,
        noStatus: el.is_no_status,
        noData: el.is_no_data,
        noStatusLatestMeasurement:
          latestData &&
          convertDataEpochToDate(
            el.last_temperature_measurement || el.last_vibs_measurement,
            null,
            null,
            true
          ),
      };
  
      tooltipInfo.push(obj);
    });
    return tooltipInfo;
  };

getIntervals = (axles, axleInterval, axleLeftMargin, assetType) => {
    const maxOrderTotal = this._intervalsAjustment(axles, assetType);
    return maxOrderTotal
      .reduce(
        (acc, _, index) => {
          if (index + 1 !== maxOrderTotal.length)
            acc.push(acc[acc.length - 1] + axleInterval);
          return acc;
        },
        [axleLeftMargin]
      )
      .reverse();
  };
}

const sensorsUtils = new SensorDiagramUtils();
const { getPositions, indexAjustment, getCorrectedAxles, getAxles, getHubOrders,getIntervals } = sensorsUtils;

export {
  getPositions,
  indexAjustment, 
  getCorrectedAxles, 
  getAxles, 
  getHubOrders,
  getIntervals 
}
