import {
    LOADING_REPORT,
    SET_REPORT_COLUMNS,
    SET_REPORT_ROWS,
    SET_REPORT_ERROR,
    SET_SELECTED_REPORT_TYPE,
    CANCEL_REPORT,
} from "../actionTypes"
import { store } from '../store'
import { getErrorObj } from "../../utils";
import api from "../../services/api";

export const setSelectedReportType = (type) => ({
    type: SET_SELECTED_REPORT_TYPE,
    payload: type,
})

const loadingReport = (isLoading) => ({
    type: LOADING_REPORT,
    payload: isLoading,
})

const setReportColumns = (columns) => ({
    type: SET_REPORT_COLUMNS,
    payload: columns,
})

const setReportRows = (rows) => ({
    type: SET_REPORT_ROWS,
    payload: rows,
})

const setReportError = (error) => ({
    type: SET_REPORT_ERROR,
    payload: error,
})

export const cancelReportAction = () => ({
    type: CANCEL_REPORT
})

let interval // send request every 5 seconds until you get report data
const defaultErrorMessage = "Something went wrong, please try again later"
export function fetchReport(link) {
    return function (dispatch, getState) {
        dispatch(loadingReport(true))
        const hideAutogeneratedAssets = getState().common.hideAutogeneratedAssets
        if (hideAutogeneratedAssets) link += "&hide_autogenerated=true"

        let isFetching = false

        return api
            .getReport(link)
            .then((s3Key) => {
                const checkStatus = async () => {
                    if (isFetching) return clearInterval(interval)
                    const res = await api.getReportStatusAndUrl(s3Key)
                    if (res && res.isReady) {
                        isFetching = true
                        const data = await api.getReportFromUrl(res.url)
                        dispatch(setReportColumns(data.columns || []))
                        const rows = data.rows.map((row) => {
                            if (row.asset_type) {
                                return { ...row, asset_type: row.asset_type.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) }
                            }
                            if (row["Asset Type"]) {
                                // eslint-disable-next-line
                                return { ...row, ["Asset Type"]: row["Asset Type"].replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) }
                            }
                            return row
                        })
                        dispatch(setReportRows(rows || []))
                        dispatch(setReportError(data.error ? getErrorObj({ statusCode: 400, message: "" }, defaultErrorMessage) : getErrorObj()))
                        dispatch(loadingReport(false))
                        clearInterval(interval)
                    }
                }

                interval = setInterval(checkStatus, 5000)
            })
            .catch((error) => {
                dispatch(setReportError(getErrorObj(error)))
                dispatch(setReportRows([]))
                dispatch(setReportColumns([]))
                dispatch(loadingReport(false))
            })
    }
}
export function fetchReportHistoryResult(history_id, is_internal) {
    return function (dispatch, getState) {
        dispatch(loadingReport(true))
        return api.getReportsHistoryResult(history_id).then((res) => {
            if (res && res.isReady) {
                api.getReportFromUrl(res.url).then((data) => {
                    const columns = []
                    if (data.columns) {
                        if (is_internal) {
                            data.columns.forEach((col) => {
                                columns.push(col)
                                const index = data.columns.indexOf(col)
                                data.rows.forEach((row) => {
                                    row[col] = row[index]
                                })
                            })
                        }
                        else {
                            data.columns.forEach((col) => {
                                columns.push(col.toLowerCase().replace(" ", "_"))
                            })
                        }
                    }
                    dispatch(setReportColumns(columns || []))
                    const rows = data.rows.map((row) => {
                        if (row.asset_type) {
                            return { ...row, asset_type: row.asset_type.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) }
                        }
                        if (row["Asset Type"]) {
                            // eslint-disable-next-line
                            return { ...row, ["Asset Type"]: row["Asset Type"].replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) }
                        }
                        return row
                    })
                    dispatch(setReportRows(rows || []))
                    dispatch(setReportError(data.error ? getErrorObj({ statusCode: 400, message: "" }, defaultErrorMessage) : getErrorObj()))
                    dispatch(loadingReport(false))
                })
            }

        }).catch((error) => {
            dispatch(setReportError(getErrorObj(error)))
            dispatch(setReportRows([]))
            dispatch(setReportColumns([]))
            dispatch(loadingReport(false))
        })
    }
}
store.subscribe(() => {
    const lastAction = store.getState().lastAction

    if (lastAction.type === SET_SELECTED_REPORT_TYPE || lastAction.type === CANCEL_REPORT) {
        store.dispatch(setReportColumns([]))
        store.dispatch(setReportRows([]))
        store.dispatch(setReportError({ statusCode: 200, message: "Use Report Controls To Get Data" }))
        store.dispatch(loadingReport(false))
        clearInterval(interval)
    }
})