import React, { InputHTMLAttributes } from 'react'

type MarkType = "line" | "check" | "circle"

interface IMenuItem {
    // [key: string]: unknown
    id: string
    type: "checkbox" | "radio"
    markType: MarkType
    checked: boolean
    label: string
    parentId: string | null
    offsetLeft?: number
    subMenuExpanded?: boolean
    onSubMenuToggle?: () => void
    onSelect?: () => void
}

export type MenuItemType = IMenuItem & InputHTMLAttributes<HTMLInputElement>

const getMarkerClassByType = (m: MarkType) => {
    switch (m) {
        case "line":
            return "check__line"
        case "circle":
            return "check__circle"
        default:
            return "check__marker"
    }
}

const MenuItem: React.FC<MenuItemType> = ({
    parentId,
    type,
    markType,
    label,
    offsetLeft = 0,
    className,
    subMenuExpanded,
    onSubMenuToggle,
    onSelect,
    ...props
}) => {
    return (
        <div 
            className='menu__item'
        >
            <div onClick={onSelect} className='menu__item__wrapper' style={{ marginLeft: `${offsetLeft}px` }}>
                <input 
                    type={type}
                    className={`${getMarkerClassByType(markType)} ${className}`}
                    onChange={() => {}}
                    {...props}
                />
                {label}
            </div>

            {subMenuExpanded !== undefined && onSubMenuToggle && (
                <div 
                    className='arrow__wrapper'
                    onClick={onSubMenuToggle}
                >
                    <i className={"arrow " + (subMenuExpanded ? "up " : "down ")}/>
                </div>
            )}
        </div>
    )
}

export default MenuItem

