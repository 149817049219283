

export const assetRequestBody = (asset: any, customer_id: number) => {
    delete asset.customer_name
  
    const requestBody = {
        customer_id,
        id: asset.id,
        vin: asset.vin.trim(),
        attributes: asset.attributes || {},
        name: asset.name,
        make: asset.make,
        model: asset.model,
        year: asset.year === "" ? null : +asset.year,
        asset_type: asset.asset_type,
        hours_of_use: asset.hours_of_use,
        ati_installed: asset.ati_installed,
        asset_subtype: asset.asset_subtype,
        number_of_axles: asset.number_of_axles
    }
  
    return requestBody
}