import moment from "moment";
import { convertDataEpochToDate, getCurrentEpoch } from "../../utils";
import { ICustomerFormValues, ICustomerReqBody } from "./types";

export const lastReportWasToday = (lastGpsUpdateEpoch: number) => {
  if (!lastGpsUpdateEpoch) return false;

  const today = convertDataEpochToDate(getCurrentEpoch(), null, null, false);
  const lastUpdateAtEpoch = convertDataEpochToDate(lastGpsUpdateEpoch, null, null, false);

  return moment(lastUpdateAtEpoch).isSame(today, "day");
};

export const someAssetIsReporting = (assets: any[]) => assets.some((asset) => lastReportWasToday(asset.last_gps_update_epoch));

interface IgetCustomerReqBody {
  (
    customer: ICustomerFormValues
  ): ICustomerReqBody
}

export const getCustomerReqBody: IgetCustomerReqBody = (customer) => {
  let { description, name, parent_id } = customer

  const request: ICustomerReqBody = {
    id: customer.id ? customer.id : null,
    description,
    name,
    parent_id: parent_id ? parseInt(parent_id) : null
  }

  return request
}