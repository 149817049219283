import { ICreateUserRequest, IUpdateUserRequest } from '../../redux/provision/action';
import { IUserFormValues } from './types';


export const getCreateUserBody = (values: IUserFormValues): ICreateUserRequest => {
    const body =  {
        email_address: values.email_address,
        first_name: values.first_name,
        last_name: values.last_name,
        phone_number: values.phone_number.trim(),
        role: values.role,
        time_zone: values.timezone,
        username: values.username,
        customer_ids: values.customerIds
    }

    return body
}


export const getUpdateUserBody = (values: IUserFormValues): IUpdateUserRequest => {
    const body: IUpdateUserRequest = {
        ...getCreateUserBody(values),
        old_phone_number: values.old_phone_number,
    }

    return body
}

interface IensureUserHaveAccess {
    (
        parentCustomerIds: Array<number>,
        childCustomers: Array<{id: number, name: string}>
    ): boolean
}

export const ensureUserHaveAccess: IensureUserHaveAccess = (parentCustomerIds, childCustomers) => {
    const haveAccess: Array<boolean> = childCustomers.map((item) => parentCustomerIds.includes(item.id))

    return haveAccess.some(el => el === false) ? false : true
}