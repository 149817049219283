import React, { useLayoutEffect, useRef, useState } from "react"
import { Tooltip } from "reactstrap"

import { IRow } from "."
import TableActions, { ITooltipText } from "../TableActions"


export interface IActionColumnConfig {
    disabled?: boolean
    customAction1Icon?: string
    customAction2Icon?: string
    tooltipTexts?: ITooltipText
    onDelete?: (id: number) => void
    onEdit?: (id: number) => void
    onCustomAction1?: (id: number) => void
    onCustomAction2?: (id: number) => void
    cellData?: IRow
}

interface IcomposeActionsFormatter {
    (
        config: IActionColumnConfig
    ): JSX.Element
}

export const composeDynamicActionsFormatter: IcomposeActionsFormatter = ({
    disabled = false,
    tooltipTexts,
    customAction1Icon,
    customAction2Icon,
    onDelete,
    onEdit,
    onCustomAction1,
    onCustomAction2,
    cellData
}) => {
    const id = parseInt(cellData?.id)
    return (
        <TableActions
            uniqueKey={id}
            disabled={disabled}
            tooltipTexts={tooltipTexts}
            customAction1Icon={customAction1Icon}
            customAction2Icon={customAction2Icon}
            onDelete={onDelete ? () => onDelete(id) : undefined}
            onUpdate={onEdit ? () => onEdit(id) : undefined}
            onCustomAction1={onCustomAction1 ? () => onCustomAction1(id) : undefined}
            onCustomAction2={onCustomAction2 ? () => onCustomAction2(id) : undefined}
        />
    )
}

export function useFocusRef<T extends HTMLOrSVGElement>(isSelected: boolean) {
    const ref = useRef<T>(null);

    useLayoutEffect(() => {
        if (!isSelected) return;
        ref.current?.focus({ preventScroll: true });
    }, [isSelected]);

    return {
        ref,
        tabIndex: isSelected ? 0 : -1
    };
}


interface ICellDetailsExpander {
    id: any
    isCellSelected: boolean
    expanded: any
    tooltipText?: string
    onCellExpand: React.MouseEventHandler<HTMLSpanElement>
}

export const CellDetailsExpander: React.FC<ICellDetailsExpander> = ({
    id,
    tooltipText,
    expanded,
    isCellSelected,
    onCellExpand,
}) => {
    const { ref, tabIndex } = useFocusRef<HTMLSpanElement>(isCellSelected);
    const [tooltipOpened, setTooltipOpened] = useState(false)

    const targetId = `profile_${id}`

    return (
        <div className={"cell__expander"} onClick={onCellExpand}>
            {tooltipText && !expanded && (
                <Tooltip
                    hideArrow={true}
                    placement="auto"
                    isOpen={tooltipOpened}
                    target={targetId}
                    toggle={() => setTooltipOpened(!tooltipOpened)}
                >
                    {tooltipText}
                </Tooltip>
            )}

            <span id={targetId} ref={ref} tabIndex={tabIndex}>
                {expanded ? '\u25BC' : '\u25B6'}
            </span>
        </div>
    )
}


export const SelectCellFormatter = ({
    value,
    isCellSelected,
    disabled,
    onChange
}: any) => {
    const { ref, tabIndex } = useFocusRef<HTMLInputElement>(isCellSelected);

    return (
        <input
            type={"checkbox"}
            ref={ref}
            tabIndex={tabIndex}
            disabled={disabled}
            checked={value}
            onChange={onChange}
        />
    );
}