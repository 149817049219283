import React, { useEffect } from "react";
import RivataDropdown from "../RivataDropdown";
import { LayerTypes } from "../RivataMap/utils";
import { saveGoogleAnalyticsEvent } from '../../utils/utils';

const dropdownItems = [
  {
    id: LayerTypes.NORMAL,
    label: "Map",
  },
  {
    id: LayerTypes.SATELLITE,
    label: "Satellite View",
  }
];

// probably connected: select makes call to add, remove makes call to remove
const LayersDropdown = ({ onSelect, selected, locale }) => {
  useEffect(() => {
    if (selected === LayerTypes.SATELLITE) {
      saveGoogleAnalyticsEvent('map_satelite_view_in_use')
    }

  }, [selected])
  return (
    <RivataDropdown
      items={dropdownItems}
      locale={locale}
      onSelect={onSelect}
      selected={selected}
      caret={true}
    />
  );
};
export default LayersDropdown;
