import { isPlainObject, maxBy } from "lodash";

import { store } from "../store";
import api from "../../services/api";

import { composeTpmsProfiles, convertDataEpochToDate, getColdInflationFromPercent, getCurrentEpoch } from "../../utils";
import { getStatusObj } from "../../utils/utils";

import { ThunkResult } from "../types";
import { AssetDetailsTypes, IChartXRange, IGpsData, IHardwareInfo } from "./types";
import { DEFAULT_WINDOW, WARNING_TYPE } from "../../constants";
import { SET_SELECTED_CUSTOMERS_LIST } from "../actionTypes";
import { AssetTypes, NoDataLabels } from "../../enums";
import { getMotionAnnotations } from "../../utils/chartUtils";
import { fetchGeofences } from "../geofences/action";
import { fetchAssetStatus } from "../details/action";
import { MenuItemType } from "../../componentsV2/Menu/MenuItem";
import { DropdownFilterType } from "../../modules/RecentWarnings";


export const setAssetDetailsWarningsOffset = (payload: number) => ({
    type: AssetDetailsTypes.SET_WARNINGS_OFFSET,
    payload
})

export const setAssetDetailsWarningsLimit = (payload: number) => ({
    type: AssetDetailsTypes.SET_WARNINGS_LIMIT,
    payload
})

export const setAssetDetailsWarningsDays = (payload: number) => ({
    type: AssetDetailsTypes.SET_WARNINGS_DAYS,
    payload
})

export const setAssetDetailsWarningsFilter = (payload: Record<DropdownFilterType, Array<MenuItemType>>) => ({
    type: AssetDetailsTypes.SET_WARNINGS_FILTER,
    payload
})

export const setAssetDetailsWarningsShowAll = (payload: boolean) => ({
    type: AssetDetailsTypes.SET_SHOW_ALL_WARNINGS,
    payload
})

export const setAssetDetailsChartsXRange = (payload: IChartXRange) => ({
    type: AssetDetailsTypes.SET_CHARTS_X_RANGE,
    payload
})

export const resetAssetDetailsReducer = (payload: boolean) => ({
    type: AssetDetailsTypes.RESET_REDUCER,
    payload
})

export const setAssetDetailsAssetInfo = (payload: IAssetInfo) => ({
    type: AssetDetailsTypes.SET_ASSET_INFO,
    payload
})


const orderGws = (gateways: IAssetInfo["gateways"]) => {
    const items = []
    const gws = [...gateways]
    const mainGwIdx = gws.findIndex(el => el.is_main === true)

    if (mainGwIdx > -1) {
        items.push(gws[mainGwIdx])
        gws.splice(mainGwIdx, 1)
    }

    gws.forEach(g => items.push(g))

    return items
}

const ONE_DAY_IN_SEC = 86400

const setInfoFromAsset = (exactAsset: IAsset) => {
    const info: IAssetInfo = {
        ...exactAsset,
        tpms_profile_id: null,
        gateways: [],
        lastLocationEpoch: exactAsset.last_gps_update_epoch,
        customer_id: null
    }

    store.dispatch({ type: AssetDetailsTypes.SET_ASSET_INFO, payload: info })
}

export const fetchAssetDetailsAssetInfo = (vin: string, timestamp: number | null): ThunkResult<void> => {
    return async (dispatch, getState) => {
        try {
            const assets: Array<IAsset> = getState().dash.assets.data
            const exactAsset = assets.find(el => el.vin === vin)
            // will trigger request for data faster
            if (exactAsset) setInfoFromAsset(exactAsset)

            dispatch({ type: AssetDetailsTypes.LOADING_ASSET_INFO, payload: true })

            const asset: IAssetInfo = await api.getExactAsset(vin)

            if (asset.gateways) {
                asset.gateways = convertDataEpochToDate(asset.gateways, "last_updated_at_epoch", "last_updated_at_datetime")

                const lastLocationEpoch = maxBy(asset.gateways, "last_updated_at_epoch")?.last_updated_at_epoch

                if (lastLocationEpoch) asset.lastLocationEpoch = lastLocationEpoch

                asset.gateways = orderGws(asset.gateways)
            } else {
                // add fake gateway data, to display missing gateway in vehicle info component
                asset.gateways = [
                    { is_main: true, mac: NoDataLabels.DASH, last_updated_at_epoch: 0, formatted_last_updated_at_datetime: NoDataLabels.DASH },
                ];
            }

            const validSubscription: ISubscriptions = {}
            const currentEpoch = getCurrentEpoch()

            if (asset.subscription) {
                Object.keys(asset.subscription).map((subKey) => {
                    const { valid_from, valid_to } = asset.subscription[subKey];

                    if (currentEpoch >= valid_from && currentEpoch <= valid_to) {
                        validSubscription[subKey] = asset.subscription[subKey];
                    }

                    return subKey;
                });
            }

            asset.subscription = validSubscription;

            const newCustomer = getState().common.customers.data.find((el: ICustomer) => el.id === asset.customer_id)

            if (newCustomer) {
                // @ts-ignore
                dispatch({ type: SET_SELECTED_CUSTOMERS_LIST, payload: [newCustomer] })
            }

            dispatch({ type: AssetDetailsTypes.SET_ASSET_INFO, payload: asset })

            if (asset.tpms_profile_id) dispatch(fetchAssetAssignedTpmsProfile(asset.tpms_profile_id))
            if (!exactAsset) dispatch(fetchAssetDetailsComponentsData(timestamp))

            dispatch({ type: AssetDetailsTypes.SET_ASSET_INFO_STATUS, payload: getStatusObj() })
        } catch (err) {
            // @ts-ignore
            const message = err.message.split(":")[1]

            dispatch({ type: AssetDetailsTypes.SET_ASSET_INFO_STATUS, payload: getStatusObj({ statusCode: 400, message }) })
        } finally {
            dispatch({ type: AssetDetailsTypes.LOADING_ASSET_INFO, payload: false })
        }
    }
}


export const fetchAssetDetailsComponentsData = (timestamp: number | null): ThunkResult<void> => {
    return async (dispatch, getState) => {
        try {
            const asset = getState().assetDetails.assetInfo.data

            if (!asset) return

            // clear range
            dispatch({ type: AssetDetailsTypes.SET_CHARTS_X_RANGE, payload: { min: null, max: null } })

            const currentEpoch = getCurrentEpoch()

            let epochFromWarning = null
            let epochToWarning = null
            let mapEpochFrom = currentEpoch - 24 * 3600
            let mapEpochTo = currentEpoch

            if (timestamp) {
                const dayPartInSec = ONE_DAY_IN_SEC / 6 // 4 hours
                timestamp = timestamp / 1000

                epochFromWarning = timestamp - ONE_DAY_IN_SEC
                epochToWarning = timestamp + ONE_DAY_IN_SEC
                mapEpochFrom = timestamp - dayPartInSec
                mapEpochTo = timestamp + dayPartInSec
            } else if (asset.lastLocationEpoch) {
                mapEpochFrom = asset.lastLocationEpoch - 24 * 3600
                mapEpochTo = asset.lastLocationEpoch
            }

            if (asset.asset_type === AssetTypes.TRAILER) {
                dispatch(fetchAssetDetailsLinePressure(asset.id, DEFAULT_WINDOW, epochFromWarning, epochToWarning))
            }

            dispatch(fetchGeofences())
            dispatch(fetchAssetDetailsGps(mapEpochFrom, mapEpochTo))
            dispatch(fetchAssetDetailsSpeedData(asset.id, DEFAULT_WINDOW, epochFromWarning, epochToWarning))
            dispatch(fetchAssetDetailsTemperature(asset.id, DEFAULT_WINDOW, epochFromWarning, epochToWarning))
            dispatch(fetchAssetDetailsVibrations(asset.id, DEFAULT_WINDOW, epochFromWarning, epochToWarning))
            dispatch(fetchAssetDetailsTirePressure(asset.id, DEFAULT_WINDOW, epochFromWarning, epochToWarning))
            if (!timestamp) {
                dispatch(fetchAssetDetailsAxleLoad(DEFAULT_WINDOW))
                dispatch(fetchAssetDetailsSensorInfo(asset.id))
                dispatch(fetchAssetStatus(asset.id, asset.asset_type))
            }
        } catch (err) {
            console.log(err);
        }
    }
}

// for charts control component
export const fetchAssetDetailsChartsData = (days: number): ThunkResult<void> => {
    return async (dispatch, getState) => {
        try {
            const assetInfoData = getState().assetDetails.assetInfo.data

            if (!assetInfoData) return

            const { id, asset_type } = assetInfoData

            // clear range
            dispatch({ type: AssetDetailsTypes.SET_CHARTS_X_RANGE, payload: { min: null, max: null } })

            dispatch(fetchAssetDetailsSpeedData(id, days, null, null))
            dispatch(fetchAssetDetailsTemperature(id, days, null, null))
            dispatch(fetchAssetDetailsVibrations(id, days, null, null))
            dispatch(fetchAssetDetailsTirePressure(id, days, null, null))
            dispatch(fetchAssetDetailsAxleLoad(days))

            if (asset_type === AssetTypes.TRAILER) {
                dispatch(fetchAssetDetailsLinePressure(id, days, null, null))
            }
        } catch (err) {
            console.log(err);
        }
    }
}


const fetchAssetAssignedTpmsProfile = (id: number): ThunkResult<void> => {
    return async (dispatch) => {
        try {
            const warningSettings = await api.getWarningsSettings(WARNING_TYPE.TIRE_PRESSURE_SENSOR, id)

            const profiles = composeTpmsProfiles(warningSettings)

            if (profiles.length) {
                let profile = profiles[0];
                profile.thresholds = {};
                const {
                    low_pressure_in_percent,
                    critical_low_pressure_in_percent,
                    over_pressure_in_percent,
                    critical_over_pressure_in_percent,
                } = profile;

                Object.keys(profile.axels).map((typeKey) => {
                    if (!isPlainObject(profile.axels[typeKey])) return null;

                    const thresholds = Object.keys(profile.axels[typeKey]).reduce(
                        (prev, current) => {
                            const cold_inflation_pressure_in_psi = +profile.axels[typeKey][current];
                            const axleThresholds = {
                                low_pressure: +getColdInflationFromPercent(
                                    cold_inflation_pressure_in_psi,
                                    low_pressure_in_percent
                                ),
                                critical_low_pressure: +getColdInflationFromPercent(
                                    cold_inflation_pressure_in_psi,
                                    critical_low_pressure_in_percent
                                ),
                                over_pressure: +getColdInflationFromPercent(
                                    cold_inflation_pressure_in_psi,
                                    over_pressure_in_percent
                                ),
                                critical_over_pressure: +getColdInflationFromPercent(
                                    cold_inflation_pressure_in_psi,
                                    critical_over_pressure_in_percent
                                ),
                            };

                            const obj = { ...prev, [current]: axleThresholds };

                            return obj;
                        },
                        {}
                    );
                    return (profile.thresholds[typeKey] = thresholds);
                });

                dispatch({ type: AssetDetailsTypes.SET_ASSIGNED_TPMS_PROFILE, payload: profile })
            }
        } catch (err) {
            console.log(err)
        }
    }
}


const fetchAssetDetailsSensorInfo = (assetId: number): ThunkResult<void> => {
    return async (dispatch) => {
        try {
            dispatch({ type: AssetDetailsTypes.LOADING_SENSOR_INFO, payload: true })

            let res: Array<IHardwareInfo> = await api.getSensorNodeInfo(assetId)

            res = convertDataEpochToDate(res, "last_updated_at_epoch", "last_update");

            dispatch({ type: AssetDetailsTypes.SET_SENSOR_INFO, payload: res })
            dispatch({ type: AssetDetailsTypes.SET_SENSOR_INFO_STATUS, payload: getStatusObj() })
        } catch (err) {
            dispatch({ type: AssetDetailsTypes.SET_SENSOR_INFO_STATUS, payload: getStatusObj(err) })
        } finally {
            dispatch({ type: AssetDetailsTypes.LOADING_SENSOR_INFO, payload: false })
        }
    }
}


export const fetchAssetDetailsWarnings = (): ThunkResult<void> => {
    return async (dispatch, getState) => {
        try {
            dispatch({ type: AssetDetailsTypes.LOADING_WARNINGS, payload: true })

            const asset = getState().assetDetails.assetInfo.data

            if (!asset) return;

            const { offset, limit, showAllWarnings, filter, days } = getState().assetDetails.recentWarnings;

            const daysRange =

                filter.warning_status.length === 1 &&
                    filter.warning_status.findIndex(s => s.id === "active") !== -1
                    ? null : days

            const res = await api.getRecentWarnings(asset.id, daysRange, offset, limit, showAllWarnings, filter)

            let pagedData = convertDataEpochToDate(res.paged_data, "epoch")
            pagedData = convertDataEpochToDate(pagedData, "recovered_to_normal_at_epoch", "recovered_to_normal_at_datetime")
            pagedData = convertDataEpochToDate(pagedData, "canceled_at_epoch", "canceled_at_datetime")

            dispatch({ type: AssetDetailsTypes.SET_WARNINGS, payload: pagedData })
            dispatch({ type: AssetDetailsTypes.SET_WARNINGS_TOTAL_COUNT, payload: res.total_count })
            dispatch({ type: AssetDetailsTypes.SET_WARNINGS_ACKNOWLEDGED_COUNT, payload: res.acknowledged_count })

            dispatch({ type: AssetDetailsTypes.SET_WARNINGS_STATUS, payload: getStatusObj() })
        } catch (err) {
            dispatch({ type: AssetDetailsTypes.SET_WARNINGS_STATUS, payload: getStatusObj(err) })
            console.log(err);

        } finally {
            dispatch({ type: AssetDetailsTypes.LOADING_WARNINGS, payload: false })
        }
    }
}

export const postAssetDetailsWarningsAcknowledge = (acknowledgements: Array<string>, newOffset?: number): ThunkResult<void> => {
    return async (dispatch) => {
        try {
            dispatch({ type: AssetDetailsTypes.LOADING_WARNINGS_ACKNOWLEDGE, payload: true })

            await api.postAcknowledgements(acknowledgements)

            if (newOffset !== undefined && newOffset >= 0) dispatch({ type: AssetDetailsTypes.SET_WARNINGS_OFFSET, payload: newOffset })
            else dispatch(fetchAssetDetailsWarnings())

        } catch (err) {
            console.log("Error postAssetDetailsWarningsAcknowledge: ", err)
        } finally {
            dispatch({ type: AssetDetailsTypes.LOADING_WARNINGS_ACKNOWLEDGE, payload: false })
        }
    }
}

export const postAssetDetailsCancelWarnings = (data: { keys: Array<string> }): ThunkResult<void> => {
    return async (dispatch) => {
        try {
            await api.postWarningsCancel(data)

            dispatch(fetchAssetDetailsWarnings())
        } catch (err) {
            console.log("postAssetDetailsCancelWarnings: ", err);
        }
    }
}


export const fetchAssetDetailsGps = (epochFrom: number, epochTo: number): ThunkResult<void> => {
    return async (dispatch, getState) => {
        try {
            const asset = getState().assetDetails.assetInfo.data

            if (!asset) return

            dispatch({ type: AssetDetailsTypes.LOADING_GPS, payload: true })

            const res: Array<IGpsData> = await api.getGpsRange(asset.id, epochFrom, epochTo);

            const data: Array<IGpsData> = convertDataEpochToDate(res, "epoch")

            data.sort((a, b) => (a.epoch > b.epoch ? 1 : -1))

            dispatch({ type: AssetDetailsTypes.SET_GPS, payload: data })
            dispatch({ type: AssetDetailsTypes.SET_GPS_STATUS, payload: getStatusObj() })
        } catch (err) {
            dispatch({ type: AssetDetailsTypes.SET_GPS_STATUS, payload: getStatusObj(err) })
        } finally {
            dispatch({ type: AssetDetailsTypes.LOADING_GPS, payload: false })
        }
    }
}


const fetchAssetDetailsSpeedData = (assetId: number, days: number, epochFrom: number | null, epochTo: number | null): ThunkResult<void> => {
    return async (dispatch) => {
        try {
            const res = await api.getSpeedData(assetId, days, epochFrom, epochTo, true)

            dispatch({ type: AssetDetailsTypes.SET_MOTION_ANNOTATIONS, payload: getMotionAnnotations(res) })
        } catch (err) {
            console.log(err);
        }
    }
}

const fetchAssetDetailsTemperature = (assetId: number, days: number, epochFrom: number | null, epochTo: number | null): ThunkResult<void> => {
    return async (dispatch) => {
        try {
            dispatch({ type: AssetDetailsTypes.LOADING_TEMPERATURE, payload: true })

            const reducePoints = window.location.search.includes("reducePoints")
            const res = await api.getTemperatures(assetId, days, epochFrom, epochTo, reducePoints)

            dispatch({ type: AssetDetailsTypes.SET_TEMPERATURE, payload: res })
            dispatch({ type: AssetDetailsTypes.SET_TEMPERATURE_STATUS, payload: getStatusObj() })
        } catch (err) {
            dispatch({ type: AssetDetailsTypes.SET_TEMPERATURE_STATUS, payload: getStatusObj(err) })
        } finally {
            dispatch({ type: AssetDetailsTypes.LOADING_TEMPERATURE, payload: false })
        }
    }
}

const fetchAssetDetailsVibrations = (assetId: number, days: number, epochFrom: number | null, epochTo: number | null): ThunkResult<void> => {
    return async (dispatch) => {
        try {
            dispatch({ type: AssetDetailsTypes.LOADING_VIBRATIONS, payload: true })

            const res = await api.getVibrations(assetId, days, epochFrom, epochTo)

            dispatch({ type: AssetDetailsTypes.SET_VIBRATIONS, payload: res })
            dispatch({ type: AssetDetailsTypes.SET_VIBRATIONS_STATUS, payload: getStatusObj() })
        } catch (err) {
            dispatch({ type: AssetDetailsTypes.SET_VIBRATIONS_STATUS, payload: getStatusObj(err) })
        } finally {
            dispatch({ type: AssetDetailsTypes.LOADING_VIBRATIONS, payload: false })
        }
    }
}

const fetchAssetDetailsTirePressure = (assetId: number, days: number, epochFrom: number | null, epochTo: number | null): ThunkResult<void> => {
    return async (dispatch) => {
        try {
            dispatch({ type: AssetDetailsTypes.LOADING_TIRE_PRESSURE, payload: true })

            const reducePoints = window.location.search.includes("reducePoints")
            const res = await api.getTirePressure(assetId, days, epochFrom, epochTo, reducePoints)

            dispatch({ type: AssetDetailsTypes.SET_TIRE_PRESSURE, payload: res })
            dispatch({ type: AssetDetailsTypes.SET_TIRE_PRESSURE_STATUS, payload: getStatusObj() })
        } catch (err) {
            dispatch({ type: AssetDetailsTypes.SET_TIRE_PRESSURE_STATUS, payload: getStatusObj(err) })
        } finally {
            dispatch({ type: AssetDetailsTypes.LOADING_TIRE_PRESSURE, payload: false })
        }
    }
}

const fetchAssetDetailsAxleLoad = (days: number): ThunkResult<void> => {
    return async (dispatch, getState) => {
        try {
            const asset = getState().assetDetails.assetInfo.data

            if (!asset) return

            dispatch({ type: AssetDetailsTypes.LOADING_AXLE_LOAD, payload: true })

            const res = await api.getAxleLoad(asset.id, days)

            dispatch({ type: AssetDetailsTypes.SET_AXLE_LOAD, payload: res.data })
            dispatch({ type: AssetDetailsTypes.SET_AXLE_LOAD_CALIBRATIONS_COUNT, payload: res.calibrations_count })
            dispatch({ type: AssetDetailsTypes.SET_AXLE_LOAD_STATUS, payload: getStatusObj() })
        } catch (err) {
            dispatch({ type: AssetDetailsTypes.SET_AXLE_LOAD_STATUS, payload: getStatusObj(err) })
        } finally {
            dispatch({ type: AssetDetailsTypes.LOADING_AXLE_LOAD, payload: false })
        }
    }
}

const fetchAssetDetailsLinePressure = (assetId: number, days: number, epochFrom: number | null, epochTo: number | null): ThunkResult<void> => {
    return async (dispatch) => {
        try {
            dispatch({ type: AssetDetailsTypes.LOADING_LINE_PRESSURE, payload: true })

            const res = await api.getLinePressure(assetId, days, epochFrom, epochTo)

            dispatch({ type: AssetDetailsTypes.SET_LINE_PRESSURE, payload: res })
            dispatch({ type: AssetDetailsTypes.SET_LINE_PRESSURE_STATUS, payload: getStatusObj() })
        } catch (err) {
            dispatch({ type: AssetDetailsTypes.SET_LINE_PRESSURE_STATUS, payload: getStatusObj(err) })
        } finally {
            dispatch({ type: AssetDetailsTypes.LOADING_LINE_PRESSURE, payload: false })
        }
    }
}


export const fetchAssetDetailsPowerInputs = (esn: string): ThunkResult<void> => {
    return async (dispatch, getState) => {
        try {
            const assetId = getState().assetDetails.assetInfo.data?.id

            if (!assetId) return

            dispatch({ type: AssetDetailsTypes.LOADING_POWER_INPUTS, payload: true })

            const res = await api.getpowerInput(assetId, esn)

            dispatch({ type: AssetDetailsTypes.SET_POWER_INPUTS, payload: res })
            dispatch({ type: AssetDetailsTypes.SET_POWER_INPUTS_STATUS, payload: getStatusObj() })
        } catch (err) {
            dispatch({ type: AssetDetailsTypes.SET_POWER_INPUTS_STATUS, payload: getStatusObj(err) })
        } finally {
            dispatch({ type: AssetDetailsTypes.LOADING_POWER_INPUTS, payload: false })
        }
    }
}


store.subscribe(() => {
    const lastAction = store.getState().lastAction;

    if (
        lastAction.type === AssetDetailsTypes.SET_WARNINGS_FILTER ||
        lastAction.type === AssetDetailsTypes.SET_WARNINGS_LIMIT ||
        lastAction.type === AssetDetailsTypes.SET_WARNINGS_DAYS ||
        lastAction.type === AssetDetailsTypes.SET_SHOW_ALL_WARNINGS
    ) {
        store.dispatch(setAssetDetailsWarningsOffset(0))
    }

    if (lastAction.type === AssetDetailsTypes.SET_WARNINGS_OFFSET) {
        store.dispatch(fetchAssetDetailsWarnings())
    }
})