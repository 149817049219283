import React from "react"
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Input,
} from "reactstrap"
import { columnsSelectorCheckboxProps } from "./useColumnsSelector"
import { saveGoogleAnalyticsEvent } from "../../utils/utils"


type Props = {
  checkboxesProps: Array<columnsSelectorCheckboxProps>
  onSetDefault: () => void
  className: string
}

const ColumnsSelectorDropdown: React.FC<Props> = ({
  checkboxesProps,
  onSetDefault,
  className,
}) => {
  return (
    <UncontrolledDropdown className={className}>
      <DropdownToggle caret color="white">
        Columns
      </DropdownToggle>

      <DropdownMenu right flip={false}>
        {checkboxesProps.map((checkboxProps) => {
          return (
            <DropdownItem
              key={`hideColumnsCheckbox_${checkboxProps.columnId}`}
              onClick={() => {
                saveGoogleAnalyticsEvent('dashboard_column_selector_change_click', { column: checkboxProps.label})
                checkboxProps.onClick()
              }}
              toggle={false}
              disabled={checkboxProps.disabled}
            >
              <Input
                type="checkbox"
                checked={checkboxProps.checked}
                disabled={checkboxProps.disabled}
                onChange={() => {}}
              />
              {checkboxProps.label}
            </DropdownItem>
          )
        })}

        <DropdownItem divider />

        <DropdownItem className="set-default" onClick={() => {
          saveGoogleAnalyticsEvent('dashboard_column_selector_change_click', { column: "reset" })
          onSetDefault()
        }}>
          Reset Columns
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default ColumnsSelectorDropdown