import baseApi from "./BaseApi"

class GpsApi {
  getLatestGpsBulk = (
    days: number,
    filterType?: string,
    filterData?: string,
    hideAutogeneratedAssets?: boolean
  ) => {
    const params = new URLSearchParams()

    if (filterType) {
      params.append("filter_type", filterType)
    }

    if (filterData) {
      params.append("filter_data", filterData)
    }

    if (typeof days === "number") {
      params.append("warning_days", days.toString())
    }

    if (hideAutogeneratedAssets) params.append("hide_autogenerated", "true")
  
    const queryString = params.toString()
  
    return baseApi.get(`rivata/location?${queryString}`)
  }

  getLatestGpsV2 = (queryStr: string) => {
    return baseApi.get(`rivata/location?${queryStr}`)
  }

  getAssetGpsDetails = (assetId: number) => {
    return baseApi.get(`rivata/location/details?asset_id=${assetId}`)
  }

  getGpsRange = (assetId: number, startDate: number, endDate: number) => {
    return baseApi.get(`rivata/location?asset_id=${assetId}&epoch_from=${startDate}&epoch_to=${endDate}`, false)
  }
}

const gpsApi = new GpsApi()

export default gpsApi