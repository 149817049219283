import React from "react"
import { Redirect } from "react-router-dom";
import { Pages } from "../enums";
import { useTypedSelector } from "../hooks/useTypedSelector";

const allowedPagesForCargoSub = [
  Pages.Geofences,
  Pages.Reports,
  Pages.Provision,
  Pages.Document,
  Pages.Settings,
  Pages.Support,
  Pages.ManageGeofences,
  Pages.TagLocations,
  Pages.TagAssetDetails,
  Pages.TagLocationDetails,
]

const cargotagPages = [
  Pages.TagLocations,
  Pages.TagAssetDetails,
  Pages.TagLocationDetails,
]

const withSubscriptionCheck = (Page) => {
  const WithSubCheck = (props) => {
    const {
      customerDefaults : { subscriptions, cargo_tags_subscription },
      isDefaultsLoading,
      isSuperAdmin,
      isAttributesLoaded
    } = useTypedSelector(state => ({
      customerDefaults: state.common.customerDefaults,
      isDefaultsLoading: state.common.isDefaultsLoading,
      isSuperAdmin: state.auth.isSuperAdmin,
      isAttributesLoaded: state.auth.isAttributesLoaded
    }))

    // return all while subscription isn't loaded or user is super admin
    if (isSuperAdmin || isDefaultsLoading || !subscriptions) return <Page {...props} /> 

    if (
      cargotagPages.includes(props.componentName) &&
      !cargo_tags_subscription && 
      !isDefaultsLoading && isAttributesLoaded
    ) {
      return <Redirect to="/" />
    }

    if (
      cargo_tags_subscription &&
      Object.keys(subscriptions).length === 0 &&
      !allowedPagesForCargoSub.includes(props.componentName) 
    ) {
      return <Redirect to="/tag-locations" />
    }

    return <Page {...props} />
  }

  return WithSubCheck
}

export default withSubscriptionCheck;