import {
  SET_GEOLOCATIONS,
  SET_GEOLOCATIONS_ORDER,
  SET_GEOLOCATIONS_TOTAL_COUNT,
  SET_GEOLOCATIONS_OFFSET,
  SET_GEOLOCATIONS_LIMIT,
  SET_GEOLOCATIONS_TAGS_GEOFENCE_DATA,
  SET_GEOLOCATIONS_LOADING,
  SET_GEOLOCATIONS_FILTER,
  SET_GEOLOCATIONS_ASSETS_WITH_CARGOTAGS,
  SET_SELECTED_LOCATION_OR_ASSET,
  SET_GEOLOCATIONS_DROPDOWN,
  SET_GEOLOCATIONS_DATA_STATUS,
  SET_GEOLOCATIONS_GEOFENCES_STATUS,
  SET_GEOLOCATIONS_ASSETS_STATUS,
  SET_GEOLOCATIONS_SELECTED_STATUS
} from "../actionTypes";

const initialState = {
  data: [],
  dataStatus: {},
  tagsGeofences: [],
  geofencesStatus: {},
  assetsWithCargotags: [],
  assetsStatus: {},
  dropdown: [],
  selectedLocationOrAsset: null,
  selectedStatus: {},
  limit: 10,
  offset: 0,
  totalCount: 0,
  isLoading: false,
  filter: "",
  sortOptions: {
    column: null,
    direction: null
  },
};

const tagLocations = (state = initialState, action) => {
  switch (action.type) {
    case SET_GEOLOCATIONS:
      return { ...state, data: action.payload };
    case SET_GEOLOCATIONS_TOTAL_COUNT:
      return { ...state, totalCount: action.payload };
    case SET_GEOLOCATIONS_OFFSET:
      return { ...state, offset: action.payload };
    case SET_GEOLOCATIONS_LIMIT:
      return { ...state, limit: action.payload };
    case SET_GEOLOCATIONS_TAGS_GEOFENCE_DATA:
      return { ...state, tagsGeofences: action.payload };
    case SET_GEOLOCATIONS_LOADING:
      return { ...state, isLoading: action.payload };
    case SET_GEOLOCATIONS_ORDER:
      return { ...state, sortOptions: action.payload };
    case SET_GEOLOCATIONS_FILTER:
      return { ...state, filter: action.payload };
    case SET_GEOLOCATIONS_ASSETS_WITH_CARGOTAGS:
      return { ...state, assetsWithCargotags: action.payload };
    case SET_SELECTED_LOCATION_OR_ASSET:
      return { ...state, selectedLocationOrAsset: action.payload };
    case SET_GEOLOCATIONS_DROPDOWN:
      return { ...state, dropdown: action.payload };
    case SET_GEOLOCATIONS_DATA_STATUS:
      return { ...state, dataStatus: action.payload };
    case SET_GEOLOCATIONS_GEOFENCES_STATUS:
      return { ...state, geofencesStatus: action.payload };
    case SET_GEOLOCATIONS_ASSETS_STATUS:
      return { ...state, assetsStatus: action.payload };
    case SET_GEOLOCATIONS_SELECTED_STATUS:
      return { ...state, selectedStatus: action.payload };
    default:
      return state;
  }
};

export default tagLocations;
