import React, { useCallback, useMemo, useState, useContext } from 'react'
import moment from 'moment'
import {
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from 'reactstrap'
import RivataLoader from '../../components/RivataLoader'
import StatusAlert from '../../components/StatusAlert'
import classnames from 'classnames'
import RivataModule from '../../components/RivataModule'
import AssetRow from './AssetRow'

import { WheelOrder, SubscriptionTypes, HardwareType } from '../../enums'
import { useTablesConfig } from './hooks'
import { useTypedSelector } from '../../hooks/useTypedSelector'

import { AssetDetailsContext } from '../../pages/AssetDetails'
import { useActions } from '../../hooks/useActions'
import { dateToEpoch } from '../../utils'
import ModalLogInfo from '../../components/LogsModal'

const SensorNodeInfo = ({ width }) => {
  const { isLoading, data, status, timezone, subscriptions, adminInfo } =
    useTypedSelector((state) => ({
      ...state.assetDetails.sensorInfo,
      timezone: state.auth.preferences.timezone,
      subscriptions: state.common.customerDefaults.subscriptions,
      adminInfo: state.getAdminLogs,
    }))
  const { fetchAdminLogs, resetTable } = useActions()

  const { locale, assetInfoData, isSuperAdmin } =
    useContext(AssetDetailsContext)

  const assetType = assetInfoData?.asset_type

  const [activeTab, setActiveTab] = useState(0)
  const [onlyIgnoredSensors, setOnlyIgnoredSensors] = useState(true)

  const defaultData = {
    hardware_id: '',
    mac: '',
    date: {
      fromDate: moment().format(),
      toDate: moment().format(),
    },
    logsType: '',
    time: { from: '00:00', to: '23:59' },
    offset: 0,
    logsCount: 10,
  }

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [fetchData, setFetchData] = useState(defaultData)

  const { hardware_id, mac, date, logsType, time, offset, logsCount } =
    fetchData

  const setUserEpoch = useCallback(
    (dates) => {
      const { from, to } = time
      const { dateFrom, dateTo } = dates

      return dateToEpoch(dateFrom, dateTo, from, to)
    },
    [time],
  )

  const setAdminLogs = useCallback(
    (id, updatedDate, type, mac = '') => {
      const gatewaysType =
        type === 'gateway_rivata' || type === 'gateway_translator'
      const { startDate, endDate } = dateToEpoch(
        updatedDate,
        updatedDate,
        time.from,
        time.to,
      )
      setFetchData((prev) => ({
        ...prev,
        hardware_id: id,
        mac,
        date: {
          fromDate: moment(startDate * 1000).format(),
          toDate: moment(startDate * 1000).format(),
        },
        logsType: type,
        logsCount: type !== 'sensors' ? 20 : 10,
        time: { from: '00:00', to: '23:59' },
      }))
      type === 'sensors' && fetchAdminLogs(id, type, startDate, 0, 9)
      gatewaysType && fetchAdminLogs(mac, type, startDate, 0, 20, endDate)
      setIsModalOpen(true)
    },
    [fetchAdminLogs, time.from, time.to],
  )

  const onSelectBase = useCallback(
    (baseType) => {
      const type = baseType.toLowerCase().replace(' ', '_')

      setFetchData((prev) => ({
        ...prev,
        logsType: type,
        time: { from: '00:00', to: '23:59' },
        offset: 0,
        logsCount: 20,
      }))

      const { startDate, endDate } = setUserEpoch(date)
      const isMac = type === 'gateway_rivata' ? hardware_id : mac

      fetchAdminLogs(isMac, type, startDate, 0, 20, endDate)
    },
    [date, fetchAdminLogs, hardware_id, mac, setUserEpoch],
  )

  const fetchLogs = useCallback(
    (epoch, fromPage, toPage) => {
      const { startDate, endDate } = setUserEpoch(epoch)
      const gatewaysType =
        logsType === 'gateway_translator' || logsType === 'gateway_rivata'
      logsType === 'sensors' &&
        fetchAdminLogs(hardware_id, logsType, startDate, fromPage, toPage)
      const isMac = logsType === 'gateway_rivata' ? hardware_id : mac
      gatewaysType &&
        fetchAdminLogs(isMac, logsType, startDate, fromPage, 20, endDate)
    },
    [fetchAdminLogs, hardware_id, logsType, mac, setUserEpoch],
  )

  const onSelectLimit = useCallback(
    (num) => {
      setFetchData((prev) => ({ ...prev, offset: 0, logsCount: num }))
      fetchLogs(date, 0, num)
    },
    [date, fetchLogs],
  )

  const onSubmit = useCallback(
    (data) => {
      setFetchData((prev) => ({ ...prev, offset: 0, logsCount: 10 }))
      fetchLogs(data, offset, logsCount)
    },
    [fetchLogs, logsCount, offset],
  )

  const formReset = useCallback((formData) => {
    const { fromDate, toDate, from, to } = formData
    setFetchData((prev) => ({
      ...prev,
      date: {
        fromDate,
        toDate,
      },
      time: { from, to },
    }))
  }, [])

  const onLogsPageChange = useCallback(
    (step) => {
      setFetchData((prev) => ({ ...prev, offset: step }))
      let start = step - logsCount
      let end = step <= 0 ? logsCount : step
      if (step <= 0 && logsType === 'sensors') {
        start = 0
      }
      if (step !== 0 && logsType === 'sensors') {
        start = step
        end = step + logsCount
      }
      if (logsType !== 'sensors') {
        start = step
      }
      fetchLogs(date, start, end)
    },
    [date, fetchLogs, logsCount, logsType],
  )

  const setTime = useCallback((time, fromTo) => {
    setFetchData((prev) => {
      const newTime = { ...prev.time }

      if (fromTo === 'from') {
        newTime.from = time
      } else {
        newTime.to = time
      }

      return {
        ...prev,
        offset: 0,
        time: newTime,
      }
    })
  }, [])

  const toggle = useCallback(
    (tab) => {
      if (activeTab !== tab) setActiveTab(tab)
    },
    [activeTab],
  )

  const wheelPositionNames = Object.keys(WheelOrder)

  const isInList = (arr, el) => {
    return arr.some((pos) => pos === el)
  }

  const sensorsInList = useMemo(() => {
    // eslint-disable-next-line
    return data.filter((el) => {
      const isIn = isInList(wheelPositionNames, el.position)
      if (isIn) return el
    })
    // eslint-disable-next-line
  }, [data])

  const sensorsOutsideList = useMemo(() => {
    // eslint-disable-next-line
    return data.filter((el) => {
      const isIn = isInList(wheelPositionNames, el.position)
      if (!isIn) return el
    })
    // eslint-disable-next-line
  }, [data])

  const sorted = useMemo(() => {
    if (Array.isArray(data)) {
      return [...sensorsInList].sort((d1, d2) => {
        return WheelOrder[d1.position] - WheelOrder[d2.position]
      })
    }
    return data
    // eslint-disable-next-line
  }, [data])

  const sortedData = useMemo(() => {
    return [...sorted, ...sensorsOutsideList]
  }, [sorted, sensorsOutsideList])

  const tablesConfig = useTablesConfig(
    sortedData,
    setOnlyIgnoredSensors,
    assetType,
  )

  const renderTabs = useMemo(() => {
    if (!subscriptions && !isSuperAdmin) return
    const subKeys = isSuperAdmin
      ? Object.values(SubscriptionTypes)
      : Object.keys(subscriptions ? subscriptions : {})

    return tablesConfig.map((tab, index) => {
      if (
        !tab ||
        !subKeys.find((el) => tab.hardwareTypes.includes(el.toLowerCase()))
      )
        return null

      return (
        <NavItem className='nav__item mt-2' key={index}>
          <NavLink
            className={classnames({ active: activeTab === index })}
            style={{ cursor: 'pointer' }}
            onClick={() => toggle(index)}
          >
            {tab.label}
          </NavLink>
        </NavItem>
      )
    })
  }, [activeTab, tablesConfig, toggle, subscriptions, isSuperAdmin])

  const renderData = (tab) => {
    let dataToRender = sortedData.filter(
      (d) => d.hardware_type_id === tab.hardwareTypeId,
    )
    if (tab.hardwareTypeId === HardwareType.GATEWAY) {
      dataToRender = dataToRender.sort((c) => c.mac)
    }

    return dataToRender.map((datum, i) => {
      return (
        <AssetRow
          key={`sensor_info_table_rows_${i}`}
          {...datum}
          lastUpdated={datum.last_updated_at_epoch}
          isSuperAdmin={isSuperAdmin}
          onAdminLogs={setAdminLogs}
          position={datum.position}
          locale={locale}
          timezone={timezone}
        />
      )
    })
  }

  const renderTables = useMemo(() => {
    if (!subscriptions && !isSuperAdmin) return
    const subKeys = isSuperAdmin
      ? Object.values(SubscriptionTypes)
      : Object.keys(subscriptions ? subscriptions : {})

    return tablesConfig.map((tab, index) => {
      if (
        !tab ||
        !subKeys.find((el) => tab.hardwareTypes.includes(el.toLowerCase()))
      )
        return null
      return (
        <TabPane tabId={index} key={index}>
          <Row>
            <Col sm='12'>
              <Table hover size='sm' responsive>
                <thead>
                  <tr className='sensor-tables'>
                    {tab.hardwareTypeId !== HardwareType.GATEWAY && (
                      <th>{locale['Position']}</th>
                    )}
                    <th>{locale['Sensor ID']}</th>
                    <th>{locale['Last Update']}</th>
                  </tr>
                </thead>
                <tbody>{renderData(tab)}</tbody>
              </Table>
            </Col>
          </Row>
        </TabPane>
      )
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedData, tablesConfig, locale, timezone, subscriptions, isSuperAdmin])

  return (
    <>
      <RivataModule
        title='Sensor Info'
        width={width}
        locale={locale}
        error={status}
        collapsible
        defaultCollapsed
      >
        {isLoading ? (
          <RivataLoader />
        ) : Object.keys(subscriptions).length < 1 && !isSuperAdmin ? (
          <StatusAlert
            customText='No Active Subscription Found'
            color='success'
          />
        ) : onlyIgnoredSensors ? (
          <StatusAlert customText='No Data' color='success' />
        ) : (
          <>
            <Nav tabs>{renderTabs}</Nav>
            <TabContent activeTab={activeTab}>{renderTables}</TabContent>
          </>
        )}
      </RivataModule>

      {isModalOpen && (
        <ModalLogInfo
          onClose={() => setIsModalOpen(false)}
          logsData={adminInfo}
          isOpen={isModalOpen}
          type={fetchData.logsType}
          setType={onSelectBase}
          onSelectLimit={onSelectLimit}
          date={date}
          offset={offset}
          onSubmit={onSubmit}
          logsCount={logsCount}
          resetTable={resetTable}
          formReset={formReset}
          onLogsPageChange={onLogsPageChange}
          setDate={(dateFromCalendar) =>
            setFetchData({
              ...fetchData,
              date: dateFromCalendar,
              offset: 0,
              time: { from: '00:00', to: '23:59' },
            })
          }
          time={time}
          setTime={setTime}
          onSetDefaultData={() => setFetchData(defaultData)}
        />
      )}
    </>
  )
}

export default SensorNodeInfo
