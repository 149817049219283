import {
  SET_TRESHOLDS_LOADING,
} from "../actionTypes";

const INIT_STATE = {
  isLoading: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_TRESHOLDS_LOADING:
      return {
        ...state,
        isLoading: action.payload
      }
    default:
      return state;
  }
};
