import React, { useEffect, useState } from 'react'
import { ReportParameterRowProps } from './ReportParameterRowProps'
import { Label } from 'reactstrap'
import classes from './styles.module.scss'
import api from '../../../services/api'
const AssetsRow: React.FC<ReportParameterRowProps> = (
  props: ReportParameterRowProps,
) => {
  const { value } = props
  const [displayValue, setDisplayValue] = useState<string>('All')
  useEffect(() => {
    if (
      !value ||
      (value &&
        ((Array.isArray(value) && value.length === 0) ||
          (!Array.isArray(value) && value.toLowerCase() === 'all')))
    ) {
      setDisplayValue('All')
    } else {
      const fetchAssets = async () => {
        try {
          const data = await api.getAssetsLimitedData(
            false,
            false,
            Array.isArray(value) ? value.join(',') : value.split(':').join(','),
          )

          if (data && data.length > 0) {
            setDisplayValue(
              data
                .map((item: any) => {
                  return `${item.name}(${item.vin})`
                })
                .join(', '),
            )
          }
        } catch (err) {
          console.log(err)
        }
      }
      fetchAssets()
    }
  }, [value])
  return (
    <div className={classes.reportParameterRow}>
      <Label className={classes.label}>Assets:</Label>
      <Label className={classes.value}>{displayValue}</Label>
    </div>
  )
}
export default AssetsRow
