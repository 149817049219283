import React, { useMemo, useContext } from "react";
import RivataLoader from '../../components/RivataLoader'
import RivataModule from "../../components/RivataModule";
import { composeLineChartData, composeAnnotations } from "./utils";
import LineChartGroup from "../../components/LineChartGroup";
import StatusAlert from "../../components/StatusAlert";
import { UnitsOfMeasurement } from '../../enums'
import { useTypedSelector } from "../../hooks/useTypedSelector";

import { AssetDetailsContext } from '../../pages/AssetDetails'

const LocationChart = ({ width }) => {
  const {
    gps: { isLoading, data, status },
  } = useTypedSelector(state => ({
    gps: state.assetDetails.gps
  }))


  const {
    locale,
    unitsOfMeasurement, 
    chartsXAxisLabel,
    healthColors,
    gpsColors,
    timestamp
} = useContext(AssetDetailsContext)

  
  const chartData = useMemo(() => {
    if (data && data.length && gpsColors) {
      return composeLineChartData(data, unitsOfMeasurement, gpsColors)
    }
    return null
  }, [data, unitsOfMeasurement, gpsColors])

  const chartsConfig = useMemo(() => {
    if (!chartData) return []

    const speedlabel = `Speed (${unitsOfMeasurement === UnitsOfMeasurement.imperial ? "mph" : "kmh"})`
    const elevationLabel = `Elevation (${unitsOfMeasurement === UnitsOfMeasurement.imperial ? "ft" : "m"})`

    return [
      {
        id: "speed",
        label: speedlabel,
        yAxisLabel: speedlabel,
        data: chartData.speed,
        height: 300,
        displayX: false,
        annotations: composeAnnotations("speed", +timestamp, healthColors),
      },
      {
        id: "elevation",
        label: elevationLabel,
        yAxisLabel: elevationLabel,
        data: chartData.elevation,
        height: 300,
        displayX: true,
        annotations: composeAnnotations("elevation", +timestamp, healthColors),
      }
    ]
  }, [chartData, unitsOfMeasurement, timestamp, healthColors])

  return (
    <RivataModule
      fullScreenModalModeEnabled
      title="Speed, Elevation"
      width={width}
      locale={locale}
      error={status}
      collapsible
    >
      {isLoading ? (
        <RivataLoader/>
      ) : (
        data && data.length ?
          <LineChartGroup 
            chartsXAxisLabel={chartsXAxisLabel}
            chartsData={chartsConfig}
          /> : <StatusAlert customText="No Data" color="success"/>
      )}
    </RivataModule>
  );
};

export default LocationChart;
