import React, { useMemo } from 'react'
import { SearchItemType } from '../../../components/ItemsSearch'
import ItemsSelect from '../../../components/ItemsSelect/ItemsSelect'
import ItemsSelectWithSearch from '../../../components/ItemsSelectWithSearch/ItemsSelectWithSearch'
import { EndPointConfigs } from '../../../components/BackendSearchInput/constants'
import { useTypedSelector } from '../../../hooks/useTypedSelector'


interface Props {
    disabled: boolean
    selectedGeofenceItems: Array<IShortGeofence>
    setMacList: React.Dispatch<React.SetStateAction<Array<string>>>
    onGeofencesSelect: React.Dispatch<React.SetStateAction<Array<SearchItemType>>>
}

const geofenceColumnsToShow = [{ key: "name", name: "Name" }]

const CargotagGatewayReport: React.FC<Props> = ({
    disabled,
    selectedGeofenceItems,
    setMacList,
    onGeofencesSelect,
}) => {
    const selectedCustomersList = useTypedSelector((state) => (state.common.customers.selectedCustomersList))

    const endPointConfig = useMemo(() => {
        return {
            ...EndPointConfigs.tagGeofence,
            subLabelKey: selectedCustomersList.length > 1 ? "customer" : undefined
        }
    }, [selectedCustomersList.length])

    return (
        <>
            <ItemsSelect 
                label="MACs"
                inputPlaceholder="Enter multiple cargo tag gateway MACs"
                itemLabel="MAC"
                onItemsSelect={setMacList}
                disabled={disabled}
            />

            <ItemsSelectWithSearch 
                label="Geofences"
                inputPlaceholder = "Search Geofences"
                endPointConfig={endPointConfig}
                onItemsSelect={onGeofencesSelect}
                columnsToShow={geofenceColumnsToShow}
                disabled={disabled}
                selectedItems={selectedGeofenceItems}
            />
        </>
    )
}

export default CargotagGatewayReport
