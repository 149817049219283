import React, { useEffect, useState } from 'react';
import { Form, FormGroup, Input, Button, Label, Row, Col } from 'reactstrap';

import RivataLoader from '../../components/RivataLoader';
import RivataModule from '../../components/RivataModule';

import { useActions } from '../../hooks/useActions';
import { useTypedSelector } from '../../hooks/useTypedSelector';

import './style.scss';

const DataMigration = ({ locale }) => {
    const assetId = useTypedSelector(state => state.assetAdminInfo.assetInfo.id)
    const { postMigrateHardwareData, deleteHardwareData } = useActions()

    const [asset, setAsset] = useState("")
    const [mac, setMac] = useState("");
    const [days, setDays] = useState(7);
    const [processWarnings, setProcessWarnings] = useState(false);
    const [processing, setProcessing] = useState(false)

    useEffect(() => {
        if (!assetId) return 
        setAsset(assetId)
    }, [assetId])

    const migrateHardwareData = () => {
        const data = {
            mac,
            days,
            "process_warnings": processWarnings,
            "asset_id": parseInt(asset)
        }
   
        setProcessing(true)
        postMigrateHardwareData(data)
    }

    const deleteData = () => {
        setProcessing(true)
        deleteHardwareData(parseInt(asset))
    }

    return (
        <RivataModule
            title={`Data migration tool`}
            locale={locale}
            marginTop={0}
        >
            {processing && <RivataLoader />}
            <Form>
                <Col>
                    <Row md="3">
                        <FormGroup className="w-100">
                            <Label>Asset id</Label>
                            <Input value={asset} onChange={ev => setAsset(ev.target.value)} />
                        </FormGroup>
                    </Row>
                    <Row md="3">
                        <FormGroup className="w-100">
                            <Label>Sensor MAC</Label>
                            <Input disabled={processing} placeholder="00-00-00-00-00-00" value={mac} onChange={ev => setMac(ev.target.value)} />
                        </FormGroup>
                    </Row>
                    <Row md="3">
                        <FormGroup className="w-100">
                            <Label>Days</Label>
                            <Input disabled={processing} value={days} type="number" onChange={ev => setDays(ev.target.value)} />
                        </FormGroup>
                    </Row>
                    <Row md="3">
                        <FormGroup check>
                            <Label check>
                                <Input disabled={processing} value={processWarnings} onChange={ev => setProcessWarnings(ev.target.checked)} type="checkbox" />{' '}
                                Process warnings
                            </Label>
                        </FormGroup>
                    </Row>
                </Col>
            </Form>
            {process.env.REACT_APP_STAGE === "prod" && <div className="migrate-data-notify">This is production! Do you really need to use generate button?</div>}
            <Button onClick={migrateHardwareData} className="mt-3" color="primary" disabled={processing}>Submit</Button>
            <Button onClick={deleteData} className="mt-3 ml-2" color="danger" disabled={processing}>Delete data</Button>
        </RivataModule>
    )
}

export default DataMigration;