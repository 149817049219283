import React from "react";
import { ListGroupItem, Button } from "reactstrap";
import { getFileTitle } from "./utils";
import "./styles.scss";
import { IDocType } from "../../redux/documents/types";
import { NoDataLabels } from "../../enums";

interface Props {
  document: IDocument;
  docTypes: IDocType[];
  onDelete: (document: IDocument) => void;
}

const Document: React.FC<Props> = ({ document, docTypes, onDelete }) => {
  return (
    <ListGroupItem>
      <div className="d-flex align-items-center">
        <div className="col-5">{getFileTitle(document.file_name)}</div>
        <div>{docTypes.find(docType => docType.id === document.doc_type)?.name ?? NoDataLabels.DASH}</div>
        <Button
          className="ml-auto"
          onClick={() => {
            onDelete(document);
          }}
        >
          <i className="fas fa-trash"></i>
        </Button>
      </div>
    </ListGroupItem>
  );
};

export default Document;
