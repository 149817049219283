import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input,FormGroup, Form } from "reactstrap";

interface SelectedEnviroments {
    staging: boolean
    dev: boolean
}

interface Props {
    open: boolean
    locale: Record<string, string>
    onClose: () => void
    onConfirm: (s: SelectedEnviroments) => void
}

const CopyProvisionModal = (props: Props) => {
    const { onConfirm, locale = {}, open, onClose } = props;
    const [selectedEnviroments, setSelectedEnviroments] = useState<SelectedEnviroments>({
        staging: false,
        dev: false
    });

    const onCheck = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedEnviroments({ ...selectedEnviroments, [evt.target.id]: evt.target.checked });
    }

    const onConfirmPress = () => {
        if (selectedEnviroments.staging || selectedEnviroments.dev){
            onConfirm(selectedEnviroments);
        }
    }

    return (
        <div>
            <Modal isOpen={open}>
                <ModalHeader toggle={onClose}>
                    Copy provision to: 
                </ModalHeader>
                <ModalBody>
                    <Form>

                        <FormGroup check>
                            <Label check>
                                <Input
                                    id="dev"
                                    type="checkbox"
                                    checked={selectedEnviroments.dev}
                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => onCheck(evt)}
                                />
                                Development
                            </Label>

                        </FormGroup>
                        <br/>

                        <FormGroup check>
                            <Label check>
                                <Input
                                    id="staging"
                                    type="checkbox"
                                    checked={selectedEnviroments.staging}
                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => onCheck(evt)}
                                />
                                Staging
                            </Label>

                        </FormGroup>

                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="secondary"
                        onClick={onConfirmPress}
                    >
                        {locale["ok"] || "Ok"}
                    </Button>
                    <Button color="secondary" onClick={onClose}>
                        {locale["cancel"] || "Cancel"}
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default CopyProvisionModal;
