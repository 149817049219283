import React, { useCallback } from 'react'
import { Row } from "reactstrap"

import AxleLoadCalibrationModule from '../../modules/AxleLoadCalibrationModule'
import AxleLoadCalibrationTable from '../../modules/AxleLoadCalibrationTable'

import { useTypedSelector } from '../../hooks/useTypedSelector'
import { useActions } from '../../hooks/useActions'

const CalibrateAxleLoad = ({
    locale,
    isSuperAdmin,
    subscriptions,
    isDefaultsLoading,
    selectedUnitsOfMeasurement,
    setSelectedUnitsOfMeasurement
}) => {
    const {
        axleLoadCalibration,
    } = useTypedSelector(state => ({
        axleLoadCalibration: state.axleLoadCalibration,
    }))
    const {
        setSelectedCalibrationAsset,
        postAxleLoadCalibrationData,
        fetchAxleLoadCalibrationData,
        setCalibrationDataOffset,
        setCalibrationDataLimit,
        putAxleLoadCalibrationData,
        deleteAxleLoadCalibrationData
    } = useActions()

    const onSelectAsset = useCallback((asset) => {
        setSelectedCalibrationAsset(asset)
        if (asset?.id) fetchAxleLoadCalibrationData(asset.id)
    }, [setSelectedCalibrationAsset, fetchAxleLoadCalibrationData])


    return (
        <>
            <Row>
                <AxleLoadCalibrationModule
                    isSuperAdmin={isSuperAdmin}
                    subscriptions={subscriptions}
                    isDefaultsLoading={isDefaultsLoading}
                    axleLoadAssets={axleLoadCalibration.axleLoadAssets}
                    locale={locale}
                    onSubmit={postAxleLoadCalibrationData}
                    setSelectedAsset={onSelectAsset}
                    selectedAsset={axleLoadCalibration.selectedAsset}
                    selectedUnitsOfMeasurement={selectedUnitsOfMeasurement}
                    setSelectedUnitsOfMeasurement={setSelectedUnitsOfMeasurement}
                />
            </Row>

            <Row className="mt-4">
                <AxleLoadCalibrationTable
                    {...axleLoadCalibration.calibrationData}
                    selectedUnitsOfMeasurement={selectedUnitsOfMeasurement}
                    selectedAsset={axleLoadCalibration.selectedAsset}
                    locale={locale}
                    onEdit={putAxleLoadCalibrationData}
                    onDelete={deleteAxleLoadCalibrationData}
                    setCalibrationDataOffset={setCalibrationDataOffset}
                    setCalibrationDataLimit={setCalibrationDataLimit}
                />
            </Row>
        </>
    )
}


export default CalibrateAxleLoad