import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import SubNav from '../../components/SubNav'
import ReactPdfViewer from './ReactPdfViewer'
import { useParams } from 'react-router-dom'
import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import RivataLoader from '../../components/RivataLoader'
import StatusAlert from '../../components/StatusAlert'
import { useLocation } from 'react-router-dom'

const DocumentPage = ({ locale = {} }) => {
  const location = useLocation()
  const { fetchDocuments, fetchDocumentTypes } = useActions()
  const { id, type } = useParams()
  const {
    isLoading: isLoadingDocs,
    documents,
    types: { isLoading: isLoadingTypes, docTypes },
  } = useTypedSelector((state) => ({ ...state.documents }))
  const [document, setDocument] = useState(null)
  const [fetched, setFetched] = useState(false)
  const [isPublic, setIsPublic] = useState(false)

  const isLoading = useMemo(() => {
    return isLoadingDocs || isLoadingTypes
  }, [isLoadingDocs, isLoadingTypes])

  useEffect(() => {
    const _isPublic = location.pathname.includes('/public/')
    setIsPublic(_isPublic)
    fetchDocumentTypes(_isPublic)
    fetchDocuments(null, _isPublic)
  }, [fetchDocuments, fetchDocumentTypes, location.pathname])

  useEffect(() => {
    if (documents[type]) {
      const doc =
        documents &&
        documents[type].find(
          (el) =>
            el.id === id ||
            el.doc_type === docTypes.find((docType) => docType.key === id)?.id,
        )
      setDocument(doc)
      setFetched(true)
    }
  }, [documents, type, id, docTypes])

  return (
    <>
      <SubNav
        title='Document'
        breadcrumbs={[
          { label: 'Home', path: '/dashboard' },
          { label: 'Support', path: '/support' },
          { label: document?.file_name },
        ]}
      />
      {isLoading || !fetched ? (
        <RivataLoader />
      ) : document ? (
        <ReactPdfViewer file={document.url} isPublic={isPublic} />
      ) : (
        <div className='col-12'>
          <StatusAlert statusText='Document not found' color='warning' />
        </div>
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  locale: state.whitelabel.locale,
})

export default connect(mapStateToProps)(DocumentPage)
