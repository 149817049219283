import {
    SET_TPMS_PROFILES,
    SET_TPMS_SELECTED_PROFILE,
    LOADING_TPMS_PROFILES,
} from '../actionTypes';

const initialState = {
    tpmsProfiles: [],
    selectedTpmsProfile: {},
    isLoading: false,
}

const setTpmsProfiles = (state, payload) => ({ ...state, tpmsProfiles: payload })
const setSelectedTpmsProfile = (state, payload) => ({ ...state, selectedTpmsProfile: payload })
const setLoading = (state, payload) => ({ ...state, isLoading: payload })

const tpmsProfiles = (state = initialState, action) => {
    switch (action.type) {
        case SET_TPMS_PROFILES:
            return setTpmsProfiles(state, action.payload)
        case SET_TPMS_SELECTED_PROFILE:
            return setSelectedTpmsProfile(state, action.payload)
        case LOADING_TPMS_PROFILES:
            return setLoading(state, action.payload)
        default:
            return state
    }
}

export default tpmsProfiles