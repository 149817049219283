import React from 'react'
import RivataModule from '../../components/RivataModule'
import './style.scss'
import {
  InternalScheduledReportTypes,
  ReportTypes,
  TagReportTypes,
} from '../../enums'
import ActiveWarningsReportParameters from './ReportParametersGroups/ActiveWarningsReport'
import AssetProvisioningSummaryReportParameters from './ReportParametersGroups/AssetProvisioningSummaryReport'
import AssetsInGeofenceReportParameters from './ReportParametersGroups/AssetsInGeofenceReport'
import DeviceHealthReportParameters from './ReportParametersGroups/DeviceHealthReport'
import DistanceTraveledReportParameters from './ReportParametersGroups/DistanceTraveledReport'
import DriverAppTripReportParameters from './ReportParametersGroups/DriverAppTripReport'
import DwellReportParameters from './ReportParametersGroups/DwellReport'
import GeofenceHistoryReportParameters from './ReportParametersGroups/GeofenceHistoryReport'
import NonReportingSensorsReportParameters from './ReportParametersGroups/NonReportingSensorsReport'
import NotificationHistoryReportParameters from './ReportParametersGroups/NotificationHistoryReport'
import OdometerOffsetSummaryReportParameters from './ReportParametersGroups/OdometerOffsetSummaryReport'
import TpmsReportParameters from './ReportParametersGroups/TpmsReport'
import UserLoginsReportParameters from './ReportParametersGroups/UserLoginsReport'
import WarningHistoryReportParameters from './ReportParametersGroups/WarningHistoryReport'
import LatestTagLocationReportParameters from './ReportParametersGroups/LatestTagLocationReport'
import LostTagsReportParameters from './ReportParametersGroups/LostTagsReport'
import TagHistoryDetailsReportParameters from './ReportParametersGroups/TagHistoryDetailsReport'
import InternalConmetBatteryVoltageReportParameters from './ReportParametersGroups/InternalConmetBatteryVoltageReport'
import InternalConmetDailyReportParameters from './ReportParametersGroups/InternalConmetDailyReport'
import InternalFailedNotificationHistoryReportParameters from './ReportParametersGroups/InternalFailedNotificationHistoryReport'
import InternalTpmsReportParameters from './ReportParametersGroups/InternalTpmsReport'

const closeIcon = require('../../assets/images/close.png')
interface Props {
  locale: ILocale
  showInModal?: boolean | undefined
  customFullScreenAction?: (f: boolean) => void | undefined
  selectedReport?: any | undefined
}
const ReportParametersModal: React.FC<Props> = ({
  locale,
  customFullScreenAction = (f) => {},
  selectedReport = undefined,
}) => {
  const renderParameters = () => {
    switch (selectedReport.report_name) {
      case ReportTypes.ACTIVE_WARNINGS_REPORT:
        return (
          <ActiveWarningsReportParameters
            parameters={selectedReport.parameters}
            isSchedule={selectedReport.is_schedule}
          />
        )

      case ReportTypes.ASSET_PROVISIONING_SUMMARY_REPORT:
        return (
          <AssetProvisioningSummaryReportParameters
            parameters={selectedReport.parameters}
          />
        )
      case ReportTypes.ASSETS_IN_GEOFENCE_REPORT:
        return (
          <AssetsInGeofenceReportParameters
            parameters={selectedReport.parameters}
            isSchedule={selectedReport.is_schedule}
          />
        )

      case ReportTypes.DEVICE_HEALTH_REPORT:
        return (
          <DeviceHealthReportParameters
            parameters={selectedReport.parameters}
            isSchedule={selectedReport.is_schedule}
          />
        )
      case ReportTypes.DISTANCE_TRAVELED_REPORT:
        return (
          <DistanceTraveledReportParameters
            parameters={selectedReport.parameters}
          />
        )
      case ReportTypes.DRIVER_APP_TRIP_REPORT:
        return (
          <DriverAppTripReportParameters
            parameters={selectedReport.parameters}
          />
        )
      case ReportTypes.DWELL_REPORT:
        return (
          <DwellReportParameters
            parameters={selectedReport.parameters}
            isSchedule={selectedReport.is_schedule}
          />
        )
      case ReportTypes.GEOFENCE_HISTORY_REPORT:
        return (
          <GeofenceHistoryReportParameters
            parameters={selectedReport.parameters}
          />
        )
      case ReportTypes.NON_REPORTING_SENSORS_REPORT:
        return (
          <NonReportingSensorsReportParameters
            parameters={selectedReport.parameters}
          />
        )
      case ReportTypes.NOTIFICATION_HISTORY_REPORT:
        return (
          <NotificationHistoryReportParameters
            parameters={selectedReport.parameters}
          />
        )
      case ReportTypes.ODOMETER_OFFSET_SUMMARY_REPORT:
      case ReportTypes.UTILIZATION_REPORT:
        return (
          <OdometerOffsetSummaryReportParameters
            parameters={selectedReport.parameters}
          />
        )
      case ReportTypes.TPMS_REPORT:
        return (
          <TpmsReportParameters
            parameters={selectedReport.parameters}
            isSchedule={selectedReport.is_schedule}
          />
        )
      case ReportTypes.USER_LOGINS_REPORT:
        return (
          <UserLoginsReportParameters parameters={selectedReport.parameters} />
        )
      case ReportTypes.WARNING_HISTORY_REPORT:
        return (
          <WarningHistoryReportParameters
            parameters={selectedReport.parameters}
          />
        )
      case TagReportTypes.LATEST_TAG_LOCATION_REPORT:
        return (
          <LatestTagLocationReportParameters
            parameters={selectedReport.parameters}
          />
        )
      case TagReportTypes.LOST_TAGS_REPORT:
        return (
          <LostTagsReportParameters parameters={selectedReport.parameters} />
        )
      case TagReportTypes.TAG_HISTORY_DETAILS_REPORT:
        return (
          <TagHistoryDetailsReportParameters
            parameters={selectedReport.parameters}
          />
        )
      case InternalScheduledReportTypes.INTERNAL_CONMET_BATTERY_VOLTAGE_REPORT:
      case InternalScheduledReportTypes.INTERNAL_FAILED_HARDWARE_COMMANDS_REPORT:
      case InternalScheduledReportTypes.INTERNAL_INSTALLATIONS_REPORT:
        return (
          <InternalConmetBatteryVoltageReportParameters
            parameters={selectedReport.parameters}
          />
        )
      case InternalScheduledReportTypes.INTERNAL_CONMET_DAILY_REPORT:
        return (
          <InternalConmetDailyReportParameters
            parameters={selectedReport.parameters}
          />
        )
      case InternalScheduledReportTypes.INTERNAL_FAILED_NOTIFICATION_HISTORY_REPORT:
        return (
          <InternalFailedNotificationHistoryReportParameters
            parameters={selectedReport.parameters}
          />
        )
      case InternalScheduledReportTypes.INTERNAL_TPMS_REPORT:
        return (
          <InternalTpmsReportParameters
            parameters={selectedReport.parameters}
          />
        )
      default:
        return <>Not supported</>
    }
  }
  return (
    <RivataModule
      title={'Report parameters'}
      locale={locale}
      marginTop={-1}
      filters={null}
      fullScreenModalModeEnabled
      width={4}
      forceShowInModal={true}
      customFullScreenAction={customFullScreenAction}
      customModalClass='report-parameters-modal'
      customCollapseIcon={closeIcon}
      customClass='report-parameters-body'
    >
      <>{renderParameters()}</>
    </RivataModule>
  )
}
export default ReportParametersModal
