import React, { useEffect } from "react";
import SubNav from "../../components/SubNav"
import TpmsConfigurationModule from "../../modules/TpmsConfigurationModule";
import { useActions } from "../../hooks/useActions";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import CustomerSelector from "../../modules/CustomerSelector";


const TpmsProfiles = () => {
    const { fetchTpmsProfilesPage } = useActions()
    const{isLoading, preferences, locale, common} = useTypedSelector((state)=>({
      isLoading:   state.tpmsProfiles.isLoading,
      preferences: state.auth.preferences,
      locale: state.whitelabel.locale,
      common: state.common,
    }))

    const { systemDefaults, customers, customerDefaults: { subscriptions }, isDefaultsLoading } = common
    useEffect(() => {
        if (customers.selectedCustomersList.length === 1) {
            fetchTpmsProfilesPage()
        }
    }, [customers.selectedCustomersList, fetchTpmsProfilesPage])

    return (
        <>
            <SubNav
                title="TPMS Profiles"
                breadcrumbs={[
                    { label: "Home", path: "/dashboard" },
                    { label: "TPMS Profiles" }
                ]}
            >
                <CustomerSelector/>
            </SubNav>
            <TpmsConfigurationModule
                isLoading={isLoading || customers.loading || isDefaultsLoading}
                warningSettingsDefaults={systemDefaults.tpms}
                preferences={preferences}
                locale={locale}
                subscriptions={subscriptions}
            />
        </>
    );
};



export default TpmsProfiles
