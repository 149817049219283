import { Dispatch } from 'redux'
import api from '../../services/api'
import { getStatusObj } from '../../utils/utils'
import { IgetState } from '../types'
import { DocumentsTypes, IDocType } from './types'

export const loadingDocuments = (isLoading: boolean) => ({
  type: DocumentsTypes.LOADING_DOCUMENTS,
  payload: isLoading,
})

export const loadingDocumentTypes = (isLoading: boolean) => ({
  type: DocumentsTypes.LOADING_DOCUMENT_TYPES,
  payload: isLoading,
})

export const setDocuments = (documents: { [dir: string]: IDocument[] }) => ({
  type: DocumentsTypes.SET_DOCUMENTS,
  payload: documents,
})

export const setDocumentsError = (error: boolean) => ({
  type: DocumentsTypes.SET_DOCUMENTS_ERROR,
  payload: error,
})

export const setManageDocumentsTypes = (docTypes: IDocType[]) => ({
  type: DocumentsTypes.SET_DOCUMENTS_TYPES,
  payload: docTypes,
})

export const setManageDocumentsStatus = (status: IStatus) => ({
  type: DocumentsTypes.SET_DOCUMENTS_TYPES_STATUS,
  payload: status,
})

export function fetchDocuments(params: any, isPublic: boolean = false) {
  return function (dispatch: Dispatch) {
    if (!params?.refetch) dispatch(loadingDocuments(true))
    return api
      .getDocuments(isPublic)
      .then((documents: { [dir: string]: IDocument[] }) => {
        dispatch(setDocuments(documents))
      })
      .catch(() => dispatch(setDocumentsError(true)))
      .finally(() => {
        if (!params?.refetch) dispatch(loadingDocuments(false))
      })
  }
}

export function addNewDocument(dir: string, title: string, doc_type: number) {
  return function (dispatch: Dispatch, getState: IgetState) {
    const documentsStore = getState().documents

    if (documentsStore?.documents) {
      if (!documentsStore.documents[dir]) documentsStore.documents[dir] = []

      const idx = documentsStore.documents[dir].findIndex(
        (doc) => doc.doc_type === doc_type,
      )
      if (idx >= 0) documentsStore.documents[dir].splice(idx, 1)

      documentsStore.documents[dir].push({
        file_name: title,
        url: '',
        doc_type: doc_type,
      })
      dispatch(setDocuments(documentsStore.documents))
    }
  }
}

export function deleteDocument(dir: string, title: string) {
  return function (dispatch: Dispatch, getState: IgetState) {
    return api.deleteDocument(dir, title).then(() => {
      const documentsStore = getState().documents

      if (documentsStore?.documents) {
        dispatch(
          setDocuments({
            ...documentsStore.documents,
            [dir]: documentsStore.documents[dir].filter(
              (document: any) => document.file_name !== title,
            ),
          }),
        )
      }
    })
  }
}

export function fetchDocumentTypes(isPublic: boolean = false) {
  return function (dispatch: Dispatch) {
    dispatch(loadingDocumentTypes(true))
    return api
      .getDocumentTypes(isPublic)
      .then((res: IDocType[]) => {
        dispatch(setManageDocumentsTypes(res))

        const status = getStatusObj(
          { statusCode: 201 },
          'Document types fetched',
        )
        dispatch(setManageDocumentsStatus(status))
      })
      .catch((err) => {
        dispatch(setManageDocumentsStatus(getStatusObj(err)))
      })
      .finally(() => {
        dispatch(loadingDocumentTypes(false))
      })
  }
}
