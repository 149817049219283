import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Input } from 'reactstrap'
import { composeDynamicActionsFormatter } from '../RivataGrid/cellFormatter'
import RivataGrid from '../RivataGrid'

type Props = {
  label?: string,
  inputPlaceholder?: string,
  caseSensitive?: boolean,
  itemLabel?: string,
  tableWidth?: string,
  onItemsSelect: (selectedItems: string[]) => void,
  disabled?: boolean,
}

const ItemsSelect: React.FC<Props> = ({
  label,
  inputPlaceholder = "Input",
  caseSensitive,
  itemLabel = "",
  tableWidth = "400px",
  onItemsSelect,
  disabled,
}) => {
  const [inputValue, setInputValue] = useState("")
  const [selected, setSelected] = useState<string[]>([])

  const onSelect = useCallback((item: string) => {
    setSelected((old) => [...old, item])
  }, [setSelected])

  const onDelete = useCallback((id) => {
    setSelected((old) => old.filter((i, itemId) => itemId !== id))
  }, [setSelected])

  const tableCollumns = useMemo(() => [
    {
      key: "remove",
      name: "Remove",
      minWidth: 75,
      width: 75,
      formatter: ({ row }: any) => composeDynamicActionsFormatter({ onDelete: onDelete, cellData: row })
    },
    {
      key: "value",
      name: itemLabel
    }
  ], [onDelete, itemLabel])

  const tableRows = useMemo(() => selected.map((item, id) => ({ id: id, value: item })), [selected])

  const tableHeight = useMemo(() => selected.length > 5 ? "210x" : undefined, [selected])

  useEffect(() => {
    onItemsSelect(selected)
  }, [selected, onItemsSelect])

  return (
    <div>
      {label && <label style={{ paddingTop: "20px" }}>{label}</label>}

      <div className="d-flex flex-row mb-2">
        <Input
          placeholder={inputPlaceholder}
          disabled={disabled}
          value={inputValue}
          style={{ width: tableWidth }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (!/[/:;, ]/.test(e.target.value)) //false if inputed characters / : ; , or space
              setInputValue(
                caseSensitive ? e.target.value : e.target.value.toUpperCase()
              );
          }}
          onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (
              ["Enter", " "].includes(e.key) &&
              !selected.includes(inputValue) &&
              inputValue !== ""
            ) {
              onSelect(inputValue);
              setInputValue("");
            }
          }}
        />
      </div>

      {selected.length > 0 && (
        <div className="d-flex flex-row mb-2">
          <div style={{ width: tableWidth }}>
            <RivataGrid
              columns={tableCollumns}
              rows={tableRows}
              height={tableHeight}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default ItemsSelect