import React, { useEffect } from "react";
import SubNav from '../../components/SubNav'
import Preferences from "../../modules/Preferences";
import CustomerSelector from "../../modules/CustomerSelector";

import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";

const breadcrumbs = [
  { label: "Home", path: "/dashboard" },
  { label: "User Profile" }
]

const Settings = () => {
  const { 
    preferences, 
    preferenceDefaults, 
    locale, 
    selectedCustomersList
  } = useTypedSelector((state) => ({
    preferences: state.auth.preferences,
    preferenceDefaults: state.whitelabel.preferenceDefaults,
    locale: state.whitelabel.locale,
    selectedCustomersList: state.common.customers.selectedCustomersList
  }))

  const { updatePreferences, fetchSettingsNotificationsProfiles } = useActions()
  useEffect(() => {
    if (selectedCustomersList.length === 1) fetchSettingsNotificationsProfiles()
  }, [selectedCustomersList, fetchSettingsNotificationsProfiles])

  return (
    <>
      <SubNav
        title="User Profile"
        breadcrumbs={breadcrumbs}
      >
        {preferences?.customer_ids?.length > 1 && <CustomerSelector/>}
      </SubNav>
      
      <Preferences
        preferences={preferences}
        preferenceDefaults={preferenceDefaults}
        locale={locale}
        onUpdatePreferences={updatePreferences}
      />
    </>
  );
};


export default Settings;
