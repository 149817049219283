import { IVIbrationData } from "../../redux/assetDetails/types"
import { convertDataEpochToDate, DATE_FORMAT, getClearLabel } from "../../utils"
import { composeCustomTooltip, getFilteredPositions, getUniqueDatasets } from "../../utils/chartUtils"
import { WheelOrder } from "../../enums"


interface ILine<T> {
    label: string
    position: string 
    backgroundColor: string
    borderColor: string
    showLine: boolean
    borderWidth: number
    radius: number
    fill: boolean
    data: Array<T>
    pointBackgroundColor: Array<string>
    pointBorderColor?: Array<string>
    pointStyle?: string
}

interface IPoint {
    x: any
    y: number
    displayValue: string
    displayAfterLabel?: string
    displayFooter: string
    order: number
    tooltipColor: string
}

let refYValueYLabel: Array<{ yValue: number, yLabel: string }> = []


export const composeVibrationChartData = (data: IVIbrationData, healthColors: Array<IHealthColor>, customerDefaults: any) => {
    const positions = Object.keys(data)

    if (!positions.length) return { datasets: [] }

    const filteredPositions = getFilteredPositions(positions, "smarthub")
    const healthGoodColor = healthColors.find(el => el.id === "HealthGood")?.color
    const healthWarningColor = healthColors.find(el => el.id === "HealthWarning")?.color
    const healthCritWarningColor = healthColors.find(el => el.id === "HealthCriticalWarning")?.color
    const vibrationThreshold = customerDefaults.smarthub.vibration_treshold ?? 1.5
    const criticalVibrationThreshold = customerDefaults.smarthub.critical_vibration_treshold ?? 2

    if (!healthGoodColor || !healthWarningColor || !healthCritWarningColor) {
        console.error("Missing health colors or customer defaults")

        return { datasets: [] }
    }

    filteredPositions.sort((p1, p2) => WheelOrder[p2] - WheelOrder[p1]) 

    const datasets = filteredPositions.map((position, i) => {
        const label = getClearLabel(position)

        const line: ILine<IPoint> = {
            label,
            position,
            backgroundColor: healthGoodColor,
            borderColor: healthGoodColor,
            showLine: false,
            borderWidth: 0,
            radius: 5,
            fill: false,
            data: [],
            pointBackgroundColor: [],
            pointBorderColor: [],
            pointStyle: 'circle',
        }

        let hasCritWarn = false
        let hasWarn = false

        data[position].forEach(vibData => {
            const epoch = vibData[0]
            const vibHealth = vibData[1]
            const vibBearingOutlierFlag = vibData[3] === 1
            const vibTireOutlierFlag = vibData[4] === 1

            const bearingVibrationWarn = vibBearingOutlierFlag && vibHealth >= vibrationThreshold
            const criticalBearingVibrationWarn = vibBearingOutlierFlag && vibHealth >= criticalVibrationThreshold
            const tireVibrationWarn = vibTireOutlierFlag && vibHealth >= vibrationThreshold
            const criticalTireVibrationWarn = vibTireOutlierFlag && vibHealth >= criticalVibrationThreshold

            let colorTire = healthGoodColor
            let colorBearing = healthGoodColor
            let tooltipColor = healthGoodColor
            let tireStatusText = "Normal"
            let bearingStatusText = "Normal"
            let tirePointPriority = 0
            let bearingPointPriority = 0

            if (tireVibrationWarn) {
                hasWarn = true
                colorTire = tooltipColor = healthWarningColor
                tireStatusText = "Inspect Tire"
                tirePointPriority++
            }
            if (bearingVibrationWarn) {
                hasWarn = true
                colorBearing = tooltipColor = healthWarningColor
                bearingStatusText = "Inspect Bearing"
                bearingPointPriority++
            }
            if (criticalTireVibrationWarn) {
                hasCritWarn = true
                colorTire = tooltipColor = healthCritWarningColor
                tireStatusText = "High Severity - Inspect Tire"
                tirePointPriority += 2
            }
            if (criticalBearingVibrationWarn) {
                hasCritWarn = true
                colorBearing = tooltipColor = healthCritWarningColor
                bearingStatusText = "High Severity - Inspect Bearing"
                bearingPointPriority += 2
            }

            const y = i + 1
            const x = convertDataEpochToDate(epoch, null, null, false)
            const displayFooter = x.format(DATE_FORMAT)
            const points = [
                { displayValue: `Tire Vibration: ${tireStatusText}`, color: colorTire, order: 1 },
                { displayValue: `Bearing Vibration: ${bearingStatusText}`, color: colorBearing, order: 2 },
            ]

            if (tirePointPriority > bearingPointPriority) points.reverse()

            points.forEach(el => {
                line.data.push({
                    y,
                    x,
                    displayValue: el.displayValue,
                    displayFooter,
                    order: el.order,
                    tooltipColor
                })
    
                line.pointBackgroundColor.push(el.color)
                line.pointBorderColor?.push(el.color)
            })
        })

        line.backgroundColor = hasCritWarn ? healthCritWarningColor : hasWarn ? healthWarningColor : healthGoodColor

        return line
    })

    const uniqueDatasets = getUniqueDatasets(datasets).reverse()

    refYValueYLabel = uniqueDatasets.map((line, i) => {
        return {
            yValue: line.data[0].y,
            yLabel: line.label
        }
    })

    return { datasets: uniqueDatasets }
}


export const vibrationChartOptions: any = {
    point: {
        radius: 5,
        hitRadius: 2,
        hoverRadius: 7
    },
    tooltip: {
        ...composeCustomTooltip({
            displayLabelColors: true,
            borderColor: true,
            footer: true,
            footerAlign: "right"
        }),
        usePointStyle: true,
        itemSort: (a: any, b: any) => a.raw.order - b.raw.order
    }, 
    yScale: {
        id: "y",
        beginAtZero: true,
        grace: "20%",
        title: {
            display: false,
            text: ""
        },
        ticks: {
            callback: function (value: number) { // added to make a sync between legend filter and ticks - if dataset is hidded then y label will be also hidden
                const match = refYValueYLabel.find(el => el.yValue === value)

                if (match) {
                    // @ts-ignore
                    const legendItems = this.chart.boxes.find(el => !!el.legendItems).legendItems

                    if (!legendItems) {
                        console.error("not found legend items in ticks generator")

                        return null
                    }
  
                    const legendItem = legendItems.find((el: any) => el.text === match.yLabel)

                    if (!legendItem || legendItem.hidden) return null

                    return match.yLabel
                }

                return null // return null means that y tick line won't be displayed
            },
        }
    }
}
