import React from 'react'
import ActionWithTooltip from './ActionWithToolip';
import './style.scss'

export interface ITooltipText {
    deleteTooltipText?: string | null
    editTooltipText?: string | null
    customAction1TooltipText?: string | null
    customAction2TooltipText?: string | null
}

interface Props {
    disabled?: boolean
    customAction1Icon?: string
    customAction2Icon?: string
    uniqueKey?: string | number,
    tooltipTexts?: ITooltipText
    onDelete?: (...args: Array<any>) => void
    onUpdate?: (...args: Array<any>) => void
    onCustomAction1?: ((...args: Array<any>) => void) | null
    onCustomAction2?: ((...args: Array<any>) => void) | null,
    disabledSpecific?: {
        delete?: boolean
        edit?: boolean
        custom1?: boolean
        custom2?: boolean
    }
}

const TableActions: React.FC<Props> = ({
    disabled,
    customAction1Icon = "far fa-plus-square fa-lg",
    customAction2Icon = "fas fa-plus-circle fa-lg",
    uniqueKey = "",
    tooltipTexts = {
        deleteTooltipText: null,
        editTooltipText: null,
        customAction1TooltipText: null,
        customAction2TooltipText: null
    },
    onDelete,
    onUpdate,
    onCustomAction1,
    onCustomAction2,
    disabledSpecific,
}) => {
    return (
        <div className='actions__cell'>
            {onDelete &&
                <div className='action-icon__wrapper'>
                    <ActionWithTooltip
                        disabled={disabled || disabledSpecific?.delete}
                        onAction={onDelete}
                        tooltipTarget={"delete" + uniqueKey}
                        tooltipText={tooltipTexts.deleteTooltipText}
                        className={"fas fa-times fa-lg"}
                    />
                </div>
            }
            {onUpdate &&
                <div className='action-icon__wrapper'>
                    <ActionWithTooltip
                        disabled={disabled || disabledSpecific?.edit}
                        onAction={onUpdate}
                        tooltipTarget={"edit" + uniqueKey}
                        tooltipText={tooltipTexts.editTooltipText}
                        className={"far fa-edit fa-lg"}
                    />
                </div>
            }
            {onCustomAction1 &&
                <div className='action-icon__wrapper'>
                    <ActionWithTooltip
                        disabled={disabled || disabledSpecific?.custom1}
                        onAction={onCustomAction1}
                        tooltipTarget={"customaction1" + uniqueKey}
                        tooltipText={tooltipTexts.customAction1TooltipText}
                        className={customAction1Icon}
                    />
                </div>
            }

            {onCustomAction2 &&
                <div className='action-icon__wrapper'>
                    <ActionWithTooltip
                        disabled={disabled || disabledSpecific?.custom2}
                        onAction={onCustomAction2}
                        tooltipTarget={"customaction2" + uniqueKey}
                        tooltipText={tooltipTexts.customAction2TooltipText}
                        className={customAction2Icon}
                    />
                </div>
            }

        </div>
    )
}

export default TableActions