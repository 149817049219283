import React, { useState, useCallback, useRef } from 'react'
import { Table } from 'reactstrap'

import RivataPagination from '../RivataPagination'
import LimitDropdown from '../LimitDropdown'
import RivataLoader from '../RivataLoader'
import StatusAlert from '../StatusAlert'
import Thead from './Thead'
import Tr from './Tr'

import { getCurrentEpoch } from '../../utils'
import { makeScrollToElementWithOffset } from '../../utils/utils'

import './styles.scss'
const limitItems = [{ item: 30 }, { item: 60 }, { item: 90 }]

const RivataTpmsTable = ({
  tpmsStatusAssets,
  defaults,
  preferences,
  isShowingLimit,
  onPageChange,
  onSelectLimit,
  onSort,
  columnsToShow,
  setColumnsOrder,
}) => {
  const scrollTarget = useRef(null)
  const [sortColumn, setSortColumn] = useState(null)

  const sortHandler = useCallback(
    (columnId, direction) => {
      onSort(columnId, direction)
    },
    [onSort],
  )

  const handlePageChange = useCallback(
    (newPage) => {
      onPageChange(newPage * tpmsStatusAssets.limit)

      makeScrollToElementWithOffset(scrollTarget, -270)
    },
    [tpmsStatusAssets.limit, onPageChange],
  )

  const handleLimitChange = useCallback(
    (limit) => {
      onSelectLimit(limit)
      makeScrollToElementWithOffset(scrollTarget, -270)
    },
    [onSelectLimit],
  )

  const showPagination =
    isShowingLimit &&
    tpmsStatusAssets.totalCount > 0 &&
    tpmsStatusAssets.data.length > 0

  return (
    <div ref={scrollTarget} className='tpms-table'>
      {tpmsStatusAssets.isLoading && <RivataLoader />}

      {tpmsStatusAssets.data.length > 0 ? (
        <>
          {showPagination && (
            <div className='d-flex'>
              <RivataPagination
                totalCount={tpmsStatusAssets.totalCount}
                pageLimit={tpmsStatusAssets.limit}
                currentPage={tpmsStatusAssets.offset / tpmsStatusAssets.limit}
                setCurrentPage={handlePageChange}
              />
              <LimitDropdown
                setPageLimit={handleLimitChange}
                pageLimit={tpmsStatusAssets.limit}
                limitDropdownItems={limitItems}
              />
            </div>
          )}

          <Table>
            <Thead
              columns={tpmsStatusAssets.columns}
              onSort={sortHandler}
              sortColumn={sortColumn}
              setSortColumn={(id) => setSortColumn(id)}
              columnsToShow={columnsToShow}
              setColumnsOrder={setColumnsOrder}
            />

            {tpmsStatusAssets.data.map((asset) => (
              <Tr
                key={asset.vin}
                defaults={defaults}
                asset={asset}
                columns={tpmsStatusAssets.columns}
                preferences={preferences}
                columnsToShow={columnsToShow}
              />
            ))}
          </Table>

          {showPagination && (
            <div className='d-flex justify-content-end'>
              <RivataPagination
                totalCount={tpmsStatusAssets.totalCount}
                pageLimit={tpmsStatusAssets.limit}
                currentPage={tpmsStatusAssets.offset / tpmsStatusAssets.limit}
                setCurrentPage={handlePageChange}
              />
              <LimitDropdown
                setPageLimit={handleLimitChange}
                pageLimit={tpmsStatusAssets.limit}
                limitDropdownItems={limitItems}
              />
            </div>
          )}
        </>
      ) : tpmsStatusAssets.error.statusCode === 200 ? (
        <StatusAlert
          customText={tpmsStatusAssets.error.message}
          color='success'
        />
      ) : !defaults.subscriptions?.TIRE_SENSOR ||
        defaults.subscriptions?.TIRE_SENSOR?.valid_to < getCurrentEpoch() ? (
        <StatusAlert
          customText={'No Active Subscription Found'}
          color='success'
        />
      ) : (
        <StatusAlert customText='No Data' color='success' />
      )}
    </div>
  )
}

export default RivataTpmsTable
