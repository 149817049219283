import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Button } from 'reactstrap'

import DatesDropdown from '../../components/DatesDropdown'
import RivataModule from '../../components/RivataModule'
import AcknowledgeButton from '../../components/AcknowledgeButton'
import CancelWarningsButton from '../../components/CancelWarningsButton'
import RivataPagination from '../../components/RivataPagination'
import LimitDropdown from '../../components/LimitDropdown'
import RivataLoader from '../../components/RivataLoader'
import WarningsGrid from './WarningsGrid'
import StatusAlert from '../../components/StatusAlert'

import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'

import { getClearLabel } from '../../utils'

import { AssetDetailsContext } from '../../pages/AssetDetails'
import {
  makeScrollToElementWithOffset,
  saveGoogleAnalyticsEvent,
} from '../../utils/utils'
import DropdownItemSelector from '../../componentsV2/DropdownItemSelector'
import { cloneDeep, isArray, uniq } from 'lodash'
import {
  warningStatusItems,
  warningTypeItems,
  smarthubWarningTypeItems,
  tpmsWarningTypeItems,
  linePressureWarningTypeItems,
  sensorTypeItems,
  gatewayWarningTypesItems,
} from './filterConfigs'
import { MenuItemType } from '../../componentsV2/Menu/MenuItem'
import { SubscriptionTypes } from '../../enums'

import './styles.scss'
import SensorTypeFilter from '../../componentsV2/SensorTypeFilter'
import HealthStatusFilter from '../../componentsV2/HealthStatusFilter'

export type DropdownFilterType =
  | 'warning_status'
  | 'sensor_type'
  | 'warning_type'
  | 'position_type'

interface SelectedFiltersObject {
  warning_status: MenuItemType[]
  sensor_type: MenuItemType[]
  warning_type: MenuItemType[]
  position_type: MenuItemType[]
}

const initialSelection: SelectedFiltersObject = {
  warning_status: [warningStatusItems[0]],
  sensor_type: [],
  warning_type: [],
  position_type: [],
}

interface Props {
  width: number
  vin: string
  showDatesDropdown?: boolean
}

interface DropDownObject {
  warningStatusItems: MenuItemType[]
  sensorTypeItems: MenuItemType[]
  warningTypeItems: MenuItemType[]
  positionTypeItems: MenuItemType[]
}

const RecentWarnings: React.FC<Props> = ({ width, vin }) => {
  const {
    subscriptions,
    timezone,
    recentWarnings: {
      isLoading,
      data,
      totalCount,
      acknowledgedWarningsCount,
      loadingWarningAcknowledge,
      limit,
      offset,
      status,
      showAllWarnings,
      days,
    },
  } = useTypedSelector((state) => ({
    subscriptions: state.common.customerDefaults.subscriptions,
    timezone: state.auth.preferences.timezone,
    recentWarnings: state.assetDetails.recentWarnings,
  }))
  const {
    setAssetDetailsWarningsOffset,
    setAssetDetailsWarningsLimit,
    setAssetDetailsWarningsDays,
    setAssetDetailsWarningsFilter,
    postAssetDetailsWarningsAcknowledge,
    setAssetDetailsWarningsShowAll,
    postAssetDetailsCancelWarnings,
    fetchAssetDetailsWarnings,
  } = useActions()

  const {
    locale,
    assetInfoData,
    isSuperAdmin,
    isAdmin,
    unitsOfMeasurement,
    healthColors,
    userName,
  } = useContext(AssetDetailsContext)

  const assetId = assetInfoData?.id

  const scrollTarget = useRef(null)

  const [daysInputDisabled, setDaysInputDisabled] = useState(false)
  const [checked, setChecked] = useState<Array<string>>([])
  const [dropdownItems, setDropdownItems] = useState<DropDownObject>({
    warningStatusItems: cloneDeep(warningStatusItems),
    sensorTypeItems: cloneDeep(sensorTypeItems),
    warningTypeItems: cloneDeep(warningTypeItems),
    positionTypeItems: [],
  })
  const [selectedItems, setSelectedItems] = useState<
    Record<DropdownFilterType, Array<MenuItemType>>
  >(cloneDeep(initialSelection))

  useEffect(() => {
    setAssetDetailsWarningsFilter(cloneDeep(initialSelection))
  }, [setAssetDetailsWarningsFilter])

  const getSensorPositions = useCallback(() => {
    const allSensors = [
      'smarthub_sensors',
      'tpms',
      'line_pressure',
      'axle_load',
    ]
    let positions: string[] = []

    if (assetInfoData) {
      const sensorInfo: any = assetInfoData.sensor_info
      if (sensorInfo) {
        if (selectedItems.sensor_type.length) {
          const sensors = selectedItems.sensor_type.map((s) => s.id)

          sensors.forEach((sens) => {
            let sensorName = sens

            switch (sens) {
              case 'hub_sensor':
                sensorName = 'smarthub_sensors'
                break
              case 'tire_sensor':
                sensorName = 'tpms'
                break
            }

            if (sensorInfo[sensorName]) {
              if (isArray(sensorInfo[sensorName])) {
                positions.push(
                  ...sensorInfo[sensorName].map(
                    (s: { position: any }) => s.position,
                  ),
                )
              } else if (sensorInfo[sensorName].position) {
                positions.push(sensorInfo[sensorName].position)
              }
            }
          })
        } else {
          allSensors.forEach((sens) => {
            if (sensorInfo[sens]) {
              if (isArray(sensorInfo[sens])) {
                positions.push(
                  ...sensorInfo[sens].map((s: { position: any }) => s.position),
                )
              } else if (sensorInfo[sens].position) {
                positions.push(sensorInfo[sens].position)
              }
            }
          })
        }
      }
    }
    return uniq(positions)
  }, [assetInfoData, selectedItems.sensor_type])

  useEffect(() => {
    let sensorInfo = assetInfoData?.sensor_info
    let allowedSensorTypes: Array<string> = []

    if (sensorInfo) {
      if (!isSuperAdmin) {
        if (subscriptions) {
          Object.keys(subscriptions).forEach((sub: string) => {
            switch (sub) {
              case SubscriptionTypes.smarthub:
                if (sensorInfo?.smarthub_sensors) {
                  allowedSensorTypes.push('hub_sensor')
                }
                break
              case SubscriptionTypes.tpms:
                if (sensorInfo?.tpms) {
                  allowedSensorTypes.push('tire_sensor')
                }
                break
              case SubscriptionTypes.axleLoad:
                if (sensorInfo?.axle_load) {
                  allowedSensorTypes.push('axle_load')
                }
                break
              case SubscriptionTypes.linePressure:
                if (sensorInfo?.line_pressure) {
                  allowedSensorTypes.push('line_pressure')
                }
                break
              // case SubscriptionTypes.gateway:
              //     if (sensorInfo?.gateways) {
              //         allowedSensorTypes.push("gateways");
              //     }
              //     break;
            }
          })
        }
      } else {
        if (sensorInfo) {
          if (sensorInfo?.smarthub_sensors) {
            allowedSensorTypes.push('hub_sensor')
          }
          if (sensorInfo?.tpms) {
            allowedSensorTypes.push('tire_sensor')
          }
          if (sensorInfo?.axle_load) {
            allowedSensorTypes.push('axle_load')
          }
          if (sensorInfo?.line_pressure) {
            allowedSensorTypes.push('line_pressure')
          }
        }
      }
    }
    setDropdownItems((prev) => {
      return {
        ...prev,
        sensorTypeItems: sensorTypeItems.filter((sensor) =>
          allowedSensorTypes.includes(sensor.id),
        ),
      }
    })
  }, [isSuperAdmin, subscriptions, assetInfoData])

  useEffect(() => {
    const positions = getSensorPositions()

    setDropdownItems((prev) => {
      return {
        ...prev,
        positionTypeItems: positions.map((p: string) => {
          return {
            id: p,
            label: getClearLabel(p),
            type: 'checkbox',
            markType: 'check',
            checked: false,
            parentId: null,
          }
        }),
      }
    })
  }, [assetInfoData, getSensorPositions])

  useEffect(() => {
    const positions = getSensorPositions()

    const warningTypes: Array<MenuItemType> = []

    let sensors = selectedItems.sensor_type?.length
      ? selectedItems.sensor_type.map((s) => s.id)
      : dropdownItems.sensorTypeItems.map((s) => s.id)
    sensors.forEach((sensor) => {
      switch (sensor) {
        case 'hub_sensor':
          warningTypes.push(...smarthubWarningTypeItems)
          break
        case 'tire_sensor':
          warningTypes.push(...tpmsWarningTypeItems)
          break
        case 'line_pressure':
          warningTypes.push(...linePressureWarningTypeItems)
          break
      }
    })
    
    warningTypes.push(...gatewayWarningTypesItems)

    setDropdownItems((prev) => {
      return {
        ...prev,
        positionTypeItems: positions.map((p: string) => {
          return {
            id: p,
            label: getClearLabel(p),
            type: 'checkbox',
            markType: 'check',
            checked: false,
            parentId: null,
          }
        }),
        warningTypeItems: warningTypes,
      }
    })

    setSelectedItems((prev) => {
      return {
        ...prev,
        warning_type: [],
        position_type: [],
      }
    })
  }, [
    selectedItems.sensor_type,
    dropdownItems.sensorTypeItems,
    getSensorPositions,
  ])

  useEffect(() => {
    if (assetId) fetchAssetDetailsWarnings()
  }, [assetId, fetchAssetDetailsWarnings])

  useEffect(() => {
    setDaysInputDisabled(
      selectedItems.warning_status.length === 1 &&
        selectedItems.warning_status.findIndex(
          (item) => item.id === warningStatusItems[0].id,
        ) !== -1,
    )
  }, [selectedItems])

  const handlePageChange = useCallback(
    (newPage) => {
      setAssetDetailsWarningsOffset(newPage * limit)
      makeScrollToElementWithOffset(scrollTarget, -250)
    },
    [limit, setAssetDetailsWarningsOffset],
  )

  const handleLimitChange = useCallback(
    (limit) => {
      setAssetDetailsWarningsLimit(limit)
      makeScrollToElementWithOffset(scrollTarget, -250)
    },
    [setAssetDetailsWarningsLimit],
  )

  const disableCancelBtn = useMemo(() => {
    return checked.length === 0
  }, [checked.length])

  const onDropdownItemSelect = (id: string, items: Array<MenuItemType>) => {
    saveGoogleAnalyticsEvent(`recent_warning_filter_select`, { filter: id })

    const checked = items.filter((el) => el.checked)
    const newSelectedItems = { ...selectedItems, [id]: checked }

    setSelectedItems(newSelectedItems)
    setAssetDetailsWarningsFilter(newSelectedItems)
  }

  const warningStatusLabel =
    'Warning Status' +
    (selectedItems.warning_status.length > 0
      ? `: ${selectedItems.warning_status.length} selected`
      : '')
  const positionTypeLabel =
    'Position' +
    (selectedItems.position_type.length > 0
      ? `: ${selectedItems.position_type.length} selected`
      : '')

  return (
    <RivataModule
      title={'Recent Warnings'}
      locale={locale}
      width={width}
      collapsible
      filters={
        <>
          <DatesDropdown
            initial={days}
            locale={locale}
            disabled={daysInputDisabled}
            onSelect={(date: number) => {
              if (days !== date) setAssetDetailsWarningsDays(date)
            }}
          />

          <DropdownItemSelector
            id='warning_status'
            filterClassName='mr-2'
            filterBtnLabel={warningStatusLabel}
            items={dropdownItems.warningStatusItems}
            onItemsChange={onDropdownItemSelect}
          />

          <SensorTypeFilter
            onDropdownItemSelect={onDropdownItemSelect}
            overrideDefaultItems={dropdownItems.sensorTypeItems}
          />

          <HealthStatusFilter
            id='warning_type'
            className='mr-2'
            overrideDefaultItems={dropdownItems.warningTypeItems}
            onDropdownItemSelect={onDropdownItemSelect}
            menuClassName='warning-type-menu'
            ignoreParrentFilterCheck={true}
          />

          <DropdownItemSelector
            id='position_type'
            filterClassName='mr-2'
            filterBtnLabel={positionTypeLabel}
            items={dropdownItems.positionTypeItems}
            onItemsChange={onDropdownItemSelect}
            menuClassName='position-type-menu'
          />
          {/* <RivataSearch
                        onFilter={(filter) => setAssetDetailsWarningsFilter(filter)}
                        onSelectSearchField={(id) => setSearchColumn(id)}
                        searchFields={searchFields}
                        locale={locale}
                        subscriptions={subscriptions}
                        searchColumn={searchColumn}
                        warningPositions={warningPositions}
                        preventFilterClear={true}
                        onInputValueChange={setFilterInputValue}
                        defaultSelected={filter.filterData}
                        isSuperAdmin={isSuperAdmin} 
                        clearFilter={undefined} 
                        clearInput={undefined} 
                        isSingleDropItem={undefined}
                    />  */}
        </>
      }
    >
      <div ref={scrollTarget} className='d-flex justify-content-between'>
        <div className='d-flex'>
          {isAdmin && (
            <>
              {data.length > 0 && (
                <AcknowledgeButton
                  btnClassName='mr-2 border mb-2'
                  disabled={loadingWarningAcknowledge || checked.length === 0}
                  onAcknowledge={async () => {
                    setChecked([])

                    if (data.length === checked.length) {
                      let newOffset = offset - limit

                      if (newOffset < 0) newOffset = 0

                      setAssetDetailsWarningsOffset(newOffset + limit)
                      postAssetDetailsWarningsAcknowledge(checked, newOffset)
                    } else {
                      postAssetDetailsWarningsAcknowledge(checked)
                    }
                  }}
                />
              )}

              {acknowledgedWarningsCount !== 0 && (
                <div>
                  <Button
                    size='md'
                    className='mr-2 border mb-2'
                    onClick={() => {
                      setAssetDetailsWarningsShowAll(!showAllWarnings)
                      setAssetDetailsWarningsOffset(0)
                    }}
                  >
                    {showAllWarnings
                      ? 'Hide Acknowledged Warnings'
                      : 'Show Acknowledged Warnings'}
                  </Button>
                </div>
              )}
            </>
          )}

          {isSuperAdmin && data.length > 0 && (
            <CancelWarningsButton
              btnClassName='mr-2 border mb-2 btn-danger'
              disabled={disableCancelBtn}
              onCancel={() => {
                setChecked([])
                postAssetDetailsCancelWarnings({ keys: checked })
              }}
            />
          )}
        </div>
      </div>

      {data.length > 0 && (
        <div className='d-flex'>
          <RivataPagination
            totalCount={totalCount}
            pageLimit={limit}
            setCurrentPage={handlePageChange}
            currentPage={offset / limit}
          />

          <LimitDropdown pageLimit={limit} setPageLimit={handleLimitChange} />
        </div>
      )}

      {isLoading && <RivataLoader />}

      {status.statusCode >= 400 ? (
        <StatusAlert
          statusCode={status.statusCode}
          statusText={status.message}
          customText={undefined}
        />
      ) : data.length === 0 ? (
        <StatusAlert
          customText='No Data'
          color='success'
          statusCode={undefined}
          statusText={undefined}
        />
      ) : (
        <WarningsGrid
          locale={locale}
          isSuperAdmin={isSuperAdmin}
          isAdmin={isAdmin}
          vin={vin}
          days={days}
          timezone={timezone}
          unitsOfMeasurement={unitsOfMeasurement}
          healthColors={healthColors}
          data={data}
          checked={checked}
          setChecked={setChecked}
          userName={userName}
        />
      )}

      {data.length > 0 && (
        <div className='d-flex justify-content-end'>
          <RivataPagination
            totalCount={totalCount}
            pageLimit={limit}
            setCurrentPage={handlePageChange}
            currentPage={offset / limit}
          />

          <LimitDropdown pageLimit={limit} setPageLimit={handleLimitChange} />
        </div>
      )}
    </RivataModule>
  )
}

export default RecentWarnings
