import React from "react"
import { Col } from "reactstrap"
import { EndPointConfigs } from "../../../../components/BackendSearchInput/constants"

import "./style.scss"
import ItemsSelectWithSearch from "../../../../components/ItemsSelectWithSearch/ItemsSelectWithSearch"
import { SearchItemType } from "../../../../components/ItemsSearch"

interface Props {
    setProfileAssets: React.Dispatch<React.SetStateAction<SearchItemType[]>>
    setIsFormTouched: React.Dispatch<React.SetStateAction<boolean>>
    profileGeofences: Array<SearchItemType>
}

const NotificationGeofences: React.FC<Props> = ({
    setIsFormTouched,
    setProfileAssets,
    profileGeofences
}) => {
    const columnsToShow = [{ key: "name", name: "Name" }]

    const handleMenuSelect = (el: any) => {
        setIsFormTouched(true)
        setProfileAssets(el)
    }

    return (
        <div>
            <Col md="12" className="mb-3">
                <ItemsSelectWithSearch
                    label="Geofences"
                    inputPlaceholder="Search Geofences"
                    endPointConfig={EndPointConfigs.geofence}
                    onItemsSelect={handleMenuSelect}
                    columnsToShow={columnsToShow}
                    disabled={false}
                    selectedItems={profileGeofences}
                />
            </Col>
        </div>
    )
}

export default NotificationGeofences
