import { IColumn, IRow } from '../../../components/RivataGrid'
import { convertDataEpochToDate, getClearLabel } from '../../../utils'

interface IgeofencesReportColumns {
  (columns: Array<string>): Array<IColumn>
}

export const geofencesReportColumns: IgeofencesReportColumns = (columns) => {
  const parsedColumns: Array<IColumn> = []
  columns.forEach((key) => {
    if (key === 'Geofence') {
      parsedColumns.push({
        key,
        name: getClearLabel(key),
        minWidth: 100,
        width: 200,
      })
      return
    } else if (key === 'vin') {
      parsedColumns.push({
        key,
        name: key.toUpperCase(),
        minWidth: 100,
        width: 200,
      })
      return
    } else if (key === 'Make') {
      parsedColumns.push({
        key,
        name: getClearLabel(key),
        minWidth: 100,
        width: 200,
      })
      return
    } else if (key === 'Date Time') {
      parsedColumns.push({
        key,
        name: getClearLabel(key),
        minWidth: 100,
        width: 200,
      })
      return
    } else if (key === 'Event') {
      parsedColumns.push({
        key,
        name: getClearLabel(key),
        minWidth: 100,
        width: 100,
      })
      return
    } else if (key === 'Timezone') {
      parsedColumns.push({
        key,
        name: getClearLabel(key),
        minWidth: 100,
        width: 100,
      })
      return
    }
    parsedColumns.push({
      key,
      name: getClearLabel(key),
      minWidth: 100,
      width: 200,
    })
  })

  return parsedColumns
}

interface IGeofencesReportRows {
  (columns: Array<string>, rows: Array<IRow>, timezone: string): Array<IRow>
}

export const geofencesReportRows: IGeofencesReportRows = (
  columns,
  rows,
  timezone,
) => {
  const parsedRows = rows.map((item) => {
    const row: IRow = {
      ...item,
    }
    row['Timezone'] = timezone
    row['Date/Time'] =
      row['Date Time'] &&
      convertDataEpochToDate(+row['Date Time'], null, null, true)

    for (let i = 0; i < columns.length; i++) {
      if (!row[columns[i]]) {
        row[columns[i]] = '---'
      }
    }

    return row
  })

  return parsedRows
}
