import { useEffect, useState } from 'react'
import { NoDataLabels } from '../../enums'

export const useTableColumns = () => {
    const [columns, setColumns] = useState([])

    useEffect(() => {
        const columns = [
            {
                label: "Id",
                id: "id"
            },
            {
                label: "Hardware Mac",
                id: "hardware_mac",
            },
            {
                label: "Geofence Name",
                id: "geofence_name"
            },
            {
                label: "Event Type",
                id: "event_type"
            },
            {
                label: 'Entry Event Id',
                id: 'entry_event_id'
            },
            {
                label: 'Seconds in geofence',
                id: 'seconds_in_geofence'
            },
            {
                label: "Event Date",
                id: "event_date"
            },
            {
                label: 'Created Date',
                id: 'created_date'
            }
        ]

        setColumns(columns)
    }, [])

    return columns;
}

export const useTableRows = (data) => {
    const [rows, setRows] = useState([])

    useEffect(() => {
        const d = data.map((row) => {
            return {
                columns: [
                    {
                        id: row.id,
                        type: "text",
                        label: row.id,
                        columnId: "id"
                    },
                    {
                        id: row.id,
                        type: "text",
                        label: row.mac,
                        columnId: "hardware_mac"
                    },
                    {
                        id: row.id,
                        type: "text",
                        label: row.geofence_name,
                        columnId: "geofence_name"
                    },
                    {
                        id: row.id,
                        type: "text",
                        label: row.event_type,
                        columnId: "event_type"
                    },
                    {
                        id: row.id,
                        type: "text",
                        label: row.event_info?.entry_event_id || NoDataLabels.DASH,
                        columnId: "entry_event_id"
                    },
                    {
                        id: row.id,
                        type: "text",
                        label: row.event_info?.seconds_in_geofence || NoDataLabels.DASH,
                        columnId: "seconds_in_geofence"
                    },
                    {
                        id: row.id,
                        type: "text",
                        label: row.formatted_event_datetime,
                        columnId: "event_date"
                    },
                    {
                        id: row.id,
                        type: "text",
                        label: row.formatted_created_datetime,
                        columnId: "created_date"
                    },
                ],
                id: row.id
            }
        })
        setRows(d);
    }, [data]);

    return rows;
}