import { MenuItemType } from "../../componentsV2/Menu/MenuItem";

export const warningStatusItems: Array<MenuItemType> = [
    { id: "active", label: "Active", type: "checkbox", markType: "check", checked: true, parentId: null },
    { id: "recovered", label: "Recovered", type: "checkbox", markType: "check", checked: false, parentId: null },
    { id: "canceled", label: "Cancelled", type: "checkbox", markType: "check", checked: false, parentId: null },
]
export const warningTypeItems: Array<MenuItemType> = [
    { id: "no_status", label: "No Status", type: "checkbox", markType: "check", checked: false, parentId: null },
    { id: "tire_pressure", label: "Tire Pressure", type: "checkbox", markType: "check", checked: false, parentId: null },
    { id: "hub_vibration", label: "Hub Vibration", type: "checkbox", markType: "check", checked: false, parentId: null },
    { id: "tire_vibration", label: "Tire Vibration", type: "checkbox", markType: "check", checked: false, parentId: null },
    { id: "bearing_vibration", label: "Bearing Vibration", type: "checkbox", markType: "check", checked: false, parentId: null },
    { id: "hub_temperature", label: "Hub Temperature", type: "checkbox", markType: "check", checked: false, parentId: null },
    { id: "line_pressure", label: "Line Pressure", type: "checkbox", markType: "check", checked: false, parentId: null },
]

export const noStatusWarningTypeItem: MenuItemType = { 
    id: "no_status", label: "No Status", type: "checkbox", markType: "check", checked: false, parentId: null 
}

export const smarthubWarningTypeItems: Array<MenuItemType> = [
    { id: "hub_vibration", label: "Hub Vibration", type: "checkbox", markType: "check", checked: false, parentId: null },
    { id: "tire_vibration", label: "Tire Vibration", type: "checkbox", markType: "check", checked: false, parentId: null },
    { id: "bearing_vibration", label: "Bearing Vibration", type: "checkbox", markType: "check", checked: false, parentId: null },
    { id: "hub_temperature", label: "Hub Temperature", type: "checkbox", markType: "check", checked: false, parentId: null }
]

export const tpmsWarningTypeItems: Array<MenuItemType> = [
    { id: "tire_pressure", label: "Tire Pressure", type: "checkbox", markType: "check", checked: false, parentId: null },
    { id: 'fast_leak', label: 'Pressure Fast Leak', type: "checkbox", markType: "check", checked: false, parentId: null }
]

export const linePressureWarningTypeItems: Array<MenuItemType> = [
    { id: "line_pressure", label: "Line Pressure", type: "checkbox", markType: "check", checked: false, parentId: null },
]

export const gatewayWarningTypesItems: Array<MenuItemType> = [
    { id: "asset_speed", label: "Asset Speed", type: "checkbox", markType: "check", checked: false, parentId: null },
    { id: "asset_state", label: "Asset State", type: "checkbox", markType: "check", checked: false, parentId: null },
]

export const sensorTypeItems: Array<MenuItemType> = [
    { id: "hub_sensor", label: "SmartHub", type: "checkbox", markType: "check", checked: false, parentId: null },
    { id: "tire_sensor", label: "TPMS", type: "checkbox", markType: "check", checked: false, parentId: null },
    { id: "line_pressure", label: "Line Pressure", type: "checkbox", markType: "check", checked: false, parentId: null },
    { id: "axle_load", label: "Axle Load", type: "checkbox", markType: "check", checked: false, parentId: null },
]