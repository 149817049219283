import React, { useCallback } from 'react'
import { SearchItemType } from '../../../components/ItemsSearch';
import ItemsSelectWithSearch from '../../../components/ItemsSelectWithSearch/ItemsSelectWithSearch';
import { EndPointConfigs } from '../../../components/BackendSearchInput/constants';
import DatesDropdown from '../../../components/DatesDropdown';

type IFailedNotificationHistoryReportForm = {
    disabled: boolean;
    setUsernames: (list: Object[]) => void;
    selectedUsersItems: Array<SearchItemType>;
    onSelectDays: (date: number) => void;
    locale: Object;
}

const usersColumnsToShow = [
    { key: "username", name: "Username" },
    { key: "first_name", name: "First Name" },
    { key: "last_name", name: "Last Name" },
    { key: "email", name: "Email" },
]

const FailedNotificationHistoryReportForm: React.FC<IFailedNotificationHistoryReportForm> = ({
    disabled,
    setUsernames,
    selectedUsersItems,
    onSelectDays,
    locale
}) => {
    const onUsersSelect = useCallback((selectedUsers: SearchItemType[]) => {
        setUsernames(selectedUsers)
    }, [setUsernames])

    return (
        <>

            <label>Select Date Range</label>
            <div className="d-flex flex-row">
                <DatesDropdown
                    onSelect={onSelectDays}
                    locale={locale}
                    includeOneDay={true}
                />
            </div>
            <ItemsSelectWithSearch
                label="Users"
                inputPlaceholder="Search Users"
                endPointConfig={EndPointConfigs.user}
                onItemsSelect={onUsersSelect}
                columnsToShow={usersColumnsToShow}
                disabled={disabled}
                tableWidth="800px"
                selectedItems={selectedUsersItems}
            />
        </>
    )
}

export default FailedNotificationHistoryReportForm
