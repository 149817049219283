import React from 'react'
import { ReportParameterGroupProps } from './ReportParameterGroupProps'
import AssetsRow from '../ReportParametersRows/AssetsRow'
import DateRangeRow from '../ReportParametersRows/DateRangeRow'
import UsersRow from '../ReportParametersRows/UsersRow'
import AssetGroupsRow from '../ReportParametersRows/AssetGroupsRow'
import NotificationTypesRow from '../ReportParametersRows/NotificationTypesRow'

const NotificationHistoryReportParameters: React.FC<
  ReportParameterGroupProps
> = (props: ReportParameterGroupProps) => {
  return (
    <>
      <DateRangeRow
        value={`${props.parameters.epoch_from}-${props.parameters.epoch_to}`}
      />
      <NotificationTypesRow value={props.parameters.notification_types} />

      <UsersRow value={props.parameters.usernames} />
      <AssetsRow value={props.parameters.vins} />
      <AssetsRow value={props.parameters.vins} />
      <AssetGroupsRow value={props.parameters.asset_group} />
    </>
  )
}
export default NotificationHistoryReportParameters
